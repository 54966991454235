export default function getperumattystations() {
    const points ={
        "type": "FeatureCollection",
        "name": "perumatty_stations",
        "crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:OGC:1.3:CRS84" } },
        "features": [
            {
                type: "Feature",
                properties: {
                  RUA: "Perumatty",
                  Type: "KrishiBhavan",
                  stationName: "kdisc_sci_aws_14",
                  tooltipText:"Panchayat Office, Perumatty",
                  Location: "Panchayat Office, Perumatty",
                  "Google Map URL": "https://goo.gl/maps/H9ZRE8qtJGtvWJvc9",
                  Lat: 10.64975298,
                  Long: 76.76319652,
                },
                geometry: { type: "Point", coordinates: [76.76319652, 10.64975298] },
              },
              {
                type: "Feature",
                properties: {
                  RUA: "Perumatty",
                  Type: "Extra",
                  stationName: "kdisc_sci_aws_11",
                  tooltipText:"Perumatty Higher Secondary School",
                  Location: "Perumatty Higher Secondary School",
                  "Google Map URL": "https://goo.gl/maps/NU4k93fpLRvnSS1D6",
                  Lat: 10.65674374,
                  Long: 76.80928768,
                },
                geometry: { type: "Point", coordinates: [76.80928768, 10.65674374] },
              },
        ]
        }
        
      
  
    return points;
  }
  