import React from "react";
import { Redirect } from "react-router-dom";
import Permission from "../permission";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import ResetPasswrod from "../pages/Authentication/ResetPassword";

// Dashboard
import Dashboard from "../pages/Dashboard/index";
import UserM from "../pages/User-management/userManagement";
import FileUpload from "../pages/Adm-components/FormUpload";
import FarmerSearch from "../pages/Adm-components/FarmerSearch.js";
import Termsheet from "../pages/Adm-components/termSheet-deleted.js";
import Report from "../pages/Adm-components/reportLayout.js";
import WeatherData from "../pages/Adm-components/weatherData";
import HourlyData from "../pages/Adm-components/hourly_Parameter";
import HourlyAllData from "../pages/Adm-components/hourly_Allparameter";
import DailyAllData from "../pages/Adm-components/daily_Allparameter";
import DailyData from "../pages/Adm-components/daily_Parameter";
import FarmerList from "../pages/Adm-components/farmerList";
import TotalCultivationRF from "../pages/Adm-components/totalCultivationRF";
import TotalAreaCrop from "../pages/Adm-components/totalAreaCrop";
import OnlineSensorData from "../pages/Adm-components/onlineSensorDataODS";
import SensorDiagnostic from "../pages/Adm-components/sensorDiagnostic";
import SensorDiagnosticADM from "../pages/Adm-components/sensorDiagnosticADM.js";
import FarmerDashboard from "../pages/Farmer/farmerDashboard.jsx";
import FarmProfile from "../pages/Farmer/farmProfile";
import TsImplication from "../pages/Farmer/termsheetImplication";
import TermsheetTable from "../pages/Adm-components/termsheetImplications";
import Termsheet_Table from "../pages/Adm-components/termsheet_Table";
import Current_Comparison from "../pages/Adm-components/current_comparison";
import Trend_Comparison from "../pages/Adm-components/trend_comparison";
import Farmer_Weather_Forecast from "../pages/Farmer/farmerForecast";
import Historical from "../pages/Adm-components/histDatalayout.js";
import FM_Historical from "../pages/Farmer/historicalData.js";
import Farmer_termsheet from "../pages/Farmer/fm_termsheet.js";
import Data_config from "../pages/Adm-components/sensorconfig";
import weather_Forecast from "../pages/Adm-components/weatherForecast.js";
// import Termsheet_conditions from "../pages/Adm-components/termsheet_conditions.js";
import Smc_Transactions from "../pages/Adm-components/smc-transactions";
import Batteryhistorical from "../pages/Adm-components/batteryhistorical";
import RssiHistorical from "../pages/Adm-components/rssihistorical";
import FarmerPayout from "../pages/Adm-components/farmTransactionStatus";
import BatteryhistoricalADM from "../pages/Adm-components/batteryHistoricalADM";
import Alert from "../pages/Adm-components/alert";
import smc_farmer from "../pages/Adm-components/payout-farmer";
import edit_termsheet from "../pages/Adm-components/edit_tmconditions";
import add_tmconditions from "../pages/Adm-components/add_tmconditions";
import tm_condition from "../pages/Adm-components/tm_conditions_old";
import tm_add from "../pages/Adm-components/termsheet_add";
import tm_update from "../pages/Adm-components/termsheet_update";
import tm_condition_demo from "../pages/Adm-components/tm_conditions";
import tm_perilwise from "../pages/Adm-components/tm_perilwise";
import weatherValidation from "../pages/Adm-components/weatherValidation";
import gatewayDiagnostics from "../pages/Adm-components/gatewayDiag";
import TermsheetClone from "../pages/Adm-components/termsheet_clone";
import TransactionFarmer from "../pages/Adm-components/transactionFarmer";
import PremiumPaid from "../pages/Adm-components/PremiumPaid";
import Tm_PDF from "../pages/Adm-components/Tm_Pdf";
import Fm_alerts from "../pages/Farmer/fm_alerts";
import FM_reference from "../pages/Farmer/FM_TermsheetReference";
import DailyReport from "../pages/Farmer/Datareport";
import Sensor_Diagnostic from "../pages/Adm-components/sensor_diag";
import Error404 from "../pages/Error404"


// const user_role = Permission();
const user_role = localStorage.getItem('user_type');
const authProtectedRoutes = [
  { path: "/dashboard", component:  Dashboard },
  { path: "/user-management", component: user_role === "KB" ? Error404 : UserM },
  { path: "/upload", component: user_role === "KB" ? Error404 : FileUpload },
  { path: "/farmer-search", component: FarmerSearch },
  { path: "/tm_perilwise", component: Termsheet },
  { path: "/report", component: Report },
  { path: "/weatherdata", component: WeatherData },
  { path: "/farmerlist", component: FarmerList },
  { path: "/totalcultivation", component: TotalCultivationRF },
  { path: "/totalareacrop", component: TotalAreaCrop },
  { path: "/onlinesensors", component: OnlineSensorData },
  { path: "/diagnostic", component: user_role === "KB" || user_role === "DR" ? Error404 : Sensor_Diagnostic },
  { path: "/farmerdashboard", component: FarmerDashboard },
  { path: "/farmprofile", component: FarmProfile },
  { path: "/tsimplication", component: TsImplication },
  { path: "/tssummarized", component: TermsheetTable },
  { path: "/termsheet-table", component: Termsheet_Table },
  { path: "/comparisoncurrent", component: user_role === "KB" ? Error404 : Current_Comparison },
  { path: "/comparisontrend", component: user_role === "KB" ? Error404 : Trend_Comparison},
  { path: "/hourlyparamsreport",  component: user_role === "KB" ? Error404 : HourlyData },
  { path: "/dailyparamsreport", component: user_role === "KB" ? Error404 : DailyData },
  { path: "/dailyallparamsreport", component: user_role === "KB" ? Error404 : DailyAllData},
  { path: "/hourlyallparamsreport", component: user_role === "KB" ? Error404 : HourlyAllData},
  { path: "/historical", component: Historical },
  { path: "/fm_forecast", component: Farmer_Weather_Forecast },
  { path: "/fmtermsheet", component: Farmer_termsheet },
  { path: "/fmhistorical", component: FM_Historical },
  { path: "/admdiagnostic", component: user_role === "KB" ? Error404 : SensorDiagnosticADM },
  { path: "/dataconfig",  component: user_role === "KB" || user_role === "DR" ? Error404 : Data_config },
  { path: "/weatherforecast", component: weather_Forecast },
  { path: "/termsheetconditions", component: tm_condition },
  { path: "/smc-transactions", component: Smc_Transactions },
  { path: "/battery-historical", component: Batteryhistorical },
  { path: "/rssi-historical", component: RssiHistorical },
  { path: "/farmer-Transaction-status",  component: user_role === "KB" ? Error404 : FarmerPayout },
  { path: "/battery-historical-adm", component: BatteryhistoricalADM },
  { path: "/alert", component: Alert },
  { path: "/smc-transactions-farmer", component: smc_farmer },
  { path: "/edit-conditions", component: edit_termsheet },
  { path: "/add-conditions", component: add_tmconditions },
  { path: "/tm_add", component: tm_add },
  { path: "/tm_update", component: tm_update },
  { path: "/tm_conditions", component: user_role === "KB" ? Error404 : tm_condition_demo },
  { path: "/termsheet", component: tm_perilwise },
  { path: "/weathervalidation", component: user_role === "KB" || user_role === "DR" ? Error404 : weatherValidation },
  { path: "/gatewaydiag", component: user_role === "KB" ? Error404 : gatewayDiagnostics },
  { path: "/tm_clone", component: TermsheetClone },
  { path: "/transactionfarmer", component: TransactionFarmer },
  { path: "/premiumpaid", component: user_role === "DR" ? Error404 : PremiumPaid },
  { path: "/tm_pdfviewer", component: Tm_PDF },
  { path: "/fm_alerts", component: Fm_alerts },
  { path: "/fmreports", component: DailyReport },
  { path: "/fm_reference", component: FM_reference },
  { path: "/sensor-diagnostic-1", component: Sensor_Diagnostic },
  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/login" /> },
];

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/change-password", component: ResetPasswrod },
];

export { authProtectedRoutes, publicRoutes };
