export default async function validate_Token(){
    const token=localStorage.getItem('token')    
    let user_token={
      "token":String(token)
    }
    user_token=JSON.stringify(user_token)
    const requestOptions = {
      method: 'POST',      
      headers: {        
        'Content-Type': 'application/json'
      },  
      body: user_token
  };
  var uservalidate = await fetch(process.env.REACT_APP_APIEND + `token/validator`, requestOptions).then(res => {
    return res.json();
  });
  if(uservalidate[0].status=="False"){
  localStorage.clear();
	window.location.href = '/';
  }
    // console.log("VALIDATOR",uservalidate[0].status);
  
      
}