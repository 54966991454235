import React, { Component } from "react";
import { Card, CardBody, Row, Col, Media } from "reactstrap";
import MapCustomControl from "./React-control/control.tsx";
import "../Dashboard/leaflet.css";
import { FormGroup, Label } from "reactstrap";
import ReactApexChart from "react-apexcharts";
import getpointsObject from "../../weather_points";
import getRuaObject from "../../rua_object";
import {
  MapContainer,
  TileLayer,
  GeoJSON,
  Popup,
  CircleMarker,
  Tooltip,
  SVGOverlay,
} from "react-leaflet";
import L from "leaflet";
import { withNamespaces } from "react-i18next";
const bounds = [
  [10.6280565267840235, 76.6888275027770305],
  [10.817207336425831, 76.9081420898434516],
];
const points = getpointsObject();
const rua = getRuaObject();
class DiagnosticMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sampletime: 0,
      otherProviderdata: [],
      dtKey: 0,
      coordinates: [],
      dropselect: "",
      latnew: "10.72",
      longnew: "76.810",
      zoom: 12,
      dpval: 0,
      showLoader: true,
      changecolor: "statusup",
      map: null,
      livestatus: [0],
      tooltiptest: "initial test",
      severityclass: "",
      selperil: "temperature",
      otherparams: [],
      series: [10, 3],
      seldp: "none",
      options: {
        dataLabels: {
          enabled: false,
        },
        chart:{
          redrawOnWindowResize: false,
          redrawOnParentResize: false,
        },
        plotOptions: {
          pie: {
            labels: {
              show: true,
            },
            donut: {
              size: "50%",
            },
          },
        },
        labels: ["Active", "Inactive"],
        colors: ["#1d7d1e", "#fa3c3c"],
        legend: {
          show: true,
          position: "right",
          horizontalAlign: "center",
          verticalAlign: "middle",
          floating: false,
          fontSize: "8px",
          offsetX: 0,
          offsetY: -10,
        },
        responsive: [
          {
            breakpoint: 600,
            options: {
              chart: {
                height: 240,
              },
              legend: {
                show: false,
              },
            },
          },
        ],
      },
    };
    this.onMapReset = this.onMapReset.bind(this);
    this.handleperilchange = this.handleperilchange.bind(this);
    this.handledpchange = this.handledpchange.bind(this);
    this.checkfillcolor=this.checkfillcolor.bind(this);
  }

  onEachrua = (rua, layer) => {
    const ruaname = rua.properties.LOCATION;
    layer.bindPopup(ruaname);
  };

  onMapReset() {
    // console.log("reset map");
    const { map } = this.state;
    if (map) map.flyTo([10.72, 76.81], 11);
  }

  getactivestatus() {
    let count = 0;
    this.props.livedata.map(function (points) {
      if (points.status == true) {
        count = count + 1;
      }
    });
    // console.log("STATUS CHECK",count)
    this.setState({
      series: [count, 14 - count],
    });
  }

  onEachws = (points, layer) => {
    layer.on({
      click: (event) => {
        const latitude = event.latlng.lat;
        const longitude = event.latlng.lng;
        // this.apicall(latitude, longitude);
      },
      mouseover: (event) => {
        const ws = event.target.feature.properties.Location;

        layer.bindPopup(ws);
      },
    });
  };
async getcurrentweather(){
  let searchData = {
    station: "all",
  };

  searchData = JSON.stringify(searchData);
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Token " + localStorage.getItem("token"),
    },
    body: searchData,
  };
  var weatherResponse = await fetch(
    process.env.REACT_APP_APIEND + `weather/current-api-weather`,
    requestOptions
  ).then((res) => {
    return res.json();
  });
  // console.log("OTHER DP DATA", weatherResponse);
  if (weatherResponse.hasOwnProperty("code")) {
    if (weatherResponse.code == 200) {
      this.setState({
        otherProviderdata: weatherResponse.data,
      });
    }
  }
}
componentDidMount() {
    //Get Total no of active sensors
    this.getactivestatus();
    //fetching data for other data providers
   this.getcurrentweather()
  }

  handleperilchange(e) {
    this.setState({ selperil: e.target.value });
  }

  checkfillcolor(status) {
    let styleClass;   
    var peril= this.state.selperil
    if (peril == "temperature") {
      if (status.temperature_status == "actual") {
        styleClass = "#2899d1";
      } else if (status.temperature_status == "compensate") {
        styleClass = "#d1c628";
      } else if (status.temperature_status == "extrapolated") {
        styleClass = "#d61f0b";
      }
    }

    if (peril == "rainfall") {
      if (status.rainfall_status == "actual") {
        styleClass = "#2899d1";
      } else if (status.rainfall_status == "compensate") {
        styleClass = "#d1c628";
      } else if (status.rainfall_status == "extrapolated") {
        styleClass = "#d61f0b";
      }
    }

    if (peril == "wind_speed") {
      if (status.wind_speed_status == "actual") {
        styleClass = "#2899d1";
      } else if (status.wind_speed_status == "compensate") {
        styleClass = "#d1c628";
      } else if (status.wind_speed_status == "extrapolated") {
        styleClass = "#d61f0b";
      }
    }

    if (peril == "wind_direction") {
      if (status.wind_direction_status == "actual") {
        styleClass = "#2899d1";
      } else if (status.wind_direction_status == "compensate") {
        styleClass = "#d1c628";
      } else if (status.wind_direction_status == "extrapolated") {
        styleClass = "#d61f0b";
      }
    }

    if (peril == "uv_index") {
      if (status.uv_index_status == "actual") {
        styleClass = "#2899d1";
      } else if (status.uv_index_status == "compensate") {
        styleClass = "#d1c628";
      } else if (status.uv_index_status == "extrapolated") {
        styleClass = "#d61f0b";
      }
    }

    if (peril == "humidity") {
      if (status.humidity_status == "actual") {
        styleClass = "#2899d1";
      } else if (status.humidity_status == "compensate") {
        styleClass = "#d1c628";
      } else if (status.humidity_status == "extrapolated") {
        styleClass = "#d61f0b";
      }
    }

    if (peril == "pressure") {
      if (status.pressure_status == "actual") {
        styleClass = "#2899d1";
      } else if (status.pressure_status == "compensate") {
        styleClass = "#d1c628";
      } else if (status.pressure_status == "extrapolated") {
        styleClass = "#d61f0b";
      }
    }

    return styleClass;
  }

  handledpchange(e) {
    this.setState({ seldp: e.target.value });
    if (e.target.value === "openweather") {
      this.setState({
        dtKey: 1,
      });
    } else if (e.target.value === "darksky") {
      this.setState({
        dtKey: 0,
      });
    }
  }

  checkcolor(status, value, peril) {
    let styleClass;
    // console.log("CHEKC COLOR FN", status, value, peril);
    if (peril == "temperature") {
      if (status.temperature_status == "actual") {
        styleClass = "statusup";
      } else if (status.temperature_status == "compensate") {
        styleClass = "statuszero";
      } else if (status.temperature_status == "extrapolated") {
        styleClass = "statusdown";
      }
    }

    if (peril == "rainfall") {
      if (status.rainfall_status == "actual") {
        styleClass = "statusup";
      } else if (status.rainfall_status == "compensate") {
        styleClass = "statuszero";
      } else if (status.rainfall_status == "extrapolated") {
        styleClass = "statusdown";
      }
    }

    if (peril == "wind_speed") {
      if (status.wind_speed_status == "actual") {
        styleClass = "statusup";
      } else if (status.wind_speed_status == "compensate") {
        styleClass = "statuszero";
      } else if (status.wind_speed_status == "extrapolated") {
        styleClass = "statusdown";
      }
    }

    if (peril == "wind_direction") {
      if (status.wind_direction_status == "actual") {
        styleClass = "statusup";
      } else if (status.wind_direction_status == "compensate") {
        styleClass = "statuszero";
      } else if (status.wind_direction_status == "extrapolated") {
        styleClass = "statusdown";
      }
    }

    if (peril == "uv_index") {
      if (status.uv_index_status == "actual") {
        styleClass = "statusup";
      } else if (status.uv_index_status == "compensate") {
        styleClass = "statuszero";
      } else if (status.uv_index_status == "extrapolated") {
        styleClass = "statusdown";
      }
    }

    if (peril == "humidity") {
      if (status.humidity_status == "actual") {
        styleClass = "statusup";
      } else if (status.humidity_status == "compensate") {
        styleClass = "statuszero";
      } else if (status.humidity_status == "extrapolated") {
        styleClass = "statusdown";
      }
    }

    if (peril == "pressure") {
      if (status.pressure_status == "actual") {
        styleClass = "statusup";
      } else if (status.pressure_status == "compensate") {
        styleClass = "statuszero";
      } else if (status.pressure_status == "extrapolated") {
        styleClass = "statusdown";
      }
    }

    return styleClass;
  }
  checkgateway(location){
    if(location=="kdisc_sci_aws_08"){
      return true
    }
    else if(location=="kdisc_sci_aws_01"){
      return true
    }

    else if(location=="kdisc_sci_aws_09"){
      return true
    }

    else if(location=="kdisc_sci_aws_11"){
      return true
    }
    else {
      return false
    }

  }
  render() {
    const { t, i18n } = this.props;
    const ruaStyle = {
      color: "red",
      weight: 1.3,
      fillOpacity: 0,
    };
    const iconPerson = new L.Icon({
      iconUrl: require("../Dashboard/images/circle.png"),
      iconRetinaUrl: require("../Dashboard/images/circle.png"),
      iconAnchor: null,
      popupAnchor: null,
      shadowUrl: null,
      shadowSize: null,
      shadowAnchor: null,
      iconSize: new L.Point(60, 75),
      className: "leaflet-div-icon",
    });

    return (
      <React.Fragment>
        <Row>
          <Col md={4}>
            <Card>
              <CardBody style={{ height: "161px" }}>
                <Media>
                  <Media body className="overflow-hidden">
                    <p className="text-truncate font-size-14 mb-2">
                      Number of Sensors
                    </p>
                    <center>
                      <h2 className="mb-0" style={{ padding: "10%" }}>
                        14
                      </h2>
                    </center>
                  </Media>
                  <div className="text-primary">
                    <i className={"ri-cpu-line font-size-24"}></i>
                  </div>
                </Media>
              </CardBody>
            </Card>
            <Card></Card>
          </Col>
          <Col md={4}>
            <Card>
              <CardBody style={{ height: "161px" }}>
                <Media>
                  <Media body className="overflow-hidden">
                    <p className="text-truncate font-size-14 mb-2">
                      Number of Gateways
                    </p>
                    <center>
                      <h2 className="mb-0" style={{ padding: "10%" }}>
                        4
                      </h2>
                    </center>
                  </Media>
                  <div className="text-primary">
                    <i className={" ri-router-line font-size-24"}></i>
                  </div>
                </Media>
              </CardBody>
            </Card>
            <Card></Card>
          </Col>
          <Col md={4}>
            <Card>
              <CardBody style={{ minHeight: "161px" }}>
                <Media>
                  <Media body className="overflow-hidden">
                    <p className="text-truncate font-size-14 mb-2">
                      Sensor Status
                    </p>
                    <ReactApexChart
                      options={this.state.options}
                      series={this.state.series}
                      type="donut"
                      height="90px"
                    />
                  </Media>
                  <div className="text-primary">
                    <i className={" ri-pie-chart-2-line font-size-24"}></i>
                  </div>
                </Media>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Card>
          <CardBody>
            <Row>
              <Col md="3">
                <FormGroup row>
                  <Label
                    className="col-form-label"
                    style={{ fontSize: "14px" }}
                  >
                    {t("Parameter")}{" "}
                  </Label>
                  <Col md={9}>
                    <select
                      className="form-select"
                      style={{ fontSize: "11px" }}
                      onChange={this.handleperilchange}
                    >
                      <option value="temperature" selected>
                        Temperature
                      </option>
                      <option value="rainfall">Rainfall</option>
                      <option value="wind_speed">Wind Speed</option>
                      <option value="wind_direction">Wind Direction</option>
                      <option value="uv_index">UVI</option>
                      <option value="humidity">Relative Humidity</option>
                      <option value="pressure">Pressure</option>
                    </select>
                  </Col>
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup row>
                  <Label
                    className="col-form-label"
                    style={{ fontSize: "14px" }}
                  >
                    {t("Data Provider")}{" "}
                  </Label>
                  <Col md={9}>
                    <select
                      className="form-select"
                      style={{ fontSize: "11px" }}
                      onChange={this.handledpchange}
                    >
                      <option value="none" selected>
                        Configuration
                      </option>
                      <option value="darksky">Dark Sky</option>
                      <option value="openweather">Open weather</option>
                    </select>
                  </Col>
                </FormGroup>
              </Col>
               <Col md={2} style={{ marginTop: "4%" }}>
                <div className="badge-desktop">
                  <center>
                    <div className="legendupadm"></div>
                    <p style={{ display: "inline-block" }}>Active</p>
                  </center>
                </div>
              </Col>
              <Col md={2} style={{ marginTop: "4%" }}>
                <div className="badge-desktop">
                  <center>
                    <div className="legenddown"></div>
                    <p style={{ display: "inline-block" }}>Extrapolated</p>
                  </center>
                </div>
              </Col>

              <Col md={2} style={{ marginTop: "4%" }}>
                <div className="badge-desktop">
                  <center>
                    <div className="legendoutrange"></div>
                    <p style={{ display: "inline-block" }}>Compensated</p>
                  </center>
                </div>
              </Col>
              <div className="badge-mobile">
               <Row>
                  <div class="col p-0">
                    <center>
                      <div className="legendupadm"></div>
                      <p style={{ display: "inline-block" }}>Active</p>
                    </center>
                  </div>
                  <div class="col p-0">
                    <center>
                      <div className="legenddown"></div>
                      <p style={{ display: "inline-block" }}>Extrapolated</p>
                    </center>
                  </div>
                  <div class="col p-0">
                    <center>
                      <div className="legendoutrange"></div>
                      <p style={{ display: "inline-block" }}>Compensated</p>
                    </center>
                  </div>
                  </Row>
              </div>
            </Row>
          </CardBody>
        </Card>
        <MapContainer
          center={[this.state.latnew, this.state.longnew]}
          whenCreated={(map) => this.setState({ map })}
          zoom={this.state.zoom}
          scrollWheelZoom={false}
          style={{
            margin: "0px 0px 0px 0px",
            minHeight: "500px",
            position: "relative",
          }}
        >
          <GeoJSON
            style={ruaStyle}
            data={rua.features}
            onEachFeature={this.onEachrua}
            icon={"text"}
          />
          {this.props.livedata.map((points, key) => (
            <CircleMarker
              center={[points.lat, points.long]}
              radius={7}
              fillOpacity={1}
              // fillColor="#2899d1"
              fillColor={this.checkfillcolor(
                (points)
              )}
              // this.state.selperil
              stroke={this.checkgateway(points.station)}
              color= "#000000"
              position={(points.lat, points.long)}
            >
            
              <div>
                <Tooltip
                  permanent
                  direction="bottom"
                  className="tooltipclass"
                  offset={[0, 7]}
                >
                  <div className="statusup">
                    {String(points[this.state.selperil].toFixed(2))}{" "}
                  </div>
                  <div>
                    {this.state.otherProviderdata.length > 0 &&
                    this.state.seldp != "none"
                      ? this.state.otherProviderdata[key][points.station][
                          this.state.dtKey
                        ][this.state.seldp][this.state.selperil].toFixed(2)
                      : ""}
                  </div>
                </Tooltip>
              </div>
              <Popup>
                {points["location"]}
                <br />
              </Popup>
            </CircleMarker>
          ))}
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />{" "}
          ;
          <SVGOverlay bounds={bounds}>
            <text x="10%" y="70%" fill="#02b514">
              Chittur
            </text>
            <text x="20%" y="95%" fill="#02b514">
              Pattanchery
            </text>
            <text x="30%" y="80%" fill="#02b514">
              Perumatty
            </text>
            <text x="40%" y="60%" fill="#02b514">
              Nallepilly
            </text>
            <text x="60%" y="50%" fill="#02b514">
              Kozhinjampara
            </text>
            <text x="75%" y="42%" fill="#02b514">
              Eruthenpathy
            </text>
            <text x="70%" y="20%" fill="#02b514">
              Vadakarapathy
            </text>
          </SVGOverlay>
          <MapCustomControl>
            <Row>
              <Col xl={3} lg={4} sm={6}>
                <i
                  className="fas fa-home"
                  onClick={this.onMapReset}
                  style={{ fontSize: "18px", cursor: "pointer" }}
                ></i>
              </Col>
            </Row>
          </MapCustomControl>
        </MapContainer>
      </React.Fragment>
    );
  }
}

export default withNamespaces()(DiagnosticMap);
