import React from "react";
import { Table, Container, Row, Col, FormGroup, Label } from "reactstrap";
function checkvalue(value) {
  if (value == null) {
    return "NIL";
  } else {
    return value;
  }
}
const CropInsured = (props) => {
  console.log("PROPS IN CROPS NOW", Object.keys(props.data).length);
  return (
    <React.Fragment>
      {Object.keys(props.data).length > 0 &&
        Object.keys(props.data).map((item, index, data) => (
          <div class="accordion" id="accordionExample">
            {console.log("ITEMSS INSIDE LOOP", props.data[item])}
            <div class="accordion-item">
              <a class="accordion-header" id="headingTwo">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={"#collapsecrop" + String(index + 1)}
                  aria-expanded="false"
                  aria-controls="collapsecrop"
                >
                  <a
                    style={{
                      fontSize: "12px",
                      color: "#a3a3a3",
                      paddingRight: "3px",
                    }}
                  >
                    Season:
                  </a>

                  <a style={{ fontSize: "12px" }}> {Object.keys(props.data)[index]}</a>
                </button>
              </a>
              <div
                id={"collapsecrop" + String(index + 1)}
                class="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <Row xs={12}>
                    <Table
                      id="tspayout"
                      bordered
                      style={{ fontSize: "11px" }}
                      responsive
                    >
                      <thead style={{ backgroundColor: "#ffffff" }}>
                        <tr>
                          <th style={{ width: "130px" }}>Crop Name</th>
                          {/* <th style={{ width: "130px" }}>Crop Code</th> */}
                          {/* <th style={{ width: "60px" }}>Mixed Crop</th> */}
                          <th style={{ width: "60px" }}>Area (ha)/No. of Trees</th>
                          {/* <th style={{ width: "60px" }}>Area Insured</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {props.data[item].map((crops) => {
                          return (
                            <tr>
                              <td>{crops.crop_name}</td>
                              {/* <td>{checkvalue(crops.crop_code)}</td> */}
                              {/* <td>{crops.mix_crop}</td> */}
                              <td>{crops.area}</td>
                              {/* <td>{crops.areainsured}</td> */}
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        ))}
    </React.Fragment>
  );
};

export default CropInsured;
