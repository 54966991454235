import React, { Component } from "react";
// Table from react-bootstrap
import {
  Table,
  Card,
  CardBody,
  Row,
  Col,
  Container,
  FormGroup,
  Label,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
// Bootstrap CSS
import { Link } from "react-router-dom";
import { MDBDataTable ,MDBFooter} from "mdbreact";
import "../Adm-components/css/tsperilwise.css";
import { toast } from "react-toastify";
import Moment from "moment";
import Permission from "../../permission.js";
import TermsheetPDF from "../Adm-components/TermsheetPDF";
toast.configure();
export class Termsheet_Table extends Component {
  constructor(props) {
    // let querydata=props.tabledata;
    super(props);
    this.state = {
      visible: false,
      CurrentUsertype: "",
      modal_xlarge: false,
      tableprops: props.tableprops,
      acre: props.tableprops.acre,
      tablequeryresponse: [],
      tabledata: [],
      rowtabledata: [],
      conditiondata: [],
      conditions: [],
      phasedata: [],
      tableseason: props.tableprops.season,
      tableperil: props.tableprops.peril,
      dP: props.tableprops.tabledp,
      tablecrop: props.tableprops.crop,
      tableRUA: props.tableprops.location,
    };
    this.tog_xlarge = this.tog_xlarge.bind(this);
    // console.log("TABLEEEE PAROPPPSPS", props.tableprops);
  }
  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  componentWillReceiveProps(nextProps) {
    this.setState({
      tableprops: nextProps.tableprops,
      acre: nextProps.tableprops.acre,
    });
    // console.log("PROPS ACRE", String(nextProps.tableprops.acre));
  }
  removeBodyCss() {
    document.body.classList.add("no_padding");
  }
  tog_xlarge() {
    this.setState((prevState) => ({
      modal_xlarge: !prevState.modal_xlarge,
    }));
    this.removeBodyCss();
  }
  show() {
    this.setState({ visible: true });
  }
  hide() {
    this.setState({ visible: false });
  }
  checkdp(peril) {
    if (peril == "OnlineS") {
      return "Online Sensors";
    } else {
      return peril;
    }
  }
  async fetchtermsheetdata() {
    let searchdata = {
      rua: this.state.tableprops.selloc,
      season: this.state.tableprops.season,
      peril: this.state.tableprops.peril,
      crop: this.state.tableprops.crop,
      data_provider: this.checkdp(this.state.tableprops.tabledp),
    };
    searchdata = JSON.stringify(searchdata);

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + localStorage.getItem("token"),
      },
      body: searchdata,
    };
    // console.log("SEARCH DATA", searchdata);
    var tableData = await fetch(
      process.env.REACT_APP_APIEND + `smart_crop/term-sheet-table-agri-dept`,
      requestOptions
    ).then((res) => {
      return res.json();
    });
    // console.log("TABLE RESPONSE", tableData);
    if (tableData.hasOwnProperty("result")) {
      // console.log("TABLE IS EMPTY");
      this.setState({
        rowtabledata: [],
      });
    } else {
      this.generatetermsheettabledata(tableData.data);
      var parsedtabledata = tableData.data[0].header;
      this.setState(
        {
          tabledata: parsedtabledata,
        },
        () => {
          // console.log("TABLEDATA", this.state.tabledata);
        }
      );
    }
  }
  async fetchconditiondata() {
    const user_role = Permission();
    if(user_role[0] === "KB"){
      
    }else{
      let searchdata = {
        crop: this.state.tableprops.crop,
        peril: this.state.tableprops.peril,
        season: this.state.tableprops.season,
      };
      searchdata = JSON.stringify(searchdata);
  
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + localStorage.getItem("token"),
        },
        body: searchdata,
      };
      try {
        var conditiondata = await fetch(
          process.env.REACT_APP_APIEND + `smart_crop/term-sheet-condition/term-condition`,
          requestOptions
        ).then((res) => {
          return res.json();
        });
        // if(conditiondata.code==200){
        //   this.setState({
        //     showloader:false
        //   })
        // }
        var phase = conditiondata.result[0];
        var peril_name = Object.keys(phase);
        var phasedata = phase[peril_name].phase_data;
  
        this.setState({
          conditions: conditiondata.result,
          phasedata: phasedata,
        });
      } catch (err) {
        toast.error("Something's not right in termsheet conditions !");
      }
    }
    
  }
  checknull(value) {
    if (value != null && value > 0) {
      return parseFloat(value).toFixed(2);
    } else {
      return "0.00";
    }
  }

  componentDidMount() {
    this.fetchtermsheetdata();
    this.props.onRef(this);
    this.fetchconditiondata();
    const user_role = Permission();
    this.setState({
      CurrentUsertype: user_role[0],
    });
  }
  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setState({
        dP: this.props.tableprops.tabledp,
        tableperil: this.props.tableprops.peril,
        tableseason: this.props.tableprops.season,
        tablecrop: this.props.tableprops.crop,
        tableRUA: this.props.tableprops.location,
      });
    }
  }
  componentWillUnmount() {
    this.props.onRef(undefined);
  }
  generatetermsheettabledata(data) {
    let getthis = this;
    let tabledt = [];
    let CalculatedV = 0.0;
    let UnitC = 0.0;
    let EligibleP = 0.0;
    let TeligibleP = 0.0;
    // console.log("TABLE DATA", data);
    if (data[1].table != "No data in table") {
      var mapdata = data[1].table;
      var mapdata1 = mapdata[0];
      mapdata.map(function (item, index, data) {
        var keyvar = Object.keys(item);
        keyvar = keyvar[0];
        CalculatedV = CalculatedV + item[keyvar].calculated_value;
        UnitC = UnitC + item[keyvar].unit_cost;
        EligibleP = EligibleP + item[keyvar].eligible_payout;
        TeligibleP =
          TeligibleP + getthis.totalpayout(item[keyvar].eligible_payout);
        var newdata = {
          peril: <p className="alncenter">{item[keyvar].peril}</p>,
          phase: getthis.capitalizeFirstLetter(keyvar),
          start_date: Moment(item[keyvar].start_date).format("DD-MM-YYYY"),
          end_date: Moment(item[keyvar].end_date).format("DD-MM-YYYY"),
          calculated_value: (
            <p className="alnrighttd">
              {parseFloat(item[keyvar].calculated_value).toFixed(2)}
            </p>
          ),
          unit_cost: (
            <p className="alnrighttd">
              {parseFloat(item[keyvar].unit_cost).toFixed(2)}
            </p>
          ),
          eligible_payout: (
            <p className="alnrighttd">
              {parseFloat(item[keyvar].eligible_payout).toFixed(2)}
            </p>
          ),
          total_eligible_payout: (
            <p className="alnrighttd">
              {parseFloat(
                getthis.totalpayout(item[keyvar].eligible_payout)
              ).toFixed(2)}
            </p>
          ),
        };
        tabledt.push(newdata);
        // console.log("GENERATE TABLE ",newdata)
      });
    }
    tabledt.push({
      peril: "Total",
      phase: " ",
      start_date: "",
      end_date: "",
      calculated_value: "",
      unit_cost: "",
      eligible_payout: <p className="alnrighttd">{EligibleP.toFixed(2)}</p>,
      total_eligible_payout: (
        <p className="alnrighttd">{TeligibleP.toFixed(2)}</p>
      ),
    });
    this.setState({
      rowtabledata: tabledt,
    });
  }

  totalpayout(amount) {
    var area = this.state.acre;
    var payout = amount * area;
    if (typeof payout == "number") {
      return Number(payout);
    } else {
      return "NIL";
    }
  }
  checknull(value) {
    if (value != null && value > 0) {
      return parseFloat(value).toFixed(2);
    } else if (value == "true") {
      return "True";
    } else if (value == "false") {
      return "False";
    } else {
      return "0.00";
    }
  }
  render() {
    let data_table = {
      columns: [
        {
          label: "Peril",
          field: "peril",
        },
        {
          label: "Phase",
          field: "phase",
        },
        {
          label: "Start Date",
          field: "start_date",
          sort: "disabled",
        },

        {
          label: "End Date",
          field: "end_date",
          sort: "disabled",
        },
        {
          label: "Deviation from trigger/strike",
          field: "calculated_value",
        },
        {
          label: "Unit Cost (Rs.)",
          field: "unit_cost",
        },
        {
          label: "Eligible payout / ha (Rs.)",
          field: "eligible_payout",
        },
        {
          label: "Total Eligible Payout (Rs.)",
          field: "total_eligible_payout",
        },
      ],
      rows: this.state.rowtabledata,
    };

    let conditiondata = {
      columns: [
        {
          label: "Phase",
          field: "phase",
        },
        {
          label: "From Date",
          field: "from_date",
        },

        {
          label: "To Date",
          field: "to_date",
        },
        {
          label: "Trigger",
          field: "trigger",
        },
        {
          label: "Strike",
          field: "strike",
        },
        {
          label: "Exit",
          field: "exit",
        },
        {
          label: "Notional/Variable Payout",
          field: "notional_payout",
        },
        {
          label: "Fixed Payout",
          field: "fixed_payout",
        },
        {
          label: "Maximum Payout",
          field: "maximum_payout",
        },
      ],
      rows: this.state.conditiondata,
    };
    // console.log("dp", this.state.tabledata);
    return (
      <>
        <React.Fragment>
          <Container>
            <Row>
              <Col xs={12} md={8}>
                <div className="pt-2" style={{ marginBottom: "-4px" }}>
                  <h6> Termsheet Implications </h6>
                </div>
              </Col>
              <div className="ts_imp">
                <Col xs={12} className="mb-1" style={{ fontSize: "13px" }}>
                  <Row className="mb-1">
                    <Col xs={12} md={8} style={{ paddingLeft: "0px" }}>
                      <b>Season : {this.state.tableseason}</b>
                    </Col>
                    <Col xs={6} md={4}>
                      <b>Peril : {this.state.tableperil}</b>
                    </Col>
                  </Row>
                  <Row className="mb-1">
                    <Col xs={12} md={8} style={{ paddingLeft: "0px" }}>
                      <b>Crop : {this.state.tablecrop}</b>
                    </Col>
                    <Col xs={6} md={4}>
                      <b>Area : {this.state.acre} (ha)/No. of Trees</b>
                    </Col>
                  </Row>
                  <Row className="mb-1">
                    <Col xs={12} md={8} style={{ paddingLeft: "0px" }}>
                      <b>
                        Location :{" "}
                        {this.state.CurrentUsertype == "KB"
                          ? localStorage.getItem("krishibhavan")
                          : this.state.tableRUA}
                      </b>
                    </Col>
                    <Col xs={6} md={4}>
                      <b>Data Provider : {this.state.dP}</b>
                    </Col>
                  </Row>

                  <Row className="mb-1">
                    <Col xs={12} md={8}></Col>
                    <Col xs={6} md={4}>
                      <Link
                        onClick={this.tog_xlarge}
                        style={{ fontSize: "13px" }}
                      >
                        <u>Termsheet Conditions</u>
                      </Link>
                    </Col>
                  </Row>
                </Col>
              </div>

              <div className="ts_imp_mob">
                <Row>
                  <Col md={3}>
                    <FormGroup>
                      <Label
                        className="col-md-12"
                        style={{ fontSize: "12px", color: "#a3a3a3" }}
                      >
                        Season
                      </Label>
                      <Col>{this.state.tabledata.season}</Col>
                    </FormGroup>
                  </Col>
                  <Col md={3}>
                    <FormGroup>
                      <Label
                        className="col-md-12"
                        style={{ fontSize: "12px", color: "#a3a3a3" }}
                      >
                        Peril
                      </Label>
                      <Col>{this.state.tabledata.peril}</Col>
                    </FormGroup>
                  </Col>
                  <Col md={3}>
                    <FormGroup>
                      <Label
                        className="col-md-12"
                        style={{ fontSize: "12px", color: "#a3a3a3" }}
                      >
                        Crop
                      </Label>
                      <Col>{this.state.tabledata.crop}</Col>
                    </FormGroup>
                  </Col>
                  <Col md={3}>
                    <FormGroup>
                      <Label
                        className="col-md-12"
                        style={{ fontSize: "12px", color: "#a3a3a3" }}
                      >
                        Area
                      </Label>
                      <Col>{this.state.acre}</Col>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={3}>
                    <FormGroup>
                      <Label
                        className="col-md-12"
                        style={{ fontSize: "12px", color: "#a3a3a3" }}
                      >
                        Location
                      </Label>
                      <Col>{this.state.tabledata.location}</Col>
                    </FormGroup>
                  </Col>
                  <Col md={3}>
                    <FormGroup>
                      <Label
                        className="col-md-12"
                        style={{ fontSize: "12px", color: "#a3a3a3" }}
                      >
                        Data Provider
                      </Label>
                      <Col>{this.state.tableprops.tabledp}</Col>
                    </FormGroup>
                  </Col>

                  <Col md={6}>
                    <Col>
                      <Link
                        onClick={this.tog_xlarge}
                        style={{ fontSize: "14px" }}
                      >
                        <u>Termsheet Conditions</u>
                      </Link>
                    </Col>
                  </Col>
                </Row>
              </div>

              {/* <Col>
                  <Link onClick={this.tog_xlarge} style={{fontSize:'11px'}}><u>Termsheet Conditions</u></Link>
                  </Col> */}
              <Col>
                <div
                  className="table-responsive"
                  // id="tsperil"
                  style={{ fontSize: "12px" }}
                >
                  <MDBDataTable
                    paging={false}
                    responsive
                    showPaginationBottom={false}
                    bordered
                    sortable={false}
                    data={data_table}
                    searching={false}
                    className="datadownloadtable"
                  >
                   
                  </MDBDataTable>
                
                </div>
              </Col>
            </Row>
          </Container>
          <Row>
            <Modal
              size="xl"
              isOpen={this.state.modal_xlarge}
              toggle={this.tog_xlarge}
              backdrop="static"
            >
              <ModalHeader
                toggle={() => this.setState({ modal_xlarge: false })}
                style={{ backgroundColor: "#A4C1D2" }}
              >
                Termsheet Conditions
              </ModalHeader>
              <ModalBody>
                <Container>
                  <TermsheetPDF data={this.state.tableprops} />
                </Container>
              </ModalBody>
            </Modal>
          </Row>
        </React.Fragment>
      </>
    );
  }
}

export default Termsheet_Table;
