export default function getpointsObject() {
  const points = {
    type: "FeatureCollection",
    name: "Final_proposed_locations",
    crs: {
      type: "name",
      properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" },
    },
    features: [
      {
        type: "Feature",
        properties: {
          RUA: "Chittur-Thattamangalam",
          Type: "Local Body HQ",
          stationName: "kdisc_sci_aws_01",
          tooltipText: "Muncipal Corporation Office",
          Location: "Muncipal Corporation Office",
          "Google Map URL": "https://goo.gl/maps/ymfxBYFk88N2Pt5VA",
          Lat: 10.69300691,
          Long: 76.72595507,
        },
        geometry: { type: "Point", coordinates: [76.72595507, 10.69300691] },
      },
      {
        type: "Feature",
        properties: {
          RUA: "Eruthempathy",
          Type: "KrishiBhavan",
          stationName: "kdisc_sci_aws_10",
          tooltipText:
            "Seed Godown, Kerala State Seed Development Authority (KSSDA)",
          Location:
            "Seed Godown, Kerala State Seed Development Authority (KSSDA)",
          "Google Map URL": "https://goo.gl/maps/UY8SGeNqZGJTwLGT8",
          Lat: 10.74866619,
          Long: 76.85879364,
        },
        geometry: { type: "Point", coordinates: [76.85879364, 10.74866619] },
      },
      {
        type: "Feature",
        properties: {
          RUA: "Eruthempathy",
          Type: "Extra",
          stationName: "kdisc_sci_aws_04",
          tooltipText: "Bhagavathy Govt. High School",
          Location: "Bhagavathy Govt. High School",
          "Google Map URL": "https://goo.gl/maps/oUF4hPYD9wJM3DUC8",
          Lat: 10.70246231,
          Long: 76.85248817,
        },
        geometry: { type: "Point", coordinates: [76.85248817, 10.70246231] },
      },
      {
        type: "Feature",
        properties: {
          RUA: "Kozhijampara",
          Type: "KrishiBhavan",
          stationName: "kdisc_sci_aws_08",
          tooltipText: "Bharathamatha Arts and Science College, Kozhinjampara",
          Location: "Bharathamatha Arts and Science College, Kozhinjampara",
          "Google Map URL": "https://goo.gl/maps/a1sAZL3RYa5y7Gqs6",
          Lat: 10.73595019,
          Long: 76.82999565,
        },
        geometry: { type: "Point", coordinates: [76.82999565, 10.73595019] },
      },
      {
        type: "Feature",
        properties: {
          RUA: "Nallepilly",
          Type: "KrishiBhavan",
          stationName: "kdisc_sci_aws_03",
          tooltipText: "Krishi Bhavan",
          Location: "KrishiBhavan",
          "Google Map URL": "https://goo.gl/maps/DZmGbTgp8QBpJt4JA",
          Lat: 10.72918673,
          Long: 76.78622876,
        },
        geometry: { type: "Point", coordinates: [76.78622876, 10.72918673] },
      },
      {
        type: "Feature",
        properties: {
          RUA: "Pattenchery",
          Type: "Panchayat Cremation Ground",
          stationName: "kdisc_sci_aws_06",
          tooltipText: "Panchayat Cremation Ground",
          Location: "Panchayat Cremation Ground",
          "Google Map URL": "https://goo.gl/maps/JwLe1Z7tQ29yr7fY8",
          Lat: 10.64777998,
          Long: 76.73270522,
        },
        geometry: { type: "Point", coordinates: [76.73270522, 10.64777998] },
      },
      {
        type: "Feature",
        properties: {
          RUA: "Perumatty",
          Type: "KrishiBhavan",
          stationName: "kdisc_sci_aws_14",
          tooltipText: "Panchayat Office, Perumatty",
          Location: "Panchayat Office, Perumatty",
          "Google Map URL": "https://goo.gl/maps/H9ZRE8qtJGtvWJvc9",
          Lat: 10.64975298,
          Long: 76.76319652,
        },
        geometry: { type: "Point", coordinates: [76.76319652, 10.64975298] },
      },
      {
        type: "Feature",
        properties: {
          RUA: "Vadakarapathy",
          Type: "KrishiBhavan",
          stationName: "kdisc_sci_aws_09",
          tooltipText: "Ahalia Campus",
          Location: "Ahalia campus",
          "Google Map URL": "https://goo.gl/maps/amU9LJkAvSeqvu6bA",
          Lat: 10.7951,
          Long: 76.8363,
        },
        geometry: { type: "Point", coordinates: [76.8363, 10.7951] },
      },
      {
        type: "Feature",
        properties: {
          RUA: "Vadakarapathy",
          Type: "Extra",
          stationName: "kdisc_sci_aws_13",
          tooltipText: "Kinarpallam Milk Producers Co-operative Society",
          Location: "Kinarpallam Milk Producers Co-operative Society",
          "Google Map URL": "https://goo.gl/maps/qeFfWvJQjXC1jStN8",
          Lat: 10.77941127,
          Long: 76.85986374,
        },
        geometry: { type: "Point", coordinates: [76.85986374, 10.77941127] },
      },
      {
        type: "Feature",
        properties: {
          RUA: "Nallepilly",
          Type: "Extra",
          stationName: "kdisc_sci_aws_07",
          tooltipText: "Government Technical High School",
          Location: "Government Technical High School chittur",
          "Google Map URL": "https://goo.gl/maps/1sAB5vCMH1UQruXT9",
          Lat: 10.70258377,
          Long: 76.75669052000001,
        },
        geometry: { type: "Point", coordinates: [76.75669052, 10.70258377] },
      },
      {
        type: "Feature",
        properties: {
          RUA: "Pattenchery",
          Type: "Extra",
          stationName: "kdisc_sci_aws_02",
          tooltipText: "GSM High School, Thathamangalam",
          Location: "GSM High School, Thathamangalam",
          "Google Map URL": "https://goo.gl/maps/xQqCSeFAWKR2J6KG6",
          Lat: 10.67481694,
          Long: 76.70654271,
        },
        geometry: { type: "Point", coordinates: [76.70654271, 10.67481694] },
      },
      {
        type: "Feature",
        properties: {
          RUA: "Kozhijampara",
          Type: "Extra",
          stationName: "kdisc_sci_aws_12",
          tooltipText: "KWA Water Treatment Plant",
          Location: "KWA Water treatment Plant",
          "Google Map URL": "https://goo.gl/maps/SCUqm3SYDyYQRgQT9",
          Lat: 10.66709167,
          Long: 76.82955577,
        },
        geometry: { type: "Point", coordinates: [76.82955577, 10.66709167] },
      },
      {
        type: "Feature",
        properties: {
          RUA: "Perumatty",
          Type: "Extra",
          stationName: "kdisc_sci_aws_11",
          tooltipText: "Perumatty Higher Secondary School",
          Location: "Perumatty Higher Secondary School",
          "Google Map URL": "https://goo.gl/maps/NU4k93fpLRvnSS1D6",
          Lat: 10.65674374,
          Long: 76.80928768,
        },
        geometry: { type: "Point", coordinates: [76.80928768, 10.65674374] },
      },
      {
        type: "Feature",
        properties: {
          RUA: "Kozhijampara",
          Type: "Extra",
          stationName: "kdisc_sci_aws_05",
          tooltipText: "Moolathara Regulator",
          Location: "Moolathara Regulator",
          "Google Map URL": null,
          Lat: 10.6657739,
          Long: 76.87386074,
        },
        geometry: { type: "Point", coordinates: [76.87386074, 10.6657739] },
      },
    ],
  };
  return points;
}
