import React, { Component } from "react";
import {
  Card,
  CardBody,
  Row,
  Col,
  CardText,
  FormGroup,
  NavLink,
  NavItem,
  Nav,
  Label,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container,
  Button,
  Spinner,
  CardHeader,
} from "reactstrap";
import { Link } from "react-router-dom";
import { Input, Space } from "antd";
import { toast } from "react-toastify";
import Moment from 'moment';
const { TextArea } = Input;
toast.configure();
export default class edit_tmconditions extends Component {
  constructor(props) {
    // console.log("EDIT  PROPS", props.location.state.data);
    super(props);
    this.state = {
      phase_data: props.location.state.data,
      // updated_data:props.location.state.data,
      modal_small: false,
      current_rules: [],
      current_head: "",
      conditions: [],
      previous_phases: [],
      rules: [],
      updatedfield: [],
      current_index: "",
    };
    this.updateconditions = this.updateconditions.bind(this);
    this.onchangefields = this.onchangefields.bind(this);
    this.updatefields = this.updatefields.bind(this);
    this.openupdaterulesbox = this.openupdaterulesbox.bind(this);
    this.Onchange = this.Onchange.bind(this);
    // console.log("INTIAL PROPS", this.state.phase_data);
  }
  changephasechange(e) {
    // console.log(e.target.value);
  }
  tog_small() {
    this.setState((prevState) => ({
      modal_small: !prevState.modal_small,
    }));
  }

  async updateconditions(id) {
    this.setState({
      current_index: id,
    });
    // console.log(this.state.phase_data);
    let items = [...this.state.phase_data];
    let keys = [];
    items.map((phases) => {
      let key = Object.getOwnPropertyNames(phases);
      keys.push(key[0]);
    });
    // console.log("ITEMS", items);
    // console.log("PHASE ID", items[id][keys[id]].unit_cost);
    // var currentPhase=
    var new_updateddata = {
      id: items[id][keys[id]].id,
      season: items[id][keys[id]].season,
      crop: items[id][keys[id]].phase,
      peril: items[id][keys[id]].peril,
      phase: items[id][keys[id]].peril,
      startDate: items[id][keys[id]].startDate,
      endDate: items[id][keys[id]].endDate,
      phase_maximum_payout: items[id][keys[id]].phase_maximum_payout,
      is_daily: items[id][keys[id]].is_daily,
      is_phase: items[id][keys[id]].is_phase,
      is_peril_phase_end: items[id][keys[id]].is_peril_phase_end,
      previous_phases: [],
      unit_cost: items[id][keys[id]].unit_cost,
      unit_cost2: items[id][keys[id]].unit_cost2,
      max_payout: items[id][keys[id]].max_payout,
      is_day_end_alert: items[id][keys[id]].is_day_end_alert,
      condition: items[id][keys[id]].condition,
      rule: items[id][keys[id]].rule,
      daily_rule: items[id][keys[id]].daily_rule,
      day_end_alert_rule: items[id][keys[id]].day_end_alert_rule,
    };
    // console.log("NEW STRUCTED", new_updateddata);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + localStorage.getItem("token"),
      },
      body: JSON.stringify(new_updateddata),
    };
    try {
      var condition_update = await fetch(
        process.env.REACT_APP_APIEND + `smart_crop/termsheet-condition-update`,
        requestOptions
      ).then((res) => {
        return res.json();
      });
      toast.success(condition_update.msg);
    } catch (err) {
      toast.error("Something's not right");
    }
  }

  onchangefields(e) {
    // console.log("CHANGE VALUE", e.target.value);
    this.setState({
      updatedfield: e.target.value,
    });
  }

  Onchange(id, name, value) {
    this.setState({
      current_index: id,
    });
    let items = [...this.state.phase_data];
    let keys = [];
    items.map((phases) => {
      let key = Object.getOwnPropertyNames(phases);
      keys.push(key[0]);
    });
    if (items[this.state.current_index] != undefined) {
      // console.log("ONCHANGE",items[this.state.current_index][keys[id]][name])
      items[this.state.current_index][keys[this.state.current_index]][name] =
        value;
    }
    this.setState({
      phase_data: items,
    });
    // items[this.state.current_index][keys[this.state.current_index]][name] =
    //   JSON.parse(e.target.value);
  }

  updatefields() {
    if (this.state.current_head == "Update rules") {
      // 1. Make a shallow copy of the items
      let items = [...this.state.phase_data];
      // 2. Make a shallow copy of the item you want to mutate
      let keys = [];
      items.map((phases) => {
        let key = Object.getOwnPropertyNames(phases);
        keys.push(key[0]);
      });
      // console.log("KEYS",keys[this.state.current_index-1])
      // console.log(
      //   "ITEM",
      //   items[this.state.current_index][keys[this.state.current_index]].rule
      // );
      // 3. Replace the property you're intested in
      items[this.state.current_index][keys[this.state.current_index]].rule =
        JSON.parse(this.state.updatedfield);
      this.setState(
        {
          rules: items,
        },
        () => {
          // console.log("PREVIOUS PHASES", this.state.rules);
        }
      );
    } else if (this.state.current_head == "Update Previous Phases") {
      // 1. Make a shallow copy of the items
      let items = [...this.state.phase_data];
      // 2. Make a shallow copy of the item you want to mutate
      // let item = { ...items[this.state.current_index] };
      // let phase_index = '"' + this.state.current_index + 1 + '"';
      let keys = [];

      items.map((phases) => {
        let key = Object.getOwnPropertyNames(phases);
        keys.push(key[0]);
      });
      // console.log("KEYS",keys[this.state.current_index-1])
      // console.log(
      //   "ITEM",
      //   items[this.state.current_index][keys[this.state.current_index]]
      //     .previous_phases
      // );
      // 3. Replace the property you're intested in
      items[this.state.current_index][
        keys[this.state.current_index]
      ].previous_phases = JSON.parse(this.state.updatedfield);
      this.setState(
        {
          phase_data: items,
        },
        () => {
          // console.log("PREVIOUS PHASES", this.state.phase_data);
        }
      );
    } else if (this.state.current_head == "Update Conditions") {
      this.setState({
        conditions: this.state.updatedfield,
      });
      // console.log("UPdated Conditions");
    }
    this.setState({
      modal_small: false,
    });
  }
  openupdaterulesbox(e, data, Heading) {
    // console.log("EVENT LOG", e);
    this.setState({
      modal_small: true,
      current_rules: data,
      current_head: Heading,
      current_index: e,
    });
  }

  checkphase(phase) {
    if (phase == "1") {
      return "Phase 1";
    }
    if (phase == "2") {
      return "Phase 2";
    }
    if (phase == "3") {
      return "Phase 3";
    }
    if (phase == "4") {
      return "Phase 4";
    }
  }
  checknull(value){
    if(value!=null){
      return(value)
    }else{
      return("0.00")
    }
  }
  async deletecondition(id) {
    let bodydata = {
      id: id,
    };
    bodydata = JSON.stringify(bodydata);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + localStorage.getItem("token"),
      },
      body: bodydata,
    };

    var deletecondition = await fetch(
      process.env.REACT_APP_APIEND + `smart_crop/termsheet-condition-delete`,
      requestOptions
    ).then((res) => {
      return res.json();
    });
    if (deletecondition.hasOwnProperty("code") && deletecondition.code == 200) {
      toast.success("Succesfully Deleted");
    } else {
      toast.error(deletecondition.non_field_errors);
    }
  }
  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Card style={{ backgroundColor: "#e5ebee" }}>
              <CardHeader style={{ backgroundColor: "#A4C1D2" }}>
                <Row xl="12">
                  <Col>
                    <h4
                      className="card-title"
                      style={{ padding: "5px", fontSize: "18px" }}
                    >
                      {" "}
                      Edit Termsheet Conditions
                    </h4>
                  </Col>
                  <Col>
                    <Link to="termsheetconditions">
                      <i
                        className="ri-close-fill"
                        style={{ fontSize: "20px", float: "right" }}
                      ></i>
                    </Link>
                  </Col>
                </Row>
              </CardHeader>
              <Row>
                <Col xs={12}>
                  <Card>
                    <CardBody>
                      <Row style={{ width: "100%", "overflow-x": "scroll" }}>
                        {" "}
                        <Table
                          id="edit-tsconditions"
                          bordered
                          style={{ fontSize: "11px" }}
                        >
                          <thead style={{ backgroundColor: "#ffffff" }}>
                            <tr>
                              <th style={{ width: "100px" }}>Phase</th>
                              <th style={{ width: "150px" }}>From Date</th>
                              <th style={{ width: "150px" }}>To Date</th>
                              <th style={{ width: "130px" }}>
                                Phase Maximum Payout
                              </th>
                              <th style={{ width: "100px" }}>Is Daily</th>
                              <th style={{ width: "130px" }}>Is Phase</th>
                              <th style={{ width: "130px" }}>Is Peril End</th>
                              <th style={{ width: "130px" }}>
                                Previous Phases
                              </th>
                              <th style={{ width: "100px" }}>Unit Cost</th>
                              <th style={{ width: "100px" }}>Maximum Payout</th>
                              <th style={{ width: "130px" }}>Conditions</th>
                              <th style={{ width: "130px" }}>Rules</th>
                              {/* <th style={{ width: "130px" }}>Update</th> */}
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.phase_data.map((phase, item) => (
                              <tr>
                                {/* {console.log("INDEX !", phase)}
                                {console.log("INDEX", phase[item + 1].crop)} */}
                                <td>
                                  <Input
                                    placeholder="Phase"
                                    defaultValue={this.checkphase(
                                      Object.keys(phase)
                                    )}
                                    style={{ width: 100 }}
                                    id={item}
                                    name="phase"
                                    onChange={(e) =>
                                      this.Onchange(
                                        e.target.id,
                                        e.target.name,
                                        this.checkphase(Object.keys(phase))
                                      )
                                    }
                                  />
                                </td>
                                <td>
                                  <Input
                                    placeholder="From Date"
                                    defaultValue={Moment(phase[item + 1].startDate).format('DD-MM-YYYY')}
                                    style={{ width: 110 }}
                                    id={item}
                                    name="startDate"
                                    onChange={(e) =>
                                      this.Onchange(
                                        e.target.id,
                                        e.target.name,
                                        e.target.value
                                      )
                                    }
                                  />
                                </td>
                                <td>
                                  <Input
                                    placeholder="End Date"
                                    defaultValue={Moment(phase[item + 1].endDate).format('DD-MM-YYYY')}
                                    style={{ width: 110 }}
                                    id={item}
                                    name="endDate"
                                    onChange={(e) =>
                                      this.Onchange(
                                        e.target.id,
                                        e.target.name,
                                        e.target.value
                                      )
                                    }
                                  />
                                </td>
                                <td>
                                  <Input
                                    placeholder="Phase Maximum Payout"
                                    defaultValue={this.checknull(phase[item + 1].phase_maximum_payout)}
                                    style={{ width: 100 }}
                                    id={item}
                                    name="phase_maximum_payout"
                                    onChange={(e) =>
                                      this.Onchange(
                                        e.target.id,
                                        e.target.name,
                                        e.target.value
                                      )
                                    }
                                  />
                                </td>
                                <td>
                                  <Input
                                    placeholder="Is Daily"
                                    defaultValue={phase[item + 1].is_daily}
                                    style={{ width: 100 }}
                                    id={item}
                                    name="is_daily"
                                    onChange={(e) =>
                                      this.Onchange(
                                        e.target.id,
                                        e.target.name,
                                        e.target.value
                                      )
                                    }
                                  />
                                </td>
                                <td>
                                  <Input
                                    placeholder="Is Phase"
                                    defaultValue={phase[item + 1].is_phase}
                                    style={{ width: 100 }}
                                    id={item}
                                    name="is_phase"
                                    onChange={(e) =>
                                      this.Onchange(
                                        e.target.id,
                                        e.target.name,
                                        e.target.value
                                      )
                                    }
                                  />
                                </td>
                                <td className="alnrighttd">
                                  <Input
                                    placeholder="Is Peril End"
                                    defaultValue={
                                      phase[item + 1].is_peril_phase_end
                                    }
                                    style={{ width: 100 }}
                                    id={item}
                                    name="is_peril_phase_end"
                                    onChange={(e) =>
                                      this.Onchange(
                                        e.target.id,
                                        e.target.name,
                                        e.target.value
                                      )
                                    }
                                  />
                                </td>
                                <td className="alnrighttd">
                                  <Link
                                    to="#"
                                    key={item}
                                    id={item}
                                    onClick={(e) =>
                                      this.openupdaterulesbox(
                                        e.target.id,
                                        phase[item + 1].previous_phases,
                                        "Update Previous Phases"
                                      )
                                    }
                                  >
                                    Update Previous Phases
                                  </Link>
                                </td>
                                <td className="alnrighttd">
                                  <Input
                                    placeholder="Unit Cost"
                                    defaultValue={this.checknull(phase[item + 1].unit_cost)}
                                    style={{ width: 100 }}
                                    id={item}
                                    name="unit_cost"
                                    onChange={(e) =>
                                      this.Onchange(
                                        e.target.id,
                                        e.target.name,
                                        e.target.value
                                      )
                                    }
                                  />
                                </td>
                                <td className="alnrighttd">
                                  <Input
                                    id={item}
                                    placeholder="Maximum Payout"
                                    defaultValue={this.checknull(phase[item + 1].max_payout)}
                                    style={{ width: 100 }}
                                    name="max_payout"
                                    onChange={(e) =>
                                      this.Onchange(
                                        e.target.id,
                                        e.target.name,
                                        e.target.value
                                      )
                                    }
                                  />
                                </td>
                                <td>
                                  <Link
                                    to="#"
                                    key={item}
                                    id={item}
                                    onClick={(e) =>
                                      this.openupdaterulesbox(
                                        e.target.id,
                                        phase[item + 1].condition,
                                        "Update Conditions"
                                      )
                                    }
                                  >
                                    Update Condition
                                  </Link>
                                </td>
                                <td>
                                  <Link
                                    to="#"
                                    key={item}
                                    id={item}
                                    onClick={(e) =>
                                      this.openupdaterulesbox(
                                        e.target.id,
                                        phase[item + 1].rule,
                                        "Update rules"
                                      )
                                    }
                                  >
                                    Update rules
                                  </Link>
                                </td>
                                <td>
                                  <Button
                                    id={item}
                                    className="btn btn-success"
                                    style={{ "font-size": "10px" }}
                                    onClick={(e) =>
                                      this.updateconditions(e.target.id)
                                    }
                                  >
                                    Update Termsheet
                                  </Button>
                                </td>
                                {/* <td>
                                  <Button
                                    className="btn btn-danger"
                                    style={{ "font-size": "10px" }}
                                    onClick={() =>this.deletecondition(phase[item + 1].id)}
                                  >
                                    Delete Condition
                                  </Button>
                                </td> */}
                                {/* <td>
                                <Button
                                  type=""
                                  color="primary"
                                  //   onClick={this.confirmUserDelete}
                                >
                                  Update
                                </Button>
                              </td> */}
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Card>

            <Modal
              size="sm"
              isOpen={this.state.modal_small}
              toggle={this.tog_small}
              backdrop="static"
            >
              <ModalHeader
                toggle={() => this.setState({ modal_small: false })}
                style={{ border: "none", backgroundColor: "#A4C1D2" }}
              >
                {this.state.current_head}
              </ModalHeader>
              <ModalBody
                style={{
                  maxHeight: "calc(100vh - 210px)",
                  overflowY: "auto",
                }}
              >
                <TextArea
                  defaultValue={JSON.stringify(
                    this.state.current_rules,
                    null,
                    2
                  )}
                  onChange={this.onchangefields}
                  rows={15}
                ></TextArea>
              </ModalBody>
              <ModalFooter style={{ border: "none" }}>
                <Button
                  type="button"
                  color="light"
                  onClick={() => this.setState({ modal_small: false })}
                >
                  Cancel
                </Button>
                <Button type="" color="primary" onClick={this.updatefields}>
                  Update
                </Button>
              </ModalFooter>
            </Modal>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
