import React, { Component } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

//i18n
import i18n from "../../../i18n";
import { withNamespaces } from "react-i18next";

// // falgs
import usFlag from "../../../assets/us.jpg";
import inFlag from "../../../assets/india.png";
// import spain from "../../../assets/images/flags/spain.jpg";
// import germany from "../../../assets/images/flags/germany.jpg";
// import italy from "../../../assets/images/flags/italy.jpg";
// import russia from "../../../assets/images/flags/russia.jpg";
import Permission from "../../../permission";

class LanguageDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: false,
      lng: "",
      flag: usFlag,
    };
    this.toggle = this.toggle.bind(this);
    this.changeLanguageAction.bind(this);
    this.malayalamLang.bind(this);
  }

  toggle() {
    this.setState((prevState) => ({
      menu: !prevState.menu,
    }));
  }
  malayalamLang = (lng) => {
    i18n.changeLanguage(lng);
    this.setState({ lng: "മലയാളം", flag: inFlag });
    localStorage.setItem("LN", "മലയാളം");
  }
  englishLang = (lng) => {
    i18n.changeLanguage(lng);
    this.setState({ lng: "English", flag: inFlag });
    localStorage.setItem("LN", "English");
  }
  changeLanguageAction = (lng) => {
    //set the selected language to i18n
    i18n.changeLanguage(lng);

    // if (lng === "sp") this.setState({ lng: "Spanish" });
    if (lng === "ml") {
      this.setState({ lng: "മലയാളം", flag: inFlag });
      localStorage.setItem("LN", "മലയാളം");
    }
    else if (lng === "eng") {
      this.setState({ lng: "English", flag: usFlag });
      localStorage.setItem("LN", "English");
    }
  };
  componentDidMount(){
   if(localStorage.getItem("LN") == "മലയാളം"){
    this.malayalamLang("ml");
  }
}
  render() {
    return (
      <React.Fragment>
        <Dropdown
          isOpen={this.state.menu}
          toggle={this.toggle}
          className="d-sm-inline-block"
          active="ML"
        >
          <DropdownToggle tag="button" className="btn header-item waves-effect">
            {"  "}
            <span className="align-middle">{localStorage.getItem("LN")}</span>
          </DropdownToggle>

          <DropdownMenu right>
            <DropdownItem
              // active={this.state.lng === "English" ? true : false}
              eventKey="EN"
              onClick={() => this.changeLanguageAction("eng")}
              className="notify-item"
            >
              <span className="align-middle">English</span>
            </DropdownItem>

            <DropdownItem
              // active={this.state.lng === "മലയാളം" ? true : false}
              onClick={() => this.changeLanguageAction("ml")}
              eventKey="ML"
              className="notify-item"
              
            >
              <span className="align-middle">മലയാളം</span>
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </React.Fragment>
    );
  }
}

export default withNamespaces()(LanguageDropdown);
