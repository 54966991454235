import React, { Component } from "react";
import {
  Card,
  CardBody,
  Row,
  Col,
  Input,
  CardText,
  FormGroup,
  NavLink,
  NavItem,
  Nav,
  Label,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container,
  Button,
  Spinner,
} from "reactstrap";
// import './table.css'
import { MDBDataTable } from "mdbreact";
import "antd/dist/antd.css";
import { Link } from "react-router-dom";
import moment from "moment";
import Permission from "../../permission.js";
import { DatePicker, Space } from "antd";
import { withNamespaces } from "react-i18next";
//import css
import "../../assets/scss/tab.css";

const { RangePicker } = DatePicker;

class Fm_Alert extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fmcrop: "all",
      rua: "",
      fmseason: "all",
      fromDate: "",
      toDate: "",
      alertData: [],
      showWaiting: true,
      showloader: true,
      showtable: false,
      seasonData: [],
      searchButton: false,
      perilseasonData: [],
      allseasonData: [],
      currentDate: "",
      fmalertType: "all",
      fmperil: "Deficit Rainfall",
      perilData: [],
      alert_type: "A2",
      cropData: ["Paddy", "Veg Group I"],
    };
    this.onChangedate = this.onChangedate.bind(this);
    this.getalert = this.getalert.bind(this);
    this.onchange = this.onchange.bind(this);
    this.handlePerilchange = this.handlePerilchange.bind(this);
    this.handleCropchange = this.handleCropchange.bind(this);
    this.handleseasonchange = this.handleseasonchange.bind(this);
  }
  onchange(ev) {
    this.setState({
      [ev.target.name]: ev.target.value,
    });
  }
  handleCropchange(e) {
    this.setState({ fmcrop: e.target.value });
    if (this.state.fmseason.slice(0, 3) == "Kha" && e.target.value == "Paddy") {
      this.setState({
        perilData: [
          "High Temperature",
          "Deficit Rainfall",
          "Excess Rainfall",
          "Dry Spell",
          "Disease Congenial Climate",
        ],
        fmperil: "High Temperature",
      });
    }
    ////////////////////////////////
    if (this.state.fmseason == "Kharif 2020" && e.target.value == "Paddy") {
      this.setState({
        perilData: [
          "High Temperature",
          "Deficit Rainfall",
          "Excess Rainfall",
          "Dry Spell",
          "Disease Congenial Climate",
        ],
        fmperil: "High Temperature",
      });
    }
    if (
      this.state.fmseason == "Kharif 2020" &&
      e.target.value == "Veg Group I"
    ) {
      this.setState({
        perilData: [
          "Rise in Temperature",
          "Excess Rainfall",
          "Disease Congenial Climate",
        ],
        fmperil: "Rise in Temperature",
      });
    }
    if (this.state.fmseason == "Kharif 2021" && e.target.value == "Paddy") {
      this.setState({
        perilData: [
          "High Temperature",
          "Deficit Rainfall",
          "Excess Rainfall",
          "Dry Spell",
          "Disease Congenial Climate",
        ],
        fmperil: "High Temperature",
      });
    }
    if (
      this.state.fmseason == "Kharif 2021" &&
      e.target.value == "Veg Group I"
    ) {
      this.setState({
        perilData: [
          "Rise in Temperature",
          "Excess Rainfall",
          "Disease Congenial Climate",
        ],
        fmperil: "Rise in Temperature",
      });
    }
    if (this.state.fmseason == "Kharif 2022" && e.target.value == "Paddy") {
      this.setState({
        perilData: [
          "High Temperature",
          "Deficit Rainfall",
          "Excess Rainfall",
          "Dry Spell",
          "Disease Congenial Climate",
        ],
        fmperil: "High Temperature",
      });
    }
    if (
      this.state.fmseason == "Kharif 2022" &&
      e.target.value == "Veg Group I"
    ) {
      this.setState({
        perilData: [
          "Rise in Temperature",
          "Excess Rainfall",
          "Disease Congenial Climate",
        ],
        fmperil: "Rise in Temperature",
      });
    }
    if (this.state.fmseason.slice(0, 3) == "Rab" && e.target.value == "Mango") {
      this.setState({
        perilData: [
          "Excess Rainfall",
          "Wind Speed",
          "Disease Congenial Climate",
        ],
        fmperil: "Excess Rainfall",
      });
    }
    if (
      this.state.fmseason.slice(0, 3) == "Rab" &&
      e.target.value == "Paddy 2nd Crop"
    ) {
      this.setState({
        perilData: [
          "Excess Rainfall",
          "Wind Speed (A)",
          "Wind Speed (B)",
          "Disease Congenial Climate",
          "Deficit Rainfall",
          "Unseasonal Rainfall",
        ],
        fmperil: "Excess Rainfall",
      });
    }
    //  ===============================
    if (this.state.fmseason.slice(0, 3) == "Rab" && e.target.value == "Mango") {
      this.setState({
        perilData: [
          "Excess Rainfall",
          "Wind Speed",
          "Disease Congenial Climate",
        ],
        fmperil: "Excess Rainfall",
      });
    }
    if (
      this.state.fmseason.slice(0, 3) == "Rab" &&
      e.target.value == "Paddy 2nd Crop"
    ) {
      this.setState({
        perilData: [
          "Excess Rainfall",
          "Wind Speed (A)",
          "Wind Speed (B)",
          "Disease Congenial Climate",
          "Deficit Rainfall",
          "Unseasonal Rainfall",
        ],
        fmperil: "Excess Rainfall",
      });
    }
    if (
      this.state.fmseason.slice(0, 3) == "Rab" &&
      e.target.value == "Paddy 3rd Crop"
    ) {
      this.setState({
        perilData: [
          "Excess Rainfall",
          "High Temperature",
          "Disease Congenial Climate",
          "Deficit Rainfall",
        ],
        fmperil: "Excess Rainfall",
      });
    }
    if (
      this.state.fmseason.slice(0, 3) == "Rab" &&
      e.target.value == "Paddy Winter"
    ) {
      this.setState({
        perilData: [
          "Excess Rainfall",
          "High Temperature",
          "Disease Congenial Climate",
          "Deficit Rainfall",
        ],
        fmperil: "Excess Rainfall",
      });
    }
    if (
      this.state.fmseason.slice(0, 3) == "Rab" &&
      e.target.value == "Veg Group I"
    ) {
      this.setState({
        perilData: [
          "Excess Rainfall",
          "Rise in Temperature",
          "Disease Congenial Climate",
        ],
        fmperil: "Excess Rainfall",
      });
    }
    if (
      this.state.fmseason.slice(0, 3) == "Rab" &&
      e.target.value == "Veg Group I - SMR"
    ) {
      this.setState({
        perilData: [
          "Excess Rainfall",
          "Deficit Rainfall",
          "Rise in Temperature",
          "Disease Congenial Climate",
        ],
        fmperil: "Excess Rainfall",
      });
    }
    if (this.state.fmseason.slice(0, 3) == "Rab" && e.target.value == "Mango") {
      this.setState({
        perilData: [
          "Excess Rainfall",
          "Wind Speed",
          "Disease Congenial Climate",
        ],
        fmperil: "Excess Rainfall",
      });
    }
    if (
      this.state.fmseason.slice(0, 3) == "Rab" &&
      e.target.value == "Paddy 2nd Crop"
    ) {
      this.setState({
        perilData: [
          "Excess Rainfall",
          "Wind Speed (A)",
          "Wind Speed (B)",
          "Disease Congenial Climate",
          "Deficit Rainfall",
          "Unseasonal Rainfall",
        ],
        fmperil: "Excess Rainfall",
      });
    }
  }
  disabledDate(current) {
    // Can not select days before today and today
    return current && current > moment().endOf("day");
  }
  onChangedate(dateString) {
    const date1 = new Date(dateString[0]);
    const date2 = new Date(dateString[1]);
    var from_dd = String(date1.getDate()).padStart(2, "0");
    var from_mm = String(date1.getMonth() + 1).padStart(2, "0"); //January is 0!
    var from_yyyy = date1.getFullYear();
    var from_date = from_yyyy + "-" + from_mm + "-" + from_dd;

    var to_dd = String(date2.getDate()).padStart(2, "0");
    var to_mm = String(date2.getMonth() + 1).padStart(2, "0"); //January is 0!
    var to_yyyy = date2.getFullYear();
    var to_date = to_yyyy + "-" + to_mm + "-" + to_dd;

    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    this.setState({
      fromDate: from_date,
      toDate: to_date,
      searchButton: true,
    });
  }

  async getalert() {
    this.setState({
      showloader: false,
      showWaiting: false,
      showtable: false,
    });
    let bodydata = {
      farmer_id: localStorage.getItem("farmer_id"),
      season: this.state.fmseason,
      from_date: "2020-01-01",
      // from_date: this.state.fromDate,
      to_date: this.state.currentDate,
      // to_date: this.state.toDate,
      crop: this.state.fmcrop,
      rua: localStorage.getItem("krishibhavan"),
      alert_type: this.state.fmalertType,
      peril: this.state.fmperil,
    };

    bodydata = JSON.stringify(bodydata);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token"),
      },
      body: bodydata,
    };

    var alertresponse = await fetch(
      process.env.REACT_APP_APIEND + `farmer/alert-notification-farmer`,
      requestOptions
    ).then((res) => {
      return res.json();
    });

    this.generatetabledata(alertresponse.data);
  }
  checkType(type) {
    if (type == "A2") {
      return "Day End";
    } else {
      return "Payout Alert";
    }
  }
  generatetabledata(data) {
    let getthis = this;
    let tabledata = [];
    data.map(function (item, index, data) {
      var newdata = {
        alertID: item.alertID,
        rua: item.rua,
        season: item.season,
        crop: item.crop,
        peril: item.peril,
        phase: item.phase,
        date: moment(item.dateTime.slice(0, 10)).format("DD-MM-YYYY"),
        // time: (item.dateTime).slice(11, 14) +"00",
        time: "23:59",
        // alerttype: item.alertType,
        alerttype: item.alert_type,
        alertmessage: getthis.getmessage(item),
        payout: item.payout,
      };
      tabledata.push(newdata);
    });
    this.setState({
      alertData: tabledata,
      showloader: true,
      showtable: true,
    });
  }
  getmessage(alert) {
    // return alert.alertMessage;
    if (alert.peril == "Dry Spell") {
      return (
        "The " +
        alert.parameter[0] +
        " is " +
        alert.calculated_value +
        " mm (Trigger : <2.5 mm)"
      );
    } else if (alert.peril == "Disease Congenial Climate") {
      if (alert.calculated_value2 != null) {
        // if(alert.season=="Rabi 2020-21" &&alert.crop== )
        var temp;

        if (alert.trigger[1] > 50) {
          temp = alert.trigger[0];
        } else {
          temp = alert.trigger[1];
        }
        return (
          "The daily average temperature  is " +
          alert.calculated_value2 +
          "°C & the daily average humidity is " +
          alert.calculated_value +
          " % (Avg. Temperature > 32 °C; Rel. Humidity between 80% & 90%)"
        );
      } else {
        return alert.alertMessage;
      }
    } else {
      return alert.alertMessage;
    }
    // if (alert.peril == "Dry Spell") {
    //   return (
    //     "The " +
    //     alert.parameter[0] +
    //     " is " +
    //     alert.calculated_value +
    //     " mm (Trigger : <2.5 mm)"
    //   );
    // } else if (alert.peril == "Disease Congenial Climate") {
    //   if (alert.calculated_value2 != null) {
    //     // if(alert.season=="Rabi 2020-21" &&alert.crop== )
    //     var temp;

    //     if (alert.trigger[1] > 50) {
    //       temp = alert.trigger[0];
    //     } else {
    //       temp = alert.trigger[1];
    //     }
    //     return (
    //       "The daily average temperature is " +
    //       alert.calculated_value2 +
    //       "°C & the daily average humidity is " +
    //       alert.calculated_value +
    //       " % (Avg. Temperature > 32 °C; Rel. Humidity between 80% & 90%)"
    //     );
    //   } else {
    //     return alert.alertMessage;
    //   }
    // } else {
    //   return alert.alertMessage;
    // }
  }
  async getPerilSeason() {
    let searchdata = {
      farmer_id: localStorage.getItem("farmer_id"),
    };
    searchdata = JSON.stringify(searchdata);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token"),
      },
      body: searchdata,
    };
    var SeasonPeril = await fetch(
      process.env.REACT_APP_APIEND + `farmer/farmer-crops-list`,
      requestOptions
    ).then((res) => {
      return res.json();
    });

    var seasons = [];
    var crops = [];
    SeasonPeril.result.map(function (item, index, data) {
      seasons.push(Object.keys(item));
    });
    SeasonPeril.result[0][seasons[0]].map(function (cropslist) {
      crops.push(cropslist.crop);
    });
    // SeasonPeril.result.map(function (item, index, data) {
    //   seasons.push(Object.keys(item));
    //   SeasonPeril.result[index][Object.keys(item)].map(function (cropslist) {
    //     if (crops.includes(cropslist.crop) == false) {
    //       crops.push(cropslist.crop);
    //     }
    //   });
    // });
    this.setState(
      {
        seasonData: seasons,
        cropData: crops,
        fmseason: seasons[0][0],
        fmcrop: crops[0],
        allseasonData: SeasonPeril.result,
      },
      () => {
    
        if (seasons[0][0] == "Kharif 2020" &&  crops[0]== "Paddy") {
          this.setState({
            perilData: [
              "High Temperature",
              "Deficit Rainfall",
              "Excess Rainfall",
              "Dry Spell",
              "Disease Congenial Climate",
            ],
            fmperil: "High Temperature",
          });
        }
        if (
          seasons[0][0] == "Kharif 2020" &&
          crops[0] == "Veg Group I"
        ) {
          this.setState({
            perilData: [
              "Rise in Temperature",
              "Excess Rainfall",
              "Disease Congenial Climate",
            ],
            fmperil: "Rise in Temperature",
          });
        }
        if (seasons[0][0] == "Kharif 2021" &&  crops[0] == "Paddy") {
          this.setState({
            perilData: [
              "High Temperature",
              "Deficit Rainfall",
              "Excess Rainfall",
              "Dry Spell",
              "Disease Congenial Climate",
            ],
            fmperil: "High Temperature",
          });
        }
        if (
          seasons[0][0] == "Kharif 2021" &&
          crops[0] == "Veg Group I"
        ) {
          this.setState({
            perilData: [
              "Rise in Temperature",
              "Excess Rainfall",
              "Disease Congenial Climate",
            ],
            fmperil: "Rise in Temperature",
          });
        } else if (seasons[0][0] == "Rabi 2020-21") {
          this.setState({
            dpData: ["DarkSky"],
            fmperil: "DarkSky",
          });
        }
        if (seasons[0][0] == "Kharif 2022" &&  crops[0] == "Paddy") {
          this.setState({
            perilData: [
              "High Temperature",
              "Deficit Rainfall",
              "Excess Rainfall",
              "Dry Spell",
              "Disease Congenial Climate",
            ],
            fmperil: "High Temperature",
          });
        }
        if (
          seasons[0][0] == "Kharif 2022" &&
          crops[0] == "Veg Group I"
        ) {
          this.setState({
            perilData: [
              "Rise in Temperature",
              "Excess Rainfall",
              "Disease Congenial Climate",
            ],
            fmperil: "Rise in Temperature",
          });
        }

        if (
          seasons[0][0].slice(0, 3) == "Rab" &&
          crops[0] == "Mango"
        ) {
          this.setState({
            perilData: [
              "Excess Rainfall",
              "Wind Speed",
              "Disease Congenial Climate",
            ],
            fmperil: "Excess Rainfall",
          });
        }
        if (
          seasons[0][0].slice(0, 3) == "Rab" &&
          crops[0] == "Paddy 2nd Crop"
        ) {
          this.setState({
            perilData: [
              "Excess Rainfall",
              "Wind Speed (A)",
              "Wind Speed (B)",
              "Disease Congenial Climate",
              "Deficit Rainfall",
              "Unseasonal Rainfall",
            ],
            fmperil: "Excess Rainfall",
          });
        }
        if (
          seasons[0][0].slice(0, 3) == "Rab" &&
          crops[0]== "Paddy 3rd Crop"
        ) {
          this.setState({
            perilData: [
              "Excess Rainfall",
              "High Temperature",
              "Disease Congenial Climate",
              "Deficit Rainfall",
            ],
            fmperil: "Excess Rainfall",
          });
        }
        if (
          seasons[0][0].slice(0, 3) == "Rab" &&
          crops[0] == "Paddy Winter"
        ) {
          this.setState({
            perilData: [
              "Excess Rainfall",
              "High Temperature",
              "Disease Congenial Climate",
              "Deficit Rainfall",
            ],
            fmperil: "Excess Rainfall",
          });
        }
        if (
          seasons[0][0].slice(0, 3) == "Rab" &&
          crops[0] == "Veg Group I"
        ) {
          this.setState({
            perilData: [
              "Excess Rainfall",
              "Rise in Temperature",
              "Disease Congenial Climate",
            ],
            fmperil: "Excess Rainfall",
          });
        }
        if (
          seasons[0][0].slice(0, 3) == "Rab" &&
          crops[0] == "Veg Group I - SMR"
        ) {
          this.setState({
            perilData: [
              "Excess Rainfall",
              "Deficit Rainfall",
              "Rise in Temperature",
              "Disease Congenial Climate",
            ],
            fmperil: "Excess Rainfall",
          });
        }
        if (
          seasons[0][0].slice(0, 3) == "Rab" &&
          crops[0] == "Mango"
        ) {
          this.setState({
            perilData: [
              "Excess Rainfall",
              "Wind Speed",
              "Disease Congenial Climate",
            ],
            fmperil: "Excess Rainfall",
          });
        }
        if (
          seasons[0][0].slice(0, 3) == "Rab" &&
          crops[0] == "Paddy 2nd Crop"
        ) {
          this.setState({
            perilData: [
              "Excess Rainfall",
              "Wind Speed (A)",
              "Wind Speed (B)",
              "Disease Congenial Climate",
              "Deficit Rainfall",
              "Unseasonal Rainfall",
            ],
            fmperil: "Excess Rainfall",
          });
        }
      }
    );
  }

  handlePerilchange(e) {
    this.setState({
      fmperil: e.target.value,
    });
  }
  componentDidMount() {
    this.getPerilSeason();
    var date = new Date();
    var to_dd = String(date.getDate()).padStart(2, "0");
    var to_mm = String(date.getMonth() + 1).padStart(2, "0"); //January is 0!
    var to_yyyy = date.getFullYear();
    var to_date = to_yyyy + "-" + to_mm + "-" + to_dd;
    this.setState({
      currentDate: to_date,
    });
  }
  handleseasonchange(e) {
    this.setState({ fmseason: e.target.value });
    let newcropData = [];
    this.state.allseasonData.map(function (seasons, index, data) {
      var currentSeason = Object.keys(seasons);
      if (currentSeason[0] == e.target.value) {
        seasons[currentSeason[0]].map(function (crops) {
          newcropData.push(crops.crop);
        });
      }
    });
    this.setState(
      {
        cropData: newcropData,
        fmcrop: newcropData[0],
      },
      () => {
        if (e.target.value == "Kharif 2020" && this.state.fmcrop == "Paddy") {
          this.setState({
            perilData: [
              "High Temperature",
              "Deficit Rainfall",
              "Excess Rainfall",
              "Dry Spell",
              "Disease Congenial Climate",
            ],
            fmperil: "High Temperature",
          });
        }
        if (
          e.target.value == "Kharif 2020" &&
          this.state.fmcrop == "Veg Group I"
        ) {
          this.setState({
            perilData: [
              "Rise in Temperature",
              "Excess Rainfall",
              "Disease Congenial Climate",
            ],
            fmperil: "Rise in Temperature",
          });
        }
        if (e.target.value == "Kharif 2021" && this.state.fmcrop == "Paddy") {
          this.setState({
            perilData: [
              "High Temperature",
              "Deficit Rainfall",
              "Excess Rainfall",
              "Dry Spell",
              "Disease Congenial Climate",
            ],
            fmperil: "High Temperature",
          });
        }
        if (
          e.target.value == "Kharif 2021" &&
          this.state.fmcrop == "Veg Group I"
        ) {
          this.setState({
            perilData: [
              "Rise in Temperature",
              "Excess Rainfall",
              "Disease Congenial Climate",
            ],
            pefmperilril: "Rise in Temperature",
          });
        } else if (e.target.value == "Rabi 2020-21") {
          this.setState({
            dpData: ["DarkSky"],
            fmperil: "DarkSky",
          });
        }
        if (e.target.value == "Kharif 2022" && this.state.fmcrop == "Paddy") {
          this.setState({
            perilData: [
              "High Temperature",
              "Deficit Rainfall",
              "Excess Rainfall",
              "Dry Spell",
              "Disease Congenial Climate",
            ],
            fmperil: "High Temperature",
          });
        }
        if (
          e.target.value == "Kharif 2022" &&
          this.state.fmcrop == "Veg Group I"
        ) {
          this.setState({
            perilData: [
              "Rise in Temperature",
              "Excess Rainfall",
              "Disease Congenial Climate",
            ],
            fmperil: "Rise in Temperature",
          });
        }

        if (
          e.target.value.slice(0, 3) == "Rab" &&
          this.state.fmcrop == "Mango"
        ) {
          this.setState({
            perilData: [
              "Excess Rainfall",
              "Wind Speed",
              "Disease Congenial Climate",
            ],
            fmperil: "Excess Rainfall",
          });
        }
        if (
          e.target.value.slice(0, 3) == "Rab" &&
          this.state.fmcrop == "Paddy 2nd Crop"
        ) {
          this.setState({
            perilData: [
              "Excess Rainfall",
              "Wind Speed (A)",
              "Wind Speed (B)",
              "Disease Congenial Climate",
              "Deficit Rainfall",
              "Unseasonal Rainfall",
            ],
            fmperil: "Excess Rainfall",
          });
        }
        if (
          e.target.value.slice(0, 3) == "Rab" &&
          this.state.fmcrop == "Paddy 3rd Crop"
        ) {
          this.setState({
            perilData: [
              "Excess Rainfall",
              "High Temperature",
              "Disease Congenial Climate",
              "Deficit Rainfall",
            ],
            fmperil: "Excess Rainfall",
          });
        }
        if (
          e.target.value.slice(0, 3) == "Rab" &&
          this.state.fmcrop == "Paddy Winter"
        ) {
          this.setState({
            perilData: [
              "Excess Rainfall",
              "High Temperature",
              "Disease Congenial Climate",
              "Deficit Rainfall",
            ],
            fmperil: "Excess Rainfall",
          });
        }
        if (
          e.target.value.slice(0, 3) == "Rab" &&
          this.state.fmcrop == "Veg Group I"
        ) {
          this.setState({
            perilData: [
              "Excess Rainfall",
              "Rise in Temperature",
              "Disease Congenial Climate",
            ],
            fmperil: "Excess Rainfall",
          });
        }
        if (
          e.target.value.slice(0, 3) == "Rab" &&
          this.state.fmcrop == "Veg Group I - SMR"
        ) {
          this.setState({
            perilData: [
              "Excess Rainfall",
              "Deficit Rainfall",
              "Rise in Temperature",
              "Disease Congenial Climate",
            ],
            fmperil: "Excess Rainfall",
          });
        }
        if (
          e.target.value.slice(0, 3) == "Rab" &&
          this.state.fmcrop == "Mango"
        ) {
          this.setState({
            perilData: [
              "Excess Rainfall",
              "Wind Speed",
              "Disease Congenial Climate",
            ],
            fmperil: "Excess Rainfall",
          });
        }
        if (
          e.target.value.slice(0, 3) == "Rab" &&
          this.state.fmcrop == "Paddy 2nd Crop"
        ) {
          this.setState({
            perilData: [
              "Excess Rainfall",
              "Wind Speed (A)",
              "Wind Speed (B)",
              "Disease Congenial Climate",
              "Deficit Rainfall",
              "Unseasonal Rainfall",
            ],
            fmperil: "Excess Rainfall",
          });
        }
      }
    );
  }
  render() {
    const { t, i18n } = this.props;
    let data = {
      columns: [
        {
          label: "Alert ID",
          field: "alertID",
          width: 100,
        },
        {
          label: "RUA",
          field: "rua",
          width: 100,
        },
        {
          label: "Season",
          field: "season",
          width: 100,
        },
        {
          label: "Crop",
          field: "crop",
          width: 100,
        },
        {
          label: "Phase",
          field: "phase",
          width: 100,
        },
        {
          label: "Date",
          field: "date",
          width: 100,
          sort:"disabled"
        },
        {
          label: "Time",
          field: "time",
          width: 100,
        },
        {
          label: "Alert Type",
          field: "alerttype",
          width: 100,
        },
        {
          label: "Peril",
          field: "peril",
          width: 100,
        },
        {
          label: "Alert Message",
          field: "alertmessage",
          width: 300,
        },
        // {
        //   label: "Payout",
        //   field: "payout",
        // },
      ],
      rows: this.state.alertData,
    };
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Row>
              <Col xs={12}>
                <h4
                  className="card-title"
                  style={{ padding: "5px", fontSize: "18px" }}
                >
                  {" "}
                  {t("Alerts")}
                </h4>
              </Col>
            </Row>
            <Row>
              <Col>
                <Card style={{ backgroundColor: "#f1f6fb" }}>
                  <CardBody>
                    <Col>
                      <Row>
                        <Col md="2">
                          <Label
                            className=" col-sm col-form-label"
                            style={{ fontSize: "11px" }}
                          >
                            {t("Season")}
                          </Label>
                          <select
                            className="form-select"
                            style={{ fontSize: "11px" }}
                            name="fmseason"
                            value={this.state.fmseason}
                            onChange={this.handleseasonchange}
                          >
                            {this.state.seasonData.length > 0 &&
                              this.state.seasonData.map((item) => (
                                <option value={item} key={item}>
                                  {item}
                                </option>
                              ))}
                          </select>
                        </Col>
                        <Col md="2">
                          <Label
                            className=" col-sm col-form-label"
                            style={{ fontSize: "11px" }}
                          >
                            {t("Crop")}
                          </Label>
                          <select
                            className="form-select"
                            style={{ fontSize: "11px" }}
                            name="fmcrop"
                            value={this.state.fmcrop}
                            onChange={this.handleCropchange}
                          >
                            {this.state.cropData.length > 0 &&
                              this.state.cropData.map((item) => (
                                <option value={item} key={item}>
                                  {item}
                                </option>
                              ))}
                            <option value={"all"} key={"all"}>
                              All
                            </option>
                          </select>
                        </Col>
                        <Col md="2">
                          <Label
                            className=" col-sm col-form-label"
                            style={{ fontSize: "11px" }}
                          >
                            {t("Peril")}
                          </Label>
                          <select
                            className="form-select"
                            style={{ fontSize: "11px" }}
                            name="fmperil"
                            value={this.state.fmperil}
                            onChange={this.onchange}
                          >
                            {this.state.perilData.length > 0 &&
                              this.state.perilData.map((item) => (
                                <option value={item} key={item}>
                                  {item}
                                </option>
                              ))}
                            <option value={"all"} key={"all"}>
                              All
                            </option>
                          </select>
                        </Col>
                        <Col md="2">
                          <Label
                            className=" col-sm col-form-label"
                            style={{ fontSize: "11px" }}
                          >
                            {t("Alert Type")}
                          </Label>
                          <select
                            className="form-select"
                            style={{ fontSize: "11px" }}
                            onChange={this.onchange}
                            name="fmalertType"
                          >
                            <option value={"all"} key={"all"}>
                              All
                            </option>
                            <option value={"A2"} key={"Day End"}>
                              Day End
                            </option>
                            <option value={"A3"} key={"Payout Alert"}>
                              Payout Alert
                            </option>
                          </select>
                        </Col>
                        {/* <Col md="4">
                          <Label
                            className=" col-sm col-form-label"
                            style={{ fontSize: "11px" }}
                          >
                            {t("Date range")}
                          </Label>
                          <RangePicker
                            onChange={this.onChangedate}
                            disabledDate={this.disabledDate}
                            format="DD-MM-YYYY"
                          />
                        </Col> */}
                      </Row>
                      <Row>
                        <Col md="12">
                          <FormGroup>
                            <Label
                              className="col-md-12 col-form-label"
                              style={{ fontSize: "11px" }}
                            >
                              <span>&nbsp;</span>
                            </Label>
                            <Button
                              color="primary"
                              className="waves-effect waves-light mr-1 btn-alert"
                              style={{
                                fontSize: "11px",
                                // float: "right",
                              }}
                              // disabled={
                              //   this.state.searchButton == true ? false : true
                              // }
                              onClick={this.getalert}
                            >
                              {t("Search")}
                            </Button>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <center>
                      <p
                        style={
                          this.state.showWaiting == true
                            ? {}
                            : { display: "none" }
                        }
                      >
                        {t("Waiting for search")}
                      </p>

                      <Spinner
                        style={
                          this.state.showloader == true
                            ? { display: "none" }
                            : {}
                        }
                      ></Spinner>
                    </center>
                    <div
                      style={this.state.showtable ? {} : { display: "none" }}
                      id="alert-table"
                    >
                      <MDBDataTable
                        paging={true}
                        showPaginationBottom={false}
                        bordered
                        data={data}
                        scrollX
                        order={["id", "asc"]}
                        className="datadownloadtable"
                        style={{ fontSize: "12px" }}
                        disableRetreatAfterSorting={true}
                        maxHeight="300px"
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default withNamespaces()(Fm_Alert);
