export function kelvintoDegreecelcius(k) {
    let degree=k - 273.15
    
    return (degree.toFixed(2))
}

export function fahrenheittoDegreecelcius(f) {
    let degree=(f - 32) * 5 / 9
    return (degree.toFixed(2))
}

export function keyCheck(a) {
    if (a.hasOwnProperty('rainfall')) {
        return (a.Rainfall)
    } else {
        return (0.00)
    }

}
export function tofix(n){
    var fixed = n.toFixed(2);
    return(n);

}