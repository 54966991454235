import React, { Component } from "react";
import { Container } from "reactstrap";
import { Card, CardBody, Row, Col } from "reactstrap";
import MapCustomControl from "../Adm-components/React-control/control.tsx"
import Permission from "../../permission.js";
import latlngTostationname from "../../common-functions"
import getRuaObject from "../../rua_object";
import getkozhinjampara from "./rua_boundary/kozhinjampara";
import getchittoor from "./rua_boundary/chittur";
import geteruthenpathy from "./rua_boundary/eruthenpathy";
import getnallepilly from "./rua_boundary/nallepilly"
import getpattanchery from "./rua_boundary/pattanchery"
import getperumatty from "./rua_boundary/perumatty"
import getvadakarapathy from "./rua_boundary/vadakarapathy"
import {
  MapContainer,
  TileLayer,
  GeoJSON,
  Popup,
  CircleMarker,
  Tooltip,
  SVGOverlay,
} from "react-leaflet";
import CurrentTable from "./CurrentTable.jsx";
import SiderHeader from "./SiderHeader.jsx";
import axios from "axios";
import {
  kelvintoDegreecelcius,
  fahrenheittoDegreecelcius,
  keyCheck,
} from "./utils";
// import '.../assets/scss/weather.css';
import $ from "jquery";
import L, { Label } from "leaflet";
import "./leaflet.css"
import { withNamespaces } from 'react-i18next';


const bounds = [
  [10.6280565267840235, 76.6888275027770305],
  [10.817207336425831, 76.9081420898434516],
];


const chittur_boundary = getRuaObject();


class StarterPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      owres: [],
      dsres: [],
      misres: [],
      sampletime: 0,
      coordinates: [],
      temperatureLine: [],
      precipitationLine: [],
      windspeedLine: [],
      wdLine: [],
      rhLine: [],
      uvLine: [],
      psLine: [],
      dropselect: "",
      latnew: "10.72918673",
      longnew: "76.78622876",
      zoom: 12,
      showLoader: true,
      map: null,
      setKB1: true,
      setKB2: true,
      setKB3: true,
      setKB4: true,
      setKB5: true,
      setKB6: true,
      setKB7: true,
      ruaapi: '',
      rua_sel: {},
      currentrua: ''
    };
    this.apicall = this.apicall.bind(this);
    this.onMapReset = this.onMapReset.bind(this);
  }

  onEachrua = (rua, layer) => {
    const ruaname = rua.properties.LOCATION;
    if(ruaname=="Eruthompathy"){
      layer.bindPopup("Eruthenpathy");
    }else{
      layer.bindPopup(ruaname);
    }
   
  };
  seatClickHandler = (e) => {

  };
  onMapReset() {

    const { map } = this.state;
    if (map) map.flyTo([10.72, 76.810], 11);

  }



  async apicall(rua) {

    let latitude = this.state.latnew;
    let longitude = this.state.longnew;
    // if(rua=='Nallepilly'){
    //   latitude=10.72918673;
    //   longitude=76.78622876;
    // }
    // if(rua=='Kozhinjampara'){
    //   latitude=10.72918673;
    //   longitude=76.78622876;
    // }

    const { map } = this.state;
    if (map) map.flyTo([latitude, longitude], 12);

    var resthis = this;
    if (resthis.state.showLoader == false) {
      resthis.setState({ showLoader: true });
    }
    resthis.setState({ latnew: latitude })
    resthis.setState({ longnew: longitude })
    // resthis.setState({ coordinates: [(latitude).toFixed(2), (longitude).toFixed(2)] })
    let bodydata = {
      "rua": this.state.currentrua
    }
    bodydata = JSON.stringify(bodydata)
    const requestOptions = {
      method: 'POST',
      headers: {
        'Authorization': localStorage.getItem('token'),
        'Content-Type': 'application/json'
      },
      body: bodydata

    };


    var responsefarmer = await fetch(process.env.REACT_APP_APIEND + `farmer/hd-current-observation`, requestOptions).then(res => {
      return res.json();
    });

    if (responsefarmer.hasOwnProperty("code")) {
      if (responsefarmer["code"] == 200) {
        resthis.setState({ dsres: responsefarmer.result[2].darksky });
        resthis.setState({ owres: responsefarmer.result[1].openweather });
        resthis.setState({ misres: responsefarmer.result[0].Online_sensor });
        let temperatureSparkline = [];
        //Temperature sparkline
        temperatureSparkline.push(
          responsefarmer.result[1].openweather.Temperature,
          responsefarmer.result[2].darksky.Temperature,
          responsefarmer.result[0].Online_sensor.Temperature
        );
        resthis.setState({
          temperatureLine: temperatureSparkline,
        });

        //Precipitation sparkline
        let precipSparkLine = [];
        precipSparkLine.push(
          keyCheck(responsefarmer.result[1].openweather),
          responsefarmer.result[2].darksky.Rainfall.toFixed(2),
          responsefarmer.result[0].Online_sensor.Rainfall
        );

        resthis.setState({
          precipitationLine: precipSparkLine,
        });

        //windspeed sparkline
        let windspSparkLine = [];
        windspSparkLine.push(
          responsefarmer.result[1].openweather.Wind_Speed,
          responsefarmer.result[2].darksky.Wind_Speed,
          responsefarmer.result[0].Online_sensor.Wind_Speed
        );
        resthis.setState({
          windspeedLine: windspSparkLine,
        });

        //winddirection sparkline
        let wdSparkLine = [];
        wdSparkLine.push(
          responsefarmer.result[1].openweather.Wind_Direction,
          responsefarmer.result[2].darksky.Wind_Direction,
          responsefarmer.result[0].Online_sensor.Wind_Direction
        );
        resthis.setState({
          wdLine: wdSparkLine,
        });
        //UV sparkline
        let uvSparkLine = [];
        uvSparkLine.push(
          responsefarmer.result[1].openweather.UV_Index,
          responsefarmer.result[2].darksky.UV_Index,
          (responsefarmer.result[1].openweather.UV_Index+responsefarmer.result[2].darksky.UV_Index)/2
        );
        resthis.setState({
          uvLine: uvSparkLine,
        });
        //RH sparkline
        let rhsparkline = [];
        rhsparkline.push(
          responsefarmer.result[1].openweather.Humidity,
          responsefarmer.result[2].darksky.Humidity,
          responsefarmer.result[0].Online_sensor.Humidity
        );

        resthis.setState({
          rhLine: rhsparkline,
        });
        //PS Line
        let pssparkline = [];
        pssparkline.push(
          responsefarmer.result[1].openweather.Pressure,
          responsefarmer.result[2].darksky.Pressure,
          responsefarmer.result[0].Online_sensor.Pressure
        );
        resthis.setState({
          psLine: pssparkline,
        });
        resthis.setState({ showLoader: false });

      }
      else {
        console.log("NOT OK")
      }

    }

  }


  componentDidMount() {

    const krishibhavan = Permission();
    this.setState({
      currentrua: krishibhavan[1]
    }, () => {
      this.apicall(krishibhavan[1]);
    })
    if (krishibhavan[1] == 'Nallepilly' && krishibhavan[0] == 'FM') {
      this.setState({
        rua_sel: getnallepilly(),
        ruaapi: "Nallepilly",
        latnew: 10.72918673,
        longnew: 76.78622876,
        setKB1: false,
        setKB2: false,
        setKB3: false,
        setKB4: true,
        setKB5: false,
        setKB6: false,
        setKB7: false,

      })
    }
    else if (krishibhavan[1] == 'Chittur-Thathamangalam' || krishibhavan[1] == 'Chittur' && krishibhavan[0] == 'FM') {
      const { map } = this.state;
      if (map) map.flyTo([10.69300691, 76.72595507], 12);
      this.setState({
        rua_sel: getchittoor(),
        ruaapi: "Chittur",
        latnew: 10.69300691,
        longnew: 76.72595507,
        setKB1: true,
        setKB2: false,
        setKB3: false,
        setKB4: false,
        setKB5: false,
        setKB6: false,
        setKB7: false
      })



    } else if (krishibhavan[1] == 'Kozhinjampara' && krishibhavan[0] == 'FM') {

      this.setState({
        rua_sel: getkozhinjampara(),
        ruaapi: "Kozhinjampara",
        latnew: 10.6657739,
        longnew: 76.87386074,
        setKB1: false,
        setKB2: false,
        setKB3: true,
        setKB4: false,
        setKB5: false,
        setKB6: false,
        setKB7: false
      })

    } else if (krishibhavan[1] == 'Eruthenpathy' && krishibhavan[0] == 'FM') {
      // this.apicall('Eruthenpathy',10.70246231,76.85248817);                    
      this.setState({
        rua_sel: geteruthenpathy(),
        ruaapi: "Eruthenpathy",
        latnew: 10.70246231,
        longnew: 76.85248817,
        setKB1: false,
        setKB2: true,
        setKB3: false,
        setKB4: false,
        setKB5: false,
        setKB6: false,
        setKB7: false
      })
    } else if (krishibhavan[1] == 'Pattanchery' && krishibhavan[0] == 'FM') {
      // this.apicall('Pattanchery',10.64777998,76.73270522);                    
      this.setState({
        rua_sel: getpattanchery(),
        ruaapi: "Pattanchery",
        latnew: 10.64777998,
        longnew: 76.73270522,
        setKB1: false,
        setKB2: false,
        setKB3: false,
        setKB4: false,
        setKB5: true,
        setKB6: false,
        setKB7: false
      })
    } else if (krishibhavan[1] == 'Perumatty' && krishibhavan[0] == 'FM') {
      // this.apicall('Perumatty',10.64975298,76.76319652);                    
      this.onMapReset(10.6600, 76.8046);
      this.setState({
        rua_sel: getperumatty(),
        ruaapi: "Perumatty",
        latnew: 10.6600,
        longnew: 76.8046,
        setKB1: false,
        setKB2: false,
        setKB3: false,
        setKB4: false,
        setKB5: false,
        setKB6: true,
        setKB7: false,
      })
    } else if (krishibhavan[1] == 'Vadakarapathy' && krishibhavan[0] == 'FM') {
      // this.apicall('Vadakarapathy',10.77941127,76.85986374);                    
      this.setState({
        rua_sel: getvadakarapathy(),
        ruaapi: "Vadakarapathy",
        latnew: 10.77941127,
        longnew: 76.85986374,
        setKB1: false,
        setKB2: false,
        setKB3: false,
        setKB4: false,
        setKB5: false,
        setKB6: false,
        setKB7: true,
      })
    }
  }


  render() {
    const { t, i18n } = this.props
    const ruaStyle = {
      color: "red",
      weight: 1.3,
      fillColor: "#a38784",
      fillOpacity: 0.3,
    };

    const boundaryStyle = {
      color: "#1d4852",
      weight: 1.3,
      fillOpacity: 0,
    }
    const iconPerson = new L.Icon({
      iconUrl: require("./images/circle.png"),
      iconRetinaUrl: require("./images/circle.png"),
      iconAnchor: null,
      popupAnchor: null,
      shadowUrl: null,
      shadowSize: null,
      shadowAnchor: null,
      iconSize: new L.Point(60, 75),
      className: "leaflet-div-icon",
    });

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Row>
              <Col lg={7}>
                <Card style={{ minHeight: '500px' }}>

                  <MapContainer dragging={false} scrollWheelZoom={false} center={[this.state.latnew, this.state.longnew]} whenCreated={map => this.setState({ map })} zoom={11} style={{ margin: '0px 0px 0px 0px', minHeight: '500px', position: "relative" }}  >
                    <GeoJSON style={boundaryStyle} data={chittur_boundary.features} />
                    {/* <Label>
                      asfasa
                    </Label> */}
                    <GeoJSON style={ruaStyle} data={this.state.rua_sel.features} onEachFeature={this.onEachrua} icon={'text'} />
                    <TileLayer
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    />{" "}
                    ;
                    <SVGOverlay bounds={bounds}>
                      <text x="10%" y="70%" fill="#02b514" style={this.state.setKB1 ? {} : { display: 'none' }}>
                        Chittur
                      </text>
                      <text x="20%" y="95%" fill="#02b514" style={this.state.setKB5 ? {} : { display: 'none' }}>
                        Pattanchery
                      </text>
                      <text x="30%" y="80%" fill="#02b514" style={this.state.setKB6 ? {} : { display: 'none' }}>
                        Perumatty
                      </text>
                      <text x="40%" y="60%" fill="#02b514" style={this.state.setKB4 ? {} : { display: 'none' }}>
                        Nallepilly
                      </text>
                      <text x="60%" y="50%" fill="#02b514" style={this.state.setKB3 ? {} : { display: 'none' }}>
                        Kozhinjampara
                      </text>
                      <text x="75%" y="42%" fill="#02b514" style={this.state.setKB2 ? {} : { display: 'none' }}>
                        Eruthenpathy
                      </text>
                      <text x="70%" y="20%" fill="#02b514" style={this.state.setKB7 ? {} : { display: 'none' }}>
                        Vadakarapathy
                      </text>
                    </SVGOverlay>
                    <MapCustomControl>
                      <Row>
                        <Col xl={3} lg={4} sm={6}>
                          <i className="fas fa-home" onClick={this.onMapReset} style={{ fontSize: '18px', cursor: 'pointer' }}></i>
                        </Col>
                      </Row>
                    </MapCustomControl>
                  </MapContainer>
                </Card>
              </Col>
              <Col lg={5}>
                <Card style={{ minHeight: "500px" }}>
                  <CardBody>
                    <Row>
                      <Col lg={9}>
                        <h4 className="card-title" style={{ marginBottom: "10px" }}>
                          {t("Current Observation")}
                        </h4>
                      </Col>

                    </Row>
                    <SiderHeader
                      apicallfn={this.apicall}
                      coordtime={this.state}
                    />
                    <CurrentTable owres={this.state} />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default withNamespaces()(StarterPage);

