import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  UncontrolledTooltip,
  CardBody,
  Spinner,
} from "reactstrap";
import DiagnosticMap from "../Adm-components/diagnosticMap";
import DiagnosticTable from "../Adm-components/diagnosticTable";
import validate_Token from "../../validateToken.js";
import { Link } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import Moment from "moment";
class SensorDiagnostic extends Component {
  constructor(props) {
    super(props);
    this.state = {
      series: [42, 26, 42],
      options: {
        labels: ["Product A", "Product B", "Product C"],
        plotOptions: {
          pie: {
            donut: {
              size: "50%",
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        colors: ["#5664d2", "#1cbb8c", "#eeb902"],
      },
      livestatus: [],
      mdbtable: [],
      gatewaydetails: "",
      isdata: false,
    };
    this.tabledatacreate = this.tabledatacreate.bind(this);
    this.getgatewayinfo = this.getgatewayinfo.bind(this);
  }
  async getcurrentData() {
    let getthis = this;
    let searchdata = {
      station: "",
    };
    searchdata = JSON.stringify(searchdata);
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: "Token " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      body: searchdata,
    };
    var searchresponse = await fetch(
      process.env.REACT_APP_APIEND + `weather/current/observation`,
      requestOptions
    ).then((res) => {
      return res.json();
    });
    getthis.tabledatacreate(searchresponse.result);
    getthis.setState({
      livestatus: searchresponse.result,
      isdata: true,
    });
  }
  componentDidMount() {
    validate_Token();
    this.getcurrentData();
  }
  getgatewayinfo(gateway) {
    if (gateway.length > 1) {
      var gateway_list = [];
      gateway.map(function (item, index, data) {
        gateway_list.push(item);
      });
      return [this.getstrongestrssi(gateway_list), gateway_list];
    } else {
      if (gateway[0] != undefined) {
        return [gateway[0]["name"], gateway_list];
      }
    }
  }
  getstrongestrssi(gateway_list) {
    var rssi = [];
    gateway_list.map(function (item, index, data) {
      rssi.push(item.rssi);
    });
    var m = Math.max(...rssi);
    const index = rssi.indexOf(m);
    return gateway_list[index].name;
  }
  tabledatacreate(data) {
    let tabledata = [];
    let statusbadge;
    let gateway = [null];
    var getthis = this;
    data.map(function (item, index, data) {
      if (item.status == true) {
        statusbadge = (
          <div className="badge badge-soft-success font-size-12">Active</div>
        );
      } else {
        statusbadge = (
          <div className="badge badge-soft-danger font-size-12">Inactive</div>
        );
      }
      gateway = getthis.getgatewayinfo(item.gateway);
      var currentGW = "";

      // console.log("GATEWAY LISTT",gateway[0])
      if (gateway != undefined) {
        if (gateway[1] != undefined) {
          var gatewaylist = [];
          gateway[1].map(function (item) {
            if (item.name == "Gateway_1") {
              gatewaylist.push(
                <p>
                  {"ID : GW 1 | Name : Chittur M.O | " +
                    "RSSI : " +
                    item.rssi +
                    " | " +
                    "SNR : " +
                    item.lo_ra_snr +
                    "\n"}
                </p>
              );
            } else if (item.name == "Gateway_2") {
              gatewaylist.push(
                <p>
                  {"ID : GW 2 | Name : Bharathamatha | " +
                    "RSSI : " +
                    item.rssi +
                    " | " +
                    "SNR : " +
                    item.lo_ra_snr +
                    "\n"}
                </p>
              );
            } else if (item.name == "Gateway_3") {
              gatewaylist.push(
                <p>
                  {"ID : GW 3 | Name : Ahalia | " +
                    "RSSI : " +
                    item.rssi +
                    " | " +
                    "SNR : " +
                    item.lo_ra_snr +
                    "\n"}
                </p>
              );
            } else if (item.name == "Gateway_4") {
              gatewaylist.push(
                <p>
                  {"ID : GW 4 | Name : Perumatty | " +
                    "RSSI : " +
                    item.rssi +
                    " | " +
                    "SNR : " +
                    item.lo_ra_snr +
                    "\n"}
                </p>
              );
            }
          });
          gatewaylist.join("\r\n");
          getthis.setState({
            gatewaydetails: gatewaylist,
          });
          // console.log("RETURN GATEWAY LIST 1111", gateway[1]);
        }
        // console.log("RETURN GATEWAY LIST", gateway);

        if (gateway[0] == "Gateway_1") {
          currentGW = "GW 1 - Chittur M.O";
        }
        if (gateway[0] == "Gateway_2") {
          currentGW = "GW 2 - Bharathamatha";
        }
        if (gateway[0] == "Gateway_3") {
          currentGW = "GW 3 - Ahalia";
        }
        if (gateway[0] == "Gateway_4") {
          currentGW = "GW 4 - Perumatty";
        }
      }

      var newdata = {
        id: index + 1,
        station: item["station"],
        rua: item["rua"],
        gateway: (
          <span>
            <UncontrolledTooltip target={"gw" + index} placement="top">
              {getthis.state.gatewaydetails}
            </UncontrolledTooltip>
            <p
              // title={getthis.state.gatewaydetails}
              id={"gw" + index}
              // onClick={(e) => {
              //   alert(getthis.state.gatewaydetails);
              // }}
              style={{ color: "cornflowerblue" }}
            >
              {currentGW}
            </p>{" "}
          </span>
        ),
        datetime: Moment(item["datetime"]).format("DD-MM-YYYY HH:mm:ss"),
        location: item["location"],
        battery: (
          <Link
            to={{
              pathname: "/battery-historical",
              state: { station: item["station"], location: item["location"] },
            }}
          >
            <div>{parseFloat(item.battery).toFixed(2)}</div>
          </Link>
        ),
        rssi: (
          <Link
            to={{
              pathname: "/rssi-historical",
              state: { station: item["station"], location: item["location"] },
            }}
          >
            <div>{item.rssi}</div>
          </Link>
        ),
        // rssi:item.rssi,
        status: statusbadge,
      };
      tabledata.push(newdata);
      // console.log("items", newdata);
    });
    this.setState({
      mdbtable: tabledata,
    });
  }

  render() {
    const { t, i18n } = this.props;
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Row>
              <Col xs={12}>
                <h4
                  className="card-title"
                  style={{ padding: "5px", fontSize: "18px" }}
                >
                  {t("Sensor Diagnostics")}{" "}
                </h4>
              </Col>
              <Col xl={4}></Col>
            </Row>

            <Row>
              <Col lg={12}>
                {this.state.isdata ? (
                  <DiagnosticMap livedata={this.state.livestatus} />
                ) : (
                  <center>
                    <Spinner color="dark" />
                  </center>
                )}
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    {this.state.isdata ? (
                      <DiagnosticTable livedata={this.state.mdbtable} />
                    ) : (
                      <div></div>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default withNamespaces()(SensorDiagnostic);
