import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { CSVLink } from 'react-csv';
import { MDBDataTable } from "mdbreact";
import { Spinner } from "reactstrap";
import "../Datatable-css/datatables.scss"
import { DatePicker } from 'antd';
import moment from 'moment';
import validate_Token from "../../validateToken.js";
import { withNamespaces } from "react-i18next";

import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  Label,
  Input,
  Container,
  CustomInput,
  InputGroup,
  Form,
  InputGroupAddon,
  CardFooter,
  CardHeader,
} from "reactstrap";
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation";
const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD HH:mm';

class hourly_Parameter extends Component {
  constructor(props) {
    super(props)
    this.state = {
      subws: true,
      subrua: false,
      subgroup: false,
      showbutton: false,
      data_hourly: [],
      type: 'avg',
      fromDate: '',
      toDate: '',
      source: 'weather_stations',
      subsource: 'all',
      showwaitintext: true,
      csvdownloadData: []
    }
    this.handleSourcechange = this.handleSourcechange.bind(this);
    this.onChangedate = this.onChangedate.bind(this);
    this.onChangetype = this.onChangetype.bind(this);
    this.handlesubSourcechange = this.handlesubSourcechange.bind(this);
    this.getData = this.getData.bind(this);
    this.disabledDateTime = this.disabledDateTime.bind(this);

  }

  componentDidMount() {
    validate_Token();
  }
  onChangedate(dateString) {

    const date1 = new Date(dateString[0]);
    const date2 = new Date(dateString[1]);
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    if (diffDays <= 30) {
      this.setState({
        fromDate: moment(date1).format('YYYY-MM-DD HH:mm'),
        toDate: moment(date2).format('YYYY-MM-DD HH:mm'),
        showbutton: true
      });
    } else {
      this.setState({
        showbutton: false
      })
    }

  }

  onChangetype(e) {
    this.setState({
      type: e.target.value
    })
  }

  handlesubSourcechange(e) {
    this.setState({
      subsource: e.target.value
    })
  }

  handleSourcechange(e) {
    this.setState({
      source: e.target.value
    })
    if (e.target.value == "weather_stations") {
      this.setState({
        subws: true,
        subrua: false,
        subgroup: false,
      })
    }
    if (e.target.value == "rua") {
      this.setState({
        subrua: true,
        subws: false,
        subgroup: false,
      })

    }
    if (e.target.value == "group") {
      this.setState({
        subgroup: true,
        subws: false,
        subrua: false,

      })
    }
  }
  disabledDate(current) {
    // Can not select days before today and today
    return current && current > moment().endOf('day');
  }
  async getData() {
    // console.log("BUTTON CLICK")
    this.setState({
      showtable: false,
      showwaitintext: false,
      showloader: true,
    })
    var getthis = this;
    let searchData = {
      "type": this.state.type,
      "from_date": this.state.fromDate,
      "to_date": this.state.toDate,
      "source": this.state.source,
      "sub_source": this.state.subsource
    }
    // console.log("SEARCH DATA", this.state.type, this.state.fromDate, this.state.toDate, this.state.source, this.state.subsource)
    searchData = JSON.stringify(searchData);
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + localStorage.getItem('token')
      },
      body: searchData

    };
    var weatherResponse = await fetch(process.env.REACT_APP_APIEND + `weather/hourly-parameter-type-report`, requestOptions).then(res => {
      return res.json();
    });
    // console.log("Daily Report ", weatherResponse)
    getthis.makecsvdata(weatherResponse.result);
    this.setState({
      data: this.splittime(weatherResponse.result),
      showtable: true,
      showloader: false,
    });
  }

  splittime(data) {
    // console.log("SPLIT TIME", data)
    let timedata = []
    if(this.state.type=="agg"){
      data.map(function (item, index, data) {
        var newdata = {
          source: <p className="alnleft">{item.source}</p>,
          date: moment((item.date).slice(0, 10)).format("DD-MM-YYYY"),
          time: (item.date).slice(11, 14) + "00",
          Temperature: "N/A",
          Humidity:"N/A",
          Pressure: "N/A",
          UV_Index: "N/A",
          Wind_Direction:"N/A",
          Wind_Speed:"N/A",
          Rainfall: item.Rainfall,
        }
        timedata.push(newdata);
      });
    }else{
      data.map(function (item, index, data) {
        var newdata = {
          source: item.source,
          date: moment((item.date).slice(0, 10)).format("DD-MM-YYYY"),
          time: (item.date).slice(11, 14) + "00",
          Temperature: parseFloat(item.Temperature),
          Humidity: parseFloat(item.Humidity),
          Pressure: parseFloat(item.Pressure),
          UV_Index: parseFloat(item.UV_Index),
          Wind_Direction: parseFloat(item.Wind_Direction),
          Wind_Speed: parseFloat(item.Wind_Speed),
          Rainfall: parseFloat(item.Rainfall),
        }
        timedata.push(newdata);
      });
    }
    return (timedata)
  }



  makecsvdata(data) {
    let csvData = [['Source', 'Date', 'Time', 'Temperature', 'Humidity', 'Pressure', 'Rainfall', 'UV_Index', 'Wind_Direction', 'Wind_Speed']]
    data.map(wdata => {
      csvData.push([wdata.source, (wdata.date).slice(0, 10), (wdata.date).slice(11, 14) + "00", wdata.Temperature, wdata.Humidity, wdata.Pressure, wdata.Rainfall, wdata.UV_Index, wdata.Wind_Direction, wdata.Wind_Speed]);
    });
    this.setState({
      csvdownloadData: csvData
    })
  }
  range(start, end) {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  }
  disabledDateTime(_, type) {
    var getthis = this;
    if (type === 'start') {
      return {
        disabledHours: () => getthis.range(0, 24).splice(1, 23),
        disabledMinutes: () => getthis.range(1, 59),
      };
    }
    return {
      disabledHours: () => getthis.range(0, 24).splice(0, 23),
      disabledMinutes: () => getthis.range(0, 59),
    };
  }
  render() {
    const { t, i18n } = this.props;
    let data_hourly = {
      columns: [
        {
          label: "Source",
          field: "source",
          sort: "asc",
          width: 150
        },
        {
          label: "Date",
          field: "date",
          sort: "asc",
          width: 150,
          sort:"disabled"
        },
        {
          label: "Time",
          field: "time",
          sort: "asc",
          width: 150
        },
        {
          label: "Temperature [°C]",
          field: "Temperature",
          sort: "asc",
          width: 150
        },
        {
          label: "Humidity [%]",
          field: "Humidity",
          sort: "asc",
          width: 150
        },
        {
          label: "Rainfall [mm]",
          field: "Rainfall",
          sort: "asc",
          width: 150
        },
        {
          label: "Wind Direction [°]",
          field: "Wind_Direction",
          sort: "asc",
          width: 150
        },
        {
          label: "Wind Speed [m/s]",
          field: "Wind_Speed",
          sort: "asc",
          width: 150
        },
        {
          label: "UVI",
          field: "UV_Index",
          sort: "asc",
          width: 150
        },
        {
          label: "Pressure [hPa]",
          field: "Pressure",
          sort: "asc",
          width: 150
        }
      ],
      rows: this.state.data
    }
    return (
      <React.Fragment>
        <div className="page-content">
          <Card style={{ backgroundColor: "#e5ebee" }}>
            <CardHeader style={{ backgroundColor: "#A4C1D2" }}>
              <Row>
                <Col xs={8}>
                  <h4
                    className="card-title"
                    style={{ padding: "5px", fontSize: "18px" }}
                  >
                    {" "}
                    {t("Hourly report all parameter wise")}
                  </h4>
                </Col>
                <Col>
                  <Link to="report">
                    <i
                      className="ri-close-fill"
                      style={{ fontSize: "20px", float: "right" }}
                    ></i>
                  </Link>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Container fluid>
                <Row>
                  <Col xs={12}></Col>
                </Row>
                <Row>
                  <Col xl={12}>
                    <Card style={{ backgroundColor: '#f1f6fb' }}>
                      <CardBody>
                        <Col>
                          <Row>
                            <Col md="5">
                              <div className="col-md-12 ">
                                <Label className="col-md-12 col-form-label" style={{ fontSize: '14px' }}>{t("Date range")} </Label>
                                <RangePicker name="range"
                                  showTime={{ format: 'HH:mm' }}
                                  format="DD-MM-YYYY HH:mm"
                                  onChange={this.onChangedate}
                                  // defaultValue={[moment('2021-04-01 00:00', dateFormat),moment('2021-04-01 23:59', dateFormat)]}
                                  // disabledDate={d => !d || d.isBefore("2021-04-01")}
                                  disabledDate={this.disabledDate}

                                  popupStyle={{ paddingLeft: '0px' }}
                                />
                              </div>
                              <center style={this.state.showbutton ? { display: 'none' } : {}} >
                                <p style={{ color: "red", fontSize: '10px' }}>
                                  {t("Please select a date range between 30 days")}
                                </p>
                              </center>

                            </Col>

                            <Col md="2">
                              <FormGroup>
                                <Label className="col-md-12 col-form-label" style={{ fontSize: '14px' }}>{t("Type")} </Label>
                                <select
                                  className="form-select"
                                  md="6"
                                  style={{ fontSize: "11px" }}
                                  name="type"
                                  required
                                  onChange={this.onChangetype}
                                >
                                  <option value="avg" selected>Average</option>
                                  <option value="min" >Minimum</option>
                                  <option value="max">Maximum</option>
                                  <option value="agg">Aggregate</option>
                                </select>
                              </FormGroup>
                            </Col>

                            <Col md="2">
                              <FormGroup>
                                <Label className="col-md-12 col-form-label" style={{ fontSize: '14px' }}>{t("Source")} </Label>
                                <select
                                  className="form-select"
                                  md="6"
                                  style={{ fontSize: "11px" }}
                                  name="source"
                                  required
                                  onChange={this.handleSourcechange}
                                >

                                  <option value="weather_stations" selected >Weather Station</option>
                                  <option value="rua">RUA</option>
                                  <option value="group">Group</option>
                                </select>
                              </FormGroup>
                            </Col>


                            <Col md="2">
                              <div style={this.state.subws ? {} : { display: 'none' }}>
                                <FormGroup>
                                  <Label className="col-md-12 col-form-label" style={{ fontSize: '14px' }}>{t("Station")} </Label>
                                  <select
                                    className="form-select"
                                    md="6"
                                    style={{ fontSize: "11px" }}
                                    name="stationdrop"
                                    required
                                    onChange={this.handlesubSourcechange}
                                  >

                                    <option value="all" selected>All </option>
                                    <option value="kdisc_sci_aws_01">Chittur-Thathamangalam (Muncipal Corporation Office)
                                    </option>
                                    <option value="kdisc_sci_aws_10">Eruthenpathy (Seed Godown)</option>
                                    <option value="kdisc_sci_aws_04" >Eruthenpathy (Bhagavathy Govt. High School)</option>
                                    <option value="kdisc_sci_aws_08" >Kozhinjampara (Bharathamatha Arts and Science
                                      College)</option>
                                    <option value="kdisc_sci_aws_12" >Kozhinjampara (KWA Water treatment Plant)</option>
                                    <option value="kdisc_sci_aws_03">Nallepilly (Krishi Bhavan)</option>

                                    <option value="kdisc_sci_aws_07">Nallepilly (Government Technical High School)
                                    </option>
                                    <option value="kdisc_sci_aws_06">Pattanchery (Panchayat Cremation Ground)</option>
                                    <option value="kdisc_sci_aws_02">Pattanchery (GSM High School,Thattamangalam)
                                    </option>
                                    <option value="kdisc_sci_aws_05" >Perumatty (Moolathara Regulator)</option>
                                    <option value="kdisc_sci_aws_14">Perumatty (Panchayat Office)</option>
                                    <option value="kdisc_sci_aws_11">Perumatty (Higher Secondary School)</option>
                                    <option value="kdisc_sci_aws_13">Vadakarapathy (Kinarpallam Milk Society)</option>
                                    <option value="kdisc_sci_aws_09">Vadakarapathy (Ahalia campus)</option>
                                  </select>

                                </FormGroup>
                              </div>




                              <div style={this.state.subgroup ? {} : { display: 'none' }}>
                                <FormGroup>
                                  <Label className="col-md-12 col-form-label" style={{ fontSize: '14px' }}>Group </Label>
                                  <select
                                    className="form-select"
                                    md="6"
                                    style={{ fontSize: "11px" }}
                                    name="groupdrop"
                                    required
                                    onChange={this.handlesubSourcechange}
                                  >
                                    <option selected value="all">All </option>
                                    <option value="Group1" >Group 1</option>
                                    <option value="Group2" >Group 2 </option>
                                  </select>
                                </FormGroup>
                              </div>

                              <div style={this.state.subrua ? {} : { display: 'none' }}>
                                <FormGroup>
                                  <Label className="col-md-12 col-form-label" style={{ fontSize: '14px' }}>RUA </Label>
                                  <select
                                    className="form-select"
                                    md="6"
                                    style={{ fontSize: "11px" }}
                                    name="ruadrop"
                                    required
                                    onChange={this.handlesubSourcechange}
                                  >
                                    <option value="all" selected>All </option>
                                    <option value="Chittur" >Chittur-Thathamangalam</option>
                                    <option value="Eruthenpathy" >Eruthenpathy </option>
                                    <option value="Kozhinjampara">Kozhinjampara</option>
                                    <option value="Nallepilly">Nallepilly</option>
                                    <option value="Pattanchery">Pattanchery</option>
                                    <option value="Perumatty">Perumatty</option>
                                    <option value="Vadakarapathy">Vadakarapathy</option>
                                  </select>
                                </FormGroup>
                              </div>
                            </Col>

                            <Col md="1">
                              <FormGroup style={this.state.showbutton ? {} : { display: 'none' }}>
                                <Label
                                  className="col-md-12 col-form-label"
                                  style={{ fontSize: "14px" }}
                                >
                                  <span>&nbsp;</span>
                                </Label>
                                <Button
                                  color="primary"
                                  className="waves-effect waves-light mr-1"
                                  style={{ fontSize: "11px", float: "right" }}
                                  onClick={this.getData}
                                >
                                  Search
                                </Button>
                              </FormGroup>
                            </Col>
                          </Row>


                        </Col>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col xl={12}>
                    <Card>
                      <CardBody>

                        <Row>
                          <div style={this.state.showwaitintext ? {} : { display: 'none' }}><center>{t("Waiting for search")}</center></div>
                          <div style={this.state.showloader ? {} : { display: 'none' }}>
                            <center>
                              <Spinner color="dark" />
                            </center>
                          </div>
                          <Col xs={12}>
                            <div id="hourlyalldata" style={this.state.showtable ? {} : { display: 'none' }}>
                              <div class="text-right">
                                <CSVLink filename="weather_datahourly_onlinesensor.csv" data={this.state.csvdownloadData} >{t("Download Report")}</CSVLink>

                              </div>
                              <MDBDataTable scrollX maxHeight="300px" paging={true} showPaginationBottom={true} bordered data={data_hourly} className='datadownloadtable alnright' style={{ fontSize: "12px" }} />
                            </div>
                          </Col>
                        </Row>

                      </CardBody>
                    </Card>

                  </Col>
                </Row>
              </Container>
            </CardBody>
          </Card>
        </div>
      </React.Fragment>
    )
  }
}
export default withNamespaces()(hourly_Parameter);
