export default function stationnameTolatlng(station) {
    var coordinates=[];
    if(station=='kdisc_sci_aws_01'){
        coordinates=[10.69300691,76.72595507]
        return coordinates
    }
    if(station=='kdisc_sci_aws_02'){
        coordinates=[10.67481694,76.70654271]
        return coordinates
    }
    if(station=='kdisc_sci_aws_03'){
        coordinates=[10.72918673,76.78622876]
        return coordinates
    }
    if(station=='kdisc_sci_aws_04'){
        coordinates=[10.70246231,76.85248817]
        return coordinates
    }
    if(station=='kdisc_sci_aws_05'){
        coordinates=[10.6657739,76.87386074]
        return coordinates
    }
    if(station=='kdisc_sci_aws_06'){
        coordinates=[10.64777998,76.73270522]
        return coordinates
    }
    if(station=='kdisc_sci_aws_07'){
        coordinates=[10.70258377,76.75669052]
        return coordinates
    }
    if(station=='kdisc_sci_aws_08'){
        coordinates=[10.73595019,76.82999565]
        return coordinates
    }
    if(station=='kdisc_sci_aws_09'){
        coordinates=[10.7951,76.8363]
        return coordinates
    }
    if(station=='kdisc_sci_aws_10'){
        coordinates=[10.74866619,76.85879364]
        return coordinates
    }
    if(station=='kdisc_sci_aws_11'){
        coordinates=[10.65674374,76.80928768]
        return coordinates
    }
    if(station=='kdisc_sci_aws_12'){
        coordinates=[10.66709167,76.82955577]
        return coordinates
    }
    if(station=='kdisc_sci_aws_13'){
        coordinates=[10.77941127,76.85986374]
        return coordinates
    }
    if(station=='kdisc_sci_aws_14'){
        coordinates=[10.64975298,76.76319652]
        return coordinates
    }

    // else if (lat == String(10.67481694) && lng == String(76.70654271)) {
    //     return ('kdisc_sci_aws_02')

    // }
    // else if (lat == String(10.70258377) && lng == String(76.75669052)) {
    //     return ('kdisc_sci_aws_07')
    // }

    // else if (lat == String(10.64777998) && lng == String(76.73270522)) {
    //     return ('kdisc_sci_aws_06')
    // }

    // else if (lat == String(10.64975298) && lng == String(76.76319652)) {
    //     return ('kdisc_sci_aws_14')
    // }

    // else if (lat == String(10.72918673) && lng == String(76.78622876)) {
    //     return ('kdisc_sci_aws_03')
    // }

    // else if (lat == String(10.65674374) && lng == String(76.80928768)) {
    //     return ('kdisc_sci_aws_11')
    // }

    // else if (lat == String(10.73595019) && lng == String(76.82999565)) {
    //     return ('kdisc_sci_aws_08')
    // }

    // else if (lat == String(10.74866619) && lng == String(76.85879364)) {
    //     return ('kdisc_sci_aws_10')
    // }

    // else if (lat == String(10.77941127) && lng == String(76.85986374)) {
    //     return ('kdisc_sci_aws_13')
    // }

    // else if (lat == String(10.7951) && lng == String(76.8363)) {
    //     return ('kdisc_sci_aws_09')
    // }

    // else if (lat == String(10.70246231) && lng == String(76.85248817)) {
    //     return ('kdisc_sci_aws_04')
    // }

    // else if (lat == String(10.6657739) && lng == String(76.87386074)) {
    //     return ('kdisc_sci_aws_05')
    // }

    // else if (lat == String(10.66709167) && lng == String(76.82955577)) {
    //     return ('kdisc_sci_aws_12')
    // }

}