
import React, { Component } from "react";
import { Row, Col, Alert, Button, Container, FormGroup, Label } from "reactstrap";

// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

// action
import { forgetUser } from '../../store/actions';

// import images
import logodark from "../../assets/images/misteo-logo.png";
import { toast } from 'react-toastify';
class ForgetPasswordPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            showSuccess:false,
            successMessage:"New password  are sended to your mailbox"

        };

        // handleValidSubmit
        this.handleValidSubmit = this.handleValidSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    // handleValidSubmit
    handleValidSubmit(event, values) {
        // this.props.forgetUser(values, this.props.history);
        // console.log(this.state.email);
        let resetData={
            "email":this.state.email
        }
        this.postResetdata(resetData);
    }
    resetForm = () => {
        this.setState({
            email: "",
        });
      };
    async postResetdata(userData) {
        // name,designation,mob,email,password,userType,kb
        var data = JSON.stringify(userData);
    
        // console.log('Reset email' + data);
    
    
        const requestOptions = {
          method: 'post',
          headers: {
            'Authorization': 'Token ' + localStorage.getItem('token'),
            'Content-Type': 'application/json'
          },
          body: data
    
        };
        var changeResponse = await fetch(process.env.REACT_APP_APIEND + `department/forgot-password`, requestOptions).then(res => {
          return res.json();
        });
        if (changeResponse.hasOwnProperty("code")) {
            if (changeResponse.code === 200) {
              toast.success(changeResponse.message);
            } else if (changeResponse.code === 400) {
              toast.error(changeResponse.message);
            }
          } else if (changeResponse.hasOwnProperty("non_field_errors")) {
            toast.error(changeResponse.non_field_errors[0]);
          }
    }
    onChange(ev) {
        this.setState({
            [ev.target.name]: ev.target.value
        });
    }

    render() {
        return (
            <React.Fragment>
                <div className="home-btn d-none d-sm-block">
               
                </div>
                <div>
                    <Container fluid className="p-0">
                        <Row className="no-gutters">
                            <Col lg={4}>
                                <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                                    <div className="w-100">
                                        <Row className="justify-content-center">
                                            <Col lg={9}>
                                                <div>
                                                    <div className="text-center">
                                                        <div>
                                                            <Link to="/" className="logo"><img src={logodark} height="50" alt="logo" /></Link>
                                                        </div>

                                                        <h4 className="font-size-18 mt-4">Reset Password</h4>

                                                    </div>

                                                    <div className="p-2 mt-5">

                                                <AvForm className="form-horizontal" onValidSubmit={this.handleValidSubmit}>

                                                            <FormGroup className="auth-form-group-custom mb-4">
                                                                <i className="ri-mail-line auti-custom-input-icon"></i>
                                                                <Label htmlFor="useremail">Email</Label>
                                                                <AvField name="email" value={this.state.email} type="email" validate={{ email: true, required: true }} className="form-control" id="useremail" placeholder="Enter email" onChange={this.onChange} />
                                                            </FormGroup>

                                                            <div className="mt-4 text-center">
                                                                <Button color="primary" className="w-md waves-effect waves-light" type="submit">Reset</Button>
                                                            </div>
                                                            <div className="mt-4 text-center">
                                                                    <Link to="/login" className="text-muted"><i className="mdi mdi-login mr-1"></i> Return to Login </Link>
                                                                </div>
                                                        </AvForm>
                                                    </div>

                                                    {/* <div className="mt-5 text-center">
                                                <p>Don't have an account ? <Link to="/login" className="font-weight-medium text-primary"> Log in </Link> </p>
                                                <p>© 2020 Nazox. Crafted with <i className="mdi mdi-heart text-danger"></i> by Themesdesign</p>
                                            </div> */}
                                                </div>

                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={8}>
                                <div className="authentication-bg">
                                    {/* <div className="bg-overlay"></div>  */}
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { message, forgetError, loading } = state.Forget;
    return { message, forgetError, loading };
}

export default withRouter(
    connect(mapStatetoProps, { forgetUser })(ForgetPasswordPage)
);
