export default function getkozhinjamparastations(){
    const points ={
        "type": "FeatureCollection",
        "name": "Kozhinjampara_stations",
        "crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:OGC:1.3:CRS84" } },
        "features": [
          {
            type: "Feature",
            properties: {
              RUA: "Kozhijampara",
              Type: "KrishiBhavan",
              stationName: "kdisc_sci_aws_08",
              tooltipText:"Bharathamatha Arts and Science College, Kozhinjampara",
              Location: "Bharathamatha Arts and Science College, Kozhinjampara",
              "Google Map URL": "https://goo.gl/maps/a1sAZL3RYa5y7Gqs6",
              Lat: 10.73595019,
              Long: 76.82999565,
            },
            geometry: { type: "Point", coordinates: [76.82999565, 10.73595019] },
          },
          {
            type: "Feature",
            properties: {
              RUA: "Kozhijampara",
              Type: "Extra",
              stationName: "kdisc_sci_aws_12",
              tooltipText:"KWA Water treatment Plant",
              Location: "KWA Water treatment Plant",
              "Google Map URL": "https://goo.gl/maps/SCUqm3SYDyYQRgQT9",
              Lat: 10.66709167,
              Long: 76.82955577,
            },
            geometry: { type: "Point", coordinates: [76.82955577, 10.66709167] },
          },
        ]
        }            
      return points
}