export default function getchitturstations(){
    const points ={
        "type": "FeatureCollection",
        "name": "chittoor_stations",
        "crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:OGC:1.3:CRS84" } },
        "features": [
          {
            type: "Feature",
            properties: {
              RUA: "Chittur-Thattamangalam",
              Type: "Local Body HQ",
              stationName: "kdisc_sci_aws_01",
              tooltipText: "Muncipal Corporation Office",
              Location: "Muncipal Corporation Office",
              "Google Map URL": "https://goo.gl/maps/ymfxBYFk88N2Pt5VA",
              Lat: 10.69300691,
              Long: 76.72595507,
            },
            geometry: { type: "Point", coordinates: [76.72595507, 10.69300691] },
          }
        ]
        }
         
      return points
}