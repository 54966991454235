import React, { Component } from 'react'
import { Table } from 'reactstrap'


var perils_config = {
    nallepilly: {
        lat: 10.72918673,
        lng: 76.78622876,
        kharif: {
            paddy: {
                meteoblue: {
                    deficit_rainfall: {
                        strike1: 300,
                        strike2: 250,
                        strike3: 250,
                        strike4: 200,
                        aggrain1: 75.00,
                        aggrain2: 11.70,
                        aggrain3: 38.50,
                        aggrain4: 8.90,
                        phaseagg1: 225.00,
                        phaseagg2: 238.30,
                        phaseagg3: 211.50,
                        phaseagg4: 191.10,
                        exit1: 60,
                        exit2: 60,
                        exit3: 60,
                        exit4: 60,
                        excond1: "N",
                        excond2: "Y",
                        excond3: "Y",
                        excond4: "Y",
                        payoutrate1: 29.17,
                        payoutrate2: 26.32,
                        payoutrate3: 26.32,
                        payoutrate4: 21.43,
                        maxpayout1: 7000.00,
                        maxpayout2: 5000.00,
                        maxpayout3: 5000.00,
                        maxpayout4: 3000.00,
                        payoutperphase1: 6563.00,
                        payoutperphase2: 6272.00,
                        payoutperphase3: 5567.00,
                        payoutperphase4: 4095.00,
                        payoutonexit1: 0.00,
                        payoutonexit2: 5000.00,
                        payoutonexit3: 5000.00,
                        payoutonexit4: 3000.00,
                        actualphasepayout1: 6563.00,
                        actualphasepayout2: 5000.00,
                        actualphasepayout3: 5000.00,
                        actualphasepayout4: 3000.00,
                        cumpay1: 6563.00,
                        cumpay2: 11563.00,
                        cumpay3: 16563.00,
                        cumpay4: 19563.00,
                        totalmaxpayout: 20000.00,
                        eligiblepayout: 19563.00
                    },
                    excess_rainfall: {
                        strike: 150,
                        threedaycumm: 56.90,
                        maxpay: 20000.00,
                        payout: 0,
                        totalmaxpayout: 20000.00,
                        eligiblepayout: 0.00,
                    },
                    hightemp: {
                        trigger1: 31,
                        trigger2: 31.50,
                        trigger3: 32,
                        trigger4: 32.50,
                        totdevian: 14.71,
                        strike: 4.00,
                        exit: 36,
                        payoutrate: 531.25,
                        maxpayout: 17000.00,
                        actualphasepayout: 5690.00,
                        totalmaxpayout: 17000.00,
                        eligiblepayout: 5690.00

                    },
                    dryspell: {
                        strike1: 13,
                        strike2: 15,
                        strike3: 18,
                        strike4: 20,
                        conse_days: 29,
                        payoutrate1: 3000.00,
                        payoutrate2: 5000.00,
                        payoutrate3: 8000.00,
                        payoutrate4: 13000.00,
                        payout: 13000.00,
                        totalmaxpayout: 13000.00,
                        eligiblepayout: 13000.00
                    },
                    diseasecongenial: {
                        strike: 3,
                        conse_days: 0,
                        exit: 7,
                        payoutrate: 2000.00,
                        payout: 0.00,
                        totalmaxpayout: 10000.00,
                        eligiblepayout: 0.00

                    }
                },
                nasa: {
                    excess_rainfall: {
                        strike: 150,
                        threedaycumm: 145,
                        maxpayout: 20000.00,
                        payout: 0.00,
                        totalmaxpayout: 20000.00,
                        eligiblepayout: 0.00
                    },
                    deficit_rainfall: {
                        strike1: 300,
                        strike2: 250,
                        strike3: 250,
                        strike4: 200,
                        aggrain1: 186.53,
                        aggrain2: 307.08,
                        aggrain3: 304.18,
                        aggrain4: 265.42,
                        phaseagg1: 113.47,
                        phaseagg2: 0.00,
                        phaseagg3: 0.00,
                        phaseagg4: 0.00,
                        exit1: 60,
                        exit2: 60,
                        exit3: 60,
                        exit4: 60,
                        excond1: "N",
                        excond2: "N",
                        excond3: "N",
                        excond4: "N",
                        payoutrate1: 29.17,
                        payoutrate2: 26.32,
                        payoutrate3: 26.32,
                        payoutrate4: 21.43,
                        maxpayout1: 7000.00,
                        maxpayout2: 5000.00,
                        maxpayout3: 5000.00,
                        maxpayout4: 3000.00,
                        payoutperphase1: 3310.00,
                        payoutperphase2: 0.00,
                        payoutperphase3: 0.00,
                        payoutperphase4: 0.00,
                        payoutonexit1: 0.00,
                        payoutonexit2: 0.00,
                        payoutonexit3: 0.00,
                        payoutonexit4: 0.00,
                        actualphasepayout1: 3310,
                        actualphasepayout2: 0.00,
                        actualphasepayout3: 0.00,
                        actualphasepayout4: 0.00,
                        cumpay1: 3310.00,
                        cumpay2: 3310.00,
                        cumpay3: 3310.00,
                        cumpay4: 3310.00,
                        totalmaxpayout: 20000.00,
                        eligiblepayout: 3309.92
                    },
                    hightemp: {
                        trigger1: 31,
                        trigger2: 31.50,
                        trigger3: 32,
                        trigger4: 32.50,
                        totdevian: 0.00,
                        strike: 4.00,
                        exit: 36,
                        payoutrate: 531.25,
                        maxpayout: 17000.00,
                        actualphasepayout: 0.00,
                        totalmaxpayout: 17000.00,
                        eligiblepayout: 0.00

                    },
                    dryspell: {
                        strike1: 13,
                        strike2: 15,
                        strike3: 18,
                        strike4: 20,
                        conse_days: 5,
                        payoutrate1: 3000.00,
                        payoutrate2: 5000.00,
                        payoutrate3: 8000.00,
                        payoutrate4: 13000.00,
                        payout: 0.00,
                        totalmaxpayout: 13000.00,
                        eligiblepayout: 0.00
                    },
                    diseasecongenial: {
                        strike: 3,
                        conse_days: 0,
                        exit: 7,
                        payoutrate: 2000.00,
                        payout: 0.00,
                        totalmaxpayout: 10000.00,
                        eligiblepayout: 0.00

                    }
                },
                ecmwf: {
                    deficit_rainfall: {
                        strike1: 300,
                        strike2: 250,
                        strike3: 250,
                        strike4: "--",
                        aggrain1: 276.10,
                        aggrain2: 762.65,
                        aggrain3: 53.55,
                        aggrain4: "--",
                        phaseagg1: 113.47,
                        phaseagg2: 0.00,
                        phaseagg3: 196.45,
                        phaseagg4: "--",
                        exit1: 60,
                        exit2: 60,
                        exit3: 60,
                        exit4: "--",
                        excond1: "N",
                        excond2: "N",
                        excond3: "Y",
                        excond4: "--",
                        payoutrate1: 29.17,
                        payoutrate2: 26.32,
                        payoutrate3: 26.32,
                        payoutrate4: "--",
                        maxpayout1: 7000.00,
                        maxpayout2: 5000.00,
                        maxpayout3: 5000.00,
                        maxpayout4: "--",
                        payoutperphase1: 697.00,
                        payoutperphase2: 0.00,
                        payoutperphase3: 5170.00,
                        payoutperphase4: "--",
                        payoutonexit1: 0.00,
                        payoutonexit2: 0.00,
                        payoutonexit3: 5000.00,
                        payoutonexit4: "--",
                        actualphasepayout1: 697.00,
                        actualphasepayout2: 0.00,
                        actualphasepayout3: 5000.00,
                        actualphasepayout4: "--",
                        cumpay1: 697.00,
                        cumpay2: 697.00,
                        cumpay3: 5697.00,
                        cumpay4: "--",
                        totalmaxpayout: 20000.00,
                        eligiblepayout: 5697.23
                    },
                    excess_rainfall: {
                        strike: 150,
                        threedaycumm: 207.96,
                        maxpayout: 20000.00,
                        payout: 20000.00,
                        totalmaxpayout: 20000.00,
                        eligiblepayout: 20000.00
                    },
                    hightemp: {
                        trigger1: 31,
                        trigger2: 31.50,
                        trigger3: 32,
                        trigger4: 32.50,
                        totdevian: 2.00,
                        strike: 4.00,
                        exit: 36,
                        payoutrate: 531.25,
                        maxpayout: 17000.00,
                        actualphasepayout: 0.00,
                        totalmaxpayout: 17000.00,
                        eligiblepayout: 0.00
                    },
                    diseasecongenial: {
                        strike: 3,
                        conse_days: 0,
                        exit: 7,
                        payoutrate: 2000.00,
                        payout: 0.00,
                        totalmaxpayout: 10000.00,
                        eligiblepayout: 0.00
                    },
                    dryspell: {
                        strike1: 13,
                        strike2: 15,
                        strike3: 18,
                        strike4: 20,
                        conse_days: 5,
                        payoutrate1: 3000.00,
                        payoutrate2: 5000.00,
                        payoutrate3: 8000.00,
                        payoutrate4: 13000.00,
                        payout: 0.00,
                        totalmaxpayout: 13000.00,
                        eligiblepayout: 0.00
                    },
                },
                aic: {
                    deficit_rainfall: {
                        strike1: 300,
                        strike2: 250,
                        strike3: 250,
                        strike4: "--",
                        aggrain1: 189.23,
                        aggrain2: 396.47,
                        aggrain3: 518.89,
                        aggrain4: "--",
                        phaseagg1: 110.77,
                        phaseagg2: 0.00,
                        phaseagg3: 0.00,
                        phaseagg4: "--",
                        exit1: 60,
                        exit2: 60,
                        exit3: 60,
                        exit4: "--",
                        excond1: "N",
                        excond2: "N",
                        excond3: "N",
                        excond4: "--",
                        payoutrate1: 29.17,
                        payoutrate2: 26.32,
                        payoutrate3: 26.32,
                        payoutrate4: "--",
                        maxpayout1: 7000.00,
                        maxpayout2: 5000.00,
                        maxpayout3: 5000.00,
                        maxpayout4: "--",
                        payoutperphase1: 3231.16,
                        payoutperphase2: 0.00,
                        payoutperphase3: 0.00,
                        payoutperphase4: "--",
                        payoutonexit1: 0.00,
                        payoutonexit2: 0.00,
                        payoutonexit3: 0.00,
                        payoutonexit4: "--",
                        actualphasepayout1: 3231.16,
                        actualphasepayout2: 0.00,
                        actualphasepayout3: 0.00,
                        actualphasepayout4: "--",
                        cummpayout1: 3231.16,
                        cummpayout2: 3231.16,
                        cummpayout3: 3231.16,
                        cummpayout4: "--",
                        totalmaxpayout: 20000.00,
                        eligiblepayout: 3231.16
                    },
                    dryspell: {
                        strike1: 13,
                        strike2: 15,
                        strike3: 18,
                        strike4: 20,
                        conse_days: 12,
                        payoutrate1: 3000.00,
                        payoutrate2: 5000.00,
                        payoutrate3: 8000.00,
                        payoutrate4: 13000.00,
                        payout: 0.00,
                        totalmaxpayout: 13000.00,
                        eligiblepayout: 0.00
                    },
                    diseasecongenial: {
                        strike: 3,
                        conse_days: 3,
                        exit: 7,
                        payoutrate: 2000.00,
                        payout: 2000.00,
                        totalmaxpayout: 10000.00,
                        eligiblepayout: 2000.00
                    },
                    hightemp: {
                        trigger1: 31,
                        trigger2: 31.50,
                        trigger3: 32,
                        trigger4: 32.50,
                        totdevian: 44.30,
                        strike: 4.00,
                        exit: 36,
                        payoutrate: 531.25,
                        maxpayout: 17000.00,
                        actualphasepayout: 17000.00,
                        totalmaxpayout: 17000.00,
                        eligiblepayout: 17000.00
                    },
                    excess_rainfall: {
                        strike: 150,
                        threedaycumm: 171.94,
                        maxpayout: 20000.00,
                        payout: 20000.00,
                        totalmaxpayout: 20000.00,
                        eligiblepayout: 20000.00
                    }
                }
            }
        }
    },
    chittur: {
        lat: 10.69300691,
        lng: 76.72595507,
        kharif: {
            paddy: {
                meteoblue: {
                    deficit_rainfall: {
                        strike1: 300,
                        strike2: 250,
                        strike3: 250,
                        strike4: 200,
                        aggrain1: 69.20,
                        aggrain2: 19.60,
                        aggrain3: 58.60,
                        aggrain4: 14.00,
                        phaseagg1: 230.80,
                        phaseagg2: 230.40,
                        phaseagg3: 191.40,
                        phaseagg4: 196.00,
                        exit1: 60,
                        exit2: 60,
                        exit3: 60,
                        exit4: 60,
                        excond1: "N",
                        excond2: "Y",
                        excond3: "Y",
                        excond4: "Y",
                        payoutrate1: 29.17,
                        payoutrate2: 26.32,
                        payoutrate3: 26.32,
                        payoutrate4: 21.43,
                        maxpayout1: 7000.00,
                        maxpayout2: 5000.00,
                        maxpayout3: 5000.00,
                        maxpayout4: 3000.00,
                        payoutperphase1: 6732.00,
                        payoutperphase2: 6064.00,
                        payoutperphase3: 5038.00,
                        payoutperphase4: 3986.00,
                        payoutonexit1: 0.00,
                        payoutonexit2: 5000.00,
                        payoutonexit3: 5000.00,
                        payoutonexit4: 3000.00,
                        actualphasepayout1: 6732.00,
                        actualphasepayout2: 5000.00,
                        actualphasepayout3: 5000.00,
                        actualphasepayout4: 3000.00,
                        cummpayout1: 6732.00,
                        cummpayout2: 11732.00,
                        cummpayout3: 16732.00,
                        cummpayout4: 19732.00,
                        totalmaxpayout: 20000.00,
                        eligiblepayout: 17000.00
                    },
                    excess_rainfall: {
                        strike: 150,
                        threedaycumm: 54.80,
                        maxpay: 20000.00,
                        payout: 0,
                        totalmaxpayout: 20000.00,
                        eligiblepayout: 0.00,
                    },
                    hightemp: {
                        trigger1: 31,
                        trigger2: 31.50,
                        trigger3: 32,
                        trigger4: 32.50,
                        totdevian: 26.25,
                        strike: 4.00,
                        exit: 36,
                        payoutrate: 531.25,
                        maxpayout: 17000.00,
                        actualphasepayout: 11820.00,
                        totalmaxpayout: 17000.00,
                        eligiblepayout: 11820.00
                    },
                    dryspell: {
                        strike1: 13,
                        strike2: 15,
                        strike3: 18,
                        strike4: 20,
                        conse_days: 24,
                        payoutrate1: 3000.00,
                        payoutrate2: 5000.00,
                        payoutrate3: 8000.00,
                        payoutrate4: 13000.00,
                        payout: 13000.00,
                        totalmaxpayout: 13000.00,
                        eligiblepayout: 13000.00
                    },
                    diseasecongenial: {
                        strike: 3,
                        conse_days: 0,
                        exit: 7,
                        payoutrate: 2000.00,
                        payout: 0.00,
                        totalmaxpayout: 10000.00,
                        eligiblepayout: 0.00
                    }
                },
                nasa: {
                    deficit_rainfall: {
                        strike1: 300,
                        strike2: 250,
                        strike3: 250,
                        strike4: 200,
                        aggrain1: 186.76,
                        aggrain2: 309.78,
                        aggrain3: 306.59,
                        aggrain4: 263.83,
                        phaseagg1: 113.24,
                        phaseagg2: 0.00,
                        phaseagg3: 0.00,
                        phaseagg4: 0.00,
                        exit1: 60,
                        exit2: 60,
                        exit3: 60,
                        exit4: 60,
                        excond1: "N",
                        excond2: "N",
                        excond3: "N",
                        excond4: "N",
                        payoutrate1: 29.17,
                        payoutrate2: 26.32,
                        payoutrate3: 26.32,
                        payoutrate4: 21.43,
                        maxpayout1: 7000.00,
                        maxpayout2: 5000.00,
                        maxpayout3: 5000.00,
                        maxpayout4: 3000.00,
                        payoutperphase1: 3303.00,
                        payoutperphase2: 0.00,
                        payoutperphase3: 0.00,
                        payoutperphase4: 0.00,
                        payoutonexit1: 0.00,
                        payoutonexit2: 0.00,
                        payoutonexit3: 0.00,
                        payoutonexit4: 0.00,
                        actualphasepayout1: 3303.00,
                        actualphasepayout2: 0.00,
                        actualphasepayout3: 0.00,
                        actualphasepayout4: 0.00,
                        cummpayout1: 3303.00,
                        cummpayout2: 3303.00,
                        cummpayout3: 3303.00,
                        cummpayout4: 3303.00,
                        totalmaxpayout: 20000.00,
                        eligiblepayout: 3303.25
                    },
                    diseasecongenial: {
                        strike: 3,
                        conse_days: 0,
                        exit: 7,
                        payoutrate: 2000.00,
                        payout: 0.00,
                        totalmaxpayout: 10000.00,
                        eligiblepayout: 0.00
                    },
                    excess_rainfall: {
                        strike: 150,
                        threedaycumm: 142.88,
                        maxpayout: 20000.00,
                        payout: 0.00,
                        totalmaxpayout: 20000.00,
                        eligiblepayout: 0.00,
                    },
                    dryspell: {
                        strike1: 13,
                        strike2: 15,
                        strike3: 18,
                        strike4: 20,
                        conse_days: 5,
                        payoutrate1: 3000.00,
                        payoutrate2: 5000.00,
                        payoutrate3: 8000.00,
                        payoutrate4: 13000.00,
                        payout: 0.00,
                        totalmaxpayout: 13000.00,
                        eligiblepayout: 0.00
                    },
                    hightemp: {
                        trigger1: 31,
                        trigger2: 31.50,
                        trigger3: 32,
                        trigger4: 32.50,
                        totdevian: 0.00,
                        strike: 4.00,
                        exit: 36,
                        payoutrate: 531.25,
                        maxpayout: 17000.00,
                        actualphasepayout: 0.00,
                        totalmaxpayout: 17000.00,
                        eligiblepayout: 0.00
                    }
                },
                ecmwf: {
                    excess_rainfall: {
                        strike: 150,
                        threedaycumm: 190.85,
                        maxpayout: 20000.00,
                        payout: 20000.00,
                        totalmaxpayout: 20000.00,
                        eligiblepayout: 20000.00,
                    },
                    diseasecongenial: {
                        strike: 3,
                        conse_days: 0,
                        exit: 7,
                        payoutrate: 2000.00,
                        payout: 0,
                        totalmaxpayout: 10000.00,
                        eligiblepayout: 0.00

                    },
                    deficit_rainfall: {
                        strike1: 300,
                        strike2: 250,
                        strike3: 250,
                        aggrain1: 270.20,
                        aggrain2: 724.76,
                        aggrain3: 55.83,
                        phaseagg1: 29.80,
                        phaseagg2: 0.00,
                        phaseagg3: 194.17,
                        exit1: 60,
                        exit2: 60,
                        exit3: 60,
                        excond1: "N",
                        excond2: "N",
                        excond3: "Y",
                        payoutrate1: 29.17,
                        payoutrate2: 26.32,
                        payoutrate3: 26.32,
                        payoutrate4: 21.43,
                        maxpayout1: 7000.00,
                        maxpayout2: 5000.00,
                        maxpayout3: 5000.00,
                        payoutperphase1: 869.00,
                        payoutperphase2: 0.00,
                        payoutperphase3: 5111.00,
                        payoutonexit1: 0.00,
                        payoutonexit2: 0.00,
                        payoutonexit3: 5000.00,
                        actualphasepayout1: 869.00,
                        actualphasepayout2: 0.00,
                        actualphasepayout3: 5000.00,
                        cummpayout1: 869.00,
                        cummpayout2: 869.00,
                        cummpayout3: 5869.00,
                        totalmaxpayout: 20000.00,
                        eligiblepayout: 5869.24
                    },
                    hightemp: {
                        trigger1: 31.00,
                        trigger2: 31.50,
                        trigger3: 32.00,
                        trigger4: 32.50
                    },
                    dryspell: {
                        strike1: 13,
                        strike2: 15,
                        strike3: 18,
                        strike4: 20,
                        conse_days: 5,
                        payoutrate1: 3000,
                        payoutrate2: 5000,
                        payoutrate3: 8000,
                        payoutrate4: 13000,
                        payout: 0.00,
                        totalmaxpayout: 13000.00,
                        eligiblepayout: 0.00
                    }
                },
                aic: {
                    deficit_rainfall: {
                        strike1: 300,
                        strike2: 250,
                        strike3: 250,
                        strike4: "--",
                        aggrain1: 189.23,
                        aggrain2: 396.47,
                        aggrain3: 518.89,
                        aggrain4: "--",
                        phaseagg1: 110.77,
                        phaseagg2: 0.00,
                        phaseagg3: 0.00,
                        phaseagg4: "--",
                        exit1: 60,
                        exit2: 60,
                        exit3: 60,
                        exit4: "--",
                        excond1: "N",
                        excond2: "N",
                        excond3: "N",
                        excond4: "--",
                        payoutrate1: 29.17,
                        payoutrate2: 26.32,
                        payoutrate3: 26.32,
                        payoutrate4: "--",
                        maxpayout1: 7000.00,
                        maxpayout2: 5000.00,
                        maxpayout3: 5000.00,
                        maxpayout4: "--",
                        payoutperphase1: 3231.16,
                        payoutperphase2: 0.00,
                        payoutperphase3: 0.00,
                        payoutperphase4: "--",
                        payoutonexit1: 0.00,
                        payoutonexit2: 0.00,
                        payoutonexit3: 0.00,
                        payoutonexit4: "--",
                        actualphasepayout1: 3231.16,
                        actualphasepayout2: 0.00,
                        actualphasepayout3: 0.00,
                        actualphasepayout4: "--",
                        cummpayout1: 3231.16,
                        cummpayout2: 3231.16,
                        cummpayout3: 3231.16,
                        cummpayout4: "--",
                        totalmaxpayout: 20000.00,
                        eligiblepayout: 3231.16
                    },
                    dryspell: {
                        strike1: 13,
                        strike2: 15,
                        strike3: 18,
                        strike4: 20,
                        conse_days: 12,
                        payoutrate1: 3000.00,
                        payoutrate2: 5000.00,
                        payoutrate3: 8000.00,
                        payoutrate4: 13000.00,
                        payout: 0.00,
                        totalmaxpayout: 13000.00,
                        eligiblepayout: 0.00
                    },
                    diseasecongenial: {
                        strike: 3,
                        conse_days: 3,
                        exit: 7,
                        payoutrate: 2000.00,
                        payout: 2000.00,
                        totalmaxpayout: 10000.00,
                        eligiblepayout: 2000.00
                    },
                    hightemp: {
                        trigger1: 31,
                        trigger2: 31.50,
                        trigger3: 32,
                        trigger4: 32.50,
                        totdevian: 44.30,
                        strike: 4.00,
                        exit: 36,
                        payoutrate: 531.25,
                        maxpayout: 17000.00,
                        actualphasepayout: 17000.00,
                        totalmaxpayout: 17000.00,
                        eligiblepayout: 17000.00
                    },
                    excess_rainfall: {
                        strike: 150,
                        threedaycumm: 171.94,
                        maxpayout: 20000.00,
                        payout: 20000.00,
                        totalmaxpayout: 20000.00,
                        eligiblepayout: 20000.00
                    }
                }
            }
        }
    },
    pattanchery: {
        lat: 10.648,
        lng: 76.733,
        kharif: {
            paddy: {
                aic: {
                    deficit_rainfall: {
                        strike1: 300,
                        strike2: 250,
                        strike3: 250,
                        strike4: "--",
                        aggrain1: 189.23,
                        aggrain2: 396.47,
                        aggrain3: 518.89,
                        aggrain4: "--",
                        phaseagg1: 110.77,
                        phaseagg2: 0.00,
                        phaseagg3: 0.00,
                        phaseagg4: "--",
                        exit1: 60,
                        exit2: 60,
                        exit3: 60,
                        exit4: "--",
                        excond1: "N",
                        excond2: "N",
                        excond3: "N",
                        excond4: "--",
                        payoutrate1: 29.17,
                        payoutrate2: 26.32,
                        payoutrate3: 26.32,
                        payoutrate4: "--",
                        maxpayout1: 7000.00,
                        maxpayout2: 5000.00,
                        maxpayout3: 5000.00,
                        maxpayout4: "--",
                        payoutperphase1: 3231.16,
                        payoutperphase2: 0.00,
                        payoutperphase3: 0.00,
                        payoutperphase4: "--",
                        payoutonexit1: 0.00,
                        payoutonexit2: 0.00,
                        payoutonexit3: 0.00,
                        payoutonexit4: "--",
                        actualphasepayout1: 3231.16,
                        actualphasepayout2: 0.00,
                        actualphasepayout3: 0.00,
                        actualphasepayout4: "--",
                        cummpayout1: 3231.16,
                        cummpayout2: 3231.16,
                        cummpayout3: 3231.16,
                        cummpayout4: "--",
                        totalmaxpayout: 20000.00,
                        eligiblepayout: 3231.16
                    },
                    dryspell: {
                        strike1: 13,
                        strike2: 15,
                        strike3: 18,
                        strike4: 20,
                        conse_days: 12,
                        payoutrate1: 3000.00,
                        payoutrate2: 5000.00,
                        payoutrate3: 8000.00,
                        payoutrate4: 13000.00,
                        payout: 0.00,
                        totalmaxpayout: 13000.00,
                        eligiblepayout: 0.00
                    },
                    diseasecongenial: {
                        strike: 3,
                        conse_days: 3,
                        exit: 7,
                        payoutrate: 2000.00,
                        payout: 2000.00,
                        totalmaxpayout: 10000.00,
                        eligiblepayout: 2000.00
                    },
                    hightemp: {
                        trigger1: 31,
                        trigger2: 31.50,
                        trigger3: 32,
                        trigger4: 32.50,
                        totdevian: 44.30,
                        strike: 4.00,
                        exit: 36,
                        payoutrate: 531.25,
                        maxpayout: 17000.00,
                        actualphasepayout: 17000.00,
                        totalmaxpayout: 17000.00,
                        eligiblepayout: 17000.00
                    },
                    excess_rainfall: {
                        strike: 150,
                        threedaycumm: 171.94,
                        maxpayout: 20000.00,
                        payout: 20000.00,
                        totalmaxpayout: 20000.00,
                        eligiblepayout: 20000.00
                    }
                },
                meteoblue: {
                    deficit_rainfall: {
                        strike1: 300,
                        strike2: 250,
                        strike3: 250,
                        strike4: 200,
                        aggrain1: 69.30,
                        aggrain2: 19.60,
                        aggrain3: 58.60,
                        aggrain4: 14.00,
                        phaseagg1: 230.70,
                        phaseagg2: 230.40,
                        phaseagg3: 191.40,
                        phaseagg4: 186.00,
                        exit1: 60,
                        exit2: 60,
                        exit3: 60,
                        exit4: 60,
                        excond1: "N",
                        excond2: "Y",
                        excond3: "Y",
                        excond4: "Y",
                        payoutrate1: 29.17,
                        payoutrate2: 26.32,
                        payoutrate3: 26.32,
                        payoutrate4: 21.43,
                        maxpayout1: 7000.00,
                        maxpayout2: 5000.00,
                        maxpayout3: 5000.00,
                        maxpayout4: 3000.00,
                        payoutperphase1: 6730.00,
                        payoutperphase2: 6064.00,
                        payoutperphase3: 5038.00,
                        payoutperphase4: 3986.00,
                        payoutonexit1: 0.00,
                        payoutonexit2: 5000.00,
                        payoutonexit3: 5000.00,
                        payoutonexit4: 3000.00,
                        actualphasepayout1: 6730.00,
                        actualphasepayout2: 5000.00,
                        actualphasepayout3: 5000.00,
                        actualphasepayout4: 3000.00,
                        cummpayout1: 6730.00,
                        cummpayout2: 11730.00,
                        cummpayout3: 16730.00,
                        cummpayout4: 19730.00,
                        totalmaxpayout: 20000.00,
                        eligiblepayout: 19730.00
                    },
                    excess_rainfall: {
                        strike: 150,
                        threedaycumm: 54.80,
                        maxpayout: 20000.00,
                        payout: 0.00,
                        totalmaxpayout: 20000.00,
                        eligiblepayout: 0.00
                    },
                    hightemp: {
                        trigger1: 31,
                        trigger2: 31.50,
                        trigger3: 32,
                        trigger4: 32.50,
                        totdevian: 26.25,
                        strike: 4.00,
                        exit: 36,
                        payoutrate: 531.25,
                        maxpayout: 17000.00,
                        actualphasepayout: 11820.00,
                        totalmaxpayout: 17000.00,
                        eligiblepayout: 11820.00
                    },
                    diseasecongenial: {
                        strike: 3,
                        conse_days: 0,
                        exit: 7,
                        payoutrate: 2000.00,
                        payout: 0.00,
                        totalmaxpayout: 10000.00,
                        eligiblepayout: 0.00
                    },
                    dryspell: {
                        strike1: 13,
                        strike2: 15,
                        strike3: 18,
                        strike4: 20,
                        conse_days: 24,
                        payoutrate1: 3000.00,
                        payoutrate2: 5000.00,
                        payoutrate3: 8000.00,
                        payoutrate4: 13000.00,
                        payout: 13000.00,
                        totalmaxpayout: 13000.00,
                        eligiblepayout: 13000.00
                    }

                },
                nasa: {
                    deficit_rainfall: {
                        strike1: 300,
                        strike2: 250,
                        strike3: 250,
                        strike4: 200,
                        aggrain1: 186.82,
                        aggrain2: 309.67,
                        aggrain3: 306.16,
                        aggrain4: 264.42,
                        phaseagg1: 113.18,
                        phaseagg2: 0.00,
                        phaseagg3: 0.00,
                        phaseagg4: 0.00,
                        exit1: 60,
                        exit2: 60,
                        exit3: 60,
                        exit4: 60,
                        excond1: "N",
                        excond2: "N",
                        excond3: "N",
                        excond4: "N",
                        payoutrate1: 29.17,
                        payoutrate2: 26.32,
                        payoutrate3: 26.32,
                        payoutrate4: 21.43,
                        maxpayout1: 7000.00,
                        maxpayout2: 5000.00,
                        maxpayout3: 5000.00,
                        maxpayout4: 3000.00,
                        payoutperphase1: 3302.00,
                        payoutperphase2: 0.00,
                        payoutperphase3: 0.00,
                        payoutperphase4: 0.00,
                        payoutonexit1: 0.00,
                        payoutonexit2: 0.00,
                        payoutonexit3: 0.00,
                        payoutonexit4: 0.00,
                        actualphasepayout1: 3302.00,
                        actualphasepayout2: 0.00,
                        actualphasepayout3: 0.00,
                        actualphasepayout4: 0.00,
                        cummpayout1: 3302.00,
                        cummpayout2: 3302.00,
                        cummpayout3: 3302.00,
                        cummpayout4: 3302.00,
                        totalmaxpayout: 20000.00,
                        eligiblepayout: 3301.57
                    },
                    dryspell: {
                        strike1: 13,
                        strike2: 15,
                        strike3: 18,
                        strike4: 20,
                        conse_days: 5,
                        payoutrate1: 3000.00,
                        payoutrate2: 5000.00,
                        payoutrate3: 8000.00,
                        payoutrate4: 13000.00,
                        payout: 0.00,
                        totalmaxpayout: 13000.00,
                        eligiblepayout: 0.00
                    },
                    diseasecongenial: {
                        strike: 3,
                        conse_days: 0,
                        exit: 7,
                        payoutrate: 2000.00,
                        payout: 0.00,
                        totalmaxpayout: 10000.00,
                        eligiblepayout: 0.00
                    },
                    hightemp: {
                        trigger1: 31,
                        trigger2: 31.50,
                        trigger3: 32,
                        trigger4: 32.50,
                        totdevian: 26.25,
                        strike: 4.00,
                        exit: 36,
                        payoutrate: 531.25,
                        maxpayout: 17000.00,
                        actualphasepayout: 0.00,
                        totalmaxpayout: 17000.00,
                        eligiblepayout: 0.00
                    },
                    excess_rainfall: {
                        strike: 150,
                        threedaycumm: 143.48,
                        maxpayout: 20000.00,
                        payout: 0.00,
                        totalmaxpayout: 20000.00,
                        eligiblepayout: 0.00
                    }
                },
                ecmwf: {
                    deficit_rainfall: {
                        strike1: 300,
                        strike2: 250,
                        strike3: 250,
                        strike4: "--",
                        aggrain1: 259.03,
                        aggrain2: 663.78,
                        aggrain3: 44.44,
                        aggrain4: "--",
                        phaseagg1: 40.97,
                        phaseagg2: 0.00,
                        phaseagg3: 205.56,
                        phaseagg4: "--",
                        exit1: 60,
                        exit2: 60,
                        exit3: 60,
                        exit4: "--",
                        excond1: "N",
                        excond2: "N",
                        excond3: "Y",
                        excond4: "--",
                        payoutrate1: 29.17,
                        payoutrate2: 26.32,
                        payoutrate3: 26.32,
                        payoutrate4: "--",
                        maxpayout1: 7000.00,
                        maxpayout2: 5000.00,
                        maxpayout3: 5000.00,
                        maxpayout4: "--",
                        payoutperphase1: 1195.00,
                        payoutperphase2: 0.00,
                        payoutperphase3: 5410.00,
                        payoutperphase4: "--",
                        payoutonexit1: 0.00,
                        payoutonexit2: 0.00,
                        payoutonexit3: 5000.00,
                        payoutonexit4: "--",
                        actualphasepayout1: 1195.00,
                        actualphasepayout2: 0.00,
                        actualphasepayout3: 5000.00,
                        actualphasepayout4: "--",
                        cummpayout1: 1195.00,
                        cummpayout2: 1195.00,
                        cummpayout3: 6195.00,
                        cummpayout4: "--",
                        totalmaxpayout: 20000.00,
                        eligiblepayout: 6195.10
                    },
                    excess_rainfall: {
                        strike: 150,
                        threedaycumm: 173.70,
                        maxpayout: 20000.00,
                        payout: 20000.00,
                        totalmaxpayout: 20000.00,
                        eligiblepayout: 20000.00
                    },
                    hightemp: {
                        trigger1: 31,
                        trigger2: 31.50,
                        trigger3: 32,
                        trigger4: 32.50,
                        totdevian: 2.00,
                        strike: 4.00,
                        exit: 36,
                        payoutrate: 531.25,
                        maxpayout: 17000.00,
                        actualphasepayout: 0.00,
                        totalmaxpayout: 17000.00,
                        eligiblepayout: 0.00
                    },
                    diseasecongenial: {
                        strike: 3,
                        conse_days: 0,
                        exit: 7,
                        payoutrate: 2000.00,
                        payout: 0.00,
                        totalmaxpayout: 10000.00,
                        eligiblepayout: 0.00
                    },
                    dryspell: {
                        strike1: 13,
                        strike2: 15,
                        strike3: 18,
                        strike4: 20,
                        conse_days: 5,
                        payoutrate1: 3000.00,
                        payoutrate2: 5000.00,
                        payoutrate3: 8000.00,
                        payoutrate4: 13000.00,
                        payout: 0.00,
                        totalmaxpayout: 13000.00,
                        eligiblepayout: 0.00
                    },

                }

            }
        }
    },
    perumatty: {
        lat: 10.650,
        lng: 76.763,
        kharif: {
            paddy: {
                aic: {
                    deficit_rainfall: {
                        strike1: 300,
                        strike2: 250,
                        strike3: 250,
                        strike4: "--",
                        aggrain1: 189.23,
                        aggrain2: 396.47,
                        aggrain3: 518.89,
                        aggrain4: "--",
                        phaseagg1: 110.77,
                        phaseagg2: 0.00,
                        phaseagg3: 0.00,
                        phaseagg4: "--",
                        exit1: 60,
                        exit2: 60,
                        exit3: 60,
                        exit4: "--",
                        excond1: "N",
                        excond2: "N",
                        excond3: "N",
                        excond4: "--",
                        payoutrate1: 29.17,
                        payoutrate2: 26.32,
                        payoutrate3: 26.32,
                        payoutrate4: "--",
                        maxpayout1: 7000.00,
                        maxpayout2: 5000.00,
                        maxpayout3: 5000.00,
                        maxpayout4: "--",
                        payoutperphase1: 3231.16,
                        payoutperphase2: 0.00,
                        payoutperphase3: 0.00,
                        payoutperphase4: "--",
                        payoutonexit1: 0.00,
                        payoutonexit2: 0.00,
                        payoutonexit3: 0.00,
                        payoutonexit4: "--",
                        actualphasepayout1: 3231.16,
                        actualphasepayout2: 0.00,
                        actualphasepayout3: 0.00,
                        actualphasepayout4: "--",
                        cummpayout1: 3231.16,
                        cummpayout2: 3231.16,
                        cummpayout3: 3231.16,
                        cummpayout4: "--",
                        totalmaxpayout: 20000.00,
                        eligiblepayout: 3231.16
                    },
                    dryspell: {
                        strike1: 13,
                        strike2: 15,
                        strike3: 18,
                        strike4: 20,
                        conse_days: 12,
                        payoutrate1: 3000.00,
                        payoutrate2: 5000.00,
                        payoutrate3: 8000.00,
                        payoutrate4: 13000.00,
                        payout: 0.00,
                        totalmaxpayout: 13000.00,
                        eligiblepayout: 0.00
                    },
                    diseasecongenial: {
                        strike: 3,
                        conse_days: 3,
                        exit: 7,
                        payoutrate: 2000.00,
                        payout: 2000.00,
                        totalmaxpayout: 10000.00,
                        eligiblepayout: 2000.00
                    },
                    hightemp: {
                        trigger1: 31,
                        trigger2: 31.50,
                        trigger3: 32,
                        trigger4: 32.50,
                        totdevian: 44.30,
                        strike: 4.00,
                        exit: 36,
                        payoutrate: 531.25,
                        maxpayout: 17000.00,
                        actualphasepayout: 17000.00,
                        totalmaxpayout: 17000.00,
                        eligiblepayout: 17000.00
                    },
                    excess_rainfall: {
                        strike: 150,
                        threedaycumm: 171.94,
                        maxpayout: 20000.00,
                        payout: 20000.00,
                        totalmaxpayout: 20000.00,
                        eligiblepayout: 20000.00
                    }
                },
                meteoblue: {
                    deficit_rainfall: {
                        strike1: 300,
                        strike2: 250,
                        strike3: 250,
                        strike4: 200,
                        aggrain1: 75.00,
                        aggrain2: 11.70,
                        aggrain3: 38.50,
                        aggrain4: 8.90,
                        phaseagg1: 225.00,
                        phaseagg2: 238.30,
                        phaseagg3: 211.50,
                        phaseagg4: 191.10,
                        exit1: 60,
                        exit2: 60,
                        exit3: 60,
                        exit4: 60,
                        excond1: "N",
                        excond2: "Y",
                        excond3: "Y",
                        excond4: "Y",
                        payoutrate1: 29.17,
                        payoutrate2: 26.32,
                        payoutrate3: 26.32,
                        payoutrate4: 21.43,
                        maxpayout1: 7000.00,
                        maxpayout2: 5000.00,
                        maxpayout3: 5000.00,
                        maxpayout4: 3000.00,
                        payoutperphase1: 6563.00,
                        payoutperphase2: 6272.00,
                        payoutperphase3: 5567.00,
                        payoutperphase4: 4095.00,
                        payoutonexit1: 0.00,
                        payoutonexit2: 5000.00,
                        payoutonexit3: 5000.00,
                        payoutonexit4: 3000.00,
                        actualphasepayout1: 6563.00,
                        actualphasepayout2: 5000.00,
                        actualphasepayout3: 5000.00,
                        actualphasepayout4: 3000.00,
                        cummpayout1: 6563.00,
                        cummpayout2: 11563.00,
                        cummpayout3: 16563.00,
                        cummpayout4: 19563.00,
                        totalmaxpayout: 20000.00,
                        eligiblepayout: 19563.00
                    },
                    excess_rainfall: {
                        strike: 150,
                        threedaycumm: 56.90,
                        maxpayout: 20000.00,
                        payout: 0.00,
                        totalmaxpayout: 20000.00,
                        eligiblepayout: 0.00
                    },
                    hightemp: {
                        trigger1: 31,
                        trigger2: 31.50,
                        trigger3: 32,
                        trigger4: 32.50,
                        totdevian: 44.30,
                        strike: 4.00,
                        exit: 36,
                        payoutrate: 531.25,
                        maxpayout: 17000.00,
                        actualphasepayout: 5690.00,
                        totalmaxpayout: 17000.00,
                        eligiblepayout: 5690.00
                    },
                    diseasecongenial: {
                        strike: 3,
                        conse_days: 0,
                        exit: 7,
                        payoutrate: 2000.00,
                        payout: 0.00,
                        totalmaxpayout: 10000.00,
                        eligiblepayout: 0.00
                    },
                    dryspell: {
                        strike1: 13,
                        strike2: 15,
                        strike3: 18,
                        strike4: 20,
                        conse_days: 29,
                        payoutrate1: 3000.00,
                        payoutrate2: 5000.00,
                        payoutrate3: 8000.00,
                        payoutrate4: 13000.00,
                        payout: 13000.00,
                        totalmaxpayout: 13000.00,
                        eligiblepayout: 13000.00
                    }
                },
                nasa: {
                    deficit_rainfall: {
                        strike1: 300,
                        strike2: 250,
                        strike3: 250,
                        strike4: 200,
                        aggrain1: 186.43,
                        aggrain2: 311.05,
                        aggrain3: 309.76,
                        aggrain4: 259.90,
                        phaseagg1: 113.57,
                        phaseagg2: 0.00,
                        phaseagg3: 0.00,
                        phaseagg4: 0.00,
                        exit1: 60,
                        exit2: 60,
                        exit3: 60,
                        exit4: 60,
                        excond1: "N",
                        excond2: "N",
                        excond3: "N",
                        excond4: "N",
                        payoutrate1: 29.17,
                        payoutrate2: 26.32,
                        payoutrate3: 26.32,
                        payoutrate4: 21.43,
                        maxpayout1: 7000.00,
                        maxpayout2: 5000.00,
                        maxpayout3: 5000.00,
                        maxpayout4: 3000.00,
                        payoutperphase1: 3313.00,
                        payoutperphase2: 0.00,
                        payoutperphase3: 0.00,
                        payoutperphase4: 0.00,
                        payoutonexit1: 0.00,
                        payoutonexit2: 0.00,
                        payoutonexit3: 0.00,
                        payoutonexit4: 0.00,
                        actualphasepayout1: 3313.00,
                        actualphasepayout2: 0.00,
                        actualphasepayout3: 0.00,
                        actualphasepayout4: 0.00,
                        cummpayout1: 3313.00,
                        cummpayout2: 3313.00,
                        cummpayout3: 3313.00,
                        cummpayout4: 3313.00,
                        totalmaxpayout: 20000.00,
                        eligiblepayout: 3312.91
                    },
                    excess_rainfall: {
                        strike: 150,
                        threedaycumm: 138.61,
                        maxpayout: 20000.00,
                        payout: 0.00,
                        totalmaxpayout: 20000.00,
                        eligiblepayout: 0.00
                    },
                    hightemp: {
                        trigger1: 31,
                        trigger2: 31.50,
                        trigger3: 32,
                        trigger4: 32.50,
                        totdevian: 0.00,
                        strike: 4.00,
                        exit: 36,
                        payoutrate: 531.25,
                        maxpayout: 17000.00,
                        actualphasepayout: 0.00,
                        totalmaxpayout: 17000.00,
                        eligiblepayout: 0.00
                    },
                    diseasecongenial: {
                        strike: 3,
                        conse_days: 0,
                        exit: 7,
                        payoutrate: 2000.00,
                        payout: 0.00,
                        totalmaxpayout: 10000.00,
                        eligiblepayout: 0.00
                    },
                    dryspell: {
                        strike1: 13,
                        strike2: 15,
                        strike3: 18,
                        strike4: 20,
                        conse_days: 5,
                        payoutrate1: 3000.00,
                        payoutrate2: 5000.00,
                        payoutrate3: 8000.00,
                        payoutrate4: 13000.00,
                        payout: 0.00,
                        totalmaxpayout: 13000.00,
                        eligiblepayout: 0.00
                    }
                },
                ecmwf: {
                    deficit_rainfall: {
                        strike1: 300,
                        strike2: 250,
                        strike3: 250,
                        strike4: "--",
                        aggrain1: 269.45,
                        aggrain2: 700.04,
                        aggrain3: 60.25,
                        aggrain4: "--",
                        phaseagg1: 30.55,
                        phaseagg2: 0.00,
                        phaseagg3: 189.75,
                        phaseagg4: "--",
                        exit1: 60,
                        exit2: 60,
                        exit3: 60,
                        exit4: "--",
                        excond1: "N",
                        excond2: "N",
                        excond3: "N",
                        excond4: "--",
                        payoutrate1: 29.17,
                        payoutrate2: 26.32,
                        payoutrate3: 26.32,
                        payoutrate4: "--",
                        maxpayout1: 7000.00,
                        maxpayout2: 5000.00,
                        maxpayout3: 5000.00,
                        maxpayout4: "--",
                        payoutperphase1: 891.00,
                        payoutperphase2: 0.00,
                        payoutperphase3: 4994.00,
                        payoutperphase4: "--",
                        payoutonexit1: 0.00,
                        payoutonexit2: 0.00,
                        payoutonexit3: 0.00,
                        payoutonexit4: "--",
                        actualphasepayout1: 891.00,
                        actualphasepayout2: 0.00,
                        actualphasepayout3: 4994.00,
                        actualphasepayout4: "--",
                        cummpayout1: 891.00,
                        cummpayout2: 891.00,
                        cummpayout3: 5886.00,
                        cummpayout4: "--",
                        totalmaxpayout: 20000.00,
                        eligiblepayout: 5885.51
                    },
                    excess_rainfall: {
                        strike: 150,
                        threedaycumm: 188.33,
                        maxpayout: 20000.00,
                        payout: 20000.00,
                        totalmaxpayout: 20000.00,
                        eligiblepayout: 20000.00
                    },
                    hightemp: {
                        trigger1: 31,
                        trigger2: 31.50,
                        trigger3: 32,
                        trigger4: 32.50,
                        totdevian: 2.00,
                        strike: 4.00,
                        exit: 36,
                        payoutrate: 531.25,
                        maxpayout: 17000.00,
                        actualphasepayout: 0.00,
                        totalmaxpayout: 17000.00,
                        eligiblepayout: 0.00
                    },
                    diseasecongenial: {
                        strike: 3,
                        conse_days: 0,
                        exit: 7,
                        payoutrate: 2000.00,
                        payout: 0.00,
                        totalmaxpayout: 10000.00,
                        eligiblepayout: 0.00
                    },
                    dryspell: {
                        strike1: 13,
                        strike2: 15,
                        strike3: 18,
                        strike4: 20,
                        conse_days: 5,
                        payoutrate1: 3000.00,
                        payoutrate2: 5000.00,
                        payoutrate3: 8000.00,
                        payoutrate4: 13000.00,
                        payout: 0.00,
                        totalmaxpayout: 13000.00,
                        eligiblepayout: 0.00
                    }
                }
            }
        }
    },
    eruthenpathy: {
        lat: 10.749,
        lng: 76.859,
        kharif: {
            paddy: {
                aic: {
                    deficit_rainfall: {
                        strike1: 300,
                        strike2: 250,
                        strike3: 250,
                        strike4: "--",
                        aggrain1: 186.39,
                        aggrain2: 414.98,
                        aggrain3: 484.88,
                        aggrain4: "--",
                        phaseagg1: 113.61,
                        phaseagg2: 0.00,
                        phaseagg3: 0.00,
                        phaseagg4: "--",
                        exit1: 60,
                        exit2: 60,
                        exit3: 60,
                        exit4: "--",
                        excond1: "N",
                        excond2: "N",
                        excond3: "N",
                        excond4: "--",
                        payoutrate1: 29.17,
                        payoutrate2: 26.32,
                        payoutrate3: 26.32,
                        payoutrate4: "--",
                        maxpayout1: 7000.00,
                        maxpayout2: 5000.00,
                        maxpayout3: 5000.00,
                        maxpayout4: "--",
                        payoutperphase1: 3314.00,
                        payoutperphase2: 0.00,
                        payoutperphase3: 0.00,
                        payoutperphase4: "--",
                        payoutonexit1: 0.00,
                        payoutonexit2: 0.00,
                        payoutonexit3: 0.00,
                        payoutonexit4: "--",
                        actualphasepayout1: 3314.00,
                        actualphasepayout2: 0.00,
                        actualphasepayout3: 0.00,
                        actualphasepayout4: "--",
                        cummpayout1: 3314.00,
                        cummpayout2: 3314.00,
                        cummpayout3: 3314.00,
                        cummpayout4: "--",
                        totalmaxpayout: 20000.00,
                        eligiblepayout: 3314.00
                    },
                    dryspell: {
                        strike1: 13,
                        strike2: 15,
                        strike3: 18,
                        strike4: 20,
                        conse_days: 12,
                        payoutrate1: 3000.00,
                        payoutrate2: 5000.00,
                        payoutrate3: 8000.00,
                        payoutrate4: 13000.00,
                        payout: 0.00,
                        totalmaxpayout: 13000.00,
                        eligiblepayout: 0.00
                    },
                    diseasecongenial: {
                        strike: 3,
                        conse_days: 4,
                        exit: 7,
                        payoutrate: 2000.00,
                        payout: 4000.00,
                        totalmaxpayout: 10000.00,
                        eligiblepayout: 4000.00
                    },
                    hightemp: {
                        trigger1: 31,
                        trigger2: 31.50,
                        trigger3: 32,
                        trigger4: 32.50,
                        totdevian: 44.30,
                        strike: 4.00,
                        exit: 36,
                        payoutrate: 531.25,
                        maxpayout: 17000.00,
                        actualphasepayout: 17000.00,
                        totalmaxpayout: 17000.00,
                        eligiblepayout: 17000.00
                    },
                    excess_rainfall: {
                        strike: 150,
                        threedaycumm: 173.73,
                        maxpayout: 20000.00,
                        payout: 20000.00,
                        totalmaxpayout: 20000.00,
                        eligiblepayout: 20000.00
                    }

                },
                meteoblue: {
                    deficit_rainfall: {
                        strike1: 300,
                        strike2: 250,
                        strike3: 250,
                        strike4: 200,
                        aggrain1: 75.20,
                        aggrain2: 6.60,
                        aggrain3: 33.70,
                        aggrain4: 7.30,
                        phaseagg1: 224.80,
                        phaseagg2: 243.40,
                        phaseagg3: 216.30,
                        phaseagg4: 192.70,
                        exit1: 60,
                        exit2: 60,
                        exit3: 60,
                        exit4: 60,
                        excond1: "N",
                        excond2: "Y",
                        excond3: "Y",
                        excond4: "Y",
                        payoutrate1: 29.17,
                        payoutrate2: 26.32,
                        payoutrate3: 26.32,
                        payoutrate4: 21.43,
                        maxpayout1: 7000.00,
                        maxpayout2: 5000.00,
                        maxpayout3: 5000.00,
                        maxpayout4: 3000.00,
                        payoutperphase1: 6557.00,
                        payoutperphase2: 6406.00,
                        payoutperphase3: 5693.00,
                        payoutperphase4: 4130.00,
                        payoutonexit1: 0.00,
                        payoutonexit2: 5000.00,
                        payoutonexit3: 5000.00,
                        payoutonexit4: 3000.00,
                        actualphasepayout1: 6557.00,
                        actualphasepayout2: 5000.00,
                        actualphasepayout3: 5000.00,
                        actualphasepayout4: 3000.00,
                        cummpayout1: 6557.00,
                        cummpayout2: 11557.00,
                        cummpayout3: 16557.00,
                        cummpayout4: 19557.00,
                        totalmaxpayout: 20000.00,
                        eligiblepayout: 19557.00
                    },
                    dryspell: {
                        strike1: 13,
                        strike2: 15,
                        strike3: 18,
                        strike4: 20,
                        conse_days: 35,
                        payoutrate1: 3000.00,
                        payoutrate2: 5000.00,
                        payoutrate3: 8000.00,
                        payoutrate4: 13000.00,
                        payout: 13000.00,
                        totalmaxpayout: 13000.00,
                        eligiblepayout: 13000.00
                    },
                    diseasecongenial: {
                        strike: 3,
                        conse_days: 0,
                        exit: 7,
                        payoutrate: 2000.00,
                        payout: 0.00,
                        totalmaxpayout: 10000.00,
                        eligiblepayout: 0.00
                    },
                    hightemp: {
                        trigger1: 31,
                        trigger2: 31.50,
                        trigger3: 32,
                        trigger4: 32.50,
                        totdevian: 7.22,
                        strike: 4.00,
                        exit: 36,
                        payoutrate: 531.25,
                        maxpayout: 17000.00,
                        actualphasepayout: 1711.00,
                        totalmaxpayout: 17000.00,
                        eligiblepayout: 1711.00
                    },
                    excess_rainfall: {
                        strike: 150,
                        threedaycumm: 60.40,
                        maxpayout: 20000.00,
                        payout: 0.00,
                        totalmaxpayout: 20000.00,
                        eligiblepayout: 0.00
                    }

                },
                ecmwf: {
                    deficit_rainfall: {
                        strike1: 300,
                        strike2: 250,
                        strike3: 250,
                        strike4: "--",
                        aggrain1: 289.97,
                        aggrain2: 705.08,
                        aggrain3: 69.96,
                        aggrain4: "--",
                        phaseagg1: 10.03,
                        phaseagg2: 0.00,
                        phaseagg3: 180.04,
                        phaseagg4: "--",
                        exit1: 60,
                        exit2: 60,
                        exit3: 60,
                        exit4: "--",
                        excond1: "N",
                        excond2: "N",
                        excond3: "N",
                        excond4: "--",
                        payoutrate1: 29.17,
                        payoutrate2: 26.32,
                        payoutrate3: 26.32,
                        payoutrate4: "--",
                        maxpayout1: 7000.00,
                        maxpayout2: 5000.00,
                        maxpayout3: 5000.00,
                        maxpayout4: "--",
                        payoutperphase1: 293.00,
                        payoutperphase2: 0.00,
                        payoutperphase3: 4739.00,
                        payoutperphase4: "--",
                        payoutonexit1: 0.00,
                        payoutonexit2: 0.00,
                        payoutonexit3: 0.00,
                        payoutonexit4: "--",
                        actualphasepayout1: 293.00,
                        actualphasepayout2: 0.00,
                        actualphasepayout3: 4739.00,
                        actualphasepayout4: "--",
                        cummpayout1: 293.00,
                        cummpayout2: 293.00,
                        cummpayout3: 5031.00,
                        cummpayout4: "--",
                        totalmaxpayout: 20000.00,
                        eligiblepayout: 5031.12
                    },
                    dryspell: {
                        strike1: 13,
                        strike2: 15,
                        strike3: 18,
                        strike4: 20,
                        conse_days: 2,
                        payoutrate1: 3000.00,
                        payoutrate2: 5000.00,
                        payoutrate3: 8000.00,
                        payoutrate4: 13000.00,
                        payout: 0.00,
                        totalmaxpayout: 13000.00,
                        eligiblepayout: 0.00
                    },
                    diseasecongenial: {
                        strike: 3,
                        conse_days: 0,
                        exit: 7,
                        payoutrate: 2000.00,
                        payout: 0.00,
                        totalmaxpayout: 10000.00,
                        eligiblepayout: 0.00
                    },
                    hightemp: {
                        trigger1: 31,
                        trigger2: 31.50,
                        trigger3: 32,
                        trigger4: 32.50,
                        totdevian: 2.00,
                        strike: 4.00,
                        exit: 36,
                        payoutrate: 531.25,
                        maxpayout: 17000.00,
                        actualphasepayout: 0.00,
                        totalmaxpayout: 17000.00,
                        eligiblepayout: 0.00
                    },
                    excess_rainfall: {
                        strike: 150,
                        threedaycumm: 201.58,
                        maxpayout: 20000.00,
                        payout: 20000.00,
                        totalmaxpayout: 20000.00,
                        eligiblepayout: 20000.00
                    }
                },
                nasa: {
                    deficit_rainfall: {
                        strike1: 300,
                        strike2: 250,
                        strike3: 250,
                        strike4: 200,
                        aggrain1: 183.11,
                        aggrain2: 298.02,
                        aggrain3: 302.53,
                        aggrain4: 257.00,
                        phaseagg1: 116.89,
                        phaseagg2: 0.00,
                        phaseagg3: 0.00,
                        phaseagg4: 0.00,
                        exit1: 60,
                        exit2: 60,
                        exit3: 60,
                        exit4: 60,
                        excond1: "N",
                        excond2: "N",
                        excond3: "N",
                        excond4: "N",
                        payoutrate1: 29.17,
                        payoutrate2: 26.32,
                        payoutrate3: 26.32,
                        payoutrate4: 21.43,
                        maxpayout1: 7000.00,
                        maxpayout2: 5000.00,
                        maxpayout3: 5000.00,
                        maxpayout4: 3000.00,
                        payoutperphase1: 3410.00,
                        payoutperphase2: 0.00,
                        payoutperphase3: 0.00,
                        payoutperphase4: 0.00,
                        payoutonexit1: 0.00,
                        payoutonexit2: 0.00,
                        payoutonexit3: 0.00,
                        payoutonexit4: 0.00,
                        actualphasepayout1: 3410.00,
                        actualphasepayout2: 0.00,
                        actualphasepayout3: 0.00,
                        actualphasepayout4: 0.00,
                        cummpayout1: 3410.00,
                        cummpayout2: 293.00,
                        cummpayout3: 3410.00,
                        cummpayout4: 3410.00,
                        totalmaxpayout: 20000.00,
                        eligiblepayout: 3409.70
                    },
                    dryspell: {
                        strike1: 13,
                        strike2: 15,
                        strike3: 18,
                        strike4: 20,
                        conse_days: 2,
                        payoutrate1: 3000.00,
                        payoutrate2: 5000.00,
                        payoutrate3: 8000.00,
                        payoutrate4: 13000.00,
                        payout: 0.00,
                        totalmaxpayout: 13000.00,
                        eligiblepayout: 0.00
                    },
                    diseasecongenial: {
                        strike: 3,
                        conse_days: 0,
                        exit: 7,
                        payoutrate: 2000.00,
                        payout: 0.00,
                        totalmaxpayout: 10000.00,
                        eligiblepayout: 0.00
                    },
                    hightemp: {
                        trigger1: 31,
                        trigger2: 31.50,
                        trigger3: 32,
                        trigger4: 32.50,
                        totdevian: 0.00,
                        strike: 4.00,
                        exit: 36,
                        payoutrate: 531.25,
                        maxpayout: 17000.00,
                        actualphasepayout: 0.00,
                        totalmaxpayout: 17000.00,
                        eligiblepayout: 0.00
                    },
                    excess_rainfall: {
                        strike: 150,
                        threedaycumm: 138.05,
                        maxpayout: 20000.00,
                        payout: 0.00,
                        totalmaxpayout: 20000.00,
                        eligiblepayout: 0.00
                    }
                }
            }
        }
    },
    vadakarapathy: {
        lat: 10.779,
        lng: 76.860,
        kharif: {
            paddy: {
                aic: {
                    deficit_rainfall: {
                        strike1: 300,
                        strike2: 250,
                        strike3: 250,
                        strike4: "--",
                        aggrain1: 186.39,
                        aggrain2: 414.98,
                        aggrain3: 484.88,
                        aggrain4: "--",
                        phaseagg1: 113.61,
                        phaseagg2: 0.00,
                        phaseagg3: 0.00,
                        phaseagg4: "--",
                        exit1: 60,
                        exit2: 60,
                        exit3: 60,
                        exit4: "--",
                        excond1: "N",
                        excond2: "N",
                        excond3: "N",
                        excond4: "--",
                        payoutrate1: 29.17,
                        payoutrate2: 26.32,
                        payoutrate3: 26.32,
                        payoutrate4: "--",
                        maxpayout1: 7000.00,
                        maxpayout2: 5000.00,
                        maxpayout3: 5000.00,
                        maxpayout4: "--",
                        payoutperphase1: 3314.00,
                        payoutperphase2: 0.00,
                        payoutperphase3: 0.00,
                        payoutperphase4: "--",
                        payoutonexit1: 0.00,
                        payoutonexit2: 0.00,
                        payoutonexit3: 0.00,
                        payoutonexit4: "--",
                        actualphasepayout1: 3314.00,
                        actualphasepayout2: 0.00,
                        actualphasepayout3: 0.00,
                        actualphasepayout4: "--",
                        cummpayout1: 3314.00,
                        cummpayout2: 3314.00,
                        cummpayout3: 3314.00,
                        cummpayout4: "--",
                        totalmaxpayout: 20000.00,
                        eligiblepayout: 3314.00
                    },
                    dryspell: {
                        strike1: 13,
                        strike2: 15,
                        strike3: 18,
                        strike4: 20,
                        conse_days: 12,
                        payoutrate1: 3000.00,
                        payoutrate2: 5000.00,
                        payoutrate3: 8000.00,
                        payoutrate4: 13000.00,
                        payout: 0.00,
                        totalmaxpayout: 13000.00,
                        eligiblepayout: 0.00
                    },
                    diseasecongenial: {
                        strike: 3,
                        conse_days: 4,
                        exit: 7,
                        payoutrate: 2000.00,
                        payout: 4000.00,
                        totalmaxpayout: 10000.00,
                        eligiblepayout: 4000.00
                    },
                    hightemp: {
                        trigger1: 31,
                        trigger2: 31.50,
                        trigger3: 32,
                        trigger4: 32.50,
                        totdevian: 44.30,
                        strike: 4.00,
                        exit: 36,
                        payoutrate: 531.25,
                        maxpayout: 17000.00,
                        actualphasepayout: 17000.00,
                        totalmaxpayout: 17000.00,
                        eligiblepayout: 17000.00
                    },
                    excess_rainfall: {
                        strike: 150,
                        threedaycumm: 173.73,
                        maxpayout: 20000.00,
                        payout: 20000.00,
                        totalmaxpayout: 20000.00,
                        eligiblepayout: 20000.00
                    }
                },
                meteoblue: {
                    deficit_rainfall: {
                        strike1: 300,
                        strike2: 250,
                        strike3: 250,
                        strike4: 200,
                        aggrain1: 119.70,
                        aggrain2: 66.80,
                        aggrain3: 84.50,
                        aggrain4: 27.30,
                        phaseagg1: 180.30,
                        phaseagg2: 183.20,
                        phaseagg3: 165.50,
                        phaseagg4: 172.70,
                        exit1: 60,
                        exit2: 60,
                        exit3: 60,
                        exit4: 60,
                        excond1: "N",
                        excond2: "N",
                        excond3: "N",
                        excond4: "Y",
                        payoutrate1: 29.17,
                        payoutrate2: 26.32,
                        payoutrate3: 26.32,
                        payoutrate4: 21.43,
                        maxpayout1: 7000.00,
                        maxpayout2: 5000.00,
                        maxpayout3: 5000.00,
                        maxpayout4: 3000,
                        payoutperphase1: 5259.00,
                        payoutperphase2: 4822.00,
                        payoutperphase3: 4356.00,
                        payoutperphase4: 3701.00,
                        payoutonexit1: 0.00,
                        payoutonexit2: 0.00,
                        payoutonexit3: 0.00,
                        payoutonexit4: 3000.00,
                        actualphasepayout1: 5259.00,
                        actualphasepayout2: 4822.00,
                        actualphasepayout3: 4356.00,
                        actualphasepayout4: 3000.00,
                        cummpayout1: 5259.00,
                        cummpayout2: 10081.00,
                        cummpayout3: 14437.00,
                        cummpayout4: 17437.00,
                        totalmaxpayout: 20000.00,
                        eligiblepayout: 17437.00
                    },
                    dryspell: {
                        strike1: 13,
                        strike2: 15,
                        strike3: 18,
                        strike4: 20,
                        conse_days: 24,
                        payoutrate1: 3000.00,
                        payoutrate2: 5000.00,
                        payoutrate3: 8000.00,
                        payoutrate4: 13000.00,
                        payout: 13000.00,
                        totalmaxpayout: 13000.00,
                        eligiblepayout: 13000.00
                    },
                    diseasecongenial: {
                        strike: 3,
                        conse_days: 0,
                        exit: 7,
                        payoutrate: 2000.00,
                        payout: 0.00,
                        totalmaxpayout: 10000.00,
                        eligiblepayout: 0.00
                    },
                    hightemp: {
                        trigger1: 31,
                        trigger2: 31.50,
                        trigger3: 32,
                        trigger4: 32.50,
                        totdevian: 6.61,
                        strike: 4.00,
                        exit: 36,
                        payoutrate: 531.25,
                        maxpayout: 17000.00,
                        actualphasepayout: 1387.00,
                        totalmaxpayout: 17000.00,
                        eligiblepayout: 1387.00
                    },
                    excess_rainfall: {
                        strike: 150,
                        threedaycumm: 173.73,
                        maxpayout: 20000,
                        payout: 0.00,
                        totalmaxpayout: 20000.00,
                        eligiblepayout: 0.00
                    }

                },
                nasa: {
                    deficit_rainfall: {
                        strike1: 300,
                        strike2: 250,
                        strike3: 250,
                        strike4: 200,
                        aggrain1: 183.11,
                        aggrain2: 298.02,
                        aggrain3: 302.53,
                        aggrain4: 257.00,
                        phaseagg1: 116.89,
                        phaseagg2: 0.00,
                        phaseagg3: 0.00,
                        phaseagg4: 0.00,
                        exit1: 60,
                        exit2: 60,
                        exit3: 60,
                        exit4: 60,
                        excond1: "N",
                        excond2: "N",
                        excond3: "N",
                        excond4: "N",
                        payoutrate1: 29.17,
                        payoutrate2: 26.32,
                        payoutrate3: 26.32,
                        payoutrate4: 21.43,
                        maxpayout1: 7000.00,
                        maxpayout2: 5000.00,
                        maxpayout3: 5000.00,
                        maxpayout4: 3000.00,
                        payoutperphase1: 3410.00,
                        payoutperphase2: 0.00,
                        payoutperphase3: 0.00,
                        payoutperphase4: 0.00,
                        payoutonexit1: 0.00,
                        payoutonexit2: 0.00,
                        payoutonexit3: 0.00,
                        payoutonexit4: 0.00,
                        actualphasepayout1: 3410.00,
                        actualphasepayout2: 0.00,
                        actualphasepayout3: 0.00,
                        actualphasepayout4: 0.00,
                        cummpayout1: 3410.00,
                        cummpayout2: 3410.00,
                        cummpayout3: 3410.00,
                        cummpayout4: 3410.00,
                        totalmaxpayout: 20000.00,
                        eligiblepayout: 3409.70
                    },
                    dryspell: {
                        strike1: 13,
                        strike2: 15,
                        strike3: 18,
                        strike4: 20,
                        conse_days: 5,
                        payoutrate1: 3000.00,
                        payoutrate2: 5000.00,
                        payoutrate3: 8000.00,
                        payoutrate4: 13000.00,
                        payout: 0.00,
                        totalmaxpayout: 13000.00,
                        eligiblepayout: 0.00
                    },
                    diseasecongenial: {
                        strike: 3,
                        conse_days: 0,
                        exit: 7,
                        payoutrate: 2000.00,
                        payout: 0.00,
                        totalmaxpayout: 10000.00,
                        eligiblepayout: 0.00
                    },
                    hightemp: {
                        trigger1: 31,
                        trigger2: 31.50,
                        trigger3: 32,
                        trigger4: 32.50,
                        totdevian: 0.00,
                        strike: 4.00,
                        exit: 36,
                        payoutrate: 531.25,
                        maxpayout: 17000.00,
                        actualphasepayout: 0.00,
                        totalmaxpayout: 17000.00,
                        eligiblepayout: 0.00
                    },
                    excess_rainfall: {
                        strike: 150,
                        threedaycumm: 137.54,
                        maxpayout: 20000,
                        payout: 0.00,
                        totalmaxpayout: 20000.00,
                        eligiblepayout: 0.00
                    }
                },
                ecmwf: {
                    deficit_rainfall: {
                        strike1: 300,
                        strike2: 250,
                        strike3: 250,
                        strike4: "--",
                        aggrain1: 276.95,
                        aggrain2: 731.40,
                        aggrain3: 61.84,
                        aggrain4: "--",
                        phaseagg1: 23.05,
                        phaseagg2: 0.00,
                        phaseagg3: 188.16,
                        phaseagg4: "--",
                        exit1: 60,
                        exit2: 60,
                        exit3: 60,
                        exit4: "--",
                        excond1: "N",
                        excond2: "N",
                        excond3: "N",
                        excond4: "--",
                        payoutrate1: 29.17,
                        payoutrate2: 26.32,
                        payoutrate3: 26.32,
                        payoutrate4: "--",
                        maxpayout1: 7000.00,
                        maxpayout2: 5000.00,
                        maxpayout3: 5000.00,
                        maxpayout4: "--",
                        payoutperphase1: 672.00,
                        payoutperphase2: 0.00,
                        payoutperphase3: 4952.00,
                        payoutperphase4: "--",
                        payoutonexit1: 0.00,
                        payoutonexit2: 0.00,
                        payoutonexit3: 0.00,
                        payoutonexit4: "--",
                        actualphasepayout1: 672.00,
                        actualphasepayout2: 0.00,
                        actualphasepayout3: 4952.00,
                        actualphasepayout4: "--",
                        cummpayout1: 672.00,
                        cummpayout2: 672.00,
                        cummpayout3: 5625.00,
                        cummpayout4: "--",
                        totalmaxpayout: 20000.00,
                        eligiblepayout: 5624.83
                    },
                    dryspell: {
                        strike1: 13,
                        strike2: 15,
                        strike3: 18,
                        strike4: 20,
                        conse_days: 3,
                        payoutrate1: 3000.00,
                        payoutrate2: 5000.00,
                        payoutrate3: 8000.00,
                        payoutrate4: 13000.00,
                        payout: 0.00,
                        totalmaxpayout: 13000.00,
                        eligiblepayout: 0.00
                    },
                    diseasecongenial: {
                        strike: 3,
                        conse_days: 0,
                        exit: 7,
                        payoutrate: 2000.00,
                        payout: 0.00,
                        totalmaxpayout: 10000.00,
                        eligiblepayout: 0.00
                    },
                    hightemp: {
                        trigger1: 31,
                        trigger2: 31.50,
                        trigger3: 32,
                        trigger4: 32.50,
                        totdevian: 2.00,
                        strike: 4.00,
                        exit: 36,
                        payoutrate: 531.25,
                        maxpayout: 17000.00,
                        actualphasepayout: 0.00,
                        totalmaxpayout: 17000.00,
                        eligiblepayout: 0.00
                    },
                    excess_rainfall: {
                        strike: 150,
                        threedaycumm: 206.15,
                        maxpayout: 20000.00,
                        payout: 20000.00,
                        totalmaxpayout: 20000.00,
                        eligiblepayout: 20000.00
                    }
                }
            }
        }
    },
    kozhinjampara: {
        lat: 10.779,
        lng: 76.860,
        kharif: {
            paddy: {
                aic: {
                    deficit_rainfall: {
                        strike1: 300,
                        strike2: 250,
                        strike3: 250,
                        strike4: "--",
                        aggrain1: 186.39,
                        aggrain2: 414.98,
                        aggrain3: 484.88,
                        aggrain4: "--",
                        phaseagg1: 113.61,
                        phaseagg2: 0.00,
                        phaseagg3: 0.00,
                        phaseagg4: "--",
                        exit1: 60,
                        exit2: 60,
                        exit3: 60,
                        exit4: "--",
                        excond1: "N",
                        excond2: "N",
                        excond3: "N",
                        excond4: "--",
                        payoutrate1: 29.17,
                        payoutrate2: 26.32,
                        payoutrate3: 26.32,
                        payoutrate4: "--",
                        maxpayout1: 7000.00,
                        maxpayout2: 5000.00,
                        maxpayout3: 5000.00,
                        maxpayout4: "--",
                        payoutperphase1: 3314.00,
                        payoutperphase2: 0.00,
                        payoutperphase3: 0.00,
                        payoutperphase4: "--",
                        payoutonexit1: 0.00,
                        payoutonexit2: 0.00,
                        payoutonexit3: 0.00,
                        payoutonexit4: "--",
                        actualphasepayout1: 3314.00,
                        actualphasepayout2: 0.00,
                        actualphasepayout3: 0.00,
                        actualphasepayout4: "--",
                        cummpayout1: 3314.00,
                        cummpayout2: 3314.00,
                        cummpayout3: 3314.00,
                        cummpayout4: "--",
                        totalmaxpayout: 20000.00,
                        eligiblepayout: 3314.00
                    },
                    dryspell: {
                        strike1: 13,
                        strike2: 15,
                        strike3: 18,
                        strike4: 20,
                        conse_days: 12,
                        payoutrate1: 3000.00,
                        payoutrate2: 5000.00,
                        payoutrate3: 8000.00,
                        payoutrate4: 13000.00,
                        payout: 0.00,
                        totalmaxpayout: 13000.00,
                        eligiblepayout: 0.00
                    },
                    diseasecongenial: {
                        strike: 3,
                        conse_days: 4,
                        exit: 7,
                        payoutrate: 2000.00,
                        payout: 4000.00,
                        totalmaxpayout: 10000.00,
                        eligiblepayout: 4000.00
                    },
                    hightemp: {
                        trigger1: 31,
                        trigger2: 31.50,
                        trigger3: 32,
                        trigger4: 32.50,
                        totdevian: 44.30,
                        strike: 4.00,
                        exit: 36,
                        payoutrate: 531.25,
                        maxpayout: 17000.00,
                        actualphasepayout: 17000.00,
                        totalmaxpayout: 17000.00,
                        eligiblepayout: 17000.00
                    },
                    excess_rainfall: {
                        strike: 150,
                        threedaycumm: 173.73,
                        maxpayout: 20000.00,
                        payout: 20000.00,
                        totalmaxpayout: 20000.00,
                        eligiblepayout: 20000.00
                    }
                },
                meteoblue: {
                    deficit_rainfall: {
                        strike1: 300,
                        strike2: 250,
                        strike3: 250,
                        strike4: 200,
                        aggrain1: 75.00,
                        aggrain2: 11.70,
                        aggrain3: 38.50,
                        aggrain4: 8.90,
                        phaseagg1: 225.00,
                        phaseagg2: 238.30,
                        phaseagg3: 211.50,
                        phaseagg4: 191.10,
                        exit1: 60,
                        exit2: 60,
                        exit3: 60,
                        exit4: 60,
                        excond1: "N",
                        excond2: "Y",
                        excond3: "Y",
                        excond4: "Y",
                        payoutrate1: 29.17,
                        payoutrate2: 26.32,
                        payoutrate3: 26.32,
                        payoutrate4: 21.43,
                        maxpayout1: 7000.00,
                        maxpayout2: 5000.00,
                        maxpayout3: 5000.00,
                        maxpayout4: 3000,
                        payoutperphase1: 6563.00,
                        payoutperphase2: 6272.00,
                        payoutperphase3: 5567.00,
                        payoutperphase4: 4095.00,
                        payoutonexit1: 0.00,
                        payoutonexit2: 5000.00,
                        payoutonexit3: 5000.00,
                        payoutonexit4: 3000.00,
                        actualphasepayout1: 6563.00,
                        actualphasepayout2: 5000.00,
                        actualphasepayout3: 5000.00,
                        actualphasepayout4: 3000.00,
                        cummpayout1: 6563.00,
                        cummpayout2: 11563.00,
                        cummpayout3: 16563.00,
                        cummpayout4: 19563.00,
                        totalmaxpayout: 20000.00,
                        eligiblepayout: 19563.00
                    },
                    dryspell: {
                        strike1: 13,
                        strike2: 15,
                        strike3: 18,
                        strike4: 20,
                        conse_days: 29,
                        payoutrate1: 3000.00,
                        payoutrate2: 5000.00,
                        payoutrate3: 8000.00,
                        payoutrate4: 13000.00,
                        payout: 13000.00,
                        totalmaxpayout: 13000.00,
                        eligiblepayout: 13000.00
                    },
                    diseasecongenial: {
                        strike: 3,
                        conse_days: 0,
                        exit: 7,
                        payoutrate: 2000.00,
                        payout: 0.00,
                        totalmaxpayout: 10000.00,
                        eligiblepayout: 0.00
                    },
                    hightemp: {
                        trigger1: 31,
                        trigger2: 31.50,
                        trigger3: 32,
                        trigger4: 32.50,
                        totdevian: 14.71,
                        strike: 4.00,
                        exit: 36,
                        payoutrate: 531.25,
                        maxpayout: 17000.00,
                        actualphasepayout: 5690.00,
                        totalmaxpayout: 17000.00,
                        eligiblepayout: 5690.00
                    },
                    excess_rainfall: {
                        strike: 150,
                        threedaycumm: 56.90,
                        maxpayout: 20000.00,
                        payout: 0.00,
                        totalmaxpayout: 20000.00,
                        eligiblepayout: 0.00
                    }
                },
                nasa: {
                    deficit_rainfall: {
                        strike1: 300,
                        strike2: 250,
                        strike3: 250,
                        strike4: 200,
                        aggrain1: 184.85,
                        aggrain2: 303.27,
                        aggrain3: 303.92,
                        aggrain4: 260.87,
                        phaseagg1: 115.15,
                        phaseagg2: 0.00,
                        phaseagg3: 0.00,
                        phaseagg4: 0.00,
                        exit1: 60,
                        exit2: 60,
                        exit3: 60,
                        exit4: 60,
                        excond1: "N",
                        excond2: "N",
                        excond3: "N",
                        excond4: "N",
                        payoutrate1: 29.17,
                        payoutrate2: 26.32,
                        payoutrate3: 26.32,
                        payoutrate4: 21.43,
                        maxpayout1: 7000.00,
                        maxpayout2: 5000.00,
                        maxpayout3: 5000.00,
                        maxpayout4: 3000.00,
                        payoutperphase1: 3359.00,
                        payoutperphase2: 0.00,
                        payoutperphase3: 0.00,
                        payoutperphase4: 0.00,
                        payoutonexit1: 0.00,
                        payoutonexit2: 0.00,
                        payoutonexit3: 0.00,
                        payoutonexit4: 0.00,
                        actualphasepayout1: 3359.00,
                        actualphasepayout2: 0.00,
                        actualphasepayout3: 0.00,
                        actualphasepayout4: 0.00,
                        cummpayout1: 3359.00,
                        cummpayout2: 3359.00,
                        cummpayout3: 3359.00,
                        cummpayout4: 3359.00,
                        totalmaxpayout: 20000.00,
                        eligiblepayout: 3358.84
                    },
                    dryspell: {
                        strike1: 13,
                        strike2: 15,
                        strike3: 18,
                        strike4: 20,
                        conse_days: 6,
                        payoutrate1: 3000.00,
                        payoutrate2: 5000.00,
                        payoutrate3: 8000.00,
                        payoutrate4: 13000.00,
                        payout: 0.00,
                        totalmaxpayout: 13000.00,
                        eligiblepayout: 0.00
                    },
                    diseasecongenial: {
                        strike: 3,
                        conse_days: 0,
                        exit: 7,
                        payoutrate: 2000.00,
                        payout: 0.00,
                        totalmaxpayout: 10000.00,
                        eligiblepayout: 0.00
                    },
                    hightemp: {
                        trigger1: 31,
                        trigger2: 31.50,
                        trigger3: 32,
                        trigger4: 32.50,
                        totdevian: 0.00,
                        strike: 4.00,
                        exit: 36,
                        payoutrate: 531.25,
                        maxpayout: 17000.00,
                        actualphasepayout: 0.00,
                        totalmaxpayout: 17000.00,
                        eligiblepayout: 0.00
                    },
                    excess_rainfall: {
                        strike: 150,
                        threedaycumm: 140.98,
                        maxpayout: 20000.00,
                        payout: 0.00,
                        totalmaxpayout: 20000.00,
                        eligiblepayout: 0.00
                    }
                },
                ecmwf: {
                    deficit_rainfall: {
                        strike1: 300,
                        strike2: 250,
                        strike3: 250,
                        strike4: 200,
                        aggrain1: 281.49,
                        aggrain2: 749.70,
                        aggrain3: 60.05,
                        aggrain4: "--",
                        phaseagg1: 18.51,
                        phaseagg2: 0.00,
                        phaseagg3: 189.95,
                        phaseagg4: "--",
                        exit1: 60,
                        exit2: 60,
                        exit3: 60,
                        exit4: "--",
                        excond1: "N",
                        excond2: "N",
                        excond3: "N",
                        excond4: "--",
                        payoutrate1: 29.17,
                        payoutrate2: 26.32,
                        payoutrate3: 26.32,
                        payoutrate4: "--",
                        maxpayout1: 7000.00,
                        maxpayout2: 5000.00,
                        maxpayout3: 5000.00,
                        maxpayout4: "--",
                        payoutperphase1: 540.00,
                        payoutperphase2: 0.00,
                        payoutperphase3: 5000.00,
                        payoutperphase4: "--",
                        payoutonexit1: 0.00,
                        payoutonexit2: 0.00,
                        payoutonexit3: 0.00,
                        payoutonexit4: "--",
                        actualphasepayout1: 540.00,
                        actualphasepayout2: 0.00,
                        actualphasepayout3: 5000.00,
                        actualphasepayout4: "--",
                        cummpayout1: 540.00,
                        cummpayout2: 540.00,
                        cummpayout3: 5539.00,
                        cummpayout4: "--",
                        totalmaxpayout: 20000.00,
                        eligiblepayout: 5539.49
                    },
                    dryspell: {
                        strike1: 13,
                        strike2: 15,
                        strike3: 18,
                        strike4: 20,
                        conse_days: 3,
                        payoutrate1: 3000.00,
                        payoutrate2: 5000.00,
                        payoutrate3: 8000.00,
                        payoutrate4: 13000.00,
                        payout: 0.00,
                        totalmaxpayout: 13000.00,
                        eligiblepayout: 0.00
                    },
                    diseasecongenial: {
                        strike: 3,
                        conse_days: 0,
                        exit: 7,
                        payoutrate: 2000.00,
                        payout: 0.00,
                        totalmaxpayout: 10000.00,
                        eligiblepayout: 0.00
                    },
                    hightemp: {
                        trigger1: 31,
                        trigger2: 31.50,
                        trigger3: 32,
                        trigger4: 32.50,
                        totdevian: 2.00,
                        strike: 4.00,
                        exit: 36,
                        payoutrate: 531.25,
                        maxpayout: 17000.00,
                        actualphasepayout: 0.00,
                        totalmaxpayout: 17000.00,
                        eligiblepayout: 0.00
                    },
                    excess_rainfall: {
                        strike: 150,
                        threedaycumm: 208.13,
                        maxpayout: 20000.00,
                        payout: 20000.00,
                        totalmaxpayout: 20000.00,
                        eligiblepayout: 20000.00
                    }

                }
            }
        }
    }
}
// const Termsheettable =(props)=> {
class Hightemtable extends Component {
    constructor(props) {
        super(props);
        // console.log("TABLE PROPS----", props.loc)
        this.state = {
            dprovider: '',
            location: '',
            peril: '',
        };

    }

    handleDPchange(e) {
        let dp = e.target.value

    }
    //   componentDidMount(){

    //   }
    render() {

        //  console.log("CONFIG",nallepilly)
        return (
            <React.Fragment>
                <select className="form-control" style={{ fontSize: '11px' }} value={this.state.dprovider} onChange={this.handleDPchange}>
                    <option value="aic ">AIC
                    </option>
                    <option value="darksky">Dark Sky</option>
                    <option value="ow">Open Weather</option>
                    <option value="onlinesen">Online Sensor</option>
                </select>

                <Table responsive scrollY class="table table-bordered" id="hightemptbl" style={{ marginTop: '2%', fontSize: '12px' }}>
                    <tbody>
                        <tr>
                            <th scope="row" style={{ backgroundColor: '#c3d7e2' }}>PERIOD</th>
                            <td colspan={4}>01-Jun-2020 to 15-Oct-2020</td>
                        </tr>
                        <tr>
                            <th scope="row" style={{ backgroundColor: '#c3d7e2' }}>PHASE</th>
                            <td>Phase-I</td>
                            <td>Phase-II</td>
                            <td>Phase-III</td>
                            <td>Phase-IV</td>
                        </tr>
                        <tr>
                            <th scope="row" style={{ backgroundColor: '#c3d7e2' }}>PHASE RANGE</th>
                            <td>1-Jun to 30-Jun</td>
                            <td>1-Jul to 31-Jul</td>
                            <td>1-Aug to 31-Aug</td>
                            <td>1-Sep to 15-Oct</td>
                        </tr>
                        <tr>
                            <th scope="row" style={{ backgroundColor: '#c3d7e2' }}>INDEX</th>
                            <td colspan={4}>Cumulative daily upward deviation of T Max from
                                respective triggers</td>
                        </tr>
                        <tr>
                            <th scope="row" style={{ backgroundColor: '#c3d7e2' }}>TRIGGER (°C )  </th>
                            <td>31</td>
                            <td>31.5</td>
                            <td>32</td>
                            <td>32.5</td>
                        </tr>
                        <tr>
                            <th style={{ backgroundColor: '#c3d7e2' }}>TOTAL DEVIATION</th>
                            <td colspan={4} id="totdevian" >61.80</td>
                        </tr>
                        <tr>
                            <th scope="row" style={{ backgroundColor: '#c3d7e2' }}>STRIKE (°C )</th>
                            <td colspan={4}>4</td>
                        </tr>
                        <tr>
                            <th scope="row" style={{ backgroundColor: '#c3d7e2' }}>EXIT</th>
                            <td colspan={4}>36</td>
                        </tr>
                        <tr>
                            <th scope="row" style={{ backgroundColor: '#c3d7e2' }}>PAYOUT RATE (Rs./° C)</th>
                            <td colspan={4} id="payout_hight">531.25</td>
                        </tr>
                    </tbody>
                </Table>
            </React.Fragment>
        )
    }
}
export default Hightemtable;