import React from 'react';
import { Table, Container, Row, Col, FormGroup, Label } from 'reactstrap';

const Claims = (props) => {
  return (
    // <Table borderless>
      
    //   <tbody>
    //     <tr>
          
    //       <td>Eligible Claims</td>
    //       <td>Claim generated date & time</td>
    //       <td>Affected Crop</td>
    //     </tr>
    //     <tr>
          
    //       <td>Peril</td>
    //       <td>Eligible Claim Amount</td>
    //       <td>Status</td>
    //     </tr>
        
        
    //   </tbody>
    // </Table>
    <React.Fragment>
    <Row xs={12}>
    <Col sm={3}>
      <FormGroup>
        <Label className="col-md-6" style={{ fontSize: '12px', color:'#a3a3a3' }}>Eligible Claims </Label>
    </FormGroup>
    </Col>
    <Col sm={3}>
      <FormGroup>
        <Label className="col-md-6" style={{ fontSize: '12px', color:'#a3a3a3' }}>Claim generated date & time</Label>
    </FormGroup>
    </Col>
    <Col sm={3}>
      <FormGroup>
        <Label className="col-md-6" style={{ fontSize: '12px', color:'#a3a3a3' }}>Affected Crop</Label>
    </FormGroup>
    </Col>
    <Col sm={3}>
      <FormGroup>
        <Label className="col-md-6" style={{ fontSize: '12px', color:'#a3a3a3' }}>Peril</Label>
    </FormGroup>
    </Col>
    </Row>

    <Row xs={12}>
    
    <Col sm={3}>
      <FormGroup>
        <Label className="col-md-6" style={{ fontSize: '12px', color:'#a3a3a3' }}>Eligible Claim Amount</Label>
    </FormGroup>
    </Col>
    <Col sm={3}>
      <FormGroup>
        <Label className="col-md-6" style={{ fontSize: '12px', color:'#a3a3a3' }}>Status</Label>
    </FormGroup>
    </Col>
    </Row>
    </React.Fragment>
  );
}

export default Claims;