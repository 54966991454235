import React, { Component } from "react";
import { Card, CardBody, Row, Col, Media } from "reactstrap";
import MapCustomControl from "./React-control/control.tsx";
import "../Dashboard/leaflet.css";
import { FormGroup, Label } from "reactstrap";
import ReactApexChart from "react-apexcharts";
import getpointsObject from "../../weather_points";
import getRuaObject from "../../rua_object";
import {
  MapContainer,
  TileLayer,
  GeoJSON,
  Popup,
  CircleMarker,
  Tooltip,
  SVGOverlay,
} from "react-leaflet";
import L from "leaflet";
import { withNamespaces } from "react-i18next";

const bounds = [
  [10.6280565267840235, 76.6888275027770305],
  [10.817207336425831, 76.9081420898434516],
];
const points = getpointsObject();
const rua = getRuaObject();
class Diagnostic_Map extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sampletime: 0,
      otherProviderdata: [],
      dtKey: 0,
      coordinates: [],
      dropselect: "",
      latnew: "10.72",
      longnew: "76.810",
      zoom: 12,
      dpval: 0,
      showLoader: true,
      changecolor: "statusup",
      map: null,
      livestatus: [0],
      tooltiptest: "initial test",
      severityclass: "",
      selperil: "temperature",
      otherparams: [],
      series: [10, 3],
      seldp: "none",
      options: {
        dataLabels: {
          enabled: false,
        },
        chart:{
          redrawOnWindowResize: false,
          redrawOnParentResize: false,
        },
        plotOptions: {
          pie: {
            labels: {
              show: true,
            },
            donut: {
              size: "50%",
            },
          },
        },
        shouldUpdateChart: false,
        labels: ["Active", "Inactive"],
        colors: ["#1d7d1e", "#fa3c3c"],
        legend: {
          show: true,
          position: "right",
          horizontalAlign: "center",
          verticalAlign: "middle",
          floating: false,
          fontSize: "8px",
          offsetX: 0,
          offsetY: -10,
        },
        responsive: [
          {
            breakpoint: 600,
            options: {
              chart: {
                height: 240,
              },
              legend: {
                show: false,
              },
            },
          },
        ],
      },
    };
  }


  getactivestatus() {
    let count = 0;
    this.props.livedata.map(function (points) {
      if (points.status == true) {
        count = count + 1;
      }
    });
    this.setState({
      series: [count, 14 - count],
    });
  }
  componentDidMount() {
    //Get Total no of active sensors
    this.getactivestatus();
  }


  render() {
    const { t, i18n } = this.props;

    return (
      <React.Fragment>
        <Row>
                    <ReactApexChart
                      options={this.state.options}
                      series={this.state.series}
                      type="donut"
                      height="90px"
                    />
        </Row>
      </React.Fragment>
    );
  }
}

export default withNamespaces()(Diagnostic_Map);

