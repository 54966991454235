import React, { Component } from "react";
import {
  Card,
  CardBody,
  Row,
  Col,
  UncontrolledTooltip,
  Label,
  Container,
  Spinner,
} from "reactstrap";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import precipitation from "../Dashboard/images/prec.png";
import windSpeed from "../Dashboard/images/wind_speed.png";
import windDirection from "../Dashboard/images/wind_direction.png";
import uv from "../Dashboard/images/uv.png";
import humidity from "../Dashboard/images/humidity.png";
import pressure from "../Dashboard/images/pressure.png";
import Permission from "../../permission.js";
import { withNamespaces } from "react-i18next";
class weather_Forecast extends Component {
  constructor(props) {
    super(props);
    this.state = {
      forecast: [],
      showloader: false,
      showheading: true,
      kb1: true,
      kb2: true,
      kb3: true,
      kb4: true,
      kb5: true,
      kb6: true,
      kb7: true,
      selval: "",
    };
    this.seeforecast = this.seeforecast.bind(this);
    this.setlatlon = this.setlatlon.bind(this);
  }
  setlatlon(e) {
    this.seeforecast(e.target.value);
  }

  async seeforecast(e) {
    this.setState({
      showloader: true,
    });
    // console.log("LAT LON ", e);
    var location = e.split(",");
    // console.log("LOCATION", parseFloat(location[0]), parseFloat(location[1]));
    var lat = parseFloat(location[0]);
    var lon = parseFloat(location[1]);
    let bodydata = {
      lat: lat,
      long: lon,
    };

    bodydata = JSON.stringify(bodydata);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token"),
      },
      body: bodydata,
    };
    var responseforecast = await fetch(
      process.env.REACT_APP_APIEND + `farmer/open-weather-forecast-farmer`,
      requestOptions
    ).then((res) => {
      return res.json();
    });
    // console.log("RESPONSE", responseforecast);
    this.setState({
      forecast: responseforecast.daily,
      showloader: false,
      showheading: false,
    });
  }

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  convertdate(date) {
    var forecast_date = new Date(parseInt(date * 1000));
    var to_dd = String(forecast_date.getDate()).padStart(2, "0");
    var to_mm = String(forecast_date.getMonth() + 1).padStart(2, "0"); //January is 0!
    var to_yyyy = forecast_date.getFullYear();
    var to_date = to_dd + "-" + to_mm + "-" + to_yyyy;
    return to_date;
  }

  keycheck(key) { 
    if(key.hasOwnProperty("rain")){
        return(key.rain)
    }
     else {
      return "0.00";
    }
  }

  componentDidMount() {
    const krishibhavan = Permission();

    if (krishibhavan[1] == "Nallepilly" && krishibhavan[0] == "FM") {
      this.setState(
        {
          kb1: false,
          kb2: false,
          kb3: false,
          kb4: true,
          kb5: false,
          kb6: false,
          kb7: false,
          selval: "10.71247,76.78929",
        },
        () => {
          this.seeforecast("10.71247,76.78929");
        }
      );
    } else if (
      krishibhavan[1] == "Chittur-Thathamangalam" || krishibhavan[1] == "Chittur" &&
      krishibhavan[0] == "FM"
    ) {
      this.setState(
        {
          kb1: true,
          kb2: false,
          kb3: false,
          kb4: false,
          kb5: false,
          kb6: false,
          kb7: false,
          selval: "10.69072,76.72247",
        },
        () => {
          this.seeforecast(this.state.selval);
        }
      );
    } else if (krishibhavan[1] == "Kozhinjampara" && krishibhavan[0] == "FM") {
      this.setState(
        {
          kb1: false,
          kb2: false,
          kb3: true,
          kb4: false,
          kb5: false,
          kb6: false,
          kb7: false,
          selval: "10.71348,76.83457",
        },
        () => {
          this.seeforecast(this.state.selval);
        }
      );
    } else if (krishibhavan[1] == "Eruthenpathy" && krishibhavan[0] == "FM") {
      this.setState(
        {
          kb1: false,
          kb2: true,
          kb3: false,
          kb4: false,
          kb5: false,
          kb6: false,
          kb7: false,
          selval: "10.72406,76.86789",
        },
        () => {
          this.seeforecast(this.state.selval);
        }
      );
    } else if (krishibhavan[1] == "Pattanchery" && krishibhavan[0] == "FM") {
      this.setState(
        {
          kb1: false,
          kb2: false,
          kb3: false,
          kb4: false,
          kb5: true,
          kb6: false,
          kb7: false,
          selval: "10.64987,76.74742",
        },
        () => {
          this.seeforecast(this.state.selval);
        }
      );
    } else if (krishibhavan[1] == "Perumatty" && krishibhavan[0] == "FM") {
      this.setState(
        {
          kb1: false,
          kb2: false,
          kb3: false,
          kb4: false,
          kb5: false,
          kb6: true,
          kb7: false,
          selval: "10.66102,76.80525",
        },
        () => {
          this.seeforecast(this.state.selval);
        }
      );
    } else if (krishibhavan[1] == "Vadakarapathy" && krishibhavan[0] == "FM") {
      this.setState(
        {
          kb1: false,
          kb2: false,
          kb3: false,
          kb4: false,
          kb5: false,
          kb6: false,
          kb7: true,
          selval: "10.78345,76.86559",
        },
        () => {
          this.seeforecast(this.state.selval);
        }
      );
    }
    // console.log(this.state.selval,"selvalue")
  }
  render() {
    const { t, i18n } = this.props;
    const responsive = {
      superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 5,
      },
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
      },
    };
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <span className="weather-hide">
              <Row>
                <Col xs={12}>
                  <h4
                    className="card-title"
                    style={{ padding: "5px", fontSize: "18px" }}
                  >
                    {" "}
                    {t("Forecast")}
                  </h4>
                </Col>
                <Card>
                  <CardBody
                    style={{ backgroundColor: "white", height: "100%" }}
                  >
                    <Row>
                      <Label
                        className="col-md-5 col-form-label"
                        style={{ fontSize: "14px" }}
                      >
                        {" "}
                        {t("Location")}{" "}
                      </Label>
                      <select
                        className="form-select"
                        style={{ fontSize: "11px" }}
                        onChange={this.setlatlon}
                        value={this.state.selval}
                      >
                        {/* <option disabled selected >Select
                                            </option> */}
                        <option
                          style={this.state.kb1 ? {} : { display: "none" }}
                          value="10.69072,76.72247"
                        >
                          Chittur-Thathamangalam
                        </option>
                        <option
                          style={this.state.kb2 ? {} : { display: "none" }}
                          value="10.72406,76.86789"
                        >
                          Eruthenpathy
                        </option>
                        <option
                          style={this.state.kb3 ? {} : { display: "none" }}
                          value="10.71348,76.83457"
                        >
                          Kozhinjampara
                        </option>
                        <option
                          style={this.state.kb4 ? {} : { display: "none" }}
                          value="10.71247,76.78929"
                        >
                          Nallepilly
                        </option>
                        <option
                          style={this.state.kb5 ? {} : { display: "none" }}
                          value="10.64987,76.74742"
                        >
                          Pattanchery
                        </option>
                        <option
                          style={this.state.kb6 ? {} : { display: "none" }}
                          value="10.66102,76.80525"
                        >
                          Perumatty
                        </option>
                        <option
                          style={this.state.kb7 ? {} : { display: "none" }}
                          value="10.78345,76.86559"
                        >
                          Vadakarapathy
                        </option>
                      </select>
                    </Row>

                    <Row style={{ paddingTop: "5%" }}>
                      <div
                        style={
                          this.state.showheading ? { display: "none" } : {}
                        }
                      >
                        <Col xs={12}>
                          <h4
                            className="card-title"
                            style={{ fontSize: "15px" }}
                          >
                            {" "}
                            {t("Daily Weather Forecast")}{" "}
                          </h4>
                        </Col>
                      </div>
                      <div
                        style={this.state.showloader ? {} : { display: "none" }}
                      >
                        <center>
                          <Spinner color="dark" />
                        </center>
                      </div>
                      <div
                        style={this.state.showloader ? { display: "none" } : {}}
                      >
                        <Carousel responsive={responsive}>
                          {this.state.forecast.map((day, index) => (
                            <Col key={index}>
                              <Card style={{ backgroundColor: "#d2e1ee" }}>
                                <CardBody>
                                  <Row>
                                    <Col md="6">
                                      <img
                                        src={`http://openweathermap.org/img/wn/${day.weather[0].icon}@2x.png`}
                                        alt=""
                                        className="avatar-lg mt-2 mb-4"
                                      />
                                    </Col>
                                    <Col md="6">
                                      <p style={{ fontSize: "24px" }}>
                                        {parseFloat(day.temp.max).toFixed(2)} °C
                                      </p>
                                      <p>
                                        {this.capitalizeFirstLetter(
                                          day.weather[0].description
                                        )}
                                      </p>
                                      <p>{this.convertdate(day.dt)}</p>
                                    </Col>
                                  </Row>
                                  <hr className="my-4" />
                                  <Row>
                                    <Col md={2}>
                                      <div class="d-flex justify-content-center">
                                        <center>
                                          <span>
                                            <UncontrolledTooltip
                                              target={"precipitation"}
                                              placement="top"
                                            >
                                              Rainfall
                                            </UncontrolledTooltip>
                                            <img
                                              src={precipitation}
                                              height="18px;"
                                              id={"precipitation"}
                                              style={{ paddingRight: "5px" }}
                                            ></img>
                                            <br/>
                                            <p
                                              style={{
                                                display: "inline-block",
                                                fontSize: "13px",
                                              }}
                                            >
                                              {" "}
                                              {this.keycheck(day)} mm
                                            </p>
                                          </span>
                                        </center>
                                      </div>
                                    </Col>
                                    <Col md={2}>
                                      <div class="d-flex justify-content-center">
                                        <center>
                                          <span>
                                            <UncontrolledTooltip
                                              target={"ws"}
                                              placement="top"
                                            >
                                              Wind Speed
                                            </UncontrolledTooltip>
                                            <img
                                              src={windSpeed}
                                              height="18px;"
                                              id={"ws"}
                                              style={{ paddingRight: "5px" }}
                                            />
                                            <br/>
                                            <p
                                              style={{
                                                display: "inline-block",
                                                fontSize: "13px",
                                              }}
                                            >
                                              {" "}
                                              {parseFloat(
                                                day.wind_speed
                                              ).toFixed(2)}{" "}
                                              m/s
                                            </p>
                                          </span>
                                        </center>
                                      </div>
                                    </Col>
                                    <Col md={2}>
                                      <div class="d-flex justify-content-center">
                                        <center>
                                          <span>
                                            <UncontrolledTooltip
                                              target={"wd"}
                                              placement="top"
                                            >
                                              Wind Direction
                                            </UncontrolledTooltip>
                                            <img
                                              src={windDirection}
                                              height="18px;"
                                              id={"wd"}
                                              style={{ paddingRight: "5px" }}
                                            />
                                            <br/>
                                            <p
                                              style={{
                                                display: "inline-block",
                                                fontSize: "13px",
                                              }}
                                            >
                                              {" "}
                                              {parseFloat(day.wind_deg).toFixed(
                                                2
                                              )}{" "}
                                              °
                                            </p>
                                          </span>
                                        </center>
                                      </div>
                                    </Col>
                                    <Col md={2}>
                                      <div class="d-flex justify-content-center">
                                        <center>
                                          <span>
                                            <UncontrolledTooltip
                                              target={"uvi"}
                                              placement="top"
                                            >
                                              UVI
                                            </UncontrolledTooltip>
                                            <img
                                              src={uv}
                                              height="18px"
                                              id={"uvi"}
                                              style={{ paddingRight: "5px" }}
                                            />
                                            <br/>
                                            <p
                                              style={{
                                                display: "inline-block",
                                                fontSize: "13px",
                                              }}
                                            >
                                              {" "}
                                              {parseFloat(day.uvi).toFixed(
                                                2
                                              )}{" "}
                                            </p>
                                          </span>
                                        </center>
                                      </div>
                                    </Col>
                                    <Col md={2}>
                                      <div class="d-flex justify-content-center">
                                        <center>
                                          <span>
                                            <UncontrolledTooltip
                                              target={"rh"}
                                              placement="top"
                                            >
                                              Relative Humidity
                                            </UncontrolledTooltip>
                                            <img
                                              src={humidity}
                                              height="18px"
                                              id={"rh"}
                                              style={{ paddingRight: "5px" }}
                                            />
                                            <br/>
                                            <p
                                              style={{
                                                display: "inline-block",
                                                fontSize: "13px",
                                              }}
                                            >
                                              {" "}
                                              {parseFloat(day.humidity).toFixed(
                                                2
                                              )}{" "}
                                              %
                                            </p>
                                          </span>
                                        </center>
                                      </div>
                                    </Col>
                                    <Col md={2}>
                                      <div class="d-flex justify-content-center">
                                        <center>
                                          <span>
                                            <UncontrolledTooltip
                                              target={"pressure"}
                                              placement="top"
                                            >
                                              Pressure
                                            </UncontrolledTooltip>
                                            <img
                                              src={pressure}
                                              height="18px"
                                              id={"pressure"}
                                              style={{ paddingRight: "5px" }}
                                            />
                                            <br/>
                                            <p
                                              style={{
                                                display: "inline-block",
                                                fontSize: "13px",
                                              }}
                                            >
                                              {parseFloat(day.pressure).toFixed(
                                                2
                                              )}{" "}
                                              hPa
                                            </p>
                                          </span>
                                        </center>
                                      </div>
                                    </Col>
                                  </Row>
                                </CardBody>
                              </Card>
                            </Col>
                          ))}
                        </Carousel>
                      </div>
                    </Row>
                  </CardBody>
                </Card>
              </Row>
            </span>

            <span className="weather-show">
              <Row>
                <Col xs={12}>
                  <h4
                    className="card-title"
                    style={{ padding: "5px", fontSize: "18px" }}
                  >
                    {" "}
                    {t("Forecast")}  
                  </h4>
                </Col>
                <Card>
                  <CardBody
                    style={{ backgroundColor: "white", height: "100%" }}
                  >
                    <Row>
                      <Label
                        className="col-md-5 col-form-label"
                        style={{ fontSize: "14px" }}
                      >
                        Location{" "}
                      </Label>
                      <select
                        className="form-select"
                        style={{ fontSize: "11px" }}
                        onChange={this.setlatlon}
                        value={this.state.selval}
                      >
                        {/* <option disabled selected >Select
                                            </option> */}
                        <option
                          style={this.state.kb1 ? {} : { display: "none" }}
                          value="10.69072,76.72247"
                        >
                          Chittur-Thathamangalam
                        </option>
                        <option
                          style={this.state.kb2 ? {} : { display: "none" }}
                          value="10.72406,76.86789"
                        >
                          Eruthenpathy
                        </option>
                        <option
                          style={this.state.kb3 ? {} : { display: "none" }}
                          value="10.71348,76.83457"
                        >
                          Kozhinjampara
                        </option>
                        <option
                          style={this.state.kb4 ? {} : { display: "none" }}
                          value="10.71247,76.78929"
                          selected
                        >
                          Nallepilly
                        </option>
                        <option
                          style={this.state.kb5 ? {} : { display: "none" }}
                          value="10.64987,76.74742"
                        >
                          Pattanchery
                        </option>
                        <option
                          style={this.state.kb6 ? {} : { display: "none" }}
                          value="10.66102,76.80525"
                        >
                          Perumatty
                        </option>
                        <option
                          style={this.state.kb7 ? {} : { display: "none" }}
                          value="10.78345,76.86559"
                        >
                          Vadakarapathy
                        </option>
                      </select>
                    </Row>

                    <Row style={{ paddingTop: "5%" }}>
                      <div
                        style={
                          this.state.showheading ? { display: "none" } : {}
                        }
                      >
                        <Col xs={12}>
                          <h4
                            className="card-title"
                            style={{ fontSize: "15px" }}
                          >
                            Daily Weather Forecast
                          </h4>
                        </Col>
                      </div>
                      <div
                        style={this.state.showloader ? {} : { display: "none" }}
                      >
                        <center>
                          <Spinner color="dark" />
                        </center>
                      </div>
                      <div
                        style={this.state.showloader ? { display: "none" } : {}}
                      >
                        <Carousel responsive={responsive}>
                          {this.state.forecast.map((day, index) => (
                            <Col key={index}>
                              <Card style={{ backgroundColor: "#d2e1ee" }}>
                                <CardBody>
                                  <div className="row">
                                    <div className="col">
                                      <img
                                        src={`http://openweathermap.org/img/wn/${day.weather[0].icon}@2x.png`}
                                        alt=""
                                        className="avatar-lg"
                                        width="60px"
                                      />
                                    </div>
                                    <div className="col">
                                      <p style={{ fontSize: "18px" }}>
                                        {parseFloat(day.temp.max).toFixed(2)} °C
                                      </p>
                                      <p>
                                        {this.capitalizeFirstLetter(
                                          day.weather[0].description
                                        )}
                                      </p>
                                      <p>{this.convertdate(day.dt)}</p>
                                    </div>
                                  </div>
                                  <hr className="my-2" />
                                  <div className="row">
                                    <div className="col">
                                      <center>
                                        <span>
                                          <UncontrolledTooltip
                                            target={"precipitation"}
                                            placement="top"
                                          >
                                            Rainfall
                                          </UncontrolledTooltip>
                                          <img
                                            src={precipitation}
                                            height="18px;"
                                            id={"precipitation"}
                                            style={{ paddingRight: "5px" }}
                                          ></img>

                                          <p
                                            style={{
                                              display: "inline-block",
                                              fontSize: "13px",
                                            }}
                                          >
                                            {" "}
                                            {this.keycheck(day)} mm
                                          
                                          </p>
                                        </span>
                                      </center>
                                    </div>
                                    <div className="col">
                                      <center>
                                        <span>
                                          <UncontrolledTooltip
                                            target={"ws"}
                                            placement="top"
                                          >
                                            Wind Speed
                                          </UncontrolledTooltip>
                                          <img
                                            src={windSpeed}
                                            height="18px;"
                                            id={"ws"}
                                            style={{ paddingRight: "5px" }}
                                          />
                                          <p
                                            style={{
                                              display: "inline-block",
                                              fontSize: "13px",
                                            }}
                                          >
                                            {" "}
                                            {parseFloat(day.wind_speed).toFixed(
                                              2
                                            )}{" "}
                                            m/s
                                          </p>
                                        </span>
                                      </center>
                                    </div>
                                    <div className="col">
                                      <center>
                                        <span>
                                          <UncontrolledTooltip
                                            target={"wd"}
                                            placement="top"
                                          >
                                            Wind Direction
                                          </UncontrolledTooltip>
                                          <img
                                            src={windDirection}
                                            height="18px;"
                                            id={"wd"}
                                            style={{ paddingRight: "5px" }}
                                          />
                                          <p
                                            style={{
                                              display: "inline-block",
                                              fontSize: "13px",
                                            }}
                                          >
                                            {" "}
                                            {parseFloat(day.wind_deg).toFixed(
                                              2
                                            )}{" "}
                                            °
                                          </p>
                                        </span>
                                      </center>
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col">
                                      <center>
                                        <span>
                                          <UncontrolledTooltip
                                            target={"uvi"}
                                            placement="top"
                                          >
                                            UVI
                                          </UncontrolledTooltip>
                                          <img
                                            src={uv}
                                            height="18px"
                                            id={"uvi"}
                                            style={{ paddingRight: "5px" }}
                                          />{" "}
                                          <br></br>
                                          <p
                                            style={{
                                              display: "inline-block",
                                              fontSize: "13px",
                                            }}
                                          >
                                            {" "}
                                            {parseFloat(day.uvi).toFixed(
                                              2
                                            )}{" "}
                                          </p>
                                        </span>{" "}
                                      </center>
                                    </div>
                                    <div className="col">
                                      <center>
                                        <span>
                                          <UncontrolledTooltip
                                            target={"rh"}
                                            placement="top"
                                          >
                                            Relative Humidity
                                          </UncontrolledTooltip>
                                          <img
                                            src={humidity}
                                            height="18px"
                                            id={"rh"}
                                            style={{ paddingRight: "5px" }}
                                          />
                                          <p
                                            style={{
                                              display: "inline-block",
                                              fontSize: "13px",
                                            }}
                                          >
                                            {" "}
                                            {parseFloat(day.humidity).toFixed(
                                              2
                                            )}{" "}
                                            %
                                          </p>
                                        </span>
                                      </center>
                                    </div>
                                    <div className="col">
                                      <center>
                                        <span>
                                          <UncontrolledTooltip
                                            target={"pressure"}
                                            placement="top"
                                          >
                                            Pressure
                                          </UncontrolledTooltip>
                                          <img
                                            src={pressure}
                                            height="18px"
                                            id={"pressure"}
                                            style={{ paddingRight: "5px" }}
                                          />
                                          <p
                                            style={{
                                              display: "inline-block",
                                              fontSize: "13px",
                                            }}
                                          >
                                            {parseFloat(day.pressure).toFixed(
                                              2
                                            )}{" "}
                                            hPa
                                          </p>
                                        </span>
                                      </center>
                                    </div>
                                  </div>
                                </CardBody>
                              </Card>
                            </Col>
                          ))}
                        </Carousel>
                      </div>
                    </Row>
                  </CardBody>
                </Card>
              </Row>
            </span>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default withNamespaces()(weather_Forecast);
