import React, { Component } from "react";
import {
  Card,
  CardBody,
  Row,
  Col,
  CardText,
  FormGroup,
  NavLink,
  NavItem,
  Nav,
  Label,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container,
  Button,
  Spinner,
} from "reactstrap";
// import './table.css'
import { MDBDataTable } from "mdbreact";
import "antd/dist/antd.css";
import { Link } from "react-router-dom";
//Import Images

import Permission from "../../permission.js";
import { DatePicker, Space } from "antd";

import FarmerPolicy from "./farmerPolicyInfo";
import CropInsured from "./farmerCropInsured";
import FarmerClaims from "./farmerClaims";
import validate_Token from "../../validateToken.js";
import { withNamespaces } from "react-i18next";

//import css
import "../../assets/scss/tab.css";
import { toast } from "react-toastify";
toast.configure();

class FarmerSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal_static: false,
      kb1: true,
      kb2: true,
      kb3: true,
      kb4: true,
      kb5: true,
      kb6: true,
      kb7: true,
      all: true,
      farmerId: "",
      userRole: "",
      activeTab: "1",
      activeTab1: "5",
      activeTab2: "9",
      activeTab3: "13",
      customActiveTab: "1",
      activeTabJustify: "5",
      col1: true,
      col2: false,
      col3: false,
      col5: true,
      showtable: false,
      showLoader: false,
      farmercrops: [],
      showWaiting: true,
      showDetailTable: false,
      rua: "all",
      crop: "all",
      farmerResponse: [],
      farmerpolicy: "",
      ispolicy: false,
      seasonData: [],
      cropData: [
        "Paddy",
        "Veg Group I",
        "Mango",
        "Paddy 2nd Crop",
        "Paddy 3rd Crop",
        "Paddy Winter",
        "Veg Group I - SMR",
      ],
      season: "all",
      farmerDetail: [
        {
          common: {
            farmer_id: "89352274",
            farmer_name: "MUHAMMED SHAREEF",
            age: 39,
            gender: "M",
            farmer_mobile: "9447521928",
            rua: "Eruthenpathy",
            address: "NADUVILA VALAPPIL NEAR LPS KOZHIKKARA KAPPUR PO PALAKKAD",
            district_of_residence: "Palakkad",
            state_of_residence: "KERALA",
          },
        },
        {
          policy: {
            scheme_name: "Pradhan Mantri Fasal Bima Yojana",
            season: "Rabi 2020-22",
            year: 2018,
            application_created_date: "2019-03-15T14:58:45.340000Z",
            application_id: "040232181011651339801",
            application_source: "BANK",
            insurance_company_name: "AGRICULTURE INSURANCE COMPANY",
            farmer_type: "LOANEE",
            farmer_category: "marginal",
            nature_of_farmer: "owner",
            account_number: "*******7535",
            farmer_account_type: "LOAN",
            branch_name: "KUNNAMKULAM",
            ifsc: "SBIN0003533",
            gross_premium: "1968.960000",
            sum_insured: "61530.0000000",
          },
        },
        {
          crops: {
            crop_code: "021002001",
            crop_name: "Banana - Plaintain",
            land_survey_number: "175/1",
            land_subdivision_number: "175",
            area_insured: "0.205100",
            mix_crop: "No",
            combined_area_insured: "0.205100",
            sowing_date: "2018-09-27T06:30:00Z",
            cut_of_date: "2019-03-15T23:59:59Z",
          },
        },
      ],
      farmerList: [
        {
          farmer_id: "",
          farmer_name: " ",
          rua: "",
          farmer_mobile: "",
        },
      ],
      farmermdlist: [],
      farmer_payout: [],
      shownodata: false,
      selrua: "",
      totalfarmers: "",
    };
    this.openprofilebox = this.openprofilebox.bind(this);
    this.onChange = this.onChange.bind(this);
    this.searchFamer = this.searchFamer.bind(this);
    this.toggle1 = this.toggle1.bind(this);
    this.handleSeasonchange = this.handleSeasonchange.bind(this);
    this.handleCropchange = this.handleCropchange.bind(this);
  }
  toggle1(tab) {
    if (this.state.activeTab1 !== tab) {
      this.setState({
        activeTab1: tab,
      });
    }
  }

  async getfarmerdata(farmerid) {
    this.setState({ showDetailTable: false });
    let farmerId = {
      farmer_id: farmerid,
    };
    this.setState({
      farmerId: farmerid,
    });
    farmerId = JSON.stringify(farmerId);
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: "Token " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      body: farmerId,
    };
    var farmerresponse = await fetch(
      process.env.REACT_APP_APIEND + `department/farmer-profile`,
      requestOptions
    ).then((res) => {
      return res.json();
    });
    if (farmerresponse.hasOwnProperty("code")) {
      if (farmerresponse.code == 200) {
        if (farmerresponse.common[0] != []) {
          this.setState({
            farmerDetail: farmerresponse.common[0],
          });
        }
        if (farmerresponse.crops[0] != []) {
          this.setState({
            farmercrops: farmerresponse.crops,
          });
        }
        if (farmerresponse.policy != 0) {
          this.setState({
            farmerpolicy: farmerresponse.policy[0],
          });
        }

        this.setState({
          showDetailTable: true,
          farmerResponse: Object.keys(farmerresponse.crops),
        });
      }
    }
  }
  openprofilebox(key) {
    this.setState({
      modal_static: true,
      activeTab1: "5",
    });
    // console.log("FARMER LIST",this.state.farmerList[key].farmer_id);
    // console.log("FARMER LIST", this.state.farmerList);
    this.getfarmerdata(this.state.farmerList[key].farmer_id);
  }
  // onChangerua(ev){
  //     console.log("DROPDOWN CHANGE",ev.target.name,":",ev.target.value)
  //     this.setState({
  //         selrua: ev.target.value,
  //         [ev.target.name]: ev.target.value
  //     })
  // }
  // onChangeseason(ev){
  //     console.log("DROPDOWN CHANGE",ev.target.name,":",ev.target.value)
  //     this.setState({
  //         [ev.target.name]: ev.target.value,
  //     })
  // }
  // onChangecrop(ev){
  //     console.log("DROPDOWN CHANGE",ev.target.name,":",ev.target.value)
  //     this.setState({
  //         [ev.target.name]: ev.target.value,
  //     })
  // }
  async handleCropchange(e) {
    this.setState({
      crop: e.target.value,
    });
    let searchdata = {
      season: this.state.season,
      crop: e.target.value,
    };
    searchdata = JSON.stringify(searchdata);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + localStorage.getItem("token"),
      },
      body: searchdata,
    };
    try {
      var perildata = await fetch(
        process.env.REACT_APP_APIEND + `smart_crop/get-perils`,
        requestOptions
      ).then((res) => {
        return res.json();
      });
      var newperils = perildata.result[0].perils;
      // console.log("PERIL DATA", newperils);
      this.setState({
        perilData: newperils,
      });
    } catch (err) {
      toast.error("Check Season");
    }
  }
  async fetchseason() {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + localStorage.getItem("token"),
      },
    };
    try {
      var seasonData = await fetch(
        process.env.REACT_APP_APIEND + `smart_crop/term-sheet-season-crop`,
        requestOptions
      ).then((res) => {
        return res.json();
      });
      this.setState({
        perilseasonData: seasonData.result,
      });

      var newseasonData = [];
      {
        seasonData.result.map(function (item, index, data) {
          newseasonData.push(Object.keys(item));
        });
      }
      // console.log("NEW DATA", newseasonData);
      this.setState({
        seasonData: newseasonData,
      });
    } catch (err) {
      toast.error("Something's not right");
    }
  }
  handleSeasonchange(e) {
    this.setState({
      season: e.target.value,
    });
    this.setState({
      cropData: [],
    });
    let selected_index = e.target.selectedIndex;
    let season = e.target.value;
    let allcropdata = this.state.perilseasonData;
    if (selected_index == "0") {
      var list_array = [];
      this.setState({
        cropData: [],
      });
      allcropdata.map((index) => {
        list_array.push("---------" + Object.keys(index)[0] + "--------");
        // console.log("INDEX ", index[Object.keys(index)[0]]);
        var seasoncrop = index[Object.keys(index)[0]];
        seasoncrop.map((crop) => {
          list_array.push(crop);
        });
      });
      this.setState({
        cropData: list_array,
        crop: "all",
      });
    } else {
      this.setState(
        {
          cropData: [],
        },
        () => {
          var seasoncrop = allcropdata[selected_index - 1];
          // console.log("Season crop", seasoncrop);
          var croplist = seasoncrop[Object.keys(seasoncrop)[0]];
          // console.log("Season crop 2", croplist);
          var list_array = [];
          croplist.map((item, index, data) => {
            list_array.push(item);
          });
          // console.log("Season crop 2", list_array);
          this.setState(
            {
              cropData: list_array,
              crop: "all",
            },
            () => {
              // console.log("NEW CROPLOSTARRAYA", this.state.cropData);
            }
          );
        }
      );
    }
  }

  onChange(ev) {
    this.setState({
      [ev.target.name]: ev.target.value,
    });
    if ([ev.target.name] == "season") {
      this.setState({
        [ev.target.name]: ev.target.value,
      });
      // console.log("clicked", ev.target.value);
    }
  }
  generatetabledata(data) {
    let getthis = this;
    let tabledata = [];
    data.map(function (item, index, data) {
      var newdata = {
        id: index + 1,
        farmer_id: item.farmer_id,
        farmer: (
          <Link to="#" onClick={() => getthis.openprofilebox(index)}>
            {item.name}
          </Link>
        ),
        rua: item.rua,
        phno: item.mobile,
      };
      tabledata.push(newdata);
      getthis.setState({
        totalfarmers: index + 1,
      });
    });
    this.setState({
      farmermdlist: tabledata,
    });
  }

  async searchFamer() {
    this.setState({
      showLoader: true,
      showtable: false,
      showWaiting: false,
      shownodata: false,
    });
    // console.log("RUA", this.state.rua);
    // console.log("CROP", this.state.crop);
    let searchdata = {
      crop: this.state.crop,
      rua: this.state.rua === "Chittur-Thathamangalam" ? "Chittur" : this.state.rua,
      season: this.state.season,
    };
    searchdata = JSON.stringify(searchdata);
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: "Token " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      body: searchdata,
    };
    var searchrespone = await fetch(
      process.env.REACT_APP_APIEND + `farmer/search`,
      requestOptions
    ).then((res) => {
      return res.json();
    });
    if (searchrespone.hasOwnProperty("code")) {
      if (searchrespone.code == 200) {
        if (searchrespone.data.length != 0) {
          this.generatetabledata(searchrespone.data);
          this.setState({
            farmerList: searchrespone.data,
            showLoader: false,
            showtable: true,
          });
        } else {
          this.setState({
            showLoader: false,
            shownodata: true,
          });
        }
      }
    }
  }

  componentDidMount() {
    validate_Token();
    this.fetchseason();
    const krishibhavan = Permission();
    if (krishibhavan[0] != "KB") {
      this.setState({
        selrua: "all",
        rua: "all",
        all: true,
      });
    }
    if (krishibhavan[0] == "KB") {
      this.setState({
        userRole: "KB",
        rua: krishibhavan[1],
      });
    }
    if (krishibhavan[1] == "Nallepilly" && krishibhavan[0] == "KB") {
      this.setState({
        kb1: false,
        kb2: false,
        kb3: false,
        kb4: true,
        kb5: false,
        kb6: false,
        kb7: false,
        selrua: "Nallepilly",
        rua: "Nallepilly",
        all: false,
      });
    } else if (
      krishibhavan[1] == "Chittur-Thathamangalam" &&
      krishibhavan[0] == "KB"
    ) {
      this.setState({
        kb1: true,
        kb2: false,
        kb3: false,
        kb4: false,
        kb5: false,
        kb6: false,
        kb7: false,
        selrua: "Chittur-Thathamangalam",
        rua: "Chittur-Thathamangalam",
        all: false,
      });
    } else if (krishibhavan[1] == "Kozhinjampara" && krishibhavan[0] == "KB") {
      this.setState({
        kb1: false,
        kb2: false,
        kb3: true,
        kb4: false,
        kb5: false,
        kb6: false,
        kb7: false,
        selrua: "Kozhinjampara",
        rua: "Kozhinjampara",
        all: false,
      });
    } else if (krishibhavan[1] == "Eruthenpathy" && krishibhavan[0] == "KB") {
      this.setState({
        kb1: false,
        kb2: true,
        kb3: false,
        kb4: false,
        kb5: false,
        kb6: false,
        kb7: false,
        selrua: "Eruthenpathy",
        rua: "Eruthenpathy",
        all: false,
      });
    } else if (krishibhavan[1] == "Pattanchery" && krishibhavan[0] == "KB") {
      this.setState({
        kb1: false,
        kb2: false,
        kb3: false,
        kb4: false,
        kb5: true,
        kb6: false,
        kb7: false,
        selrua: "Pattanchery",
        rua: "Pattanchery",
        all: false,
      });
    } else if (krishibhavan[1] == "Perumatty" && krishibhavan[0] == "KB") {
      this.setState({
        kb1: false,
        kb2: false,
        kb3: false,
        kb4: false,
        kb5: false,
        kb6: true,
        kb7: false,
        selrua: "Perumatty",
        rua: "Perumatty",
        all: false,
      });
    } else if (krishibhavan[1] == "Vadakarapathy" && krishibhavan[0] == "KB") {
      this.setState({
        kb1: false,
        kb2: false,
        kb3: false,
        kb4: false,
        kb5: false,
        kb6: false,
        kb7: true,
        selrua: "Vadakarapathy",
        rua: "Vadakarapathy",
        all: false,
      });
    }
  }
  render() {
    const { t, i18n } = this.props;
    let data = {
      columns: [
        {
          label: "Sl. No.",
          field: "id",
          width: 100,
        },
        {
          label: "Farmer ID",
          field: "farmer_id",
          width: 100,
        },
        {
          label: "Farmer Name",
          field: "farmer",
          width: 300,
        },

        {
          label: "RUA",
          field: "rua",
          width: 100,
        },
        {
          label: "Phone Number",
          field: "phno",
        },
      ],
      rows: this.state.farmermdlist,
    };
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Row>
              <Col xs={12}>
                <h4
                  className="card-title"
                  style={{ padding: "5px", fontSize: "18px" }}
                >
                  {" "}
                  {t("Farmer Search")}{" "}
                </h4>
              </Col>
            </Row>
            <Row>
              <Col>
                <Card style={{ backgroundColor: "#f1f6fb" }}>
                  <CardBody>
                    <Col>
                      <Row>
                        <Col md="4">
                          <FormGroup>
                            <Label
                              className="col-md-5 col-form-label"
                              style={{ fontSize: "14px" }}
                            >
                              {t("Location")}{" "}
                            </Label>
                            <select
                              className="form-select"
                              md="6"
                              style={{ fontSize: "11px" }}
                              name="rua"
                              value={this.state.rua}
                              onChange={this.onChange}
                            >
                              <option
                                value="all"
                                style={
                                  this.state.userRole == "KB"
                                    ? { display: "none" }
                                    : {}
                                }
                              >
                                All
                              </option>
                              <option
                                value="Chittur-Thathamangalam"
                                style={
                                  this.state.kb1 ? {} : { display: "none" }
                                }
                              >
                                Chittur-Thathamangalam
                              </option>
                              <option
                                value="Eruthenpathy"
                                style={
                                  this.state.kb2 ? {} : { display: "none" }
                                }
                              >
                                Eruthenpathy
                              </option>
                              <option
                                value="Kozhinjampara"
                                style={
                                  this.state.kb3 ? {} : { display: "none" }
                                }
                              >
                                Kozhinjampara
                              </option>
                              <option
                                value="Nallepilly"
                                style={
                                  this.state.kb4 ? {} : { display: "none" }
                                }
                              >
                                Nallepilly
                              </option>
                              <option
                                value="Pattanchery"
                                style={
                                  this.state.kb5 ? {} : { display: "none" }
                                }
                              >
                                Pattanchery
                              </option>
                              <option
                                value="Perumatty"
                                style={
                                  this.state.kb6 ? {} : { display: "none" }
                                }
                              >
                                Perumatty
                              </option>
                              <option
                                value="Vadakarapathy"
                                style={
                                  this.state.kb7 ? {} : { display: "none" }
                                }
                              >
                                Vadakarapathy
                              </option>
                            </select>
                          </FormGroup>
                        </Col>

                        <Col md="2">
                          <FormGroup>
                            <Label
                              className="col-md-10 col-form-label"
                              style={{ fontSize: "14px" }}
                            >
                              {t("Season")}{" "}
                            </Label>
                            <select
                              className="form-select"
                              style={{ fontSize: "11px" }}
                              onChange={this.handleSeasonchange}
                              name="season"
                            >
                              <option value="all">All</option>
                              {this.state.seasonData.length > 0 &&
                                this.state.seasonData.map((item) => (
                                  <option value={item} key={item}>
                                    {item}
                                  </option>
                                ))}
                            </select>
                          </FormGroup>
                        </Col>
                        <Col md="2">
                          <FormGroup>
                            <Label
                              className="col-md-10 col-form-label"
                              style={{ fontSize: "14px" }}
                            >
                              {t("Crop")}{" "}
                            </Label>
                            <select
                              className="form-select"
                              style={{ fontSize: "11px" }}
                              onChange={this.handleCropchange}
                              name="crop"
                            >
                              <option value="all">All</option>
                              {this.state.cropData.length > 0 &&
                                this.state.cropData.map((item) => (
                                  <option value={item} key={item}>
                                    {item}
                                  </option>
                                ))}
                            </select>
                          </FormGroup>
                        </Col>
                        <Col md="2">
                          <FormGroup>
                            <Label
                              className="col-md-12 col-form-label"
                              style={{ fontSize: "14px" }}
                            >
                              <span>&nbsp;</span>
                            </Label>
                            <Button
                              color="primary"
                              className="waves-effect waves-light mr-1"
                              style={{ fontSize: "11px", float: "right" }}
                              onClick={this.searchFamer}
                            >
                              {t("Search")}
                            </Button>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col></Col>
                      </Row>
                    </Col>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <center>
                      <p
                        style={
                          this.state.showWaiting == true
                            ? {}
                            : { display: "none" }
                        }
                      >
                        {t("Waiting for search")}
                      </p>
                      <p
                        style={
                          this.state.shownodata == true
                            ? {}
                            : { display: "none" }
                        }
                      >
                        No Data available
                      </p>
                      <Spinner
                        style={
                          this.state.showLoader == false
                            ? { display: "none" }
                            : {}
                        }
                      ></Spinner>
                    </center>
                    <div
                      id="farmersearch"
                      style={
                        this.state.showtable == false ? { display: "none" } : {}
                      }
                    >
                      {/* <p style={{ float: "right" }}>Total number of farmers : {this.state.totalfarmers}</p> */}
                      <div style={{ paddingTop: "10px" }}>
                        <MDBDataTable
                          paging={true}
                          showPaginationBottom={false}
                          bordered
                          scrollX
                          data={data}
                          order={["id", "asc"]}
                          className="datadownloadtable"
                          style={{ fontSize: "12px" }}
                          disableRetreatAfterSorting={true}
                          maxHeight="300px"
                        />
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Modal
              isOpen={this.state.modal_static}
              //   toggle={this.tog_static}
              backdrop="static"
              centered
              size="xl"
              className="modal-dialog modal-dialog-scrollable"
            >
              <ModalHeader
                toggle={() => this.setState({ modal_static: false })}
                style={{ backgroundColor: "#A4C1D2" }}
              >
                Farmer Profile
              </ModalHeader>
              <ModalBody>
                <center>
                  <Spinner
                    style={
                      this.state.showDetailTable == true
                        ? { display: "none" }
                        : {}
                    }
                  ></Spinner>
                </center>
                <Row
                  style={
                    this.state.showDetailTable == true
                      ? {}
                      : { display: "none" }
                  }
                >
                  <Col lg={12}>
                    <Container>
                      <Row>
                        <Col md={12}>
                          <Row>
                            <Col
                              className="mb-2"
                              style={{
                                fontSize: "18px",
                                marginLeft: "12px",
                                fontWeight: "bold",
                              }}
                            >
                              {this.state.farmerDetail.name}
                            </Col>
                          </Row>
                          <Row>
                            <Col
                              className="mb-2"
                              md={2}
                              style={{ marginLeft: "12px" }}
                            >
                              <i
                                className="fas fa-map-marker"
                                style={{ fontSize: "11px", color: "#a3a3a3" }}
                              ></i>{" "}
                              {this.state.farmerDetail.rua}
                            </Col>
                            <Col md={3} style={{ marginLeft: "12px" }}>
                              <i
                                className="fas fa-phone-alt"
                                style={{ fontSize: "11px", color: "#a3a3a3" }}
                              ></i>{" "}
                              {this.state.farmerDetail.mobile}
                            </Col>
                          </Row>
                          <hr className="mt-3 mb-3" />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={3}>
                          <FormGroup>
                            <Label
                              className="col-md-5  "
                              style={{ fontSize: "12px", color: "#a3a3a3" }}
                            >
                              Farmer ID{" "}
                            </Label>
                            <Col>{this.state.farmerDetail.farmer_id}</Col>
                          </FormGroup>
                        </Col>
                        <Col md={3}>
                          <FormGroup>
                            <Label
                              className="col-md-5 "
                              style={{ fontSize: "12px", color: "#a3a3a3" }}
                            >
                              Age | Gender
                            </Label>
                            {/* <Col> {this.state.farmerDetail[0].common.age} | {this.state.farmerDetail[0].common.gender}</Col> */}
                            <Col> NIL | NIL</Col>
                          </FormGroup>
                        </Col>
                        <Col sm={3}>
                          <FormGroup>
                            <Label
                              className="col-md-5  "
                              style={{ fontSize: "12px", color: "#a3a3a3" }}
                            >
                              Address
                            </Label>
                            <Col>{this.state.farmerDetail.address}</Col>
                          </FormGroup>
                        </Col>
                        <Col sm={3}>
                          <FormGroup>
                            <Label
                              className="col-md-5 "
                              style={{ fontSize: "12px", color: "#a3a3a3" }}
                            >
                              District
                            </Label>
                            <Col>{this.state.farmerDetail.district}</Col>
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row></Row>
                    </Container>
                    <Row style={{ padding: "10px" }}>
                      <Col>
                        <div>
                          <Row>
                            <Col lg={12}>
                              <h4 className="card-title"></h4>
                              <ul
                                class="nav nav-tabs"
                                id="myTab"
                                role="tablist"
                              >
                                <li class="nav-item" role="presentation">
                                  <a
                                    class="nav-link active"
                                    id="home-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#home"
                                    type="button"
                                    role="tab"
                                    aria-controls="home"
                                    aria-selected="true"
                                  >
                                    POLICY INFO
                                  </a>
                                </li>
                                <li class="nav-item" role="presentation">
                                  <a
                                    class="nav-link"
                                    id="profile-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#profile"
                                    type="button"
                                    role="tab"
                                    aria-controls="profile"
                                    aria-selected="false"
                                  >
                                    CROP INSURED
                                  </a>
                                </li>
                                <li class="nav-item" role="presentation">
                                  <a
                                    class="nav-link"
                                    id="contact-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#contact"
                                    type="button"
                                    role="tab"
                                    aria-controls="contact"
                                    aria-selected="false"
                                  >
                                    PAYOUT
                                  </a>
                                </li>
                              </ul>
                              <div class="tab-content mt-4" id="myTabContent">
                                <div
                                  class="tab-pane fade show active"
                                  id="home"
                                  role="tabpanel"
                                  aria-labelledby="home-tab"
                                >
                                  <div
                                    style={
                                      this.state.ispolicy
                                        ? { display: "none" }
                                        : {}
                                    }
                                  >
                                    <FarmerPolicy
                                      data={this.state.farmerpolicy}
                                    />
                                  </div>
                                  <center
                                    style={
                                      this.state.ispolicy
                                        ? {}
                                        : { display: "none" }
                                    }
                                  >
                                    No Data Available
                                  </center>
                                </div>
                                <div
                                  class="tab-pane fade"
                                  id="profile"
                                  role="tabpanel"
                                  aria-labelledby="profile-tab"
                                >
                                  <CropInsured data={this.state.farmercrops} />
                                </div>
                                <div
                                  class="tab-pane fade"
                                  id="contact"
                                  role="tabpanel"
                                  aria-labelledby="contact-tab"
                                >
                                  <FarmerClaims
                                    farmerId={this.state.farmerId}
                                    farmerCrops={this.state.farmerResponse}
                                  />
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </ModalBody>
            </Modal>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default withNamespaces()(FarmerSearch);
