import React, { Component } from "react";
import {
  Container,
  Card,
  CardBody,
  Row,
  Col,
  Button,
  Input,
  FormGroup,
  Label,
  CardText,
} from "reactstrap";
import { Link } from "react-router-dom";
import $ from "jquery";

import Chart from "react-apexcharts";
import ApexCharts from "apexcharts";
import classnames from "classnames";
import Hightemptable from "./hightemtable.jsx";
import Deficittable from "./deficitrain.jsx";
import Excessraintable from "./excessraintable.jsx";
import Dryspelltable from "./dryspelltable.jsx";
import Congenialtable from "./congenialtable.jsx";
import loader from "../Dashboard/images/loader.gif";
import Termsheettable from "./termsheet_Table";
import Permission from "../../permission.js";
import validate_Token from "../../validateToken.js";
import { toast } from "react-toastify";
import { withNamespaces } from "react-i18next";
import "react-toastify/dist/ReactToastify.css";
import termsheet_json from "./Termsheet.json";
toast.configure();
class termSheet extends Component {
  constructor(props) {
    super(props);

    this.state = {
      location: "Nallepilly",
      season: "Kharif 2020",
      crop: "Paddy",
      peril: "High Temperature",
      acre: 1,
      seasonData: [],
      perilseasonData: [],
      dprovider: "",
      activeTab: "1",
      activeTab1: "5",
      activeTab2: "9",
      activeTab3: "13",
      customActiveTab: "1",
      activeTabJustify: "5",
      col1: true,
      col2: false,
      tmtable: false,
      userRole: "",
      col3: false,
      col5: true,
      showchart: false,
      hightemp: false,
      deficitrain: false,
      excessrain: false,
      dryspell: false,
      diseasecongenial: false,
      showWaiting: true,
      showPhaseselector: false,
      showLoader: true,
      rua: "",
      showLoader: false,
      showCongenial: false,
      isGetdatadisabled: false,
      selectedValue: "",
      dloc: "Nallepilly",
      dperil: "High Temperature",
      kfdcolor: "#151714",
      kfowcolor: "#151714",
      kfallcolor: "#151714",
      kfsencolor: "#151714",
      kfmetcolor: "#151714",
      kfecmwfcolor: "#151714",
      kfnasacolor: "#151714",
      kfaiccolor: "#151714",
      showdeficit: true,
      isDiseaseCong: false,
      isExcessrain: false,
      showfilter: true,
      ddloc: "Nallepilly",
      ddperil: "High Temperature",
      ddseason: "Kharif 2020-21",
      user_DR: false,
      user_KB: false,
      tableloc: "Nallepilly",
      tableseason: "Kharif 2020-21",
      tablecrop: "Paddy",
      tableperils: "High Temperature",
      tabledp: "Online Sensors",
      phaseselection: [],
      allstDate: "",
      allendDate: "",
      tabledata: [],
      tablequeryresponse: [],
      seasonData: [],
      showtable: false,
      selloc: "Nallepilly",
      // dpData: ["AIC", "MeteoBlue", "Nasa Power", "ECMWF"],
      dpData: ["Online Sensors"],
      cropData: ["Paddy", "Veg Group I"],
      perilData: [
        "High Temperature",
        "Deficit Rainfall",
        "Disease Congenial Climate",
        "Dry Spell",
        "Excess Rainfall",
      ],
      //CHITTUR
      kb1: true,

      //ERUTHENPATHY
      kb2: true,

      //KOZJINJAMPARA
      kb3: true,

      //NALLEPILLY
      kb4: true,

      //NALLEPILLY
      fkb4: true,

      //PATTANCHERY
      kb5: true,

      //PERUMATTY
      kb6: true,

      //VADAKARAPATHY
      kb7: true,
      options: {
        chart: {
          id: "area-datetime",
          type: "area",
          height: 350,
          zoom: {
            autoScaleYaxis: true,
          },
        },
        xaxis: {
          type: "datetime",
          tickAmount: 6,
          labels: {
            datetimeUTC: false,
            datetimeFormatter: {
              year: "yyyy",
              month: "dd MMM",
              day: "dd MMM",
              hour: "HH:mm",
            },
          },
        },
        tooltip: {
          x: {
            format: "dd.MM.yyyy HH:mm",
          },
        },
        legend: {
          show: false,
        },
      },
      series: [
        {
          name: "Test Data",
          data: [],
        },
        // {
        //   name: "Test Data3",
        //   data2: [],
        // },
        // {
        //   name: "Test Data4",
        //   data2: [
        //     { x: 1615362718000, y: 77.95 },
        //     { x: 1615363619000, y: 90.34 },
        //     { x: 1615364518000, y: 24.18 },
        //     { x: 1615365418000, y: 21.05 },
        //     { x: 1615366318000, y: 71.0 },
        //     { x: 1615367218000, y: 80.95 },
        //     { x: 1615369018000, y: 81.24 },
        //     { x: 1615369918000, y: 51.29 },
        //     { x: 1615467413000, y: 41.85 },
        //     { x: 1615467520000, y: 21.86 },
        //     { x: 1615467681000, y: 12.28 },
        //     { x: 1615468200000, y: 82.1 },
        //   ],
        // },
        {
          name: "Test Data2",
          data: [
            { x: 1615362718000, y: 77.95 },
            { x: 1615363619000, y: 90.34 },
            { x: 1615364518000, y: 24.18 },
            { x: 1615365418000, y: 21.05 },
            { x: 1615366318000, y: 71.0 },
            { x: 1615367218000, y: 80.95 },
            { x: 1615369018000, y: 81.24 },
            { x: 1615369918000, y: 51.29 },
            { x: 1615467413000, y: 41.85 },
            { x: 1615467520000, y: 21.86 },
            { x: 1615467681000, y: 12.28 },
            { x: 1615468200000, y: 82.1 },
          ],
        },
      ],
      optionscongenial1: {
        chart: {
          id: "tmaxcongenial",
          group: "congenial",
          type: "area",
          height: 350,
          stacked: false,
          zoom: {
            autoScaleYaxis: true,
          },
        },
        xaxis: {
          type: "datetime",
          tickAmount: 6,
          labels: {
            datetimeFormatter: {
              year: "yyyy",
              month: "dd MMM",
              day: "dd MMM",
              hour: "HH:mm",
            },
          },
          title: {
            text: "Date/Time",
            rotate: -90,
            offsetX: 0,
            offsetY: 0,
            style: {
              color: undefined,
              fontSize: "12px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 600,
              cssClass: "apexcharts-yaxis-title",
            },
          },
        },
        yaxis: {
          min: 0,
          max: 50,
          title: {
            text: "Temperature - Max (°C)",
            rotate: -90,
            offsetX: 0,
            offsetY: 0,
            style: {
              color: undefined,
              fontSize: "12px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 600,
              cssClass: "apexcharts-yaxis-title",
            },
          },
        },
        tooltip: {
          x: {
            format: "dd MMM yyyy",
          },
        },
        title: {
          text: "TEMPERATURE",
          align: "left",
        },
      },
      seriescongenial1: [
        {
          name: "temp test",
          data: [],
        },
      ],
      optionscongenial2: {
        chart: {
          id: "humiditycongenial",
          group: "congenial",
          height: 350,
          stacked: false,
          zoom: {
            autoScaleYaxis: true,
          },
        },
        xaxis: {
          type: "datetime",
          tickAmount: 6,
          labels: {
            datetimeFormatter: {
              year: "yyyy",
              month: "dd MMM",
              day: "dd MMM",
              hour: "HH:mm",
            },
          },
        },

        yaxis: {
          show: true,
          min: 0,
          title: {
            text: "Humidity - Avg (%)",
            rotate: -90,
            offsetX: 0,
            offsetY: 0,
            style: {
              color: undefined,
              fontSize: "12px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 600,
              cssClass: "apexcharts-yaxis-title",
            },
          },
        },
        tooltip: {
          x: {
            format: "dd MMM yyyy",
          },
        },
        title: {
          text: "HUMIDITY",
          align: "left",
        },
      },
      seriescongenial2: [
        {
          name: "Data humidity",
          data: [],
        },
      ],
    };
    this.handleLocationchange = this.handleLocationchange.bind(this);
    this.handleCropchange = this.handleCropchange.bind(this);
    this.handleSeasonchange = this.handleSeasonchange.bind(this);
    this.handlePerilchange = this.handlePerilchange.bind(this);
    this.handleAcreChange = this.handleAcreChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.toggle = this.toggle.bind(this);
    this.phaseSelector = this.phaseSelector.bind(this);
    this.handledpchange = this.handledpchange.bind(this);
  }
  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }
  handleLocationchange(e) {
    this.setState({ location: e.target.value, selloc: e.target.value });
    if (e.target.value === "Chittur") {
      this.setState({ rua: "chittur" });
      this.setState({ dloc: "Chittur Thathamangalam", tableloc: "Chittur" });
    }
    if (e.target.value === "Eruthenpathy") {
      this.setState({ rua: "eruthenpathy" });
      this.setState({ dloc: "Eruthenpathy", tableloc: "Eruthenpathy" });
    }
    if (e.target.value === "Kozhinjampara") {
      this.setState({ rua: "kozhinjampara" });
      this.setState({ dloc: "Kozhinjampara", tableloc: "Kozhinjampara" });
    }
    if (e.target.value === "Nallepilly") {
      this.setState({ rua: "nallepilly" });
      this.setState({ dloc: "Nallepilly", tableloc: "Nallepilly" });
    }
    if (e.target.value === "Pattanchery") {
      this.setState({ rua: "pattanchery" });
      this.setState({ dloc: "Pattanchery", tableloc: "Pattanchery" });
    }
    if (e.target.value === "Perumatty") {
      this.setState({ rua: "perumatty" });
      this.setState({ dloc: "Perumatty", tableloc: "Perumatty" });
    }
    if (e.target.value === "Vadakarapathy") {
      this.setState({ rua: "vadakarapathy" });
      this.setState({ dloc: "Vadakarapathy", tableloc: "Vadakarapathy" });
    }
  }
  handledpchange(e) {
    this.setState({
      tabledp: e.target.value,
    });
  }
  handleAcreChange(e) {
    this.setState({ acre: e.target.value });
  }
  phaseSelector(e) {
    this.setState({
      selectedValue: e.target.value,
    });
    if (this.state.peril == "Disease Congenial Climate") {
      this.setState({
        showPhaseselector: false,
      });
    }else if (this.state.peril == "Excess Rainfall") {
      this.setState({
        showPhaseselector: true,
      });
         if(this.state.season == "Kharif 2022" && this.state.crop == "Paddy"){
          if (e.target.id == "p1") {
            var season = this.state.season;
            var crop = this.state.crop;
            var peril = this.state.peril;
            var strike =
              termsheet_json[season].crop[crop].peril[peril].Phase_1.strike;
            ApexCharts.exec("area-datetime", "removeAnnotation", "phase4");
            ApexCharts.exec("area-datetime", "removeAnnotation", "phase2");
            ApexCharts.exec("area-datetime", "removeAnnotation", "phase3");
            ApexCharts.exec("area-datetime", "removeAnnotation", "phase5");
            ApexCharts.exec("area-datetime", "addYaxisAnnotation", {
              id: "phase1",
              y: strike,
              borderColor: "#74007E",
              max: 40,
              label: {
                borderColor: "#74007E",
                style: {
                  color: "#fff",
                  background: "#74007E",
                },
                text: "Strike-Phase 1",
              },
            });
            let st = "2022-06-01";
            st = new Date(st).getTime();
            let end = "2022-10-31";
            end = new Date(end).getTime();
            ApexCharts.exec("area-datetime", "zoomX", st, end);
          }else if (e.target.id == "all") {
            ApexCharts.exec("area-datetime", "removeAnnotation", "phase1");
            ApexCharts.exec("area-datetime", "removeAnnotation", "phase2");
            ApexCharts.exec("area-datetime", "removeAnnotation", "phase3");
            ApexCharts.exec("area-datetime", "removeAnnotation", "phase4");
            ApexCharts.exec("area-datetime", "removeAnnotation", "phase5");
            ApexCharts.exec("area-datetime", "resetSeries");
            // ApexCharts.exec(
            //   "area-datetime",
            //   "zoomX",
            //   this.state.allstDate,
            //   this.state.allendDate
            // );
          }
        }else if(this.state.season == "Kharif 2022" && this.state.crop == "Veg Group I"){
          if (e.target.id == "p1") {
            var season = this.state.season;
            var crop = this.state.crop;
            var peril = this.state.peril;
            var strike =
              termsheet_json[season].crop[crop].peril[peril].Phase_1.strike;
            ApexCharts.exec("area-datetime", "removeAnnotation", "phase4");
            ApexCharts.exec("area-datetime", "removeAnnotation", "phase2");
            ApexCharts.exec("area-datetime", "removeAnnotation", "phase3");
            ApexCharts.exec("area-datetime", "removeAnnotation", "phase5");
            ApexCharts.exec("area-datetime", "addYaxisAnnotation", {
              id: "phase1",
              y: strike,
              borderColor: "#74007E",
              max: 40,
              label: {
                borderColor: "#74007E",
                style: {
                  color: "#fff",
                  background: "#74007E",
                },
                text: "Strike-Phase 1",
              },
            });
            let st = "2022-06-01";
            st = new Date(st).getTime();
            let end = "2022-09-10";
            end = new Date(end).getTime();
            ApexCharts.exec("area-datetime", "zoomX", st, end);
          }else if (e.target.id == "p2") {
            var season = this.state.season;
            var crop = this.state.crop;
            var peril = this.state.peril;
            var strike =
              termsheet_json[season].crop[crop].peril[peril].Phase_2.strike;
            ApexCharts.exec("area-datetime", "removeAnnotation", "phase1");
            ApexCharts.exec("area-datetime", "removeAnnotation", "phase3");
            ApexCharts.exec("area-datetime", "removeAnnotation", "phase4");
            ApexCharts.exec("area-datetime", "removeAnnotation", "phase5");
            ApexCharts.exec("area-datetime", "addYaxisAnnotation", {
              id: "phase2",
              y: strike,
              borderColor: "#FF0000",
              label: {
                borderColor: "#FF0000",
                offsetX: -430,
      
                style: {
                  color: "#fff",
                  background: "#FF0000",
                },
                text: "Strike-Phase 2",
              },
            });
            let st = "2022-09-11";
            st = new Date(st).getTime();
            let end = "2022-09-30";
            end = new Date(end).getTime();
            ApexCharts.exec("area-datetime", "zoomX", st, end);
          } else if (e.target.id == "all") {
            ApexCharts.exec("area-datetime", "removeAnnotation", "phase1");
            ApexCharts.exec("area-datetime", "removeAnnotation", "phase2");
            ApexCharts.exec("area-datetime", "removeAnnotation", "phase3");
            ApexCharts.exec("area-datetime", "removeAnnotation", "phase4");
            ApexCharts.exec("area-datetime", "removeAnnotation", "phase5");
            ApexCharts.exec("area-datetime", "resetSeries");
            // ApexCharts.exec(
            //   "area-datetime",
            //   "zoomX",
            //   this.state.allstDate,
            //   this.state.allendDate
            // );
          }
        }
        else{
          this.setState({
            showPhaseselector: true,
          });
          if (e.target.id == "p1") {
            var season = this.state.season;
            var crop = this.state.crop;
            var peril = this.state.peril;
            var strike =
              termsheet_json[season].crop[crop].peril[peril].Phase_1.strike;
            ApexCharts.exec("area-datetime", "removeAnnotation", "phase4");
            ApexCharts.exec("area-datetime", "removeAnnotation", "phase2");
            ApexCharts.exec("area-datetime", "removeAnnotation", "phase3");
            ApexCharts.exec("area-datetime", "removeAnnotation", "phase5");
            ApexCharts.exec("area-datetime", "addYaxisAnnotation", {
              id: "phase1",
              y: strike,
              borderColor: "#74007E",
              max: 40,
              label: {
                borderColor: "#74007E",
                style: {
                  color: "#fff",
                  background: "#74007E",
                },
                text: "Strike-Phase 1",
              },
            });
            let st = this.state.phaseselection[0].Phase_1;
            st = st[0].from_date;
            st = new Date(st).getTime();
            let end = this.state.phaseselection[0].Phase_1;
            end = end[0].end_date;
            end = new Date(end).getTime();
            ApexCharts.exec("area-datetime", "zoomX", st, end);
          } else if (e.target.id == "p2") {
            var season = this.state.season;
            var crop = this.state.crop;
            var peril = this.state.peril;
            var strike =
              termsheet_json[season].crop[crop].peril[peril].Phase_2.strike;
            ApexCharts.exec("area-datetime", "removeAnnotation", "phase1");
            ApexCharts.exec("area-datetime", "removeAnnotation", "phase3");
            ApexCharts.exec("area-datetime", "removeAnnotation", "phase4");
            ApexCharts.exec("area-datetime", "removeAnnotation", "phase5");
            ApexCharts.exec("area-datetime", "addYaxisAnnotation", {
              id: "phase2",
              y: strike,
              borderColor: "#FF0000",
              label: {
                borderColor: "#FF0000",
                offsetX: -430,
      
                style: {
                  color: "#fff",
                  background: "#FF0000",
                },
                text: "Strike-Phase 2",
              },
            });
            let st = this.state.phaseselection[1].Phase_2;
            st = st[0].from_date;
            st = new Date(st).getTime();
      
            let end = this.state.phaseselection[1].Phase_2;
            end = end[0].end_date;
            end = new Date(end).getTime();
            ApexCharts.exec("area-datetime", "zoomX", st, end);
          } else if (e.target.id == "p3") {
            var season = this.state.season;
            var crop = this.state.crop;
            var peril = this.state.peril;
            var strike =
              termsheet_json[season].crop[crop].peril[peril].Phase_3.strike;
            ApexCharts.exec("area-datetime", "removeAnnotation", "phase1");
            ApexCharts.exec("area-datetime", "removeAnnotation", "phase2");
            ApexCharts.exec("area-datetime", "removeAnnotation", "phase4");
            ApexCharts.exec("area-datetime", "removeAnnotation", "phase5");
            ApexCharts.exec("area-datetime", "addYaxisAnnotation", {
              id: "phase3",
              y: strike,
              borderColor: "#FE670A",
              label: {
                borderColor: "#FE670A",
                offsetX: -200,
                style: {
                  color: "#fff",
                  background: "#FE670A",
                },
                text: "Strike-Phase 3",
              },
            });
            let st = this.state.phaseselection[2].Phase_3;
            st = st[0].from_date;
            st = new Date(st).getTime();
      
            let end = this.state.phaseselection[2].Phase_3;
            end = end[0].end_date;
            end = new Date(end).getTime();
            ApexCharts.exec("area-datetime", "zoomX", st, end);
          } else if (e.target.id == "p4") {
            var season = this.state.season;
            var crop = this.state.crop;
            var peril = this.state.peril;
            var strike =
              termsheet_json[season].crop[crop].peril[peril].Phase_4.strike;
            ApexCharts.exec("area-datetime", "removeAnnotation", "phase1");
            ApexCharts.exec("area-datetime", "removeAnnotation", "phase2");
            ApexCharts.exec("area-datetime", "removeAnnotation", "phase3");
            ApexCharts.exec("area-datetime", "removeAnnotation", "phase5");
            ApexCharts.exec("area-datetime", "addYaxisAnnotation", {
              id: "phase4",
              y: strike,
              borderColor: "#FFAA0D",
              label: {
                borderColor: "#FFAA0D",
                offsetX: -100,
                style: {
                  color: "#fff",
                  background: "#FFAA0D",
                },
                text: "Strike-Phase 4",
              },
            });
            let st = this.state.phaseselection[3].Phase_4;
            st = st[0].from_date;
            st = new Date(st).getTime();
      
            let end = this.state.phaseselection[3].Phase_4;
            end = end[0].end_date;
            end = new Date(end).getTime();
            ApexCharts.exec("area-datetime", "zoomX", st, end);
          } else if (e.target.id == "p5") {
            var season = this.state.season;
            var crop = this.state.crop;
            var peril = this.state.peril;
            var strike =
              termsheet_json[season].crop[crop].peril[peril].Phase_5.strike;
            ApexCharts.exec("area-datetime", "removeAnnotation", "phase1");
            ApexCharts.exec("area-datetime", "removeAnnotation", "phase2");
            ApexCharts.exec("area-datetime", "removeAnnotation", "phase3");
            ApexCharts.exec("area-datetime", "removeAnnotation", "phase4");
            ApexCharts.exec("area-datetime", "addYaxisAnnotation", {
              id: "phase5",
              y: strike,
              borderColor: "#ffd505",
              label: {
                borderColor: "#ffd505",
                offsetX: -100,
                style: {
                  color: "#fff",
                  background: "#ffd505",
                },
                text: "Strike-Phase 5",
              },
            });
            let st = this.state.phaseselection[4].Phase_5;
            st = st[0].from_date;
            st = new Date(st).getTime();
      
            let end = this.state.phaseselection[4].Phase_5;
            end = end[0].end_date;
            end = new Date(end).getTime();
            ApexCharts.exec("area-datetime", "zoomX", st, end);
          }  
          else if (e.target.id == "all") {
            ApexCharts.exec("area-datetime", "removeAnnotation", "phase1");
            ApexCharts.exec("area-datetime", "removeAnnotation", "phase2");
            ApexCharts.exec("area-datetime", "removeAnnotation", "phase3");
            ApexCharts.exec("area-datetime", "removeAnnotation", "phase4");
            ApexCharts.exec("area-datetime", "removeAnnotation", "phase5");
            ApexCharts.exec("area-datetime", "resetSeries");
            // ApexCharts.exec(
            //   "area-datetime",
            //   "zoomX",
            //   this.state.allstDate,
            //   this.state.allendDate
            // );
          }
         }
    } else {
      this.setState({
        showPhaseselector: true,
      });
      if (e.target.id == "p1") {
        var season = this.state.season;
        var crop = this.state.crop;
        var peril = this.state.peril;
        var strike =
          termsheet_json[season].crop[crop].peril[peril].Phase_1.strike;
        ApexCharts.exec("area-datetime", "removeAnnotation", "phase4");
        ApexCharts.exec("area-datetime", "removeAnnotation", "phase2");
        ApexCharts.exec("area-datetime", "removeAnnotation", "phase3");
        ApexCharts.exec("area-datetime", "removeAnnotation", "phase5");
        ApexCharts.exec("area-datetime", "addYaxisAnnotation", {
          id: "phase1",
          y: strike,
          borderColor: "#74007E",
          max: 40,
          label: {
            borderColor: "#74007E",
            style: {
              color: "#fff",
              background: "#74007E",
            },
            text: "Strike-Phase 1",
          },
        });
        let st = this.state.phaseselection[0].Phase_1;
        st = st[0].from_date;
        st = new Date(st).getTime();
        let end = this.state.phaseselection[0].Phase_1;
        end = end[0].end_date;
        end = new Date(end).getTime();
        ApexCharts.exec("area-datetime", "zoomX", st, end);
      } else if (e.target.id == "p2") {
        var season = this.state.season;
        var crop = this.state.crop;
        var peril = this.state.peril;
        var strike =
          termsheet_json[season].crop[crop].peril[peril].Phase_2.strike;
        ApexCharts.exec("area-datetime", "removeAnnotation", "phase1");
        ApexCharts.exec("area-datetime", "removeAnnotation", "phase3");
        ApexCharts.exec("area-datetime", "removeAnnotation", "phase4");
        ApexCharts.exec("area-datetime", "removeAnnotation", "phase5");
        ApexCharts.exec("area-datetime", "addYaxisAnnotation", {
          id: "phase2",
          y: strike,
          borderColor: "#FF0000",
          label: {
            borderColor: "#FF0000",
            offsetX: -430,

            style: {
              color: "#fff",
              background: "#FF0000",
            },
            text: "Strike-Phase 2",
          },
        });
        let st = this.state.phaseselection[1].Phase_2;
        st = st[0].from_date;
        st = new Date(st).getTime();

        let end = this.state.phaseselection[1].Phase_2;
        end = end[0].end_date;
        end = new Date(end).getTime();
        ApexCharts.exec("area-datetime", "zoomX", st, end);
      } else if (e.target.id == "p3") {
        var season = this.state.season;
        var crop = this.state.crop;
        var peril = this.state.peril;
        var strike =
          termsheet_json[season].crop[crop].peril[peril].Phase_3.strike;
        ApexCharts.exec("area-datetime", "removeAnnotation", "phase1");
        ApexCharts.exec("area-datetime", "removeAnnotation", "phase2");
        ApexCharts.exec("area-datetime", "removeAnnotation", "phase4");
        ApexCharts.exec("area-datetime", "removeAnnotation", "phase5");
        ApexCharts.exec("area-datetime", "addYaxisAnnotation", {
          id: "phase3",
          y: strike,
          borderColor: "#FE670A",
          label: {
            borderColor: "#FE670A",
            offsetX: -200,
            style: {
              color: "#fff",
              background: "#FE670A",
            },
            text: "Strike-Phase 3",
          },
        });
        let st = this.state.phaseselection[2].Phase_3;
        st = st[0].from_date;
        st = new Date(st).getTime();

        let end = this.state.phaseselection[2].Phase_3;
        end = end[0].end_date;
        end = new Date(end).getTime();
        ApexCharts.exec("area-datetime", "zoomX", st, end);
      } else if (e.target.id == "p4") {
        var season = this.state.season;
        var crop = this.state.crop;
        var peril = this.state.peril;
        var strike =
          termsheet_json[season].crop[crop].peril[peril].Phase_4.strike;
        ApexCharts.exec("area-datetime", "removeAnnotation", "phase1");
        ApexCharts.exec("area-datetime", "removeAnnotation", "phase2");
        ApexCharts.exec("area-datetime", "removeAnnotation", "phase3");
        ApexCharts.exec("area-datetime", "removeAnnotation", "phase5");
        ApexCharts.exec("area-datetime", "addYaxisAnnotation", {
          id: "phase4",
          y: strike,
          borderColor: "#FFAA0D",
          label: {
            borderColor: "#FFAA0D",
            offsetX: -100,
            style: {
              color: "#fff",
              background: "#FFAA0D",
            },
            text: "Strike-Phase 4",
          },
        });
        let st = this.state.phaseselection[3].Phase_4;
        st = st[0].from_date;
        st = new Date(st).getTime();

        let end = this.state.phaseselection[3].Phase_4;
        end = end[0].end_date;
        end = new Date(end).getTime();
        ApexCharts.exec("area-datetime", "zoomX", st, end);
      } else if (e.target.id == "p5") {
        var season = this.state.season;
        var crop = this.state.crop;
        var peril = this.state.peril;
        var strike =
          termsheet_json[season].crop[crop].peril[peril].Phase_5.strike;
        ApexCharts.exec("area-datetime", "removeAnnotation", "phase1");
        ApexCharts.exec("area-datetime", "removeAnnotation", "phase2");
        ApexCharts.exec("area-datetime", "removeAnnotation", "phase3");
        ApexCharts.exec("area-datetime", "removeAnnotation", "phase4");
        ApexCharts.exec("area-datetime", "addYaxisAnnotation", {
          id: "phase5",
          y: strike,
          borderColor: "#ffd505",
          label: {
            borderColor: "#ffd505",
            offsetX: -100,
            style: {
              color: "#fff",
              background: "#ffd505",
            },
            text: "Strike-Phase 5",
          },
        });
        let st = this.state.phaseselection[4].Phase_5;
        st = st[0].from_date;
        st = new Date(st).getTime();

        let end = this.state.phaseselection[4].Phase_5;
        end = end[0].end_date;
        end = new Date(end).getTime();
        ApexCharts.exec("area-datetime", "zoomX", st, end);
      }  
      else if (e.target.id == "all") {
        ApexCharts.exec("area-datetime", "removeAnnotation", "phase1");
        ApexCharts.exec("area-datetime", "removeAnnotation", "phase2");
        ApexCharts.exec("area-datetime", "removeAnnotation", "phase3");
        ApexCharts.exec("area-datetime", "removeAnnotation", "phase4");
        ApexCharts.exec("area-datetime", "removeAnnotation", "phase5");
        ApexCharts.exec("area-datetime", "resetSeries");
        // ApexCharts.exec(
        //   "area-datetime",
        //   "zoomX",
        //   this.state.allstDate,
        //   this.state.allendDate
        // );
      }
    }
  }
  findMax(one, two, three, four, five, six) {
    const largest = Math.max(one, two, three, four, five, six);
    let per = (largest * 10) / 100;
    let retn = largest + per;
    let fvalue = Math.floor(retn);
    let top = Math.ceil((fvalue + 1) / 10) * 10;
    let final = parseInt(fvalue / 10, 10) + 1 * 10;

    return top;
  }
  findpercentage(val) {
    let per = (val * 10) / 100;
    return val + per;
  }
  async fetchseason() {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + localStorage.getItem("token"),
      },
    };
    try {
      var seasonData = await fetch(
        process.env.REACT_APP_APIEND + `smart_crop/term-sheet-season-crop`,
        requestOptions
      ).then((res) => {
        return res.json();
      });
      this.setState({
        perilseasonData: seasonData.result,
      });

      var newseasonData = [];
      {
        seasonData.result.map(function (item, index, data) {
          newseasonData.push(Object.keys(item));
        });
      }
      this.setState(
        {
          seasonData: newseasonData,
          season: newseasonData[0][0],
          crop: seasonData.result[0][Object.keys(seasonData.result[0])][0],
          cropData: seasonData.result[0][Object.keys(seasonData.result[0])],
        },
        () => {
          this.getperilList();
        }
      );
    } catch (errseason) {
      toast.error("Something's not right");
    }
  }
  handleSeasonchange(e) {
    this.setState({
      season: e.target.value,
    });
    this.setState({
      cropData: [],
    });
    if (e.target.value == "Kharif 2020") {
      this.setState({
        dpData: ["AIC", "MeteoBlue", "Nasa Power", "ECMWF"],
        tabledp: "AIC",
      });
    } else if (e.target.value == "Rabi 2020-21") {
      this.setState({
        dpData: ["DarkSky"],
        tabledp: "darksky",
      });
    } else {
      this.setState({
        dpData: ["Online Sensors"],
        tabledp: "Online Sensors",
      });
    }
    let selected_index = e.target.selectedIndex;
    let season = e.target.value;
    let allcropdata = this.state.perilseasonData;
    var seasoncrop = allcropdata[selected_index - 0];
    var croplist = seasoncrop[Object.keys(seasoncrop)[0]];
    var list_array = [];
    croplist.map((item, index, data) => {
      list_array.push(item);
    });
    this.setState(
      {
        cropData: list_array,
        crop: list_array[0],
      },
      () => {
        this.getperilList();
      }
    );
  }
  async getperilList() {
    let searchdata = {
      season: this.state.season,
      crop: this.state.crop,
    };
    searchdata = JSON.stringify(searchdata);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + localStorage.getItem("token"),
      },
      body: searchdata,
    };
    try {
      var perildata = await fetch(
        process.env.REACT_APP_APIEND + `smart_crop/get-perils`,
        requestOptions
      ).then((res) => {
        return res.json();
      });
      var newperils = perildata.result[0].perils;
      this.setState({
        perilData: newperils,
        peril: newperils[0],
      });
    } catch (err) {
      toast.error("Check Season");
    }
  }
  handleCropchange(e) {
    this.setState(
      {
        crop: e.target.value,
      },
      () => {
        this.getperilList();
      }
    );
  }
  handlePerilchange(e) {
    this.setState({
      peril: e.target.value,
    });
  }
  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  componentDidMount() {
    validate_Token();
    const user_role = Permission();
    // this.child.fetchtermsheetdata();
    this.fetchseason();
    if (user_role[0] == "DR") {
      this.setState({
        kb4: true,
        userRole: "DR",
      });
    }

    if (user_role[0] == "ADM") {
      this.setState({
        kb4: true,
        userRole: "ADM",
      });
    }

    if (user_role[0] == "KB") {
      this.setState({
        userRole: "KB",
      });
    }

    if (user_role[0] === "KB" || user_role[0] === "FM") {
      if (user_role[1] == "Pattanchery") {
        this.setState({
          kb1: false,
          kb2: false,
          kb3: false,
          kb4: false,
          kb5: true,
          kb6: false,
          kb7: false,
          ddloc: "Pattanchery",
          selloc: "Pattanchery",
        });
      } else if (user_role[1] == "Chittur-Thathamangalam") {
        this.setState({
          kb1: true,
          kb2: false,
          kb3: false,
          kb4: false,
          kb5: false,
          kb6: false,
          kb7: false,
          ddloc: "Chittur-Thathamangalam",
          selloc: "Chittur",
        });
      } else if (user_role[1] == "Nallepilly") {
        this.setState({
          kb1: false,
          kb2: false,
          kb3: false,
          kb4: true,
          kb5: false,
          kb6: false,
          kb7: false,
          ddloc: "Nallepilly",
          selloc: "Nallepilly",
        });
      } else if (user_role[1] == "Kozhinjampara") {
        this.setState({
          kb1: false,
          kb2: false,
          kb3: true,
          kb4: false,
          kb5: false,
          kb6: false,
          kb7: false,
          ddloc: "Kozhinjampara",
          selloc: "Kozhinjampara",
        });
      } else if (user_role[1] == "Eruthenpathy") {
        this.setState({
          kb1: false,
          kb2: true,
          kb3: false,
          kb4: false,
          kb5: false,
          kb6: false,
          kb7: false,
          ddloc: "Eruthenpathy",
          selloc: "Eruthenpathy",
        });
      } else if (user_role[1] == "Perumatty") {
        this.setState({
          kb1: false,
          kb2: false,
          kb3: false,
          kb4: false,
          kb5: false,
          kb6: true,
          kb7: false,
          latnew: 10.662,
          longnew: 76.787,
          ddloc: "Perumatty",
          selloc: "Perumatty",
        });
      } else if (user_role[1] == "Vadakarapathy") {
        this.setState({
          kb1: false,
          kb2: false,
          kb3: false,
          kb4: false,
          kb5: false,
          kb6: false,
          kb7: true,
          ddloc: "Vadakarapathy",
          selloc: "Vadakarapathy",
        });
      }
    }

    // let chart_values = [];
    // this.setState({
    //   hightemp: true,
    //   deficitrain: false,
    //   excessrain: false,
    //   dryspell: false,
    //   diseasecongenial: false,
    //   showPhaseselector: true,
    //   showCongenial: false,
    //   isGetdatadisabled: true,
    //   showLoader: true,
    //   showfilter: false,
    // });
  }

  async handleSubmit(event) {
    this.child.fetchtermsheetdata();
    this.child.fetchconditiondata();
    this.setState({
      seriescongenial1: [],
      seriescongenial2: [],
      series: [],
    });
    if (this.state.peril == "all") {
      this.setState({
        showchart: false,
        tmtable: true,
        showCongenial: false,
        showWaiting: false,
      });
    } else {
      var i = 0;
      var ele = document.getElementsByName("radio2");
      ele[i].checked = true;
      this.setState({
        ddloc: this.state.dloc,
        ddperil: this.state.peril,
        ddseason: this.state.season,
      });

      this.setState({
        showWaiting: false,
        showchart: false,
        tmtable: false,
        showCongenial: false,
        showLoader: true,
        isGetdatadisabled: true,
        selectedValue: "",
        showfilter: true,
        showdeficit: true,
      });
      let spellcheckdp = "";
      if (this.state.tabledp == "darksky") {
        spellcheckdp = "darksky";
      } else {
        spellcheckdp = this.state.tabledp;
      }
      let searchdata = {
        rua: this.state.selloc,
        data_provider: spellcheckdp,
        crop: this.state.crop,
        peril: this.state.peril,
        season: this.state.season,
      };
      searchdata = JSON.stringify(searchdata);

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + localStorage.getItem("token"),
        },
        body: searchdata,
      };
      try {
        var chartdata = await fetch(
          process.env.REACT_APP_APIEND + `smart_crop/rua-termchart-provider`,
          requestOptions
        ).then((res) => {
          return res.json();
        });
        if (chartdata.data.length > 5) {
          if (this.state.peril != "Disease Congenial Climate") {
            this.getValues(
              chartdata.data,
              this.state.tabledp,
              chartdata.Phases
            );
          } else {
            this.setDiseasecon(chartdata.data, this.state.tabledp);
          }
        } else {
          toast.info(
            "Weather data is not available for the selected conditions !"
          );
          this.setState({
            showLoader: false,
            isGetdatadisabled: false,
            showWaiting: true,
          });
        }
        if (chartdata.Phases.length > 0) {
          this.setState({
            phaseselection: chartdata.Phases,
          });
        }
        // } else {
        //   toast.error("Data is Empty !");
        // }
      } catch (err) {
        // console.log("ERROR FETCHING DATA", err);
      }
    }
  }
  setDiseasecon(data, dp) {
    this.setState({
      showPhaseselector: false,
    });
    var chartHMvalues = [];
    var chartTMvalues = [];
    var dataHM = {
      name: dp,
      data: [],
    };
    var dataTM = {
      name: dp,
      data: [],
    };
    for (var key in data) {
      // console.log(object[key]);
      //   if (values.length>0) continue;

      if (
        data[key].parameter == "Humidity_avg" ||
        data[key].parameter == "Humidity"
      ) {
        dataHM.data.push({ x: data[key].unixms * 1000, y: data[key].value });
      }
      if (
        data[key].parameter == "Temperature_avg" ||
        data[key].parameter == "Temperature"
      ) {
        dataTM.data.push({ x: data[key].unixms * 1000, y: data[key].value });
      }
    }
    chartHMvalues = [dataHM];
    chartTMvalues = [dataTM];
    if (this.state.crop == "Veg Group I") {
      this.setState({
        optionscongenial1: {
          colors: ["#ab2200"],
          chart: {
            id: "tmaxcongenial",
            group: "Disease_CONG",
            animations: {
              enabled: false,
            },
            stacked: false,
            zoom: {
              type: "x",
              enabled: true,
            },
            toolbar: {
              autoSelected: "zoom",
              show: true,
            },
            background: "#fff",
          },
          annotations: {
            yaxis: [
              {
                y: this.getDCCTM("min"),
                y2: this.getDCCTM("max"),
                borderColor: "#000",
                fillColor: "#e8b990",
                label: {
                  text: "Temperature range",
                },
              },
            ],
          },
          dataLabels: {
            enabled: false,
          },
          markers: {
            size: 0,
          },
          title: {
            text: "Temperature",
            align: "left",
          },
          // fill: {
          //     type: 'gradient',
          //     gradient: {
          //         shadeIntensity: 1,
          //         opacityFrom: 0.7,
          //         opacityTo: 0.9,
          //         stops: [0, 100]
          //     }
          // },
          xaxis: {
            type: "datetime",
            labels: {
              datetimeFormatter: {
                year: "yyyy",
                month: "dd MMM",
                day: "dd MMM",
                hour: "HH:mm",
              },
            },
            title: {
              text: "Date/Time",
              rotate: -90,
              offsetX: 0,
              offsetY: 0,
              style: {
                color: undefined,
                fontSize: "12px",
                fontFamily: "Helvetica, Arial, sans-serif",
                fontWeight: 600,
                cssClass: "apexcharts-yaxis-title",
              },
            },
          },
          yaxis: {
            min: 0,
            max: 50,
            title: {
              text: this.checkDccPerilUnit(),
              rotate: -90,
              offsetX: 0,
              offsetY: 0,
              style: {
                color: undefined,
                fontSize: "12px",
                fontFamily: "Helvetica, Arial, sans-serif",
                fontWeight: 600,
                cssClass: "apexcharts-yaxis-title",
              },
            },
          },
          stroke: {
            width: 1,
          },

          tooltip: {
            shared: false,
            x: {
              format: "dd MMM - HH : mm ",
            },
          },
        },
        seriescongenial1: chartTMvalues,
        optionscongenial2: {
          colors: ["#0b6b75"],
          chart: {
            id: `humiditycongenial`,
            group: "Disease_CONG",
            animations: {
              enabled: false,
            },
            stacked: false,
            zoom: {
              type: "x",
              enabled: true,
            },
            background: "#fff",
            toolbar: {
              autoSelected: "zoom",
              show: true,
            },
          },

          annotations: {
            yaxis: [
              {
                y: this.getDCCRH("min"),
                y2: this.getDCCRH("max"),
                borderColor: "#000",
                fillColor: "#a2e5eb",
                label: {
                  text: "Humidity range",
                },
              },
            ],
          },
          dataLabels: {
            enabled: false,
          },
          markers: {
            size: 0,
          },
          title: {
            text: "Humidity",
            align: "left",
          },
          // fill: {
          //     type: 'gradient',
          //     gradient: {
          //         shadeIntensity: 1,
          //         opacityFrom: 0.7,
          //         opacityTo: 0.9,
          //         stops: [0, 100]
          //     }
          // },
          xaxis: {
            type: "datetime",
            labels: {
              datetimeFormatter: {
                year: "yyyy",
                month: "dd MMM",
                day: "dd MMM",
                hour: "HH:mm",
              },
            },
            title: {
              text: "Date/Time",
              rotate: -90,
              offsetX: 0,
              offsetY: 0,
              style: {
                color: undefined,
                fontSize: "12px",
                fontFamily: "Helvetica, Arial, sans-serif",
                fontWeight: 600,
                cssClass: "apexcharts-yaxis-title",
              },
            },
          },
          yaxis: {
            show: true,
            min: 0,
            title: {
              text: "Humidity - Avg (%)",
              rotate: -90,
              offsetX: 0,
              offsetY: 0,
              style: {
                color: undefined,
                fontSize: "12px",
                fontFamily: "Helvetica, Arial, sans-serif",
                fontWeight: 600,
                cssClass: "apexcharts-yaxis-title",
              },
            },
          },
          stroke: {
            width: 1,
          },

          tooltip: {
            shared: false,
            x: {
              format: "dd MMM - HH : mm ",
            },
          },
        },
        seriescongenial2: chartHMvalues,
        isDiseaseCong: true,
        isExcessrain: false,
        showchart: true,
        showtable: true,
        tmtable: true,
        showLoader: false,
        isGetdatadisabled: false,
      });
    } else {
      this.setState({
        optionscongenial1: {
          colors: ["#ab2200"],
          chart: {
            id: "tmaxcongenial",
            group: "Disease_CONG",
            animations: {
              enabled: false,
            },
            stacked: false,
            zoom: {
              type: "x",
              enabled: true,
            },
            toolbar: {
              autoSelected: "zoom",
              show: true,
            },
            background: "#fff",
          },
          annotations: {
            yaxis: [
              {
                y: this.getDCCTM("min"),
                y2: this.getDCCTM("max"),
                borderColor: "#000",
                fillColor: "#e8b990",
                label: {
                  text: "Temperature range",
                },
              },
            ],
          },
          dataLabels: {
            enabled: false,
          },
          markers: {
            size: 0,
          },
          title: {
            text: "Temperature",
            align: "left",
          },
          // fill: {
          //     type: 'gradient',
          //     gradient: {
          //         shadeIntensity: 1,
          //         opacityFrom: 0.7,
          //         opacityTo: 0.9,
          //         stops: [0, 100]
          //     }
          // },
          xaxis: {
            type: "datetime",
            labels: {
              datetimeFormatter: {
                year: "yyyy",
                month: "dd MMM",
                day: "dd MMM",
                hour: "HH:mm",
              },
            },
            title: {
              text: "Date/Time",
              rotate: -90,
              offsetX: 0,
              offsetY: 0,
              style: {
                color: undefined,
                fontSize: "12px",
                fontFamily: "Helvetica, Arial, sans-serif",
                fontWeight: 600,
                cssClass: "apexcharts-yaxis-title",
              },
            },
            title: {
              text: "Date/Time",
              rotate: -90,
              offsetX: 0,
              offsetY: 0,
              style: {
                color: undefined,
                fontSize: "12px",
                fontFamily: "Helvetica, Arial, sans-serif",
                fontWeight: 600,
                cssClass: "apexcharts-yaxis-title",
              },
            },
          },
          yaxis: {
            min: 0,
            max: 50,
            title: {
              text: this.checkDccPerilUnit(),
              rotate: -90,
              offsetX: 0,
              offsetY: 0,
              style: {
                color: undefined,
                fontSize: "12px",
                fontFamily: "Helvetica, Arial, sans-serif",
                fontWeight: 600,
                cssClass: "apexcharts-yaxis-title",
              },
            },
          },
          stroke: {
            width: 1,
          },

          tooltip: {
            shared: false,
            x: {
              format: "dd MMM - HH : mm ",
            },
          },
        },
        seriescongenial1: chartTMvalues,
        optionscongenial2: {
          colors: ["#0b6b75"],
          chart: {
            id: `humiditycongenial`,
            group: "Disease_CONG",
            animations: {
              enabled: false,
            },
            stacked: false,
            zoom: {
              type: "x",
              enabled: true,
            },
            background: "#fff",
            toolbar: {
              show: true,
            },
          },

          annotations: {
            yaxis: [
              {
                y: this.getDCCRH("min"),
                y2: this.getDCCRH("max"),
                borderColor: "#000",
                fillColor: "#a2e5eb",
                label: {
                  text: "Humidity range",
                },
              },
            ],
          },
          dataLabels: {
            enabled: false,
          },
          markers: {
            size: 0,
          },
          title: {
            text: "Humidity",
            align: "left",
          },
          // fill: {
          //     type: 'gradient',
          //     gradient: {
          //         shadeIntensity: 1,
          //         opacityFrom: 0.7,
          //         opacityTo: 0.9,
          //         stops: [0, 100]
          //     }
          // },
          xaxis: {
            type: "datetime",
            labels: {
              datetimeFormatter: {
                year: "yyyy",
                month: "dd MMM",
                day: "dd MMM",
                hour: "HH:mm",
              },
            },
          },
          yaxis: {
            show: true,
            min: 0,
            title: {
              text: "Humidity - Avg (%)",
              rotate: -90,
              offsetX: 0,
              offsetY: 0,
              style: {
                color: undefined,
                fontSize: "12px",
                fontFamily: "Helvetica, Arial, sans-serif",
                fontWeight: 600,
                cssClass: "apexcharts-yaxis-title",
              },
            },
          },
          stroke: {
            width: 1,
          },

          tooltip: {
            shared: false,
            x: {
              format: "dd MMM - HH : mm ",
            },
          },
        },
        seriescongenial2: chartHMvalues,
        isDiseaseCong: true,
        isExcessrain: false,
        showchart: true,
        tmtable: true,
        showtable: true,
        showLoader: false,
        isGetdatadisabled: false,
      });
    }
    ApexCharts.exec("area-datetime", "removeAnnotation", "phase1");
    ApexCharts.exec("area-datetime", "removeAnnotation", "phase2");
    ApexCharts.exec("area-datetime", "removeAnnotation", "phase3");
    ApexCharts.exec("area-datetime", "removeAnnotation", "phase4");
    ApexCharts.exec("area-datetime", "removeAnnotation", "phase5");
  }
  getDCCRH(type) {
    // console.log("THIS CROP", this.state.crop);
    if (type == "min") {
      if (this.state.crop == "Mango") {
        return 80;
      }
      if (this.state.crop == "Paddy") {
        return 80;
      }
      if (this.state.crop == "Veg Group I") {
        return 80;
      }
      if (this.state.crop == "Paddy 2nd Crop") {
        return 70;
      }
      if (this.state.crop == "Paddy 3rd Crop") {
        return 80;
      }
      if (this.state.crop == "Paddy Winter") {
        return 80;
      }
      if (this.state.crop == "Veg Group I - SMR") {
        return 80;
      }
    }

    if (type == "max") {
      if (this.state.crop == "Mango") {
        return 100;
      }
      if (this.state.crop == "Paddy") {
        return 90;
      }
      if (this.state.crop == "Veg Group I") {
        return 100;
      }
      if (this.state.crop == "Paddy 2nd Crop") {
        return 80;
      }
      if (this.state.crop == "Paddy 3rd Crop") {
        return 90;
      }
      if (this.state.crop == "Paddy Winter") {
        return 90;
      }
      if (this.state.crop == "Veg Group I - SMR") {
        return 100;
      }
    }
  }
  getDCCTM(type) {
    if (type == "min") {
      if (this.state.crop == "Mango") {
        return 0;
      }
      if (this.state.crop == "Paddy") {
        return 32;
      }
      if (this.state.crop == "Veg Group I") {
        return 28;
      }
      if (this.state.crop == "Paddy 2nd Crop") {
        return 31;
      }
      if (this.state.crop == "Paddy 3rd Crop") {
        return 32;
      }
      if (this.state.crop == "Paddy Winter") {
        return 28;
      }
      if (this.state.crop == "Veg Group I - SMR") {
        return 28;
      }
    }

    if (type == "max") {
      if (this.state.crop == "Mango") {
        return 24;
      }
      if (this.state.crop == "Paddy") {
        return 50;
      }
      if (this.state.crop == "Veg Group I") {
        return 32;
      }
      if (this.state.crop == "Paddy 2nd Crop") {
        return 50;
      }
      if (this.state.crop == "Paddy 3rd Crop") {
        return 50;
      }
      if (this.state.crop == "Paddy Winter") {
        return 32;
      }
      if (this.state.crop == "Veg Group I - SMR") {
        return 32;
      }

    }
  }
  getValues(values, dp, phases) {
    this.setState({
      showPhaseselector: false,
    });
    var chartvalues = [];

    if (this.state.peril == "Excess Rainfall" && this.state.crop == "Paddy") {
      var data = {
        name: "Daliy Rainfall",
        data: [],
      };
      var data2 = {
        name: "Last 3-day cumulative",
        data: [],
      };
    } else if (
      this.state.peril == "Excess Rainfall" &&
      this.state.crop == "Paddy 2nd Crop"
    ) {
      var data = {
        name: "Daliy Rainfall",
        data: [],
      };
      var data2 = {
        name: "Last 3-day cumulative",
        data: [],
      };
    } else if (
      this.state.peril == "Excess Rainfall" &&
      this.state.crop == "Paddy 3rd Crop"
    ) {
      var data = {
        name: "Daliy Rainfall",
        data: [],
      };
      var data2 = {
        name: "Last 3-day cumulative",
        data: [],
      };
    } else if (
      this.state.peril == "Excess Rainfall" &&
      this.state.crop == "Paddy Winter"
    ) {
      var data = {
        name: "Daliy Rainfall",
        data: [],
      };
      var data2 = {
        name: "Last 3-day cumulative",
        data: [],
      };
    } else {
      var data = {
        name: dp,
        data: [],
      };
      var data2 = {
        name: "Last 3-day cumulative",
        data: [],
      };
    }

    var value_list = [];
    var value_list2 = [];
    for (var key in values) {
      // console.log(object[key]);
      //   if (values.length>0) continue;
      data.data.push({ x: values[key].unixms * 1000, y: values[key].value });
      data2.data.push({
        x: values[key].unixms * 1000,
        y: values[key].cumulative,
      });
      if (values[key].value != null) {
        value_list.push(Number(values[key].value));
      }
      if (values[key].cumulative != null) {
        value_list2.push(Number(values[key].cumulative));
      }
      // console.log("val", data2)
    }
    var lastdate = values.length;
    lastdate = lastdate - 1;
    lastdate = values[lastdate].datetime;
    this.setState({
      allstDate: values[0].datetime,
      allendDate: lastdate,
    });
    var season = this.state.season;
        var crop = this.state.crop;
        var peril = this.state.peril;
        // console.log("SEASON1", season, "CROP1", crop, "Peril1", peril);
        var strike =
          termsheet_json[season].crop[crop].peril[peril].Phase_1.strike;
    var max_value1 = Math.max(...value_list);
    var max_value2 = Math.max(...value_list2);
    if (max_value1 > max_value2) {
      
      if(max_value1 > strike){
        var max_value = Math.max(...value_list);
        max_value = max_value + 10;
        max_value = Math.ceil(max_value / 5) * 5;
        // console.log("MAX VALUE1",max_value)
      }
      else{
        max_value = Math.ceil(strike / 5) * 6;
        // console.log("MAX VALUE",max_value)
      }
    } else if (max_value1 < max_value2) {
      if(max_value2 > strike){
        var max_value = Math.max(...value_list2);
        max_value = max_value + 10;
        max_value = Math.ceil(max_value / 5) * 5;
        // console.log("MAX VALUE2",max_value)
      }else{
        max_value = Math.ceil(strike / 5) * 6;
        // console.log("MAX VALUE3",max_value)
      }
      
    }

    chartvalues = [data, data2];
    // console.log("VALUE LIST", value_list);
    // console.log("MAX VALUE", max_value);
    if (this.state.peril == "Excess Rainfall") {
      if (this.state.crop == "Paddy" && this.state.season == "Kharif 2020") {
        var list = [];
        phases.map((items) => {
          let key = Object.getOwnPropertyNames(items);
          key = key[0];
          // console.log("MAP FN PHASES START DATE", items[key][0].from_date);
          // console.log("MAP FN PHASES END DATE", items[key][0].end_date);
          list.push({
            x: new Date(items[key][0].from_date).getTime(),
            x2: new Date(items[key][0].end_date).getTime(),
            fillColor: "#08ffb5",
            label: {
              text: "Strike",
            },
          });
        });
        this.setState({
          showPhaseselector: false,
          isExcessrain: true,
          isDiseaseCong: false,
          options: {
            colors: ["#0b6b75"],
            chart: {
              id: "area-datetime",
              type: "area",
              height: 350,
              zoom: {
                autoScaleYaxis: true,
              },
              toolbar: {
                show: true,
                tools: {
                  download: false,
                },
              },
            },
            annotations: {
              xaxis: list,
            },
            xaxis: {
              type: "datetime",
              labels: {
                datetimeUTC: false,
                datetimeFormatter: {
                  year: "yyyy",
                  month: "dd MMM",
                  day: "dd MMM",
                  hour: "HH:mm",
                },
              },
            },
            yaxis: {
              show: true,
              // min: 0,
              max: max_value,
              title: {
                text: this.checkPerilunit(),
                rotate: -90,
                offsetX: 0,
                offsetY: 0,
                style: {
                  color: undefined,
                  fontSize: "12px",
                  fontFamily: "Helvetica, Arial, sans-serif",
                  fontWeight: 600,
                  cssClass: "apexcharts-yaxis-title",
                },
              },
            },

            stroke: {
              width: 1,
            },
            tooltip: {
              x: {
                format: "dd.MM.yyyy",
              },
            },
          },
        });
      } else if (
        this.state.crop == "Mango" &&
        this.state.peril == "Excess Rainfall"
      ) {
        this.setState({
          isExcessrain: false,
          isDiseaseCong: false,
          showPhaseselector: true,
          options: {
            colors: ["#0b6b75", "#ff6a00"],
            chart: {
              id: "area-datetime",
              type: "area",
              height: 350,
              zoom: {
                autoScaleYaxis: true,
              },
              toolbar: {
                show: true,
                tools: {
                  download: false,
                },
              },
            },
            xaxis: {
              type: "datetime",
              labels: {
                datetimeUTC: false,
                datetimeFormatter: {
                  year: "yyyy",
                  month: "dd MMM",
                  day: "dd MMM",
                  hour: "HH:mm",
                },
              },
              title: {
                text: "Date/Time",
                rotate: -90,
                offsetX: 0,
                offsetY: 0,
                style: {
                  color: undefined,
                  fontSize: "12px",
                  fontFamily: "Helvetica, Arial, sans-serif",
                  fontWeight: 600,
                  cssClass: "apexcharts-yaxis-title",
                },
              },
            },
            annotations: {
              xaxis: [],
            },
            yaxis: {
              show: true,
              min: 0,
              max: max_value,
              title: {
                text: this.checkPerilunit(),
                rotate: -90,
                offsetX: 0,
                offsetY: 0,
                style: {
                  color: undefined,
                  fontSize: "12px",
                  fontFamily: "Helvetica, Arial, sans-serif",
                  fontWeight: 600,
                  cssClass: "apexcharts-yaxis-title",
                },
              },
            },

            stroke: {
              width: 1,
            },
            tooltip: {
              x: {
                format: "dd.MM.yyyy",
              },
            },
          },
        });
      } else if (
        this.state.crop == "Veg Group I" &&
        this.state.peril == "Excess Rainfall"
      ) {
        this.setState({
          isExcessrain: false,
          isDiseaseCong: false,
          showPhaseselector: true,
          options: {
            colors: ["#0b6b75", "#ff6a00"],
            chart: {
              id: "area-datetime",
              type: "area",
              height: 350,
              zoom: {
                autoScaleYaxis: true,
              },
              toolbar: {
                show: true,
                tools: {
                  download: false,
                },
              },
            },
            xaxis: {
              type: "datetime",
              labels: {
                datetimeUTC: false,
                datetimeFormatter: {
                  year: "yyyy",
                  month: "dd MMM",
                  day: "dd MMM",
                  hour: "HH:mm",
                },
              },
              title: {
                text: "Date/Time",
                rotate: -90,
                offsetX: 0,
                offsetY: 0,
                style: {
                  color: undefined,
                  fontSize: "12px",
                  fontFamily: "Helvetica, Arial, sans-serif",
                  fontWeight: 600,
                  cssClass: "apexcharts-yaxis-title",
                },
              },
            },
            annotations: {
              xaxis: [],
            },
            yaxis: {
              show: true,
              min: 0,
              max: max_value,
              title: {
                text: this.checkPerilunit(),
                rotate: -90,
                offsetX: 0,
                offsetY: 0,
                style: {
                  color: undefined,
                  fontSize: "12px",
                  fontFamily: "Helvetica, Arial, sans-serif",
                  fontWeight: 600,
                  cssClass: "apexcharts-yaxis-title",
                },
              },
            },

            stroke: {
              width: 1,
            },
            tooltip: {
              x: {
                format: "dd.MM.yyyy",
              },
            },
          },
        });
      } else if (
        this.state.crop == "Veg Group I - SMR" &&
        this.state.peril == "Excess Rainfall"
      ) {
        this.setState({
          isExcessrain: false,
          isDiseaseCong: false,
          showPhaseselector: true,
          options: {
            colors: ["#0b6b75", "#ff6a00"],
            chart: {
              id: "area-datetime",
              type: "area",
              height: 350,
              zoom: {
                autoScaleYaxis: true,
              },
              toolbar: {
                show: true,
                tools: {
                  download: false,
                },
              },
            },
            xaxis: {
              type: "datetime",
              labels: {
                datetimeUTC: false,
                datetimeFormatter: {
                  year: "yyyy",
                  month: "dd MMM",
                  day: "dd MMM",
                  hour: "HH:mm",
                },
              },
              title: {
                text: "Date/Time",
                rotate: -90,
                offsetX: 0,
                offsetY: 0,
                style: {
                  color: undefined,
                  fontSize: "12px",
                  fontFamily: "Helvetica, Arial, sans-serif",
                  fontWeight: 600,
                  cssClass: "apexcharts-yaxis-title",
                },
              },
            },
            annotations: {
              xaxis: [],
            },
            yaxis: {
              show: true,
              // min: 0,
              max: max_value,
              title: {
                text: this.checkPerilunit(),
                rotate: -90,
                offsetX: 0,
                offsetY: 0,
                style: {
                  color: undefined,
                  fontSize: "12px",
                  fontFamily: "Helvetica, Arial, sans-serif",
                  fontWeight: 600,
                  cssClass: "apexcharts-yaxis-title",
                },
              },
            },

            stroke: {
              width: 1,
            },
            tooltip: {
              x: {
                format: "dd.MM.yyyy",
              },
            },
          },
        });
      } else {
        this.setState({
          isExcessrain: true,
          isDiseaseCong: false,
          showPhaseselector: true,
          options: {
            colors: ["#0b6b75", "#ff6a00"],
            chart: {
              id: "area-datetime",
              type: "area",
              height: 350,
              zoom: {
                autoScaleYaxis: true,
              },
              toolbar: {
                show: true,
                tools: {
                  download: false,
                },
              },
            },
            xaxis: {
              type: "datetime",
              labels: {
                datetimeUTC: false,
                datetimeFormatter: {
                  year: "yyyy",
                  month: "dd MMM",
                  day: "dd MMM",
                  hour: "HH:mm",
                },
              },
              title: {
                text: "Date/Time",
                rotate: -90,
                offsetX: 0,
                offsetY: 0,
                style: {
                  color: undefined,
                  fontSize: "12px",
                  fontFamily: "Helvetica, Arial, sans-serif",
                  fontWeight: 600,
                  cssClass: "apexcharts-yaxis-title",
                },
              },
            },
            annotations: {
              xaxis: [],
            },
            yaxis: {
              show: true,
              // min: 0,
              max: max_value,
              title: {
                text: this.checkPerilunit(),
                rotate: -90,
                offsetX: 0,
                offsetY: 0,
                style: {
                  color: undefined,
                  fontSize: "12px",
                  fontFamily: "Helvetica, Arial, sans-serif",
                  fontWeight: 600,
                  cssClass: "apexcharts-yaxis-title",
                },
              },
            },

            stroke: {
              width: 1,
            },
            tooltip: {
              x: {
                format: "dd.MM.yyyy",
              },
            },
          },
        });
      }
    } else if (this.state.crop == "Mango" && this.state.peril == "Wind Speed") {
      this.setState({
        isDiseaseCong: false,
        isExcessrain: false,
        showPhaseselector: true,
        options: {
          colors: ["#0b6b75"],
          chart: {
            id: "area-datetime",
            type: "area",
            height: 350,
            zoom: {
              autoScaleYaxis: true,
            },
            toolbar: {
              show: true,
              tools: {
                download: false,
              },
            },
          },
          xaxis: {
            type: "datetime",
            labels: {
              datetimeUTC: false,
              datetimeFormatter: {
                year: "yyyy",
                month: "dd MMM",
                day: "dd MMM",
                hour: "HH:mm",
              },
            },
            title: {
              text: "Date/Time",
              rotate: -90,
              offsetX: 0,
              offsetY: 0,
              style: {
                color: undefined,
                fontSize: "12px",
                fontFamily: "Helvetica, Arial, sans-serif",
                fontWeight: 600,
                cssClass: "apexcharts-yaxis-title",
              },
            },
          },
          annotations: {
            xaxis: [],
          },
          yaxis: {
            show: true,
            min: 0,
            max: max_value,
            title: {
              text: this.checkPerilunit(),
              rotate: -90,
              offsetX: 0,
              offsetY: 0,
              style: {
                color: undefined,
                fontSize: "12px",
                fontFamily: "Helvetica, Arial, sans-serif",
                fontWeight: 600,
                cssClass: "apexcharts-yaxis-title",
              },
            },
          },

          stroke: {
            width: 1,
          },
          tooltip: {
            x: {
              format: "dd.MM.yyyy",
            },
          },
        },
      });
    } else if (
      this.state.crop == "Paddy 2nd Crop" &&
      this.state.peril == "Deficit Rainfall"
    ) {
      this.setState({
        isDiseaseCong: false,
        isExcessrain: false,
        showPhaseselector: true,
        options: {
          colors: ["#0b6b75"],
          chart: {
            id: "area-datetime",
            type: "area",
            height: 350,
            zoom: {
              autoScaleYaxis: true,
            },
            toolbar: {
              show: true,
              tools: {
                download: false,
              },
            },
          },
          xaxis: {
            type: "datetime",
            labels: {
              datetimeUTC: false,
              datetimeFormatter: {
                year: "yyyy",
                month: "dd MMM",
                day: "dd MMM",
                hour: "HH:mm",
              },
            },
            title: {
              text: "Date/Time",
              rotate: -90,
              offsetX: 0,
              offsetY: 0,
              style: {
                color: undefined,
                fontSize: "12px",
                fontFamily: "Helvetica, Arial, sans-serif",
                fontWeight: 600,
                cssClass: "apexcharts-yaxis-title",
              },
            },
          },
          annotations: {
            xaxis: [],
          },
          yaxis: {
            show: true,
            min: 0,
            max: 250,
            title: {
              text: this.checkPerilunit(),
              rotate: -90,
              offsetX: 0,
              offsetY: 0,
              style: {
                color: undefined,
                fontSize: "12px",
                fontFamily: "Helvetica, Arial, sans-serif",
                fontWeight: 600,
                cssClass: "apexcharts-yaxis-title",
              },
            },
          },

          stroke: {
            width: 1,
          },
          tooltip: {
            x: {
              format: "dd.MM.yyyy",
            },
          },
        },
      });
    }
    else if (
      this.state.crop == "Paddy 2nd Crop" &&
      this.state.peril == "Wind Speed (A)" ||
      this.state.peril == "Wind Speed (B)"
    ) {
      this.setState({
        isDiseaseCong: false,
        isExcessrain: false,
        showPhaseselector: true,
        options: {
          colors: ["#0b6b75"],
          chart: {
            id: "area-datetime",
            type: "area",
            height: 350,
            zoom: {
              autoScaleYaxis: true,
            },
            toolbar: {
              show: true,
              tools: {
                download: false,
              },
            },
          },
          xaxis: {
            type: "datetime",
            labels: {
              datetimeUTC: false,
              datetimeFormatter: {
                year: "yyyy",
                month: "dd MMM",
                day: "dd MMM",
                hour: "HH:mm",
              },
            },
            title: {
              text: "Date/Time",
              rotate: -90,
              offsetX: 0,
              offsetY: 0,
              style: {
                color: undefined,
                fontSize: "12px",
                fontFamily: "Helvetica, Arial, sans-serif",
                fontWeight: 600,
                cssClass: "apexcharts-yaxis-title",
              },
            },
          },
          annotations: {
            xaxis: [],
          },
          yaxis: {
            show: true,
            min: 0,
            max: 50,
            title: {
              text: this.checkPerilunit(),
              rotate: -90,
              offsetX: 0,
              offsetY: 0,
              style: {
                color: undefined,
                fontSize: "12px",
                fontFamily: "Helvetica, Arial, sans-serif",
                fontWeight: 600,
                cssClass: "apexcharts-yaxis-title",
              },
            },
          },

          stroke: {
            width: 1,
          },
          tooltip: {
            x: {
              format: "dd.MM.yyyy",
            },
          },
        },
      });
    } else if (
      this.state.peril == "Excess Rainfall" &&
      this.state.crop == "Paddy"
    ) {
      this.setState({
        isDiseaseCong: false,
        isExcessrain: true,
        showPhaseselector: true,
        options: {
          colors: ["#0b6b75"],
          chart: {
            id: "area-datetime",
            type: "area",
            height: 350,
            zoom: {
              autoScaleYaxis: true,
            },
            toolbar: {
              show: true,
              tools: {
                download: false,
              },
            },
          },
          xaxis: {
            type: "datetime",
            labels: {
              datetimeUTC: false,
              datetimeFormatter: {
                year: "yyyy",
                month: "dd MMM",
                day: "dd MMM",
                hour: "HH:mm",
              },
            },
            title: {
              text: "Date/Time",
              rotate: -90,
              offsetX: 0,
              offsetY: 0,
              style: {
                color: undefined,
                fontSize: "12px",
                fontFamily: "Helvetica, Arial, sans-serif",
                fontWeight: 600,
                cssClass: "apexcharts-yaxis-title",
              },
            },
          },
          annotations: {
            xaxis: [],
          },
          yaxis: {
            show: true,
            min: 0,
            max: max_value,
            title: {
              text: this.checkPerilunit(),
              rotate: -90,
              offsetX: 0,
              offsetY: 0,
              style: {
                color: undefined,
                fontSize: "12px",
                fontFamily: "Helvetica, Arial, sans-serif",
                fontWeight: 600,
                cssClass: "apexcharts-yaxis-title",
              },
            },
          },

          stroke: {
            width: 1,
          },
          tooltip: {
            x: {
              format: "dd.MM.yyyy",
            },
          },
        },
      });
    } else {
      this.setState({
        isDiseaseCong: false,
        isExcessrain: false,
        showPhaseselector: true,
        options: {
          colors: ["#0b6b75"],
          chart: {
            id: "area-datetime",
            type: "area",
            height: 350,
            zoom: {
              autoScaleYaxis: true,
            },
            toolbar: {
              show: true,
              tools: {
                download: false,
              },
            },
          },
          xaxis: {
            type: "datetime",
            labels: {
              datetimeUTC: false,
              datetimeFormatter: {
                year: "yyyy",
                month: "dd MMM",
                day: "dd MMM",
                hour: "HH:mm",
              },
            },
            title: {
              text: "Date/Time",
              rotate: -90,
              offsetX: 0,
              offsetY: 0,
              style: {
                color: undefined,
                fontSize: "12px",
                fontFamily: "Helvetica, Arial, sans-serif",
                fontWeight: 600,
                cssClass: "apexcharts-yaxis-title",
              },
            },
          },
          annotations: {
            xaxis: [],
          },
          yaxis: {
            show: true,
            min: 0,
            max: max_value,
            title: {
              text: this.checkPerilunit(),
              rotate: -90,
              offsetX: 0,
              offsetY: 0,
              style: {
                color: undefined,
                fontSize: "12px",
                fontFamily: "Helvetica, Arial, sans-serif",
                fontWeight: 600,
                cssClass: "apexcharts-yaxis-title",
              },
            },
          },

          stroke: {
            width: 1,
          },
          tooltip: {
            x: {
              format: "dd.MM.yyyy",
            },
          },
        },
      });
    }
    this.setState({
      series: chartvalues,
      showchart: true,
      tmtable: true,
      showtable: true,
      showLoader: false,
      isGetdatadisabled: false,
    });

    ApexCharts.exec("area-datetime", "removeAnnotation", "phase1");
    ApexCharts.exec("area-datetime", "removeAnnotation", "phase2");
    ApexCharts.exec("area-datetime", "removeAnnotation", "phase3");
    ApexCharts.exec("area-datetime", "removeAnnotation", "phase4");
    ApexCharts.exec("area-datetime", "removeAnnotation", "phase5");
  }
  checkPerilunit() {
    if (this.state.peril === "High Temperature") {
      return "Temperature - Max (°C)";
    }
    if (this.state.peril === "Rise in Temperature") {
      return "Temperature - Max (°C)";
    }
    if (this.state.peril === "Deficit Rainfall") {
      return "Rainfall (mm)";
    }
    if (this.state.peril === "Dry Spell") {
      return "Rainfall (mm)";
    }
    if (this.state.peril === "Excess Rainfall") {
        return "Rainfall (mm)";
    }
    if (this.state.peril === "Unseasonal Rainfall") {
      return "Rainfall (mm)";
    }
    if (this.state.peril === "Wind Speed") {
      return "Wind Speed - Max (m/s)";
    }
    if (this.state.peril === "Wind Speed (A)") {
      return "Wind Speed - Max (m/s)";
    }
    if (this.state.peril === "Wind Speed (B)") {
      return "Wind Speed - Max (m/s)";
    }
  }
   checkDccPerilUnit(){
    if(this.state.crop === "Mango"){
      return "Temperature - Min (°C)"
    }
    if(this.state.crop === "Paddy 2nd Crop"){
      return "Temperature - Max (°C)"
    }
    if(this.state.crop === "Paddy 3rd Crop"){
      return "Temperature - Max (°C)"
    }
    if(this.state.crop === "Paddy Winter"){
      return "Temperature - Max (°C)"
    }
    if(this.state.crop === "Veg Group I"){
      return "Temperature - Max (°C)"
    }
    if(this.state.crop === "Veg Group I - SMR"){
      return "Temperature - Max (°C)"
    }
    if(this.state.crop === "Paddy"){
      return "Temperature - Avg (°C)"
    }
 }
  findMax(owmax, dsmax) {
    const largest = Math.max(owmax, dsmax);
    var per = (largest * 10) / 100;
    return largest + per;
  }

  findpercentage(val) {
    var per = (val * 10) / 100;
    return val + per;
  }

  render() {
    const { t, i18n } = this.props;
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Row>
              <Col xs={12}>
                <h4
                  className="card-title"
                  style={{ padding: "5px", fontSize: "18px" }}
                >
                  {" "}
                  {t("Termsheet Peril Wise")}
                </h4>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Card style={{ backgroundColor: "#f1f6fb" }}>
                  <CardBody>
                    <div>
                      <Row style={{ fontSize: "14px" }}>
                        <Col md={2}>
                          <FormGroup>
                            <Label className="col-sm col-form-label">
                              {t("Location")}
                            </Label>

                            <select
                              className="form-select"
                              style={{ fontSize: "11px" }}
                              value={this.state.selloc}
                              onChange={this.handleLocationchange}
                            >
                              <option
                                value="Chittur"
                                style={
                                  this.state.kb1 ? {} : { display: "none" }
                                }
                              >
                                Chittur-Thathamangalam
                              </option>
                              <option
                                value="Eruthenpathy"
                                style={
                                  this.state.kb2 ? {} : { display: "none" }
                                }
                              >
                                Eruthenpathy{" "}
                              </option>
                              <option
                                // disabled={this.state.kb3 ? true : false}
                                value="Kozhinjampara"
                                style={
                                  this.state.kb3 ? {} : { display: "none" }
                                }
                              >
                                Kozhinjampara
                              </option>
                              <option
                                // disabled={this.state.fkb4 ? true : false}
                                value="Nallepilly"
                                style={
                                  this.state.kb4 ? {} : { display: "none" }
                                }
                              >
                                Nallepilly
                              </option>
                              <option
                                // disabled={this.state.kb5 ? true : false}
                                value="Pattanchery"
                                style={
                                  this.state.kb5 ? {} : { display: "none" }
                                }
                              >
                                Pattanchery
                              </option>
                              <option
                                // disabled={this.state.kb6 ? true : false}
                                value="Perumatty"
                                style={
                                  this.state.kb6 ? {} : { display: "none" }
                                }
                              >
                                Perumatty
                              </option>
                              <option
                                // disabled={this.state.kb7 ? true : false}
                                value="Vadakarapathy"
                                // selected={this.state.kb7 ? true : false}
                                style={
                                  this.state.kb7 ? {} : { display: "none" }
                                }
                              >
                                Vadakarapathy
                              </option>
                            </select>
                          </FormGroup>
                        </Col>
                        <Col md={2}>
                          <FormGroup>
                            <Label className="col-sm col-form-label">
                              {t("Season")}
                            </Label>
                            <select
                              className="form-select"
                              style={{ fontSize: "11px" }}
                              value={this.state.season}
                              onChange={this.handleSeasonchange}
                            >
                              {this.state.seasonData.length > 0 &&
                                this.state.seasonData.map((item) => (
                                  <option value={item} key={item}>
                                    {item}
                                  </option>
                                ))}
                            </select>
                          </FormGroup>
                        </Col>
                        <Col md={2}>
                          <FormGroup>
                            <Label className="col-sm col-form-label">
                              {t("Crop")}
                            </Label>
                            <select
                              className="form-select"
                              style={{ fontSize: "11px" }}
                              value={this.state.crop}
                              onChange={this.handleCropchange}
                            >
                              {this.state.cropData.length > 0 &&
                                this.state.cropData.map((item) => (
                                  <option value={item} key={item}>
                                    {item}
                                  </option>
                                ))}
                            </select>
                          </FormGroup>
                        </Col>
                        <Col md={2}>
                          <FormGroup>
                            <Label className=" col-sm col-form-label">
                              {t("Perils")}
                            </Label>
                            <select
                              className="form-select"
                              style={{ fontSize: "11px" }}
                              onChange={this.handlePerilchange}
                              value={this.state.peril}
                            >
                              {this.state.perilData.length > 0 &&
                                this.state.perilData.map((item) => (
                                  <option value={item} key={item}>
                                    {item}
                                  </option>
                                ))}
                              <option value={"all"} key={"item"}>
                                All
                              </option>
                            </select>
                          </FormGroup>
                        </Col>
                        <Col md={2}>
                          <FormGroup>
                            <Label
                              htmlFor="example-number-input"
                              className="col-sm col-form-label"
                            >
                              {" "}
                              {t("Acreage (ha)")}
                            </Label>
                            <Input
                              className="form-control"
                              type="number"
                              defaultValue="1"
                              min="1"
                              id="example-number-input"
                              style={{ fontSize: "11px" }}
                              onChange={this.handleAcreChange}
                            />
                          </FormGroup>
                        </Col>
                        <Col md={2}>
                          <FormGroup>
                            <Label className=" col-sm col-form-label">
                              {t("Data Provider")}
                            </Label>
                            <select
                              className="form-select"
                              style={{ fontSize: "11px" }}
                              onChange={this.handledpchange}
                              value={this.state.tabledp}
                            >
                              {this.state.dpData.length > 0 &&
                                this.state.dpData.map((item) => (
                                  <option value={item} key={item}>
                                    {item}
                                  </option>
                                ))}
                            </select>
                          </FormGroup>
                        </Col>
                      </Row>
                      <FormGroup
                        className="mb-0"
                        style={{
                          paddingLeft: "0px",
                          paddingTop: "20px",
                          float: "right",
                        }}
                      >
                        <div
                          title={
                            this.state.isGetdatadisabled
                              ? "Select Chart to Continue"
                              : ""
                          }
                        >
                          <Button
                            type="submit"
                            color="primary"
                            className="mr-1"
                            style={{ fontSize: "11px" }}
                            disabled={this.state.isGetdatadisabled}
                            onClick={this.handleSubmit}
                          >
                            {t("Get Data")}
                          </Button>
                        </div>
                      </FormGroup>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col xs={12}>
                <Card
                // style={{ minHeight: "470px" }}
                >
                  <CardBody>
                    <Row>
                      <Col sm="12">
                        <CardText>
                          <center>
                            <div
                              style={
                                this.state.showWaiting
                                  ? {}
                                  : { display: "none" }
                              }
                            >
                              Waiting for search
                            </div>
                            <img
                              src={loader}
                              height={"200px"}
                              style={
                                this.state.showLoader ? {} : { display: "none" }
                              }
                            ></img>
                          </center>
                          <div
                            style={
                              this.state.showchart ? {} : { display: "none" }
                            }
                          >
                            <h6
                              style={{
                                paddingTop: "10px",
                                paddingBottom: "10px",
                              }}
                            >
                              {this.state.selloc} |{" "}
                              {this.capitalizeFirstLetter(this.state.ddseason)}{" "}
                              | {this.state.ddperil}
                            </h6>
                            <div className="row" style={{ marginLeft: "10px" }}>
                              <Col
                                style={
                                  this.state.showPhaseselector
                                    ? {}
                                    : { display: "none" }
                                }
                              >
                                <Label checked>
                                  <Input
                                    type="radio"
                                    name="radio2"
                                    id={"all"}
                                    // defaultValue={this.state.selectedValue==="" ? {} : "p" + (index + 1)}
                                    value={"all"}
                                    onClick={this.phaseSelector}
                                    defaultChecked
                                  />
                                  <p
                                    style={{
                                      display: "inline-block",
                                      fontSize: "13px",
                                    }}
                                  >
                                    All
                                  </p>
                                </Label>
                              </Col>
                              {this.state.phaseselection.map(
                                (item, index, data) => {
                                  return (
                                    <Col
                                      style={
                                        this.state.showPhaseselector
                                          ? {}
                                          : { display: "none" }
                                      }
                                    >
                                      <Label check>
                                        {console.log("ITEMSS", item)}
                                        <Input
                                          type="radio"
                                          name="radio2"
                                          id={"p" + (index + 1)}
                                          // defaultValue={this.state.selectedValue==="" ? {} : "p" + (index + 1)}
                                          value={"p" + (index + 1)}
                                          onClick={this.phaseSelector}
                                        />
                                        <p
                                          style={{
                                            display: "inline-block",
                                            fontSize: "13px",
                                          }}
                                        >
                                          Phase {index + 1}
                                        </p>
                                      </Label>
                                    </Col>
                                  );
                                }
                              )}

                              <div
                                style={
                                  this.state.isDiseaseCong
                                    ? { display: "none" }
                                    : {}
                                }
                              >
                                <Chart
                                  options={this.state.options}
                                  series={this.state.series}
                                  type="line"
                                  height="300"
                                  width="100%"
                                />
                              </div>
                              <div
                                style={
                                  this.state.isDiseaseCong
                                    ? {}
                                    : { display: "none" }
                                }
                              >
                                <Chart
                                  options={this.state.optionscongenial1}
                                  series={this.state.seriescongenial1}
                                  type="line"
                                  height="300"
                                  width="100%"
                                />
                                <div style={{ "text-align": "center" }}>
                                  <span
                                    class="dot"
                                    style={{ "background-color": "#AB2200" }}
                                  ></span>
                                  <p
                                    id="Data_Provider"
                                    style={{
                                      cursor: "pointer",
                                      display: "inline-block",
                                      "font-size": "12px",
                                      "font-family": "Segoe UI",
                                      paddingRight: "10px",
                                      paddingLeft: "4px",
                                    }}
                                  >
                                    {this.state.tabledp}
                                  </p>
                                </div>
                                <Chart
                                  options={this.state.optionscongenial2}
                                  series={this.state.seriescongenial2}
                                  type="line"
                                  height="300"
                                  width="100%"
                                />
                              </div>
                              <div style={{ "text-align": "center" }}>
                                <span
                                  style={
                                    this.state.isExcessrain
                                      ? { display: "none" }
                                      : {}
                                  }
                                >
                                  <span
                                    class="dot"
                                    style={{ "background-color": "#0b6b75" }}
                                  ></span>
                                  <p
                                    id="Data_Provider"
                                    style={{
                                      cursor: "pointer",
                                      display: "inline-block",
                                      "font-size": "12px",
                                      "font-family": "Segoe UI",
                                      paddingRight: "10px",
                                      paddingLeft: "4px",
                                    }}
                                  >
                                    {this.state.tabledp}
                                  </p>
                                </span>
                                <span
                                  style={
                                    this.state.isExcessrain
                                      ? {}
                                      : { display: "none" }
                                  }
                                >
                                  <span
                                    class="dot"
                                    style={{ "background-color": "#0b6b75" }}
                                  ></span>
                                  <p
                                    id="Data_Provider"
                                    style={{
                                      cursor: "pointer",
                                      display: "inline-block",
                                      "font-size": "12px",
                                      "font-family": "Segoe UI",
                                      paddingRight: "10px",
                                      paddingLeft: "4px",
                                    }}
                                  >
                                    Daily Rainfall
                                  </p>

                                  <span
                                    class="dot"
                                    style={{ "background-color": "#ff6a00" }}
                                  ></span>
                                  <p
                                    id="Data_Provider"
                                    style={{
                                      cursor: "pointer",
                                      display: "inline-block",
                                      "font-size": "12px",
                                      "font-family": "Segoe UI",
                                      paddingRight: "10px",
                                      paddingLeft: "4px",
                                    }}
                                  >
                                    Last 3-day cumulative
                                  </p>
                                </span>
                              </div>
                              {/* <div
                                style={
                                  this.state.isExcessrain
                                    ? {}
                                    : { display: "none" }
                                }
                              >
                              <div style={{ "text-align": "center" }}>
                                <span
                                  class="dot"
                                  style={{ "background-color": "#ff6a00" }}
                                ></span>
                                <p
                                  id="Data_Provider"
                                  style={{
                                    cursor: "pointer",
                                    display: "inline-block",
                                    "font-size": "12px",
                                    "font-family": "Segoe UI",
                                    paddingRight: "10px",
                                    paddingLeft: "4px",
                                  }}
                                >
                                 last 3 day cumulative
                                </p>
                              </div>
                              </div> */}
                            </div>
                          </div>
                        </CardText>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <Card style={this.state.tmtable ? {} : { display: "none" }}>
                  <CardBody>
                    <Termsheettable
                      onRef={(ref) => (this.child = ref)}
                      tableprops={this.state}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default withNamespaces()(termSheet);
