import React, { Component } from "react";
// Table from react-bootstrap
import {
  Table,
  Card,
  CardBody,
  Row,
  Col,
  Container,
  FormGroup,
  Label,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
// Bootstrap CSS
import { Link } from "react-router-dom";
import Permission from "../../permission.js";
import { MDBDataTable } from "mdbreact";
import { toast } from "react-toastify";
import { withNamespaces } from "react-i18next";
import FMTermsheetPDF from "./fmTermsheetPDF";
import moment from "moment";
toast.configure();
export class Termsheet_Table extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      modal_xlarge: false,
      tableprops: props.tableprops,
      tablerua: "",
      tableheader: [],
      rowtabledata: [],
      conditiondata: [],
    };
    this.tog_xlarge = this.tog_xlarge.bind(this);
  }
  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  componentWillReceiveProps(nextProps) {
    this.setState({ tableprops: nextProps.tableprops });
  }
  removeBodyCss() {
    document.body.classList.add("no_padding");
  }
  tog_xlarge() {
    this.setState((prevState) => ({
      modal_xlarge: !prevState.modal_xlarge,
    }));
    this.removeBodyCss();
  }
  show() {
    this.setState({ visible: true });
  }
  hide() {
    this.setState({ visible: false });
  }
  async fetchtermsheetdata() {
    let searchdata = {
      farmerId: this.state.tableprops.farmer_id,
      season: this.state.tableprops.tableseason,
      crop: this.state.tableprops.tablecrop,
      data_provider: this.state.tableprops.tabledp,
      peril: this.state.tableprops.tableperil,
      rua: this.state.tablerua,
    };

    // {
    //   rua: this.state.tablerua,
    //   season: this.state.tableprops.tableseason,
    //   crop: this.state.tableprops.tablecrop,
    //   peril: this.state.tableprops.tableperil,
    //   data_provider: this.state.tableprops.tabledp,
    //   farmer_id: this.state.tableprops.farmer_id,
    // };
    searchdata = JSON.stringify(searchdata);

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token"),
      },
      body: searchdata,
    };

    // console.log("SEARCH DATA",searchdata)
    // console.log("TOKEN", localStorage.getItem('token'))
    var tableData = await fetch(
      process.env.REACT_APP_APIEND + `farmer/payout`,
      requestOptions
    ).then((res) => {
      return res.json();
    });
    if (tableData.code == 200) {
      this.generatetermsheettabledata(tableData.data.results);
    } else {
      toast.info("Empy Data");
    }
  }

  generatetermsheettabledata(data) {
    var getthis = this;
    let tabledt = [];
    let CalculatedV = 0.0;
    let UnitC = 0.0;
    let EligibleP = 0.0;
    let TeligibleP = 0.0;
    data.map(function (item, index, data) {
      // var keyvar = Object.keys(item);
      // keyvar = keyvar[0];
      CalculatedV = CalculatedV + item.calculated_value;
      UnitC = UnitC + item.unitCost;
      EligibleP = EligibleP + item.eligiblePayout;
      TeligibleP = TeligibleP + item.totalEligiblePayout;

      var newdata = {
        peril: item.peril,
        phase: item.phase,
        start_date: moment(item.startDate).format("DD-MM-YYYY"),
        end_date: moment(item.endDate).format("DD-MM-YYYY"),
        calculated_value: (
          <p className="alnrighttd">{parseFloat(item.calculatedValue).toFixed(2)}</p>
        ),
        unit_cost: (
          <p className="alnrighttd">
            {getthis.checknull(parseFloat(item.unitCost).toFixed(2))}
          </p>
        ),
        eligible_payout_pha: (
          <p className="alnrighttd">
            {parseFloat(item.eligiblePayout).toFixed(2)}
          </p>
        ),
        Eligible_payout_tot: (
          <p className="alnrighttd">
            {parseFloat(item.totalEligiblePayout).toFixed(2)}
          </p>
        ),
      };
      tabledt.push(newdata);
    });
    tabledt.push({
      peril: "",
      phase: "Total",
      start_date: "",
      end_date: "",
      calculated_value: "",
      unit_cost: "",
      eligible_payout_pha: <p className="alnrighttd">{EligibleP.toFixed(2)}</p>,
      Eligible_payout_tot: (
        <p className="alnrighttd">{TeligibleP.toFixed(2)}</p>
      ),
    });
    this.setState({
      rowtabledata: tabledt,
    });
  }
  async fetchconditiondata() {
    let searchdata = {
      crop: this.state.tableprops.tablecrop,
      peril: this.state.tableprops.tableperil,
      season: this.state.tableprops.tableseason,
    };
    searchdata = JSON.stringify(searchdata);

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token"),
      },
      body: searchdata,
    };
    try {
      var conditiondata = await fetch(
        process.env.REACT_APP_APIEND + `farmer/termSheet-condition-farmer/term-condition-farmer`,
        requestOptions
      ).then((res) => {
        return res.json();
      });
      var phase = conditiondata.result[0];
      var peril_name = Object.keys(conditiondata.result[0]);
      var phasedata = phase[peril_name].phase_data;
      var perilname = phase[peril_name].common;
      this.generateconditiontable(phasedata);
    } catch (err) {
      toast.error("Something's not right");
    }
  }
  checknull(value) {
    if (value != null && value > 0) {
      return parseFloat(value).toFixed(2);
    } else {
      return "0.00";
    }
  }
  formatPhase(phase) {
    if (phase == "phase_1") {
      return "Phase 1";
    }
    if (phase == "phase_2") {
      return "Phase 2";
    }
    if (phase == "phase_3") {
      return "Phase 3";
    }
    if (phase == "phase_4") {
      return "Phase 4";
    }
    if (phase == "phase_5") {
      return "Phase 5";
    }
  }
  generateconditiontable(data) {
    let tabledata = [];
    var getthis = this;
    data.map(function (item, index, data) {
      var currentphase = Object.keys(item);
      var newdata = {
        peril: "",
        phase: getthis.formatPhase(currentphase),
        from_date: item[currentphase].from_date,
        to_date: item[currentphase].end_date,
        trigger: getthis.checknull(item[currentphase].trigger),
        strike: getthis.checknull(item[currentphase].strike),
        exit: getthis.checknull(item[currentphase].exit),
        notional_payout: getthis.checknull(item[currentphase].notional_payout),
        fixed_payout: getthis.checknull(item[currentphase].fixed_payout),
        maximum_payout: getthis.checknull(item[currentphase].maximum_payout),
      };
      tabledata.push(newdata);
    });

    this.setState({
      conditiondata: tabledata,
    });
  }

  componentWillUnmount() {
    this.props.onRef(undefined);
  }
  componentDidMount() {
    this.props.onRef(this);
    this.fetchconditiondata();
    const user_role = Permission();
    this.setState({
      tablerua: user_role[1],
      // farmer_id: "123456789",
      farmer_id: localStorage.getItem("farmer_id"),
    });
  }
  render() {
    const { t, i18n } = this.props;
    let termsheet_table = {
      columns: [
        {
          label: "Peril",
          field: "peril",
        },
        {
          label: "Phase",
          field: "phase",
        },
        {
          label: "Start Date",
          field: "start_date",
          sort: "disabled",
        },

        {
          label: "End Date",
          field: "end_date",
          sort: "disabled",
        },
        {
          label: "Deviation from trigger/strike",
          field: "calculated_value",
        },
        {
          label: "Unit Cost (Rs.)",
          field: "unit_cost",
        },
        {
          label: "Eligible payout / ha (Rs.)",
          field: "eligible_payout_pha",
        },
        {
          label: "Total Eligible payout (Rs.)",
          field: "Eligible_payout_tot",
        },
      ],
      rows: this.state.rowtabledata,
    };
    let conditiondata = {
      columns: [
        {
          label: "Phase",
          field: "phase",
          sort: "disabled",
        },
        {
          label: "From Date",
          field: "from_date",
          sort: "disabled",
        },

        {
          label: "To Date",
          field: "to_date",
          sort: "disabled",
        },
        {
          label: "Trigger",
          field: "trigger",
          sort: "disabled",
        },
        {
          label: "Strike",
          field: "strike",
          sort: "disabled",
        },
        {
          label: "Exit",
          field: "exit",
        },
        {
          label: "Notional/Variable Payout (Rs.)",
          field: "notional_payout",
        },
        {
          label: "Fixed Payout (Rs.)",
          field: "fixed_payout",
        },
        {
          label: "Maximum Payout (Rs.)",
          field: "maximum_payout",
        },
      ],
      rows: this.state.conditiondata,
    };
    return (
      <>
        <React.Fragment>
          <Container>
            <Row>
              <Col xs={12}>
                <h4 className="card-title">{t("Termsheet Implications")}</h4>{" "}
              </Col>
              <Col xs={12} className="mb-1" style={{ fontSize: "11px" }}>
                <Row className="mb-1">
                  <Col xs={12} md={8}>
                    <b>
                      {t("Season")} : {this.state.tableprops.tableseason}
                    </b>
                  </Col>
                  <Col xs={6} md={4}>
                    <b>
                      {t("Peril")} :{" "}
                      {this.state.tableprops.tableperil == "all"
                        ? "All"
                        : this.state.tableprops.tableperil}
                    </b>
                  </Col>
                </Row>
                <Row className="mb-1">
                  <Col xs={12} md={8}>
                    <b>
                      {t("Crop")} : { this.state.tableprops.tablecrop}
                    </b>
                  </Col>
                  {/* <Col xs={6} md={4}>
                    <b>
                      {t("Area")} : {this.state.tableheader.area} (ha)
                    </b>
                  </Col> */}
                </Row>
                <Row className="mb-1">
                  <Col xs={12} md={8}>
                    <b>
                      {t("Location")} : {this.state.tablerua}
                    </b>
                  </Col>
                  <Col xs={6} md={4}>
                    <b>
                      {t("Data Provider")} :{" "}
                      {this.state.tableprops.tabledp}
                    </b>
                  </Col>
                </Row>
              </Col>

              <Col xs={6} md={8}></Col>
              <Col xs={6} md={4}>
                <Link onClick={this.tog_xlarge} style={{ fontSize: "11px" }}>
                  <u>{t("Termsheet Conditions")}</u>
                </Link>
              </Col>

              {/* <Col>
                <div
                  className="table-responsive"
                  // id="tsperil"
                  style={{ fontSize: "12px" }}
                >
                  <MDBDataTable
                    paging={false}
                    responsive
                    showPaginationBottom={false}
                    bordered
                    sortable={false}
                    searching={false}
                    data={termsheet_table}
                    sortable={false}
                    className="datadownloadtable"
                  />
                </div>
              </Col> */}
            </Row>
          </Container>
          <Row>
            <Modal
              size="xl"
              isOpen={this.state.modal_xlarge}
              toggle={this.tog_xlarge}
              backdrop="static"
            >
              <ModalHeader
                toggle={() => this.setState({ modal_xlarge: false })}
                style={{ backgroundColor: "#A4C1D2" }}
              >
                {t("Termsheet Conditions")}
              </ModalHeader>
              <ModalBody>
                <Container>
                  <FMTermsheetPDF data={this.state.tableprops} />
                </Container>
              </ModalBody>
            </Modal>
          </Row>
        </React.Fragment>
      </>
    );
  }
}

export default withNamespaces()(Termsheet_Table);
