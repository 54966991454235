import "../../assets/scss/bootstrap.scss";
import React, { useState, useEffect  } from "react";
import { Row, Col, Table, FormGroup, Label } from "reactstrap";
import Permission from "../../permission.js";
import './table.css'
import { Component } from 'react'
import { withNamespaces } from 'react-i18next';
import Moment from 'moment';



class SiderHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {          
          kb1:true,
          kb2:true,
          kb3:true,
          kb4:true,
          kb5:true,
          kb6:true,
          kb7:true,
    
        };
        this.selectcall = this.selectcall.bind(this);
      }     
      
selectcall (e) {
    
    // console.log("SELECTED",e.target.value)
    this.props.apicallfn(e.target.value)
    
}

      componentDidMount() {
        const krishibhavan= Permission();
        if(krishibhavan[0]=='DR' || krishibhavan[0]=='ADM' || krishibhavan[0]=='FM') {
            this.props.apicallfn("kdisc_sci_aws_03")
            this.setState({
                kb1:true,
          kb2:true,
          kb3:true,
          kb4:true,
          kb5:true,
          kb6:true,
          kb7:true,
            })                                 
        }

       if(krishibhavan[1]=='Nallepilly' && krishibhavan[0]=='KB'|| krishibhavan[0]=='FM'){
        this.setState({
            kb1:false,
      kb2:false,
      kb3:false,
      kb4:true,
      kb5:false,
      kb6:false,
      kb7:false,
        })                        
       }else if(krishibhavan[1]=='Chittur-Thathamangalam' && krishibhavan[0]=='KB'){
        this.setState({
            kb1:true,
      kb2:false,
      kb3:false,
      kb4:false,
      kb5:false,
      kb6:false,
      kb7:false,
        })      
                           
    }else if(krishibhavan[1]=='Kozhinjampara' && krishibhavan[0]=='KB'){
        this.setState({
            kb1:false,
      kb2:false,
      kb3:true,
      kb4:false,
      kb5:false,
      kb6:false,
      kb7:false,
        })     
              
                   
    }else if(krishibhavan[1]=='Eruthenpathy' && krishibhavan[0]=='KB'){
        this.setState({
            kb1:false,
      kb2:true,
      kb3:false,
      kb4:false,
      kb5:false,
      kb6:false,
      kb7:false,
        })     
              
    }else if(krishibhavan[1]=='Pattanchery' && krishibhavan[0]=='KB'){
        this.setState({
            kb1:false,
      kb2:false,
      kb3:false,
      kb4:false,
      kb5:true,
      kb6:false,
      kb7:false,
        })     
                          
    }else if(krishibhavan[1]=='Perumatty' && krishibhavan[0]=='KB'){
        this.setState({
            kb1:false,
      kb2:false,
      kb3:false,
      kb4:false,
      kb5:false,
      kb6:true,
      kb7:false,
        })     
                       
    }else if(krishibhavan[1]=='Vadakarapathy' && krishibhavan[0]=='KB'){
        this.setState({
            kb1:false,
      kb2:false,
      kb3:false,
      kb4:false,
      kb5:false,
      kb6:false,
      kb7:true,
        })     
               
                      
    }
    
      }

    render() {        
        const  {t,i18n} =this.props
        return (
            <React.Fragment>
            <FormGroup row>
                <Label className="col-md-3 col-form-label" style={{ fontSize: '14px' }}>{t("Location")}  </Label>
                <Col md={9}>
                    <select className="form-select" onChange={this.selectcall} style={{ fontSize: '11px' }} value={this.props.coordtime.dropselect}>
                        <option value="kdisc_sci_aws_01" style={this.state.kb1? {} : {display: 'none'}} >Chittur-Thathamangalam (Muncipal Corporation Office)
                    </option>
                        <option value="kdisc_sci_aws_10" style={this.state.kb2? {} : {display: 'none'}}>Eruthenpathy (Seed Godown, Kerala State Seed Development Authority (KSSDA))</option>
                        <option value="kdisc_sci_aws_04" style={this.state.kb2? {} : {display: 'none'}}>Eruthenpathy (Bhagavathy Govt. High School)</option>
                        <option value="kdisc_sci_aws_08" style={this.state.kb3? {} : {display: 'none'}}>Kozhinjampara (Bharathamatha Arts and Science
                        College)</option>
                        <option value="kdisc_sci_aws_12" style={this.state.kb3? {} : {display: 'none'}}>Kozhinjampara (KWA Water Treatment Plant)</option>
                        <option value="kdisc_sci_aws_03" selected style={this.state.kb4? {} : {display: 'none'}}>Nallepilly (Krishi Bhavan)</option>                        
                        <option value="kdisc_sci_aws_07" style={this.state.kb4? {} : {display: 'none'}}>Nallepilly (Government Technical High School)
                    </option>
                        <option value="kdisc_sci_aws_06" style={this.state.kb5? {} : {display: 'none'}}>Pattanchery (Panchayat Cremation Ground)</option>
                        <option value="kdisc_sci_aws_02" style={this.state.kb5? {} : {display: 'none'}}>Pattanchery (GSM High School,Thathamangalam)
                    </option>
                        <option value="kdisc_sci_aws_05" style={this.state.kb6? {} : {display: 'none'}}>Perumatty (Moolathara Regulator)</option>
                        <option value="kdisc_sci_aws_14" style={this.state.kb6? {} : {display: 'none'}}>Perumatty (Panchayat Office)</option>
                        <option value="kdisc_sci_aws_11" style={this.state.kb6? {} : {display: 'none'}}>Perumatty (Higher Secondary School)</option>
                        <option value="kdisc_sci_aws_13" style={this.state.kb7? {} : {display: 'none'}}>Vadakarapathy (Kinarpallam Milk Producers Co-operative Society)</option>
                        <option value="kdisc_sci_aws_09" style={this.state.kb7? {} : {display: 'none'}}>Vadakarapathy (Ahalia Campus)</option>
                    </select>
                </Col>

            </FormGroup>
            {/* <Row style={{ fontSize: '12px', fontWeight: 'lighter' }}>
                <Col lg='6'>
                    <Label >Coordinates [Lat,Lon] </Label>
                </Col>
                <Col lg='6' style={{ textAlign: 'right' }}>
                    <Label>Sample time </Label>
                </Col>
                
            </Row>
            <Row style={{ fontSize: '12px', fontWeight: 'lighter' }}>
                <Col lg='6'>
                    <Label >{this.props.coordtime.coordinates[0]}, {this.props.coordtime.coordinates[1]} </Label>
                </Col>
                <Col lg='6' style={{ textAlign: 'right' }}>
                    <Label>{this.props.coordtime.misres.datetime}</Label>
                </Col>
                
            </Row> */}
            <div class="row" style={{ fontSize: '12px', fontWeight: 'lighter' }}>
                <div class="col"> <Label >  {t("Coordinates")} [Lat,Long] </Label></div>
                <div class="col" style={{ textAlign: 'right' }}><Label>{t("Last updated")} </Label></div>
                <div class="w-100"></div>
                <div class="col"><Label >{this.props.coordtime.coordinates[0]}, {this.props.coordtime.coordinates[1]} </Label></div>
                <div class="col" style={{ textAlign: 'right' }}> <Label>{Moment(this.props.coordtime.misres.datetime).format('DD-MM-YYYY HH:mm')}</Label></div>
            </div>
            
        </React.Fragment >
        )
    }
}
export default withNamespaces()(SiderHeader);

