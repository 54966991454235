import React, { Component } from "react";
import { Link } from "react-router-dom";
import { CSVLink } from "react-csv";
import { MDBDataTable } from "mdbreact";
import { Spinner } from "reactstrap";
import "../Datatable-css/datatables.scss";
import { DatePicker } from "antd";
import moment from "moment";
import validate_Token from "../../validateToken.js";
import Permission from "../../permission.js";
import { withNamespaces } from "react-i18next";
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  Label,
  Container,
  CardHeader,
} from "reactstrap";
import { isTemplateMiddle } from "typescript";
const { RangePicker } = DatePicker;
const dateFormat = "YYYY-MM-DD";
class WeatherData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      fromDate: "",
      toDate: "",
      location: "kdisc_sci_aws_01",
      showloader: false,
      csvdownloadData: [],
      showwaitintext: true,
      showtable: false,
      showbutton: false,
      selrua: "Nallepilly",
      setKB1: true,
      setKB2: true,
      setKB3: true,
      setKB4: true,
      setKB5: true,
      setKB6: true,
      setKB7: true,
    };
    this.getData = this.getData.bind(this);
    this.onChangedate = this.onChangedate.bind(this);
    this.onChange = this.onChange.bind(this);
  }
  onChangedate(dateString) {
    const date1 = new Date(dateString[0]);
    const date2 = new Date(dateString[1]);
    var from_dd = String(date1.getDate()).padStart(2, "0");
    var from_mm = String(date1.getMonth() + 1).padStart(2, "0"); //January is 0!
    var from_yyyy = date1.getFullYear();
    var from_date = from_yyyy + "-" + from_mm + "-" + from_dd;

    var to_dd = String(date2.getDate()).padStart(2, "0");
    var to_mm = String(date2.getMonth() + 1).padStart(2, "0"); //January is 0!
    var to_yyyy = date2.getFullYear();
    var to_date = to_yyyy + "-" + to_mm + "-" + to_dd;

    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    if (diffDays <= 30) {
      this.setState({
        fromDate: from_date,
        toDate: to_date,
        showbutton: true,
      });
    } else {
      this.setState({
        showbutton: false,
      });
    }
  }

  onChange(ev) {
    if (ev.target.name == "location") {
      this.setState({
        selrua: ev.target.value,
      });
    }
    this.setState({
      [ev.target.name]: ev.target.value,
    });
  }

  async getData() {
    this.setState({
      showloader: true,
      showtable: false,
      showwaitintext: false,
    });
    let searchData = {
      from_date: this.state.fromDate,
      to_date: this.state.toDate,
      station: this.state.location,
    };
    searchData = JSON.stringify(searchData);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + localStorage.getItem("token"),
      },
      body: searchData,
    };
    var weatherResponse = await fetch(
      process.env.REACT_APP_APIEND + `weather/weather-date-range`,
      requestOptions
    ).then((res) => {
      return res.json();
    });
    this.makecsvdata(weatherResponse.result);
    // console.log("REPORT", weatherResponse.result);
    this.generatetabledata(weatherResponse.result);
  }
  generatetabledata(data) {
    let getthis = this;
    let tableData = [];
    // console.log("FORMAT DATA", data);
    data.map(function (item, index, data) {
      var newdata = {
        station: item.station,
        rua: item.rua,
        location: item.location,
        time: moment(item.time).format("DD-MM-YYYY HH:mm:ss"),
        temperature: parseFloat(item.temperature),
        humidity: parseFloat(item.humidity),
        pressure: parseFloat(item.pressure),
        rainfall: parseFloat(item.rainfall),
        uv_index: parseFloat(item.uv_index),
        wind_direction: parseFloat(item.wind_direction),
        wind_speed: parseFloat(item.wind_speed),
      };
      tableData.push(newdata);
    });

    this.setState({
      data: tableData,
      showtable: true,
      showloader: false,
    });
  }

  makecsvdata(data) {
    let csvData = [
      [
        "Station",
        "RUA",
        "Location",
        "Time",
        "Temperature",
        "Humidity",
        "Pressure",
        "Rainfall",
        "UVI",
        "Wind Direction",
        "Wind Speed",
      ],
    ];
    data.map((wdata) => {
      csvData.push([
        wdata.station,
        wdata.rua,
        wdata.location,
        wdata.time,
        wdata.temperature,
        wdata.humidity,
        wdata.pressure,
        wdata.rainfall,
        wdata.uv_index,
        wdata.wind_direction,
        wdata.wind_speed,
      ]);
    });
    this.setState({
      csvdownloadData: csvData,
    });
  }
  disabledDate(current) {
    // Can not select days before today and today
    return current && current > moment().endOf("day");
  }

  componentDidMount() {
    validate_Token();
    const user_role = Permission();
    if (user_role[0] == "KB") {
      if (user_role[1] == "Pattanchery") {
        this.setState({
          selrua: "kdisc_sci_aws_02",
          //Chittur-Thathamangalam Muncipality</option>
          setKB1: false,
          //Eruthenpathy
          setKB2: false,
          //Kozhinjampara
          setKB3: false,
          //Nallepilly
          setKB4: false,
          //Pattanchery
          setKB5: true,
          //Perumatty
          setKB6: false,
          //Vadakarapathy
          setKB7: false,
        });
      }
      if (user_role[1] == "Nallepilly") {
        this.setState({
          selrua: "kdisc_sci_aws_03",
          //Chittur-Thathamangalam Muncipality</option>
          setKB1: false,
          //Eruthenpathy
          setKB2: false,
          //Kozhinjampara
          setKB3: false,
          //Nallepilly
          setKB4: true,
          //Pattanchery
          setKB5: false,
          //Perumatty
          setKB6: false,
          //Vadakarapathy
          setKB7: false,
        });
      }
      if (user_role[1] == "Kozhinjampara") {
        this.setState({
          selrua: "kdisc_sci_aws_08",
          //Chittur-Thathamangalam Muncipality</option>
          setKB1: false,
          //Eruthenpathy
          setKB2: false,
          //Kozhinjampara
          setKB3: true,
          //Nallepilly
          setKB4: false,
          //Pattanchery
          setKB5: false,
          //Perumatty
          setKB6: false,
          //Vadakarapathy
          setKB7: false,
        });
      }
      if (user_role[1] == "Vadakarapathy") {
        this.setState({
          selrua: "kdisc_sci_aws_09",
          //Chittur-Thathamangalam Muncipality</option>
          setKB1: false,
          //Eruthenpathy
          setKB2: false,
          //Kozhinjampara
          setKB3: false,
          //Nallepilly
          setKB4: false,
          //Pattanchery
          setKB5: false,
          //Perumatty
          setKB6: false,
          //Vadakarapathy
          setKB7: true,
        });
      }
      if (user_role[1] == "Perumatty") {
        this.setState({
          selrua: "kdisc_sci_aws_14",
          //Chittur-Thathamangalam Muncipality</option>
          setKB1: false,
          //Eruthenpathy
          setKB2: false,
          //Kozhinjampara
          setKB3: false,
          //Nallepilly
          setKB4: false,
          //Pattanchery
          setKB5: false,
          //Perumatty
          setKB6: true,
          //Vadakarapathy
          setKB7: false,
        });
      }
      if (user_role[1] == "Eruthenpathy") {
        this.setState({
          selrua: "kdisc_sci_aws_10",
          //Chittur-Thathamangalam Muncipality</option>
          setKB1: false,
          //Eruthenpathy
          setKB2: true,
          //Kozhinjampara
          setKB3: false,
          //Nallepilly
          setKB4: false,
          //Pattanchery
          setKB5: false,
          //Perumatty
          setKB6: false,
          //Vadakarapathy
          setKB7: false,
        });
      }
      if (user_role[1] == "Chittur") {
        this.setState({
          selrua: "kdisc_sci_aws_01",
          //Chittur-Thathamangalam Muncipality</option>
          setKB1: true,
          //Eruthenpathy
          setKB2: false,
          //Kozhinjampara
          setKB3: false,
          //Nallepilly
          setKB4: false,
          //Pattanchery
          setKB5: false,
          //Perumatty
          setKB6: false,
          //Vadakarapathy
          setKB7: false,
        });
      }
    }
  }

  render() {
    const { t, i18n } = this.props;
    let data = {
      columns: [
        {
          label: "Station",
          field: "station",
          sort: "asc",
          width: 150,
        },
        {
          label: "RUA",
          field: "rua",
          sort: "asc",
          width: 150,
        },
        {
          label: "Location",
          field: "location",
          sort: "asc",
          width: 150,
        },
        {
          label: "Datetime",
          field: "time",
          sort: "asc",
          width: 150,
          sort:"disabled"
        },
        {
          label: "Temperature [°C]",
          field: "temperature",
          sort: "asc",
          width: 150,
        },
        {
          label: "Humidity [%]",
          field: "humidity",
          sort: "asc",
          width: 150,
        },
        {
          label: "Pressure [hPa]",
          field: "pressure",
          sort: "asc",
          width: 150,
        },
        {
          label: "Rainfall [mm]",
          field: "rainfall",
          sort: "asc",
          width: 150,
        },
        {
          label: "UV Index",
          field: "uv_index",
          sort: "asc",
          width: 150,
        },
        {
          label: "Wind Direction [°]",
          field: "wind_direction",
          sort: "asc",
          width: 150,
        },
        {
          label: "Wind Speed [m/s]",
          field: "wind_speed",
          sort: "asc",
          width: 150,
        },
      ],
      rows: this.state.data,
    };
    const selectRow = {
      mode: "checkbox",
      clickToSelect: true,
    };

    const CustomToggleList = ({ columns, onColumnToggle, toggles }) => (
      <div className="btn-group btn-group-toggle" data-toggle="buttons">
        {columns
          .map((column) => ({
            ...column,
            toggle: toggles[column.dataField],
          }))
          .map((column) => (
            <button
              type="button"
              key={column.dataField}
              className={`btn btn-secondary ${column.toggle ? "active" : ""}`}
              data-toggle="button"
              aria-pressed={column.toggle ? "true" : "false"}
              onClick={() => onColumnToggle(column.dataField)}
            >
              {column.text}
            </button>
          ))}
      </div>
    );
    return (
      <React.Fragment>
        <div className="page-content">
          <Card style={{ backgroundColor: "#e5ebee" }}>
            <CardHeader style={{ backgroundColor: "#A4C1D2" }}>
              <Row>
                <Col>
                  <h4
                    className="card-title"
                    style={{ padding: "5px", fontSize: "18px" }}
                  >
                    {" "}
                    {t("Weather Data")}
                  </h4>
                </Col>
                <Col>
                  <Link to="report">
                    <i
                      className="ri-close-fill"
                      style={{ fontSize: "20px", float: "right" }}
                    ></i>
                  </Link>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Container fluid>
                <Row>
                  <Col xs={12}></Col>
                </Row>
                <Row>
                  <Col xl={12}>
                    <Card style={{ backgroundColor: "#f1f6fb" }}>
                      <CardBody>
                        <Col>
                          <Row>
                            <Col md="4">
                              <div className="col-md-12 date-range">
                                <Label
                                  className="col-md-12 col-form-label"
                                  style={{ fontSize: "14px" }}
                                >
                                  {t("Date range")}{" "}
                                </Label>

                                <RangePicker
                                  name="range"
                                  onChange={this.onChangedate}
                                  //  defaultValue={[moment('2021-04-01', dateFormat)]}
                                  disabledDate={this.disabledDate}
                                  format="DD-MM-YYYY"
                                  width
                                />
                              </div>
                              <center
                                style={
                                  this.state.showbutton
                                    ? { display: "none" }
                                    : {}
                                }
                              >
                                <p style={{ color: "red", fontSize: "10px" }}>
                                  {t(
                                    "Please select a date range between 30 days"
                                  )}
                                </p>
                              </center>
                            </Col>
                            <Col md="4">
                              <FormGroup>
                                <Label
                                  className="col-md-12 col-form-label"
                                  style={{ fontSize: "14px" }}
                                >
                                  {t("Location")}{" "}
                                </Label>
                                <select
                                  className="form-select"
                                  md="6"
                                  style={{ fontSize: "12px" }}
                                  name="location"
                                  onChange={this.onChange}
                                  required
                                  value={this.state.selrua}
                                >
                                  <option
                                    value="kdisc_sci_aws_01"
                                    style={
                                      this.state.setKB1
                                        ? {}
                                        : { display: "none" }
                                    }
                                  >
                                    Chittur-Thathamangalam (Muncipal Corporation Office)
                                  </option>
                                  <option
                                    value="kdisc_sci_aws_10"
                                    style={
                                      this.state.setKB2
                                        ? {}
                                        : { display: "none" }
                                    }
                                  >
                                    Eruthenpathy (Seed Godown)
                                  </option>
                                  <option
                                    value="kdisc_sci_aws_04"
                                    style={
                                      this.state.setKB2
                                        ? {}
                                        : { display: "none" }
                                    }
                                  >
                                    Eruthenpathy (Bhagavathy Govt. High School)
                                  </option>
                                  <option
                                    value="kdisc_sci_aws_08"
                                    style={
                                      this.state.setKB3
                                        ? {}
                                        : { display: "none" }
                                    }
                                  >
                                    Kozhinjampara (Bharathamatha Arts and Science
                                      College)
                                  </option>
                                  <option
                                    value="kdisc_sci_aws_12"
                                    style={
                                      this.state.setKB3
                                        ? {}
                                        : { display: "none" }
                                    }
                                  >
                                   Kozhinjampara (KWA Water treatment Plant)
                                  </option>
                                  <option
                                    value="kdisc_sci_aws_05"
                                    style={
                                      this.state.setKB3
                                        ? {}
                                        : { display: "none" }
                                    }
                                  >
                                    Perumatty (Moolathara Regulator)
                                  </option>
                                  <option
                                    value="kdisc_sci_aws_03"
                                    style={
                                      this.state.setKB4
                                        ? {}
                                        : { display: "none" }
                                    }
                                  >
                                    Nallepilly (Krishi Bhavan)
                                  </option>
                                  <option
                                    value="kdisc_sci_aws_07"
                                    style={
                                      this.state.setKB4
                                        ? {}
                                        : { display: "none" }
                                    }
                                  >
                                    Nallepilly (Government Technical High School)
                                  </option>
                                  <option
                                    value="kdisc_sci_aws_06"
                                    style={
                                      this.state.setKB5
                                        ? {}
                                        : { display: "none" }
                                    }
                                  >
                                    Pattanchery (Panchayat Cremation Ground)
                                  </option>
                                  <option
                                    value="kdisc_sci_aws_02"
                                    style={
                                      this.state.setKB5
                                        ? {}
                                        : { display: "none" }
                                    }
                                  >
                                    Pattanchery (GSM High School,Thattamangalam)
                                  </option>
                                  <option
                                    value="kdisc_sci_aws_14"
                                    style={
                                      this.state.setKB6
                                        ? {}
                                        : { display: "none" }
                                    }
                                  >
                                    Perumatty (Panchayat Office)
                                  </option>
                                  <option
                                    value="kdisc_sci_aws_11"
                                    style={
                                      this.state.setKB6
                                        ? {}
                                        : { display: "none" }
                                    }
                                  >
                                    Perumatty (Higher Secondary School)
                                  </option>
                                  <option
                                    value="kdisc_sci_aws_13"
                                    style={
                                      this.state.setKB7
                                        ? {}
                                        : { display: "none" }
                                    }
                                  >
                                    Vadakarapathy (Kinarpallam Milk Society)
                                  </option>
                                  <option
                                    value="kdisc_sci_aws_09"
                                    style={
                                      this.state.setKB7
                                        ? {}
                                        : { display: "none" }
                                    }
                                  >
                                    Vadakarapathy (Ahalia campus)
                                  </option>
                                </select>
                              </FormGroup>
                            </Col>

                            <Col md="4">
                              <FormGroup
                                style={
                                  this.state.showbutton
                                    ? {}
                                    : { display: "none" }
                                }
                              >
                                <Label
                                  className="col-md-12 col-form-label"
                                  style={{ fontSize: "14px" }}
                                >
                                  <span>&nbsp;</span>
                                </Label>
                                <Button
                                  color="primary"
                                  className="waves-effect waves-light mr-1"
                                  style={{ fontSize: "11px", float: "right" }}
                                  onClick={this.getData}
                                >
                                  {t("Search")}
                                </Button>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col></Col>
                          </Row>
                        </Col>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col xl={12}>
                    <Card>
                      <CardBody>
                        <Row>
                          <Col xs={12}>
                            <div
                              style={
                                this.state.showwaitintext
                                  ? {}
                                  : { display: "none" }
                              }
                            >
                              <center>{t("Waiting for search")}</center>
                            </div>
                            <div
                              style={
                                this.state.showloader ? {} : { display: "none" }
                              }
                            >
                              <center>
                                <Spinner color="dark" />
                              </center>
                            </div>

                            <div
                              style={
                                this.state.showtable ? {} : { display: "none" }
                              }
                            >
                              <div class="text-right">
                                <CSVLink
                                  filename="weather_data_onlinesensor.csv"
                                  data={this.state.csvdownloadData}
                                >
                                  {t("Download Report")}
                                </CSVLink>
                              </div>
                              <div id="weatherdata">
                                <MDBDataTable
                                  paging={true}
                                  bordered
                                  scrollX
                                  data={data}
                                  className="datadownloadtable alnright"
                                  style={{ fontSize: "12px" }}
                                  maxHeight="300px"
                                />
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </CardBody>
          </Card>
        </div>
      </React.Fragment>
    );
  }
}

export default withNamespaces()(WeatherData);
