import React, { Component } from "react";
import {
  Card,
  CardBody,
  Row,
  Col,
  FormGroup,
  Label,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  Container,
  Button,
  Spinner,
} from "reactstrap";
import Permission from "../../permission.js";
import { MDBDataTable } from "mdbreact";
// Breadcrumb
import { toast } from "react-toastify";
import validate_Token from "../../validateToken.js";
import { CSVLink } from "react-csv";
import { withNamespaces } from "react-i18next";
import greencheck from "../../assets/images/checkgreen.png";
import greycheck from "../../assets/images/checkgrey.png";
import Moment from "moment";

toast.configure();
class PayoutFarmer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabledata: [],
      showloader: true,
      perilData: [],
      crop: "all",
      peril: "all",
      season: "all",
      rua: "all",
      userRole:"",
      showLoader: false,
      showWaiting: true,
      showtable: false,
      seasonData: [],
      nextPageURL: "",
      PrevPageURL: "",
      disablenext:false,
      showPrevious: false,
      cropKey:1,
      previousPageURL: "",
      processingprevData: false,
      processingpNextData: false,
      modal_transhash: false,
      csvdownloadData: [],
      hashData: [],
      transactionHash: "",
      cropData: [
        "Paddy",
        "Veg Group I",
        "Mango",
        "Paddy 2nd Crop",
        "Paddy 3rd Crop",
        "Paddy Winter",
        "Veg Group I - SMR",
      ],
      perilData: [
        "Deficit Rainfall",
        "Disease Congenial Climate",
        "Dry Spell",
        "Excess Rainfall",
        "High Temperature",
        "Low Temperature",
        "Rise in Night Temperature",
        "Rise in Temperature",
        "Unseasonal Rainfall",
        "Wet Spell",
        "Wind Speed",
        "Wind Speed (A)",
        "Wind Speed (B)",
      ],
      perilseasonData: [],
      dp: "all",
      farmerInfo: [],
      farmerID: "",
      insurance_data: [],
    };
    this.searchtransaction = this.searchtransaction.bind(this);
    this.onChange = this.onChange.bind(this);
    this.handleCropchange = this.handleCropchange.bind(this);
    this.handleSeasonchange = this.handleSeasonchange.bind(this);
    this.handlePerilchange = this.handlePerilchange.bind(this);
    this.handledpchange = this.handledpchange.bind(this);
    this.nextpage = this.nextpage.bind(this);
    this.Backpage = this.Backpage.bind(this);
    this.tog_transhash = this.tog_transhash.bind(this);
  }
  handledpchange(e) {
    this.setState({
      dp: e.target.value,
    });
  }
  async gettransactionBVdetails(hash) {
    this.setState({
      hashData: [],
    });
    // console.log("TRANSACTION HASH",hash)
    if(hash.length>0){
      let searchdata = {
        transaction_hash: hash,
      };
      searchdata = JSON.stringify(searchdata);
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + localStorage.getItem("token"),
        },
        body: searchdata,
      };
      try {
        var hashData = await fetch(
          process.env.REACT_APP_APIEND + `farmer/smart-contract-transaction-hash`,
          requestOptions
        ).then((res) => {
          return res.json();
        });
        // console.log("HASH DATA", hashData.insurance_data);
        this.setState({
          hashData: hashData.data.result.metaData[0],
          farmerInfo: hashData.data.result.basicInfo,
          farmerID: hashData.data.result.farmerID,
          insurance_data: hashData.insurance_data,
        });
        if (
          hashData.insurance_data[0].premium_paid == true &&
          hashData.insurance_data[0].stateGovtSubsidy == true &&
          hashData.insurance_data[0].centralGovtSubsidy == true &&
          hashData.insurance_data[0].weatherDataVerified == true
        ) {
          this.setState({
            transactionHash: hash,
          });
        } else {
          this.setState({
            transactionHash: "",
          });
        }
      } catch (err) {
        toast.error("Something's not right !");
      }
    }else{
      this.setState({
        transactionHash: "",
      });
    }
  
  }
  tog_transhash(hash, data) {
    this.setState({
      farmerInfo: data,
      farmerID: data.farmerID,
    })
    this.gettransactionBVdetails(hash);
    this.setState((prevState) => ({
      modal_transhash: !prevState.modal_transhash,
    }));
    // console.log("CURRENT DATA", data);
  }
  async handleCropchange(e) {
    this.setState({
      crop: e.target.value,
    });
    let searchdata = {
      season: this.state.season,
      crop: e.target.value,
    };
    searchdata = JSON.stringify(searchdata);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + localStorage.getItem("token"),
      },
      body: searchdata,
    };
    try {
      var perildata = await fetch(
        process.env.REACT_APP_APIEND + `smart_crop/get-perils`,
        requestOptions
      ).then((res) => {
        return res.json();
      });
      var newperils = perildata.result[0].perils;
      // console.log("PERIL DATA", newperils);
      this.setState({
        perilData: newperils,
      });
    } catch (err) {
      toast.error("Check Season");
    }
  }

  onChange(ev) {
    // if ((ev.target.name = "rua")) {
    //   this.setState({
    //     selrua: ev.target.value,
    //   });
    // }
    this.setState({
      [ev.target.name]: ev.target.value,
    });
  }
  makecsvdata(data) {
    // console.log("CSV DATA", data);
    let csvData = [
      [
        "FarmerID",
        "Farmer Name",
        "Rua",
        "Crop",
        "Season",
        "Phase",
        "Peril",
        "CropArea",
        "EligiblePayout",
        "UnitRate",
        "DataProvider",
      ],
    ];
    data.map((wdata) => {
      if (wdata.hasOwnProperty("farmerID")) {
        csvData.push([
          wdata.farmerID,
          wdata.farmerName,
          wdata.rua,
          wdata.crop,
          wdata.season,
          wdata.phase,
          wdata.peril,
          wdata.cropArea,
          wdata.eligiblePayout,
          wdata.unitRate,
          wdata.data_provider,
        ]);
      }
    });
    this.setState({
      csvdownloadData: csvData,
    });
  }
  async searchtransaction() {
    var getthis = this;
    this.setState({
      showLoader: true,
      showWaiting: false,
      showtable: false,
    });
    let searchData = {
      rua: getthis.state.rua === "Chittur-Thathamangalam" ? "Chittur" : this.state.rua,
      season: getthis.state.season,
      crop: getthis.state.crop,
      peril: getthis.state.peril,
      data_provider: getthis.state.dp,
    };
    searchData = JSON.stringify(searchData);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + localStorage.getItem("token"),
      },
      body: searchData,
    };
    try {
      var getpayout = await fetch(
        process.env.REACT_APP_APIEND + `farmer/farmer-payout`,
        requestOptions
      ).then((res) => {
        return res.json();
      });
      if (getpayout.code == 200) {
        // console.log("Transaction Data");
        this.generatetable(getpayout.data.results);
        this.makecsvdata(getpayout.data.results);
        if( getpayout.data.next==null){
          this.setState({
            disablenext:true
          })
        }else{
          this.setState({
            nextPageURL: getpayout.data.next,
            previousPageURL: getpayout.data.previous,
            disablenext:false
          });
        }
  
      } else {
        toast.error("Error Occured!");
      }
    } catch (err) {
      toast.error("Something Happened !");
    }
  }
  checkNaN(cost) {
    cost = Number(cost);
    // console.log("COSTTT ",cost)
    if (isNaN(cost)) {
      return "0.00";
    } else {
      return parseFloat(cost).toFixed(2);
    }
  }
  generatetable(data) {
    let transaction_data = [];
    var getthis = this;
    var newdata = {};
    data.map(function (item, index, data) {
      if (item.hasOwnProperty("farmerID")) {
        newdata = {
          farmerID: item.farmerID,
          farmerName: <p>{item.farmerName}</p>,
          rua: item.rua,
          crop: item.crop,
          season: item.season,
          cropArea: <p>{getthis.checkNaN(item.cropArea)}</p>,
          peril: item.peril,
          eligiblePayout: (
            <p className="alnrighttd">
              {parseFloat(item.eligiblePayout).toFixed(2)}
            </p>
          ),
          unitRate: (
            <p className="alnrighttd">{getthis.checkNaN(item.unitRate)}</p>
          ),
          phase: <p>{item.phase}</p>,
          dp: item.data_provider,
          BCstatus: getthis.checkBC(item),
        };
      }

      transaction_data.push(newdata);
      // if (item.eligiblePayout != 0) {
      //   transaction_data.push(newdata);
      // }
    });
    this.setState({
      tabledata: transaction_data,
      showLoader: false,
      showtable: true,
    });
  }

  checkBC(item) {
    if(item.transactionHash.length>0){
      if (
        item.premium_paid == true &&
        item.weatherDataVerified == true &&
        item.centralGovtSubsidy == true &&
        item.stateGovtSubsidy == true
      ) {
        return (
          <img
            src={greencheck}
            height="50"
            alt="logo"
            style={{ cursor: "pointer" }}
            onClick={(e) => this.tog_transhash(item.transactionHash, item)}
          />
        );
      } else {
        return (
          <img
            src={greycheck}
            height="50"
            alt="logo"
            style={{ cursor: "pointer" }}
            onClick={(e) => this.tog_transhash(item.transactionHash, item)}
          />
        );
      }
    }else{
      return (
        <img
          src={greycheck}
          height="50"
          alt="logo"
          style={{ cursor: "pointer" }}
          onClick={(e) => this.tog_transhash(item.transactionHash, item)}
        />
      );
    }
    
  }
  async fetchseason() {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + localStorage.getItem("token"),
      },
    };
    try {
      var seasonData = await fetch(
        process.env.REACT_APP_APIEND + `smart_crop/term-sheet-season-crop`,
        requestOptions
      ).then((res) => {
        return res.json();
      });
      this.setState({
        perilseasonData: seasonData.result,
      });

      var newseasonData = [];
      {
        seasonData.result.map(function (item, index, data) {
          newseasonData.push(Object.keys(item));
        });
      }
      // console.log("NEW DATA", newseasonData);
      this.setState({
        seasonData: newseasonData,
      });
    } catch (err) {
      toast.error("Something's not right");
    }
  }
  handlePerilchange(e) {
    this.setState({
      peril: e.target.value,
    });
  }
  handleSeasonchange(e) {
    // console.log("SELECTED SEASON", e.target.value);
    this.setState({
      season: e.target.value,
    });
    this.setState({
      cropData: [],
    });
    let selected_index = e.target.selectedIndex;
    let season = e.target.value;
    let allcropdata = this.state.perilseasonData;
    if (selected_index == "0") {
      var list_array = [];
      allcropdata.map((index) => {
        list_array.push("---------" + Object.keys(index)[0] + "--------");
        // console.log("INDEX ", index[Object.keys(index)[0]]);
        var seasoncrop = index[Object.keys(index)[0]];
        seasoncrop.map((crop) => {
          list_array.push(crop);
        });
      });
      this.setState({
        cropData: list_array,
        crop:"all",
        cropKey:this.state.cropKey+1
      });
    } else {
      var seasoncrop = allcropdata[selected_index - 1];
      // console.log("Season crop", seasoncrop[Object.keys(seasoncrop)[0]]);
      var croplist = seasoncrop[Object.keys(seasoncrop)[0]];
      var list_array = [];
      croplist.map((item, index, data) => {
        list_array.push(item);
      });
      this.setState({
        cropData: list_array,
        crop:list_array[0],
        cropKey:this.state.cropKey+1
      });
    }
  }

  async nextpage() {
    this.setState({
      processingpNextData: true,
    });
    let searchData = {
      rua: this.state.rua,
      season: this.state.season,
      crop: this.state.crop,
      peril: this.state.peril,
      data_provider: this.state.dp,
    };
    searchData = JSON.stringify(searchData);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + localStorage.getItem("token"),
      },
      body: searchData,
    };
    try {
      var getpayout = await fetch(this.state.nextPageURL, requestOptions).then(
        (res) => {
          return res.json();
        }
      );
      if (getpayout.code == 200) {
        // console.log("Transaction Data");
        this.generatetable(getpayout.data.results);
        this.setState({
          showPrevious: true,
          processingpNextData: false,
        });
        if (getpayout.data.hasOwnProperty("previous")) {
          this.setState({
            PrevPageURL: getpayout.data.previous,
          });
        }
        if (getpayout.data.hasOwnProperty("next")) {
          this.setState({
            nextPageURL: getpayout.data.next,
          });
        }
      } else {
        toast.error("Error Occured!");
      }
    } catch (err) {
      toast.error("Something Happened !");
    }
  }
  async Backpage() {
    // console.log("ONCLICK PREVIOUS");
    this.setState({
      processingprevData: true,
    });
    let searchData = {
      rua: this.state.rua,
      season: this.state.season,
      crop: this.state.crop,
      peril: this.state.peril,
      data_provider: this.state.dp,
    };
    searchData = JSON.stringify(searchData);

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + localStorage.getItem("token"),
      },
      body: searchData,
    };
    try {
      var getpayout = await fetch(this.state.PrevPageURL, requestOptions).then(
        (res) => {
          return res.json();
        }
      );
      if (getpayout.code == 200) {
        // console.log("Transaction Data");
        this.generatetable(getpayout.data.results);
        this.setState({
          processingprevData: false,
        });
      } else {
        toast.error("Error Occured!");
      }
    } catch (err) {
      toast.error("Something Happened !");
    }
  }
  checkStatus(status, type) {
    if (status[0] != undefined) {
      // console.log("STATUS", status[0].weatherDataVerified);
      if (type == "weatherData") {
        if (status[0].weatherDataVerified == true) {
          return (
            "True" +
            " | Last updated by " +
            status[0].weather_data_user +
            " on " +
            status[0].weather_data_datetime
          );
        } else {
          return "False";
        }
      }
      if (type == "weatherDate") {
        if (status[0].weatherDataVerified == true) {
          return (
            Moment(status[0].created_at).format("DD-MM-YYYY h:mm:ss")
          );
        } else {
          return "False";
        }
      }
      if (type == "govtSubsidyState") {
        if (status[0].stateGovtSubsidy == true) {
          return (
            "True" +
            " | Last updated by " +
            status[0].stateGovtSubsidy_user +
            " on " +
            status[0].stateGovtSubsidy_datetime
          );
        } else {
          return "False";
        }
      }
      if (type == "govtSubsidyCentral") {
        if (status[0].centralGovtSubsidy == true) {
          return (
            "True" +
            " | Last updated by " +
            status[0].centralGovtSubsidy_user +
            " on " +
            status[0].centralGovtSubsidy_datetime
          );
        } else {
          return "False";
        }
      }
      if (type == "premiumPaid") {
        if (status[0].premium_paid == true) {
          return (
            "True" +
            " | Last updated by " +
            status[0].premium_paid_user +
            " on " +
            status[0].premium_paid_datetime
          );
        } else {
          return "False";
        }
      }
    } else {
      return "";
    }
  }
  componentDidMount() {
    validate_Token();
    this.fetchseason(); 
    
    const krishibhavan = Permission();
    if (krishibhavan[0] == "KB") {
      this.setState({
        userRole: "KB",
        rua: krishibhavan[1],
      });
    }
  }
  render() {
    const { t, i18n } = this.props;
    let data = {
      columns: [
        {
          label: "Farmer ID",
          field: "farmerID",
          width: 100,
        },
        {
          label: "Farmer Name",
          field: "farmerName",
          width: 300,
        },
        {
          label: "RUA",
          field: "rua",
          width: 150,
        },
        {
          label: "Crop",
          field: "crop",
          width: 150,
        },
        {
          label: "Season",
          field: "season",
          width: 150,
        },
        {
          label: "Phase",
          field: "phase",
          width: 150,
        },
        {
          label: "Peril",
          field: "peril",
          width: 150,
        },
        {
          label: "Crop Area",
          field: "cropArea",
          width: 150,
        },
        {
          label: "Eligible Payout",
          field: "eligiblePayout",
          width: 150,
        },
        {
          label: "Unit Rate",
          field: "unitRate",
          width: 150,
        },

        {
          label: "Data Provider",
          field: "dp",
          width: 150,
        },

        {
          label: "BC Status",
          field: "BCstatus",
          sort: "asc",
          width: 150,
        },
      ],
      rows: this.state.tabledata,
    };
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Row>
              <Col xs={12}>
                <h4
                  className="card-title"
                  style={{ padding: "5px", fontSize: "18px" }}
                >
                  {" "}
                  {t("Payout Transactions - Farmer")}
                </h4>
              </Col>
            </Row>
            <Row>
              <Col>
                <Card style={{ backgroundColor: "#f1f6fb" }}>
                  <CardBody>
                    <Col>
                      <Row>
                        <Col md="3">
                          <FormGroup>
                            <Label
                              className="col-md-5 col-form-label"
                              style={{ fontSize: "14px" }}
                            >
                              {t("Location")}{" "}
                            </Label>
                            <select
                              className="form-select"
                              md="6"
                              style={{ fontSize: "11px" }}
                              name="rua"
                              value={this.state.rua}
                              onChange={this.onChange}
                            >
                              <option value="all"
                               style={
                                this.state.userRole == "KB"
                                  ? { display: "none" }
                                  : {}
                              }
                              >All</option>
                            <option
                                value="Chittur-Thathamangalam"
                                style={
                                  this.state.userRole == "KB"
                                    ? this.state.rua == "Chittur" || this.state.rua == "Chittur-Thathamangalam"
                                      ? {}
                                      : { display: "none" }
                                    : {}
                                }
                              >
                                Chittur-Thathamangalam
                              </option>
                              <option
                                value="Eruthenpathy"
                                style={
                                  this.state.userRole == "KB"
                                    ? this.state.rua == "Eruthenpathy"
                                      ? {}
                                      : { display: "none" }
                                    : {}
                                }
                              >
                                Eruthenpathy{" "}
                              </option>
                              <option
                                value="Kozhinjampara"
                                style={
                                  this.state.userRole == "KB"
                                    ? this.state.rua == "Kozhinjampara"
                                      ? {}
                                      : { display: "none" }
                                    : {}
                                }
                              >
                                Kozhinjampara
                              </option>
                              <option
                                value="Nallepilly"
                                style={
                                  this.state.userRole == "KB"
                                    ? this.state.rua == "Nallepilly"
                                      ? {}
                                      : { display: "none" }
                                    : {}
                                }
                              >
                                Nallepilly
                              </option>
                              <option
                                value="Pattanchery"
                                style={
                                  this.state.userRole == "KB"
                                    ? this.state.rua == "Pattanchery"
                                      ? {}
                                      : { display: "none" }
                                    : {}
                                }
                              >
                                Pattanchery
                              </option>
                              <option
                                value="Perumatty"
                                style={
                                  this.state.userRole == "KB"
                                    ? this.state.rua == "Perumatty"
                                      ? {}
                                      : { display: "none" }
                                    : {}
                                }
                              >
                                Perumatty
                              </option>
                              <option
                                value="Vadakarapathy"
                                style={
                                  this.state.userRole == "KB"
                                    ? this.state.rua == "Vadakarapathy"
                                      ? {}
                                      : { display: "none" }
                                    : {}
                                }
                              >
                                Vadakarapathy
                              </option>
                            </select>
                          </FormGroup>
                        </Col>

                        <Col md="2">
                          <FormGroup>
                            <Label
                              className="col-md-10 col-form-label"
                              style={{ fontSize: "14px" }}
                            >
                              {t("Season")}{" "}
                            </Label>
                            <select
                              className="form-select"
                              style={{ fontSize: "11px" }}
                              onChange={this.handleSeasonchange}
                            >
                              <option value="all">All</option>
                              {this.state.seasonData.length > 0 &&
                                this.state.seasonData.map((item) => (
                                  <option value={item} key={item}>
                                    {item}
                                  </option>
                                ))}
                            </select>
                          </FormGroup>
                        </Col>
                        <Col md="2">
                          <FormGroup>
                            <Label
                              className="col-md-10 col-form-label"
                              style={{ fontSize: "14px" }}
                            >
                              {t("Crop")}{" "}
                            </Label>
                            <select
                              className="form-select"
                              style={{ fontSize: "11px" }}
                              value={this.state.crop}
                              onChange={this.handleCropchange}
                              key={this.state.cropKey}
                            >
                              <option value="all">All</option>
                              {this.state.cropData.length > 0 &&
                                this.state.cropData.map((item) => (
                                  <option value={item} key={item}>
                                    {item}
                                  </option>
                                ))}
                            </select>
                          </FormGroup>
                        </Col>

                        <Col md="2">
                          <FormGroup>
                            <Label
                              className="col-md-10 col-form-label"
                              style={{ fontSize: "14px" }}
                            >
                              {t("Peril")}{" "}
                            </Label>
                            <select
                              className="form-select"
                              style={{ fontSize: "11px" }}
                              onChange={this.handlePerilchange}
                            >
                              <option value="all">All</option>
                              {this.state.perilData.length > 0 &&
                                this.state.perilData.map((item) => (
                                  <option value={item} key={item}>
                                    {item}
                                  </option>
                                ))}
                            </select>
                          </FormGroup>
                        </Col>
                        <Col md="2">
                          <FormGroup>
                            <Label
                              className="col-md-12 col-form-label"
                              style={{ fontSize: "14px" }}
                            >
                              {t("Data Provider")}
                            </Label>
                            <select
                              className="form-select"
                              style={{ fontSize: "11px" }}
                              onChange={this.handledpchange}
                            >
                              <option value="all">All</option>
                              <option value="AIC">AIC</option>
                              <option value="Online Sensors">
                                Online Sensor
                              </option>
                              {/* <option value="ECMWF">ECMWF</option>
                              <option value="Meteoblue">Meteoblue</option>
                              <option value="NASA PowerLarc">Nasa</option>
                              <option value="darksky">Dark Sky</option> */}
                            </select>
                          </FormGroup>
                        </Col>
                        <Col md="1">
                          <FormGroup>
                            <Label
                              className="col-md-12 col-form-label"
                              style={{ fontSize: "14px", float: "right" }}
                            >
                              <span>&nbsp;</span>
                            </Label>
                            <Button
                              color="primary"
                              className="waves-effect waves-light mr-1"
                              style={{ fontSize: "11px", float: "right" }}
                              onClick={this.searchtransaction}
                            >
                              {t("Search")}
                            </Button>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <center>
                      <p
                        style={
                          this.state.showWaiting == true
                            ? {}
                            : { display: "none" }
                        }
                      >
                        {t("Waiting for search")}
                      </p>

                      <Spinner
                        style={
                          this.state.showLoader == false
                            ? { display: "none" }
                            : {}
                        }
                      ></Spinner>
                    </center>
                    <div id="payout-transaction-farmer">
                      {/* <p style={{ float: "right" }}>Total number of farmers : {this.state.totalfarmers}</p> */}
                      <div
                        style={
                          this.state.showtable == true
                            ? {}
                            : { display: "none" }
                        }
                      >
                        <div class="text-right">
                          <CSVLink
                            filename="Payout.csv"
                            data={this.state.csvdownloadData}
                          >
                            Download Report
                          </CSVLink>
                        </div>

                        <MDBDataTable
                          paging={false}
                          showPaginationBottom={false}
                          bordered
                          searching={false}
                          data={data}
                          scrollX
                          maxHeight="300px"
                          order={["farmerID", "asc"]}
                          className="datadownloadtable"
                          style={{ fontSize: "12px" }}
                        />

                        <div
                          style={
                            this.state.showPrevious == true
                              ? {}
                              : { display: "none" }
                          }
                        >
                          <Button
                            style={{ float: "left", marginTop: "10px" }}
                            onClick={this.Backpage}
                          >
                            {this.state.processingprevData ? (
                              <Spinner
                                style={{ width: "0.7rem", height: "0.7rem" }}
                                type="grow"
                                color="dark"
                              />
                            ) : (
                              "Previous"
                            )}
                          </Button>
                        </div>
                        <Button
                          style={{ float: "right", marginTop: "10px" }}
                          onClick={this.nextpage}
                          disabled={this.state.disablenext}
                        >
                          {this.state.processingpNextData ? (
                            <Spinner
                              style={{ width: "0.7rem", height: "0.7rem" }}
                              type="grow"
                              color="dark"
                            />
                          ) : (
                            "Next"
                          )}
                        </Button>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {/* =============================================MODAL TRANSACTION HASH====================================== */}
            <Modal
              isOpen={this.state.modal_transhash}
              toggle={this.tog_transhash}
              backdrop="static"
              centered
              size="md"
            >
              <ModalHeader
                toggle={() => this.setState({ modal_transhash: false , farmerID: "",farmerInfo: [],transactionHash: "",insurance_data:"",})}
                style={{ backgroundColor: "#A4C1D2" }}
              >
                {this.state.farmerID} | {this.state.farmerInfo.farmerName}
              </ModalHeader>
              <ModalBody style={{ height: "500px", overflow: "auto" }}>
                {/* <pre>{this.state.hashData}</pre> */}
                <p
                  style={{
                    overflow: "100%",
                    fontFamily: "courier",
                    wordWrap: "break-word",
                  }}
                >
                  Transaction Hash - {this.state.transactionHash}
                </p>
                <Table style={{ fontSize: "12px" }}>
                  <tbody>
                    {/* <tr>
                      <td>ID</td>
                      <td>{this.state.hashData.id}</td>
                    </tr> */}
                    <tr>
                      <td>Created on</td>
                      <td> {this.checkStatus(
                          this.state.insurance_data,
                          "weatherDate"
                        )}</td>
                    </tr>
                    <tr>
                      <td>Crop RUA</td>
                      <td>{this.state.hashData.cropRUA}</td>
                    </tr>
                    <tr>
                      <td>Crop Area</td>
                      <td>{this.state.hashData.cropArea}</td>
                    </tr>
                    <tr>
                      <td>Season</td>
                      <td>{this.state.hashData.season}</td>
                    </tr>
                    <tr>
                      <td>Crop</td>
                      <td>{this.state.hashData.crop}</td>
                    </tr>
                    <tr>
                      <td>Peril</td>
                      <td>{this.state.hashData.peril}</td>
                    </tr>
                    <tr>
                      <td>Phase</td>
                      <td>{this.state.hashData.phase}</td>
                    </tr>
                    <tr>
                      <td>Data Provider</td>
                      <td>{this.state.hashData.dataProvider}</td>
                    </tr>
                    <tr>
                      <td>Payout RUA</td>
                      <td>{this.state.hashData.payoutRUA}</td>
                    </tr>
                    <tr>
                      <td>Payout Farmer</td>
                      <td>{this.state.hashData.payoutFarmer}</td>
                    </tr>
                    {/* <tr>
                      <td>Created Date</td>
                      <td>{this.state.hashData.createdDate}</td>
                    </tr>
                    <tr>
                      <td>Updated Date</td>
                      <td>{this.state.hashData.updatedDate}</td>
                    </tr> */}
                    <tr>
                      <td>Weather Data Verified</td>
                      <td>
                        {this.checkStatus(
                          this.state.insurance_data,
                          "weatherData"
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>Govt Subsidy State</td>
                      <td>
                        {this.checkStatus(
                          this.state.insurance_data,
                          "govtSubsidyState"
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>Govt Subsidy Central</td>
                      <td>
                        {this.checkStatus(
                          this.state.insurance_data,
                          "govtSubsidyCentral"
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>Premium Paid</td>
                      <td>
                        {this.checkStatus(
                          this.state.insurance_data,
                          "premiumPaid"
                        )}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </ModalBody>
            </Modal>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default withNamespaces()(PayoutFarmer);
