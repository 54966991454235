import React, { Component } from 'react'
import { Table } from 'reactstrap'
// const Termsheettable =(props)=> {
class Deficittable extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
dprovider:'',
speril:0,
        };
        
      }
      componentDidMount() {
        this.setState({speril:this.props.tperil})
        this.setState({
            speril:false
        })
    }
 
    render(){
        // console.log("TABLE PROPS",this.props.tperil)
        
 
        return (
          <React.Fragment>
               <select className="form-control" style={{ fontSize: '11px' }} value={this.state.dprovider} >
                                                                <option value="aic ">AIC
                    </option>
                                                                <option value="darksky">Dark Sky</option>
                                                                <option value="ow">Open Weather</option>
                                                                <option value="onlinesen">Online Sensor</option>                                                              
                                                            </select>	

               <Table responsive scrollY class="table table-bordered" id="diseasecongenialtbl" style={{marginTop:'2%',fontSize:'12px'}}>

                    <tbody>
                        <tr>
                            <th scope="row" style={{backgroundColor:'#c3d7e2'}}>PERIOD</th>
                            <td colspan={4}>01-Jun-2020 to 31-Oct-2020</td>

                        </tr>
                        <tr>
                            <th scope="row" style={{backgroundColor:'#c3d7e2'}}>INDEX</th>
                            <td colspan={4}>Number of days in the longest spell of consecutive days having daily average
                                RH between 80 % to 90 % with daily Mean Temperature greater than 32 °C</td>

                        </tr>
                        <tr>
                            <th scope="row" style={{backgroundColor:'#c3d7e2'}}>STRIKE (cons. days) greater than</th>
                            <td colspan={4}>3</td>
                        </tr>
                        <tr>
                            <th scope="row" style={{backgroundColor:'#c3d7e2'}}>CONSECUTIVE DAYS</th>
                            <td colspan={4} id="consedays">4</td>
                        </tr>
                        <tr>
                            <th scope="row" style={{backgroundColor:'#c3d7e2'}}>EXIT</th>
                            <td colspan={4}>7</td>

                        </tr>
                        <tr>
                            <th scope="row" style={{backgroundColor:'#c3d7e2'}}>PAYOUT RATE (Rs./ cons. day)</th>
                            <td colspan={4} id="diseasepay">2000.00</td>
                        </tr>
                        <tr>
                            <th scope="row" style={{backgroundColor:'#c3d7e2'}}>PAYOUT </th>
                            <td colspan={4} id="diseasepayout">2000.00</td>
                        </tr>
                        <tr>
                            <th scope="row" style={{backgroundColor:'#c3d7e2'}}>TOTAL MAXIMUM PAYOUT (Rs.)</th>
                            <td colspan={4} id="diseasetotpay">10000.00</td>
                        </tr>
                        <tr>
                            <th scope="row" style={{backgroundColor:'#c3d7e2'}}>ELIGIBLE PAYOUT (Rs./ha)</th>
                            <td colspan={4} id="diseaseelipay">2000.00</td>
                        </tr>
                        <tr>
                            <th scope="row" style={{backgroundColor:'#c3d7e2'}}>TOTAL ELIGIBLE PAYOUT</th>
                            <td id="epcongenial" colspan={4}>
                                </td>
                        </tr>


                    </tbody>
                </Table>
          </React.Fragment>
        )
    }
    }
export default Deficittable;