import React, { useState } from "react";
import { Table, Container, Row, Col, FormGroup, Label } from "reactstrap";
import "../Dashboard/table.css";

const CropInsure = (props) => {
  function checknull(status) {
    if (status == null) {
      return "NIL";
    } else {
      return status;
    }
  }

  console.log("CROPS INSURED NOW", props.cropinsure);
  const [crops, setCrops] = useState(props.cropinsure);
  return (
    <React.Fragment>
      {Object.keys(props.cropinsure).length > 0 &&
        Object.keys(props.cropinsure).map((item, index) => (
          <div class="accordion" id="accordionExample">
            <div class="accordion-item">
              <a class="accordion-header" id="headingTwo">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={"#collapsecropins" + String(index + 1)}
                  aria-expanded="false"
                  aria-controls="collapsecropins"
                >
                  <span className="short-text">
                    <div class="container">
                      <div class="row">
                        <div class="col-sm-4">
                          <a
                            style={{
                              fontSize: "12px",
                              color: "#a3a3a3",
                              paddingRight: "3px",
                            }}
                          >
                            Season:
                          </a>
                        </div>
                        <div class="col-sm-8">
                          <a style={{ fontSize: "12px" }}>
                            {Object.keys(props.cropinsure)[index]}
                          </a>
                        </div>
                      </div>
                    </div>
                  </span>

                  <span className="full-text">
                    <a
                      style={{
                        fontSize: "12px",
                        color: "#a3a3a3",
                        paddingRight: "3px",
                      }}
                    >
                      Season:
                    </a>
                    <a style={{ fontSize: "12px" }}>
                    {Object.keys(props.cropinsure)[index]}
                    </a>
                  </span>
                  {/* <a style={{ fontSize: "12px", color: "#a3a3a3", paddingRight:'3px' }}>Crop Name:</a><a style={{ fontSize: "12px"}}>{props.cropinsure.crop_name} </a> */}
                </button>
              </a>
              <div
                id={"collapsecropins" + String(index + 1)}
                class="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <Table
                    id="tspayout"
                    bordered
                    style={{ fontSize: "11px" }}
                    responsive
                  >
                    <thead style={{ backgroundColor: "#ffffff" }}>
                      <tr>
                        <th style={{ width: "130px" }}>Crop Name</th>
                        {/* <th style={{ width: "130px" }}>Crop Code</th> */}
                        {/* <th style={{ width: "60px" }}>Mixed Crop</th> */}
                        <th style={{ width: "60px" }}>Area (ha)/No. of Trees</th>
                        {/* <th style={{ width: "60px" }}>Area Insured</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {props.cropinsure[item].map((crops) => {
                        return (
                          <tr>
                            <td>{crops.crop_name}</td>
                            {/* <td>{checknull(crops.crop_code)}</td> */}
                            {/* <td>{crops.mix_crop}</td> */}
                            <td>{parseFloat(crops.area).toFixed(2)}</td>
                            {/* <td>{crops.areainsured}</td> */}
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </div>
        ))}
    </React.Fragment>
  );
};

export default CropInsure;
