export default function getchittur(){
    const rua ={
      "type": "FeatureCollection",
      "name": "Eruthompathy",
      "crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:OGC:1.3:CRS84" } },
      "features": [
        {
          "type": "Feature",
          "properties": {
            "AREA": 11390769.569599999,
            "PERIMETER": 20410.7488954,
            "LOCATION": "Chittoor-Thathamangalam MC",
            "PANCHAYAT": null,
            "BLOCK": null,
            "MUNICIPALI": "Chittoor-Thathamangalam",
            "CORPORATIO": null,
            "DISTRICT": "Palakkad",
            "AEU_NEW": "Agro-ecological unit 23: Palakkad Eastern Plains",
            "AEU_FINAL": 23
          },
          "geometry": {
            "type": "MultiPolygon",
            "coordinates": [
              [
                [
                  [76.724945077256649, 10.700643523738693],
                  [76.725036634975595, 10.700790408369842],
                  [76.725120536237142, 10.701174742263508],
                  [76.725196846346364, 10.701787938927929],
                  [76.725433365574503, 10.702014912355221],
                  [76.725662228345271, 10.702013020759869],
                  [76.726196297774493, 10.701624876652705],
                  [76.726585403442655, 10.701698302079034],
                  [76.726982102514981, 10.702923765373487],
                  [76.727127064024188, 10.703228938253485],
                  [76.727439893361577, 10.703456873241755],
                  [76.728134140383119, 10.703759195217323],
                  [76.72968290885018, 10.704435341472848],
                  [76.730300913319454, 10.704584115447449],
                  [76.732772823105407, 10.705560694335084],
                  [76.733077995985383, 10.705634119761415],
                  [76.734237658784309, 10.705625535521545],
                  [76.734931939584342, 10.705005643960716],
                  [76.735626218132467, 10.705153456374338],
                  [76.735702528241703, 10.705535898672649],
                  [76.734710699492481, 10.707002623695027],
                  [76.734954845903317, 10.709762569407824],
                  [76.735046369843758, 10.710759172183426],
                  [76.735351576502254, 10.711984635477883],
                  [76.735588064203782, 10.71221257046615],
                  [76.736900344969314, 10.71204854662996],
                  [76.73905180273897, 10.711800590005621],
                  [76.73987579518662, 10.711727130800808],
                  [76.740043629236311, 10.709720603013748],
                  [76.740264900854754, 10.708797461694866],
                  [76.741104109385503, 10.706258787600516],
                  [76.74156190248398, 10.70533372090779],
                  [76.743164074741301, 10.703095445667069],
                  [76.744079593381301, 10.701706888570804],
                  [76.745689390569396, 10.69985198566277],
                  [76.746070871306742, 10.699157704862742],
                  [76.746292111398617, 10.698003777651159],
                  [76.746276861537012, 10.695933336460126],
                  [76.745887755868878, 10.69432546239395],
                  [76.745727544498081, 10.693175313869276],
                  [76.745796195898052, 10.69294452797658],
                  [76.745330813899159, 10.69126034380116],
                  [76.745239256180199, 10.690219887539932],
                  [76.745239256180199, 10.690187466946698],
                  [76.743858321762843, 10.689814570449233],
                  [76.743392937512056, 10.68966483491366],
                  [76.741531371234188, 10.687914847990809],
                  [76.740837092686078, 10.687538139027936],
                  [76.73905180273897, 10.686094288797952],
                  [76.736968998621165, 10.685037621114157],
                  [76.735572814342206, 10.684589376068418],
                  [76.734420776474067, 10.684370025320018],
                  [76.731567381446993, 10.683932289887984],
                  [76.730873102898883, 10.683860785331699],
                  [76.729484543550697, 10.683795912618653],
                  [76.729019161551818, 10.683569900752321],
                  [76.728706363741026, 10.682881357791537],
                  [76.728385909472834, 10.680965426162444],
                  [76.727920527473955, 10.680049907522456],
                  [76.726524343194995, 10.678756720610524],
                  [76.726287855493439, 10.677607531394923],
                  [76.724884048535571, 10.675546638256634],
                  [76.724037183547466, 10.67494010496204],
                  [76.722724900530054, 10.67395401405205],
                  [76.721801759211161, 10.674269660515369],
                  [76.719024674293323, 10.674676900728015],
                  [76.717102047767824, 10.674769386229432],
                  [76.715484625648912, 10.674859056856812],
                  [76.713478097861852, 10.675335876251902],
                  [76.712333682672622, 10.6761131282791],
                  [76.710716262805605, 10.67720221430422],
                  [76.709335328388235, 10.678209290663151],
                  [76.707649221090875, 10.679375650610385],
                  [76.706497183222766, 10.680381765408338],
                  [76.706268320451997, 10.680460926422009],
                  [76.70520018159354, 10.681544312890175],
                  [76.703346240246461, 10.682478891605257],
                  [76.701889029498361, 10.68310640809689],
                  [76.699584953762098, 10.68389320592493],
                  [76.698432913642066, 10.684133539870365],
                  [76.697273253095048, 10.684449186333687],
                  [76.696289049276601, 10.684458734386428],
                  [76.696357734455049, 10.685837745681852],
                  [76.696594253683188, 10.686450012311886],
                  [76.696594253683188, 10.686756146752856],
                  [76.69644163571661, 10.686912545658252],
                  [76.696212772945842, 10.686989783549976],
                  [76.694976800037679, 10.686616887052507],
                  [76.694282521489569, 10.686928754828921],
                  [76.692665099370657, 10.687173829022235],
                  [76.691970818570624, 10.687485696798646],
                  [76.691360475062524, 10.688181866942129],
                  [76.691284164953288, 10.688719746584871],
                  [76.691833481992219, 10.689175616561407],
                  [76.692451484209585, 10.689247118865792],
                  [76.693069454900353, 10.689624789389635],
                  [76.693611147008525, 10.691308973565055],
                  [76.694465636927447, 10.691838268967922],
                  [76.695541366938215, 10.691753374618813],
                  [76.696159369155581, 10.692438138892694],
                  [76.696548474823715, 10.692435256461678],
                  [76.697395339811848, 10.69181443486646],
                  [76.698013310502645, 10.691578874947394],
                  [76.698158272011838, 10.691808699279116],
                  [76.698402416170779, 10.69295692693375],
                  [76.698257454661572, 10.694185241132633],
                  [76.698410041101582, 10.694414137681871],
                  [76.698715213981572, 10.694564834778415],
                  [76.700111398260532, 10.694860459605671],
                  [76.701026914648637, 10.694853796235847],
                  [76.702270512487573, 10.695072185423275],
                  [76.702499375258327, 10.694764127860365],
                  [76.703346240246461, 10.694295892705149],
                  [76.703811656023859, 10.695596706799774],
                  [76.703514108074643, 10.69652080967963],
                  [76.702896105857263, 10.697752936343912],
                  [76.701599106479946, 10.699221618266719],
                  [76.701293933599956, 10.699838625144636],
                  [76.701370241457283, 10.700144759585605],
                  [76.701606762937317, 10.700371733012901],
                  [76.702301009958873, 10.700674054988472],
                  [76.704147326375136, 10.700888629458602],
                  [76.704460157964405, 10.701039326555144],
                  [76.705001816294072, 10.70080376888798],
                  [76.704917915032496, 10.700267776336798],
                  [76.705520635861717, 10.697960849696116],
                  [76.705444328004347, 10.697502128815152],
                  [76.705825808741693, 10.696578025935297],
                  [76.706207291730948, 10.696496975578169],
                  [76.707283019489836, 10.696795451309852],
                  [76.708213819517979, 10.696787826379055],
                  [76.708671578837979, 10.697013840497281],
                  [76.708831790208791, 10.697166458463872],
                  [76.70883941288767, 10.69778061668926],
                  [76.708686828699584, 10.698395734223723],
                  [76.709152210698463, 10.698775294091018],
                  [76.709915176676958, 10.698615084972122],
                  [76.710220347305039, 10.698306099626731],
                  [76.711296077315836, 10.696532247075824],
                  [76.712059007263917, 10.696448312035786],
                  [76.712989807292104, 10.697285662749666],
                  [76.713920607320247, 10.697737688734216],
                  [76.714538578011044, 10.697655676816121],
                  [76.714767440781785, 10.697500239471697],
                  [76.715682990948352, 10.6961126416845],
                  [76.716911305147249, 10.695027365872877],
                  [76.717903133896471, 10.694098455188165],
                  [76.718749998884618, 10.69270992961849],
                  [76.719200133273816, 10.691325184987619],
                  [76.719886789143047, 10.69024468320236],
                  [76.720352171141926, 10.690087322735996],
                  [76.721817006820856, 10.691072454336918],
                  [76.722518910299783, 10.691374776312488],
                  [76.723365777539811, 10.691980348046107],
                  [76.723983779757177, 10.69243621577075],
                  [76.724288952637181, 10.692510605009948],
                  [76.725296028996112, 10.693115246709187],
                  [76.726158143845836, 10.693952565896476],
                  [76.726158143845836, 10.694490447791111],
                  [76.725929247296605, 10.695105565325576],
                  [76.725471487976606, 10.695721612894422],
                  [76.724624622988472, 10.696267117467954],
                  [76.724243173777708, 10.696884158124362],
                  [76.724327072787375, 10.69780445079072],
                  [76.724876392078201, 10.698720928739782],
                  [76.724639904376659, 10.699643144528086],
                  [76.724647529307447, 10.700180992644233],
                  [76.724945077256649, 10.700643523738693]
                ]
              ]
            ]
          }
        }
      ]
      }
      
        

      return rua
}