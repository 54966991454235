import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Divider } from "@material-ui/core";
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  Label,
  Input,
  Container,
  CustomInput,
  InputGroup,
  Form,
  InputGroupAddon,
  CardFooter,
  CardHeader,
  Table,
  CardImg,
  CardTitle,
  CardSubtitle,
} from "reactstrap";
import img1 from "../../assets/images/profilepic.png";
import "./uploadbutton.css";
import Policy from "./policy";
import CropInsure from "./cropInsured";
import Claims from "./claims";
import { render } from "@testing-library/react";
import "../Dashboard/table.css";
import { withNamespaces } from 'react-i18next';
class FarmProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      common: [],
      crops: [],
      policy: [],
    };
  }
async getfarmerDetails(){
  var getthis = this;
  let fm_id = localStorage.getItem("farmer_id");
  let bodydata = {
    farmer_id: fm_id,
    // "farmer_id":"1"
  };
  bodydata = JSON.stringify(bodydata);
  const requestOptions = {
    method: "POST",
    headers: {
      Authorization: localStorage.getItem("token"),
      "Content-Type": "application/json",
    },
    body: bodydata,
  };

  var responsefarmer = await fetch(
    process.env.REACT_APP_APIEND + `farmer/my-profile`,
    requestOptions
  ).then((res) => {
    return res.json();
  });
  // console.log("FARMER API", responsefarmer);
  getthis.setState({
    common: responsefarmer.common[0],
    policy: responsefarmer.policy,
    crops: responsefarmer.crops,
  });
}
  componentDidMount() {
  this.getfarmerDetails() 
  }
  render() {
    const  {t,i18n} =this.props
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Card>
              <Col lg={12}>
                <Row>
                  <Col md={12}>
                    <Row>
                      <Col
                        className="mb-2"
                        style={{
                          fontSize: "18px",
                          marginLeft: "12px",
                          fontWeight: "bold",
                          marginTop: "18px",
                        }}
                      >
                        {this.state.common.name}
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        className="mb-2"
                        md={2}
                        style={{ marginLeft: "12px" }}
                      >
                        <i
                          className="fas fa-map-marker"
                          style={{ fontSize: "11px", color: "#a3a3a3" }}
                        ></i>{" "}
                        {this.state.common.rua}
                      </Col>
                      <Col md={3} style={{ marginLeft: "12px" }}>
                        <i
                          className="fas fa-phone-alt"
                          style={{ fontSize: "11px", color: "#a3a3a3" }}
                        ></i>{" "}
                        {this.state.common.mobile}
                      </Col>
                    </Row>
                    <hr className="mt-3 mb-3" />
                  </Col>
                </Row>
                <Row>
                  <Col md={3}>
                    <FormGroup>
                      <Label
                        className="col-md-5  "
                        style={{ fontSize: "12px", color: "#a3a3a3" }}
                      >
                        {t("Farmer")} ID                        
                      </Label>
                      <Col>{this.state.common.farmer_id}</Col>
                    </FormGroup>
                  </Col>
                  <Col md={3}>
                    <FormGroup>
                      <Label
                        className="col-md-8 "
                        style={{ fontSize: "12px", color: "#a3a3a3" }}
                      >
                        {t("Age")}|{t("Gender")}                        
                      </Label>
                      <Col>NIL | NIL</Col>
                      {/* <Col>{this.state.common.age} | {this.state.common.gender}</Col> */}
                    </FormGroup>
                  </Col>
                  <Col sm={3}>
                    <FormGroup>
                      <Label
                        className="col-md-5  "
                        style={{ fontSize: "12px", color: "#a3a3a3" }}
                      >
                        {t("Address")}                        
                        
                      </Label>
                      <Col>{this.state.common.address}</Col>
                    </FormGroup>
                  </Col>
                  <Col sm={3}>
                    <FormGroup>
                      <Label
                        className="col-md-5 "
                        style={{ fontSize: "12px", color: "#a3a3a3" }}
                      >
                        {t("District")}  
                        
                      </Label>
                      <Col>{this.state.common.district}</Col>
                    </FormGroup>
                  </Col>
                </Row>

                <Row></Row>
                <Row style={{ padding: "13px" }}>
                  <Col>
                    <div>
                      <Row style={{ padding: "5px" }}>
                        <h4 className="card-title"></h4>
                        <div class="col-lg-8  col-12 bg-white rounded-top tab-head">
                          <ul class="nav nav-tabs" id="myTab" role="tablist">
                            <li class="nav-item">
                              <a
                                class="nav-link active"
                                id="home-tab"
                                data-toggle="tab"
                                href="#home"
                                role="tab"
                                aria-controls="home"
                                aria-selected="true"
                              >
                                {t("POLICY INFO")}                                 
                              </a>
                            </li>
                            <li class="nav-item">
                              <a
                                class="nav-link"
                                id="profile-tab"
                                data-toggle="tab"
                                href="#profile"
                                role="tab"
                                aria-controls="profile"
                                aria-selected="false"
                              >
                                {t("CROP INSURED")}
                                
                              </a>
                            </li>
                            {/* <li class="nav-item">
                              <a
                                class="nav-link"
                                id="contact-tab"
                                data-toggle="tab"
                                href="#contact"
                                role="tab"
                                aria-controls="contact"
                                aria-selected="false"
                              >
                                {t("CLAIMS")}                                
                              </a>
                            </li> */}
                          </ul>
                        </div>
                        <div class="col-lg-12  bg-white mb-2">
                          <div class="tab-content mt-4" id="myTabContent">
                            <div
                              class="tab-pane fade show active"
                              id="home"
                              role="tabpanel"
                              aria-labelledby="home-tab"
                            >
                              <Policy policydata={this.state.policy} />
                            </div>
                            <div
                              class="tab-pane fade"
                              id="profile"
                              role="tabpanel"
                              aria-labelledby="profile-tab"
                            >
                              <CropInsure cropinsure={this.state.crops} />
                            </div>
                            <div
                              class="tab-pane fade"
                              id="contact"
                              role="tabpanel"
                              aria-labelledby="contact-tab"
                            >
                              <Claims />
                            </div>
                          </div>
                        </div>
                        <div></div>
                      </Row>
                    </div>
                  </Col>
                </Row>

                {/* </CardBody> */}
                {/* </Card> */}
              </Col>
            </Card>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default withNamespaces()(FarmProfile);
