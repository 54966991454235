import React, { Component } from "react";
import {
  Container,
  Card,
  CardBody,
  Row,
  Col,
  Nav,
  Button,
  NavItem,
  NavLink,
  TabPane,
  TabContent,
  Form,
  Input,
  Table,
  FormGroup,
  Label,
  CardText,
} from "reactstrap";
import { Link } from "react-router-dom";
import $ from "jquery";

import Chart from "react-apexcharts";
import ApexCharts from "apexcharts";
import classnames from "classnames";
import Hightemptable from "./hightemtable.jsx";
import Deficittable from "./deficitrain.jsx";
import Excessraintable from "./excessraintable.jsx";
import Dryspelltable from "./dryspelltable.jsx";
import Congenialtable from "./congenialtable.jsx";
import loader from "../Dashboard/images/loader.gif";
import Termsheettable from "./termsheet_Table";
import Permission from "../../permission.js";
import validate_Token from "../../validateToken.js";
export default class termSheet extends Component {
  constructor(props) {
    super(props);

    this.state = {
      location: "10.72918673,76.78622876",
      season: "Kharif 2020-21",
      crop: "Paddy",
      peril: "High Temperature",
      acre: 1,
      dprovider: "",
      activeTab: "1",
      activeTab1: "5",
      activeTab2: "9",
      activeTab3: "13",
      customActiveTab: "1",
      activeTabJustify: "5",
      col1: true,
      col2: false,
      col3: false,
      col5: true,
      showchart: false,
      hightemp: false,
      deficitrain: false,
      excessrain: false,
      dryspell: false,
      diseasecongenial: false,
      showWaiting: true,
      showPhaseselector: false,
      showLoader: true,
      rua: "",
      showPhaseselector: false,
      showLoader: false,
      showCongenial: false,
      isGetdatadisabled: false,
      selectedValue: "",
      dloc: "Nallepilly",
      dperil: "High Temperature",
      kfdcolor: "#151714",
      kfowcolor: "#151714",
      kfallcolor: "#151714",
      kfsencolor: "#151714",
      kfmetcolor: "#151714",
      kfecmwfcolor: "#151714",
      kfnasacolor: "#151714",
      kfaiccolor: "#151714",
      showdeficit: true,
      showfilter: true,
      ddloc: "Nallepilly",
      ddperil: "High Temperature",
      ddseason: "Kharif 2020-21",
      user_DR: false,
      user_KB: false,
      tableloc: "Nallepilly",
      tableseason: "Kharif 2020-21",
      tablecrop: "Paddy",
      tableperils: "High Temperature",
      tabledp: "AIC",
      latnew: 10.72918673,
      longnew: 76.78622876,
      tabledata: [],
      tablequeryresponse: [],
      seasonData: [],
      showtable: false,
      selloc: "10.72918673,76.78622876",
      cropData: ["Paddy", "Millets", "Veg Group I"],
      perilData: [
        "High Temperature",
        "Deficit Rainfall",
        "Disease Congenial Climate",
        "Dry Spell",
        "Excess Rainfall",
      ],
      //CHITTUR
      kb1: true,

      //ERUTHENPATHY
      kb2: true,

      //KOZJINJAMPARA
      kb3: true,

      //NALLEPILLY
      kb4: true,

      //NALLEPILLY
      fkb4: true,

      //PATTANCHERY
      kb5: true,

      //PERUMATTY
      kb6: true,

      //VADAKARAPATHY
      kb7: true,
      options: {
        chart: {
          id: "area-datetime",
          type: "area",
          height: 350,
          stacked: false,
          zoom: {
            autoScaleYaxis: true,
          },
        },
        xaxis: {
          type: "datetime",
          tickAmount: 6,
        },
        tooltip: {
          x: {
            format: "dd MMM yyyy",
          },
        },
      },
      series: [
        {
          name: "Test Data",
          data: [
            { x: 1615362718000, y: 67.95 },
            { x: 1615363619000, y: 20.34 },
          ],
        },
        {
          name: "Test Data2",
          data: [
            { x: 1615362718000, y: 77.95 },
            { x: 1615363619000, y: 90.34 },
          ],
        },
      ],
      optionscongenial1: {
        chart: {
          id: "tmaxcongenial",
          group: "congenial",
          type: "area",
          height: 350,
          stacked: false,
          zoom: {
            autoScaleYaxis: true,
          },
        },
        xaxis: {
          type: "datetime",
          tickAmount: 6,
        },
        yaxis: {
          min: 0,
          max: 50,
        },
        tooltip: {
          x: {
            format: "dd MMM yyyy",
          },
        },
        title: {
          text: "TEMPERATURE",
          align: "left",
        },
        annotations: {
          yaxis: [
            {
              y: 45,
              y2: 32,
              fillColor: "#247BA0",
              opacity: 0.2,
              borderColor: "#FF0000",
              label: {
                borderColor: "#FF0000",
                // offsetX: -430,
                style: {
                  color: "#fff",
                  background: "#FF0000",
                },
                text: "Temp_STRIKE",
              },
            },
          ],
        },
      },
      seriescongenial1: [
        {
          name: "temp test",
          data: [
            { x: 1615362718000, y: 67.95 },
            { x: 1615363619000, y: 20.34 },
          ],
        },
      ],
      optionscongenial2: {
        chart: {
          id: "humiditycongenial",
          group: "congenial",
          height: 350,
          stacked: false,
          zoom: {
            autoScaleYaxis: true,
          },
        },
        xaxis: {
          type: "datetime",
          tickAmount: 6,
        },
        tooltip: {
          x: {
            format: "dd MMM yyyy",
          },
        },
        title: {
          text: "HUMIDITY",
          align: "left",
        },
      },
      seriescongenial2: [
        {
          name: "Test Data humidity",
          data: [
            { x: 1615362718000, y: 67.95 },
            { x: 1615363619000, y: 20.34 },
          ],
        },
      ],
    };
    this.handleLocationchange = this.handleLocationchange.bind(this);
    this.handleCropchange = this.handleCropchange.bind(this);
    this.handleSeasonchange = this.handleSeasonchange.bind(this);
    this.handlePerilchange = this.handlePerilchange.bind(this);
    this.handleAcreChange = this.handleAcreChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.toggle = this.toggle.bind(this);
    this.phaseSelector = this.phaseSelector.bind(this);
    this.handleKfilter = this.handleKfilter.bind(this);
    this.handlecongenialKfilter = this.handlecongenialKfilter.bind(this);
    this.handledpchange = this.handledpchange.bind(this);
  }
  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }
  handleLocationchange(e) {
    this.setState({ location: e.target.value });
    if (e.target.value === "10.69300691,76.72595507") {
      this.setState({ rua: "chittur" });
      this.setState({ dloc: "Chittur Thathamangalam", tableloc: "Chittur" });
    }
    if (
      e.target.value === "10.74866619,76.85879364" ||
      e.target.value === "10.736,76.862"
    ) {
      this.setState({ rua: "eruthenpathy" });
      this.setState({ dloc: "Eruthenpathy", tableloc: "Eruthenpathy" });
    }
    if (
      e.target.value === "10.738,76.834" ||
      e.target.value === "10.73595019,76.82999565"
    ) {
      this.setState({ rua: "kozhinjampara" });
      this.setState({ dloc: "Kozhinjampara", tableloc: "Kozhinjampara" });
    }
    if (
      e.target.value === "10.72918673,76.78622876" ||
      e.target.value === "10.73,76.786"
    ) {
      this.setState({ rua: "nallepilly" });
      this.setState({ dloc: "Nallepilly", tableloc: "Nallepilly" });
    }
    if (e.target.value === "10.64777998,76.73270522") {
      this.setState({ rua: "pattanchery" });
      this.setState({ dloc: "Pattanchery", tableloc: "Pattanchery" });
    }
    if (
      e.target.value === "10.662,76.787" ||
      e.target.value === "10.64975298,76.76319652"
    ) {
      this.setState({ rua: "perumatty" });
      this.setState({ dloc: "Perumatty", tableloc: "Perumatty" });
    }
    if (
      e.target.value === "10.807,76.846" ||
      e.target.value === "10.81,76.846"
    ) {
      this.setState({ rua: "vadakarapathy" });
      this.setState({ dloc: "Vadakarapathy", tableloc: "Vadakarapathy" });
    }
  }
  async handleCropchange(e) {
    this.setState({ crop: e.target.value, tablecrop: e.target.value });
    let searchdata = {
      season: this.state.season,
      crop: this.state.crop,
    };
    searchdata = JSON.stringify(searchdata);

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + localStorage.getItem("token"),
      },
      body: searchdata,
    };
    // console.log("PERIL SEARCH DATA ", searchdata);
    var perildata = await fetch(
      process.env.REACT_APP_APIEND + `smart_crop/get-perils`,
      requestOptions
    ).then((res) => {
      return res.json();
    });
    var newperils = perildata.result[0].perils;
    // console.log("PERIL DATA", newperils);
    this.setState({
      perilData: newperils,
    });
  }
  handleSeasonchange(e) {
    this.setState({ season: e.target.value, tableseason: e.target.value });
    var newcrop = [];
    if (e.target.value == "Kharif 2020-21") {
      newcrop = ["Millets", "Paddy", "Veg Group I"];
    }
    if (e.target.value == "Rabi 2021-22") {
      newcrop = [
        "Cashew Nut",
        "Mango",
        "Millets",
        "Paddy 2nd Crop",
        "Paddy 3rd Crop",
        "Paddy Winter",
        "Veg Group I",
        "Veg Group I - SMR",
      ];
    }
    if (e.target.value == "Rabi 2022-23") {
      newcrop = ["Millets"];
    }
    if (e.target.value == "Rabi 2023") {
      newcrop = ["Millets"];
    }
    this.setState({
      cropData: newcrop,
    });
  }

  handleDataProvider(e) {
    this.setState({ dprovider: e.target.value });
  }

  handlePerilchange(e) {
    this.setState({ peril: e.target.value }, () => {
      // console.log("SELECTED PERIL ",this.state.peril)
    });
    if (e.target.value === "High Temperature") {
      this.setState({
        dperil: "High Temperature",
        tableperils: "High Temperature",
      });
    } else if (e.target.value === "Deficit Rainfall") {
      this.setState({
        dperil: "Deficit Rain",
        tableperils: "Deficit Rainfall",
      });
    } else if (e.target.value === "Excess Rainfall") {
      this.setState({ dperil: "Excess Rain", tableperils: "Excess Rainfall" });
    } else if (e.target.value === "Dry Spell") {
      this.setState({ dperil: "Dry Spell", tableperils: "Dry Spell" });
    } else if (e.target.value === "Disease Congenial Climate") {
      this.setState({
        dperil: "Disease Congenial",
        tableperils: "Disease Congenial Climate",
      });
    } else if (e.target.value == "Rise in Night Temperature") {
      this.setState({
        tableperils: "Rise in Night Temperature",
      });
    } else if (e.target.value == "Rise in Temperature") {
      this.setState({
        tableperils: "Rise in Temperature",
      });
    }
  }

  handledpchange(e) {
    this.setState({
      tabledp: e.target.value,
    });
  }
  handleAcreChange(e) {
    // console.log("ACRE", e.target.value);
    this.setState({ acre: e.target.value });
  }
  phaseSelector(e) {
    this.setState({
      selectedValue: e.target.value,
    });
    if (this.state.peril === "High Temperature") {
      if (e.target.id == "p1") {
        ApexCharts.exec("area-datetime", "removeAnnotation", "phase4");
        ApexCharts.exec("area-datetime", "removeAnnotation", "phase2");
        ApexCharts.exec("area-datetime", "removeAnnotation", "phase3");
        ApexCharts.exec("area-datetime", "addYaxisAnnotation", {
          id: "phase1",
          y: 31,
          borderColor: "#74007E",
          label: {
            borderColor: "#74007E",
            style: {
              color: "#fff",
              background: "#74007E",
            },
            text: "Strike-Phase 1",
          },
        });
        let st = new Date("01 Jun 2020").getTime();
        let end = new Date("30 Jun 2020").getTime();
        ApexCharts.exec("area-datetime", "zoomX", st, end);
      } else if (e.target.id == "p2") {
        ApexCharts.exec("area-datetime", "removeAnnotation", "phase1");
        ApexCharts.exec("area-datetime", "removeAnnotation", "phase3");
        ApexCharts.exec("area-datetime", "removeAnnotation", "phase4");

        ApexCharts.exec("area-datetime", "addYaxisAnnotation", {
          id: "phase2",
          y: 31.5,
          borderColor: "#FF0000",
          label: {
            borderColor: "#FF0000",
            offsetX: -430,

            style: {
              color: "#fff",
              background: "#FF0000",
            },
            text: "Strike-Phase 2",
          },
        });
        let st = new Date("01 Jul 2020").getTime();
        let end = new Date("31 Jul 2020").getTime();
        ApexCharts.exec("area-datetime", "zoomX", st, end);
      } else if (e.target.id == "p3") {
        ApexCharts.exec("area-datetime", "removeAnnotation", "phase1");
        ApexCharts.exec("area-datetime", "removeAnnotation", "phase2");
        ApexCharts.exec("area-datetime", "removeAnnotation", "phase4");
        ApexCharts.exec("area-datetime", "addYaxisAnnotation", {
          id: "phase3",
          y: 32,
          borderColor: "#FE670A",
          label: {
            borderColor: "#FE670A",
            offsetX: -200,
            style: {
              color: "#fff",
              background: "#FE670A",
            },
            text: "Strike-Phase 3",
          },
        });
        let st = new Date("01 Aug 2020").getTime();
        let end = new Date("31 Aug 2020").getTime();
        ApexCharts.exec("area-datetime", "zoomX", st, end);
      } else if (e.target.id == "p4") {
        ApexCharts.exec("area-datetime", "removeAnnotation", "phase1");
        ApexCharts.exec("area-datetime", "removeAnnotation", "phase2");
        ApexCharts.exec("area-datetime", "removeAnnotation", "phase3");
        ApexCharts.exec("area-datetime", "addYaxisAnnotation", {
          id: "phase4",
          y: 32.5,
          borderColor: "#FFAA0D",
          label: {
            borderColor: "#FFAA0D",
            offsetX: -100,
            style: {
              color: "#fff",
              background: "#FFAA0D",
            },
            text: "Strike-Phase 4",
          },
        });
        let st = new Date("01 Sep 2020").getTime();
        let end = new Date("15 Oct 2020").getTime();
        ApexCharts.exec("area-datetime", "zoomX", st, end);
      }
    } else if (this.state.peril === "Deficit Rainfall") {
      if (e.target.id == "p1") {
        ApexCharts.exec("area-datetime", "removeAnnotation", "phase4");
        ApexCharts.exec("area-datetime", "removeAnnotation", "phase2");
        ApexCharts.exec("area-datetime", "removeAnnotation", "phase3");
        ApexCharts.exec("area-datetime", "addYaxisAnnotation", {
          id: "phase1",
          y: 300,
          borderColor: "#74007E",
          label: {
            borderColor: "#74007E",
            style: {
              color: "#fff",
              background: "#74007E",
            },
            text: "Strike-Phase 1",
          },
        });
        let st = new Date("07 Jul 2020").getTime();
        let end = new Date("27 Jul 2020").getTime();
        ApexCharts.exec("area-datetime", "zoomX", st, end);
      } else if (e.target.id == "p2") {
        ApexCharts.exec("area-datetime", "removeAnnotation", "phase1");
        ApexCharts.exec("area-datetime", "removeAnnotation", "phase3");
        ApexCharts.exec("area-datetime", "removeAnnotation", "phase4");

        ApexCharts.exec("area-datetime", "addYaxisAnnotation", {
          id: "phase2",
          y: 250,
          borderColor: "#FF0000",
          label: {
            borderColor: "#FF0000",
            offsetX: -430,

            style: {
              color: "#fff",
              background: "#FF0000",
            },
            text: "STRIKE PHASE2",
          },
        });
        let st = new Date("28 Jul 2020").getTime();
        let end = new Date("21 Aug 2020").getTime();
        ApexCharts.exec("area-datetime", "zoomX", st, end);
      } else if (e.target.id == "p3") {
        ApexCharts.exec("area-datetime", "removeAnnotation", "phase1");
        ApexCharts.exec("area-datetime", "removeAnnotation", "phase2");
        ApexCharts.exec("area-datetime", "removeAnnotation", "phase4");
        ApexCharts.exec("area-datetime", "addYaxisAnnotation", {
          id: "phase3",
          y: 250,
          borderColor: "#FE670A",
          label: {
            borderColor: "#FE670A",
            offsetX: -200,
            style: {
              color: "#fff",
              background: "#FE670A",
            },
            text: "STRIKE PHASE3",
          },
        });
        let st = new Date("22 Aug 2020").getTime();
        let end = new Date("30 Sep 2020").getTime();
        ApexCharts.exec("area-datetime", "zoomX", st, end);
      }
    }
  }
  handlecongenialKfilter(e) {
    if (e.target.id == "aic") {
      this.setState({
        kfaiccolor: "#94de71",
        kfdcolor: "#151714",
        kfowcolor: "#151714",
        kfallcolor: "#151714",
        kfsencolor: "#151714",
        kfmetcolor: "#151714",
        kfecmwfcolor: "#151714",
        kfnasacolor: "#151714",
      });
      ApexCharts.exec("tmaxcongenial", "hideSeries", "ECMWF");
      ApexCharts.exec("tmaxcongenial", "hideSeries", "Meteo Blue");
      ApexCharts.exec("tmaxcongenial", "hideSeries", "Nasa Power Larc");
      ApexCharts.exec("tmaxcongenial", "hideSeries", "Online Sensor");
      //Show current series even if it is hidden
      ApexCharts.exec("tmaxcongenial", "showSeries", "AIC");

      ApexCharts.exec("humiditycongenial", "hideSeries", "ECMWF");
      ApexCharts.exec("humiditycongenial", "hideSeries", "Meteo Blue");
      ApexCharts.exec("humiditycongenial", "hideSeries", "Nasa Power Larc");
      ApexCharts.exec("humiditycongenial", "hideSeries", "Online Sensor");
      //Show current series even if it is hidden
      ApexCharts.exec("humiditycongenial", "showSeries", "AIC");
    } else if (e.target.id == "nasa") {
      this.setState({
        kfnasacolor: "#94de71",
        kfdcolor: "#151714",
        kfowcolor: "#151714",
        kfallcolor: "#151714",
        kfsencolor: "#151714",
        kfmetcolor: "#151714",
        kfecmwfcolor: "#151714",
        kfaiccolor: "#151714",
      });
      ApexCharts.exec("tmaxcongenial", "hideSeries", "ECMWF");
      ApexCharts.exec("tmaxcongenial", "hideSeries", "AIC");
      ApexCharts.exec("tmaxcongenial", "hideSeries", "Meteo Blue");
      ApexCharts.exec("tmaxcongenial", "hideSeries", "Online Sensor");
      //Show current series even if it is hidden
      ApexCharts.exec("tmaxcongenial", "showSeries", "Nasa Power Larc");

      ApexCharts.exec("humiditycongenial", "hideSeries", "ECMWF");
      ApexCharts.exec("humiditycongenial", "hideSeries", "AIC");
      ApexCharts.exec("humiditycongenial", "hideSeries", "Meteo Blue");
      ApexCharts.exec("humiditycongenial", "hideSeries", "Online Sensor");
      //Show current series even if it is hidden
      ApexCharts.exec("humiditycongenial", "showSeries", "Nasa Power Larc");
    } else if (e.target.id == "ecmwf") {
      this.setState({
        kfecmwfcolor: "#94de71",
        kfdcolor: "#151714",
        kfowcolor: "#151714",
        kfallcolor: "#151714",
        kfsencolor: "#151714",
        kfmetcolor: "#151714",
        kfnasacolor: "#151714",
        kfaiccolor: "#151714",
      });

      ApexCharts.exec("tmaxcongenial", "hideSeries", "AIC");
      ApexCharts.exec("tmaxcongenial", "hideSeries", "Meteo Blue");
      ApexCharts.exec("tmaxcongenial", "hideSeries", "Nasa Power Larc");
      ApexCharts.exec("tmaxcongenial", "hideSeries", "Online Sensor");
      //Show current series even if it is hidden
      ApexCharts.exec("tmaxcongenial", "showSeries", "ECMWF");

      ApexCharts.exec("humiditycongenial", "hideSeries", "AIC");
      ApexCharts.exec("humiditycongenial", "hideSeries", "Meteo Blue");
      ApexCharts.exec("humiditycongenial", "hideSeries", "Nasa Power Larc");
      ApexCharts.exec("humiditycongenial", "hideSeries", "Online Sensor");
      //Show current series even if it is hidden
      ApexCharts.exec("humiditycongenial", "showSeries", "ECMWF");
    } else if (e.target.id == "mb") {
      this.setState({
        kfmetcolor: "#94de71",
        kfdcolor: "#151714",
        kfowcolor: "#151714",
        kfallcolor: "#151714",
        kfsencolor: "#151714",
        kfecmwfcolor: "#151714",
        kfnasacolor: "#151714",
        kfaiccolor: "#151714",
      });
      ApexCharts.exec("tmaxcongenial", "hideSeries", "ECMWF");
      ApexCharts.exec("tmaxcongenial", "hideSeries", "AIC");
      ApexCharts.exec("tmaxcongenial", "hideSeries", "Nasa Power Larc");
      ApexCharts.exec("tmaxcongenial", "hideSeries", "Online Sensor");
      //Show current series even if it is hidden
      ApexCharts.exec("tmaxcongenial", "showSeries", "Meteo Blue");

      ApexCharts.exec("humiditycongenial", "hideSeries", "ECMWF");
      ApexCharts.exec("humiditycongenial", "hideSeries", "AIC");
      ApexCharts.exec("humiditycongenial", "hideSeries", "Nasa Power Larc");
      ApexCharts.exec("humiditycongenial", "hideSeries", "Online Sensor");
      //Show current series even if it is hidden
      ApexCharts.exec("humiditycongenial", "showSeries", "Meteo Blue");
    } else if (e.target.id == "Online") {
      this.setState({
        kfsencolor: "#94de71",
        kfdcolor: "#151714",
        kfowcolor: "#151714",
        kfallcolor: "#151714",
        kfmetcolor: "#151714",
        kfecmwfcolor: "#151714",
        kfnasacolor: "#151714",
        kfaiccolor: "#151714",
      });
      ApexCharts.exec("tmaxcongenial", "hideSeries", "ECMWF");
      ApexCharts.exec("tmaxcongenial", "hideSeries", "Dark Sky");
      ApexCharts.exec("tmaxcongenial", "hideSeries", "OpenWeather");
      ApexCharts.exec("tmaxcongenial", "hideSeries", "AIC");
      ApexCharts.exec("tmaxcongenial", "hideSeries", "Meteo Blue");
      ApexCharts.exec("tmaxcongenial", "hideSeries", "Nasa Power Larc");
      //Show current series even if it is hidden
      ApexCharts.exec("tmaxcongenial", "showSeries", "Online Sensor");

      ApexCharts.exec("humiditycongenial", "hideSeries", "ECMWF");
      ApexCharts.exec("humiditycongenial", "hideSeries", "Dark Sky");
      ApexCharts.exec("humiditycongenial", "hideSeries", "OpenWeather");
      ApexCharts.exec("humiditycongenial", "hideSeries", "AIC");
      ApexCharts.exec("humiditycongenial", "hideSeries", "Meteo Blue");
      ApexCharts.exec("humiditycongenial", "hideSeries", "Nasa Power Larc");
      //Show current series even if it is hidden
      ApexCharts.exec("humiditycongenial", "showSeries", "Online Sensor");
    } else if (e.target.id == "All") {
      this.setState({
        kfallcolor: "#94de71",
        kfdcolor: "#151714",
        kfowcolor: "#151714",
        kfsencolor: "#151714",
        kfmetcolor: "#151714",
        kfecmwfcolor: "#151714",
        kfnasacolor: "#151714",
        kfaiccolor: "#151714",
      });
      ApexCharts.exec("tmaxcongenial", "showSeries", "ECMWF");
      ApexCharts.exec("tmaxcongenial", "showSeries", "AIC");
      ApexCharts.exec("tmaxcongenial", "showSeries", "Meteo Blue");
      ApexCharts.exec("tmaxcongenial", "showSeries", "Nasa Power Larc");
      ApexCharts.exec("tmaxcongenial", "showSeries", "Online Sensor");

      ApexCharts.exec("humiditycongenial", "showSeries", "ECMWF");
      ApexCharts.exec("humiditycongenial", "showSeries", "AIC");
      ApexCharts.exec("humiditycongenial", "showSeries", "Meteo Blue");
      ApexCharts.exec("humiditycongenial", "showSeries", "Nasa Power Larc");
      ApexCharts.exec("humiditycongenial", "showSeries", "Online Sensor");
    }
  }

  handleKfilter(e) {
    if (this.state.peril == "Deficit Rainfall") {
      if (e.target.id == "aic") {
        this.setState({
          kfaiccolor: "#94de71",
          kfdcolor: "#151714",
          kfowcolor: "#151714",
          kfallcolor: "#151714",
          kfsencolor: "#151714",
          kfmetcolor: "#151714",
          kfecmwfcolor: "#151714",
          kfnasacolor: "#151714",
        });
        ApexCharts.exec("area-datetime", "hideSeries", "ECMWF");
        ApexCharts.exec("area-datetime", "hideSeries", "Meteo Blue");
        ApexCharts.exec("area-datetime", "hideSeries", "Nasa Power Larc");
        // ApexCharts.exec('area-datetime', 'hideSeries', 'Online Sensor');
        //Show current series even if it is hidden
        ApexCharts.exec("area-datetime", "showSeries", "AIC");
      } else if (e.target.id == "nasa") {
        this.setState({
          kfnasacolor: "#94de71",
          kfdcolor: "#151714",
          kfowcolor: "#151714",
          kfallcolor: "#151714",
          kfsencolor: "#151714",
          kfmetcolor: "#151714",
          kfecmwfcolor: "#151714",
          kfaiccolor: "#151714",
        });
        ApexCharts.exec("area-datetime", "hideSeries", "ECMWF");

        ApexCharts.exec("area-datetime", "hideSeries", "AIC");
        ApexCharts.exec("area-datetime", "hideSeries", "Meteo Blue");
        // ApexCharts.exec('area-datetime', 'hideSeries', 'Online Sensor');
        //Show current series even if it is hidden
        ApexCharts.exec("area-datetime", "showSeries", "Nasa Power Larc");
      } else if (e.target.id == "ecmwf") {
        this.setState({
          kfecmwfcolor: "#94de71",
          kfdcolor: "#151714",
          kfowcolor: "#151714",
          kfallcolor: "#151714",
          kfsencolor: "#151714",
          kfmetcolor: "#151714",
          kfnasacolor: "#151714",
          kfaiccolor: "#151714",
        });

        ApexCharts.exec("area-datetime", "hideSeries", "AIC");
        ApexCharts.exec("area-datetime", "hideSeries", "Meteo Blue");
        ApexCharts.exec("area-datetime", "hideSeries", "Nasa Power Larc");
        // ApexCharts.exec('area-datetime', 'hideSeries', 'Online Sensor');
        //Show current series even if it is hidden
        ApexCharts.exec("area-datetime", "showSeries", "ECMWF");
      } else if (e.target.id == "mb") {
        this.setState({
          kfmetcolor: "#94de71",
          kfdcolor: "#151714",
          kfowcolor: "#151714",
          kfallcolor: "#151714",
          kfsencolor: "#151714",
          kfecmwfcolor: "#151714",
          kfnasacolor: "#151714",
          kfaiccolor: "#151714",
        });
        ApexCharts.exec("area-datetime", "hideSeries", "ECMWF");
        ApexCharts.exec("area-datetime", "hideSeries", "AIC");
        ApexCharts.exec("area-datetime", "hideSeries", "Nasa Power Larc");
        // ApexCharts.exec('area-datetime', 'hideSeries', 'Online Sensor');
        //Show current series even if it is hidden
        ApexCharts.exec("area-datetime", "showSeries", "Meteo Blue");
      } else if (e.target.id == "Online") {
        this.setState({
          kfsencolor: "#94de71",
          kfdcolor: "#151714",
          kfowcolor: "#151714",
          kfallcolor: "#151714",
          kfmetcolor: "#151714",
          kfecmwfcolor: "#151714",
          kfnasacolor: "#151714",
          kfaiccolor: "#151714",
        });
        ApexCharts.exec("area-datetime", "hideSeries", "ECMWF");
        ApexCharts.exec("area-datetime", "hideSeries", "AIC");
        ApexCharts.exec("area-datetime", "hideSeries", "Meteo Blue");
        ApexCharts.exec("area-datetime", "hideSeries", "Nasa Power Larc");
        //Show current series even if it is hidden
        ApexCharts.exec("area-datetime", "showSeries", "Online Sensor");
      } else if (e.target.id == "All") {
        this.setState({
          kfallcolor: "#94de71",
          kfdcolor: "#151714",
          kfowcolor: "#151714",
          kfsencolor: "#151714",
          kfmetcolor: "#151714",
          kfecmwfcolor: "#151714",
          kfnasacolor: "#151714",
          kfaiccolor: "#151714",
        });
        ApexCharts.exec("area-datetime", "showSeries", "ECMWF");
        ApexCharts.exec("area-datetime", "showSeries", "AIC");
        ApexCharts.exec("area-datetime", "showSeries", "Meteo Blue");
        ApexCharts.exec("area-datetime", "showSeries", "Nasa Power Larc");
        // ApexCharts.exec('area-datetime', 'showSeries', 'Online Sensor');
      }
    } else {
      if (e.target.id == "ds") {
        this.setState({
          kfdcolor: "#94de71",
          kfowcolor: "#151714",
          kfallcolor: "#151714",
          kfsencolor: "#151714",
          kfmetcolor: "#151714",
          kfecmwfcolor: "#151714",
          kfnasacolor: "#151714",
          kfaiccolor: "#151714",
        });
        ApexCharts.exec("area-datetime", "hideSeries", "ECMWF");
        ApexCharts.exec("area-datetime", "hideSeries", "OpenWeather");
        ApexCharts.exec("area-datetime", "hideSeries", "AIC");
        ApexCharts.exec("area-datetime", "hideSeries", "Meteo Blue");
        ApexCharts.exec("area-datetime", "hideSeries", "Nasa Power Larc");
        ApexCharts.exec("area-datetime", "hideSeries", "Online Sensor");
        //Show current series even if it is hidden
        ApexCharts.exec("area-datetime", "showSeries", "Dark Sky");
      } else if (e.target.id == "ow") {
        this.setState({
          kfowcolor: "#94de71",
          kfdcolor: "#151714",
          kfallcolor: "#151714",
          kfsencolor: "#151714",
          kfmetcolor: "#151714",
          kfecmwfcolor: "#151714",
          kfnasacolor: "#151714",
          kfaiccolor: "#151714",
        });
        ApexCharts.exec("area-datetime", "hideSeries", "ECMWF");
        ApexCharts.exec("area-datetime", "hideSeries", "Dark Sky");
        ApexCharts.exec("area-datetime", "hideSeries", "AIC");
        ApexCharts.exec("area-datetime", "hideSeries", "Meteo Blue");
        ApexCharts.exec("area-datetime", "hideSeries", "Nasa Power Larc");
        ApexCharts.exec("area-datetime", "hideSeries", "Online Sensor");
        //Show current series even if it is hidden
        ApexCharts.exec("area-datetime", "showSeries", "OpenWeather");
      } else if (e.target.id == "aic") {
        this.setState({
          kfaiccolor: "#94de71",
          kfdcolor: "#151714",
          kfowcolor: "#151714",
          kfallcolor: "#151714",
          kfsencolor: "#151714",
          kfmetcolor: "#151714",
          kfecmwfcolor: "#151714",
          kfnasacolor: "#151714",
        });
        ApexCharts.exec("area-datetime", "hideSeries", "ECMWF");
        ApexCharts.exec("area-datetime", "hideSeries", "Dark Sky");
        ApexCharts.exec("area-datetime", "hideSeries", "OpenWeather");
        ApexCharts.exec("area-datetime", "hideSeries", "Meteo Blue");
        ApexCharts.exec("area-datetime", "hideSeries", "Nasa Power Larc");
        ApexCharts.exec("area-datetime", "hideSeries", "Online Sensor");
        //Show current series even if it is hidden
        ApexCharts.exec("area-datetime", "showSeries", "AIC");
      } else if (e.target.id == "nasa") {
        this.setState({
          kfnasacolor: "#94de71",
          kfdcolor: "#151714",
          kfowcolor: "#151714",
          kfallcolor: "#151714",
          kfsencolor: "#151714",
          kfmetcolor: "#151714",
          kfecmwfcolor: "#151714",
          kfaiccolor: "#151714",
        });
        ApexCharts.exec("area-datetime", "hideSeries", "ECMWF");
        ApexCharts.exec("area-datetime", "hideSeries", "Dark Sky");
        ApexCharts.exec("area-datetime", "hideSeries", "OpenWeather");
        ApexCharts.exec("area-datetime", "hideSeries", "AIC");
        ApexCharts.exec("area-datetime", "hideSeries", "Meteo Blue");
        ApexCharts.exec("area-datetime", "hideSeries", "Online Sensor");
        //Show current series even if it is hidden
        ApexCharts.exec("area-datetime", "showSeries", "Nasa Power Larc");
      } else if (e.target.id == "ecmwf") {
        this.setState({
          kfecmwfcolor: "#94de71",
          kfdcolor: "#151714",
          kfowcolor: "#151714",
          kfallcolor: "#151714",
          kfsencolor: "#151714",
          kfmetcolor: "#151714",
          kfnasacolor: "#151714",
          kfaiccolor: "#151714",
        });

        ApexCharts.exec("area-datetime", "hideSeries", "Dark Sky");
        ApexCharts.exec("area-datetime", "hideSeries", "OpenWeather");
        ApexCharts.exec("area-datetime", "hideSeries", "AIC");
        ApexCharts.exec("area-datetime", "hideSeries", "Meteo Blue");
        ApexCharts.exec("area-datetime", "hideSeries", "Nasa Power Larc");
        ApexCharts.exec("area-datetime", "hideSeries", "Online Sensor");
        //Show current series even if it is hidden
        ApexCharts.exec("area-datetime", "showSeries", "ECMWF");
      } else if (e.target.id == "mb") {
        this.setState({
          kfmetcolor: "#94de71",
          kfdcolor: "#151714",
          kfowcolor: "#151714",
          kfallcolor: "#151714",
          kfsencolor: "#151714",
          kfecmwfcolor: "#151714",
          kfnasacolor: "#151714",
          kfaiccolor: "#151714",
        });
        ApexCharts.exec("area-datetime", "hideSeries", "ECMWF");
        ApexCharts.exec("area-datetime", "hideSeries", "Dark Sky");
        ApexCharts.exec("area-datetime", "hideSeries", "OpenWeather");
        ApexCharts.exec("area-datetime", "hideSeries", "AIC");
        ApexCharts.exec("area-datetime", "hideSeries", "Nasa Power Larc");
        ApexCharts.exec("area-datetime", "hideSeries", "Online Sensor");
        //Show current series even if it is hidden
        ApexCharts.exec("area-datetime", "showSeries", "Meteo Blue");
      } else if (e.target.id == "Online") {
        this.setState({
          kfsencolor: "#94de71",
          kfdcolor: "#151714",
          kfowcolor: "#151714",
          kfallcolor: "#151714",
          kfmetcolor: "#151714",
          kfecmwfcolor: "#151714",
          kfnasacolor: "#151714",
          kfaiccolor: "#151714",
        });
        ApexCharts.exec("area-datetime", "hideSeries", "ECMWF");
        ApexCharts.exec("area-datetime", "hideSeries", "Dark Sky");
        ApexCharts.exec("area-datetime", "hideSeries", "OpenWeather");
        ApexCharts.exec("area-datetime", "hideSeries", "AIC");
        ApexCharts.exec("area-datetime", "hideSeries", "Meteo Blue");
        ApexCharts.exec("area-datetime", "hideSeries", "Nasa Power Larc");
        //Show current series even if it is hidden
        ApexCharts.exec("area-datetime", "showSeries", "Online Sensor");
      } else if (e.target.id == "All") {
        this.setState({
          kfallcolor: "#94de71",
          kfdcolor: "#151714",
          kfowcolor: "#151714",
          kfsencolor: "#151714",
          kfmetcolor: "#151714",
          kfecmwfcolor: "#151714",
          kfnasacolor: "#151714",
          kfaiccolor: "#151714",
        });
        ApexCharts.exec("area-datetime", "showSeries", "ECMWF");
        ApexCharts.exec("area-datetime", "showSeries", "Dark Sky");
        ApexCharts.exec("area-datetime", "showSeries", "OpenWeather");
        ApexCharts.exec("area-datetime", "showSeries", "AIC");
        ApexCharts.exec("area-datetime", "showSeries", "Meteo Blue");
        ApexCharts.exec("area-datetime", "showSeries", "Nasa Power Larc");
        ApexCharts.exec("area-datetime", "showSeries", "Online Sensor");
      }
    }
  }

  findMax(one, two, three, four, five, six) {
    const largest = Math.max(one, two, three, four, five, six);
    let per = (largest * 10) / 100;
    let retn = largest + per;
    let fvalue = Math.floor(retn);
    let top = Math.ceil((fvalue + 1) / 10) * 10;
    let final = parseInt(fvalue / 10, 10) + 1 * 10;

    return top;
  }

  findpercentage(val) {
    let per = (val * 10) / 100;
    return val + per;
  }
  async fetchseason() {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + localStorage.getItem("token"),
      },
    };

    var seasonData = await fetch(
      process.env.REACT_APP_APIEND + `smart_crop/term-sheet-season-crop`,
      requestOptions
    ).then((res) => {
      return res.json();
    });

    var newseasonData = [];
    {
      seasonData.result.map(function (item, index, data) {
        newseasonData.push(Object.keys(item));
      });
    }
    // console.log("NEW DATA", newseasonData);
    this.setState({
      seasonData: newseasonData,
    });
  }
  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  componentDidMount() {
    validate_Token();
    const user_role = Permission();
    this.child.fetchtermsheetdata();
    this.fetchseason();
    if (user_role[0] == "DR") {
      this.setState({
        kb4: true,
      });
    }

    if (user_role[0] == "ADM") {
      this.setState({
        kb4: true,
      });
    }

    if (user_role[0] === "KB") {
      if (user_role[1] == "Pattanchery") {
        this.setState({
          kb1: false,
          kb2: false,
          kb3: false,
          kb4: false,
          kb5: true,
          kb6: false,
          kb7: false,
          latnew: 10.72918673,
          longnew: 76.78622876,
          ddloc: "Pattanchery",
          selloc: "10.64777998,76.73270522",
        });
      } else if (user_role[1] == "Chittur-Thathamangalam") {
        this.setState({
          kb1: true,
          kb2: false,
          kb3: false,
          kb4: false,
          kb5: false,
          kb6: false,
          kb7: false,
          latnew: 10.69300691,
          longnew: 76.72595507,
          ddloc: "Chittur-Thathamangalam",
          selloc: "10.69300691,76.72595507",
        });
      } else if (user_role[1] == "Nallepilly") {
        this.setState({
          kb1: false,
          kb2: false,
          kb3: false,
          kb4: true,
          kb5: false,
          kb6: false,
          kb7: false,
          latnew: 10.72918673,
          longnew: 76.78622876,
          ddloc: "Nallepilly",
          selloc: "10.72918673,76.78622876",
        });
      } else if (user_role[1] == "Kozhinjampara") {
        this.setState({
          kb1: false,
          kb2: false,
          kb3: true,
          kb4: false,
          kb5: false,
          kb6: false,
          kb7: false,
          latnew: 10.73595019,
          longnew: 76.82999565,
          ddloc: "Kozhinjampara",
          selloc: "10.738,76.834",
        });
      } else if (user_role[1] == "Eruthenpathy") {
        this.setState({
          kb1: false,
          kb2: true,
          kb3: false,
          kb4: false,
          kb5: false,
          kb6: false,
          kb7: false,
          latnew: 10.74866619,
          longnew: 76.85879364,
          ddloc: "Eruthenpathy",
          selloc: "10.74866619,76.85879364",
        });
      } else if (user_role[1] == "Perumatty") {
        this.setState({
          kb1: false,
          kb2: false,
          kb3: false,
          kb4: false,
          kb5: false,
          kb6: true,
          kb7: false,
          latnew: 10.662,
          longnew: 76.787,
          ddloc: "Perumatty",
          selloc: "10.662,76.787",
        });
      } else if (user_role[1] == "Vadakarapathy") {
        this.setState({
          kb1: false,
          kb2: false,
          kb3: false,
          kb4: false,
          kb5: false,
          kb6: false,
          kb7: true,
          latnew: 10.81,
          longnew: 76.846,
          ddloc: "Vadakarapathy",
          selloc: "10.807,76.846",
        });
      }
    }

    let chart_values = [];
    this.setState({
      hightemp: true,
      deficitrain: false,
      excessrain: false,
      dryspell: false,
      diseasecongenial: false,
      showPhaseselector: true,
      showCongenial: false,
      isGetdatadisabled: true,
      showLoader: true,
      showfilter: false,
    });

    var objthis = this;
    $.ajax({
      //api call to fetch data belongs to darksky
      url:
        "https://demo.misteo.co:5007/weathertrend?arg1=tm&dp=darksky&lat=" +
        objthis.state.latnew +
        "&lng=" +
        objthis.state.longnew,
      dataType: "json",
      type: "GET",
      success: function (responseds) {
        var dsMaxval = responseds[0].maximumvalue;
        let dataDarsky = objthis.getValues(responseds, "tm", "darksky");
        chart_values.push(dataDarsky);
        $.ajax({
          //api call to fetch data belongs to open weather
          url:
            "https://demo.misteo.co:5007/weathertrend?arg1=tm&dp=openweather&lat=" +
            objthis.state.latnew +
            "&lng=" +
            objthis.state.longnew,
          dataType: "json",
          type: "GET",
          success: function (responseow) {
            var owMaxval = responseow[0].maximumvalue;
            let dataOpenweather = objthis.getValues(
              responseow,
              "tm",
              "openweather"
            );
            chart_values.push(dataOpenweather);
            $.ajax({
              //api call to fetch data belongs to dtn
              url:
                "https://demo.misteo.co:5007/weathertrend?arg1=tm&dp=aic&lat=" +
                objthis.state.latnew +
                "&lng=" +
                objthis.state.longnew,
              dataType: "json",
              type: "GET",
              success: function (responsedtn) {
                var dtnMaxval = responsedtn[0].maximumvalue;
                let dataaic = objthis.getValues(responsedtn, "tm", "aic");
                chart_values.push(dataaic);

                $.ajax({
                  //api call to fetch data belongs to dtn
                  url:
                    "https://demo.misteo.co:5007/weathertrend?arg1=tm&dp=MeteoBlue&lat=" +
                    objthis.state.latnew +
                    "&lng=" +
                    objthis.state.longnew,
                  dataType: "json",
                  type: "GET",
                  success: function (responsemeteoblue) {
                    var meteomax = responsemeteoblue[0].maximumvalue;
                    let datameteoblue = objthis.getValues(
                      responsemeteoblue,
                      "tm",
                      "MeteoBlue"
                    );
                    chart_values.push(datameteoblue);
                    $.ajax({
                      //api call to fetch data belongs to dtn
                      url:
                        "https://demo.misteo.co:5007/weathertrend?arg1=tm&dp=ECMWF&lat=" +
                        objthis.state.latnew +
                        "&lng=" +
                        objthis.state.longnew,
                      dataType: "json",
                      type: "GET",
                      success: function (responseecmwf) {
                        var ecmwfmax = responseecmwf[0].maximumvalue;
                        let dataecmwf = objthis.getValues(
                          responseecmwf,
                          "tm",
                          "ECMWF"
                        );
                        chart_values.push(dataecmwf);

                        $.ajax({
                          //api call to fetch data belongs to dtn
                          url:
                            "https://demo.misteo.co:5007/weathertrend?arg1=tm&dp=Nasa Power&lat=" +
                            objthis.state.latnew +
                            "&lng=" +
                            objthis.state.longnew,
                          dataType: "json",
                          type: "GET",
                          success: function (responsenasa) {
                            var nasamax = responsenasa[0].maximumvalue;
                            let datanasa = objthis.getValues(
                              responsenasa,
                              "tm",
                              "Nasa Power"
                            );
                            chart_values.push(datanasa);

                            $.ajax({
                              //api call to fetch data belongs to open weather
                              url:
                                "https://dev.misteo.co:5004/historical?var=tm&lat=" +
                                objthis.state.latnew +
                                "&lng=" +
                                objthis.state.longnew,
                              dataType: "json",
                              type: "GET",
                              success: function (responseonline_sensor) {
                                let datasensor = objthis.getValues(
                                  responseonline_sensor,
                                  "tm",
                                  "Online Sensor"
                                );
                                let maxvalue = objthis.findMax(
                                  dsMaxval,
                                  owMaxval,
                                  dtnMaxval,
                                  meteomax,
                                  ecmwfmax,
                                  nasamax
                                );
                                maxvalue = Math.round(maxvalue / 10) * 10;
                                chart_values.push(datasensor);
                                objthis.setState({
                                  options: {
                                    colors: [
                                      "#2E93fA",
                                      "#66DA26",
                                      "#FF4500",
                                      "#ff05e6",
                                      "#FF9800",
                                      "#9c59ff",
                                      "#0b6b75",
                                    ],
                                    chart: {
                                      id: "area-datetime",
                                      type: "area",
                                      height: 350,
                                      stacked: false,
                                      zoom: {
                                        autoScaleYaxis: true,
                                      },
                                      toolbar: {
                                        show: true,
                                        tools: {
                                          download: false,
                                        },
                                      },
                                    },
                                    stroke: {
                                      width: 1,
                                    },
                                    xaxis: {
                                      type: "datetime",
                                      tickAmount: 6,
                                      min: 1590949800000,
                                      max: 1602700200000,
                                    },
                                    tooltip: {
                                      x: {
                                        format: "dd MMM yyyy",
                                      },
                                    },
                                    yaxis: {
                                      min: 0,
                                      max: 50,
                                      // labels: {
                                      //     "formatter": function (val) {
                                      //         return val.toFixed(2)
                                      //     }
                                      // },
                                    },
                                    legend: {
                                      show: false,
                                    },
                                  },
                                });
                                objthis.setState({
                                  series: chart_values,
                                  showchart: true,
                                  showtable: true,
                                  showLoader: false,
                                  isGetdatadisabled: false,
                                });
                                ApexCharts.exec(
                                  "area-datetime",
                                  "removeAnnotation",
                                  "phase1"
                                );
                                ApexCharts.exec(
                                  "area-datetime",
                                  "removeAnnotation",
                                  "phase2"
                                );
                                ApexCharts.exec(
                                  "area-datetime",
                                  "removeAnnotation",
                                  "phase3"
                                );
                                ApexCharts.exec(
                                  "area-datetime",
                                  "removeAnnotation",
                                  "phase4"
                                );
                                ApexCharts.exec(
                                  "area-datetime",
                                  "removeAnnotation",
                                  "excessrain"
                                );
                                ApexCharts.exec(
                                  "area-datetime",
                                  "removeAnnotation",
                                  "dryspell"
                                );

                                // chart.updateSeries(objthis.state.series)
                              },
                            });
                          },
                        });
                      },
                    });
                  },
                });
              },
            });
          },
        });
      },
    });
  }

  async handleSubmit(event) {
    this.child.fetchtermsheetdata();
    this.setState({
      ddloc: this.state.dloc,
      ddperil: this.state.peril,
      ddseason: this.state.season,
    });
    let latlng = this.state.location;
    let latlngarray = latlng.split(",");
    let latitude = parseFloat(latlngarray[0]);
    let longitude = parseFloat(latlngarray[1]);
    let apiParam = this.getPrameter(this.state.peril);
    let chart_values = [];
    let chart_values2 = [];
    let dataDarsky = [];
    let dataOpenweather = [];
    let datameteoblue = [];
    let dataecmwf = [];
    let datanasa = [];
    let dataaic = [];
    let datasensor = [];
    this.setState({
      showWaiting: false,
      showchart: false,
      showCongenial: false,
      showLoader: true,
      isGetdatadisabled: true,
      selectedValue: "",
      kfdcolor: "#151714",
      kfowcolor: "#151714",
      kfallcolor: "#151714",
      kfsencolor: "#151714",
      kfmetcolor: "#151714",
      kfecmwfcolor: "#151714",
      kfnasacolor: "#151714",
      kfaiccolor: "#151714",
      showfilter: true,
      showdeficit: true,
    });

    if (this.state.peril === "High Temperature") {
      var objthis = this;
      this.setState({
        hightemp: true,
        deficitrain: false,
        excessrain: false,
        dryspell: false,
        diseasecongenial: false,
        showPhaseselector: true,
        showCongenial: false,
        showfilter: false,
      });

      $.ajax({
        //api call to fetch data belongs to darksky
        url:
          "https://demo.misteo.co:5007/weathertrend?arg1=" +
          apiParam +
          "&dp=darksky&lat=" +
          latitude +
          "&lng=" +
          longitude,
        dataType: "json",
        type: "GET",
        success: function (responseds) {
          var dsMaxval = responseds[0].maximumvalue;
          dataDarsky = objthis.getValues(responseds, apiParam, "darksky");
          chart_values.push(dataDarsky);
          $.ajax({
            //api call to fetch data belongs to open weather
            url:
              "https://demo.misteo.co:5007/weathertrend?arg1=" +
              apiParam +
              "&dp=openweather&lat=" +
              latitude +
              "&lng=" +
              longitude,
            dataType: "json",
            type: "GET",
            success: function (responseow) {
              var owMaxval = responseow[0].maximumvalue;
              dataOpenweather = objthis.getValues(
                responseow,
                apiParam,
                "openweather"
              );
              chart_values.push(dataOpenweather);

              $.ajax({
                //api call to fetch data belongs to dtn
                url:
                  "https://demo.misteo.co:5007/weathertrend?arg1=" +
                  apiParam +
                  "&dp=aic&lat=" +
                  latitude +
                  "&lng=" +
                  longitude,
                dataType: "json",
                type: "GET",
                success: function (responsedtn) {
                  var dtnMaxval = responsedtn[0].maximumvalue;
                  dataaic = objthis.getValues(responsedtn, apiParam, "aic");
                  chart_values.push(dataaic);

                  $.ajax({
                    //api call to fetch data belongs to dtn
                    url:
                      "https://demo.misteo.co:5007/weathertrend?arg1=" +
                      apiParam +
                      "&dp=MeteoBlue&lat=" +
                      latitude +
                      "&lng=" +
                      longitude,
                    dataType: "json",
                    type: "GET",
                    success: function (responsemeteoblue) {
                      var meteomax = responsemeteoblue[0].maximumvalue;
                      datameteoblue = objthis.getValues(
                        responsemeteoblue,
                        apiParam,
                        "MeteoBlue"
                      );
                      chart_values.push(datameteoblue);
                      $.ajax({
                        //api call to fetch data belongs to dtn
                        url:
                          "https://demo.misteo.co:5007/weathertrend?arg1=" +
                          apiParam +
                          "&dp=ECMWF&lat=" +
                          latitude +
                          "&lng=" +
                          longitude,
                        dataType: "json",
                        type: "GET",
                        success: function (responseecmwf) {
                          var ecmwfmax = responseecmwf[0].maximumvalue;
                          dataecmwf = objthis.getValues(
                            responseecmwf,
                            apiParam,
                            "ECMWF"
                          );
                          chart_values.push(dataecmwf);

                          $.ajax({
                            //api call to fetch data belongs to dtn
                            url:
                              "https://demo.misteo.co:5007/weathertrend?arg1=" +
                              apiParam +
                              "&dp=Nasa Power&lat=" +
                              latitude +
                              "&lng=" +
                              longitude,
                            dataType: "json",
                            type: "GET",
                            success: function (responsenasa) {
                              var nasamax = responsenasa[0].maximumvalue;
                              datanasa = objthis.getValues(
                                responsenasa,
                                apiParam,
                                "Nasa Power"
                              );
                              chart_values.push(datanasa);

                              $.ajax({
                                //api call to fetch data belongs to open weather
                                url:
                                  "https://dev.misteo.co:5004/historical?var=" +
                                  apiParam +
                                  "&lat=" +
                                  latitude +
                                  "&lng=" +
                                  longitude,
                                dataType: "json",
                                type: "GET",
                                success: function (responseonline_sensor) {
                                  datasensor = objthis.getValues(
                                    responseonline_sensor,
                                    apiParam,
                                    "Online Sensor"
                                  );
                                  let maxvalue = objthis.findMax(
                                    dsMaxval,
                                    owMaxval,
                                    dtnMaxval,
                                    meteomax,
                                    ecmwfmax,
                                    nasamax
                                  );
                                  maxvalue = Math.round(maxvalue / 10) * 10;
                                  chart_values.push(datasensor);
                                  objthis.setState({
                                    options: {
                                      colors: [
                                        "#2E93fA",
                                        "#66DA26",
                                        "#FF4500",
                                        "#ff05e6",
                                        "#FF9800",
                                        "#9c59ff",
                                        "#0b6b75",
                                      ],
                                      chart: {
                                        id: "area-datetime",
                                        type: "area",
                                        height: 350,
                                        stacked: false,
                                        zoom: {
                                          autoScaleYaxis: true,
                                        },
                                        toolbar: {
                                          show: true,
                                          tools: {
                                            download: false,
                                          },
                                        },
                                      },
                                      stroke: {
                                        width: 1,
                                      },
                                      xaxis: {
                                        type: "datetime",
                                        tickAmount: 6,
                                        min: 1590949800000,
                                        max: 1602700200000,
                                      },
                                      tooltip: {
                                        x: {
                                          format: "dd MMM yyyy",
                                        },
                                      },
                                      yaxis: {
                                        min: 0,
                                        max: 50,
                                        // labels: {
                                        //     "formatter": function (val) {
                                        //         return val.toFixed(2)
                                        //     }
                                        // },
                                      },
                                      legend: {
                                        show: false,
                                      },
                                    },
                                  });
                                  objthis.setState({
                                    series: chart_values,
                                    showchart: true,
                                    showtable: true,
                                    showLoader: false,
                                    isGetdatadisabled: false,
                                  });
                                  ApexCharts.exec(
                                    "area-datetime",
                                    "removeAnnotation",
                                    "phase1"
                                  );
                                  ApexCharts.exec(
                                    "area-datetime",
                                    "removeAnnotation",
                                    "phase2"
                                  );
                                  ApexCharts.exec(
                                    "area-datetime",
                                    "removeAnnotation",
                                    "phase3"
                                  );
                                  ApexCharts.exec(
                                    "area-datetime",
                                    "removeAnnotation",
                                    "phase4"
                                  );
                                  ApexCharts.exec(
                                    "area-datetime",
                                    "removeAnnotation",
                                    "excessrain"
                                  );
                                  ApexCharts.exec(
                                    "area-datetime",
                                    "removeAnnotation",
                                    "dryspell"
                                  );

                                  // chart.updateSeries(objthis.state.series)
                                },
                              });
                            },
                          });
                        },
                      });
                    },
                  });
                },
              });
            },
          });
        },
      });
    } else if (this.state.peril === "Excess Rainfall") {
      this.setState({
        hightemp: false,
        deficitrain: false,
        excessrain: true,
        dryspell: false,
        diseasecongenial: false,
        showPhaseselector: false,
        showCongenial: false,
        showfilter: false,
      });

      var objthis = this;
      $.ajax({
        //api call to fetch data belongs to darksky
        url:
          "https://demo.misteo.co:5007/weathertrend?arg1=" +
          apiParam +
          "&dp=darksky&lat=" +
          latitude +
          "&lng=" +
          longitude,
        dataType: "json",
        type: "GET",
        success: function (responseds) {
          let dataDarsky = objthis.getValues(responseds, apiParam, "darksky");
          chart_values.push(dataDarsky);
          $.ajax({
            //api call to fetch data belongs to open weather
            url:
              "https://demo.misteo.co:5007/weathertrend?arg1=" +
              apiParam +
              "&dp=openweather&lat=" +
              latitude +
              "&lng=" +
              longitude,
            dataType: "json",
            type: "GET",
            success: function (responseow) {
              var owMaxval = responseow[0].maximumvalue;
              let dataOpenweather = objthis.getValues(
                responseow,
                apiParam,
                "openweather"
              );
              chart_values.push(dataOpenweather);

              $.ajax({
                //api call to fetch data belongs to dtn
                url:
                  "https://demo.misteo.co:5007/weathertrend?arg1=pp&dp=AIC&lat=" +
                  latitude +
                  "&lng=" +
                  longitude,
                dataType: "json",
                type: "GET",
                success: function (responsedtn) {
                  var dtnMaxval = responsedtn[0].maximumvalue;
                  let dataaic = objthis.getValues(responsedtn, "pp", "aic");
                  chart_values.push(dataaic);
                  $.ajax({
                    //api call to fetch data belongs to dtn
                    url:
                      "https://demo.misteo.co:5007/weathertrend?arg1=pp&dp=MeteoBlue&lat=" +
                      latitude +
                      "&lng=" +
                      longitude,
                    dataType: "json",
                    type: "GET",
                    success: function (responsemeteoblue) {
                      var meteomax = responsemeteoblue[0].maximumvalue;
                      let datameteoblue = objthis.getValues(
                        responsemeteoblue,
                        "pp",
                        "MeteoBlue"
                      );
                      chart_values.push(datameteoblue);
                      $.ajax({
                        //api call to fetch data belongs to dtn
                        url:
                          "https://demo.misteo.co:5007/weathertrend?arg1=pp&dp=ECMWF&lat=" +
                          latitude +
                          "&lng=" +
                          longitude,
                        dataType: "json",
                        type: "GET",
                        success: function (responseecmwf) {
                          var ecmwfmax = responseecmwf[0].maximumvalue;
                          let dataecmwf = objthis.getValues(
                            responseecmwf,
                            "pp",
                            "ECMWF"
                          );
                          chart_values.push(dataecmwf);
                          $.ajax({
                            //api call to fetch data belongs to dtn
                            url:
                              "https://demo.misteo.co:5007/weathertrend?arg1=pp&dp=Nasa Power&lat=" +
                              latitude +
                              "&lng=" +
                              longitude,
                            dataType: "json",
                            type: "GET",
                            success: function (responsenasa) {
                              var nasamax = responsenasa[0].maximumvalue;
                              let datanasa = objthis.getValues(
                                responsenasa,
                                "pp",
                                "Nasa Power"
                              );
                              chart_values.push(datanasa);
                              $.ajax({
                                //api call to fetch data belongs to open weather
                                url:
                                  "https://dev.misteo.co:5004/historical?var=" +
                                  apiParam +
                                  "&lat=" +
                                  latitude +
                                  "&lng=" +
                                  longitude,
                                dataType: "json",
                                type: "GET",
                                success: function (responseonline_sensor) {
                                  let datasensor = objthis.getValues(
                                    responseonline_sensor,
                                    apiParam,
                                    "Online Sensor"
                                  );
                                  chart_values.push(datasensor);

                                  objthis.setState({
                                    options: {
                                      colors: [
                                        "#2E93fA",
                                        "#66DA26",
                                        "#FF4500",
                                        "#ff05e6",
                                        "#FF9800",
                                        "#9c59ff",
                                        "#0b6b75",
                                      ],
                                      chart: {
                                        id: "area-datetime",
                                        type: "area",
                                        height: 350,
                                        zoom: {
                                          autoScaleYaxis: true,
                                        },
                                        toolbar: {
                                          show: true,
                                          tools: {
                                            download: false,
                                          },
                                        },
                                      },
                                      stroke: {
                                        width: 1,
                                      },
                                      xaxis: {
                                        type: "datetime",
                                        labels: {
                                          datetimeUTC: false,
                                        },
                                        min: 1590949800000,
                                        max: 1602700200000,
                                      },
                                      yaxis: {
                                        show: true,
                                        min: 0,
                                        max: 100,
                                        // "labels": {
                                        //     "formatter": function (val) {
                                        //         return val.toFixed(2)
                                        //     }
                                        // }
                                      },
                                      tooltip: {
                                        x: {
                                          format: "dd MMM yyyy",
                                        },
                                      },
                                      annotations: {
                                        xaxis: [
                                          {
                                            id: "excessrain",
                                            x: 1596738600000,
                                            x2: 1596994200000,
                                            fillColor: "#B3F7CA",
                                            opacity: 0.6,
                                            label: {
                                              borderColor: "#B3F7CA",
                                              style: {
                                                fontSize: "10px",
                                                color: "#fff",
                                                background: "#00E396",
                                              },
                                              offsetY: -10,
                                              text: "STRIKE",
                                            },
                                          },
                                        ],
                                      },
                                      legend: {
                                        show: false,
                                      },
                                    },
                                  });
                                  objthis.setState({
                                    series: chart_values,
                                    showchart: true,
                                    showtable: true,
                                    showLoader: false,
                                    isGetdatadisabled: false,
                                  });
                                  ApexCharts.exec(
                                    "area-datetime",
                                    "removeAnnotation",
                                    "phase1"
                                  );
                                  ApexCharts.exec(
                                    "area-datetime",
                                    "removeAnnotation",
                                    "phase2"
                                  );
                                  ApexCharts.exec(
                                    "area-datetime",
                                    "removeAnnotation",
                                    "phase3"
                                  );
                                  ApexCharts.exec(
                                    "area-datetime",
                                    "removeAnnotation",
                                    "phase4"
                                  );
                                  ApexCharts.exec(
                                    "area-datetime",
                                    "removeAnnotation",
                                    "dryspell"
                                  );
                                },
                              });
                            },
                          });
                        },
                      });
                    },
                  });
                },
              });
            },
          });
        },
      });
    } else if (this.state.peril == "Dry Spell") {
      this.setState({
        hightemp: false,
        deficitrain: false,
        excessrain: false,
        dryspell: true,
        diseasecongenial: false,
        showPhaseselector: false,
        showCongenial: false,
        showfilter: false,
      });
      var objthis = this;

      $.ajax({
        //api call to fetch data belongs to darksky
        url:
          "https://demo.misteo.co:5007/weathertrend?arg1=" +
          apiParam +
          "&dp=darksky&lat=" +
          latitude +
          "&lng=" +
          longitude,
        dataType: "json",
        type: "GET",
        success: function (responseds) {
          var dsMaxval = responseds[0].maximumvalue;
          let dataDarsky = objthis.getValues(responseds, apiParam, "darksky");
          chart_values.push(dataDarsky);
          $.ajax({
            //api call to fetch data belongs to open weather
            url:
              "https://demo.misteo.co:5007/weathertrend?arg1=" +
              apiParam +
              "&dp=openweather&lat=" +
              latitude +
              "&lng=" +
              longitude,
            dataType: "json",
            type: "GET",
            success: function (responseow) {
              var owMaxval = responseow[0].maximumvalue;
              let dataOpenweather = objthis.getValues(
                responseow,
                apiParam,
                "openweather"
              );
              chart_values.push(dataOpenweather);

              $.ajax({
                //api call to fetch data belongs to dtn
                url:
                  "https://demo.misteo.co:5007/weathertrend?arg1=" +
                  apiParam +
                  "&dp=AIC&lat=" +
                  latitude +
                  "&lng=" +
                  longitude,
                dataType: "json",
                type: "GET",
                success: function (responsedtn) {
                  var dtnMaxval = responsedtn[0].maximumvalue;
                  let dataaic = objthis.getValues(responsedtn, apiParam, "aic");
                  chart_values.push(dataaic);

                  $.ajax({
                    //api call to fetch data belongs to dtn
                    url:
                      "https://demo.misteo.co:5007/weathertrend?arg1=" +
                      apiParam +
                      "&dp=MeteoBlue&lat=" +
                      latitude +
                      "&lng=" +
                      longitude,
                    dataType: "json",
                    type: "GET",
                    success: function (responsemeteoblue) {
                      var meteomax = responsemeteoblue[0].maximumvalue;
                      let datameteoblue = objthis.getValues(
                        responsemeteoblue,
                        apiParam,
                        "MeteoBlue"
                      );
                      chart_values.push(datameteoblue);

                      $.ajax({
                        //api call to fetch data belongs to dtn
                        url:
                          "https://demo.misteo.co:5007/weathertrend?arg1=" +
                          apiParam +
                          "&dp=ECMWF&lat=" +
                          latitude +
                          "&lng=" +
                          longitude,
                        dataType: "json",
                        type: "GET",
                        success: function (responseecmwf) {
                          var maxecm = responseecmwf[0].maximumvalue;
                          let dataecmwf = objthis.getValues(
                            responseecmwf,
                            apiParam,
                            "ECMWF"
                          );
                          chart_values.push(dataecmwf);

                          $.ajax({
                            //api call to fetch data belongs to dtn
                            url:
                              "https://demo.misteo.co:5007/weathertrend?arg1=" +
                              apiParam +
                              "&dp=Nasa Power&lat=" +
                              latitude +
                              "&lng=" +
                              longitude,
                            dataType: "json",
                            type: "GET",
                            success: function (responsenasapower) {
                              var maxnasa = responsenasapower[0].maximumvalue;
                              let datanasa = objthis.getValues(
                                responsenasapower,
                                apiParam,
                                "Nasa Power"
                              );
                              chart_values.push(datanasa);

                              $.ajax({
                                //api call to fetch data belongs to open weather
                                url:
                                  "https://dev.misteo.co:5004/historical?var=" +
                                  apiParam +
                                  "&lat=" +
                                  latitude +
                                  "&lng=" +
                                  longitude,
                                dataType: "json",
                                type: "GET",
                                success: function (responseonline_sensor) {
                                  let datasensor = objthis.getValues(
                                    responseonline_sensor,
                                    apiParam,
                                    "Online Sensor"
                                  );
                                  chart_values.push(datasensor);

                                  objthis.setState({
                                    options: {
                                      colors: [
                                        "#2E93fA",
                                        "#66DA26",
                                        "#FF4500",
                                        "#ff05e6",
                                        "#FF9800",
                                        "#9c59ff",
                                        "#0b6b75",
                                      ],
                                      chart: {
                                        id: "area-datetime",
                                        type: "area",
                                        height: 350,
                                        zoom: {
                                          autoScaleYaxis: true,
                                        },
                                        toolbar: {
                                          show: true,
                                          tools: {
                                            download: false,
                                          },
                                        },
                                      },
                                      stroke: {
                                        width: 1,
                                      },
                                      xaxis: {
                                        type: "datetime",
                                        labels: {
                                          datetimeUTC: false,
                                        },
                                        min: 1590949800000,
                                        max: 1602700200000,
                                      },
                                      yaxis: {
                                        show: true,
                                        min: 0,
                                        max: 100,
                                        // "labels": {
                                        //     "formatter": function (val) {
                                        //         return val.toFixed(2)
                                        //     }
                                        // }
                                      },
                                      tooltip: {
                                        x: {
                                          format: "dd MMM yyyy hh mm ss",
                                        },
                                      },
                                      annotations: {
                                        yaxis: [
                                          {
                                            id: "dryspell",
                                            y: 2.5,
                                            y2: 0,
                                            fillColor: "#ed1a1a",
                                            borderColor: "#FF0000",
                                            label: {
                                              borderColor: "#FF0000",
                                              // offsetX: -430,
                                              style: {
                                                color: "#fff",
                                                background: "#FF0000",
                                              },
                                              text: "STRIKE",
                                            },
                                          },
                                        ],
                                      },
                                    },
                                    legend: {
                                      show: false,
                                    },
                                  });
                                  objthis.setState({
                                    series: chart_values,
                                    showchart: true,
                                    showtable: true,
                                    showLoader: false,
                                    isGetdatadisabled: false,
                                  });
                                  ApexCharts.exec(
                                    "area-datetime",
                                    "removeAnnotation",
                                    "phase1"
                                  );
                                  ApexCharts.exec(
                                    "area-datetime",
                                    "removeAnnotation",
                                    "phase2"
                                  );
                                  ApexCharts.exec(
                                    "area-datetime",
                                    "removeAnnotation",
                                    "phase3"
                                  );
                                  ApexCharts.exec(
                                    "area-datetime",
                                    "removeAnnotation",
                                    "phase4"
                                  );
                                  ApexCharts.exec(
                                    "area-datetime",
                                    "removeAnnotation",
                                    "excessrain"
                                  );
                                },
                              });
                            },
                          });
                        },
                      });
                    },
                  });
                },
              });
            },
          });
        },
      });
    } else if (this.state.peril == "Deficit Rainfall") {
      this.setState({
        hightemp: false,
        deficitrain: true,
        excessrain: false,
        dryspell: false,
        diseasecongenial: false,
        showPhaseselector: true,
        showdeficit: false,
      });
      var objthis = this;
      $.ajax({
        //api call to fetch data belongs to darksky
        url:
          "https://demo.misteo.co:5007/weathertrend?arg1=" +
          apiParam +
          "&dp=darksky&lat=" +
          latitude +
          "&lng=" +
          longitude,
        dataType: "json",
        type: "GET",
        success: function (responseds) {
          var dsMaxval = responseds[0].maximumvalue;
          let dataDarsky = objthis.getValues(responseds, apiParam, "darksky");
          // chart_values.push(dataDarsky)
          $.ajax({
            //api call to fetch data belongs to open weather
            url:
              "https://demo.misteo.co:5007/weathertrend?arg1=" +
              apiParam +
              "&dp=openweather&lat=" +
              latitude +
              "&lng=" +
              longitude,
            dataType: "json",
            type: "GET",
            success: function (responseow) {
              var owMaxval = responseow[0].maximumvalue;
              let dataOpenweather = objthis.getValues(
                responseow,
                apiParam,
                "openweather"
              );
              // chart_values.push(dataOpenweather)

              $.ajax({
                //api call to fetch data belongs to dtn
                url:
                  "https://demo.misteo.co:5007/weathertrend?arg1=pp_tot&dp=AIC&lat=" +
                  latitude +
                  "&lng=" +
                  longitude,
                dataType: "json",
                type: "GET",
                success: function (responsedtn) {
                  var dtnMaxval = responsedtn[0].maximumvalue;
                  let dataaic = objthis.getValues(responsedtn, "pp_tot", "aic");
                  chart_values.push(dataaic);
                  $.ajax({
                    //api call to fetch data belongs to dtn
                    url:
                      "https://demo.misteo.co:5007/weathertrend?arg1=pp_tot&dp=MeteoBlue&lat=" +
                      latitude +
                      "&lng=" +
                      longitude,
                    dataType: "json",
                    type: "GET",
                    success: function (responsemeteoblue) {
                      var meteobluemax = responsemeteoblue[0].maximumvalue;
                      let datameteoblue = objthis.getValues(
                        responsemeteoblue,
                        "pp_tot",
                        "MeteoBlue"
                      );
                      chart_values.push(datameteoblue);
                      $.ajax({
                        //api call to fetch data belongs to dtn
                        url:
                          "https://demo.misteo.co:5007/weathertrend?arg1=pp_tot&dp=ECMWF&lat=" +
                          latitude +
                          "&lng=" +
                          longitude,
                        dataType: "json",
                        type: "GET",
                        success: function (responseecmwf) {
                          var ecmwfmax = responseecmwf[0].maximumvalue;
                          let dataecmwf = objthis.getValues(
                            responseecmwf,
                            "pp_tot",
                            "ECMWF"
                          );
                          chart_values.push(dataecmwf);
                          $.ajax({
                            //api call to fetch data belongs to dtn
                            url:
                              "https://demo.misteo.co:5007/weathertrend?arg1=pp_tot&dp=Nasa Power&lat=" +
                              latitude +
                              "&lng=" +
                              longitude,
                            dataType: "json",
                            type: "GET",
                            success: function (responsenasapower) {
                              var nasapwrmaxval =
                                responsenasapower[0].maximumvalue;
                              let datanasa = objthis.getValues(
                                responsenasapower,
                                "pp_tot",
                                "Nasa Power"
                              );
                              chart_values.push(datanasa);
                              let maxdeficit = objthis.findMax(
                                dsMaxval,
                                owMaxval,
                                dtnMaxval,
                                meteobluemax,
                                ecmwfmax,
                                nasapwrmaxval
                              );
                              $.ajax({
                                //api call to fetch data belongs to open weather
                                url:
                                  "https://dev.misteo.co:5004/historical?var=" +
                                  apiParam +
                                  "&lat=" +
                                  latitude +
                                  "&lng=" +
                                  longitude,
                                dataType: "json",
                                type: "GET",
                                success: function (responseonline_sensor) {
                                  let datasensor = objthis.getValues(
                                    responseonline_sensor,
                                    apiParam,
                                    "Online Sensor"
                                  );
                                  chart_values.push(datasensor);

                                  objthis.setState({
                                    options: {
                                      colors: [
                                        "#FF4500",
                                        "#ff05e6",
                                        "#FF9800",
                                        "#9c59ff",
                                        "#0b6b75",
                                      ],
                                      chart: {
                                        id: "area-datetime",
                                        type: "area",
                                        height: 350,
                                        zoom: {
                                          autoScaleYaxis: true,
                                        },
                                        toolbar: {
                                          show: true,
                                          tools: {
                                            download: false,
                                          },
                                        },
                                      },
                                      stroke: {
                                        width: 1,
                                      },
                                      xaxis: {
                                        type: "datetime",
                                        min: 1594060200000,
                                        max: 1602700200000,
                                      },
                                      yaxis: {
                                        show: true,
                                        min: 0,
                                        max: 850,
                                        labels: {
                                          formatter: function (val) {
                                            return val.toFixed(2);
                                          },
                                        },
                                      },
                                      tooltip: {
                                        x: {
                                          format: "dd MMM yyyy hh mm ss",
                                        },
                                      },
                                    },
                                    legend: {
                                      show: false,
                                    },
                                  });
                                  objthis.setState({
                                    series: chart_values,
                                    showchart: true,
                                    showtable: true,
                                    showLoader: false,
                                    isGetdatadisabled: false,
                                  });
                                  ApexCharts.exec(
                                    "area-datetime",
                                    "removeAnnotation",
                                    "phase1"
                                  );
                                  ApexCharts.exec(
                                    "area-datetime",
                                    "removeAnnotation",
                                    "phase2"
                                  );
                                  ApexCharts.exec(
                                    "area-datetime",
                                    "removeAnnotation",
                                    "phase3"
                                  );
                                  ApexCharts.exec(
                                    "area-datetime",
                                    "removeAnnotation",
                                    "phase4"
                                  );
                                  ApexCharts.exec(
                                    "area-datetime",
                                    "removeAnnotation",
                                    "excessrain"
                                  );
                                  ApexCharts.exec(
                                    "area-datetime",
                                    "removeAnnotation",
                                    "dryspell"
                                  );
                                },
                              });
                            },
                          });
                        },
                      });
                    },
                  });
                },
              });
            },
          });
        },
      });
    } else if (this.state.peril == "Disease Congenial Climate") {
      this.setState({
        // hightemp: false,
        // deficitrain: false,
        // excessrain: false,
        // dryspell: false,
        // diseasecongenial: true,
        // showPhaseselector: false,
        // ========================================================
        hightemp: false,
        deficitrain: false,
        excessrain: false,
        dryspell: false,
        diseasecongenial: false,
        showPhaseselector: true,
        showCongenial: true,
        showfilter: false,
      });

      var objthis = this;
      var apex = ApexCharts;

      $.ajax({
        //api call to fetch data belongs to AIC
        url:
          "https://demo.misteo.co:5007/weathertrend?arg1=tmax&dp=AIC&lat=" +
          latitude +
          "&lng=" +
          longitude,
        dataType: "json",
        type: "GET",
        success: function (tmaxresponseaic) {
          var tmaxaicmaxval = tmaxresponseaic[0].maximumvalue;
          dataaic = objthis.getValues(tmaxresponseaic, "tmax", "aic");

          chart_values.push(dataaic);

          $.ajax({
            //api call to fetch data belongs to ECMWF
            url:
              "https://demo.misteo.co:5007/weathertrend?arg1=tmax&dp=ECMWF&lat=" +
              latitude +
              "&lng=" +
              longitude,
            dataType: "json",
            type: "GET",
            success: function (tmaxresponseecmwf) {
              var tmaxecmwfmax = tmaxresponseecmwf[0].maximumvalue;
              dataecmwf = objthis.getValues(tmaxresponseecmwf, "tmax", "ECMWF");
              chart_values.push(dataecmwf);

              $.ajax({
                //api call to fetch data belongs to
                url:
                  "https://demo.misteo.co:5007/weathertrend?arg1=tmax&dp=Nasa Power&lat=" +
                  latitude +
                  "&lng=" +
                  longitude,
                dataType: "json",
                type: "GET",
                success: function (tmaxresponsenasa) {
                  var tmaxaicecmwfmax = tmaxresponsenasa[0].maximumvalue;
                  datanasa = objthis.getValues(
                    tmaxresponsenasa,
                    "tmax",
                    "Nasa Power"
                  );
                  chart_values.push(datanasa);

                  $.ajax({
                    //api call to fetch data belongs to dtn
                    url:
                      "https://demo.misteo.co:5007/weathertrend?arg1=tmax&dp=MeteoBlue&lat=" +
                      latitude +
                      "&lng=" +
                      longitude,
                    dataType: "json",
                    type: "GET",
                    success: function (tmaxresponsemeteoblue) {
                      var meteomax = tmaxresponsemeteoblue[0].maximumvalue;
                      datameteoblue = objthis.getValues(
                        tmaxresponsemeteoblue,
                        "tmax",
                        "MeteoBlue"
                      );
                      chart_values.push(datameteoblue);

                      $.ajax({
                        url:
                          "https://dev.misteo.co:5004/historical?var=tm&lat=" +
                          latitude +
                          "&lng=" +
                          longitude,
                        dataType: "json",
                        type: "GET",
                        success: function (responseonline_sensor) {
                          datasensor = objthis.getValues(
                            responseonline_sensor,
                            "tm",
                            "Online Sensor"
                          );
                          chart_values.push(datasensor);
                          objthis.setState({
                            optionscongenial1: {
                              colors: [
                                "#FF4500",
                                "#FF9800",
                                "#9c59ff",
                                "#ff05e6",
                                "#0b6b75",
                              ],
                              chart: {
                                id: "tmaxcongenial",
                                group: "congenial",
                                type: "area",
                                height: 350,
                                stacked: false,
                                zoom: {
                                  autoScaleYaxis: true,
                                },
                                toolbar: {
                                  show: true,
                                  tools: {
                                    download: false,
                                  },
                                },
                              },
                              stroke: {
                                width: 1,
                              },
                              xaxis: {
                                type: "datetime",
                                min: 1590949800000,
                                max: 1602700200000,
                              },
                              tooltip: {
                                x: {
                                  format: "dd MMM yyyy hh mm ss",
                                },
                              },

                              legend: {
                                show: false,
                              },

                              annotations: {
                                yaxis: [
                                  {
                                    y: 45,
                                    y2: 32,
                                    fillColor: "#247BA0",
                                    opacity: 0.2,
                                    borderColor: "#FF0000",
                                    label: {
                                      borderColor: "#FF0000",
                                      // offsetX: -430,
                                      style: {
                                        color: "#fff",
                                        background: "#FF0000",
                                      },
                                      text: "Temp_STRIKE",
                                    },
                                  },
                                ],
                              },
                              yaxis: {
                                min: 0,
                                max: 50,
                                // labels: {
                                //     "formatter": function (val) {
                                //         return val.toFixed(2)
                                //     }
                                // },
                              },
                            },
                            seriescongenial1: chart_values,
                          });
                        },
                      });
                      objthis.setState({
                        showLoader: false,
                        showchart: false,
                      });
                    },
                  });
                },
              });
            },
          });
        },
      });
      //ends first chart call

      $.ajax({
        //api call to fetch data belongs to darksky
        url:
          "https://demo.misteo.co:5007/weathertrend?arg1=hm&dp=AIC&lat=" +
          latitude +
          "&lng=" +
          longitude,
        dataType: "json",
        type: "GET",
        success: function (responseaic) {
          var aicMaxval = responseaic[0].maximumvalue;
          dataaic = objthis.getValues(responseaic, "hm", "aic");
          chart_values2.push(dataaic);
          $.ajax({
            //api call to fetch data belongs to open weather
            url:
              "https://demo.misteo.co:5007/weathertrend?arg1=hm&dp=ECMWF&lat=" +
              latitude +
              "&lng=" +
              longitude,
            dataType: "json",
            type: "GET",
            success: function (responseecmwf) {
              var ecmwfmax = responseecmwf[0].maximumvalue;
              dataecmwf = objthis.getValues(responseecmwf, "hm", "ECMWF");
              chart_values2.push(dataecmwf);

              $.ajax({
                //api call to fetch data belongs to dtn
                url:
                  "https://demo.misteo.co:5007/weathertrend?arg1=hm&dp=Nasa Power&lat=" +
                  latitude +
                  "&lng=" +
                  longitude,
                dataType: "json",
                type: "GET",
                success: function (responsenasapower) {
                  var nasamax = responsenasapower[0].maximumvalue;
                  datanasa = objthis.getValues(
                    responsenasapower,
                    "hm",
                    "Nasa Power"
                  );
                  chart_values2.push(datanasa);

                  $.ajax({
                    //api call to fetch data belongs to dtn
                    url:
                      "https://demo.misteo.co:5007/weathertrend?arg1=hm&dp=MeteoBlue&lat=" +
                      latitude +
                      "&lng=" +
                      longitude,
                    dataType: "json",
                    type: "GET",
                    success: function (responsemeteoblue) {
                      var meteomax = responsemeteoblue[0].maximumvalue;
                      datameteoblue = objthis.getValues(
                        responsemeteoblue,
                        "hm",
                        "MeteoBlue"
                      );

                      chart_values2.push(datameteoblue);

                      $.ajax({
                        //api call to fetch data belongs to open weather
                        url:
                          "https://dev.misteo.co:5004/historical?var=hm&lat=" +
                          latitude +
                          "&lng=" +
                          longitude,
                        dataType: "json",
                        type: "GET",
                        success: function (responseonline_sensor) {
                          datasensor = objthis.getValues(
                            responseonline_sensor,
                            "hm",
                            "Online Sensor"
                          );
                          chart_values2.push(datasensor);

                          objthis.setState({
                            optionscongenial2: {
                              colors: [
                                "#FF4500",
                                "#FF9800",
                                "#9c59ff",
                                "#ff05e6",
                                "#0b6b75",
                              ],
                              chart: {
                                id: "humiditycongenial",
                                group: "congenial",
                                type: "area",
                                height: 350,
                                stacked: false,
                                zoom: {
                                  autoScaleYaxis: true,
                                },
                                toolbar: {
                                  show: true,
                                  tools: {
                                    download: false,
                                  },
                                },
                              },
                              stroke: {
                                width: 1,
                              },
                              xaxis: {
                                type: "datetime",
                                tickAmount: 6,
                                min: 1590949800000,
                                max: 1602700200000,
                              },
                              tooltip: {
                                x: {
                                  format: "dd MMM yyyy hh mm ss",
                                },
                              },
                              legend: {
                                show: false,
                              },
                              // title: {
                              //     text: 'HUMIDITY',
                              //     align: 'left'
                              // },
                              annotations: {
                                yaxis: [
                                  {
                                    y: 60,
                                    borderColor: "#00E396",
                                    label: {
                                      borderColor: "#00E396",
                                      style: {
                                        color: "#fff",
                                        background: "#00E396",
                                      },
                                      text: "Y-axis annotation on 8800",
                                    },
                                  },
                                ],
                              },
                              yaxis: {
                                min: 0,
                                max: 100,
                                // labels: {
                                //     "formatter": function (val) {
                                //         return val.toFixed(2)
                                //     }
                                // },
                              },
                            },
                            legend: {
                              show: false,
                            },
                          });

                          objthis.setState({
                            seriescongenial2: chart_values2,
                            showchart: false,
                            showtable: true,
                            showLoader: false,
                            showCongenial: true,
                            isGetdatadisabled: false,
                          });
                        },
                      });
                    },
                  });
                },
              });
            },
          });
        },
      });
      ApexCharts.exec("tmaxcongenial", "updateSeries", chart_values);
      ApexCharts.exec("humiditycongenial", "updateSeries", chart_values2);
    }
  }

  getPrameter(peril) {
    var apiParameter;
    if (peril == "High Temperature") {
      apiParameter = "tm";
    } else if (peril == "Deficit Rainfall") {
      apiParameter = "pp";
    } else if (peril == "Excess Rainfall") {
      apiParameter = "pp";
    } else if (peril == "Dry Spell") {
      apiParameter = "pp";
    } else if (peril == "Disease Congenial Climate") {
      apiParameter = "tm";
    }
    return apiParameter;
  }

  getValues(object, k, dp) {
    var dataProvider;
    if (dp == "aic") {
      dataProvider = "AIC";
    } else if (dp == "openweather") {
      dataProvider = "OpenWeather";
    } else if (dp == "MeteoBlue") {
      dataProvider = "Meteo Blue";
    } else if (dp == "ECMWF") {
      dataProvider = "ECMWF";
    } else if (dp == "Nasa Power") {
      dataProvider = "Nasa Power Larc";
    } else if (dp == "Online Sensor") {
      dataProvider = "Online Sensor";
    } else {
      dataProvider = "Dark Sky";
    }
    var values = [];
    for (var key in object) {
      // console.log(object[key]);
      if (!object.hasOwnProperty(key)) continue;
      if (k == "tm") {
        values.push({ x: object[key].unixms, y: object[key].tm });
      }
      if (k == "hm") {
        values.push({ x: object[key].unixms, y: object[key].hm });
      }
      if (k == "ps") {
        values.push({ x: object[key].unixms, y: object[key].ps });
      }
      if (k == "ws") {
        values.push({ x: object[key].unixms, y: object[key].ws });
      }
      if (k == "cc") {
        values.push({ x: object[key].unixms, y: object[key].cc });
      }
      if (k == "dp") {
        values.push({ x: object[key].unixms, y: object[key].dp });
      }
      if (k == "pp") {
        values.push({ x: object[key].unixms, y: object[key].pp });
      }
      if (k == "wd") {
        values.push({ x: object[key].unixms, y: object[key].wd });
      }
      if (k == "uvi") {
        values.push({ x: object[key].unixms, y: object[key].uvi });
      }
      if (k == "pp_tot") {
        values.push({ x: object[key].unixms, y: object[key].pp_tot });
      }
      if (k == "tmax") {
        values.push({ x: object[key].unixms, y: object[key].tmax });
      }

      var data = {
        name: dataProvider,
        data: values,
      };
    }
    // console.log(data)
    return data;
  }

  findMax(owmax, dsmax) {
    const largest = Math.max(owmax, dsmax);
    var per = (largest * 10) / 100;
    return largest + per;
  }

  findpercentage(val) {
    var per = (val * 10) / 100;
    return val + per;
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Row>
              <Col xs={12}>
                <h4
                  className="card-title"
                  style={{ padding: "5px", fontSize: "18px" }}
                >
                  {" "}
                  Termsheet Peril Wise
                </h4>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <Card
                  style={{ minHeight: "470px", backgroundColor: "#f1f6fb" }}
                >
                  <CardBody>
                    <h6 style={{ paddingTop: "10px", paddingBottom: "20px" }}>
                      Filter
                    </h6>
                    <div>
                      <Row style={{ fontSize: "11px", padding: "0px" }}>
                        <Col>
                          <FormGroup row>
                            <Label className="col-sm col-form-label">
                              Location
                            </Label>
                            <Col md={7}>
                              <select
                                className="form-select"
                                style={{ fontSize: "11px" }}
                                value={this.state.selloc}
                                onChange={this.handleLocationchange}
                              >
                                <option
                                  value="10.69300691,76.72595507"
                                  style={
                                    this.state.kb1 ? {} : { display: "none" }
                                  }
                                >
                                  Chittur-Thathamangalam
                                </option>
                                <option
                                  // disabled={this.state.kb2 ? true : false}
                                  value="10.74866619,76.85879364"
                                  // selected={this.state.kb2 ? true : false}
                                  style={
                                    this.state.kb2 ? {} : { display: "none" }
                                  }
                                >
                                  Eruthenpathy{" "}
                                </option>
                                <option
                                  // disabled={this.state.kb3 ? true : false}
                                  value="10.738,76.834"
                                  style={
                                    this.state.kb3 ? {} : { display: "none" }
                                  }
                                >
                                  Kozhinjampara
                                </option>
                                <option
                                  // disabled={this.state.fkb4 ? true : false}
                                  value="10.72918673,76.78622876"
                                  style={
                                    this.state.kb4 ? {} : { display: "none" }
                                  }
                                >
                                  Nallepilly
                                </option>
                                <option
                                  // disabled={this.state.kb5 ? true : false}
                                  value="10.64777998,76.73270522"
                                  style={
                                    this.state.kb5 ? {} : { display: "none" }
                                  }
                                >
                                  Pattanchery
                                </option>
                                <option
                                  // disabled={this.state.kb6 ? true : false}
                                  value="10.662,76.787"
                                  style={
                                    this.state.kb6 ? {} : { display: "none" }
                                  }
                                >
                                  Perumatty
                                </option>
                                <option
                                  // disabled={this.state.kb7 ? true : false}
                                  value="10.807,76.846"
                                  // selected={this.state.kb7 ? true : false}
                                  style={
                                    this.state.kb7 ? {} : { display: "none" }
                                  }
                                >
                                  Vadakarapathy
                                </option>
                              </select>
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Label className="col-sm col-form-label">
                              Season
                            </Label>
                            <Col md={7}>
                              <select
                                className="form-select"
                                style={{ fontSize: "11px" }}
                                value={this.state.season}
                                onChange={this.handleSeasonchange}
                              >
                                {this.state.seasonData.length > 0 &&
                                  this.state.seasonData.map((item) => (
                                    <option value={item} key={item}>
                                      {item}
                                    </option>
                                  ))}
                              </select>
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Label className="col-sm col-form-label">
                              Crop
                            </Label>
                            <Col md={7}>
                              <select
                                className="form-select"
                                style={{ fontSize: "11px" }}
                                value={this.state.crop}
                                onChange={this.handleCropchange}
                              >
                                {this.state.cropData.length > 0 &&
                                  this.state.cropData.map((item) => (
                                    <option value={item} key={item}>
                                      {item}
                                    </option>
                                  ))}
                              </select>
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Label className=" col-sm col-form-label">
                              Perils
                            </Label>
                            <Col md={7}>
                              <select
                                className="form-select"
                                style={{ fontSize: "11px" }}
                                onChange={this.handlePerilchange}
                              >
                                {this.state.perilData.length > 0 &&
                                  this.state.perilData.map((item) => (
                                    <option value={item} key={item}>
                                      {item}
                                    </option>
                                  ))}
                              </select>
                            </Col>
                          </FormGroup>

                          <FormGroup row>
                            <Label
                              htmlFor="example-number-input"
                              className="col-sm col-form-label"
                            >
                              {" "}
                              Acreage (ha)
                            </Label>
                            <Col md={7}>
                              <Input
                                className="form-control"
                                type="number"
                                defaultValue="1"
                                min="1"
                                id="example-number-input"
                                style={{ fontSize: "11px" }}
                                onChange={this.handleAcreChange}
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup row className="mb-0">
                            <Label className=" col-sm col-form-label">
                              Data Provider
                            </Label>
                            <Col md={7}>
                              <select
                                className="form-select"
                                style={{ fontSize: "11px" }}
                                onChange={this.handledpchange}
                              >
                                <option>AIC</option>
                                <option>Dark Sky</option>
                                <option>Open Weather</option>
                                <option>Online Sensor</option>
                              </select>
                            </Col>
                          </FormGroup>
                        </Col>
                      </Row>
                      <FormGroup
                        className="mb-0"
                        style={{ paddingLeft: "0px", paddingTop: "20px" }}
                      >
                        <div>
                          <Button
                            type="submit"
                            color="primary"
                            className="mr-1"
                            style={{ fontSize: "11px" }}
                            disabled={this.state.isGetdatadisabled}
                            onClick={this.handleSubmit}
                          >
                            Get Data
                          </Button>
                        </div>
                      </FormGroup>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col md={8}>
                <Card style={{ minHeight: "470px" }}>
                  <CardBody>
                    <h6 style={{ paddingTop: "10px", paddingBottom: "10px" }}>
                      {this.state.ddloc} |{" "}
                      {this.capitalizeFirstLetter(this.state.ddseason)} |{" "}
                      {this.state.ddperil}
                    </h6>

                    <Nav tabs>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: this.state.activeTab === "1",
                          })}
                          onClick={() => {
                            this.toggle("1");
                            this.setState({
                              isGetdatadisabled: false,
                            });
                          }}
                        >
                          Chart
                        </NavLink>
                      </NavItem>
                      <NavItem
                        style={this.state.showtable ? {} : { display: "none" }}
                      >
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: this.state.activeTab === "2",
                          })}
                          onClick={() => {
                            this.toggle("2");
                            this.setState({
                              isGetdatadisabled: true,
                            });
                          }}
                        >
                          Table
                        </NavLink>
                      </NavItem>
                    </Nav>

                    <TabContent activeTab={this.state.activeTab}>
                      <TabPane tabId="1" className="p-3">
                        <Row>
                          <Col sm="12">
                            <CardText>
                              <center>
                                {/* <div style={this.state.showWaiting ? {} : { display: 'none' }}>Waiting for search</div> */}
                                <img
                                  src={loader}
                                  height={"200px"}
                                  style={
                                    this.state.showLoader
                                      ? {}
                                      : { display: "none" }
                                  }
                                ></img>
                              </center>
                              <div
                                style={
                                  this.state.showchart
                                    ? {}
                                    : { display: "none" }
                                }
                              >
                                <div class="row">
                                  <div class="col">
                                    <Label check>
                                      <Input
                                        type="radio"
                                        name="radio1"
                                        id="p1"
                                        value="p1"
                                        onClick={this.phaseSelector}
                                        checked={
                                          this.state.selectedValue === "p1"
                                        }
                                      />{" "}
                                      <p
                                        style={{
                                          display: "inline-block",
                                          fontSize: "13px",
                                        }}
                                      >
                                        {" "}
                                        Phase 1{" "}
                                      </p>
                                    </Label>
                                  </div>
                                  <div class="col">
                                    <Label check>
                                      <Input
                                        type="radio"
                                        name="radio1"
                                        id="p2"
                                        value="p2"
                                        onClick={this.phaseSelector}
                                        checked={
                                          this.state.selectedValue === "p2"
                                        }
                                      />{" "}
                                      <p
                                        style={{
                                          display: "inline-block",
                                          fontSize: "13px",
                                        }}
                                      >
                                        {" "}
                                        Phase 2{" "}
                                      </p>
                                    </Label>
                                  </div>
                                  <div class="col">
                                    <Label check>
                                      <Input
                                        type="radio"
                                        name="radio1"
                                        id="p3"
                                        value="p3"
                                        onClick={this.phaseSelector}
                                        checked={
                                          this.state.selectedValue === "p3"
                                        }
                                      />{" "}
                                      <p
                                        style={{
                                          display: "inline-block",
                                          fontSize: "13px",
                                        }}
                                      >
                                        {" "}
                                        Phase 3{" "}
                                      </p>
                                    </Label>
                                  </div>
                                  <div class="col">
                                    <Label
                                      check
                                      style={
                                        this.state.deficitrain
                                          ? { display: "none" }
                                          : {}
                                      }
                                    >
                                      <Input
                                        type="radio"
                                        name="radio1"
                                        id="p4"
                                        value="p4"
                                        onClick={this.phaseSelector}
                                        checked={
                                          this.state.selectedValue === "p4"
                                        }
                                      />{" "}
                                      <p
                                        style={{
                                          display: "inline-block",
                                          fontSize: "13px",
                                        }}
                                      >
                                        {" "}
                                        Phase 4{" "}
                                      </p>
                                    </Label>
                                  </div>

                                  <div>
                                    {/* <Label check>
                                                                        <Input type="radio" name="radio1" id="p1" value="p1" onClick={this.phaseSelector} checked={this.state.selectedValue === 'p1'} />{' '}
                                                                        <p style={{ display: 'inline-block', fontSize: '13px' }}  > Phase 1 </p>
                                                                    </Label>

                                                                    <Label check style={{ marginLeft: '35px' }}>
                                                                        <Input type="radio" name="radio1" id="p2" value="p2" onClick={this.phaseSelector} checked={this.state.selectedValue === 'p2'} />{' '}
                                                                        <p style={{ display: 'inline-block', fontSize: '13px' }} > Phase 2 </p>
                                                                    </Label>

                                                                    <Label check style={{ marginLeft: '35px' }}>
                                                                        <Input type="radio" name="radio1" id="p3" value="p3" onClick={this.phaseSelector} checked={this.state.selectedValue === 'p3'} />{' '}
                                                                        <p style={{ display: 'inline-block', fontSize: '13px' }} > Phase 3 </p>
                                                                    </Label>

                                                                    <Label check style={this.state.deficitrain ? { display: 'none' } : { marginLeft: '35px' }} >
                                                                        <Input type="radio" name="radio1" id="p4" value="p4" onClick={this.phaseSelector} checked={this.state.selectedValue === 'p4'} />{' '}
                                                                        <p style={{ display: 'inline-block', fontSize: '13px' }} > Phase 4 </p>
                                                                    </Label> */}
                                  </div>

                                  <Chart
                                    options={this.state.options}
                                    series={this.state.series}
                                    type="line"
                                    height="300"
                                    width="100%"
                                  />
                                  <div
                                    style={{ "text-align": "center" }}
                                    hidden={this.state.showfilter}
                                  >
                                    <span
                                      class="dot"
                                      style={{ "background-color": "#2E93fA" }}
                                    ></span>
                                    <p
                                      id="ds"
                                      onClick={this.handleKfilter}
                                      style={{
                                        cursor: "pointer",
                                        display: "inline-block",
                                        "font-size": "12px",
                                        "font-family": "Segoe UI",
                                        paddingRight: "10px",
                                        paddingLeft: "4px",
                                        color: this.state.kfdcolor,
                                      }}
                                    >
                                      Dark Sky
                                    </p>
                                    <span
                                      class="dot"
                                      style={{ "background-color": "#66DA26" }}
                                    ></span>
                                    <p
                                      id="ow"
                                      onClick={this.handleKfilter}
                                      style={{
                                        cursor: "pointer",
                                        display: "inline-block",
                                        "font-size": "12px",
                                        "font-family": "Segoe UI",
                                        paddingRight: "10px",
                                        paddingLeft: "4px",
                                        color: this.state.kfowcolor,
                                      }}
                                    >
                                      OpenWeather
                                    </p>
                                    <span
                                      class="dot"
                                      style={{ "background-color": "#FF4500" }}
                                    ></span>
                                    <p
                                      id="aic"
                                      onClick={this.handleKfilter}
                                      style={{
                                        cursor: "pointer",
                                        display: "inline-block",
                                        "font-size": "12px",
                                        "font-family": "Segoe UI",
                                        paddingRight: "10px",
                                        paddingLeft: "4px",
                                        color: this.state.kfaiccolor,
                                      }}
                                    >
                                      AIC
                                    </p>
                                    <span
                                      class="dot"
                                      style={{ "background-color": "#9c59ff" }}
                                    ></span>
                                    <p
                                      id="nasa"
                                      onClick={this.handleKfilter}
                                      style={{
                                        cursor: "pointer",
                                        display: "inline-block",
                                        "font-size": "12px",
                                        "font-family": "Segoe UI",
                                        paddingRight: "10px",
                                        paddingLeft: "4px",
                                        color: this.state.kfnasacolor,
                                      }}
                                    >
                                      Nasa
                                    </p>
                                    <span
                                      class="dot"
                                      style={{ "background-color": "#FF9800" }}
                                    ></span>
                                    <p
                                      id="ecmwf"
                                      onClick={this.handleKfilter}
                                      style={{
                                        cursor: "pointer",
                                        display: "inline-block",
                                        "font-size": "12px",
                                        "font-family": "Segoe UI",
                                        paddingRight: "10px",
                                        paddingLeft: "4px",
                                        color: this.state.kfecmwfcolor,
                                      }}
                                    >
                                      ECMWF
                                    </p>
                                    <span
                                      class="dot"
                                      style={{ "background-color": "#ff05e6" }}
                                    ></span>
                                    <p
                                      id="mb"
                                      onClick={this.handleKfilter}
                                      style={{
                                        cursor: "pointer",
                                        display: "inline-block",
                                        "font-size": "12px",
                                        "font-family": "Segoe UI",
                                        paddingRight: "10px",
                                        paddingLeft: "4px",
                                        color: this.state.kfmetcolor,
                                      }}
                                    >
                                      MeteoBlue
                                    </p>

                                    <span
                                      class="dot"
                                      style={{ "background-color": "#0b6b75;" }}
                                    ></span>
                                    <p
                                      id="Online"
                                      onClick={this.handleKfilter}
                                      style={{
                                        cursor: "pointer",
                                        display: "inline-block",
                                        "font-size": "12px",
                                        "font-family": "Segoe UI",
                                        paddingRight: "10px",
                                        paddingLeft: "4px",
                                        color: this.state.kfsencolor,
                                      }}
                                    >
                                      Online Sensor
                                    </p>
                                    <span
                                      class="dot"
                                      style={{ "background-color": "#9bd6e8" }}
                                    ></span>
                                    <p
                                      id="All"
                                      onClick={this.handleKfilter}
                                      style={{
                                        cursor: "pointer",
                                        display: "inline-block",
                                        "font-size": "12px",
                                        "font-family": "Segoe UI",
                                        paddingRight: "10px",
                                        paddingLeft: "4px",
                                        color: this.state.kfallcolor,
                                      }}
                                    >
                                      All
                                    </p>
                                  </div>
                                </div>

                                <div
                                  style={{ "text-align": "center" }}
                                  hidden={this.state.showdeficit}
                                >
                                  <span
                                    class="dot"
                                    style={{ "background-color": "#FF4500" }}
                                  ></span>
                                  <p
                                    id="aic"
                                    onClick={this.handleKfilter}
                                    style={{
                                      cursor: "pointer",
                                      display: "inline-block",
                                      "font-size": "12px",
                                      "font-family": "Segoe UI",
                                      paddingRight: "10px",
                                      paddingLeft: "4px",
                                      color: this.state.kfaiccolor,
                                    }}
                                  >
                                    AIC
                                  </p>
                                  <span
                                    class="dot"
                                    style={{ "background-color": "#9c59ff" }}
                                  ></span>
                                  <p
                                    id="nasa"
                                    onClick={this.handleKfilter}
                                    style={{
                                      cursor: "pointer",
                                      display: "inline-block",
                                      "font-size": "12px",
                                      "font-family": "Segoe UI",
                                      paddingRight: "10px",
                                      paddingLeft: "4px",
                                      color: this.state.kfnasacolor,
                                    }}
                                  >
                                    Nasa
                                  </p>
                                  <span
                                    class="dot"
                                    style={{ "background-color": "#FF9800" }}
                                  ></span>
                                  <p
                                    id="ecmwf"
                                    onClick={this.handleKfilter}
                                    style={{
                                      cursor: "pointer",
                                      display: "inline-block",
                                      "font-size": "12px",
                                      "font-family": "Segoe UI",
                                      paddingRight: "10px",
                                      paddingLeft: "4px",
                                      color: this.state.kfecmwfcolor,
                                    }}
                                  >
                                    ECMWF
                                  </p>
                                  <span
                                    class="dot"
                                    style={{ "background-color": "#ff05e6" }}
                                  ></span>
                                  <p
                                    id="mb"
                                    onClick={this.handleKfilter}
                                    style={{
                                      cursor: "pointer",
                                      display: "inline-block",
                                      "font-size": "12px",
                                      "font-family": "Segoe UI",
                                      paddingRight: "10px",
                                      paddingLeft: "4px",
                                      color: this.state.kfmetcolor,
                                    }}
                                  >
                                    MeteoBlue
                                  </p>
                                  <span
                                    class="dot"
                                    style={{ "background-color": "#9bd6e8" }}
                                  ></span>
                                  <p
                                    id="All"
                                    onClick={this.handleKfilter}
                                    style={{
                                      cursor: "pointer",
                                      display: "inline-block",
                                      "font-size": "12px",
                                      "font-family": "Segoe UI",
                                      paddingRight: "10px",
                                      paddingLeft: "4px",
                                      color: this.state.kfallcolor,
                                    }}
                                  >
                                    All
                                  </p>
                                </div>
                              </div>
                              <div
                                style={
                                  this.state.showCongenial
                                    ? {}
                                    : { display: "none" }
                                }
                              >
                                <Chart
                                  options={this.state.optionscongenial1}
                                  series={this.state.seriescongenial1}
                                  type="line"
                                  height="300"
                                  width="100%"
                                />
                                <Chart
                                  options={this.state.optionscongenial2}
                                  series={this.state.seriescongenial2}
                                  type="line"
                                  height="300"
                                  width="100%"
                                />
                                <div style={{ "text-align": "center" }}>
                                  <span
                                    class="dot"
                                    style={{ "background-color": "#FF4500" }}
                                  ></span>
                                  <p
                                    id="aic"
                                    onClick={this.handlecongenialKfilter}
                                    style={{
                                      cursor: "pointer",
                                      display: "inline-block",
                                      "font-size": "12px",
                                      "font-family": "Segoe UI",
                                      paddingRight: "10px",
                                      paddingLeft: "4px",
                                      color: this.state.kfaiccolor,
                                    }}
                                  >
                                    AIC
                                  </p>
                                  <span
                                    class="dot"
                                    style={{ "background-color": "#9c59ff" }}
                                  ></span>
                                  <p
                                    id="nasa"
                                    onClick={this.handlecongenialKfilter}
                                    style={{
                                      cursor: "pointer",
                                      display: "inline-block",
                                      "font-size": "12px",
                                      "font-family": "Segoe UI",
                                      paddingRight: "10px",
                                      paddingLeft: "4px",
                                      color: this.state.kfnasacolor,
                                    }}
                                  >
                                    Nasa
                                  </p>
                                  <span
                                    class="dot"
                                    style={{ "background-color": "#FF9800" }}
                                  ></span>
                                  <p
                                    id="ecmwf"
                                    onClick={this.handlecongenialKfilter}
                                    style={{
                                      cursor: "pointer",
                                      display: "inline-block",
                                      "font-size": "12px",
                                      "font-family": "Segoe UI",
                                      paddingRight: "10px",
                                      paddingLeft: "4px",
                                      color: this.state.kfecmwfcolor,
                                    }}
                                  >
                                    ECMWF
                                  </p>
                                  <span
                                    class="dot"
                                    style={{ "background-color": "#ff05e6" }}
                                  ></span>
                                  <p
                                    id="mb"
                                    onClick={this.handlecongenialKfilter}
                                    style={{
                                      cursor: "pointer",
                                      display: "inline-block",
                                      "font-size": "12px",
                                      "font-family": "Segoe UI",
                                      paddingRight: "10px",
                                      paddingLeft: "4px",
                                      color: this.state.kfmetcolor,
                                    }}
                                  >
                                    MeteoBlue
                                  </p>

                                  {/* <span class="dot" style={{ "background-color": "#0b6b75;" }}></span>
                                                        <p id="Online" onClick={this.handlecongenialKfilter} style={{ 'cursor': 'pointer', display: 'inline-block', 'font-size': '12px', 'font-family': 'Segoe UI', paddingRight:'10px', paddingLeft:'4px',color:this.state.kfsencolor }}>
                                                            Online Sensor</p> */}
                                  <span
                                    class="dot"
                                    style={{ "background-color": "#9bd6e8" }}
                                  ></span>
                                  <p
                                    id="All"
                                    onClick={this.handlecongenialKfilter}
                                    style={{
                                      cursor: "pointer",
                                      display: "inline-block",
                                      "font-size": "12px",
                                      "font-family": "Segoe UI",
                                      paddingRight: "10px",
                                      paddingLeft: "4px",
                                      color: this.state.kfallcolor,
                                    }}
                                  >
                                    All
                                  </p>
                                </div>
                              </div>
                            </CardText>
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId="2" className="pt-2">
                        <Termsheettable
                          onRef={(ref) => (this.child = ref)}
                          tableprops={this.state}
                        />
                      </TabPane>
                    </TabContent>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
