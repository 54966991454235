import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  UncontrolledTooltip,
  CardBody,
  Spinner,
  Button,
  Media,
  Modal,
  ModalHeader,
  ModalBody,
  ButtonToolbar,
  ButtonGroup,
} from "reactstrap";
import MapCustomControl from "./React-control/control.tsx";
import "../Dashboard/leaflet.css";
import { FormGroup, Label } from "reactstrap";
import getpointsObject from "../../weather_points";
import getRuaObject from "../../rua_object";
import {
  MapContainer,
  TileLayer,
  GeoJSON,
  Popup,
  CircleMarker,
  Tooltip,
  SVGOverlay,
  Marker,
} from "react-leaflet";
import L from "leaflet";
import DiagnosticMap from "../Adm-components/diag_map";
import DiagnosticTable from "../Adm-components/diag_table";
import validate_Token from "../../validateToken.js";
import { Link } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import Moment from "moment";
import ContentLoader from "react-content-loader";
import loader from "../../assets/images/loader.gif";
import Chart from "react-apexcharts";
import { DatePicker } from "antd";
const { RangePicker } = DatePicker;
const dateFormat = "YYYY-MM-DD";
const bounds = [
  [10.6280565267840235, 76.6888275027770305],
  [10.817207336425831, 76.9081420898434516],
];
const LoaderIcon = new L.Icon({
  iconUrl: loader,
  iconSize: [150, 150],
});
const points = getpointsObject();
const rua = getRuaObject();
class Sensor_Diagnostic extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal_static: false,
      modal_rssi: false,
      sampletime: 0,
      otherProviderdata: [],
      dtKey: 0,
      coordinates: [],
      dropselect: "",
      latnew: "10.72",
      longnew: "76.810",
      long: 76.815843,
      lat: 10.712439,
      disabled: false,
      zoom: 12,
      dpval: 0,
      showLoader: true,
      changecolor: "statusup",
      map: null,
      livestatus: [0],
      tooltiptest: "initial test",
      severityclass: "",
      selperil: "temperature",
      parameter: "Temperature",
      otherparams: [],
      series: [10, 3],
      seldp: "none",
      options: {
        dataLabels: {
          enabled: false,
        },
        chart: {
          redrawOnWindowResize: false,
          redrawOnParentResize: false,
        },
        plotOptions: {
          pie: {
            labels: {
              show: true,
            },
            donut: {
              size: "50%",
            },
          },
        },
        shouldUpdateChart: false,
        labels: ["Active", "Inactive"],
        colors: ["#1d7d1e", "#fa3c3c"],
        legend: {
          show: true,
          position: "right",
          horizontalAlign: "center",
          verticalAlign: "middle",
          floating: false,
          fontSize: "8px",
          offsetX: 0,
          offsetY: -10,
        },
        responsive: [
          {
            breakpoint: 600,
            options: {
              chart: {
                height: 240,
              },
              legend: {
                show: false,
              },
            },
          },
        ],
      },
      series: [42, 26, 42],
      options: {
        labels: ["Product A", "Product B", "Product C"],
        plotOptions: {
          pie: {
            donut: {
              size: "50%",
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        colors: ["#5664d2", "#1cbb8c", "#eeb902"],
      },
      livestatus: [],
      mdbtable: [],
      gatewaydetails: "",
      isdata: false,
      currentLocation: "",
      currentStation: "",
      from_date: "",
      to_date: "",
      active: true,
      active_week: false,
      active_month: false,
      showloader: true,
      batteryoptions: {
        chart: {
          id: "historicaldata",
          type: "area",
          height: 350,
          zoom: {
            autoScaleYaxis: true,
          },
        },
        xaxis: {
          type: "datetime",
          tickAmount: 6,
          labels: {
            datetimeUTC: false,
            datetimeFormatter: {
              year: "yyyy",
              month: "dd MMM",
              day: "dd MMM",
              hour: "HH:mm",
            },
          },
        },
        tooltip: {
          x: {
            format: "dd.MM.yyyy HH:mm",
          },
        },
      },
      batteryseries: [
        {
          name: "Test Data",
          data: [],
        },
        {
          name: "Test Data2",
          data: [
            { x: 1615362718000, y: 77.95 },
            { x: 1615363619000, y: 90.34 },
            { x: 1615364518000, y: 24.18 },
            { x: 1615365418000, y: 21.05 },
            { x: 1615366318000, y: 71.0 },
            { x: 1615367218000, y: 80.95 },
            { x: 1615369018000, y: 81.24 },
            { x: 1615369918000, y: 51.29 },
            { x: 1615467413000, y: 41.85 },
            { x: 1615467520000, y: 21.86 },
            { x: 1615467681000, y: 12.28 },
            { x: 1615468200000, y: 82.1 },
          ],
        },
      ],

      rssioptions: {
        chart: {
          id: "historicaldata",
          type: "area",
          height: 350,
          zoom: {
            autoScaleYaxis: true,
          },
        },
        xaxis: {
          type: "datetime",
          tickAmount: 6,
          labels: {
            datetimeUTC: false,
            datetimeFormatter: {
              year: "yyyy",
              month: "dd MMM",
              day: "dd MMM",
              hour: "HH:mm",
            },
          },
        },
        tooltip: {
          x: {
            format: "dd.MM.yyyy HH:mm",
          },
        },
      },
      rssiseries: [
        {
          name: "Test Data",
          data: [],
        },
        {
          name: "Test Data2",
          data: [
            { x: 1615362718000, y: 77.95 },
            { x: 1615363619000, y: 90.34 },
            { x: 1615364518000, y: 24.18 },
            { x: 1615365418000, y: 21.05 },
            { x: 1615366318000, y: 71.0 },
            { x: 1615367218000, y: 80.95 },
            { x: 1615369018000, y: 81.24 },
            { x: 1615369918000, y: 51.29 },
            { x: 1615467413000, y: 41.85 },
            { x: 1615467520000, y: 21.86 },
            { x: 1615467681000, y: 12.28 },
            { x: 1615468200000, y: 82.1 },
          ],
        },
      ],
    };
    this.tabledatacreate = this.tabledatacreate.bind(this);
    this.getgatewayinfo = this.getgatewayinfo.bind(this);
    this.onMapReset = this.onMapReset.bind(this);
    this.handleperilchange = this.handleperilchange.bind(this);
    this.handledpchange = this.handledpchange.bind(this);
    this.getcurrentData = this.getcurrentData.bind(this);
    this.batteryChart = this.batteryChart.bind(this);
    this.generatechart = this.generatechart.bind(this);
    this.onChangedate = this.onChangedate.bind(this);
    this.datechange = this.datechange.bind(this);
    this.onChangerssidate = this.onChangerssidate.bind(this);
    this.fetchrssidata = this.fetchrssidata.bind(this);
    this.generaterssichart = this.generaterssichart.bind(this);
  }
  async getcurrentData() {
    // console.log("param", this.state.parameter);
    let getthis = this;
    let searchdata = {
      station: "",
      parameter: this.state.parameter,
    };
    searchdata = JSON.stringify(searchdata);
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: "Token " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      body: searchdata,
    };
    if (this.state.parameter == "Temperature") {
      this.setState({
        selperil: "temperature",
      });
    } else if (this.state.parameter == "Rainfall") {
      this.setState({
        selperil: "rainfall",
      });
    } else if (this.state.parameter == "Wind_Speed") {
      this.setState({
        selperil: "wind_speed",
      });
    } else if (this.state.parameter == "UV_Index") {
      this.setState({
        selperil: "uv_index",
      });
    } else if (this.state.parameter == "Wind_Direction") {
      this.setState({
        selperil: "wind_direction",
      });
    } else if (this.state.parameter == "Humidity") {
      this.setState({
        selperil: "humidity",
      });
    } else if (this.state.parameter == "Pressure") {
      this.setState({
        selperil: "pressure",
      });
    } else if (this.state.parameter == "Battery") {
      this.setState({
        selperil: "battery",
      });
    }
    this.setState({
      isdata: false,
    });
    var searchresponse = await fetch(
      process.env.REACT_APP_APIEND + `weather/current-new/observation`,
      requestOptions
    ).then((res) => {
      return res.json();
    });
    getthis.tabledatacreate(searchresponse.result);
    getthis.setState({
      livestatus: searchresponse.result,
      isdata: true,
      seldp: "none",
    });
  }

  onEachrua = (rua, layer) => {
    const ruaname = rua.properties.LOCATION;
    layer.bindPopup(ruaname);
  };

  onMapReset() {
    const { map } = this.state;
    if (map) map.flyTo([10.72, 76.81], 11);
  }

  handleperilchange(e) {
    this.setState({ parameter: e.target.value });
  }

  onEachws = (points, layer) => {
    layer.on({
      click: (event) => {
        const latitude = event.latlng.lat;
        const longitude = event.latlng.lng;
        this.apicall(latitude, longitude);
      },
      mouseover: (event) => {
        const ws = event.target.feature.properties.Location;

        layer.bindPopup(ws);
      },
    });
  };

  async test(a, b, c) {
    // console.log(a, b, c);
    let searchData = {
      station: "kdisc_sci_aws_03",
    };

    searchData = JSON.stringify(searchData);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + localStorage.getItem("token"),
      },
      body: searchData,
    };
    var weatherResponse = await fetch(
      process.env.REACT_APP_APIEND + `weather/current-api-weather`,
      requestOptions
    ).then((res) => {
      return res.json();
    });
  }
  async getcurrrentData() {
    let searchData = {
      station: "all",
    };

    searchData = JSON.stringify(searchData);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + localStorage.getItem("token"),
      },
      body: searchData,
    };
    var weatherResponse = await fetch(
      process.env.REACT_APP_APIEND + `weather/current-api-weather`,
      requestOptions
    ).then((res) => {
      return res.json();
    });
    if (weatherResponse.hasOwnProperty("code")) {
      if (weatherResponse.code == 200) {
        this.setState({
          otherProviderdata: weatherResponse.data,
        });
      }
    }
  }

  handledpchange(e) {
    this.setState({ seldp: e.target.value });
    if (e.target.value === "openweather") {
      this.setState({
        dtKey: 1,
      });
    } else if (e.target.value === "darksky") {
      this.setState({
        dtKey: 0,
      });
    }
  }

  checkcolor(status, value, peril) {
    let styleClass;
    if (status == false) {
      styleClass = "statusdown";
    } else if (status == true) {
      if (peril == "temperature") {
        if (value <= 10 || value >= 50) {
          styleClass = "statuszero";
        } else {
          styleClass = "statusup";
        }
      } else if (peril == "rainfall") {
        if (value < 0 || value >= 200) {
          styleClass = "statuszero";
        } else {
          styleClass = "statusup";
        }
      } else if (peril == "wind_speed") {
        styleClass = "statusup";
        if (value < 0 || value >= 250) {
          styleClass = "statuszero";
        } else {
          styleClass = "statusup";
        }
      } else if (peril == "wind_direction") {
        if (value < 0 || value > 360) {
          styleClass = "statuszero";
        } else {
          styleClass = "statusup";
        }
      } else if (peril == "uv_index") {
        if (value < 0 || value >= 15) {
          styleClass = "statuszero";
        } else {
          styleClass = "statusup";
        }
      } else if (peril == "humidity") {
        if (value <= 20 || value > 100) {
          styleClass = "statuszero";
        } else {
          styleClass = "statusup";
        }
      } else if (peril == "pressure") {
        if (value <= 650 || value >= 1200) {
          styleClass = "statuszero";
        } else {
          styleClass = "statusup";
        }
      } else if (peril == "battery") {
        if (value < 0) {
          styleClass = "statuszero";
        } else {
          styleClass = "statusup";
        }
      }
    }

    return styleClass;
  }

  checkgateway(location) {
    if (location == "kdisc_sci_aws_08") {
      return true;
    } else if (location == "kdisc_sci_aws_01") {
      return true;
    } else if (location == "kdisc_sci_aws_09") {
      return true;
    } else if (location == "kdisc_sci_aws_12") {
      return true;
    } else {
      return false;
    }
  }

  // battery chart

  onChangedate(dateString) {
    if (dateString !== null) {
      let getthis = this;
      const date1 = new Date(dateString[0]);
      const date2 = new Date(dateString[1]);

      var from_dd = String(date1.getDate()).padStart(2, "0");
      var from_mm = String(date1.getMonth() + 1).padStart(2, "0"); //January is 0!
      var from_yyyy = date1.getFullYear();
      var from_date = from_yyyy + "-" + from_mm + "-" + from_dd;

      var to_dd = String(date2.getDate()).padStart(2, "0");
      var to_mm = String(date2.getMonth() + 1).padStart(2, "0"); //January is 0!
      var to_yyyy = date2.getFullYear();
      var to_date = to_yyyy + "-" + to_mm + "-" + to_dd;

      getthis.setState(
        {
          from_date: from_date,
          to_date: to_date,
          active: false,
        },
        () => {
          getthis.fetchbatterydata();
        }
      );
    }
  }
  async fetchbatterydata() {
    var getthis = this;
    getthis.setState({ showloader: true });
    let bodydata = {
      station_name: this.state.currentStation,
      from_date: this.state.from_date,
      to_date: this.state.to_date,
    };
    bodydata = JSON.stringify(bodydata);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + localStorage.getItem("token"),
      },
      body: bodydata,
    };
    if (this.state.currentStation.length > 0) {
      var batteryhistory = await fetch(
        process.env.REACT_APP_APIEND + `weather/historical-battery-data`,
        requestOptions
      ).then((res) => {
        return res.json();
      });
      // console.log("batteryhist", batteryhistory);
      var battery_values = this.generatechart(batteryhistory);

      getthis.setState({
        batteryoptions: {
          colors: ["#0b6b75"],
          chart: {
            id: "historicaldata",
            type: "area",
            height: 350,
            zoom: {
              autoScaleYaxis: true,
            },
            toolbar: {
              show: true,
              tools: {
                download: false,
              },
            },
          },
          xaxis: {
            type: "datetime",
            labels: {
              datetimeUTC: false,
              datetimeFormatter: {
                year: "yyyy",
                month: "dd MMM",
                day: "dd MMM",
                hour: "HH:mm",
              },
            },
            title: {
              text: "Date/Time",
              rotate: -90,
              offsetX: 0,
              offsetY: 0,
              style: {
                color: undefined,
                fontSize: "12px",
                fontFamily: "Helvetica, Arial, sans-serif",
                fontWeight: 600,
                cssClass: "apexcharts-yaxis-title",
              },
            },
          },
          yaxis: {
            show: true,
            // min: 0,
            // max: 10,
            title: {
              text: "Battery (V)",
              rotate: -90,
              offsetX: 0,
              offsetY: 0,
              style: {
                color: undefined,
                fontSize: "12px",
                fontFamily: "Helvetica, Arial, sans-serif",
                fontWeight: 600,
                cssClass: "apexcharts-yaxis-title",
              },
            },
          },

          stroke: {
            width: 1,
          },
          tooltip: {
            x: {
              format: "dd.MM.yyyy HH:mm",
            },
          },
        },
      });
      getthis.setState({
        batteryseries: battery_values,
        showloader: false,
      });
    }
  }

  generatechart(data) {
    let chart_values = [];
    var batterydata = {
      name: "Battery",
      data: [],
    };
    // console.log("data", data);
    if (data != null) {
      data.data.map(function (item, index, data) {
        batterydata.data.push({
          x: item.unixms * 1000,
          y: Number(item.battery_value) / 100,
        });
      });
    }
    if (batterydata.data == null) {
      chart_values = [batterydata];
    }
    // console.log("DATA ", [batterydata]);
    return [batterydata];
  }
  disabledDate(current) {
    // Can not select days before today and today
    return current && current > Moment().endOf("day");
  }
  datechange(day) {
    var getthis = this;
    if (day == "one") {
      this.setState({
        active: true,
        active_week: false,
        active_month: false,
      });
      var to_date = new Date();
      var to_dd = String(to_date.getDate()).padStart(2, "0");
      var to_mm = String(to_date.getMonth() + 1).padStart(2, "0"); //January is 0!
      var to_yyyy = to_date.getFullYear();
      var to_date1 = to_yyyy + "-" + to_mm + "-" + to_dd;
      this.setState(
        {
          from_date: to_date1,
          to_date: to_date1,
        },
        () => {
          getthis.fetchbatterydata();
        }
      );
    }
    if (day == "week") {
      this.setState({
        active: false,
        active_week: true,
        active_month: false,
      });
      var to_date = new Date();
      var to_dd = String(to_date.getDate()).padStart(2, "0");
      var to_mm = String(to_date.getMonth() + 1).padStart(2, "0"); //January is 0!
      var to_yyyy = to_date.getFullYear();
      var to_date1 = to_yyyy + "-" + to_mm + "-" + to_dd;

      var from_date = new Date();
      from_date.setDate(from_date.getDate() - 7);
      var from_dd = String(from_date.getDate()).padStart(2, "0");
      var from_mm = String(from_date.getMonth() + 1).padStart(2, "0"); //January is 0!
      var from_yyyy = from_date.getFullYear();
      var from_date1 = from_yyyy + "-" + from_mm + "-" + from_dd;

      this.setState(
        {
          from_date: from_date1,
          to_date: to_date1,
        },
        () => {
          this.fetchbatterydata();
        }
      );
    }
    if (day == "month") {
      this.setState({
        active: false,
        active_week: false,
        active_month: true,
      });
      var to_date = new Date();
      var to_dd = String(to_date.getDate()).padStart(2, "0");
      var to_mm = String(to_date.getMonth() + 1).padStart(2, "0"); //January is 0!
      var to_yyyy = to_date.getFullYear();
      var to_date1 = to_yyyy + "-" + to_mm + "-" + to_dd;

      var from_date = new Date();
      from_date.setDate(from_date.getDate() - 30);
      var from_dd = String(from_date.getDate()).padStart(2, "0");
      var from_mm = String(from_date.getMonth() + 1).padStart(2, "0"); //January is 0!
      var from_yyyy = from_date.getFullYear();
      var from_date1 = from_yyyy + "-" + from_mm + "-" + from_dd;

      this.setState(
        {
          from_date: from_date1,
          to_date: to_date1,
        },
        () => {
          this.fetchbatterydata();
        }
      );
    }
  }

  // rssi chart

  onChangerssidate(dateString) {
    if (dateString !== null) {
      const date1 = new Date(dateString[0]);
      const date2 = new Date(dateString[1]);

      var from_dd = String(date1.getDate()).padStart(2, "0");
      var from_mm = String(date1.getMonth() + 1).padStart(2, "0"); //January is 0!
      var from_yyyy = date1.getFullYear();
      var from_date = from_yyyy + "-" + from_mm + "-" + from_dd;

      var to_dd = String(date2.getDate()).padStart(2, "0");
      var to_mm = String(date2.getMonth() + 1).padStart(2, "0"); //January is 0!
      var to_yyyy = date2.getFullYear();
      var to_date = to_yyyy + "-" + to_mm + "-" + to_dd;

      this.setState(
        {
          from_date: from_date,
          to_date: to_date,
          active: false,
          active_month: false,
          active_week: false,
        },
        () => {
          this.fetchrssidata();
        }
      );
    }
  }
  async fetchrssidata() {
    var getthis = this;
    getthis.setState({ showloader: true });
    let bodydata = {
      station_name: this.state.currentStation,
      from_date: this.state.from_date,
      to_date: this.state.to_date,
    };
    bodydata = JSON.stringify(bodydata);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + localStorage.getItem("token"),
      },
      body: bodydata,
    };

    var rssihistory = await fetch(
      process.env.REACT_APP_APIEND + `weather/historical-rssi-data`,
      requestOptions
    ).then((res) => {
      return res.json();
    });
    var rssi_values = this.generaterssichart(rssihistory);

    getthis.setState({
      rssioptions: {
        colors: ["#0b6b75"],
        chart: {
          id: "historicaldata",
          type: "area",
          height: 350,
          zoom: {
            autoScaleYaxis: true,
          },
          toolbar: {
            show: true,
            tools: {
              download: false,
            },
          },
        },
        xaxis: {
          type: "datetime",
          labels: {
            datetimeUTC: false,
            datetimeFormatter: {
              year: "yyyy",
              month: "dd MMM",
              day: "dd MMM",
              hour: "HH:mm",
            },
          },
          title: {
            text: "Date/Time",
            rotate: -90,
            offsetX: 0,
            offsetY: 0,
            style: {
              color: undefined,
              fontSize: "12px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 600,
              cssClass: "apexcharts-yaxis-title",
            },
          },
        },
        yaxis: {
          show: true,
          //   min: 0,
          //   max: 10,
          title: {
            text: "RSSI (dB)",
            rotate: -90,
            offsetX: 0,
            offsetY: 0,
            style: {
              color: undefined,
              fontSize: "12px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 600,
              cssClass: "apexcharts-yaxis-title",
            },
          },
        },

        stroke: {
          width: 1,
        },
        tooltip: {
          x: {
            format: "dd.MM.yyyy HH:mm",
          },
        },
      },
    });
    getthis.setState({
      rssiseries: rssi_values,
      showloader: false,
    });
  }

  generaterssichart(data) {
    let chart_values = [];
    var rssidata = {
      name: "RSSI",
      data: [],
    };

    if (data != null) {
      data.data.map(function (item, index, data) {
        rssidata.data.push({
          x: item.unixms * 1000,
          y: item.rssi_value,
        });
      });
    }
    if (rssidata.data == null) {
      chart_values = [rssidata];
    }
    // console.log("DATA ", [rssidata]);
    return [rssidata];
  }
  disabledDate(current) {
    // Can not select days before today and today
    return (
      (current && current > Moment().endOf("day")) ||
      (current && current > Moment().endOf("day"))
    );
  }
  rssidatechange(day) {
    var getthis = this;
    if (day == "one") {
      this.setState({
        active: true,
        active_week: false,
        active_month: false,
      });
      var to_date = new Date();
      var to_dd = String(to_date.getDate()).padStart(2, "0");
      var to_mm = String(to_date.getMonth() + 1).padStart(2, "0"); //January is 0!
      var to_yyyy = to_date.getFullYear();
      var to_date1 = to_yyyy + "-" + to_mm + "-" + to_dd;
      this.setState(
        {
          from_date: to_date1,
          to_date: to_date1,
        },
        () => {
          getthis.fetchrssidata();
        }
      );
    }
    if (day == "week") {
      this.setState({
        active: false,
        active_week: true,
        active_month: false,
      });
      var to_date = new Date();
      var to_dd = String(to_date.getDate()).padStart(2, "0");
      var to_mm = String(to_date.getMonth() + 1).padStart(2, "0"); //January is 0!
      var to_yyyy = to_date.getFullYear();
      var to_date1 = to_yyyy + "-" + to_mm + "-" + to_dd;

      var from_date = new Date();
      from_date.setDate(from_date.getDate() - 7);
      var from_dd = String(from_date.getDate()).padStart(2, "0");
      var from_mm = String(from_date.getMonth() + 1).padStart(2, "0"); //January is 0!
      var from_yyyy = from_date.getFullYear();
      var from_date1 = from_yyyy + "-" + from_mm + "-" + from_dd;

      this.setState(
        {
          from_date: from_date1,
          to_date: to_date1,
        },
        () => {
          this.fetchrssidata();
        }
      );
    }
    if (day == "month") {
      this.setState({
        active: false,
        active_week: false,
        active_month: true,
      });
      var to_date = new Date();
      var to_dd = String(to_date.getDate()).padStart(2, "0");
      var to_mm = String(to_date.getMonth() + 1).padStart(2, "0"); //January is 0!
      var to_yyyy = to_date.getFullYear();
      var to_date1 = to_yyyy + "-" + to_mm + "-" + to_dd;

      var from_date = new Date();
      from_date.setDate(from_date.getDate() - 30);
      var from_dd = String(from_date.getDate()).padStart(2, "0");
      var from_mm = String(from_date.getMonth() + 1).padStart(2, "0"); //January is 0!
      var from_yyyy = from_date.getFullYear();
      var from_date1 = from_yyyy + "-" + from_mm + "-" + from_dd;

      this.setState(
        {
          from_date: from_date1,
          to_date: to_date1,
        },
        () => {
          this.fetchrssidata();
        }
      );
    }
  }

  componentDidMount() {
    validate_Token();
    this.getcurrentData();
    //Get Total no of active sensors
    //fetching data for other data providers
    this.getcurrrentData();
  }
  getgatewayinfo(gateway) {
    // console.log("newgateway", gateway);
    if (gateway.length > 1) {
      var gateway_list = [];
      gateway.map(function (item, index, data) {
        gateway_list.push(item);
      });
      return [this.getstrongestrssi(gateway_list), gateway_list];
    } else {
      if (gateway[0] != undefined) {
        return [gateway[0]["name"], gateway];
      }
    }
  }
  getstrongestrssi(gateway_list) {
    var rssi = [];
    gateway_list.map(function (item, index, data) {
      rssi.push(item.rssi);
    });
    var m = Math.max(...rssi);
    const index = rssi.indexOf(m);
    // console.log("gatewaylist", gateway_list[index].name);
    return gateway_list[index].name;
  }
  batteryChart(station, location) {
    this.datechange("one");
    let getthis = this;
    getthis.setState({
      currentLocation: location,
      currentStation: station,
      modal_static: true,
    });
  }

  rssiChart(station, location) {
    this.rssidatechange("one");
    let getthis = this;
    getthis.setState({
      currentLocation: location,
      currentStation: station,
      modal_rssi: true,
    });
  }
  tabledatacreate(data) {
    let tabledata = [];
    let statusbadge;
    let gateway = [null];
    var getthis = this;
    data.map(function (item, index, data) {
    
      if (item.status == true) {
        statusbadge = (
          <div className="badge badge-soft-success font-size-12">Active</div>
        );
      } else {
        statusbadge = (
          <div className="badge badge-soft-danger font-size-12">Inactive</div>
        );
      }
      gateway = getthis.getgatewayinfo(item.gateway);
      var currentGW = "";

      if (gateway != undefined) {
        if (gateway[1].length > 1) {
          // currentGW = gateway[0]
          if (gateway[0] == "Gateway_1") {
            currentGW = "GW 1 - Chittur M.O";
          }
          if (gateway[0] == "Gateway_2") {
            currentGW = "GW 2 - Bharathamatha";
          }
          if (gateway[0] == "Gateway_3") {
            currentGW = "GW 3 - Ahalia";
          }
          if (gateway[0] == "Gateway_4") {
            currentGW = "GW 4 - Perumatty";
          }
          var gatewaylist = [];
          gateway[1].map(function (item) {
            if (item.name == "Gateway_1") {
              gatewaylist.push(
                <p>
                  {"ID : GW 1 | Name : Chittur M.O | " +
                    "RSSI : " +
                    item.rssi +
                    " | " +
                    "SNR : " +
                    item.lo_ra_snr +
                    "\n"}
                </p>
              );
              //  currentGW = "GW 1 - Chittur M.O";
            } else if (item.name == "Gateway_2") {
              gatewaylist.push(
                <p>
                  {"ID : GW 2 | Name : Bharathamatha | " +
                    "RSSI : " +
                    item.rssi +
                    " | " +
                    "SNR : " +
                    item.lo_ra_snr +
                    "\n"}
                </p>
              );
              // currentGW = "GW 2 - Bharathamatha";
            } else if (item.name == "Gateway_3") {
              gatewaylist.push(
                <p>
                  {"ID : GW 3 | Name : Ahalia | " +
                    "RSSI : " +
                    item.rssi +
                    " | " +
                    "SNR : " +
                    item.lo_ra_snr +
                    "\n"}
                </p>
              );
              // currentGW = "GW 3 - Ahalia";
            } else if (item.name == "Gateway_4") {
              gatewaylist.push(
                <p>
                  {"ID : GW 4 | Name : Perumatty | " +
                    "RSSI : " +
                    item.rssi +
                    " | " +
                    "SNR : " +
                    item.lo_ra_snr +
                    "\n"}
                </p>
              );
              // currentGW = "GW 4 - Perumatty";
            }
          });
          var gatewaylist1 = [];
          gatewaylist.map(function (item) {
            gatewaylist1.push(<p> {item.props.children}</p>);
          });

          gatewaylist.join("\r\n");
          getthis.setState({
            gatewaydetails: gatewaylist1,
          });
        } else if (gateway[0] == "Gateway_1") {
          currentGW = "GW 1 - Chittur M.O";
          getthis.setState({
            gatewaydetails: "ID : GW 1 | Name : Chittur M.O | " +
            "RSSI : " +
            gateway[1][0].rssi +
            " | " +
            "SNR : " +
            gateway[1][0].lo_ra_snr +
            "\n",
          });
        } else if (gateway[0] == "Gateway_2") {
          currentGW = "GW 2 - Bharathamatha";
          getthis.setState({
            gatewaydetails: "ID : GW 2 | Name : Bharathamatha | " +
            "RSSI : " +
            gateway[1][0].rssi +
            " | " +
            "SNR : " +
            gateway[1][0].lo_ra_snr +
            "\n",
          });
        } else if (gateway[0] == "Gateway_3") {
          currentGW = "GW 3 - Ahalia";
          getthis.setState({
            gatewaydetails: "ID : GW 3 | Name : Ahalia | " +
            "RSSI : " +
            gateway[1][0].rssi +
            " | " +
            "SNR : " +
            gateway[1][0].lo_ra_snr +
            "\n",
          });
        } else if (gateway[0] == "Gateway_4") {
          currentGW = "GW 4 - Perumatty";
          getthis.setState({
            gatewaydetails: "ID : GW 4 | Name : Perumatty | " +
            "RSSI : " +
            gateway[1][0].rssi +
            " | " +
            "SNR : " +
            gateway[1][0].lo_ra_snr +
            "\n",
          });
        }
      }
      var newdata = {
        id: index + 1,
        station: item["station"],
        rua: item["rua"],
        gateway: (
          <span>
            <UncontrolledTooltip target={"gw" + index} placement="top">
              {getthis.state.gatewaydetails}
            </UncontrolledTooltip>
            <p
              // title={getthis.state.gatewaydetails}
              id={"gw" + index}
              // onClick={(e) => {
              //   alert(getthis.state.gatewaydetails);
              // }}
              style={{ color: "cornflowerblue" }}
            >
              {currentGW}
            </p>{" "}
          </span>
        ),
        datetime: Moment(item["datetime"]).format("DD-MM-YYYY HH:mm:ss"),
        location: item["location"],
        battery: (
          <Link
            to="#"
            onClick={() =>
              getthis.batteryChart(item["station"], item["location"])
            }
          >
            <div>Link</div>
          </Link>
        ),
        rssi: (
          <Link
            to="#"
            onClick={() => getthis.rssiChart(item["station"], item["location"])}
          >
            <div>Link</div>
          </Link>
        ),
        // rssi:item.rssi,
        status: statusbadge,
      };
      tabledata.push(newdata);
      // console.log("items", newdata);
    });
    this.setState({
      mdbtable: tabledata,
    });
  }

  render() {
    const { t, i18n } = this.props;
    const ruaStyle = {
      color: "red",
      weight: 1.3,
      fillOpacity: 0,
    };
    const iconPerson = new L.Icon({
      iconUrl: require("../Dashboard/images/circle.png"),
      iconRetinaUrl: require("../Dashboard/images/circle.png"),
      iconAnchor: null,
      popupAnchor: null,
      shadowUrl: null,
      shadowSize: null,
      shadowAnchor: null,
      iconSize: new L.Point(60, 75),
      className: "leaflet-div-icon",
    });
    const random = Math.random() * (1 - 0.7) + 0.7;
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Row>
              <Col xs={12}>
                <h4
                  className="card-title"
                  style={{ padding: "5px", fontSize: "18px" }}
                >
                  {t("Sensor Diagnostics")}{" "}
                </h4>
              </Col>
              <Col xl={4}></Col>
            </Row>

            <Row>
              <Col lg={12}>
                <span>
                  <Row> 
                    <Col md={4}>
                      <Card>
                        <CardBody
                          style={{ height: "161px"}}
                        >
                          <Media>
                            <Media body className="overflow-hidden">
                              <p className="text-truncate font-size-14 mb-2">
                                Number of Sensors
                              </p>
                              {this.state.isdata ? (
                                <center>
                                  <h2
                                    className="mb-0"
                                    style={{ padding: "10%" }}
                                  >
                                    14
                                  </h2>
                                </center>
                              ) : (
                                <center>
                                  <ContentLoader
                                    width={100}
                                    height={80}
                                    viewBox="0 0 100 80"
                                    backgroundColor="#f3f3f3"
                                    foregroundColor="#ecebeb"
                                  >
                                    <circle cx="52" cy="39" r="25" />
                                  </ContentLoader>
                                </center>
                              )}
                            </Media>
                            <div className="text-primary">
                              <i className={"ri-cpu-line font-size-24"}></i>
                            </div>
                          </Media>
                        </CardBody>
                      </Card>
                      <Card></Card>
                    </Col>

                    <Col md={4}>
                      <Card>
                        <CardBody
                          style={{ height: "161px"}}
                        >
                          <Media>
                            <Media body className="overflow-hidden">
                              <p className="text-truncate font-size-14 mb-2">
                                Number of Gateways
                              </p>
                              {this.state.isdata ? (
                                <center>
                                  <h2
                                    className="mb-0"
                                    style={{ padding: "10%" }}
                                  >
                                    4
                                  </h2>
                                </center>
                              ) : (
                                <center>
                                  <ContentLoader
                                    width={100}
                                    height={80}
                                    viewBox="0 0 100 80"
                                    backgroundColor="#f3f3f3"
                                    foregroundColor="#ecebeb"
                                  >
                                    <circle cx="52" cy="39" r="25" />
                                  </ContentLoader>
                                </center>
                              )}
                            </Media>
                            <div className="text-primary">
                              <i className={" ri-router-line font-size-24"}></i>
                            </div>
                          </Media>
                        </CardBody>
                      </Card>
                      <Card></Card>
                    </Col>

                    <Col md={4}>
                      <Card>
                        <CardBody
                          style={{ minHeight: "161px"}}
                        >
                          <Media>
                            <Media body className="overflow-hidden">
                              <p className="text-truncate font-size-14 mb-2">
                                Sensor Status
                              </p>
                              {this.state.isdata ? (
                                <DiagnosticMap
                                  livedata={this.state.livestatus}
                                />
                              ) : (
                                <center>
                                  <ContentLoader
                                    width={100}
                                    height={80}
                                    viewBox="0 0 100 80"
                                    backgroundColor="#f3f3f3"
                                    foregroundColor="#ecebeb"
                                  >
                                    <circle cx="52" cy="39" r="25" />
                                  </ContentLoader>
                                </center>
                              )}
                            </Media>
                            <div className="text-primary">
                              <i
                                className={" ri-pie-chart-2-line font-size-24"}
                              ></i>
                            </div>
                          </Media>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <Card>
                    <CardBody>
                      <Row>
                        <Col md="3">
                          <FormGroup row>
                            <Label
                              className="col-form-label"
                              style={{ fontSize: "14px" }}
                            >
                              {t("Parameter")}{" "}
                            </Label>
                            <Col md={12}>
                              <select
                                className="form-select"
                                style={{ fontSize: "11px" }}
                                onChange={(e) => this.handleperilchange(e)}
                                value={this.state.parameter}
                                disabled={this.state.isdata ? false : true}
                              >
                                <option value="Temperature" selected>
                                  Temperature
                                </option>
                                <option value="Rainfall">Rainfall</option>
                                <option value="Wind_Speed">Wind Speed</option>
                                <option value="Wind_Direction">
                                  Wind Direction
                                </option>
                                <option value="UV_Index">UVI</option>
                                <option value="Humidity">
                                  Relative Humidity
                                </option>
                                <option value="Pressure">Pressure</option>
                                <option value="Battery">Battery</option>
                              </select>
                            </Col>
                          </FormGroup>
                        </Col>
                        <Col md="1">
                          <FormGroup>
                            <Label
                              className="col-md-12 col-form-label"
                              style={{ fontSize: "14px" }}
                            >
                              <span>&nbsp;</span>
                            </Label>
                            <Button
                              color="primary"
                              className="waves-effect waves-light mr-1"
                              style={{ fontSize: "11px", float: "right" }}
                              onClick={this.getcurrentData}
                              disabled={this.state.isdata ? false : true}
                            >
                              {t("Search")}
                            </Button>
                          </FormGroup>
                        </Col>
                        <Col md="2">
                          <FormGroup row>
                            <Label
                              className="col-form-label"
                              style={{ fontSize: "14px" }}
                            >
                              {t("Data Provider")}{" "}
                            </Label>
                            <Col md={9}>
                              <select
                                className="form-select"
                                style={{ fontSize: "11px" }}
                                onChange={this.handledpchange}
                                value={this.state.seldp}
                                disabled={
                                  this.state.selperil === "battery"
                                    ? true
                                    : false || this.state.isdata
                                    ? false
                                    : true
                                }
                              >
                                <option value="none" selected>
                                  None
                                </option>
                                <option value="darksky">Dark Sky</option>
                                <option value="openweather">
                                  Open weather
                                </option>
                              </select>
                            </Col>
                          </FormGroup>
                        </Col>

                        <Col md={2} style={{ marginTop: "4%" }}>
                          <div className="badge-desktop">
                            <center>
                              <div className="legendup"></div>
                              <p style={{ display: "inline-block" }}>Active</p>
                            </center>
                          </div>
                        </Col>
                        <Col md={2} style={{ marginTop: "4%" }}>
                          <div className="badge-desktop">
                            <center>
                              <div className="legenddown"></div>
                              <p style={{ display: "inline-block" }}>
                                Inactive
                              </p>
                            </center>
                          </div>
                        </Col>

                        <Col md={2} style={{ marginTop: "4%" }}>
                          <div className="badge-desktop">
                            <center>
                              <div className="legendoutrange"></div>
                              <p style={{ display: "inline-block" }}>Outlier</p>
                            </center>
                          </div>
                        </Col>

                        <span className="badge-mobile">
                          <div className="row">
                            <div class="col">
                              <center>
                                <div className="legendup"></div>
                                <p style={{ display: "inline-block" }}>
                                  Active
                                </p>
                              </center>
                            </div>
                            <div class="col">
                              {" "}
                              <center>
                                <div className="legenddown"></div>
                                <p style={{ display: "inline-block" }}>
                                  Inactive
                                </p>
                              </center>
                            </div>
                            <div class="col">
                              <center>
                                <div className="legendoutrange"></div>
                                <p style={{ display: "inline-block" }}>
                                  Outlier
                                </p>
                              </center>
                            </div>
                          </div>
                        </span>
                      </Row>
                    </CardBody>
                  </Card>
                  {this.state.isdata ? (
                    <MapContainer
                      center={[this.state.latnew, this.state.longnew]}
                      whenCreated={(map) => this.setState({ map })}
                      zoom={this.state.zoom}
                      scrollWheelZoom={false}
                      style={{
                        margin: "0px 0px 0px 0px",
                        minHeight: "500px",
                        position: "relative",
                      }}
                    >
                      <GeoJSON
                        style={ruaStyle}
                        data={rua.features}
                        onEachFeature={this.onEachrua}
                        icon={"text"}
                      />
                      {this.state.livestatus.map((points, key) => (
                        <CircleMarker
                          center={[points.lat, points.long]}
                          radius={7}
                          fillOpacity={1}
                          fillColor="#2899d1"
                          stroke={this.checkgateway(points.station)}
                          color="#000000"
                          position={(points.lat, points.long)}
                        >
                          <div>
                            <Tooltip
                              permanent
                              direction="bottom"
                              className="tooltipclass"
                              offset={[0, 7]}
                            >
                              <div
                                className={this.checkcolor(
                                  points.status,
                                  points[this.state.selperil],
                                  this.state.selperil
                                )}
                              >
                                {String(points[this.state.selperil].toFixed(2))}{" "}
                              </div>
                              <div>
                                {this.state.otherProviderdata.length > 0 &&
                                this.state.seldp != "none"
                                  ? this.state.otherProviderdata[key][
                                      points.station
                                    ][this.state.dtKey][this.state.seldp][
                                      this.state.selperil
                                    ].toFixed(2)
                                  : ""}
                              </div>
                            </Tooltip>
                          </div>
                          <Popup>
                            {points["location"]}
                            <br />
                          </Popup>
                        </CircleMarker>
                      ))}
                      <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                      />{" "}
                      ;
                      <SVGOverlay bounds={bounds}>
                        <text x="10%" y="70%" fill="#02b514">
                          Chittur
                        </text>
                        <text x="20%" y="95%" fill="#02b514">
                          Pattanchery
                        </text>
                        <text x="30%" y="80%" fill="#02b514">
                          Perumatty
                        </text>
                        <text x="40%" y="60%" fill="#02b514">
                          Nallepilly
                        </text>
                        <text x="60%" y="50%" fill="#02b514">
                          Kozhinjampara
                        </text>
                        <text x="75%" y="42%" fill="#02b514">
                          Eruthenpathy
                        </text>
                        <text x="70%" y="20%" fill="#02b514">
                          Vadakarapathy
                        </text>
                      </SVGOverlay>
                      <MapCustomControl>
                        <Row>
                          <Col xl={3} lg={4} sm={6}>
                            <i
                              className="fas fa-home"
                              onClick={this.onMapReset}
                              style={{ fontSize: "18px", cursor: "pointer" }}
                            ></i>
                          </Col>
                        </Row>
                      </MapCustomControl>
                    </MapContainer>
                  ) : (
                    <MapContainer
                      center={[this.state.latnew, this.state.longnew]}
                      whenCreated={(map) => this.setState({ map })}
                      zoom={this.state.zoom}
                      scrollWheelZoom={false}
                      style={{
                        margin: "0px 0px 0px 0px",
                        minHeight: "500px",
                        position: "relative",
                      }}
                    >
                      <GeoJSON
                        style={ruaStyle}
                        data={rua.features}
                        onEachFeature={this.onEachrua}
                        icon={"text"}
                      />
                      <Marker
                        position={[this.state.lat, this.state.long]}
                        icon={LoaderIcon}
                      ></Marker>
                      <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                      />{" "}
                      ;
                      <SVGOverlay bounds={bounds}>
                        <text x="10%" y="70%" fill="#02b514">
                          Chittur
                        </text>
                        <text x="20%" y="95%" fill="#02b514">
                          Pattanchery
                        </text>
                        <text x="30%" y="80%" fill="#02b514">
                          Perumatty
                        </text>
                        <text x="40%" y="60%" fill="#02b514">
                          Nallepilly
                        </text>
                        <text x="60%" y="50%" fill="#02b514">
                          Kozhinjampara
                        </text>
                        <text x="75%" y="42%" fill="#02b514">
                          Eruthenpathy
                        </text>
                        <text x="70%" y="20%" fill="#02b514">
                          Vadakarapathy
                        </text>
                      </SVGOverlay>
                      <MapCustomControl>
                        <Row>
                          <Col xl={3} lg={4} sm={6}>
                            <i
                              className="fas fa-home"
                              onClick={this.onMapReset}
                              style={{ fontSize: "18px", cursor: "pointer" }}
                            ></i>
                          </Col>
                        </Row>
                      </MapCustomControl>
                    </MapContainer>
                  )}
                </span>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    {this.state.isdata ? (
                      <DiagnosticTable livedata={this.state.mdbtable} />
                    ) : (
                      <div>
                        {Array(5)
                          .fill("")
                          .map((e, i) => (
                            <ContentLoader
                              height={40}
                              width={1060}
                              speed={2}
                              primaryColor="#d9d9d9"
                              secondaryColor="#ecebeb"
                            >
                              <rect
                                x="30"
                                y="15"
                                rx="4"
                                ry="4"
                                width="6"
                                height="6.4"
                              />
                              <rect
                                x="64"
                                y="13"
                                rx="6"
                                ry="6"
                                width={200 * random}
                                height="12"
                              />
                              <rect
                                x="643"
                                y="13"
                                rx="6"
                                ry="6"
                                width={23 * random}
                                height="12"
                              />
                              <rect
                                x="683"
                                y="13"
                                rx="6"
                                ry="6"
                                width={78 * random}
                                height="12"
                              />
                              <rect
                                x="785"
                                y="13"
                                rx="6"
                                ry="6"
                                width={117 * random}
                                height="12"
                              />
                              <rect
                                x="968"
                                y="13"
                                rx="6"
                                ry="6"
                                width={83 * random}
                                height="12"
                              />

                              <rect
                                x="0"
                                y="39"
                                rx="6"
                                ry="6"
                                width="1060"
                                height=".3"
                              />
                            </ContentLoader>
                          ))}
                      </div>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        <Modal
          isOpen={this.state.modal_static}
          //   toggle={this.tog_static}
          backdrop="static"
          centered
          size="xl"
          className="modal-dialog modal-dialog-scrollable"
        >
          <ModalHeader
            toggle={() => this.setState({ modal_static: false })}
            style={{ backgroundColor: "#A4C1D2" }}
          >
            Battery Historical Data
          </ModalHeader>
          <ModalBody>
            <Card>
              <CardBody>
                Location - {this.state.currentLocation}
                <div>
                  <ButtonToolbar>
                    <ButtonGroup size="sm" className="mt-2">
                      <Button
                        color="light"
                        active={this.state.active}
                        onClick={() => this.datechange("one")}
                      >
                        Today
                      </Button>
                      <Button
                        color="light"
                        active={this.state.active_week}
                        onClick={() => this.datechange("week")}
                      >
                        Last 7 days
                      </Button>
                      <Button
                        color="light"
                        active={this.state.active_month}
                        onClick={() => this.datechange("month")}
                      >
                        Last 30 days
                      </Button>
                    </ButtonGroup>
                    <RangePicker
                      size={"small"}
                      className="hist-date"
                      onChange={this.onChangedate}
                      format="DD-MM-YYYY"
                      disabledDate={this.disabledDate}
                    />
                  </ButtonToolbar>
                  <div style={this.state.showloader ? {} : { display: "none" }}>
                    <center>
                      <Spinner color="dark" />
                    </center>
                  </div>
                  <div style={this.state.showloader ? { display: "none" } : {}}>
                    <Chart
                      series={this.state.batteryseries}
                      options={this.state.batteryoptions}
                      type="line"
                      height="310"
                    />
                    <div style={{ "text-align": "center" }}>
                      <span
                        class="dot"
                        style={{ "background-color": "#0b6b75" }}
                      ></span>
                      <p
                        id="battery"
                        style={{
                          cursor: "pointer",
                          display: "inline-block",
                          "font-size": "12px",
                          "font-family": "Segoe UI",
                          paddingRight: "10px",
                          paddingLeft: "4px",
                        }}
                      >
                        Battery
                      </p>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </ModalBody>
        </Modal>

        <Modal
          isOpen={this.state.modal_rssi}
          //   toggle={this.tog_static}
          backdrop="static"
          centered
          size="xl"
          className="modal-dialog modal-dialog-scrollable"
        >
          <ModalHeader
            toggle={() => this.setState({ modal_rssi: false })}
            style={{ backgroundColor: "#A4C1D2" }}
          >
            RSSI Historical Data
          </ModalHeader>
          <ModalBody>
            <Card>
              <CardBody>
                Location - {this.state.currentLocation}
                <div>
                  <ButtonToolbar>
                    <ButtonGroup size="sm" className="mt-2">
                      <Button
                        color="light"
                        active={this.state.active}
                        onClick={() => this.rssidatechange("one")}
                      >
                        Today
                      </Button>
                      <Button
                        color="light"
                        active={this.state.active_week}
                        onClick={() => this.rssidatechange("week")}
                      >
                        Last 7 days
                      </Button>
                      <Button
                        color="light"
                        active={this.state.active_month}
                        onClick={() => this.rssidatechange("month")}
                      >
                        Last 30 days
                      </Button>
                    </ButtonGroup>
                    <RangePicker
                      size={"small"}
                      className="hist-date"
                      onChange={this.onChangerssidate}
                      format="DD-MM-YYYY"
                      disabledDate={this.disabledDate}
                    />
                  </ButtonToolbar>
                  <div style={this.state.showloader ? {} : { display: "none" }}>
                    <center>
                      <Spinner color="dark" />
                    </center>
                  </div>
                  <div style={this.state.showloader ? { display: "none" } : {}}>
                    <Chart
                      series={this.state.rssiseries}
                      options={this.state.rssioptions}
                      type="line"
                      height="310"
                    />
                    <div style={{ "text-align": "center" }}>
                      <span
                        class="dot"
                        style={{ "background-color": "#0b6b75" }}
                      ></span>
                      <p
                        id="battery"
                        style={{
                          cursor: "pointer",
                          display: "inline-block",
                          "font-size": "12px",
                          "font-family": "Segoe UI",
                          paddingRight: "10px",
                          paddingLeft: "4px",
                        }}
                      >
                        RSSI
                      </p>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </ModalBody>
        </Modal>
      </React.Fragment>
    );
  }
}
export default withNamespaces()(Sensor_Diagnostic);
