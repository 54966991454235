import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  Form,
  CardBody,
  CardTitle,
  CardSubtitle,
  Container,
  Button,
  FormGroup,
  Label,
} from "reactstrap";
import Moment from 'moment';
import Permission from "../../permission.js";
import { Spinner } from "reactstrap";
import { MDBDataTable } from "mdbreact";
// Breadcrumb
import validate_Token from "../../validateToken.js";
import { toast } from "react-toastify";
import { withNamespaces } from 'react-i18next';
toast.configure();
class FormUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabledata: [],
      showloader: false,
      showWaiting: true,
      rua: "all",
      season: "all",
      peril: "all",
      data_provider: [],
      crop: "all",
      showtable: false,
      seasonData: [],
      cropKey:1,
      userRole:"",
      cropData: ["Paddy",
      "Veg Group I",
      "Mango",
      "Paddy 2nd Crop",
      "Paddy 3rd Crop",
      "Paddy Winter",
      "Veg Group I - SMR"],
      dpData: ["AIC", "MeteoBlue", "Nasa Power", "ECMWF"],
      perilData: [
        "Deficit Rainfall",
        "Disease Congenial Climate",
        "Dry Spell",
        "Excess Rainfall",
        "High Temperature",
        "Low Temperature",
        "Rise in Night Temperature",
        "Rise in Temperature",
        "Unseasonal Rainfall",
        "Wet Spell",
        "Wind Speed",
        "Wind Speed (A)",
        "Wind Speed (B)",
        ],
      perilseasonData: [],
      dp: "all",
    };
    this.gettransactions = this.gettransactions.bind(this);
    this.onChange = this.onChange.bind(this);
    this.handleSeasonchange = this.handleSeasonchange.bind(this);
    this.handleCropchange = this.handleCropchange.bind(this);
    this.handlePerilchange = this.handlePerilchange.bind(this);
    this.handledpchange = this.handledpchange.bind(this);
    // this.checknull = this.checknull.bind(this);
  }
  handledpchange(e) {
    if(e.target.value=="DarkSky"){
      this.setState({
        dp: "darksky",
      });
    }else{
      this.setState({
        dp: e.target.value,
      });
    }
   
    // console.log("dp", e.target.value);
  }
  async gettransactions() {
    this.setState({
      showWaiting: false,
      showtable: false,
      showloader: true,
    });
    let searchdata = {
      rua: this.state.rua === "Chittur-Thathamangalam" ? "Chittur" : this.state.rua,
      season: this.state.season,
      crop: this.state.crop,
      peril: this.state.peril,
      data_provider: this.state.dp,
    };
    searchdata = JSON.stringify(searchdata);

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + localStorage.getItem("token"),
      },
      body: searchdata,
    };
    try {
      var transaction_data = await fetch(
        process.env.REACT_APP_APIEND + `smart_crop/transaction`,
        requestOptions
      ).then((res) => {
        return res.json();
      });
      this.generatetable(transaction_data.data);
    } catch (err) {
      toast.error("Something Happened!");
    }
  }
  async handleCropchange(e) {
    this.setState({
      crop: e.target.value,
    });
    let searchdata = {
      season: this.state.season,
      crop: e.target.value,
    };
    let selected_index = e.target.selectedIndex;
    if (selected_index == "0") {
      this.setState({
        perilData: [
        "Deficit Rainfall",
        "Disease Congenial Climate",
        "Dry Spell",
        "Excess Rainfall",
        "High Temperature",
        "Low Temperature",
        "Rise in Night Temperature",
        "Rise in Temperature",
        "Unseasonal Rainfall",
        "Wet Spell",
        "Wind Speed",
        "Wind Speed (A)",
        "Wind Speed (B)",
        ],
      });
    } else {
    searchdata = JSON.stringify(searchdata);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + localStorage.getItem("token"),
      },
      body: searchdata,
    };
    try {
      var perildata = await fetch(
        process.env.REACT_APP_APIEND + `smart_crop/get-perils`,
        requestOptions
      ).then((res) => {
        return res.json();
      });
      var newperils = perildata.result[0].perils;
      // console.log("PERIL DATA", newperils);
      this.setState({
        perilData: newperils,
      });
    } catch (err) {
      toast.error("Check Season");
    }
  }
}
  handlePerilchange(e) {
    this.setState({
      peril: e.target.value,
    });
  }
  generatetable(data) {
    // console.log("DATA ", data);
    let tabledata = [];
    var getthis = this;
    data.map(function (item, index, data) {
      var newdata = {
        trnID: Number(item.trnID),
        rua: item.rua,
        season: item.season,
        crop: item.crop,
        peril: item.peril,
        datetime:  Moment(item.date_time).format('DD-MM-YYYY'),
        phase: item.phase,
        calcval: item.calculated_value,
        unitcost: <p className="alnrighttd">{item.unit_cost}</p>,
        eligiblepay:parseFloat(item.eligible_payout).toFixed(2),
        max_payout: parseFloat(getthis.checknull(item.max_payout)).toFixed(2),
        dp: item.data_provider,
        // bcstatus:item.BCstatus
        // bcstatus: (
        //   <div>
        //     <p
        //       className="badge badge-soft-success font-size-12 alncenter"
        //       style={{ cursor: "pointer" }}
        //     >
        //       Pending
        //     </p>
        //     <a href="#" className="alncenter">verify</a>
        //   </div>
        // ),
      };
      tabledata.push(newdata);
    });
    this.setState({
      tabledata: tabledata,
      showloader: false,
      showtable: true,
    });
  }
  checknull(value) {
    // console.log("VALUEEEE ",value)
    if (value != null) {
      return value;
    } else {
      return ("0.00");
    }
  }
  onChange(ev) {
    this.setState({
      [ev.target.name]: ev.target.value,
    });
    if ([ev.target.name] == "season") {
      this.setState({
        [ev.target.name]: ev.target.value,
      });
      // console.log("clicked", ev.target.value);
    }
  }
  async fetchseason() {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + localStorage.getItem("token"),
      },
    };
    try {
      var seasonData = await fetch(
        process.env.REACT_APP_APIEND + `smart_crop/term-sheet-season-crop`,
        requestOptions
      ).then((res) => {
        return res.json();
      });
      this.setState({
        perilseasonData: seasonData.result,
      });

      var newseasonData = [];
      {
        seasonData.result.map(function (item, index, data) {
          newseasonData.push(Object.keys(item));
        });
      }
      // console.log("NEW DATA", newseasonData);
      this.setState({
        seasonData: newseasonData,
      });
    } catch (err) {
      toast.error("Something's not right");
    }
  }
  handleSeasonchange(e) {
    this.setState({
      season: e.target.value,
    });
    this.setState({
      cropData: [],
    });
    if (e.target.value == "Kharif 2020") {
      this.setState({
        dpData: ["AIC", "MeteoBlue", "Nasa Power", "ECMWF"],
        dp: "AIC",
      });
    } else if (e.target.value == "Rabi 2020-21") {
      this.setState({
        dpData: ["DarkSky"],
        dp: "darksky",
      });
    } else {
      this.setState({
        dpData: ["Online Sensors"],
        dp: "Online Sensors",
      })
    }
    let selected_index = e.target.selectedIndex;
    let season = e.target.value;
    let allcropdata = this.state.perilseasonData;
    if (selected_index == "0") {
      var list_array = [];
      allcropdata.map((index) => {
        list_array.push("---------" + Object.keys(index)[0] + "--------");
        // console.log("INDEX ", index[Object.keys(index)[0]]);
        var seasoncrop = index[Object.keys(index)[0]];
        seasoncrop.map((crop) => {
          list_array.push(crop);
        });
      });
      this.setState({
        cropData: list_array,

        cropKey:this.state.cropKey+1
      });
    } else {
      var seasoncrop = allcropdata[selected_index - 1];
      // console.log("Season crop", seasoncrop);
      var croplist = seasoncrop[Object.keys(seasoncrop)[0]];
      var list_array = [];
      croplist.map((item, index, data) => {
        list_array.push(item);
      });
      this.setState({
        cropData: list_array,
        cropKey:this.state.cropKey+1
      });
     
    }
  }
  componentDidMount() {
    validate_Token();
    this.fetchseason();
    const krishibhavan = Permission();
    if (krishibhavan[0] == "KB") {
      this.setState({
        userRole: "KB",
        rua: krishibhavan[1],
      });
    }
  }
  render() {
    const  {t,i18n} =this.props
    let data = {
      columns: [
        {
          label: "Trans. ID",
          field: "trnID",
          width: 100
        },
        {
          label: "RUA",
          field: "rua",
          width: 150
        },
        {
          label: "Season",
          field: "season",
          width: 150
        },
        {
          label: "Crop",
          field: "crop",
          width: 150
        },
        {
          label: "Peril",
          field: "peril",
          width: 150
        },
        {
          label: "Date",
          field: "datetime",
          width: 150,
          sort:"disabled"
        },
        {
          label: "Phase",
          field: "phase",
          width: 150
        },
        // {
        //   label: "Calculated value",
        //   field: "calcval",
        //   width: 150
        // },
        // {
        //   label: "Unit cost",
        //   field: "unitcost",
        //   width: 150
        // },
        {
          label: "Maximum payout / hectare",
          field: "max_payout",
          width: 150
        },
        {
          label: "Eligible payout",
          field: "eligiblepay",
          width: 150
        },
        
        {
          label: "Data provider",
          field: "dp",
          width: 150
        },
        // {
        //   label: "BC Status",
        //   field: "bcstatus",
        //   sort: "asc",
        // },
      ],
      rows: this.state.tabledata,
    };
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Row>
              <Col xs={12}>
                <h4
                  className="card-title"
                  style={{ padding: "5px", fontSize: "18px" }}
                >
                  {" "}
                  {t("Payout Transactions - RUA")}   
                  
                </h4>
              </Col>
              </Row>
              <Row>
                <Col>
                  <Card style={{ backgroundColor: "#f1f6fb" }}>
                    <CardBody>
                      <Col>
                        <Row>
                          <Col md="3">
                            <FormGroup>
                              <Label
                                className="col-md-12 col-form-label"
                                style={{ fontSize: "14px" }}
                              >
                                {t("RUA")}
                              </Label>
                              <select
                                className="form-select"
                                md="6"
                                style={{ fontSize: "11px" }}
                                name="rua"
                                value={this.state.rua}
                                onChange={this.onChange}
                              >
                                <option value="all"
                                style={
                                  this.state.userRole == "KB"
                                    ? { display: "none" }
                                    : {}
                                }
                                >
                                  All
                                </option>
                                <option
                                value="Chittur-Thathamangalam"
                                style={
                                  this.state.userRole == "KB"
                                    ? this.state.rua == "Chittur" || this.state.rua == "Chittur-Thathamangalam"
                                      ? {}
                                      : { display: "none" }
                                    : {}
                                }
                              >
                                Chittur-Thathamangalam
                              </option>
                              <option
                                value="Eruthenpathy"
                                style={
                                  this.state.userRole == "KB"
                                    ? this.state.rua == "Eruthenpathy"
                                      ? {}
                                      : { display: "none" }
                                    : {}
                                }
                              >
                                Eruthenpathy{" "}
                              </option>
                              <option
                                value="Kozhinjampara"
                                style={
                                  this.state.userRole == "KB"
                                    ? this.state.rua == "Kozhinjampara"
                                      ? {}
                                      : { display: "none" }
                                    : {}
                                }
                              >
                                Kozhinjampara
                              </option>
                              <option
                                value="Nallepilly"
                                style={
                                  this.state.userRole == "KB"
                                    ? this.state.rua == "Nallepilly"
                                      ? {}
                                      : { display: "none" }
                                    : {}
                                }
                              >
                                Nallepilly
                              </option>
                              <option
                                value="Pattanchery"
                                style={
                                  this.state.userRole == "KB"
                                    ? this.state.rua == "Pattanchery"
                                      ? {}
                                      : { display: "none" }
                                    : {}
                                }
                              >
                                Pattanchery
                              </option>
                              <option
                                value="Perumatty"
                                style={
                                  this.state.userRole == "KB"
                                    ? this.state.rua == "Perumatty"
                                      ? {}
                                      : { display: "none" }
                                    : {}
                                }
                              >
                                Perumatty
                              </option>
                              <option
                                value="Vadakarapathy"
                                style={
                                  this.state.userRole == "KB"
                                    ? this.state.rua == "Vadakarapathy"
                                      ? {}
                                      : { display: "none" }
                                    : {}
                                }
                              >
                                Vadakarapathy
                              </option>
                              </select>
                            </FormGroup>
                          </Col>

                          <Col md="2">
                            <FormGroup>
                              <Label
                                className="col-md-10 col-form-label"
                                style={{ fontSize: "14px" }}
                              >
                                {t("Season")}{" "}
                              </Label>
                              <select
                                className="form-select"
                                style={{ fontSize: "11px" }}
                                onChange={this.handleSeasonchange}
                                name="season"
                              >
                                <option value="all">All</option>
                                {this.state.seasonData.length > 0 &&
                                  this.state.seasonData.map((item) => (
                                    <option value={item} key={item}>
                                      {item}
                                    </option>
                                  ))}
                              </select>
                            </FormGroup>
                          </Col>
                          <Col md="2">
                            <FormGroup>
                              <Label
                                className="col-md-10 col-form-label"
                                style={{ fontSize: "14px" }}
                              >
                                {t("Crop")}{" "}
                              </Label>
                              <select
                                className="form-select"
                                style={{ fontSize: "11px" }}
                                onChange={this.handleCropchange}
                                name="crop"
                                key={this.state.cropKey}
                              >
                                <option value="all">All</option>
                              {this.state.cropData.length > 0 &&
                                this.state.cropData.map((item) => (
                                  <option value={item} key={item}>
                                    {item}
                                  </option>
                                ))}
                              </select>
                            </FormGroup>
                          </Col>
                          <Col md="2">
                          <FormGroup>
                            <Label
                              className="col-md-10 col-form-label"
                              style={{ fontSize: "14px" }}
                            >
                              {t("Peril")}{" "}
                            </Label>
                            <select
                              className="form-select"
                              style={{ fontSize: "11px" }}
                              onChange={this.handlePerilchange}
                            >
                              <option value="all">All</option>
                              {this.state.perilData.length > 0 &&
                                this.state.perilData.map((item) => (
                                  <option value={item} key={item}>
                                    {item}
                                  </option>
                                ))}
                            </select>
                          </FormGroup>
                          </Col>
                          <Col md="2">
                            <FormGroup>
                            <Label
                              className="col-md-12 col-form-label"
                              style={{ fontSize: "14px" }}
                            >
                              {t("Data Provider")}
                            </Label>
                            <select
                              className="form-select"
                              style={{ fontSize: "11px" }}
                              onChange={this.handledpchange}
                            >     
                              <option value="all">All</option>
                              {this.state.dpData.length > 0 &&
                                this.state.dpData.map((item) => (
                                  <option value={item} key={item}>
                                    {item}
                                  </option>
                                ))}
                              {/* <option value="AIC">AIC</option>
                              <option value="ECMWF">ECMWF</option>
                              <option value="Meteoblue">Meteoblue</option>
                              <option value="NASA PowerLarc">Nasa</option>
                              <option value="darksky">Dark Sky</option> */}
                            </select>
                          </FormGroup>
                          </Col>
                          <Col md="1">
                            <FormGroup>
                              <Label
                                className="col-md-12 col-form-label"
                                style={{ fontSize: "14px" }}
                              >
                                <span>&nbsp;</span>
                              </Label>
                              <Button
                                color="primary"
                                className="waves-effect waves-light mr-1"
                                style={{ fontSize: "11px", float: "right" }}
                                onClick={this.gettransactions}
                              >
                                {t("Search")}
                              </Button>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col></Col>
                        </Row>
                      </Col>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Card>
                    <CardBody>
                      <center>
                        <p
                          style={
                            this.state.showWaiting ? {} : { display: "none" }
                          }
                        >
                          Waiting for search
                        </p>
                        <Spinner
                          style={
                            this.state.showloader ? {} : { display: "none" }
                          }
                        ></Spinner>
                      </center>
                      <div
                        id="payout-transaction"
                        style={this.state.showtable ? {} : { display: "none" }}
                      >
                        <MDBDataTable
                          paging={true}
                          bordered
                          scrollX
                          data={data}
                          order={["trnID", "asc"]}
                          className="datadownloadtable"
                          style={{ fontSize: "12px" }}
                          maxHeight="300px"
                        />
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default withNamespaces()(FormUpload);

