import React from "react";
export default function Mmessages(props) {
  var template = {
    "Day End": {
      "Kharif 2020": {
        1: {
          Paddy: [
            {
              "Dry Spell":
                "The aggregate rainfall is" +
                props.calculated_value +
                " mm (Trigger : <2.5 mm)",

              "Disease Congenial Climate":
                "The daily Avg temperature is " +
                props.calculated_value2 +
                " °C & the daily average humidity is" +
                props.calculated_value +
                "% (Avg. Temperature > 32 °C; Avg. Rel. Humidity between 80% & 90%)",
              "High Temperature":
                "The daily max Temperature is " +
                props.calculated_value2 +
                "(Trigger : max temperature > 31 °C)",
            },
          ],
          "Veg Group I": [
            {
              "Dry Spell":
                "The aggregate rainfall is" +
                props.calculated_value +
                " mm (Trigger : <2.5 mm)",

              "Disease Congenial Climate":
                "The daily Max temperature is " +
                props.calculated_value2 +
                " °C & the daily average humidity is" +
                props.calculated_value +
                "% (Trigger : Max. Temperature between 28°C & 32°C; Avg. Rel. Humidity > 80%)",
              "Excess Rainfall":
                "The daily cummulative rainfall is " +
                props.calculated_value +
                "mm (Trigger : Cummulative rainfall > 75)",

              "Rise in Temperature":
                "The daily min Temperature is " +
                props.calculated_value2 +
                "°C (Trigger : min temperature > 36°C)",
            },
          ],
        },
        2: {
          Paddy: [
            {
              "Dry Spell":
                "The aggregate rainfall is" +
                props.calculated_value +
                " mm (Trigger : <2.5 mm)",
              "Disease Congenial Climate":
                "The daily Avg temperature is " +
                props.calculated_value2 +
                " °C & the daily average humidity is" +
                props.calculated_value +
                "% (Avg. Temperature > 32 °C; Avg. Rel. Humidity between 80% & 90%)",
              "High Temperature":
                "The daily max Temperature is " +
                props.calculated_value2 +
                "(Trigger : max temperature > 31.5 °C)",
            },
          ],
          "Veg Group I": [
            {
              "Dry Spell":
                "The aggregate rainfall is" +
                props.calculated_value +
                " mm (Trigger : <2.5 mm)",
              "Disease Congenial Climate":
                "The daily Max temperature is " +
                props.calculated_value2 +
                " °C & the daily average humidity is" +
                props.calculated_value +
                "% (Trigger : Max. Temperature between 28°C & 32°C; Avg. Rel. Humidity > 80%)",
              "Excess Rainfall":
                "The daily cummulative rainfall is " +
                props.calculated_value +
                "mm (Trigger : Cummulative rainfall > 25)",
              "Rise in Temperature":
                "The daily min Temperature is " +
                props.calculated_value2 +
                "(Trigger : min temperature > 36°C)",
            },
          ],
        },
        3: {
          Paddy: [
            {
              "Dry Spell":
                "The aggregate rainfall is" +
                props.calculated_value +
                " mm (Trigger : <2.5 mm)",
              "Disease Congenial Climate":
                "The daily Avg temperature is " +
                props.calculated_value2 +
                " °C & the daily average humidity is" +
                props.calculated_value +
                "% (Avg. Temperature > 32 °C; Avg. Rel. Humidity between 80% & 90%)",
              "High Temperature":
                "The daily max Temperature is " +
                props.calculated_value2 +
                "(Trigger : max temperature > 32 °C)",
            },
          ],
          "Veg Group I": [
            {
              "Dry Spell":
                "The aggregate rainfall is" +
                props.calculated_value +
                " mm (Trigger : <2.5 mm)",
              "Disease Congenial Climate":
                "The daily Max temperature is " +
                props.calculated_value2 +
                " °C & the daily average humidity is" +
                props.calculated_value +
                "% (Trigger : Max. Temperature between 28°C & 32°C; Avg. Rel. Humidity > 80%)",
              "Excess Rainfall":
                "The daily cummulative rainfall is " +
                props.calculated_value +
                "mm (Trigger : Cummulative rainfall > 25)",
              "Rise in Temperature":
                "The daily min Temperature is " +
                props.calculated_value2 +
                "(Trigger : min temperature > 36°C)",
            },
          ],
        },
        4: {
          Paddy: [
            {
              "Dry Spell":
                "The aggregate rainfall is" +
                props.calculated_value +
                " mm (Trigger : <2.5 mm)",
              "Disease Congenial Climate":
                "The daily Avg temperature is " +
                props.calculated_value2 +
                " °C & the daily average humidity is" +
                props.calculated_value +
                "% (Avg. Temperature > 32 °C; Avg. Rel. Humidity between 80% & 90%)",
              "High Temperature":
                "The daily max Temperature is " +
                props.calculated_value2 +
                "(Trigger : max temperature > 32.5 °C)",
            },
          ],
          "Veg Group I": [
            {
              "Dry Spell":
                "The aggregate rainfall is" +
                props.calculated_value +
                " mm (Trigger : <2.5 mm)",
              "Disease Congenial Climate":
                "The daily Max temperature is " +
                props.calculated_value2 +
                " °C & the daily average humidity is" +
                props.calculated_value +
                "% (Trigger : Max. Temperature between 28°C & 32°C; Avg. Rel. Humidity > 80%)",
              "Excess Rainfall":
                "The daily cummulative rainfall is " +
                props.calculated_value +
                "mm (Trigger : Cummulative rainfall > 25)",
              "Rise in Temperature":
                "The daily min Temperature is " +
                props.calculated_value2 +
                "(Trigger : min temperature > 36°C)",
            },
          ],
        },
      },
      "Kharif 2021": {
        1: {
          Paddy: [
            {
              "Dry Spell":
                "The aggregate rainfall is" +
                props.calculated_value +
                " mm (Trigger : <2.5 mm)",

              "Disease Congenial Climate":
                "The daily Avg temperature is " +
                props.calculated_value2 +
                " °C & the daily average humidity is" +
                props.calculated_value +
                "% (Avg. Temperature > 32 °C; Avg. Rel. Humidity between 80% & 90%)",
              "High Temperature":
                "The daily max Temperature is " +
                props.calculated_value2 +
                "(Trigger : max temperature > 31 °C)",
            },
          ],
          "Veg Group I": [
            {
              "Dry Spell":
                "The aggregate rainfall is" +
                props.calculated_value +
                " mm (Trigger : <2.5 mm)",

              "Disease Congenial Climate":
                "The daily Max temperature is " +
                props.calculated_value2 +
                " °C & the daily average humidity is" +
                props.calculated_value +
                "% (Trigger : Max. Temperature between 28°C & 32°C; Avg. Rel. Humidity > 80%)",
              "Excess Rainfall":
                "The daily cummulative rainfall is " +
                props.calculated_value +
                "mm (Trigger : Cummulative rainfall > 75)",

              "Rise in Temperature":
                "The daily min Temperature is " +
                props.calculated_value2 +
                "°C (Trigger : min temperature > 36°C)",
            },
          ],
        },
        2: {
          Paddy: [
            {
              "Dry Spell":
                "The aggregate rainfall is" +
                props.calculated_value +
                " mm (Trigger : <2.5 mm)",
              "Disease Congenial Climate":
                "The daily Avg temperature is " +
                props.calculated_value2 +
                " °C & the daily average humidity is" +
                props.calculated_value +
                "% (Avg. Temperature > 32 °C; Avg. Rel. Humidity between 80% & 90%)",
              "High Temperature":
                "The daily max Temperature is " +
                props.calculated_value2 +
                "(Trigger : max temperature > 31.5 °C)",
            },
          ],
          "Veg Group I": [
            {
              "Dry Spell":
                "The aggregate rainfall is" +
                props.calculated_value +
                " mm (Trigger : <2.5 mm)",
              "Disease Congenial Climate":
                "The daily Max temperature is " +
                props.calculated_value2 +
                " °C & the daily average humidity is" +
                props.calculated_value +
                "% (Trigger : Max. Temperature between 28°C & 32°C; Avg. Rel. Humidity > 80%)",
              "Excess Rainfall":
                "The daily cummulative rainfall is " +
                props.calculated_value +
                "mm (Trigger : Cummulative rainfall > 25)",
              "Rise in Temperature":
                "The daily min Temperature is " +
                props.calculated_value2 +
                "(Trigger : min temperature > 36°C)",
            },
          ],
        },
        3: {
          Paddy: [
            {
              "Dry Spell":
                "The aggregate rainfall is" +
                props.calculated_value +
                " mm (Trigger : <2.5 mm)",
              "Disease Congenial Climate":
                "The daily Avg temperature is " +
                props.calculated_value2 +
                " °C & the daily average humidity is" +
                props.calculated_value +
                "% (Avg. Temperature > 32 °C; Avg. Rel. Humidity between 80% & 90%)",
              "High Temperature":
                "The daily max Temperature is " +
                props.calculated_value2 +
                "(Trigger : max temperature > 32 °C)",
            },
          ],
          "Veg Group I": [
            {
              "Dry Spell":
                "The aggregate rainfall is" +
                props.calculated_value +
                " mm (Trigger : <2.5 mm)",
              "Disease Congenial Climate":
                "The daily Max temperature is " +
                props.calculated_value2 +
                " °C & the daily average humidity is" +
                props.calculated_value +
                "% (Trigger : Max. Temperature between 28°C & 32°C; Avg. Rel. Humidity > 80%)",
              "Excess Rainfall":
                "The daily cummulative rainfall is " +
                props.calculated_value +
                "mm (Trigger : Cummulative rainfall > 25)",
              "Rise in Temperature":
                "The daily min Temperature is " +
                props.calculated_value2 +
                "(Trigger : min temperature > 36°C)",
            },
          ],
        },
        4: {
          Paddy: [
            {
              "Dry Spell":
                "The aggregate rainfall is" +
                props.calculated_value +
                " mm (Trigger : <2.5 mm)",
              "Disease Congenial Climate":
                "The daily Avg temperature is " +
                props.calculated_value2 +
                " °C & the daily average humidity is" +
                props.calculated_value +
                "% (Avg. Temperature > 32 °C; Avg. Rel. Humidity between 80% & 90%)",
              "High Temperature":
                "The daily max Temperature is " +
                props.calculated_value2 +
                "(Trigger : max temperature > 32.5 °C)",
            },
          ],
          "Veg Group I": [
            {
              "Dry Spell":
                "The aggregate rainfall is" +
                props.calculated_value +
                " mm (Trigger : <2.5 mm)",
              "Disease Congenial Climate":
                "The daily Max temperature is " +
                props.calculated_value2 +
                " °C & the daily average humidity is" +
                props.calculated_value +
                "% (Trigger : Max. Temperature between 28°C & 32°C; Avg. Rel. Humidity > 80%)",
              "Excess Rainfall":
                "The daily cummulative rainfall is " +
                props.calculated_value +
                "mm (Trigger : Cummulative rainfall > 25)",
              "Rise in Temperature":
                "The daily min Temperature is " +
                props.calculated_value2 +
                "(Trigger : min temperature > 36°C)",
            },
          ],
        },
      },
      "Kharif 2022": {
        1: {
          Paddy: [
            {
              "Dry Spell":
                "The aggregate rainfall is" +
                props.calculated_value +
                " mm (Trigger : <2.5 mm)",

              "Disease Congenial Climate":
                "The daily Avg temperature is " +
                props.calculated_value2 +
                " °C & the daily average humidity is" +
                props.calculated_value +
                "% (Avg. Temperature > 32 °C; Avg. Rel. Humidity between 80% & 90%)",
              "High Temperature":
                "The daily max Temperature is " +
                props.calculated_value2 +
                "(Trigger : max temperature > 31 °C)",
            },
          ],
          "Veg Group I": [
            {
              "Dry Spell":
                "The aggregate rainfall is" +
                props.calculated_value +
                " mm (Trigger : <2.5 mm)",

              "Disease Congenial Climate":
                "The daily Max temperature is " +
                props.calculated_value2 +
                " °C & the daily average humidity is" +
                props.calculated_value +
                "% (Trigger : Max. Temperature between 28°C & 32°C; Avg. Rel. Humidity > 80%)",
              "Excess Rainfall":
                "The daily cummulative rainfall is " +
                props.calculated_value +
                "mm (Trigger : Cummulative rainfall > 75)",

              "Rise in Temperature":
                "The daily min Temperature is " +
                props.calculated_value2 +
                "°C (Trigger : min temperature > 36°C)",
            },
          ],
        },
        2: {
          Paddy: [
            {
              "Dry Spell":
                "The aggregate rainfall is" +
                props.calculated_value +
                " mm (Trigger : <2.5 mm)",
              "Disease Congenial Climate":
                "The daily Avg temperature is " +
                props.calculated_value2 +
                " °C & the daily average humidity is" +
                props.calculated_value +
                "% (Avg. Temperature > 32 °C; Avg. Rel. Humidity between 80% & 90%)",
              "High Temperature":
                "The daily max Temperature is " +
                props.calculated_value2 +
                "(Trigger : max temperature > 31.5 °C)",
            },
          ],
          "Veg Group I": [
            {
              "Dry Spell":
                "The aggregate rainfall is" +
                props.calculated_value +
                " mm (Trigger : <2.5 mm)",
              "Disease Congenial Climate":
                "The daily Max temperature is " +
                props.calculated_value2 +
                " °C & the daily average humidity is" +
                props.calculated_value +
                "% (Trigger : Max. Temperature between 28°C & 32°C; Avg. Rel. Humidity > 80%)",
              "Excess Rainfall":
                "The daily cummulative rainfall is " +
                props.calculated_value +
                "mm (Trigger : Cummulative rainfall > 25)",
              "Rise in Temperature":
                "The daily min Temperature is " +
                props.calculated_value2 +
                "(Trigger : min temperature > 36°C)",
            },
          ],
        },
        3: {
          Paddy: [
            {
              "Dry Spell":
                "The aggregate rainfall is" +
                props.calculated_value +
                " mm (Trigger : <2.5 mm)",
              "Disease Congenial Climate":
                "The daily Avg temperature is " +
                props.calculated_value2 +
                " °C & the daily average humidity is" +
                props.calculated_value +
                "% (Avg. Temperature > 32 °C; Avg. Rel. Humidity between 80% & 90%)",
              "High Temperature":
                "The daily max Temperature is " +
                props.calculated_value2 +
                "(Trigger : max temperature > 32 °C)",
            },
          ],
          "Veg Group I": [
            {
              "Dry Spell":
                "The aggregate rainfall is" +
                props.calculated_value +
                " mm (Trigger : <2.5 mm)",
              "Disease Congenial Climate":
                "The daily Max temperature is " +
                props.calculated_value2 +
                " °C & the daily average humidity is" +
                props.calculated_value +
                "% (Trigger : Max. Temperature between 28°C & 32°C; Avg. Rel. Humidity > 80%)",
              "Excess Rainfall":
                "The daily cummulative rainfall is " +
                props.calculated_value +
                "mm (Trigger : Cummulative rainfall > 25)",
              "Rise in Temperature":
                "The daily min Temperature is " +
                props.calculated_value2 +
                "(Trigger : min temperature > 36°C)",
            },
          ],
        },
        4: {
          Paddy: [
            {
              "Dry Spell":
                "The aggregate rainfall is" +
                props.calculated_value +
                " mm (Trigger : <2.5 mm)",
              "Disease Congenial Climate":
                "The daily Avg temperature is " +
                props.calculated_value2 +
                " °C & the daily average humidity is" +
                props.calculated_value +
                "% (Avg. Temperature > 32 °C; Avg. Rel. Humidity between 80% & 90%)",
              "High Temperature":
                "The daily max Temperature is " +
                props.calculated_value2 +
                "(Trigger : max temperature > 32.5 °C)",
            },
          ],
          "Veg Group I": [
            {
              "Dry Spell":
                "The aggregate rainfall is" +
                props.calculated_value +
                " mm (Trigger : <2.5 mm)",
              "Disease Congenial Climate":
                "The daily Max temperature is " +
                props.calculated_value2 +
                " °C & the daily average humidity is" +
                props.calculated_value +
                "% (Trigger : Max. Temperature between 28°C & 32°C; Avg. Rel. Humidity > 80%)",
              "Excess Rainfall":
                "The daily cummulative rainfall is " +
                props.calculated_value +
                "mm (Trigger : Cummulative rainfall > 25)",
              "Rise in Temperature":
                "The daily min Temperature is " +
                props.calculated_value2 +
                "(Trigger : min temperature > 36°C)",
            },
          ],
        },
      },
      "Rabi 2020-21": {
        1: {
          "Veg Group I": [
            {
              "Dry Spell":
                "The aggregate rainfall is" +
                props.calculated_value +
                " mm (Trigger : <2.5 mm)",
              "Disease Congenial Climate":
                "The daily max temperature is " +
                props.calculated_value2 +
                " °C & the daily average humidity is" +
                props.calculated_value +
                "% (Max. Temperature between 28°C & 32°C; Avg. Rel. Humidity > 80%)",
              "Excess Rainfall":
                "The daily cummulative rainfall is " +
                props.calculated_value +
                "mm (Trigger : Cummulative rainfall > 15)",
              "Rise in Temperature":
                "The daily min Temperature is " +
                props.calculated_value2 +
                "(Trigger : min temperature > 36°C)",
            },
          ],
          Mango: [
            {
              "Disease Congenial Climate":
                "The daily min temperature is " +
                props.calculated_value2 +
                " °C & the daily average humidity is" +
                props.calculated_value +
                "% (Min. Temperature < 24 °C; Avg. Rel. Humidity > 80%)",
              "Excess Rainfall":
                "The Calculated Value is " +
                props.calculated_value +
                "mm (Trigger : Cummulative rainfall > 10)",
              "Wind Speed":
                "The daily cummulative wind speed is " +
                props.calculated_value +
                "km/h (Trigger : Cummulative wind speed > 45 km/h)",
            },
          ],
          "Paddy 3rd Crop": [
            {
              "Disease Congenial Climate":
                "The daily max temperature is " +
                props.calculated_value2 +
                " & the daily average humidity is " +
                props.calculated_value +
                "% (Max Temperature > 32 °C; Avg. Rel. Humidity between 80% & 90%)",

              "High Temperature":
                "The daily max Temperature is " +
                props.calculated_value2 +
                "(Trigger : max temperature > 34.5 °C)",
            },
          ],
          "Veg Group I - SMR": [
            {
              "Disease Congenial Climate":
                "The daily max temperature is " +
                props.calculated_value2 +
                " & the daily average humidity is " +
                props.calculated_value +
                "% (Max Temperature between 28°C & 33°C ;  Avg. Rel. Humidity > 80%)",
              "Rise in Temperature":
                "The daily min temperature is " +
                props.calculated_value2 +
                "(Trigger : Min Temperature > 36°C)",
              "Excess Rainfall":
                "The Calculated Value is " +
                props.calculated_value +
                "mm (Trigger : Cummulative rainfall > 15)",
            },
          ],
          "Paddy Winter": [
            {
              "Disease Congenial Climate":
                "The daily avg temperature is " +
                props.calculated_value2 +
                " & the daily average humidity is " +
                props.calculated_value +
                "% (Avg Temperature >32°C ;  Avg. Rel. Humidity between 80% & 90%)",
              "High Temperature":
                "The daily max Temperature is " +
                props.calculated_value2 +
                "(Trigger : Max temperature > 33°C)",
            },
          ],
          "Paddy 2nd Crop": [
            {
              "Excess Rainfall":
                "The daily cummulative rainfall is " +
                props.calculated_value +
                "mm (Trigger : Cummulative rainfall > 30)",
              "Unseasonal Rainfall":
                "The daily cummulative rainfall is " +
                props.calculated_value +
                "mm (Trigger : Cummulative rainfall > 30)",
              "Wind Speed (A)":
                "The daily cummulative wind speed is " +
                props.calculated_value +
                "km/h (Trigger : Cummulative wind speed > 45 km/h)",
              "Wind Speed (B)":
                "The daily cummulative wind speed is " +
                props.calculated_value +
                "km/h (Trigger : Cummulative wind speed > 40 km/h)",
            },
          ],
        },
        2: {
          "Veg Group I": [
            {
              "Dry Spell":
                "The aggregate rainfall is" +
                props.calculated_value +
                " mm (Trigger : <2.5 mm)",
              "Disease Congenial Climate":
                "The daily max temperature is " +
                props.calculated_value2 +
                " °C & the daily average humidity is" +
                props.calculated_value +
                "% (Max. Temperature between 28°C & 32°C; Avg. Rel. Humidity > 80%)",
              "Excess Rainfall":
                "The daily cummulative rainfall is " +
                props.calculated_value +
                "mm (Trigger : Cummulative rainfall > 10)",
              "Rise in Temperature":
                "The daily min Temperature is " +
                props.calculated_value2 +
                "(Trigger : min temperature > 36°C)",
            },
          ],
          Mango: [
            {
              "Disease Congenial Climate":
                "The daily min temperature is " +
                props.calculated_value2 +
                " °C & the daily average humidity is" +
                props.calculated_value +
                "% (Min. Temperature < 24 °C; Avg. Rel. Humidity > 80%)",
              "Excess Rainfall":
                "The Calculated Value is " +
                props.calculated_value +
                "mm (Trigger : Cummulative rainfall > 5)",
              "Wind Speed":
                "The daily cummulative wind speed is " +
                props.calculated_value +
                "km/h (Trigger : Cummulative wind speed > 50 km/h)",
            },
          ],
          "Paddy 3rd Crop": [
            {
              "Disease Congenial Climate":
                "The daily max temperature is " +
                props.calculated_value2 +
                " & the daily average humidity is " +
                props.calculated_value +
                "% (Max Temperature > 32 °C; Avg. Rel. Humidity between 80% & 90%)",

              "High Temperature":
                "The daily max Temperature is " +
                props.calculated_value2 +
                "(Trigger : max temperature > 36 °C)",
            },
          ],
          "Veg Group I - SMR": [
            {
              "Disease Congenial Climate":
                "The daily max temperature is " +
                props.calculated_value2 +
                " & the daily average humidity is " +
                props.calculated_value +
                "% (Max Temperature between 28°C & 33°C ;  Avg. Rel. Humidity > 80%)",
              "Rise in Temperature":
                "The daily min temperature is " +
                props.calculated_value2 +
                "(Trigger : Min Temperature > 36°C)",
              "Excess Rainfall":
                "The Calculated Value is " +
                props.calculated_value +
                "mm (Trigger : Cummulative rainfall > 10)",
            },
          ],
          "Paddy Winter": [
            {
              "Disease Congenial Climate":
                "The daily avg temperature is " +
                props.calculated_value2 +
                " & the daily average humidity is " +
                props.calculated_value +
                "% (Avg Temperature >32°C ;  Avg. Rel. Humidity between 80% & 90%)",
              "High Temperature":
                "The daily max Temperature is " +
                props.calculated_value2 +
                "(Trigger : Max temperature > 33°C)",
            },
          ],
          "Paddy 2nd Crop": [
            {
              "Excess Rainfall":
                "The daily cummulative rainfall is " +
                props.calculated_value +
                "mm (Trigger : Cummulative rainfall > 30)",
              "Unseasonal Rainfall":
                "The daily cummulative rainfall is " +
                props.calculated_value +
                "mm (Trigger : Cummulative rainfall > 30)",
              "Wind Speed (A)":
                "The daily cummulative wind speed is " +
                props.calculated_value +
                "km/h (Trigger : Cummulative wind speed > 55 km/h)",
              "Wind Speed (B)":
                "The daily cummulative wind speed is " +
                props.calculated_value +
                "km/h (Trigger : Cummulative wind speed > 50 km/h)",
            },
          ],
        },
        3: {
          "Veg Group I": [
            {
              "Dry Spell":
                "The aggregate rainfall is" +
                props.calculated_value +
                " mm (Trigger : <2.5 mm)",
              "Disease Congenial Climate":
                "The daily max temperature is " +
                props.calculated_value2 +
                " °C & the daily average humidity is" +
                props.calculated_value +
                "% (Max. Temperature between 28°C & 32°C; Avg. Rel. Humidity > 80%)",
              "Excess Rainfall":
                "The daily cummulative rainfall is " +
                props.calculated_value +
                "mm (Trigger : Cummulative rainfall > 10)",
              "Rise in Temperature":
                "The daily min Temperature is " +
                props.calculated_value2 +
                "(Trigger : min temperature > 36°C)",
            },
          ],
          Mango: [
            {
              "Disease Congenial Climate":
                "The daily min temperature is " +
                props.calculated_value2 +
                " °C & the daily average humidity is" +
                props.calculated_value +
                "% (Min. Temperature < 24 °C; Avg. Rel. Humidity > 80%)",
              "Excess Rainfall":
                "The Calculated Value is " +
                props.calculated_value +
                "mm (Trigger : Cummulative rainfall > 10)",
              "Wind Speed":
                "The daily cummulative wind speed is " +
                props.calculated_value +
                "km/h (Trigger : Cummulative wind speed > 40 km/h)",
            },
          ],
          "Paddy 3rd Crop": [
            {
              "Disease Congenial Climate":
                "The daily max temperature is " +
                props.calculated_value2 +
                " & the daily average humidity is " +
                props.calculated_value +
                "% (Max Temperature > 32 °C; Avg. Rel. Humidity between 80% & 90%)",

              "High Temperature":
                "The daily max Temperature is " +
                props.calculated_value2 +
                "(Trigger : max temperature > 37 °C)",
            },
          ],
          "Veg Group I - SMR": [
            {
              "Disease Congenial Climate":
                "The daily max temperature is " +
                props.calculated_value2 +
                " & the daily average humidity is " +
                props.calculated_value +
                "% (Max Temperature between 28°C & 33°C ;  Avg. Rel. Humidity > 80%)",
              "Rise in Temperature":
                "The daily min temperature is " +
                props.calculated_value2 +
                "(Trigger : Min Temperature > 36°C)",
              "Excess Rainfall":
                "The Calculated Value is " +
                props.calculated_value +
                "mm (Trigger : Cummulative rainfall > 10)",
            },
          ],
          "Paddy Winter": [
            {
              "Disease Congenial Climate":
                "The daily avg temperature is " +
                props.calculated_value2 +
                " & the daily average humidity is " +
                props.calculated_value +
                "% (Avg Temperature >32°C ;  Avg. Rel. Humidity between 80% & 90%)",
              "High Temperature":
                "The daily max Temperature is " +
                props.calculated_value2 +
                "(Trigger : Max temperature > 33°C)",
            },
          ],
          "Paddy 2nd Crop": [
            {
              "Excess Rainfall":
                "The daily cummulative rainfall is " +
                props.calculated_value +
                "mm (Trigger : Cummulative rainfall > 30)",
            },
          ],
          "Paddy 2nd Crop": [
            {
              "Excess Rainfall":
                "The daily cummulative rainfall is " +
                props.calculated_value +
                "mm (Trigger : Cummulative rainfall > 30)",
              "Unseasonal Rainfall":
                "The daily cummulative rainfall is " +
                props.calculated_value +
                "mm (Trigger : Cummulative rainfall > 30)",
              "Wind Speed (A)":
                "The daily cummulative wind speed is " +
                props.calculated_value +
                "km/h (Trigger : Cummulative wind speed > 60 km/h)",
              "Wind Speed (B)":
                "The daily cummulative wind speed is " +
                props.calculated_value +
                "km/h (Trigger : Cummulative wind speed > 55 km/h)",
            },
          ],
        },
        4: {
          "Veg Group I": [
            {
              "Dry Spell":
                "The aggregate rainfall is" +
                props.calculated_value +
                " mm (Trigger : <2.5 mm)",
              "Disease Congenial Climate":
                "The daily max temperature is " +
                props.calculated_value2 +
                " °C & the daily average humidity is" +
                props.calculated_value +
                "% (Max. Temperature between 28°C & 32°C; Avg. Rel. Humidity > 80%)",
              "Excess Rainfall":
                "The daily cummulative rainfall is " +
                props.calculated_value +
                "mm (Trigger : Cummulative rainfall > 10)",
              "Rise in Temperature":
                "The daily min Temperature is " +
                props.calculated_value2 +
                "(Trigger : min temperature > 36°C)",
            },
          ],
          Mango: [
            {
              "Disease Congenial Climate":
                "The daily min temperature is " +
                props.calculated_value2 +
                " °C & the daily average humidity is" +
                props.calculated_value +
                "% (Min. Temperature < 24 °C; Avg. Rel. Humidity > 80%)",
              "Excess Rainfall":
                "The Calculated Value is " +
                props.calculated_value +
                "mm (Trigger : Cummulative rainfall > 10)",
              "Wind Speed":
                "The daily cummulative wind speed is " +
                props.calculated_value +
                "km/h (Trigger : Cummulative wind speed > 40 km/h)",
            },
          ],
          "Paddy 3rd Crop": [
            {
              "Disease Congenial Climate":
                "The daily max temperature is " +
                props.calculated_value2 +
                " & the daily average humidity is " +
                props.calculated_value +
                "% (Max Temperature > 32 °C; Avg. Rel. Humidity between 80% & 90%)",

              "High Temperature":
                "The daily max Temperature is " +
                props.calculated_value2 +
                "(Trigger : max temperature > 38 °C)",
            },
          ],
          "Veg Group I - SMR": [
            {
              "Disease Congenial Climate":
                "The daily max temperature is " +
                props.calculated_value2 +
                " & the daily average humidity is " +
                props.calculated_value +
                "% (Max Temperature between 28°C & 33°C ;  Avg. Rel. Humidity > 80%)",
              "Rise in Temperature":
                "The daily min temperature is " +
                props.calculated_value2 +
                "(Trigger : Min Temperature > 36°C)",
              "Excess Rainfall":
                "The Calculated Value is " +
                props.calculated_value +
                "mm (Trigger : Cummulative rainfall > 10)",
            },
          ],
          "Paddy Winter": [
            {
              "Disease Congenial Climate":
                "The daily avg temperature is " +
                props.calculated_value2 +
                " & the daily average humidity is " +
                props.calculated_value +
                "% (Avg Temperature >32°C ;  Avg. Rel. Humidity between 80% & 90%)",
              "High Temperature":
                "The daily max Temperature is " +
                props.calculated_value2 +
                "(Trigger : Max temperature > 33°C)",
            },
          ],
          "Paddy 2nd Crop": [
            {
              "Excess Rainfall":
                "The daily cummulative rainfall is " +
                props.calculated_value +
                "mm (Trigger : Cummulative rainfall > 30)",
            },
          ],
          "Paddy 2nd Crop": [
            {
              "Excess Rainfall":
                "The daily cummulative rainfall is " +
                props.calculated_value +
                "mm (Trigger : Cummulative rainfall > 30)",
              "Unseasonal Rainfall":
                "The daily cummulative rainfall is " +
                props.calculated_value +
                "mm (Trigger : Cummulative rainfall > 30)",
              "Wind Speed (A)":
                "The daily cummulative wind speed is " +
                props.calculated_value +
                "km/h (Trigger : Cummulative wind speed > 60 km/h)",
              "Wind Speed (B)":
                "The daily cummulative wind speed is " +
                props.calculated_value +
                "km/h (Trigger : Cummulative wind speed > 55 km/h)",
            },
          ],
        },
      },
      "Rabi 2021-22": {
        1: {
          "Veg Group I": [
            {
              "Dry Spell":
                "The aggregate rainfall is" +
                props.calculated_value +
                " mm (Trigger : <2.5 mm)",
              "Disease Congenial Climate":
                "The daily max temperature is " +
                props.calculated_value2 +
                " °C & the daily average humidity is" +
                props.calculated_value +
                "% (Max. Temperature between 28°C & 32°C; Avg. Rel. Humidity > 80%)",
              "Excess Rainfall":
                "The daily cummulative rainfall is " +
                props.calculated_value +
                "mm (Trigger : Cummulative rainfall > 15)",
              "Rise in Temperature":
                "The daily min Temperature is " +
                props.calculated_value2 +
                " °C (Trigger : min temperature > 36°C)",
            },
          ],
          Mango: [
            {
              "Disease Congenial Climate":
                "The daily min temperature is " +
                props.calculated_value2 +
                " °C & the daily average humidity is" +
                props.calculated_value +
                "% (Min. Temperature < 24 °C; Avg. Rel. Humidity > 80%)",
              "Excess Rainfall":
                "The Calculated Value is " +
                props.calculated_value +
                "mm (Trigger : Cummulative rainfall > 10)",
              "Wind Speed":
                "The daily cummulative wind speed is " +
                props.calculated_value +
                "km/h (Trigger : Cummulative wind speed > 45 km/h)",
            },
          ],
          "Paddy 3rd Crop": [
            {
              "Disease Congenial Climate":
                "The daily max temperature is " +
                props.calculated_value2 +
                "°C & the daily average humidity is " +
                props.calculated_value +
                "% (Max Temperature > 32 °C; Avg. Rel. Humidity between 80% & 90%)",

              "High Temperature":
                "The daily max Temperature is " +
                props.calculated_value2 +
                "°C (Trigger : max temperature > 34.5 °C)",
            },
          ],
          "Veg Group I - SMR": [
            {
              "Disease Congenial Climate":
                "The daily max temperature is " +
                props.calculated_value2 +
                " °C & the daily average humidity is " +
                props.calculated_value +
                "% (Max Temperature between 28°C & 33°C ;  Avg. Rel. Humidity > 80%)",
              "Rise in Temperature":
                "The daily min temperature is " +
                props.calculated_value2 +
                " °C (Trigger : Min Temperature > 36°C)",
              "Excess Rainfall":
                "The Calculated Value is " +
                props.calculated_value +
                "mm (Trigger : Cummulative rainfall > 15)",
            },
          ],
          "Paddy Winter": [
            {
              "Disease Congenial Climate":
                "The daily avg temperature is " +
                props.calculated_value2 +
                " °C & the daily average humidity is " +
                props.calculated_value +
                "% (Avg Temperature >32°C ;  Avg. Rel. Humidity between 80% & 90%)",
              "High Temperature":
                "The daily max Temperature is " +
                props.calculated_value2 +
                " °C (Trigger : Max temperature > 33°C)",
            },
          ],
          "Paddy 2nd Crop": [
            {
              "Excess Rainfall":
                "The daily cummulative rainfall is " +
                props.calculated_value +
                "mm (Trigger : Cummulative rainfall > 30)",
              "Unseasonal Rainfall":
                "The daily cummulative rainfall is " +
                props.calculated_value +
                "mm (Trigger : Cummulative rainfall > 30)",
              "Wind Speed (A)":
                "The daily cummulative wind speed is " +
                props.calculated_value +
                "km/h (Trigger : Cummulative wind speed > 45 km/h)",
              "Wind Speed (B)":
                "The daily cummulative wind speed is " +
                props.calculated_value +
                "km/h (Trigger : Cummulative wind speed > 40 km/h)",
              "Disease Congenial Climate":
                "The daily avg temperature is " +
                props.calculated_value2 +
                " °C & the daily average humidity is " +
                props.calculated_value +
                "% (Avg Temperature >31°C ;  Avg. Rel. Humidity between 70% & 80%)",
            },
          ],
        },
        2: {
          "Veg Group I": [
            {
              "Dry Spell":
                "The aggregate rainfall is" +
                props.calculated_value +
                " mm (Trigger : <2.5 mm)",
              "Disease Congenial Climate":
                "The daily max temperature is " +
                props.calculated_value2 +
                " °C & the daily average humidity is" +
                props.calculated_value +
                "% (Max. Temperature between 28°C & 32°C; Avg. Rel. Humidity > 80%)",
              "Excess Rainfall":
                "The daily cummulative rainfall is " +
                props.calculated_value +
                "mm (Trigger : Cummulative rainfall > 10)",
              "Rise in Temperature":
                "The daily min Temperature is " +
                props.calculated_value2 +
                " °C (Trigger : min temperature > 36°C)",
            },
          ],
          Mango: [
            {
              "Disease Congenial Climate":
                "The daily min temperature is " +
                props.calculated_value2 +
                " °C & the daily average humidity is" +
                props.calculated_value +
                "% (Min. Temperature < 24 °C; Avg. Rel. Humidity > 80%)",
              "Excess Rainfall":
                "The Calculated Value is " +
                props.calculated_value +
                "mm (Trigger : Cummulative rainfall > 5)",
              "Wind Speed":
                "The daily cummulative wind speed is " +
                props.calculated_value +
                "km/h (Trigger : Cummulative wind speed > 50 km/h)",
            },
          ],
          "Paddy 3rd Crop": [
            {
              "Disease Congenial Climate":
                "The daily max temperature is " +
                props.calculated_value2 +
                " °C & the daily average humidity is " +
                props.calculated_value +
                "% (Max Temperature > 32 °C; Avg. Rel. Humidity between 80% & 90%)",

              "High Temperature":
                "The daily max Temperature is " +
                props.calculated_value2 +
                " °C (Trigger : max temperature > 36 °C)",
            },
          ],
          "Veg Group I - SMR": [
            {
              "Disease Congenial Climate":
                "The daily max temperature is " +
                props.calculated_value2 +
                " °C & the daily average humidity is " +
                props.calculated_value +
                "% (Max Temperature between 28°C & 33°C ;  Avg. Rel. Humidity > 80%)",
              "Rise in Temperature":
                "The daily min temperature is " +
                props.calculated_value2 +
                " °C (Trigger : Min Temperature > 36°C)",
              "Excess Rainfall":
                "The Calculated Value is " +
                props.calculated_value +
                "mm (Trigger : Cummulative rainfall > 10)",
            },
          ],
          "Paddy Winter": [
            {
              "Disease Congenial Climate":
                "The daily avg temperature is " +
                props.calculated_value2 +
                " & the daily average humidity is " +
                props.calculated_value +
                "% (Avg Temperature >32°C ;  Avg. Rel. Humidity between 80% & 90%)",
              "High Temperature":
                "The daily max Temperature is " +
                props.calculated_value2 +
                " °C (Trigger : Max temperature > 33°C)",
            },
          ],
          "Paddy 2nd Crop": [
            {
              "Excess Rainfall":
                "The daily cummulative rainfall is " +
                props.calculated_value +
                "mm (Trigger : Cummulative rainfall > 30)",
              "Unseasonal Rainfall":
                "The daily cummulative rainfall is " +
                props.calculated_value +
                "mm (Trigger : Cummulative rainfall > 30)",
              "Wind Speed (A)":
                "The daily cummulative wind speed is " +
                props.calculated_value +
                "km/h (Trigger : Cummulative wind speed > 55 km/h)",
              "Wind Speed (B)":
                "The daily cummulative wind speed is " +
                props.calculated_value +
                "km/h (Trigger : Cummulative wind speed > 50 km/h)",
              "Disease Congenial Climate":
                "The daily avg temperature is " +
                props.calculated_value2 +
                " °C & the daily average humidity is " +
                props.calculated_value +
                "% (Avg Temperature >31°C ;  Avg. Rel. Humidity between 70% & 80%)",
            },
          ],
        },
        3: {
          "Veg Group I": [
            {
              "Dry Spell":
                "The aggregate rainfall is" +
                props.calculated_value +
                " mm (Trigger : <2.5 mm)",
              "Disease Congenial Climate":
                "The daily max temperature is " +
                props.calculated_value2 +
                " °C & the daily average humidity is" +
                props.calculated_value +
                "% (Max. Temperature between 28°C & 32°C; Avg. Rel. Humidity > 80%)",
              "Excess Rainfall":
                "The daily cummulative rainfall is " +
                props.calculated_value +
                "mm (Trigger : Cummulative rainfall > 10)",
              "Rise in Temperature":
                "The daily min Temperature is " +
                props.calculated_value2 +
                " °C (Trigger : min temperature > 36°C)",
            },
          ],
          Mango: [
            {
              "Disease Congenial Climate":
                "The daily min temperature is " +
                props.calculated_value2 +
                " °C & the daily average humidity is" +
                props.calculated_value +
                "% (Min. Temperature < 24 °C; Avg. Rel. Humidity > 80%)",
              "Excess Rainfall":
                "The Calculated Value is " +
                props.calculated_value +
                "mm (Trigger : Cummulative rainfall > 10)",
              "Wind Speed":
                "The daily cummulative wind speed is " +
                props.calculated_value +
                "km/h (Trigger : Cummulative wind speed > 40 km/h)",
            },
          ],
          "Paddy 3rd Crop": [
            {
              "Disease Congenial Climate":
                "The daily max temperature is " +
                props.calculated_value2 +
                " °C & the daily average humidity is " +
                props.calculated_value +
                "% (Max Temperature > 32 °C; Avg. Rel. Humidity between 80% & 90%)",

              "High Temperature":
                "The daily max Temperature is " +
                props.calculated_value2 +
                " °C (Trigger : max temperature > 37 °C)",
            },
          ],
          "Veg Group I - SMR": [
            {
              "Disease Congenial Climate":
                "The daily max temperature is " +
                props.calculated_value2 +
                " °C & the daily average humidity is " +
                props.calculated_value +
                "% (Max Temperature between 28°C & 33°C ;  Avg. Rel. Humidity > 80%)",
              "Rise in Temperature":
                "The daily min temperature is " +
                props.calculated_value2 +
                " °C (Trigger : Min Temperature > 36°C)",
              "Excess Rainfall":
                "The Calculated Value is " +
                props.calculated_value +
                "mm (Trigger : Cummulative rainfall > 10)",
            },
          ],
          "Paddy Winter": [
            {
              "Disease Congenial Climate":
                "The daily avg temperature is " +
                props.calculated_value2 +
                " °C & the daily average humidity is " +
                props.calculated_value +
                "% (Avg Temperature >32°C ;  Avg. Rel. Humidity between 80% & 90%)",
              "High Temperature":
                "The daily max Temperature is " +
                props.calculated_value2 +
                " °C (Trigger : Max temperature > 33°C)",
            },
          ],
          "Paddy 2nd Crop": [
            {
              "Excess Rainfall":
                "The daily cummulative rainfall is " +
                props.calculated_value +
                "mm (Trigger : Cummulative rainfall > 30)",
              "Unseasonal Rainfall":
                "The daily cummulative rainfall is " +
                props.calculated_value +
                "mm (Trigger : Cummulative rainfall > 30)",
              "Wind Speed (A)":
                "The daily cummulative wind speed is " +
                props.calculated_value +
                "km/h (Trigger : Cummulative wind speed > 60 km/h)",
              "Wind Speed (B)":
                "The daily cummulative wind speed is " +
                props.calculated_value +
                "km/h (Trigger : Cummulative wind speed > 55 km/h)",
              "Disease Congenial Climate":
                "The daily avg temperature is " +
                props.calculated_value2 +
                " °C & the daily average humidity is " +
                props.calculated_value +
                "% (Avg Temperature >31°C ;  Avg. Rel. Humidity between 70% & 80%)",
            },
          ],
        },
        4: {
          "Veg Group I": [
            {
              "Dry Spell":
                "The aggregate rainfall is" +
                props.calculated_value +
                " mm (Trigger : <2.5 mm)",
              "Disease Congenial Climate":
                "The daily max temperature is " +
                props.calculated_value2 +
                " °C & the daily average humidity is" +
                props.calculated_value +
                "% (Max. Temperature between 28°C & 32°C; Avg. Rel. Humidity > 80%)",
              "Excess Rainfall":
                "The daily cummulative rainfall is " +
                props.calculated_value +
                "mm (Trigger : Cummulative rainfall > 10)",
              "Rise in Temperature":
                "The daily min Temperature is " +
                props.calculated_value2 +
                " °C (Trigger : min temperature > 36°C)",
            },
          ],
          Mango: [
            {
              "Disease Congenial Climate":
                "The daily min temperature is " +
                props.calculated_value2 +
                " °C & the daily average humidity is" +
                props.calculated_value +
                "% (Min. Temperature < 24 °C; Avg. Rel. Humidity > 80%)",
              "Excess Rainfall":
                "The Calculated Value is " +
                props.calculated_value +
                "mm (Trigger : Cummulative rainfall > 10)",
              "Wind Speed":
                "The daily cummulative wind speed is " +
                props.calculated_value +
                "km/h (Trigger : Cummulative wind speed > 40 km/h)",
            },
          ],
          "Paddy 3rd Crop": [
            {
              "Disease Congenial Climate":
                "The daily max temperature is " +
                props.calculated_value2 +
                " & the daily average humidity is " +
                props.calculated_value +
                "% (Max Temperature > 32 °C; Avg. Rel. Humidity between 80% & 90%)",

              "High Temperature":
                "The daily max Temperature is " +
                props.calculated_value2 +
                "(Trigger : max temperature > 38 °C)",
            },
          ],
          "Veg Group I - SMR": [
            {
              "Disease Congenial Climate":
                "The daily max temperature is " +
                props.calculated_value2 +
                " & the daily average humidity is " +
                props.calculated_value +
                "% (Max Temperature between 28°C & 33°C ;  Avg. Rel. Humidity > 80%)",
              "Rise in Temperature":
                "The daily min temperature is " +
                props.calculated_value2 +
                "(Trigger : Min Temperature > 36°C)",
              "Excess Rainfall":
                "The Calculated Value is " +
                props.calculated_value +
                "mm (Trigger : Cummulative rainfall > 10)",
            },
          ],
          "Paddy Winter": [
            {
              "Disease Congenial Climate":
                "The daily avg temperature is " +
                props.calculated_value2 +
                " & the daily average humidity is " +
                props.calculated_value +
                "% (Avg Temperature >32°C ;  Avg. Rel. Humidity between 80% & 90%)",
              "High Temperature":
                "The daily max Temperature is " +
                props.calculated_value2 +
                "(Trigger : Max temperature > 33°C)",
            },
          ],
          "Paddy 2nd Crop": [
            {
              "Excess Rainfall":
                "The daily cummulative rainfall is " +
                props.calculated_value +
                "mm (Trigger : Cummulative rainfall > 30)",
              "Unseasonal Rainfall":
                "The daily cummulative rainfall is " +
                props.calculated_value +
                "mm (Trigger : Cummulative rainfall > 30)",
              "Wind Speed (A)":
                "The daily cummulative wind speed is " +
                props.calculated_value +
                "km/h (Trigger : Cummulative wind speed > 60 km/h)",
              "Wind Speed (B)":
                "The daily cummulative wind speed is " +
                props.calculated_value +
                "km/h (Trigger : Cummulative wind speed > 55 km/h)",
              "Disease Congenial Climate":
                "The daily avg temperature is " +
                props.calculated_value2 +
                " °C & the daily average humidity is " +
                props.calculated_value +
                "% (Avg Temperature >31°C ;  Avg. Rel. Humidity between 70% & 80%)",
            },
          ],
        },
      },
      "Rabi 2022-23": {
        1: {
          "Veg Group I": [
            {
              "Dry Spell":
                "The aggregate rainfall is" +
                props.calculated_value +
                " mm (Trigger : <2.5 mm)",
              "Disease Congenial Climate":
                "The daily max temperature is " +
                props.calculated_value2 +
                " °C & the daily average humidity is" +
                props.calculated_value +
                "% (Max. Temperature between 28°C & 32°C; Avg. Rel. Humidity > 80%)",
              "Excess Rainfall":
                "The daily cummulative rainfall is " +
                props.calculated_value +
                "mm (Trigger : Cummulative rainfall > 15)",
              "Rise in Temperature":
                "The daily min Temperature is " +
                props.calculated_value2 +
                " °C (Trigger : min temperature > 36°C)",
            },
          ],
          Mango: [
            {
              "Disease Congenial Climate":
                "The daily min temperature is " +
                props.calculated_value2 +
                " °C & the daily average humidity is" +
                props.calculated_value +
                "% (Min. Temperature < 24 °C; Avg. Rel. Humidity > 80%)",
              "Excess Rainfall":
                "The Calculated Value is " +
                props.calculated_value +
                "mm (Trigger : Cummulative rainfall > 10)",
              "Wind Speed":
                "The daily cummulative wind speed is " +
                props.calculated_value +
                "km/h (Trigger : Cummulative wind speed > 45 km/h)",
            },
          ],
          "Paddy 3rd Crop": [
            {
              "Disease Congenial Climate":
                "The daily max temperature is " +
                props.calculated_value2 +
                " & the daily average humidity is " +
                props.calculated_value +
                "% (Max Temperature > 32 °C; Avg. Rel. Humidity between 80% & 90%)",

              "High Temperature":
                "The daily max Temperature is " +
                props.calculated_value2 +
                "(Trigger : max temperature > 34.5 °C)",
            },
          ],
          "Veg Group I - SMR": [
            {
              "Disease Congenial Climate":
                "The daily max temperature is " +
                props.calculated_value2 +
                " & the daily average humidity is " +
                props.calculated_value +
                "% (Max Temperature between 28°C & 33°C ;  Avg. Rel. Humidity > 80%)",
              "Rise in Temperature":
                "The daily min temperature is " +
                props.calculated_value2 +
                "(Trigger : Min Temperature > 36°C)",
              "Excess Rainfall":
                "The Calculated Value is " +
                props.calculated_value +
                "mm (Trigger : Cummulative rainfall > 15)",
            },
          ],
          "Paddy Winter": [
            {
              "Disease Congenial Climate":
                "The daily avg temperature is " +
                props.calculated_value2 +
                " & the daily average humidity is " +
                props.calculated_value +
                "% (Avg Temperature >32°C ;  Avg. Rel. Humidity between 80% & 90%)",
              "High Temperature":
                "The daily max Temperature is " +
                props.calculated_value2 +
                "(Trigger : Max temperature > 33°C)",
            },
          ],
          "Paddy 2nd Crop": [
            {
              "Excess Rainfall":
                "The daily cummulative rainfall is " +
                props.calculated_value +
                "mm (Trigger : Cummulative rainfall > 30)",
              "Unseasonal Rainfall":
                "The daily cummulative rainfall is " +
                props.calculated_value +
                "mm (Trigger : Cummulative rainfall > 30)",
              "Wind Speed (A)":
                "The daily cummulative wind speed is " +
                props.calculated_value +
                "km/h (Trigger : Cummulative wind speed > 45 km/h)",
              "Wind Speed (B)":
                "The daily cummulative wind speed is " +
                props.calculated_value +
                "km/h (Trigger : Cummulative wind speed > 40 km/h)",
              "Disease Congenial Climate":
                "The daily avg temperature is " +
                props.calculated_value2 +
                " °C & the daily average humidity is " +
                props.calculated_value +
                "% (Avg Temperature >31°C ;  Avg. Rel. Humidity between 70% & 80%)",
            },
          ],
        },
        2: {
          "Veg Group I": [
            {
              "Dry Spell":
                "The aggregate rainfall is" +
                props.calculated_value +
                " mm (Trigger : <2.5 mm)",
              "Disease Congenial Climate":
                "The daily max temperature is " +
                props.calculated_value2 +
                " °C & the daily average humidity is" +
                props.calculated_value +
                "% (Max. Temperature between 28°C & 32°C; Avg. Rel. Humidity > 80%)",
              "Excess Rainfall":
                "The daily cummulative rainfall is " +
                props.calculated_value +
                "mm (Trigger : Cummulative rainfall > 10)",
              "Rise in Temperature":
                "The daily min Temperature is " +
                props.calculated_value2 +
                " °C (Trigger : min temperature > 36°C)",
            },
          ],
          Mango: [
            {
              "Disease Congenial Climate":
                "The daily min temperature is " +
                props.calculated_value2 +
                " °C & the daily average humidity is" +
                props.calculated_value +
                "% (Min. Temperature < 24 °C; Avg. Rel. Humidity > 80%)",
              "Excess Rainfall":
                "The Calculated Value is " +
                props.calculated_value +
                "mm (Trigger : Cummulative rainfall > 5)",
              "Wind Speed":
                "The daily cummulative wind speed is " +
                props.calculated_value +
                "km/h (Trigger : Cummulative wind speed > 50 km/h)",
            },
          ],
          "Paddy 3rd Crop": [
            {
              "Disease Congenial Climate":
                "The daily max temperature is " +
                props.calculated_value2 +
                " °C & the daily average humidity is " +
                props.calculated_value +
                "% (Max Temperature > 32 °C; Avg. Rel. Humidity between 80% & 90%)",

              "High Temperature":
                "The daily max Temperature is " +
                props.calculated_value2 +
                " °C (Trigger : max temperature > 36 °C)",
            },
          ],
          "Veg Group I - SMR": [
            {
              "Disease Congenial Climate":
                "The daily max temperature is " +
                props.calculated_value2 +
                " °C & the daily average humidity is " +
                props.calculated_value +
                "% (Max Temperature between 28°C & 33°C ;  Avg. Rel. Humidity > 80%)",
              "Rise in Temperature":
                "The daily min temperature is " +
                props.calculated_value2 +
                " °C (Trigger : Min Temperature > 36°C)",
              "Excess Rainfall":
                "The Calculated Value is " +
                props.calculated_value +
                "mm (Trigger : Cummulative rainfall > 10)",
            },
          ],
          "Paddy Winter": [
            {
              "Disease Congenial Climate":
                "The daily avg temperature is " +
                props.calculated_value2 +
                " °C & the daily average humidity is " +
                props.calculated_value +
                "% (Avg Temperature >32°C ;  Avg. Rel. Humidity between 80% & 90%)",
              "High Temperature":
                "The daily max Temperature is " +
                props.calculated_value2 +
                " °C (Trigger : Max temperature > 33°C)",
            },
          ],
          "Paddy 2nd Crop": [
            {
              "Excess Rainfall":
                "The daily cummulative rainfall is " +
                props.calculated_value +
                "mm (Trigger : Cummulative rainfall > 30)",
              "Unseasonal Rainfall":
                "The daily cummulative rainfall is " +
                props.calculated_value +
                "mm (Trigger : Cummulative rainfall > 30)",
              "Wind Speed (A)":
                "The daily cummulative wind speed is " +
                props.calculated_value +
                "km/h (Trigger : Cummulative wind speed > 55 km/h)",
              "Wind Speed (B)":
                "The daily cummulative wind speed is " +
                props.calculated_value +
                "km/h (Trigger : Cummulative wind speed > 50 km/h)",
              "Disease Congenial Climate":
                "The daily avg temperature is " +
                props.calculated_value2 +
                " °C & the daily average humidity is " +
                props.calculated_value +
                "% (Avg Temperature >31°C ;  Avg. Rel. Humidity between 70% & 80%)",
            },
          ],
        },
        3: {
          "Veg Group I": [
            {
              "Dry Spell":
                "The aggregate rainfall is" +
                props.calculated_value +
                " mm (Trigger : <2.5 mm)",
              "Disease Congenial Climate":
                "The daily max temperature is " +
                props.calculated_value2 +
                " °C & the daily average humidity is" +
                props.calculated_value +
                "% (Max. Temperature between 28°C & 32°C; Avg. Rel. Humidity > 80%)",
              "Excess Rainfall":
                "The daily cummulative rainfall is " +
                props.calculated_value +
                "mm (Trigger : Cummulative rainfall > 10)",
              "Rise in Temperature":
                "The daily min Temperature is " +
                props.calculated_value2 +
                "°C (Trigger : min temperature > 36°C)",
            },
          ],
          Mango: [
            {
              "Disease Congenial Climate":
                "The daily min temperature is " +
                props.calculated_value2 +
                " °C & the daily average humidity is" +
                props.calculated_value +
                "% (Min. Temperature < 24 °C; Avg. Rel. Humidity > 80%)",
              "Excess Rainfall":
                "The Calculated Value is " +
                props.calculated_value +
                "mm (Trigger : Cummulative rainfall > 10)",
              "Wind Speed":
                "The daily cummulative wind speed is " +
                props.calculated_value +
                "km/h (Trigger : Cummulative wind speed > 40 km/h)",
            },
          ],
          "Paddy 3rd Crop": [
            {
              "Disease Congenial Climate":
                "The daily max temperature is " +
                props.calculated_value2 +
                "°C & the daily average humidity is " +
                props.calculated_value +
                "% (Max Temperature > 32 °C; Avg. Rel. Humidity between 80% & 90%)",

              "High Temperature":
                "The daily max Temperature is " +
                props.calculated_value2 +
                "°C (Trigger : max temperature > 37 °C)",
            },
          ],
          "Veg Group I - SMR": [
            {
              "Disease Congenial Climate":
                "The daily max temperature is " +
                props.calculated_value2 +
                "°C & the daily average humidity is " +
                props.calculated_value +
                "% (Max Temperature between 28°C & 33°C ;  Avg. Rel. Humidity > 80%)",
              "Rise in Temperature":
                "The daily min temperature is " +
                props.calculated_value2 +
                "°C (Trigger : Min Temperature > 36°C)",
              "Excess Rainfall":
                "The Calculated Value is " +
                props.calculated_value +
                "mm (Trigger : Cummulative rainfall > 10)",
            },
          ],
          "Paddy Winter": [
            {
              "Disease Congenial Climate":
                "The daily avg temperature is " +
                props.calculated_value2 +
                "°C & the daily average humidity is " +
                props.calculated_value +
                "% (Avg Temperature >32°C ;  Avg. Rel. Humidity between 80% & 90%)",
              "High Temperature":
                "The daily max Temperature is " +
                props.calculated_value2 +
                "°C (Trigger : Max temperature > 33°C)",
            },
          ],

          "Paddy 2nd Crop": [
            {
              "Excess Rainfall":
                "The daily cummulative rainfall is " +
                props.calculated_value +
                "mm (Trigger : Cummulative rainfall > 30)",
              "Unseasonal Rainfall":
                "The daily cummulative rainfall is " +
                props.calculated_value +
                "mm (Trigger : Cummulative rainfall > 30)",
              "Wind Speed (A)":
                "The daily cummulative wind speed is " +
                props.calculated_value +
                "km/h (Trigger : Cummulative wind speed > 60 km/h)",
              "Wind Speed (B)":
                "The daily cummulative wind speed is " +
                props.calculated_value +
                "km/h (Trigger : Cummulative wind speed > 55 km/h)",
              "Disease Congenial Climate":
                "The daily avg temperature is " +
                props.calculated_value2 +
                " °C & the daily average humidity is " +
                props.calculated_value +
                "% (Avg Temperature >31°C ;  Avg. Rel. Humidity between 70% & 80%)",
            },
          ],
        },
        4: {
          "Veg Group I": [
            {
              "Dry Spell":
                "The aggregate rainfall is" +
                props.calculated_value +
                " mm (Trigger : <2.5 mm)",
              "Disease Congenial Climate":
                "The daily max temperature is " +
                props.calculated_value2 +
                " °C & the daily average humidity is" +
                props.calculated_value +
                "% (Max. Temperature between 28°C & 32°C; Avg. Rel. Humidity > 80%)",
              "Excess Rainfall":
                "The daily cummulative rainfall is " +
                props.calculated_value +
                "mm (Trigger : Cummulative rainfall > 10)",
              "Rise in Temperature":
                "The daily min Temperature is " +
                props.calculated_value2 +
                "(Trigger : min temperature > 36°C)",
            },
          ],
          Mango: [
            {
              "Disease Congenial Climate":
                "The daily min temperature is " +
                props.calculated_value2 +
                " °C & the daily average humidity is" +
                props.calculated_value +
                "% (Min. Temperature < 24 °C; Avg. Rel. Humidity > 80%)",
              "Excess Rainfall":
                "The Calculated Value is " +
                props.calculated_value +
                "mm (Trigger : Cummulative rainfall > 10)",
              "Wind Speed":
                "The daily cummulative wind speed is " +
                props.calculated_value +
                "km/h (Trigger : Cummulative wind speed > 40 km/h)",
            },
          ],
          "Paddy 3rd Crop": [
            {
              "Disease Congenial Climate":
                "The daily max temperature is " +
                props.calculated_value2 +
                "°C & the daily average humidity is " +
                props.calculated_value +
                "% (Max Temperature > 32 °C; Avg. Rel. Humidity between 80% & 90%)",

              "High Temperature":
                "The daily max Temperature is " +
                props.calculated_value2 +
                "(Trigger : max temperature > 38 °C)",
            },
          ],
          "Veg Group I - SMR": [
            {
              "Disease Congenial Climate":
                "The daily max temperature is " +
                props.calculated_value2 +
                "°C & the daily average humidity is " +
                props.calculated_value +
                "% (Max Temperature between 28°C & 33°C ;  Avg. Rel. Humidity > 80%)",
              "Rise in Temperature":
                "The daily min temperature is " +
                props.calculated_value2 +
                "(Trigger : Min Temperature > 36°C)",
              "Excess Rainfall":
                "The Calculated Value is " +
                props.calculated_value +
                "mm (Trigger : Cummulative rainfall > 10)",
            },
          ],
          "Paddy Winter": [
            {
              "Disease Congenial Climate":
                "The daily avg temperature is " +
                props.calculated_value2 +
                "°C & the daily average humidity is " +
                props.calculated_value +
                "% (Avg Temperature >32°C ;  Avg. Rel. Humidity between 80% & 90%)",
              "High Temperature":
                "The daily max Temperature is " +
                props.calculated_value2 +
                "(Trigger : Max temperature > 33°C)",
            },
          ],

          "Paddy 2nd Crop": [
            {
              "Excess Rainfall":
                "The daily cummulative rainfall is " +
                props.calculated_value +
                "mm (Trigger : Cummulative rainfall > 30)",
              "Unseasonal Rainfall":
                "The daily cummulative rainfall is " +
                props.calculated_value +
                "mm (Trigger : Cummulative rainfall > 30)",
              "Wind Speed (A)":
                "The daily cummulative wind speed is " +
                props.calculated_value +
                "km/h (Trigger : Cummulative wind speed > 60 km/h)",
              "Wind Speed (B)":
                "The daily cummulative wind speed is " +
                props.calculated_value +
                "km/h (Trigger : Cummulative wind speed > 55 km/h)",

              "Disease Congenial Climate":
                "The daily avg temperature is " +
                props.calculated_value2 +
                " °C & the daily average humidity is " +
                props.calculated_value +
                "% (Avg Temperature >31°C ;  Avg. Rel. Humidity between 70% & 80%)",
            },
          ],
        },
      },
    },
  };
  return (
    <p>
      {
        template["Day End"][props.season][props.phase][props.crop][0][
          props.peril
        ]
      }
    </p>
  );
}
