import React from "react";
import {
  Card,
  CardBody,
  Row,
  Col,
  CardText,
  FormGroup,
  NavLink,
  NavItem,
  Nav,
  Label,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container,
  Button,
  Spinner,
} from "reactstrap";
// import './table.css'
import moment from "moment";
import "antd/dist/antd.css";
import { Tabs, Input, Radio, Checkbox } from "antd";
import { toast } from "react-toastify";
import "../../assets/scss/tab.css";
import { DatePicker, Space } from "antd";
import { Link } from "react-router-dom";
const { RangePicker } = DatePicker;

const TabPane = Tabs.TabPane;
toast.configure();
const { TextArea } = Input;

const Default_content = (props) => {
  // console.log("THISPROPSINUPDATE", props);
  let items = { ...props };

  async function saveclick() {
    // console.log("+++++++++++++++++++++++++++++++++++++++++++++++++++");
    // console.log("SEARCH DATA", items);
    var new_list = JSON.stringify(items);

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + localStorage.getItem("token"),
      },
      body: new_list,
    };
    try {
      var updateData = await fetch(
        process.env.REACT_APP_APIEND + `smart_crop/termsheet-condition-update`,
        requestOptions
      ).then((res) => {
        return res.json();
      });
      // console.log("Updated Data", updateData);
      toast.success("Succesfully Updated");
    } catch (err) {
      toast.error("Something's not right");
    }
  }
  function onChangeField(e) {
    items[e.target.name] = [e.target.value][0];
    // console.log("NEW ITEMS", items);
  }
  function getdaterange(date, dateString) {
    var getthis = this;
    const date1 = new Date(dateString[0]);
    const date2 = new Date(dateString[1]);
    var from_dd = String(date1.getDate()).padStart(2, "0");
    var from_mm = String(date1.getMonth() + 1).padStart(2, "0"); //January is 0!
    var from_yyyy = date1.getFullYear();
    var from_date = from_yyyy + "-" + from_mm + "-" + from_dd;

    var to_dd = String(date2.getDate()).padStart(2, "0");
    var to_mm = String(date2.getMonth() + 1).padStart(2, "0"); //January is 0!
    var to_yyyy = date2.getFullYear();
    var to_date = to_yyyy + "-" + to_mm + "-" + to_dd;
    items["startDate"] = from_date;
    items["endDate"] = to_date;
  }
  function onChangeCondition(e, index) {
    items["condition"][index][e.target.name] = [e.target.value][0];
    // console.log("Condition ITEMS", items);
  }
  function onChangePhase(e) {
    // items[e.target.name] = [e.target.value][0]
    if (e.target.name == "is_day_end_alert") {
      if (e.target.checked == true) {
        items["is_day_end_alert"] = "Yes";
      } else {
        items["is_day_end_alert"] = "No";
      }
    } else {
      items[e.target.name] = [e.target.checked][0];
    }
    // console.log("Phase ITEMS", items);
  }
  function onchangeRules(e) {
    if (e.target.name == "day_end_alert_rule") {
      items["day_end_alert_rule"] = e.target.value;
    }
    if (e.target.name == "daily_rule") {
      items["daily_rule"] = e.target.value;
    }
    // console.log("Phase ITEMS", items);
  }
  function checkNaN(cost) {
    if (cost == null) {
      return "0.00";
    } else {
      return parseFloat(cost).toFixed(2);
    }
  }
  function onchangeDailyRules(e){
    items["daily_rule"] =JSON.parse(e.target.value)
  }
  return (
    <>
      <Row>
        <Col>
          <h2 style={{ fontWeight: "bolder", fontSize: "14px" }}>Basic</h2>
          <Row>
            <Col md="2">
              <FormGroup>
                <Label
                  className="col-md-12 col-form-label nextline"
                  style={{ fontSize: "12px" }}
                >
                  <br />
                  Phase
                </Label>
                <Input
                  placeholder="Phase"
                  style={{ width: 50, fontSize: "11px" }}
                  name="phase"
                  defaultValue={props.phase}
                  onChange={onChangeField}
                />
              </FormGroup>
            </Col>
            <Col md="4">
              <FormGroup>
                <Label
                  className="col-md-12 col-form-label"
                  style={{ fontSize: "12px" }}
                >
                  <br />
                  Start Date - End Date
                  <br />
                </Label>
                <RangePicker
                  defaultValue={[
                    moment(props.startDate, "YYYY/MM/DD"),
                    moment(props.endDate, "YYYY/MM/DD"),
                  ]}
                  onChange={getdaterange}
                  format="DD-MM-YYYY"
                />
              </FormGroup>
            </Col>
            <Col md="2">
              <FormGroup>
                <Label
                  className="col-md-12 col-form-label"
                  style={{ fontSize: "12px" }}
                >
                  <span>
                    Phase Max
                    <br /> Payout (Rs.)
                  </span>
                </Label>
                <Input
                  placeholder="Phase maximum Payout"
                  style={{ width: 100, fontSize: "11px" }}
                  name="phase_maximum_payout"
                  defaultValue={props.phase_maximum_payout}
                  onChange={onChangeField}
                />
              </FormGroup>
            </Col>
            <Col md="2">
              <FormGroup>
                <Label
                  className="col-md-12 col-form-label"
                  style={{ fontSize: "12px", paddingRight: "0px" }}
                >
                  <span>
                    Season
                    <br />
                    Max Payout (Rs.)
                  </span>
                </Label>
                <Input
                  placeholder="Season maximum Payout"
                  style={{ width: 100, fontSize: "11px" }}
                  name="max_payout"
                  defaultValue={props.max_payout}
                  onChange={onChangeField}
                />
              </FormGroup>
            </Col>
            <Col md="2">
              <FormGroup>
                <Label
                  className="col-md-12 col-form-label"
                  style={{ fontSize: "12px" }}
                >
                  <br />
                  Unit Cost (Rs.)
                </Label>
                <Input
                  placeholder="Unit Cost"
                  style={{ width: 100, fontSize: "11px" }}
                  name="unit_cost"
                  defaultValue={props.unit_cost}
                  onChange={onChangeField}
                />
              </FormGroup>
            </Col>
          </Row>
        </Col>
      </Row>
      {/* ---------------------------- */}
      <Row>
        <Col>
          <h2 style={{ fontWeight: "bolder", fontSize: "14px" }}>Conditions</h2>
          <Row>
            <Col md="12">
              {" "}
              <Table
                id="tsconditions"
                bordered
                style={{ fontSize: "12px" }}
                responsive
                className="ts-conditions"
              >
                <thead style={{ backgroundColor: "#ffffff" }}>
                  <tr>
                    <th style={{ width: "100px" }}>ID</th>
                    <th style={{ width: "130px" }}>Condition</th>
                    <th style={{ width: "130px" }}>Parameter</th>
                    <th style={{ width: "130px" }}>Index</th>
                    <th style={{ width: "130px" }}>Trigger</th>
                    <th style={{ width: "100px" }}>Strike</th>
                    <th style={{ width: "130px" }}>Exit</th>
                    <th style={{ width: "130px" }}>Notional (Rs.)</th>
                    <th style={{ width: "130px" }}>Fixed (Rs.)</th>
                    {/* <th style={{ width: "130px" }}>
                      <button className="btn-add-row" onClick={handleAddRow}>
                        Add Row
                      </button>
                    </th> */}
                  </tr>
                </thead>
                <tbody>
                  {props.condition.map((item, index) => (
                    <tr>
                      <td>
                        <Input
                          placeholder="ID"
                          style={{ width: 50, fontSize: "11px" }}
                          name="id"
                          defaultValue={item.id}
                          onChange={(e) => onChangeCondition(e, index)}
                          disabled
                        />
                      </td>
                      <td>
                        <TextArea
                          rows={1}
                          defaultValue={item.condition}
                          name="condition"
                          style={{ fontSize: "11px" }}
                          onChange={(e) => onChangeCondition(e, index)}
                        />
              
                      </td>
                      <td>
                        <TextArea
                          rows={1}
                          defaultValue={item.parameter}
                          name="parameter"
                          style={{ fontSize: "11px" }}
                          onChange={(e) => onChangeCondition(e, index)}
                        />
              
                      </td>
                      <td>
                        <TextArea
                          rows={1}
                          defaultValue={item.index}
                          name="index"
                          style={{ fontSize: "11px" }}
                          onChange={(e) => onChangeCondition(e, index)}
                        />
                        {/* <Input
                          placeholder="Index"
                          style={{ width: 100 }}
                          name="index"
                          defaultValue={item.index}
                          onChange={(e) => onChangeCondition(e, index)}
                        /> */}
                      </td>
                      <td>
                        <Input
                          placeholder="Trigger"
                          style={{ width: 50, fontSize: "11px" }}
                          name="trigger_value"
                          defaultValue={item.trigger_value}
                          onChange={(e) => onChangeCondition(e, index)}
                        />
                      </td>
                      <td>
                        <Input
                          placeholder="Strike"
                          style={{ width: 50, fontSize: "11px" }}
                          name="strike"
                          defaultValue={item.strike}
                          onChange={(e) => onChangeCondition(e, index)}
                        />
                      </td>
                      <td>
                        <Input
                          placeholder="Exit"
                          style={{ width: 50, fontSize: "11px" }}
                          name="exit_value"
                          defaultValue={item.exit_value}
                          onChange={(e) => onChangeCondition(e, index)}
                        />
                      </td>
                      <td className="alnrighttd">
                        <Input
                          placeholder="Notional"
                          style={{ width: 75, fontSize: "11px" }}
                          name="notional_payout"
                          defaultValue={checkNaN(item.notional_payout)}
                          onChange={(e) => onChangeCondition(e, index)}
                        />
                      </td>
                      <td className="alnrighttd">
                        <Input
                          placeholder="Fixed"
                          style={{ width: 75, fontSize: "11px" }}
                          name="fixed_payout"
                          defaultValue={checkNaN(item.fixed_payout)}
                          onChange={(e) => onChangeCondition(e, index)}
                        />
                      </td>
                      {/* <td style={{ width: 100 }}>
                      <i
                        className="ri-add-box-fill "
                        style={{ fontSize: "23px" }}
                      />
                    </td> */}
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Col>
      </Row>
      {/* ---------------------------- */}
      <Row>
        <Col>
          <p
            style={{
              fontWeight: "bolder",
              fontSize: "18px",
              paddingTop: "20px",
            }}
          >
            <h2 style={{ fontWeight: "bolder", fontSize: "14px" }}>Others</h2>
          </p>
          <Row>
            <Col md="12">
              <Checkbox
                defaultChecked={props.is_peril_phase_end}
                name="is_peril_phase_end"
                onChange={onChangePhase}
                style={{ fontSize: "12px" }}
              >
                Is Phase End
              </Checkbox>
              <Checkbox
                defaultChecked={props.is_day_end_alert == "No" ? false : true}
                name="is_day_end_alert"
                onChange={onChangePhase}
                style={{ fontSize: "12px" }}
              >
                Is Day End Alert Applicable?
              </Checkbox>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <FormGroup
                style={props.is_phase == "Y" ? {} : { display: "none" }}
              >
                <Label
                  className="col-md-12 col-form-label"
                  style={{ fontSize: "14px" }}
                >
                  <br />
                  Payout Rules
                </Label>
                <div
                  style={{
                    maxHeight: "calc(100vh - 390px)",
                    overflowY: "auto",
                    backgroundColor: "#e6ebef",
                  }}
                >
                  <pre
                    contentEditable
                    name="daily_rule"
                    onChange={onchangeRules}
                  >
                    {JSON.stringify(props.rule, null, 2)}
                  </pre>
                </div>
              </FormGroup>
              <FormGroup
                style={props.is_daily == "Y" ? {} : { display: "none" }}
              >
                <Label
                  className="col-md-12 col-form-label"
                  style={{ fontSize: "14px" }}
                >
                  <br />
                  Daily Rules
                </Label>

                <div
                  style={{
                    maxHeight: "calc(100vh - 390px)",
                    overflowY: "auto",
                    height: "500px",
                    backgroundColor: "#e6ebef",
                  }}
                >
                  <TextArea
                    defaultValue={
                      props.daily_rule.length < 1
                        ? " "
                        : JSON.stringify(props.daily_rule, undefined, 2)
                    }
                    name="rule"
                    rows={4}
                    style={{
                      maxHeight: "calc(100vh - 390px)",
                      overflowY: "auto",
                      height: "500px",
                      backgroundColor: "#e6ebef",
                    }}
                    onChange={onchangeDailyRules}
                  />
                </div>
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label
                  className="col-md-12 col-form-label"
                  style={{ fontSize: "14px" }}
                >
                  <br />
                  Alert Rules
                </Label>
                <div
                  style={{
                    maxHeight: "calc(100vh - 390px)",
                    overflowY: "auto",
                    backgroundColor: "#e6ebef",
                    height: "500px",
                  }}
                >
                  <pre
                    contentEditable
                    name="day_end_alert_rule"
                    onChange={onchangeRules}
                  >
                    {JSON.stringify(props.day_end_alert_rule, null, 2)}
                  </pre>
                </div>
              </FormGroup>
            </Col>
          </Row>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col md="12">
          <Button
            color="primary"
            style={{ "font-size": "10px", float: "right" }}
            onClick={saveclick}
          >
            Save
          </Button>
          <Link to="/tm_conditions">
            <Button
              color="danger"
              style={{
                "font-size": "10px",
                float: "right",
                marginRight: "15px",
              }}
              // onClick={saveclick}
            >
              Close
            </Button>
          </Link>
        </Col>
      </Row>
    </>
  );
};
export default Default_content;
