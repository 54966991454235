export default function getperumattystations() {
    const points ={
        "type": "FeatureCollection",
        "name": "vadakarapathy_stations",
        "crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:OGC:1.3:CRS84" } },
        "features": [
            {
                type: "Feature",
                properties: {
                  RUA: "Vadakarapathy",
                  Type: "KrishiBhavan",
                  stationName: "kdisc_sci_aws_09",
                  tooltipText:"Ahalia campus",
                  Location: "Ahalia campus",
                  "Google Map URL": "https://goo.gl/maps/amU9LJkAvSeqvu6bA",
                  Lat: 10.7951,
                  Long: 76.8363,
                },
                geometry: { type: "Point", coordinates: [76.8363, 10.7951] },
              },
              {
                type: "Feature",
                properties: {
                  RUA: "Vadakarapathy",
                  Type: "Extra",
                  stationName: "kdisc_sci_aws_13",
                  tooltipText:"Kinarpallam Milk Producers Co-operative Society",
                  Location: "Kinarpallam Milk Producers Co-operative Society",
                  "Google Map URL": "https://goo.gl/maps/qeFfWvJQjXC1jStN8",
                  Lat: 10.77941127,
                  Long: 76.85986374,
                },
                geometry: { type: "Point", coordinates: [76.85986374, 10.77941127] },
              },
        ]
        }
    return points;
  }
  