export default function getpattancherystations() {
  const points = {
    "type": "FeatureCollection",
    "name": "pattencherry_stations",
    "crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:OGC:1.3:CRS84" } },
    "features": [
      {
        type: "Feature",
        properties: {
          RUA: "Pattenchery",
          Type: "Panchayat Cremation Ground",
          stationName: "kdisc_sci_aws_06",
          tooltipText:"Panchayat Cremation Ground",
          Location: "Panchayat Cremation Ground",
          "Google Map URL": "https://goo.gl/maps/JwLe1Z7tQ29yr7fY8",
          Lat: 10.64777998,
          Long: 76.73270522,
        },
        geometry: { type: "Point", coordinates: [76.73270522, 10.64777998] },
      },
      {
        type: "Feature",
        properties: {
          RUA: "Pattenchery",
          Type: "Extra",
          stationName: "kdisc_sci_aws_02",
          tooltipText:"GSM High School, Thathamangalam",
          Location: "GSM High School, Thathamangalam",
          "Google Map URL": "https://goo.gl/maps/xQqCSeFAWKR2J6KG6",
          Lat: 10.67481694,
          Long: 76.70654271,
        },
        geometry: { type: "Point", coordinates: [76.70654271, 10.67481694] },
      }
    ]
    }
    

  return points;
}
