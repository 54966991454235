import React, { Component } from "react";
import {
  Card,
  CardBody,
  Row,
  Col,
  Input,
  CardText,
  FormGroup,
  Form,
  NavLink,
  NavItem,
  Nav,
  Label,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container,
  Button,
  Spinner,
} from "reactstrap";
// import './table.css'
import { MDBDataTable } from "mdbreact";
import "antd/dist/antd.css";
import { Link } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Permission from "../../permission.js";
import { DatePicker, Space } from "antd";
import FarmerPolicy from "./farmerPolicyInfo";
import CropInsured from "./farmerCropInsured";
import FarmerClaims from "./farmerClaims";
import validate_Token from "../../validateToken.js";
import Dropzone from "react-dropzone-uploader";
import { toast } from "react-toastify";
import { CSVLink } from "react-csv";
import { Select } from "antd";
import { withNamespaces } from "react-i18next";

//import css
import "../../assets/scss/tab.css";
import { configConsumerProps } from "antd/lib/config-provider";
toast.configure();
const { Option } = Select;
class FarmerTransactionStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal_center: false,
      modal_bulkupdate: false,
      season: "Kharif 2020-21",
      transaction_list: [],
      perilsdata: [],
      showLoader: false,
      showWaiting: true,
      premiumPaid: false,
      govtsubsidy: false,
      weatherdata: false,
      insurance: false,
      csvdownloadData: [],
      csvmapdata: [],
      showtable: false,
      updatedperils: [],
      perilagainstcrop: [],
      selUID: "",
      showbulkupdate: false,
      defaultselectionperil: [],
      seasonData: [],
      userDR: false,
      userKB: false,
      Cgovtsubsidy: false,
      Sgovtsubsidy: false,
    };
    this.edit = this.edit.bind(this);
    this.onsearch = this.onsearch.bind(this);
    this.handleeasonchange = this.handleeasonchange.bind(this);
    this.tog_bulkupdate = this.tog_bulkupdate.bind(this);
    this.handleupdateperil = this.handleupdateperil.bind(this);
    this.updatedata = this.updatedata.bind(this);
    this.onChangeCsubsidy = this.onChangeCsubsidy.bind(this);
    this.onChangeSsubsidy = this.onChangeSsubsidy.bind(this);
  }

  tog_center() {
    this.setState((prevState) => ({
      modal_center: !prevState.modal_center,
    }));
  }
  tog_bulkupdate() {
    this.setState((prevState) => ({
      modal_bulkupdate: !prevState.modal_bulkupdate,
    }));
    this.makecsvdata();
  }
  makecsvdata() {
    var getthis = this;
    // let csvData = [
    //   [
    //     "UID",
    //     "farmer_id",
    //     "farmer_name",
    //     "mobile",
    //     "season",
    //     "rua",
    //     "crop_id",
    //     "peril",
    //     "condition",
    //     "premium_paid",
    //     "govt_subsidy",
    //     "weather_data",
    //     "insurance",
    //   ],
    // ];
    let csvData = [
      [
        "user_id",
        "farmer_id",
        "farmer_name",
        "mobile",
        "season",
        "rua",
        "crop_id",
        "peril",
        "center_govt_subsidy_status",
        "state_govt_subsidy_status"
      ],
    ];
    this.state.csvmapdata.map((wdata) => {
      csvData.push([
        localStorage.getItem('uid'),
        wdata.farmerID,
        wdata.farmerName,
        wdata.mobile,
        wdata.season,
        wdata.rua,
        wdata.crop,
        getthis.perillist(wdata.perils),
        "",
        // wdata.premiumPaid,
        // wdata.govtSubsidy,
        // wdata.weatherData,
        // wdata.insurance,
      ]);
    });
    this.setState({
      csvdownloadData: csvData,
    });
  }
  async fetchseason() {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + localStorage.getItem("token"),
      },
    };
    try {
      var seasonData = await fetch(
        process.env.REACT_APP_APIEND + `smart_crop/term-sheet-season-crop`,
        requestOptions
      ).then((res) => {
        return res.json();
      });
      this.setState({
        perilseasonData: seasonData.result,
      });
      var newseasonData = [];
      {
        seasonData.result.map(function (item, index, data) {
          newseasonData.push(Object.keys(item));
        });
      }
      // console.log("NEW DATA", newseasonData);
      this.setState({
        seasonData: newseasonData,
        season: newseasonData[0][0],
      });
    } catch (err) {
      toast.error("Something's not right");
    }
  }
  async getperillist(rowdata) {
    // selUID
    // console.log("UID", rowdata.uid);
    this.setState({
      selUID: rowdata.uid,
    });
    let searchData = {
      season: rowdata.season,
      crop: rowdata.crop,
    };
    searchData = JSON.stringify(searchData);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + localStorage.getItem("token"),
      },
      body: searchData,
    };
    var perilResponse = await fetch(
      process.env.REACT_APP_APIEND + `smart_crop/get-perils`,
      requestOptions
    ).then((res) => {
      return res.json();
    });
    var result = perilResponse.result[0];
    // var defaultselectionperil = [];
    // result.perils.map(function (item, range, data) {
    //   defaultselectionperil.push(String(item));
    // });
    this.setState(
      {
        perilagainstcrop: result.perils,
        // defaultselectionperil: defaultselectionperil,
      },
      () => {
        // console.log("DEFAULT SELECTIOn", this.state.defaultselectionperil);
        this.tog_center();
      }
    );
  }
  edit(rowdata) {
    // console.log("ROW DATA ", rowdata);
    this.setState({
      selUID: rowdata.uid,
    });
    if (rowdata.centralGovtSubsidy == "Yes") {
      this.setState({
        Cgovtsubsidy: true,
      });
    } else {
      this.setState({
        Cgovtsubsidy: false,
      });
    }
    if (rowdata.stateGovtSubsidy == "Yes") {
      this.setState({
        Sgovtsubsidy: true,
      });
    } else {
      this.setState({
        Sgovtsubsidy: false,
      });
    }
    this.tog_center();
  }

  getUploadParams = ({ meta }) => {
    return { url: "https://httpbin.org/post" };
  };
  async onsearch() {
    this.setState({
      showLoader: true,
      showWaiting: false,
      showtable: false,
    });

    let searchdata = {
      season: this.state.season,
      rua:"all"
    };
    searchdata = JSON.stringify(searchdata);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + localStorage.getItem("token"),
      },
      body: searchdata,
    };
    var transaction = await fetch(
      process.env.REACT_APP_APIEND + `farmer/farmer-insurance-status`,
      requestOptions
    ).then((res) => {
      return res.json();
    });
    this.setState({
      csvmapdata: transaction.data,
    });
    this.generatetabledata(transaction.data);
  }
  handleChangeStatus = ({ meta, file }, status) => {
    console.log(status, meta, file);
  };

  checkstatus(status) {
    if (status == "Yes") {
      return (
        <i
          style={{ transform: "translateX(0) translateY(20%)" }}
          className="ri-check-fill"
        ></i>
      );
    } else {
      return (
        <i
          style={{ transform: "translateX(0) translateY(20%)" }}
          className="ri-close-fill"
        ></i>
      );
    }
  }
  perillist(perils) {
    var peril_list = [];
    perils.map(function (item, index, data) {
      peril_list.push(item);
      index != data.length - 1 ? peril_list.push(",") : peril_list.push("");
    });
    return [peril_list];
  }
  generatetabledata(data) {
    let transaction_data = [];
    var getthis = this;
    data.map(function (item, index, data) {
      var newdata = {
        fmid:<p className="alnleft">{item.farmerID}</p>,
        fmname: item.farmerName,
        mobile: item.mobile,
        rua: item.rua,
        crop: item.crop,
        Cgovtsubsidy: <p className="vertical-center">{getthis.checkstatus(item.centralGovtSubsidy)}</p>,
        Sgovtsubsidy:  <p className="vertical-center">{getthis.checkstatus(item.stateGovtSubsidy)}</p>,
        action: (
          <p onClick={() => getthis.edit(item)}>
            <Link>Change Status</Link>
          </p>
        ),
      };
      transaction_data.push(newdata);
    });
    this.setState({
      transaction_list: transaction_data,
      showLoader: false,
      showtable: true,
      showbulkupdate: true,
    });
  }
  handleeasonchange(e) {
    this.setState({
      season: e.target.value,
    });
  }

  handleSubmit = async (files, allFiles) => {
    var file = files.map((f) => f.meta);
    var allfiles = allFiles[0].file;
    const formData = new FormData();
    formData.append("file", allfiles);

    allFiles.forEach((f) => f.remove());
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: "Token " + localStorage.getItem("token"),
      },
      body: formData,
    };
    try {
      var data = await fetch(
        process.env.REACT_APP_APIEND + `farmer/farmer-insurance-subsidy-status-upload`,
        requestOptions
      ).then((res) => {
        return res.json();
      });
      if (data.code == 200) {
        toast.success("Data Uploaded Successfully!");
        this.onsearch();
      } else {
        toast.error("Error Occured!");
      }
    } catch (err) {
      toast.error("Something's not right");
    }
  };
  handleupdateperil(value) {
    var getthis = this;
    var perils = [];
    perils.push(value);

    perils.map(function (item, index, data) {
      getthis.setState({
        updatedperils: item,
      });
    });
  }

  checktruestatus(status) {
    if (status == true) {
      return "Yes";
    } else {
      return "No";
    }
  }

  async updatedata() {
    let searchData = {
      uid: this.state.selUID,
      user_id: localStorage.getItem("uid"),
      stateGovtSubsidy:  this.checktruestatus(this.state.Sgovtsubsidy),
      centralGovtSubsidy: this.checktruestatus(this.state.Cgovtsubsidy),      
    };
    searchData = JSON.stringify(searchData);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + localStorage.getItem("token"),
      },
      body: searchData,
    };
    // console.log("SEARCH DATA",searchData)
    try {
      var updateStatus = await fetch(
        process.env.REACT_APP_APIEND + `farmer/farmer-insurance-status-update`,
        requestOptions
      ).then((res) => {
        return res.json();
      });
      if (updateStatus.code == 200) {
        toast.success("Data Updated Successfully!");
      } else {
        toast.error("Error Occured!");
      }
    } catch (err) {
      toast.error("Could not Update !");
    }
    this.tog_center();
    this.onsearch();
  }
  onChangeCsubsidy(e) {
    if (e.target.checked == true) {
      this.setState({
        Cgovtsubsidy: true,
      });
    } else {
      this.setState({
        Cgovtsubsidy: false,
      });
    }
  }
  onChangeSsubsidy(e) {
    // console.log("CHEKCED",e.target)
    if (e.target.checked == true) {
      this.setState({
        Sgovtsubsidy: true,
      });
    } else {
      this.setState({
        Sgovtsubsidy: false,
      });
    }
  }
  componentDidMount() {
    validate_Token();
    const user_role = Permission();
    if (user_role[0] == "DR") {
      this.setState({
        userDR: true,
        userKB: false,
        showStateSubsidy: true,
      });
    } else if (user_role[0] == "KB") {
      this.setState({
        userDR: false,
        userKB: true,
        showStateSubsidy: false,
      });
    }
    this.fetchseason();
  }
  render() {
    const { t, i18n } = this.props;
    let data_table = {
      columns: [
        {
          label: "Farmer ID",
          field: "fmid",
          sort: "asc",
          width: 100
        },
        {
          label: "Farmer Name",
          field: "fmname",
          width: 250
        },
        {
          label: "Mobile No",
          field: "mobile",
          width: 150
        },
        {
          label: "RUA",
          field: "rua",
          width: 150
        },  
        {
          label: "Crop",
          field: "crop",
          width: 150
        },       
        {
          label: "Central Govt Subsidy Settled?",
          field: "Cgovtsubsidy",
          width: 150
        },

        {
          label: "State Govt Subsidy Settled?",
          field: "Sgovtsubsidy",
          width: 150
        },
        {
          label: "Action",
          field: "action",
          width: 150
        },
      ],
      rows: this.state.transaction_list,
    };

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Row>
              <Col xs={12}>
                <h4
                  className="card-title"
                  style={{ padding: "5px", fontSize: "18px" }}
                >
                  {t("Govt. Subsidy Status")}{" "}
                </h4>
              </Col>
            </Row>
            <Row>
              <Col>
                <Card style={{ backgroundColor: "#f1f6fb" }}>
                  <CardBody>
                    <Col>
                      <Row>
                        <Col md="5">
                          <div class="row g-2">
                            <div class="col-auto">
                              <label
                                for="inputPassword6"
                                class="col-form-label"
                              >
                                {t("Season")} :
                              </label>
                            </div>
                            <div class="col-md-8">
                              <select
                                className="form-select"
                                style={{ fontSize: "11px" }}
                                onChange={this.handleeasonchange}
                                name="season"
                              >
                                {this.state.seasonData.length > 0 &&
                                  this.state.seasonData.map((item) => (
                                    <option value={item} key={item}>
                                      {item}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>
                        </Col>

                        <Col md="2">
                          <FormGroup>
                            <Button
                              color="primary"
                              className="waves-effect waves-light mr-1 btn-farmertransacion"
                              style={{ fontSize: "11px", float: "right" }}
                              onClick={this.onsearch}
                            >
                              {t("Search")}
                            </Button>
                          </FormGroup>
                        </Col>

                        <Col md="5">
                          <FormGroup
                            style={
                              this.state.showbulkupdate == true
                                ? {}
                                : { display: "none" }
                            }
                          >
                            <Button
                              color="success"
                              className="waves-effect waves-light mr-1 btn-bulkupdate"
                              style={{
                                fontSize: "11px",
                                float: "right",
                                marginBottom: "20px",
                              }}
                              onClick={this.tog_bulkupdate}
                            >
                              Bulk Update
                            </Button>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col></Col>
                      </Row>
                    </Col>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <center>
                      <p
                        style={
                          this.state.showWaiting == true
                            ? {}
                            : { display: "none" }
                        }
                      >
                        {t("Waiting for search")}
                      </p>
                      <Spinner
                        style={
                          this.state.showLoader == false
                            ? { display: "none" }
                            : {}
                        }
                      ></Spinner>
                    </center>
                    <div
                      id="farmer-transaction"
                      style={
                        this.state.showtable == true ? {} : { display: "none" }
                      }
                    >
                      <MDBDataTable
                        // searching={false}
                        lengthChange={false}
                        disableRetreatAfterSorting={true}
                        bordered
                        scrollX
                        order={["fmid", "asc"]}
                        data={data_table}
                        className="datadownloadtable"
                        style={{ fontSize: "12px" }}
                        maxHeight="300px"
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
          {/* =============================================ACTION MODAL=============================================== */}
          <Modal
            isOpen={this.state.modal_center}
            toggle={this.tog_center}
            backdrop="static"
            centered
            size="sm"
          >
            <ModalHeader
              toggle={() => this.setState({ modal_center: false })}
              style={{ backgroundColor: "#A4C1D2" }}
            >
              Update Status
            </ModalHeader>
            {/* <ModalBody style={{ marginLeft: "10%" }}> */}
            <ModalBody style={{ marginLeft: "5%" }}>
              <p style={{ fontSize: 17 }}></p>
              {/* <hr className="mt-1" /> */}
              <AvForm>
                <div class="row">
                  <div className="form-check form-check-right mb-1">
                    <div className="col">
                      <Input
                        className="form-check-input"
                        type="checkbox"
                        id="subsidycheck"
                        onChange={this.onChangeCsubsidy}
                        checked={this.state.Cgovtsubsidy}
                      />
                      <Label
                        className="form-check-label"
                        htmlFor="subsidycheck"
                        style={{ fontWeight: "lighter" }}
                      >
                        Central Govt Subsidy Settled ?
                      </Label>
                    </div>
                  </div>
                  <div class="w-100"></div>
                  <div className="form-check form-check-right mb-1">
                    <div class="col">
                      <Input
                        className="form-check-input"
                        type="checkbox"
                        id="insurancheck"
                        onChange={this.onChangeSsubsidy}
                        checked={this.state.Sgovtsubsidy}
                      />
                      <Label
                        className="form-check-label"
                        htmlFor="insurancheck"
                        style={{ fontWeight: "lighter" }}
                      >
                        State Govt Subsidy Settled ?
                      </Label>
                    </div>
                  </div>
                </div>
                <ModalFooter style={{ border: "none" }}>
                  <Button
                    type="button"
                    color="light"
                    onClick={() => this.setState({ modal_center: false })}
                  >
                    Cancel
                  </Button>
                  <Button type="" color="primary" onClick={this.updatedata}>
                    Update
                  </Button>
                </ModalFooter>
              </AvForm>
            </ModalBody>
          </Modal>
          {/* ===============================================Bulk Update========================================================= */}
          <Modal
            isOpen={this.state.modal_bulkupdate}
            toggle={this.tog_bulkupdate}
            backdrop="static"
            centered
            size="md"
          >
            <ModalHeader
              toggle={() => this.setState({ modal_bulkupdate: false })}
              style={{ backgroundColor: "#A4C1D2" }}
            >
              Bulk Update
            </ModalHeader>
            <ModalBody>
              <div style={{ float: "right", padding: "2px" }}>
                <CSVLink
                  filename="TransactionData.csv"
                  data={this.state.csvdownloadData}
                >
                  Download Data
                </CSVLink>
              </div>
              <Form>
                <Dropzone
                  getUploadParams={this.getUploadParams}
                  onChangeStatus={this.handleChangeStatus}
                  onSubmit={this.handleSubmit}
                  accept=".csv"
                  maxFiles={1}
                  styles={{
                    dropzone: { overflow: "hidden" },
                  }}
                  inputContent={
                    <div style={{ fontSize: "16px", color: "#8f8e8d" }}>
                      Upload Data
                    </div>
                  }
                />
              </Form>
            </ModalBody>
          </Modal>
        </div>
      </React.Fragment>
    );
  }
}
export default withNamespaces()(FarmerTransactionStatus);
