import React from 'react';
import { toast } from 'react-toastify';
  
class ChangePassword extends React.Component {
    constructor() {
    super();
    this.state = {
      input: {},
      errors: {}
    };
     
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
     
  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
  
    this.setState({
      input
    });
  }
  
  async updatePassword(){
    let updatePasswordData={
      "old_pass":this.state.input.old_password,
      "new_pass":this.state.input.password
    }
    let id=localStorage.getItem('uid');
    updatePasswordData=JSON.stringify(updatePasswordData);
    const requestOptions = {
      method: 'PUT',
      headers: {
        'Authorization': 'Token ' + localStorage.getItem('token'),
        'Content-Type': 'application/json'
      },
      body:updatePasswordData

    };
    var changePasswordresp = await fetch(process.env.REACT_APP_APIEND + `department/user/`+id+`/update_password`, requestOptions).then(res => {
      return res.json();
    });
    if(changePasswordresp.hasOwnProperty('code')){
      if(changePasswordresp.code==400){
        toast.error(changePasswordresp.message);
        let input = {};
        input["password"] = "";
        input["confirm_password"] = "";
        input["old_password"]="";
        this.setState({input:input});
        
      }
      else if(changePasswordresp.code==200){
        toast.success("Password updated successfully");
        let input = {};
        input["password"] = "";
        input["confirm_password"] = "";
        input["old_password"]="";
        this.setState({input:input});
      }else if(changePasswordresp.code==625){
        toast.error(changePasswordresp.message);
        let input = {};
        input["password"] = "";
        input["confirm_password"] = "";
        input["old_password"]="";
        this.setState({input:input});
      }
      else if(changePasswordresp.code==626){
        toast.error(changePasswordresp.message);
        let input = {};
        input["password"] = "";
        input["confirm_password"] = "";
        input["old_password"]="";
        this.setState({input:input});
      }
    }

  }
  isStrongPassword(password) {
    const strongPasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/;
    return strongPasswordRegex.test(password);
  }
   
  handleSubmit(event) {
    event.preventDefault();

    if (this.validate()) {
      if (!this.isStrongPassword(this.state.input.password)) {
        toast.error("Password must be at least 6 characters long and contain at least one uppercase letter, one lowercase letter, one number, and one special character.");
      } else {
        this.updatePassword();
      }
    }
  }
  
  validate(){
      let input = this.state.input;
      let errors = {};
      let isValid = true;
  
      if (!input["old_password"]) {
        isValid = false;
        errors["old_password"] = "Please enter your old password.";
      }

      if (!input["password"]) {
        isValid = false;
        errors["password"] = "Please enter your password.";
      }
  
      if (!input["confirm_password"]) {
        isValid = false;
        errors["confirm_password"] = "Please enter your confirm password.";
      }
  
      if (typeof input["password"] !== "undefined" && typeof input["confirm_password"] !== "undefined") {
          
        if (input["password"] != input["confirm_password"]) {
          isValid = false;
          errors["password"] = "Passwords don't match.";
        }
      } 
  
      this.setState({
        errors: errors
      });
  
      return isValid;
  }
     
  render() {
    return (
      <div>
        
        <form onSubmit={this.handleSubmit}>
        <div class="form-group">
            <label for="password">Old Password:</label>
            <input 
              type="password" 
              name="old_password" 
              value={this.state.input.old_password}
              onChange={this.handleChange}
              class="form-control" 
              placeholder="Enter old password" 
              id="old_password" />
  
              <div className="text-danger">{this.state.errors.old_password}</div>
          </div>
         
   
          <div class="form-group">
            <label for="password">New Password:</label>
            <input 
              type="password" 
              name="password" 
              value={this.state.input.password}
              onChange={this.handleChange}
              class="form-control" 
              placeholder="Enter New password" 
              id="password" />
  
              <div className="text-danger">{this.state.errors.password}</div>
          </div>
  
          <div class="form-group">
            <label for="password">Confirm Password:</label>
            <input 
              type="password" 
              name="confirm_password" 
              value={this.state.input.confirm_password}
              onChange={this.handleChange}
              class="form-control" 
              placeholder="Enter confirm password" 
              id="confirm_password" />
  
              <div className="text-danger">{this.state.errors.confirm_password}</div>
          </div>
              
          <input type="submit" value="Submit" class="btn btn-success" style={{marginTop:'20px', float:'right'}} />
        </form>
      </div>
    );
  }
}
  
export default ChangePassword;