import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Divider } from "@material-ui/core";
import { MDBDataTable } from "mdbreact";
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  Label,
  Container,
  Table,
} from "reactstrap";
import img1 from "../../assets/images/profilepic.png";
import { withNamespaces } from "react-i18next";
import { toast } from "react-toastify";
class TsImplication extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fmseason: "Kharif 2020",
      fmcrop: "Paddy",
      fmdp: "",
      rowtabledata: [],
      deficitrain: "",
      disease: "",
      dryspell: "",
      rainfall: "",
      hightemp: "",
      total: "",
      cropData: [],
      seasonData: [],
      allseasonData: [],
      dpData: [],
      payoutData: [],
    };

    this.handledpchange = this.handledpchange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handlecropchange = this.handlecropchange.bind(this);
    this.handleseasonchange = this.handleseasonchange.bind(this);
  }
  handledpchange(e) {
    this.setState({ fmdp: e.target.value });
  }
  handlecropchange(e) {
    this.setState({ fmcrop: e.target.value });
  }
  handleseasonchange(e) {
    this.setState({ fmseason: e.target.value });
    let newcropData = [];
    this.state.allseasonData.map(function (seasons, index, data) {
      var currentSeason = Object.keys(seasons);
      if (currentSeason[0] == e.target.value) {
        seasons[currentSeason[0]].map(function (crops) {
          newcropData.push(crops.crop);
        });
      }
    });
    if (e.target.value == "Kharif 2020") {
      this.setState({
        dpData: ["AIC"],
        fmdp: "AIC",
      });
    } else {
      this.setState({
        dpData: ["Online Sensors"],
        fmdp: "Online Sensors",
      });
    }

    this.setState({
      cropData: newcropData,
      fmcrop: newcropData[0],
    });
  }
  checkDefined(data, peril) {
    if (data != undefined) {
      if (data.hasOwnProperty(peril)) {
        return data[peril];
      } else {
        // console.log("NOT HERE");
        return "N/A";
      }
    }
  }
  generatetableData(data) {
    let tabledata = [];
    var getthis = this;
    let payoutData = data.result[0];
    var keys = Object.keys(payoutData);
    // console.log("KEYS", keys);
    keys.map(function (item, index) {
      if (getthis.checkperil(item) === "RUA") {
        return;
      } else {
        // console.log("KEYS ITEM", keys[index]);
        var newdata = {
          slno: getthis.checkperil(item) === "Total" ? " " : index,
          peril: getthis.checkperil(item),
          amount: getthis.checkamount(
            payoutData[keys[index]],
            getthis.checkperil(item)
          ),
        };
        tabledata.push(newdata);
      }
    });
    this.setState({
      payoutData: tabledata,
    });
    // console.log("TABEL", tabledata);
  }
  checkamount(amount, peril) {
    if (peril == "RUA") {
      return <p className="alnrighttd">{amount}</p>;
    } else {
      return <p className="alnrighttd">{parseFloat(amount).toFixed(2)}</p>;
    }
  }
  checkperil(peril) {
    // console.log("HERE PERIL", peril);
    var allperils = [
      "Deficit Rainfall",
      "Disease Congenial Climate",
      "Dry Spell",
      "Excess Rainfall",
      "High Temperature",
      "Low Temperature",
      "Rise in Night Temperature",
      "Rise in Temperature",
      "Unseasonal Rainfall",
      "Wet Spell",
      "Wind Speed",
      "Wind Speed (A)",
      "Wind Speed (B)",
    ];
    if (allperils.includes(peril)) {
      return peril;
    } else if (peril == "total") {
      return "Total";
    } else if (peril == "rua") {
      return "RUA";
    }
  }
  async handleSubmit(e) {
    let searchdata = {
      farmer_id: localStorage.getItem("farmer_id"),
      season: this.state.fmseason,
      crop: this.state.fmcrop,
      data_provider: this.state.fmdp,
    };
    searchdata = JSON.stringify(searchdata);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token"),
      },
      body: searchdata,
    };
    var payout_data = await fetch(
      process.env.REACT_APP_APIEND + `farmer/peril-payout`,
      requestOptions
    ).then((res) => {
      return res.json();
    });
    if (payout_data.result.length > 0) {
      if (payout_data.result[0].message == "no data available") {
        toast.info("Payout not available!");
      } else {
        this.generatetableData(payout_data);
      }
    } else {
      toast.info("Payout not available!");
      this.setState({
        payoutData: [],
      });
    }
  }
  async getPerilSeason() {
    let searchdata = {
      farmer_id: localStorage.getItem("farmer_id"),
    };
    searchdata = JSON.stringify(searchdata);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token"),
      },
      body: searchdata,
    };
    var SeasonPeril = await fetch(
      process.env.REACT_APP_APIEND + `farmer/farmer-crops-list`,
      requestOptions
    ).then((res) => {
      return res.json();
    });

    var seasons = [];
    var crops = [];
    SeasonPeril.result.map(function (item, index, data) {
      seasons.push(Object.keys(item));
    });
    SeasonPeril.result[0][seasons[0]].map(function (cropslist) {
      crops.push(cropslist.crop);
    });
    if (seasons[0][0] == "Kharif 2020") {
      this.setState({
        dpData: ["AIC"],
        fmdp: "AIC",
      });
    } else {
      this.setState({
        dpData: ["Online Sensors"],
        fmdp: "Online Sensors",
      });
    }

    this.setState(
      {
        seasonData: seasons,
        cropData: crops,
        fmseason: seasons[0][0],
        fmcrop: crops[0],
        allseasonData: SeasonPeril.result,
      },
      () => {
        this.handleSubmit();
      }
    );
    // console.log("SEASON", seasons);
    // console.log("PERILS", crops);
  }

  componentDidMount() {
    this.getPerilSeason();
  }
  render() {
    const { t, i18n } = this.props;
    let termsheet_table = {
      columns: [
        {
          label: "#",
          field: "slno",
        },
        {
          label: "Peril",
          field: "peril",
        },

        {
          label: "Amount",
          field: "amount",
        },
      ],
      rows: this.state.payoutData,
    };
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Row>
              <Col xs={12}>
                <h4
                  className="card-title"
                  style={{ padding: "5px", fontSize: "18px" }}
                >
                  {t("Eligible Payout")}
                </h4>
              </Col>
              <Col xl={12}>
                <Card style={{ backgroundColor: "#F1F6FB" }}>
                  <CardBody>
                    <Row>
                      <Col md={2}>
                        <FormGroup>
                          <Label
                            className="col-md-12 col-form-label"
                            style={{ fontSize: "11px" }}
                          >
                            {t("Season")}
                          </Label>

                          <select
                            className="form-select"
                            name="season"
                            style={{ fontSize: "11px", marginTop: "2%" }}
                            onChange={this.handleseasonchange}
                            defaultValue={this.state.fmseason}
                          >
                            {this.state.seasonData.length > 0 &&
                              this.state.seasonData.map((item) => (
                                <option value={item} key={item}>
                                  {item}
                                </option>
                              ))}
                            {/* <option value="rabi">Rabi 2021</option>                                             */}
                          </select>
                        </FormGroup>
                      </Col>
                      <Col md={2}>
                        <FormGroup>
                          <Label
                            className="col-md-12 col-form-label"
                            style={{ fontSize: "11px" }}
                          >
                            {t("Crop")}
                          </Label>

                          <select
                            className="form-select"
                            style={{ fontSize: "11px", marginTop: "2%" }}
                            onChange={this.handlecropchange}
                            value={this.state.fmcrop}
                          >
                            {this.state.cropData.length > 0 &&
                              this.state.cropData.map((item) => (
                                <option value={item} key={item}>
                                  {item}
                                </option>
                              ))}
                            {/* <option value="coconut">Coconut</option>                                             */}
                          </select>
                        </FormGroup>
                      </Col>
                      <Col md={2}>
                        <FormGroup>
                          <Label
                            className="col-md-12 col-form-label"
                            style={{ fontSize: "11px" }}
                          >
                            {t("Data Provider")}
                          </Label>
                          <select
                            className="form-select"
                            name="dp"
                            style={{ fontSize: "11px", marginTop: "2%" }}
                            onChange={this.handledpchange}
                            value={this.state.tabledp}
                          >
                            {/* <option value="darksky" >DarkSky</option>
                                            <option value="ow">Open Weather</option> */}
                            {this.state.dpData.length > 0 &&
                              this.state.dpData.map((item) => (
                                <option value={item} key={item}>
                                  {item}
                                </option>
                              ))}
                          </select>
                        </FormGroup>
                      </Col>
                      <Col md={2}>
                        <Button
                          color="primary"
                          className="waves-effect waves-light mr-1"
                          style={{
                            fontSize: "11px",
                            marginTop: "27%",
                            float: "right",
                          }}
                          onClick={this.handleSubmit}
                        >
                          {t("Search")}
                        </Button>
                      </Col>
                      {/* <Col md={4}>                                        
                                        
                                        <Label className="col-md-12 col-form-label" style={{ fontSize: '14px',marginTop:"12%",float:"right" }}>Total Area : 1.6 ha</Label>
                                        
                                    </Col> */}
                    </Row>
                  </CardBody>
                </Card>
              </Col>

              <Col xl={12}>
                <Card>
                  <CardBody>
                    <Row>
                      <Col>
                        <div
                          className="table-responsive mt-3"
                          style={{ fontSize: "12px" }}
                        >
                          <MDBDataTable
                            paging={false}
                            responsive
                            searching={false}
                            showPaginationBottom={false}
                            bordered
                            data={termsheet_table}
                            className="datadownloadtable"
                          />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default withNamespaces()(TsImplication);
