import React, { useState, useEffect } from "react";
import FileViewer from "react-file-viewer";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import {
  Table,
  Card,
  CardBody,
  Row,
  Col,
  Container,
  FormGroup,
  Label,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";
// Bootstrap CSS
import { Link } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import { toast } from "react-toastify";
import axios from "axios";
import validate_Token from "../../validateToken";
import { withNamespaces } from "react-i18next";
import KH2020PADDY from "../../assets/pdf/KH2020Paddy.pdf";
import KH2021PADDY from "../../assets/pdf/KH2021Paddy.pdf";
import KH2022PADDY from "../../assets/pdf/KH2022Paddy.pdf";
import KH2020VEG from "../../assets/pdf/KH2020Veg.pdf";
import KH2021VEG from "../../assets/pdf/KH2021Veg.pdf";
import KH2022VEG from "../../assets/pdf/KH2022Veg.pdf";
import RB2020PADDY3 from "../../assets/pdf/RB2020PADDY3.pdf";
import RB2021PADDY3 from "../../assets/pdf/RB2021PADDY3.pdf";
import RB2022PADDY3 from "../../assets/pdf/RB2022PADDY3.pdf";
import RB2020MANGO from "../../assets/pdf/RB2020MANGO.pdf";
import RB2021MANGO from "../../assets/pdf/RB2021MANGO.pdf";
import RB2022MANGO from "../../assets/pdf/RB2022MANGO.pdf";
import RB2020PADDY2 from "../../assets/pdf/RB2020PADDY2.pdf";
import RB2021PADDY2 from "../../assets/pdf/RB2021PADDY2.pdf";
import RB2022PADDY2 from "../../assets/pdf/RB2022PADDY2.pdf";
import RB2020VEG from "../../assets/pdf/RB2020Veg.pdf";
import RB2021VEG from "../../assets/pdf/RB2021Veg.pdf";
import RB2022VEG from "../../assets/pdf/RB2022Veg.pdf";
import RB2020VEGSMR from "../../assets/pdf/RB2020VEGSMR.pdf";
import RB2021VEGSMR from "../../assets/pdf/RB2021VEGSMR.pdf";
import RB2022VEGSMR from "../../assets/pdf/RB2022VEGSMR.pdf";
import RB2020PADDYWINTER from "../../assets/pdf/RB2020PADDYWINTER.pdf";
import RB2021PADDYWINTER from "../../assets/pdf/RB2021PADDYWINTER.pdf";
import RB2022PADDYWINTER from "../../assets/pdf/RB2022PADDYWINTER.pdf";
// Import styles
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import {
  Position,
  Tooltip,
  Viewer,
  Worker,
  PrimaryButton,
} from "@react-pdf-viewer/core";
let file = RB2022MANGO;

function Tm_Pdf(props) {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const [season, setSeason] = useState("");
  const [crop, setCrop] = useState("");
  const [key, setKey] = useState(1);
  const { t, i18n } = props;
  const [perilseasonData, setperilseasonData] = useState([]);
  const [seasonData, setseasonData] = useState([]);
  const [cropData, setcropData] = useState([]);
  async function onChangeSeason(e) {
    setSeason(e.target.value);
    setcropData([]);
    let selected_index = e.target.selectedIndex;
    let season = e.target.value;
    setcropData(
      perilseasonData[selected_index][
        Object.keys(perilseasonData[selected_index])
      ]
    );
    setCrop(
      perilseasonData[selected_index][
        Object.keys(perilseasonData[selected_index])
      ][0]
    );
  }
  const onChangeCrop = (e) => {
    setCrop(e.target.value);
  };
  function searchFile() {
    console.log("SEASON ", season, crop);
    if (crop == "Paddy" && season == "Kharif 2020") {
      file = KH2020PADDY;
    } else if (crop == "Paddy" && season == "Kharif 2021") {
      file = KH2021PADDY;
    } else if (crop == "Paddy" && season == "Kharif 2022") {
      file = KH2022PADDY;
    } else if (crop == "Veg Group I" && season == "Kharif 2020") {
      file = KH2020VEG;
    } else if (crop == "Veg Group I" && season == "Kharif 2021") {
      file = KH2021VEG;
    } else if (crop == "Veg Group I" && season == "Kharif 2022") {
      file = KH2022VEG;
    } else if (crop == "Paddy 3rd Crop" && season == "Rabi 2020-21") {
      file = RB2020PADDY3;
    } else if (crop == "Paddy 3rd Crop" && season == "Rabi 2021-22") {
      file = RB2021PADDY3;
    } else if (crop == "Paddy 3rd Crop" && season == "Rabi 2022-23") {
      file = RB2022PADDY3;
    } else if (crop == "Mango" && season == "Rabi 2020-21") {
      file = RB2020MANGO;
    } else if (crop == "Mango" && season == "Rabi 2021-22") {
      file = RB2021MANGO;
    } else if (crop == "Mango" && season == "Rabi 2022-23") {
      file = RB2022MANGO;
    } else if (crop == "Paddy 2nd Crop" && season == "Rabi 2020-21") {
      file = RB2020PADDY2;
    } else if (crop == "Paddy 2nd Crop" && season == "Rabi 2021-22") {
      file = RB2021PADDY2;
    } else if (crop == "Paddy 2nd Crop" && season == "Rabi 2022-23") {
      file = RB2022PADDY2;
    } else if (crop == "Veg Group I" && season == "Rabi 2020-21") {
      file = RB2020VEG;
    } else if (crop == "Veg Group I" && season == "Rabi 2021-22") {
      file = RB2021VEG;
    } else if (crop == "Veg Group I" && season == "Rabi 2022-23") {
      file = RB2022VEG;
    } else if (crop == "Veg Group I - SMR" && season == "Rabi 2020-21") {
      file = RB2020VEGSMR;
    } else if (crop == "Veg Group I - SMR" && season == "Rabi 2021-22") {
      file = RB2021VEGSMR;
    } else if (crop == "Veg Group I - SMR" && season == "Rabi 2022-23") {
      file = RB2022VEGSMR;
    } else if (crop == "Paddy Winter" && season == "Rabi 2020-21") {
      file = RB2020PADDYWINTER;
    } else if (crop == "Paddy Winter" && season == "Rabi 2021-22") {
      file = RB2021PADDYWINTER;
    } else if (crop == "Paddy Winter" && season == "Rabi 2022-23") {
      file = RB2022PADDYWINTER;
    } else {
      file = [];
    }
    setKey(key + 1);
  }
  async function getseasoncrop() {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + localStorage.getItem("token"),
      },
    };

    var seasonData = await fetch(
      process.env.REACT_APP_APIEND + `smart_crop/term-sheet-season-crop`,
      requestOptions
    ).then((res) => {
      return res.json();
    });
    setperilseasonData(seasonData.result);
    var newseasonData = [];
    var newcropdata = [];
    {
      seasonData.result.map(function (item, index, data) {
        newseasonData.push(Object.keys(item));
      });
    }
    setseasonData(newseasonData);
    setcropData(seasonData.result[0][Object.keys(seasonData.result[0])]);
    setSeason(newseasonData[0][0]);
    // this.setState({
    //   seasonData: newseasonData,
    //   cropData: seasonData.result[0][Object.keys(seasonData.result[0])],
    //   selectedseason: newseasonData[0][0],
    //   // crop: seasonData.result[0][Object.keys(seasonData.result[0])][0],
    // });
  }
  useEffect(() => {
    getseasoncrop();
    validate_Token();
  }, []);
  
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xs={12}>
              <h4
                className="card-title"
                style={{ padding: "5px", fontSize: "18px" }}
              >
                {t("Termsheet Reference")}
              </h4>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card style={{ backgroundColor: "#f1f6fb" }}>
                <CardBody>
                  <Col>
                    <Row>
                      <Col md="2">
                        <FormGroup>
                          <Label
                            className="col-md-10 col-form-label"
                            style={{ fontSize: "14px" }}
                          >
                            {t("Season")}{" "}
                          </Label>
                          <select
                            className="form-select"
                            style={{ fontSize: "11px" }}
                            name="season"
                            onChange={(e) => onChangeSeason(e)}
                          >
                            {seasonData.length > 0 &&
                              seasonData.map((item) => (
                                <option value={item} key={item}>
                                  {item}
                                </option>
                              ))}

                            {/* <option value="Kharif 2020">Kharif 2020</option>
                            <option value="Kharif 2021">Kharif 2021</option>
                            <option value="Kharif 2022">Kharif 2022</option>
                            <option value="Rabi 2020-21">Rabi 2020-21</option>
                            <option value="Rabi 2021-22">Rabi 2021-22</option>
                            <option value="Rabi 2022-23">Rabi 2022-23</option> */}
                          </select>
                        </FormGroup>
                      </Col>
                      <Col md="2">
                        <FormGroup>
                          <Label
                            className="col-md-10 col-form-label"
                            style={{ fontSize: "14px" }}
                          >
                            {t("Crop")}{" "}
                          </Label>
                          <select
                            className="form-select"
                            style={{ fontSize: "11px" }}
                            name="crop"
                            value={crop}
                            onChange={onChangeCrop}
                          >
                            {cropData.length > 0 &&
                              cropData.map((item) => (
                                <option value={item} key={item}>
                                  {item}
                                </option>
                              ))}

                            {/* <option value="Paddy">Paddy</option>
                            <option value="VegGroup">Veg Group</option>
                            <option value="Paddy3">Paddy 3rd</option>
                            <option value="Mango">Mango</option>
                            <option value="Paddy2">Paddy 2nd</option>
                            <option value="VegGroupSMR">Veg Group SMR</option>
                            <option value="PaddyWinter">Paddy Winter</option> */}
                          </select>
                        </FormGroup>
                      </Col>
                      <Col md="2">
                        <FormGroup>
                          <Label
                            className="col-md-12 col-form-label"
                            style={{ fontSize: "14px" }}
                          >
                            <span>&nbsp;</span>
                          </Label>
                          <Button
                            color="primary"
                            className="waves-effect waves-light mr-1"
                            style={{ fontSize: "11px", float: "right" }}
                            onClick={searchFile}
                          >
                            {t("Search")}
                          </Button>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col></Col>
                    </Row>
                  </Col>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  {/* <FileViewer                    
                      fileType={type}
                      filePath={file}
                      key={this.state.key}
                    />    
                                     */}
                  <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.1.266/build/pdf.worker.min.js">
                    <div
                      style={{
                        height: "550px",
                        border: "1px solid #919191",
                      }}
                    >
                      <Viewer
                        initialPage={3}
                        fileUrl={file}
                        plugins={[defaultLayoutPluginInstance]}
                      />
                    </div>
                  </Worker>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}
export default withNamespaces()(Tm_Pdf);