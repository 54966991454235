import React, { Component } from "react";
import {
  Dropdown,
  DropdownToggle,
  Modal,
  DropdownMenu,
  DropdownItem,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Table,
  Col,
  Label,
  FormGroup,
  Container,
} from "reactstrap";
import ChangePassword from "../../../pages/Authentication/changePassword";
//i18n
import { withNamespaces } from "react-i18next";

// users
import avatar2 from "../../../assets/images/users/unnamed.png";
import { auth } from "firebase";

class ProfileMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: false,
      modal_large: false,
      name: "",
      designation: "",
      email: "",
      phoneNo: "",
      userType: "",
      krishiBhavan: "",
      user_FM: false,
      user_ADM: "",
      krishibhavanfield:false,
      isMenuVisible: false,
    };
    this.toggle = this.toggle.bind(this);
    this.tog_static = this.tog_static.bind(this);
    this.tog_large = this.tog_large.bind(this);
  }
async getuserData(){
  let id = localStorage.getItem("uid");
  // console.log("Id:", id);
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: "Token " + localStorage.getItem("token"),
      "Content-Type": "application/json",
    },
  };
  var userData = await fetch(
    process.env.REACT_APP_APIEND + `department/user/` + id,
    requestOptions
  ).then((res) => {
    return res.json();
  });
  localStorage.setItem("user_type", userData.user_type);
  localStorage.setItem("username", userData.name);
  localStorage.setItem(
    "krishibhavan",
    userData.krishibhavan
  );
//  console.log(userData);
  this.setState({
    name: userData.name,
    designation: userData.designation,
    phoneNo: userData.mob_no,
    userType: userData.user_type,
    email: userData.email,      
  });
}
componentDidMount() {
  setTimeout(() => {
    this.setState({ isMenuVisible: true });
    let user = localStorage.getItem("user_type");
    if (user == "DR") {
      this.setState({          
        krishibhavanfield:true,
      });
    }       
    if (user == "FM") {
      this.setState({
        user_FM: true,
      });
    }
    if (user == "ADM") {
      this.setState({
        user_ADM: true,
        krishibhavanfield:true,
      });
      this.setState({
        krishiBhavan:localStorage.getItem("krishibhavan")
      }) 
    }
  }, 500);
 this.getuserData()
  }

  toggle() {
    this.setState((prevState) => ({
      menu: !prevState.menu,
    }));
  }

  tog_static() {
    this.setState((prevState) => ({
      modal_static: !prevState.modal_static,
    }));
  }
  tog_large() {
    this.setState((prevState) => ({
      modal_large: !prevState.modal_large,
    }));
  }
  show() {
    this.setState({ visible: true });
  }
  hide() {
    this.setState({ visible: false });
  }
  render() {
    let username = "Admin12e";
    let krishibhavan = "krishibhavan";
    if (localStorage.getItem("token")) {
      username = localStorage.getItem("username");
      krishibhavan = localStorage.getItem("krishibhavan");
    }

    return (
      <React.Fragment>
        <Dropdown
          isOpen={this.state.menu}
          toggle={this.toggle}
          className="d-inline-block user-dropdown"
        >
          <DropdownToggle
            tag="button"
            className="btn header-item waves-effect"
            id="page-header-user-dropdown"
          >
            <img
              className="rounded-circle header-profile-user mr-1"
              src={avatar2}
              alt="Header Avatar"
            />
            <span className="d-xl-inline-block ml-1 text-transform">
              {username}
            </span>
            <i className="mdi mdi-chevron-down d-none ml-1 d-xl-inline-block"></i>
          </DropdownToggle>
          <DropdownMenu right>
            <DropdownItem
              onClick={this.tog_large}
              style={this.state.user_FM ? { display: "none" } : {}}
            >
              <i className="ri-user-line align-middle mr-1"></i>{" "}
              {this.props.t("Profile")}
            </DropdownItem>
            <DropdownItem
              onClick={this.tog_static}
              style={this.state.user_FM ? { display: "none" } : {}}
            >
              <span className="badge badge-success float-right mt-1"></span>
              <i className="ri-lock-password-line"></i>{" "}
              {this.props.t("Change Password")}
            </DropdownItem>
            <DropdownItem divider />
            <DropdownItem className="text-danger" href="/logout">
              <i className="ri-shut-down-line align-middle mr-1 text-danger"></i>{" "}
              {this.props.t("Logout")}
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
        <Modal
          isOpen={this.state.modal_static}
          toggle={this.tog_static}
          backdrop="static"
        >
          <ModalHeader
            toggle={() => this.setState({ modal_static: false })}
            style={{ backgroundColor: "#A4C1D2" }}
          >
            Change Password
          </ModalHeader>
          <ModalBody>
            <ChangePassword />
          </ModalBody>
        </Modal>

        <Modal
          size="lg"
          isOpen={this.state.modal_large}
          toggle={this.tog_large}
          backdrop="static"
        >
          <ModalHeader
            toggle={() => this.setState({ modal_large: false })}
            style={{ backgroundColor: "#A4C1D2" }}
          >
            Profile
          </ModalHeader>
          <ModalBody>
            <Row className="p-1">
              <Col lg={12}>
              {this.state.isMenuVisible && (
                <Container >
                  <Row>
                    <Col md={4}>
                      <FormGroup>
                        <Label
                          className="col-md-6"
                          style={{ fontSize: "12px", color: "#a3a3a3" }}
                        >
                          Name
                        </Label>
                        <Col>{this.state.name}</Col>
                      </FormGroup>
                    </Col>
                    <Col md={4}>
                      <FormGroup>
                        <Label
                          className="col-md-6"
                          style={{ fontSize: "12px", color: "#a3a3a3" }}
                        >
                          Designation
                        </Label>
                        <Col> {this.state.designation}</Col>
                      </FormGroup>
                    </Col>
                    <Col sm={4}>
                      <FormGroup>
                        <Label
                          className="col-md-6"
                          style={{ fontSize: "12px", color: "#a3a3a3" }}
                        >
                          Email
                        </Label>
                        <Col>{this.state.email}</Col>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col sm={4}>
                      <FormGroup>
                        <Label
                          className="col-md-6"
                          style={{ fontSize: "12px", color: "#a3a3a3" }}
                        >
                          Phone Number
                        </Label>
                        <Col>{this.state.phoneNo}</Col>
                      </FormGroup>
                    </Col>
                    <Col sm={4}>
                      <FormGroup>
                        <Label
                          className="col-md-6"
                          style={{ fontSize: "12px", color: "#a3a3a3" }}
                        >
                          User Type
                        </Label>
                        <Col>{this.state.userType}</Col>
                      </FormGroup>
                    </Col>
                    <Col
                      sm={4}
                      style={this.state.krishibhavanfield ? { display: "none" } : {}}
                    >
                      <FormGroup>
                        <Label
                          className="col-md-6"
                          style={{ fontSize: "12px", color: "#a3a3a3" }}
                        >
                          Krishibhavan
                        </Label>
                        <Col>{krishibhavan}</Col>
                      </FormGroup>
                    </Col>
                  </Row>
                </Container>
                )}
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      </React.Fragment>
    );
  }
}

export default withNamespaces()(ProfileMenu);
