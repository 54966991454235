import React, { Component } from "react";
import {
  Card,
  CardBody,
  Row,
  Col,
  FormGroup,
  Label,
  Container,
  Button,
  Spinner,
} from "reactstrap";
// import './table.css'
import { MDBDataTable } from "mdbreact";
import "antd/dist/antd.css";
import moment from "moment";
import Permission from "../../permission.js";
import { DatePicker, Space } from "antd";
import validate_Token from "../../validateToken.js";
import { withNamespaces } from "react-i18next";
//import css
import "../../assets/scss/tab.css";

const { RangePicker } = DatePicker;

class Alert extends Component {
  constructor(props) {
    super(props);
    this.state = {
      crop: "all",
      rua: "",
      peril: "all",
      season: "all",
      fromDate: "",
      toDate: "",
      alertData: [],
      showWaiting: true,
      showloader: true,
      showtable: false,
      seasonData: [],
      searchButton: false,
      perilseasonData: [],
      perilData: [],
      alert_type: "A2",
      cropData: [],
      kb1: true,
      kb2: true,
      kb3: true,
      kb4: true,
      kb5: true,
      kb6: true,
      kb7: true,
      all: true,
    };
    this.onChangedate = this.onChangedate.bind(this);
    this.getalert = this.getalert.bind(this);
    this.onchange = this.onchange.bind(this);
    this.handleCropchange = this.handleCropchange.bind(this);
    this.handlePerilchange = this.handlePerilchange.bind(this);
  }
  onchange(ev) {
    this.setState({
      [ev.target.name]: ev.target.value,
    });
    let selected_index = ev.target.selectedIndex;
    if (ev.target.name == "season") {
      this.setState({
        cropData: [],
      });
      let season = ev.target.value;
      let allcropdata = this.state.perilseasonData;
      if (selected_index == "0") {
        var list_array = [];
        this.setState({
          cropData: [],
        });
        allcropdata.map((index) => {
          list_array.push("---------" + Object.keys(index)[0] + "--------");
          console.log("INDEX ", index[Object.keys(index)[0]]);
          var seasoncrop = index[Object.keys(index)[0]];
          seasoncrop.map((crop) => {
            list_array.push(crop);
          });
        });
        this.setState({
          cropData: list_array,  
          crop:"all",
          peril:"all"
        });
      } else {
        this.setState({
          cropData: [],
        },()=>{
          var seasoncrop = allcropdata[selected_index - 1];
          // console.log("Season crop", seasoncrop[Object.keys(seasoncrop)[0]]);
          var croplist = seasoncrop[Object.keys(seasoncrop)[0]];
          var list_array = [];
          croplist.map((item, index, data) => {
            list_array.push(item);
          });
          this.setState({
            cropData: list_array,
            crop:"all",
            peril:"all"
          });
        });
     
      }
    }
  }
  disabledDate(current) {
    // Can not select days before today and today
    return current && current > moment().endOf("day");
  }
  onChangedate(dateString) {
    const date1 = new Date(dateString[0]);
    const date2 = new Date(dateString[1]);
    var from_dd = String(date1.getDate()).padStart(2, "0");
    var from_mm = String(date1.getMonth() + 1).padStart(2, "0"); //January is 0!
    var from_yyyy = date1.getFullYear();
    var from_date = from_yyyy + "-" + from_mm + "-" + from_dd;

    var to_dd = String(date2.getDate()).padStart(2, "0");
    var to_mm = String(date2.getMonth() + 1).padStart(2, "0"); //January is 0!
    var to_yyyy = date2.getFullYear();
    var to_date = to_yyyy + "-" + to_mm + "-" + to_dd;

    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      this.setState({
        fromDate: from_date,
        toDate: to_date,
        searchButton: true,
      });
  }

  async getalert() {
    this.setState({
      showloader: false,
      showWaiting: false,
      showtable: false,
    });
    let bodydata = {
      crop: this.state.crop,
      rua: this.state.rua  === "Chittur-Thathamangalam" ? "Chittur" : this.state.rua,
      season: this.state.season,
      from_date: this.state.fromDate,
      to_date: this.state.toDate,
      alert_type: this.state.alert_type,
      peril: this.state.peril,
    };
    bodydata = JSON.stringify(bodydata);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + localStorage.getItem("token"),
      },
      body: bodydata,
    };

    var alertresponse = await fetch(
      process.env.REACT_APP_APIEND + `smart_crop/alert-notification`,
      requestOptions
    ).then((res) => {
      return res.json();
    });

    this.generatetabledata(alertresponse.data);
  }
  generatetabledata(data) {
    let getthis = this;
    let tabledata = [];
    data.map(function (item, index, data) {
      var newdata = {
        alertID: item.alertID,
        rua: item.rua,
        season: item.season,
        crop: item.crop,
        peril: item.peril,
        phase: item.phase,
        date: moment(item.dateTime.slice(0, 10)).format("DD-MM-YYYY"),
        // time: (item.dateTime).slice(11, 14) +"00",
        time: "23:59",
        // alerttype: item.alertType,
        alerttype: item.alert_type,
        alertmessage: getthis.getmessage(item),
        payout: item.payout,
      };
      tabledata.push(newdata);
    });
    this.setState({
      alertData: tabledata,
      showloader: true,
      showtable: true,
    });
  }
  getmessage(alert) {
    if (alert.peril == "Dry Spell") {
      return (        
        "The " +
        alert.parameter[0] +
        " is " +     
        alert.calculated_value +
        " mm (Trigger : <2.5 mm)"
      );
    } else if (alert.peril == "Disease Congenial Climate") {
      if (alert.calculated_value2 != null){    
        if(alert.crop === "Mango"){
          return (
            "The daily min temperature is "+ alert.calculated_value2 +"°C & the daily average humidity is "+ alert.calculated_value +" % (Min. Temperature < 24 °C; Avg. Rel. Humidity > 80%)"
     
          );
        }
        
        else if( alert.crop === "Veg Group I"){
          return( "The daily max temperature is "+ alert.calculated_value2 +"°C & the daily average humidity is "+ alert.calculated_value +" % (Max. Temperature between 28°C & 32°C; Avg. Rel. Humidity > 80%)")
      }
      }
      else{
        return alert.alertMessage
      }
    } else if (alert.peril == "Excess Rainfall") {
      return (        
        "The Calculated Value is " + alert.calculated_value +     
        " mm(Trigger : Cummulative rainfall >" +alert.trigger[0]+ ")"
      );
    }
    // else if (alert.peril == "Excess Rainfall") {
    //   return (        
    //     "The daily cummulative rainfall is " + alert.calculated_value +     
    //     " mm(Trigger : Cummulative rainfall >" +alert.trigger[0]+ ")"
    //   );
    // }
    else {
      return alert.alertMessage;
    }
  }
  async fetchseason() {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + localStorage.getItem("token"),
      },
    };

    var seasonData = await fetch(
      process.env.REACT_APP_APIEND + `smart_crop/term-sheet-season-crop`,
      requestOptions
    ).then((res) => {
      return res.json();
    });
    this.setState({
      perilseasonData: seasonData.result,
    });

    var newseasonData = [];
    {
      seasonData.result.map(function (item, index, data) {
        newseasonData.push(Object.keys(item));
      });
    }
    this.setState({
      seasonData: newseasonData,
    });
  }
  async handleCropchange(e) {
    this.setState({
      crop: e.target.value,
    });
    let searchdata = {
      season: this.state.season,
      crop: e.target.value,
    };
    let selected_index = e.target.selectedIndex;
    if (selected_index == "0") {
      this.setState({       
        perilData: [
          "Deficit Rainfall",
          "Disease Congenial Climate",
          "Dry Spell",
          "Excess Rainfall",
          "High Temperature",
          "Low Temperature",
          "Rise in Night Temperature",
          "Rise in Temperature",
          "Unseasonal Rainfall",
          "Wet Spell",
          "Wind Speed",
          "Wind Speed (A)",
          "Wind Speed (B)",
        ],
      });
    } else {
      searchdata = JSON.stringify(searchdata);
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + localStorage.getItem("token"),
        },
        body: searchdata,
      };
      try {
        var perildata = await fetch(
          process.env.REACT_APP_APIEND + `smart_crop/get-perils`,
          requestOptions
        ).then((res) => {
          return res.json();
        });
        var newperils = perildata.result[0].perils;
        this.setState({
          perilData: newperils,
          peril:"all"
        });
      } catch (err) {
        // toast.error("Check Season");
      }
    }
  }
  handlePerilchange(e) {
    this.setState({
      peril: e.target.value,
    });
  }
  checkcrop(item) {
    if (item.charAt(1) == "-") {
      return (
        <option disabled value={item} key={item}>
          {item}
        </option>
      );
    } else {
      return (
        <option value={item} key={item}>
          {item}
        </option>
      );
    }
  }
  componentDidMount() {
    validate_Token();
    this.fetchseason();
    const krishibhavan = Permission();
    if (krishibhavan[0] != "KB") {
      this.setState({
        selrua: "Nallepilly",
        rua: "Nallepilly",
        all: true,
      });
    }
    if (krishibhavan[1] == "Nallepilly" && krishibhavan[0] == "KB") {
      this.setState({
        kb1: false,
        kb2: false,
        kb3: false,
        kb4: true,
        kb5: false,
        kb6: false,
        kb7: false,
        selrua: "Nallepilly",
        rua: "Nallepilly",
        all: false,
      });
    } else if (
      krishibhavan[1] == "Chittur-Thathamangalam" &&
      krishibhavan[0] == "KB"
    ) {
      this.setState({
        kb1: true,
        kb2: false,
        kb3: false,
        kb4: false,
        kb5: false,
        kb6: false,
        kb7: false,
        selrua: "Chittur-Thathamangalam",
        rua: "Chittur-Thathamangalam",
        all: false,
      });
    } else if (krishibhavan[1] == "Kozhinjampara" && krishibhavan[0] == "KB") {
      this.setState({
        kb1: false,
        kb2: false,
        kb3: true,
        kb4: false,
        kb5: false,
        kb6: false,
        kb7: false,
        selrua: "Kozhinjampara",
        rua: "Kozhinjampara",
        all: false,
      });
    } else if (krishibhavan[1] == "Eruthenpathy" && krishibhavan[0] == "KB") {
      this.setState({
        kb1: false,
        kb2: true,
        kb3: false,
        kb4: false,
        kb5: false,
        kb6: false,
        kb7: false,
        selrua: "Eruthenpathy",
        rua: "Eruthenpathy",
        all: false,
      });
    } else if (krishibhavan[1] == "Pattanchery" && krishibhavan[0] == "KB") {
      this.setState({
        kb1: false,
        kb2: false,
        kb3: false,
        kb4: false,
        kb5: true,
        kb6: false,
        kb7: false,
        selrua: "Pattanchery",
        rua: "Pattanchery",
        all: false,
      });
    } else if (krishibhavan[1] == "Perumatty" && krishibhavan[0] == "KB") {
      this.setState({
        kb1: false,
        kb2: false,
        kb3: false,
        kb4: false,
        kb5: false,
        kb6: true,
        kb7: false,
        selrua: "Perumatty",
        rua: "Perumatty",
        all: false,
      });
    } else if (krishibhavan[1] == "Vadakarapathy" && krishibhavan[0] == "KB") {
      this.setState({
        kb1: false,
        kb2: false,
        kb3: false,
        kb4: false,
        kb5: false,
        kb6: false,
        kb7: true,
        selrua: "Vadakarapathy",
        rua: "Vadakarapathy",
        all: false,
      });
    }
  }
  render() {
    const { t, i18n } = this.props;
    let data = {
      columns: [
        {
          label: "Alert ID",
          field: "alertID",
          width: 100,
        },
        {
          label: "RUA",
          field: "rua",
          width: 100,
        },
        {
          label: "Season",
          field: "season",
          width: 100,
        },
        {
          label: "Crop",
          field: "crop",
          width: 100,
        },        
        {
          label: "Phase",
          field: "phase",
          width: 100,
        },
        {
          label: "Date",
          field: "date",
          width: 100,
          sort:"disabled"
        },
        {
          label: "Time",
          field: "time",
          width: 100,
        },
        {
          label: "Alert Type",
          field: "alerttype",
          width: 100,
        },
        {
          label: "Peril",
          field: "peril",
          width: 100,
        },
        {
          label: "Alert Message",
          field: "alertmessage",
          width: 300
        },
        // {
        //   label: "Payout",
        //   field: "payout",
        // },
      ],
      rows: this.state.alertData,
    };
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Row>
              <Col xs={12}>
                <h4
                  className="card-title"
                  style={{ padding: "5px", fontSize: "18px" }}
                >
                  {" "}
                  {t("Alerts")}
                </h4>
              </Col>
            </Row>
            <Row>
              <Col>
                <Card style={{ backgroundColor: "#f1f6fb" }}>
                  <CardBody>
                    <Col>
                      <Row>
                        <Col md="2">
                          <Label className=" col-sm col-form-label">
                            {t("Season")}
                          </Label>
                          <select
                            className="form-select"
                            style={{ fontSize: "11px" }}
                            name="season"
                            value={this.state.season}
                            onChange={this.onchange}
                          >
                            <option value="all">All</option>
                            {this.state.seasonData.length > 0 &&
                              this.state.seasonData.map((item) => (
                                <option value={item} key={item}>
                                  {item}
                                </option>
                              ))}
                          </select>
                        </Col>
                        <Col md="2">
                          <FormGroup>
                            <Label className="col-sm col-form-label">
                              {t("Crop")}
                            </Label>
                            <select
                              className="form-select"
                              style={{ fontSize: "11px" }}
                              name="crop"
                              onChange={this.handleCropchange}
                            >
                              <option value="all">All</option>
                              {this.state.cropData.length > 0 &&
                                this.state.cropData.map((item) =>
                                  this.checkcrop(item)
                                )}
                            </select>
                          </FormGroup>
                        </Col>
                        <Col md="2">
                          <FormGroup>
                            <Label
                              className="col-md-10 col-form-label"
                              style={{ fontSize: "14px" }}
                            >
                              {t("Peril")}{" "}
                            </Label>
                            <select
                              className="form-select"
                              style={{ fontSize: "11px" }}
                              onChange={this.handlePerilchange}
                              name="peril"
                              value={this.state.peril}
                            >
                              <option value="all">All</option>
                              {this.state.perilData.length > 0 &&
                                this.state.perilData.map((item) => (
                                  <option value={item} key={item}>
                                    {item}
                                  </option>
                                ))}
                            </select>
                          </FormGroup>
                        </Col>

                        <Col md="2">
                          <FormGroup>
                            <Label className="col-sm col-form-label">RUA</Label>
                            <select
                              className="form-select"
                              style={{ fontSize: "11px" }}
                              name="rua"
                              onChange={this.onchange}
                              value={this.state.rua}
                            >
                              <option
                                value="Chittur"
                                style={
                                  this.state.kb1 ? {} : { display: "none" }
                                }
                              >
                                Chittur-Thathamangalam
                              </option>
                              <option
                                value="Eruthenpathy"
                                style={
                                  this.state.kb2 ? {} : { display: "none" }
                                }
                              >
                                Eruthenpathy
                              </option>
                              <option
                                value="Kozhinjampara"
                                style={
                                  this.state.kb3 ? {} : { display: "none" }
                                }
                              >
                                Kozhinjampara
                              </option>
                              <option
                                value="Nallepilly"
                                style={
                                  this.state.kb4 ? {} : { display: "none" }
                                }
                              >
                                Nallepilly
                              </option>
                              <option
                                value="Pattanchery"
                                style={
                                  this.state.kb5 ? {} : { display: "none" }
                                }
                              >
                                Pattanchery
                              </option>
                              <option
                                value="Perumatty"
                                style={
                                  this.state.kb6 ? {} : { display: "none" }
                                }
                              >
                                Perumatty
                              </option>
                              <option
                                value="Vadakarapathy"
                                style={
                                  this.state.kb7 ? {} : { display: "none" }
                                }
                              >
                                Vadakarapathy
                              </option>
                              <option
                                value="all"
                                style={
                                  this.state.all ? {} : { display: "none" }
                                }
                              >
                                All
                              </option>
                            </select>
                          </FormGroup>
                        </Col>

                        <Col md="4">
                          <Label className=" col-sm col-form-label">
                            {t("Date range")}
                          </Label>
                          <RangePicker
                            onChange={this.onChangedate}
                            disabledDate={this.disabledDate}
                            format="DD-MM-YYYY"
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md="2">
                          <FormGroup>
                            <Label className="col-sm col-form-label">
                              {t("Alert Type")}
                            </Label>
                            <select
                              className="form-select"
                              style={{ fontSize: "11px" }}
                              name="alert_type"
                              onChange={this.onchange}
                            >
                              <option value="A2">Day End</option>
                              <option value="A3">Payout Alert</option>
                              <option value="all">All</option>
                            </select>
                          </FormGroup>
                        </Col>

                        <Col md="10">
                          <FormGroup>
                            <Label
                              className="col-md-12 col-form-label"
                              style={{ fontSize: "14px" }}
                            >
                              <span>&nbsp;</span>
                            </Label>
                            <Button
                              color="primary"
                              className="waves-effect waves-light mr-1 btn-alert"
                              style={{
                                fontSize: "11px",
                                // float: "right",
                              }}
                              disabled={
                                this.state.searchButton == true ? false : true
                              }
                              onClick={this.getalert}
                            >
                              {t("Search")}
                            </Button>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <center>
                      <p
                        style={
                          this.state.showWaiting == true
                            ? {}
                            : { display: "none" }
                        }
                      >
                        {t("Waiting for search")}
                      </p>

                      <Spinner
                        style={
                          this.state.showloader == true
                            ? { display: "none" }
                            : {}
                        }
                      ></Spinner>
                    </center>
                    <div
                      style={this.state.showtable ? {} : { display: "none" }}
                      id="alert-table"
                    >
                      <MDBDataTable
                        paging={true}
                        showPaginationBottom={false}
                        bordered
                        scrollX
                        data={data}
                        order={["id", "asc"]}
                        className="datadownloadtable"
                        style={{ fontSize: "12px" }}
                        disableRetreatAfterSorting={true}
                        maxHeight="300px"
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default withNamespaces()(Alert);
