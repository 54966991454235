import React, { Component } from 'react'
import { Spinner, Container, Card, CardBody, UncontrolledTooltip, Row, Col, Nav, NavItem, NavLink, TabPane, TabContent, CardText } from "reactstrap";
import classnames from "classnames";
import { MDBDataTable } from "mdbreact";
import { Link } from "react-router-dom";
import validate_Token from "../../validateToken.js";
import { withNamespaces } from 'react-i18next';
import Moment from 'moment';
class Current_comparison extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeTab: "1",
      rua_humidity: [],
      rua_temperature: [],
      rua_precipitation: [],
      rua_windspeed: [],
      rua_winddirection: [],
      rua_uvi: [],
      rua_pressure: [],
      group_humidity: [],
      group_temperature: [],
      group_precipitation: [],
      group_windspeed: [],
      group_winddirection: [],
      group_uvi: [],
      group_pressure: [],
      showloader: true,
      updatetime: '',
      data_group: [],
      data_rua: [],


    }
    this.toggle = this.toggle.bind(this);
    this.setparameter = this.setparameter.bind(this);
  }
  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }
async getcomparisonData(){
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Token ' + localStorage.getItem('token')

    },

  };
  var comparison_data = await fetch(process.env.REACT_APP_APIEND + `weather/hd-rua-current-comparison`, requestOptions).then(res => {
    return res.json();
  });
  var parseddata = comparison_data.result
  this.generatetabledata(parseddata);
  // let ruadata=comparison_data.result[0].ruawise;
  // let groupwise=comparison_data.result[0].groupwise;

  this.setState({
    showloader: false,
    updatetime: comparison_data.result[2].datetime

  })
}
componentDidMount() {
    validate_Token();
this.getcomparisonData()
  }

  setparameter(params) {
    if (params == "Humidity") {
      return (<p style={{textAlign:"left"}}>Humidity [%]</p>)
    }
    if (params == "Pressure") {
      return (<p style={{textAlign:"left"}}>Pressure [hPa]</p>)
    }
    if (params == "Rainfall") {
      return (<p style={{textAlign:"left"}}>Rainfall [mm]</p>)
    }
    if (params == "Temperature") {
      return (<p style={{textAlign:"left"}}>Temperature [°C]</p>)
    }
    if (params == "UV_Index") {
      return (<p style={{textAlign:"left"}}>UV Index </p>)
    }
    if (params == "Wind_Direction") {
      return (<p style={{textAlign:"left"}}>Wind Direction [°]</p>)
    }
    if (params == "Wind_Speed") {
      return (<p style={{textAlign:"left"}}>Wind Speed [m/s]</p>)
    } else {
      return (params)
    }
  }

  generatetabledata(data) {
    let rua_comparison = []
    let group_comparison = []
    let getthis = this;
    let ordered_ruawise = [data[0].ruawise[3], data[0].ruawise[2], data[0].ruawise[6], data[0].ruawise[5], data[0].ruawise[4], data[0].ruawise[0], data[0].ruawise[1]]
    ordered_ruawise.map(function (item, index, data) {
      var newdata = {
        parameter: getthis.setparameter(item.parameter),
        chittur: parseFloat(item.Chittur).toFixed(2),
        eruthenpathy: parseFloat(item.Eruthenpathy).toFixed(2),
        nallepilly: parseFloat(item.Nallepilly).toFixed(2),
        kozhinjampara: parseFloat(item.Kozhinjampara).toFixed(2),
        perumatty: parseFloat(item.Perumatty).toFixed(2),
        pattanchery: parseFloat(item.Pattanchery).toFixed(2),
        vadakarapathy: parseFloat(item.Vadakarapathy).toFixed(2),
      }
      rua_comparison.push(newdata);

    });
    let ordered_groupwise = [data[1].groupwise[3], data[1].groupwise[2], data[1].groupwise[6], data[1].groupwise[5], data[1].groupwise[4], data[1].groupwise[0], data[1].groupwise[1]]
    ordered_groupwise.map(function (item, index, data) {
      var groupdata = {
        parameter: getthis.setparameter(item.parameter),
        group1: parseFloat(item.group1).toFixed(2),
        group2: parseFloat(item.group2).toFixed(2)
      }
      group_comparison.push(groupdata);

    });

    this.setState({
      data_rua: rua_comparison,
      data_group: group_comparison

    })

  }

  render() {
    const { t, i18n } = this.props
    let data_rua = {
      columns: [
        {
          label: "Parameter",
          field: "parameter",
          sort: "asc",

        },
        {
          label: "Chittur",
          field: "chittur",
          sort: "asc",

        },

        {
          label: "Eruthenpathy",
          field: "eruthenpathy",
          sort: "asc",

        },
        {
          label: "Nallepilly",
          field: "nallepilly",
          sort: "asc",

        },
        {
          label: "Kozhinjampara",
          field: "kozhinjampara",
          sort: "asc",

        },
        {
          label: "Perumatty",
          field: "perumatty",
          sort: "asc",

        },
        {
          label: "Pattanchery",
          field: "pattanchery",
          sort: "asc",

        },
        {
          label: "Vadakarapathy",
          field: "vadakarapathy",
          sort: "asc",

        }
      ],
      rows: this.state.data_rua
    };

    let data_group = {
      columns: [
        {
          label: <center className="alnleft">Parameter</center>,
          field: "parameter",
          sort: "asc",


        },
        {
          label: <span> Group 1 <Link className="mr-3 text-primary" id={"group1"}><i className="ri-information-line" style={{ display: "inline-block", float: "right" }}></i></Link>
            <UncontrolledTooltip target={"group1"} placement="top">
              Vadakarapathy,{" "}Eruthenpathy,{" "}Kozhinjampara
            </UncontrolledTooltip></span>,
          field: "group1",
          sort: "asc",

        },

        {
          label: <span> Group 2
            <Link className="mr-3 text-primary" id={"group2"}><i className="ri-information-line" style={{ display: "inline-block", float: "right" }}></i></Link>
            <UncontrolledTooltip target={"group2"} placement="top">
              Nallepilly,{" "}Perumatty{" "},{" "}Pattanchery,{" "}Chittur-Thathamangalam
            </UncontrolledTooltip>
          </span>,
          field: "group2",
          sort: "asc",

        }
      ],
      rows: this.state.data_group
    };
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Row>
              <Col xs={6}>
                <h4 className="card-title" style={{ padding: '5px', fontSize: '18px' }}>
                {t("Current Comparison")}  </h4>

              </Col>
              <Col xs={6}>
                <p style={{ padding: '5px', float: 'right' }}> Last Updated : {Moment(this.state.updatetime).format('DD-MM-YYYY HH:mm')} </p>
              </Col>
              <Col lg={12}>
                <Card >
                  <CardBody>

                    <Nav tabs>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: this.state.activeTab === "1"
                          })}
                          onClick={() => {
                            this.toggle("1");
                          }}
                        >
                          RUA
                        </NavLink>
                      </NavItem>
                      <NavItem  >
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: this.state.activeTab === "2"
                          })}
                          onClick={() => {
                            this.toggle("2");
                          }}
                        >
                          Group
                        </NavLink>
                      </NavItem>
                    </Nav>


                    <TabContent activeTab={this.state.activeTab}>
                      <TabPane tabId="1" className="p-3">
                        <Row>
                          <Col sm="12">
                            <CardText>
                              <div style={this.state.showloader ? {} : { display: 'none' }}>
                                <center>
                                  <Spinner color="dark" />
                                </center>
                              </div>
                              <div class="table-responsive" id="rua" style={this.state.showloader ? { display: 'none' } : {}}>
                                <MDBDataTable paging={false} showPaginationBottom={false} bordered data={data_rua} sortable={false} searching={false} className='datadownloadtable alnright' style={{fontSize:"12px"}} />
                              </div>
                            </CardText>
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId="2" className="p-3">
                        <div class="table-responsive" id="group" style={{fontSize:"12px"}}>
                          <MDBDataTable paging={false} showPaginationBottom={false} bordered data={data_group} sortable={false} searching={false} className='datadownloadtable alnright' />
                        </div>
                      </TabPane>
                    </TabContent>

                  </CardBody>
                </Card>
              </Col>

            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}
export default withNamespaces()(Current_comparison);
