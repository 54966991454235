import React from "react";
export default function Mmessages(props) {
  var template = {
    "Day End": {
      Paddy: [
        {
          "Dry Spell":
            "മൊത്തം മഴ " + props.calculated_value + " ആണ്.(ട്രിഗർ : >13 മിമി)",
          "Excess Rainfall":
            "ദിവസേനയുള്ള സഞ്ചിത മഴ" +
            props.calculated_value +
            "mm (ട്രിഗർ : സഞ്ചിത മഴ > 150)",
          "High Temperature":
          "പ്രതിദിന പരമാവധി താപനില " +
          props.calculated_value2 +
          "(ട്രിഗർ : പരമാവധി താപനില 31 °C)",
          "Disease Congenial Climate":
            "പ്രതിദിന താപനില " +
            props.calculated_value2 +
            " °C ആണ്, പ്രതിദിന ശരാശരി ഈർപ്പം " +
            props.calculated_value +
            " % (ട്രിഗർ : കുറഞ്ഞ  താപനില > 32 °C; ഈർപ്പം 80%നും 90%നും ഇടയിൽ)",
        },
      ],
      "Veg Group I": [
        {
          "Dry Spell":
            "മൊത്തം മഴ " + props.calculated_value + " ആണ്.(ട്രിഗർ : <2.5 മിമി)",
          "Excess Rainfall":
            "ദിവസേനയുള്ള സഞ്ചിത മഴ" +
            props.calculated_value +
            "mm (ട്രിഗർ : സഞ്ചിത മഴ > 30)",
          "Disease Congenial Climate":
            "പ്രതിദിന പരമാവധി താപനില " +
            props.calculated_value2 +
            " °C ആണ്, പ്രതിദിന ശരാശരി ഈർപ്പം " +
            props.calculated_value +
            " % (ട്രിഗർ : പരമാവധി താപനില > 32°C; ഈർപ്പം 80%നും 90%നും ഇടയിൽ)",
        },
      ],
      Mango: [
        {
          "Disease Congenial Climate":
            "പ്രതിദിന കുറഞ്ഞ താപനില " +
            props.calculated_value2 +
            " °C ആണ്, പ്രതിദിന ശരാശരി ഈർപ്പം " +
            props.calculated_value +
            " % (ട്രിഗർ : കുറഞ്ഞ  താപനില < 24 °C; ഈർപ്പം > 80%)",
          "Excess Rainfall":
            "കണക്കാക്കിയ മൂല്യം " +
            props.calculated_value +
            "mm (ട്രിഗർ : സഞ്ചിത മഴ > 30)",
        },
      ],
      "Paddy 3rd Crop": [
        {
          "Disease Congenial Climate":
            "പ്രതിദിന പരമാവധി താപനില " +
            props.calculated_value2 +
            " °C ആണ്, പ്രതിദിന ശരാശരി ഈർപ്പം " +
            props.calculated_value +
            " % (ട്രിഗർ : പരമാവധി താപനില 28 ഡിഗ്രി സെൽഷ്യസിനും 32 ഡിഗ്രി സെൽഷ്യസിനും ഇടയിലാണ്; ശരാശരി ഈർപ്പം > 80%)",
        },
      ],
      "Paddy 2nd Crop": [
        {
          "Excess Rainfall":
            "ദിവസേനയുള്ള സഞ്ചിത മഴ" +
            props.calculated_value +
            "mm (ട്രിഗർ : സഞ്ചിത മഴ > 30)",
          "Unseasonal Rainfall":
            "ദിവസേനയുള്ള സഞ്ചിത മഴ " +
            props.calculated_value +
            "mm (ട്രിഗർ : സഞ്ചിത മഴ > 30)",
          "Disease Congenial Climate":
            "പ്രതിദിന കുറഞ്ഞ താപനില " +
            props.calculated_value2 +
            " °C ആണ്, പ്രതിദിന ശരാശരി ഈർപ്പം " +
            props.calculated_value +
            " % (ട്രിഗർ : ട്രിഗർ : പരമാവധി താപനില > 31°C; ഈർപ്പം 70%നും 80%നും ഇടയിൽ)",
        },
      ],
      "Veg Group I - SMR": [
        {
          "Disease Congenial Climate":
            "പ്രതിദിന പരമാവധി താപനില " +
            props.calculated_value2 +
            " °C ആണ്, പ്രതിദിന ശരാശരി ഈർപ്പം " +
            props.calculated_value +
            " % (ട്രിഗർ : പരമാവധി താപനില 28 ഡിഗ്രി സെൽഷ്യസിനും 33 ഡിഗ്രി സെൽഷ്യസിനും ഇടയിലാണ്; ശരാശരി ഈർപ്പം > 80%)",
          "Excess Rainfall":
            "കണക്കാക്കിയ മൂല്യം " +
            props.calculated_value +
            "mm (ട്രിഗർ : സഞ്ചിത മഴ > 30)",
        },
      ],
    },
  };
  return <p>{template["Day End"][props.crop][0][props.peril]}</p>;
}
