import React, { Component } from 'react'
import { Table } from 'reactstrap'

// const Termsheettable =(props)=> {
class Deficittable extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
dprovider:'',
speril:0,
        };
        
      }
      componentDidMount() {
        this.setState({speril:this.props.tperil})
        this.setState({
            speril:false
        })
    }
 
    render(){
        // console.log("TABLE PROPS",this.props.tperil)
        
 
        return (
          <React.Fragment>
               <select className="form-control" style={{ fontSize: '11px' }} value={this.state.dprovider} >
                                                                <option value="aic ">AIC
                    </option>
                                                                <option value="darksky">Dark Sky</option>
                                                                <option value="ow">Open Weather</option>
                                                                <option value="onlinesen">Online Sensor</option>                                                              
                                                            </select>	

              <Table responsive scrollY class="table table-bordered" id="tbldryspellpaddy" style={{marginTop:'2%',fontSize:'12px'}}>

                    <tbody>
                        <tr>
                            <th scope="row" style={{backgroundColor:'#c3d7e2'}}>PERIOD</th>
                            <td colspan={4}>01-Jun-2020 to 15-Oct-2020</td>

                        </tr>
                        <tr>
                            <th scope="row" style={{backgroundColor:'#c3d7e2'}}>INDEX</th>
                            <td colspan={4}>Number of days in the longest spell of consecutive dry days (dry day is less than 2.5
                                    mm)</td>

                        </tr>
                        <tr>
                            <th scope="row" style={{backgroundColor:'#c3d7e2'}}>STRIKE (cons. days) greater than </th>
                            <td>13</td>
                            <td>15</td>
                            <td>18</td>
                            <td>20</td>
                        </tr>
                        <tr>
                            <th scope="row" style={{backgroundColor:'#c3d7e2'}}>CONSECUTIVE DAYS</th>
                            <td colspan={4} id="consedays">12</td>

                        </tr>
                        <tr>
                            <th scope="row" style={{backgroundColor:'#c3d7e2'}}>PAYOUT RATE (Rs.)</th>
                            <td>3000</td>
                            <td>5000</td>
                            <td>8000</td>
                            <td>13000</td>
                        </tr>
                        <tr>
                            <th scope="row" style={{backgroundColor:'#c3d7e2'}}>PAYOUT </th>
                            <td colspan={4} id="dryspellpay">0</td>

                        </tr>
                        <tr>
                            <th scope="row" style={{backgroundColor:'#c3d7e2'}}>TOTAL MAXIMUM PAYOUT (Rs.)</th>
                            <td colspan={4} id="dryspelltotmaxpay">13000.00</td>

                        </tr>
                        <tr>
                            <th scope="row" style={{backgroundColor:'#c3d7e2'}}>ELIGIBLE PAYOUT (Rs./ha)</th>
                            <td colspan={4} id="dryspellelipay">0</td>
                        </tr>
                        <tr>
                            <th scope="row" style={{backgroundColor:'#c3d7e2'}}>TOTAL ELIGIBLE PAYOUT</th>
                            <td id="epdryspell" colspan={4}>0.00
                                </td>
                        </tr>

                    </tbody>
                </Table>
          </React.Fragment>
        )
    }
    }
export default Deficittable;