import React, { Component } from "react";
import { Card, CardBody, Row, Col, Media } from "reactstrap";
import MapCustomControl from "./React-control/control.tsx";
import "../Dashboard/leaflet.css";
import ReactApexChart from "react-apexcharts";
import getRuaObject from "../../rua_object";
import {
  MapContainer,
  TileLayer,
  GeoJSON,
  Popup,
  CircleMarker,
  SVGOverlay,
} from "react-leaflet";

const bounds = [
  [10.6280565267840235, 76.6888275027770305],
  [10.817207336425831, 76.9081420898434516],
];
const rua = getRuaObject();
class GWDiagnosticMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      labels: ["Active", "Inactive"],
      colors: ["#1d7d1e", "#fa3c3c"],
      series: [4, 0],
      options: {
        dataLabels: {
          enabled: false,
        },
        plotOptions: {
          pie: {
            labels: {
              show: true,
            },
            donut: {
              size: "50%",
            },
          },
        },
        labels: ["Active", "Inactive"],
        colors: ["#1d7d1e", "#fa3c3c"],
        legend: {
          show: true,
          position: "right",
          horizontalAlign: "center",
          verticalAlign: "middle",
          floating: false,
          fontSize: "8px",
          offsetX: 0,
          offsetY: -10,
        },
        responsive: [
          {
            breakpoint: 600,
            options: {
              chart: {
                height: 240,
              },
              legend: {
                show: false,
              },
            },
          },
        ],
      },
    };
    this.onMapReset = this.onMapReset.bind(this);
  }

  onEachrua = (rua, layer) => {
    const ruaname = rua.properties.LOCATION;
    layer.bindPopup(ruaname);
  };

  onMapReset() {
    console.log("reset map");
    const { map } = this.state;
    if (map) map.flyTo([10.72, 76.81], 11);
  }
  getactivestatus() {
    let count = 0;
    // console.log("COUNT ACTIVE", this.props);
    this.props.livedata.map(function (points) {
      if (points["status "] == true) {
        count = count + 1;
      }
    });

    this.setState({
      series: [count, 4 - count],
    });
  }

  onEachws = (points, layer) => {
    layer.on({
      mouseover: (event) => {
        const ws = event.target.feature.properties.Location;

        layer.bindPopup(ws);
      },
    });
  };

  componentDidMount() {
    this.getactivestatus();
  }
  //   async componentDidMount() {
  //     //Get Total no of active sensors
  //     this.getactivestatus();
  //     //fetching data for other data providers
  //     let searchData = {
  //       station: "all",
  //     };

  //     searchData = JSON.stringify(searchData);
  //     const requestOptions = {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: "Token " + localStorage.getItem("token"),
  //       },
  //       body: searchData,
  //     };
  //     var weatherResponse = await fetch(
  //       "https://smc.misteo.co/weather/current-api-weather",
  //       requestOptions
  //     ).then((res) => {
  //       return res.json();
  //     });
  //     if (weatherResponse.hasOwnProperty("code")) {
  //       if (weatherResponse.code == 200) {
  //         this.setState({
  //           otherProviderdata: weatherResponse.data,
  //         });
  //       }
  //     }
  //   }
  checkcolor(GW) {
    // console.log("LIVEDATA", this.props.livedata[0]);
    if (GW == "GW1") {
      if (this.props.livedata[0]["status "] == true) {
        return "#8cc78f";
      } else {
        return "#d12836";
      }
    }
    if (GW == "GW2") {
      if (this.props.livedata[1]["status "] == true) {
        return "#8cc78f";
      } else {
        return "#d12836";
      }
    }
    if (GW == "GW3") {
      if (this.props.livedata[2]["status "] == true) {
        return "#8cc78f";
      } else {
        return "#d12836";
      }
    }
    if (GW == "GW4") {
      if (this.props.livedata[3]["status "] == true) {
        return "#8cc78f";
      } else {
        return "#d12836";
      }
    }
  }
  render() {
    const ruaStyle = {
      color: "red",
      weight: 1.3,
      fillOpacity: 0,
    };

    return (
      <React.Fragment>
        <Row>
          <Col md={4}>
            <Card>
              <CardBody style={{ height: "161px" }}>
                <Media>
                  <Media body className="overflow-hidden">
                    <p className="text-truncate font-size-14 mb-2">
                      Number of Sensors
                    </p>
                    <center>
                      <h2 className="mb-0" style={{ padding: "10%" }}>
                        14
                      </h2>
                    </center>
                  </Media>
                  <div className="text-primary">
                    <i className={"ri-cpu-line font-size-24"}></i>
                  </div>
                </Media>
              </CardBody>
            </Card>
            <Card></Card>
          </Col>
          <Col md={4}>
            <Card>
              <CardBody style={{ height: "161px" }}>
                <Media>
                  <Media body className="overflow-hidden">
                    <p className="text-truncate font-size-14 mb-2">
                      Number of Gateways
                    </p>
                    <center>
                      <h2 className="mb-0" style={{ padding: "10%" }}>
                        4
                      </h2>
                    </center>
                  </Media>
                  <div className="text-primary">
                    <i className={" ri-router-line font-size-24"}></i>
                  </div>
                </Media>
              </CardBody>
            </Card>
            <Card></Card>
          </Col>
          <Col md={4}>
            <Card>
              <CardBody style={{ minHeight: "161px" }}>
                <Media>
                  <Media body className="overflow-hidden">
                    <p className="text-truncate font-size-14 mb-2">
                      Gateway Status
                    </p>
                    <ReactApexChart
                      options={this.state.options}
                      series={this.state.series}
                      type="donut"
                      height="90px"
                    />
                  </Media>
                  <div className="text-primary">
                    <i className={" ri-pie-chart-2-line font-size-24"}></i>
                  </div>
                </Media>
              </CardBody>
            </Card>
          </Col>
        </Row>
        {/* <Card>
          <CardBody>
            <Row>
              <Col md={2} style={{ marginTop: "4%" }}>
                <div className="badge-desktop">
                  <center>
                    <div className="legendup"></div>
                    <p style={{ display: "inline-block" }}>Active</p>
                  </center>
                </div>
              </Col>
              <Col md={2} style={{ marginTop: "4%" }}>
                <div className="badge-desktop">
                  <center>
                    <div className="legenddown"></div>
                    <p style={{ display: "inline-block" }}>Inactive</p>
                  </center>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card> */}
        <MapContainer
          center={[10.72, 76.81]}
          whenCreated={(map) => this.setState({ map })}
          zoom={11}
          scrollWheelZoom={false}
          style={{
            margin: "0px 0px 0px 0px",
            minHeight: "500px",
            position: "relative",
          }}
        >
          <GeoJSON
            style={ruaStyle}
            data={rua.features}
            onEachFeature={this.onEachrua}
            icon={"text"}
          />
          <CircleMarker
            center={[10.69300691, 76.72595507]}
            radius={7}
            fillOpacity={1}
            fillColor={this.checkcolor("GW1")}
            color="#000000"
          >
            <Popup>GW-1 | Chittur</Popup>
          </CircleMarker>
          <CircleMarker
            center={[10.73595019, 76.82999565]}
            radius={7}
            fillOpacity={1}
            fillColor={this.checkcolor("GW2")}
            color="#000000"
          >
            <Popup>GW-2 | Bharathamatha</Popup>
          </CircleMarker>
          <CircleMarker
            center={[10.7951, 76.8363]}
            radius={7}
            fillOpacity={1}
            fillColor={this.checkcolor("GW3")}
            color="#000000"
          >
            <Popup>GW-3 | Ahalia</Popup>
          </CircleMarker>
          <CircleMarker
            center={[10.67, 76.83]}
            radius={7}
            fillOpacity={1}
            fillColor={this.checkcolor("GW4")}
            color="#000000"
          >
            <Popup>GW-4 | KWA Water Treatment Plant</Popup>
          </CircleMarker>
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />{" "}
          ;
          <SVGOverlay bounds={bounds}>
            <text x="10%" y="70%" fill="#02b514">
              Chittur
            </text>
            <text x="20%" y="95%" fill="#02b514">
              Pattanchery
            </text>
            <text x="30%" y="80%" fill="#02b514">
              Perumatty
            </text>
            <text x="40%" y="60%" fill="#02b514">
              Nallepilly
            </text>
            <text x="60%" y="50%" fill="#02b514">
              Kozhinjampara
            </text>
            <text x="75%" y="42%" fill="#02b514">
              Eruthenpathy
            </text>
            <text x="70%" y="20%" fill="#02b514">
              Vadakarapathy
            </text>
          </SVGOverlay>
          <MapCustomControl>
            <Row>
              <Col xl={3} lg={4} sm={6}>
                <i
                  className="fas fa-home"
                  onClick={this.onMapReset}
                  style={{ fontSize: "18px", cursor: "pointer",paddingTop:"2px",paddingLeft:"15px" }}
                ></i>
              </Col>
            </Row>
            <Row style={{ paddingTop: "10px" }}>
              <Col xl={12}>
                <div className="badge-desktop">
                  <center>
                    <div className="legendup"></div>
                    <p
                      style={{
                        display: "inline-block",
                        fontSize: "14px",
                        fontWeight: "bolder",
                      }}
                    >
                      Active
                    </p>
                  </center>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xl={12}>
                <div className="badge-desktop">
                  <center>
                    <div className="legenddown"></div>
                    <p
                      style={{
                        display: "inline-block",
                        fontSize: "14px",
                        fontWeight: "bolder",
                      }}
                    >
                      Inactive
                    </p>
                  </center>
                </div>
              </Col>
            </Row>
          </MapCustomControl>          
        </MapContainer>
      </React.Fragment>
    );
  }
}

export default GWDiagnosticMap;
