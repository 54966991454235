import React from "react";

import { MDBDataTable } from "mdbreact";
const DiagnosticTable = (props) => {
  // console.log("DIAG TABLE", props.livedata);
  let data = {
    columns: [
      {
        label: "Sl. No.",
        field: "id",
        sort: "asc",
        width: 100
      },
      {
        label: "Device Name",
        field: "station",
        sort: "asc",
        width : 150
      },

      {
        label: "Location",
        field: "location",
        sort: "asc",
        width: 250
      },
      {
        label: "RUA",
        field: "rua",
        sort: "asc",
        width: 150
      },
      {
        label: "Last Compensated",
        field: "datetime",
        sort: "asc",
        width: 150
      },
      {
        label: "Battery",
        field: "Battery",
        sort: "asc",
        sort:"disabled"
      },
    ],
    rows: props.livedata,
  };
  const selectRow = {
    mode: "checkbox",
    clickToSelect: true,
  };

  const CustomToggleList = ({ columns, onColumnToggle, toggles }) => (
    <div className="btn-group btn-group-toggle" data-toggle="buttons">
      {columns
        .map((column) => ({
          ...column,
          toggle: toggles[column.dataField],
        }))
        .map((column) => (
          <button
            type="button"
            key={column.dataField}
            className={`btn btn-secondary ${column.toggle ? "active" : ""}`}
            data-toggle="button"
            aria-pressed={column.toggle ? "true" : "false"}
            onClick={() => onColumnToggle(column.dataField)}
          >
            {column.text}
          </button>
        ))}
    </div>
  );
  return (
    <div id="sensordiagnostic-compensated">
      <MDBDataTable
        paging={false}
        showPaginationBottom={false}
        searching={false}
        bordered
        data={data}
        scrollX
        order={["id", "asc"]}
        className="datadownloadtable"
        style={{ fontSize: "12px" }}
        maxHeight="300px"
      />
    </div>
  );
};

export default DiagnosticTable;
