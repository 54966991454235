export default function geteruthenpathystations(){
    const points ={
        "type": "FeatureCollection",
        "name": "eruthenpathy_stations",
        "crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:OGC:1.3:CRS84" } },
        "features": [
          {
            type: "Feature",
            properties: {
              RUA: "Eruthempathy",
              Type: "KrishiBhavan",
              stationName: "kdisc_sci_aws_10",
              tooltipText: "Seed Godown, Kerala State Seed Development Authority (KSSDA)",
              Location:
                "Seed Godown, Kerala State Seed Development Authority (KSSDA)",
              "Google Map URL": "https://goo.gl/maps/UY8SGeNqZGJTwLGT8",
              Lat: 10.74866619,
              Long: 76.85879364,
            },
            geometry: { type: "Point", coordinates: [76.85879364, 10.74866619] },
          },
          {
            type: "Feature",
            properties: {
              RUA: "Eruthempathy",
              Type: "Extra",
              stationName: "kdisc_sci_aws_04",
              tooltipText:"Bhagavathy Govt. High School",
              Location: "Bhagavathy Govt. High School",
              "Google Map URL": "https://goo.gl/maps/oUF4hPYD9wJM3DUC8",
              Lat: 10.70246231,
              Long: 76.85248817,
            },
            geometry: { type: "Point", coordinates: [76.85248817, 10.70246231] },
          },
        ]
        }
        
         
      return points
}