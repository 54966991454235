import React, { Component } from "react";
import Permission from "../../permission.js";
// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

//i18n
import { withNamespaces } from "react-i18next";

import { connect } from "react-redux";
import {
  changeLayout,
  changeLayoutWidth,
  changeSidebarTheme,
  changeSidebarType,
  changePreloader,
} from "../../store/actions";

class SidebarContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user_DR: false,
      user_KB: false,
      user_FM: false,
      isusermgmtvisible: true,
      isfarmerupload: false,
      isfarmersearch: false,
      issendiagnostic: false,
      iscomparison: true,
      istermsheetupload: false,
      farmersubsidy: true,
      issettings: true,
      isactualdiagnostic: false,
      conditions: true,
      weatherValidation: true,
      premiumStatus: true,
      payoutFarmer: true,
      isMobile: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent),
      isMenuVisible: false,
    };
    this.toggleMenuCall = this.toggleMenuCall.bind(this);
  }
  toggleMenuCall = () => {
    if (this.props.leftSideBarType === "default") {
      this.props.changeSidebarType("sider", this.state.isMobile);
    } else if (this.props.leftSideBarType === "sider") {
      this.props.changeSidebarType("default", this.state.isMobile);
    }
  };

  componentDidMount() {
    setTimeout(() => {
    this.setState({ isMenuVisible: true });
    this.initMenu();
    const user_role = Permission();
    // alert(user_role[1])
    if (user_role[0] == "DR") {
      this.setState({
        user_DR: true,
        istermsheetupload: false,
        issettings: false,
        isactualdiagnostic: true,
        isusermgmtvisible: true,
        weatherValidation: false,
        farmersubsidy: true,
        premiumStatus: false,
        payoutFarmer: true,
      });
    } else if (user_role[0] == "KB") {
      this.setState({
        user_KB: true,
        isfarmerupload: true,
        issendiagnostic: true,
        istermsheetupload: true,
        issettings: false,
        isactualdiagnostic: true,
        isusermgmtvisible: false,
        conditions: false,
        weatherValidation: false,
        farmersubsidy: false,
        payoutFarmer: true,
        premiumStatus: true,
      });
    } else if (user_role[0] == "FM") {
      this.setState({
        user_FM: true,
        isfarmerupload: true,
        isfarmersearch: true,
        issendiagnostic: true,
        istermsheetupload: true,
        issettings: false,
        weatherValidation: false,
        isusermgmtvisible: false,
        isactualdiagnostic: true,
        farmersubsidy: false,
        conditions: false,
        premiumStatus: false,
        payoutFarmer: false,
      });
    }

    if (user_role[0] == "KB" || user_role[0] == "FM") {
      this.setState({
        iscomparison: false,
        issettings: false,
        isactualdiagnostic: true,
      });
    }
  }, 500);
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      if (this.props.type !== prevProps.type) {
        this.initMenu();
      }
    }
  }

  initMenu() {
    new MetisMenu("#side-menu");

    var matchingMenuItem = null;
    var ul = document.getElementById("side-menu");
    var items = ul.getElementsByTagName("a");
    for (var i = 0; i < items.length; ++i) {
      if (this.props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem);
    }
  }

  activateParentDropdown = (item) => {
    item.classList.add("active");
    const parent = item.parentElement;

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show");

        const parent3 = parent2.parentElement;

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement;
          if (parent4) {
            parent4.classList.add("mm-active");
          }
        }
      }
      return false;
    }
    return false;
  };

  render() {
    return (
      <React.Fragment>
        {this.state.isMenuVisible && (
        <div id="sidebar-menu" className="sider-desktop">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{this.props.t("Menu")}</li>
            <li style={this.state.user_FM ? {} : { display: "none" }}>
              <Link
                to="farmprofile"
                className=" waves-effect"
                onClick={this.toggleMenuCall}
              >
                <i className=" ri-user-line"></i>
                <span className="ml-1">{this.props.t("Profile")}</span>
              </Link>
            </li>
            <li style={this.state.user_FM ? {} : { display: "none" }}>
              <Link to="/#" className="has-arrow waves-effect">
                <i className=" ri-dashboard-line"></i>
                <span className="ml-1">{this.props.t("Weather")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/farmerdashboard" onClick={this.toggleMenuCall}>
                    {this.props.t("Current")}
                  </Link>
                </li>
                <li>
                  <Link to="/fm_forecast" onClick={this.toggleMenuCall}>
                    {this.props.t("Forecast")}
                  </Link>
                </li>
              </ul>
            </li>
            <li style={this.state.user_FM ? { display: "none" } : {}}>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="ri-dashboard-line"></i>
                <span className="ml-1">{this.props.t("Weather")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/dashboard" onClick={this.toggleMenuCall}>
                    {this.props.t("Current")}
                  </Link>
                </li>
                <li style={this.state.iscomparison ? {} : { display: "none" }}>
                  <Link to="/comparisoncurrent" onClick={this.toggleMenuCall}>
                    {this.props.t("Current Comparison")}
                  </Link>
                </li>
                <li style={this.state.iscomparison ? {} : { display: "none" }}>
                  <Link to="/comparisontrend" onClick={this.toggleMenuCall}>
                    {this.props.t("Hist. Data Comparison")}
                  </Link>
                </li>
                <li
                  style={
                    this.state.isactualdiagnostic ? { display: "none" } : {}
                  }
                >
                  <Link to="/diagnostic" onClick={this.toggleMenuCall}>
                    {this.props.t("Sensor Diagnostics")}
                  </Link>
                </li>
                <li
                  style={this.state.issendiagnostic ? { display: "none" } : {}}
                >
                  <Link to="/admdiagnostic" onClick={this.toggleMenuCall}>
                    {this.props.t("Sensor Diagnostics - Compensated")}
                  </Link>
                </li>
                <li
                  style={this.state.issendiagnostic ? { display: "none" } : {}}
                >
                  <Link to="/gatewaydiag" onClick={this.toggleMenuCall}>
                    {this.props.t("Gateway Diagnostics")}
                  </Link>
                </li>
                <li>
                  <Link to="/weatherforecast" onClick={this.toggleMenuCall}>
                    {this.props.t("Forecast")}
                  </Link>
                </li>
              </ul>
            </li>

            <li style={this.state.isusermgmtvisible ? {} : { display: "none" }}>
              <Link
                to="user-management"
                className=" waves-effect"
                onClick={this.toggleMenuCall}
              >
                {/* <i className=" ri-user-line"> </i>
                <span className="ml-1 translation-align">{this.props.t("User Management")}</span> */}
                <div class="row">
                  <div class="col-md-1 col-1">
                    {" "}
                    <i className=" ri-user-line"> </i>
                  </div>
                  <div class="col-md-10 col-10">
                    <span className="ml-1">
                      {this.props.t("User Management")}
                    </span>
                  </div>
                </div>
              </Link>
            </li>

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="ri-calendar-2-line"></i>
                <span className="ml-1">{this.props.t("Termsheet")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li style={this.state.conditions ? {} : { display: "none" }}>
                  <Link to="tm_conditions" onClick={this.toggleMenuCall}>
                    {this.props.t("Termsheet Conditions")}
                  </Link>
                </li>
                <li style={this.state.user_FM ? { display: "none" } : {}}>
                  <Link to="termsheet" onClick={this.toggleMenuCall}>
                    {this.props.t("Termsheet Peril Wise")}
                  </Link>
                </li>
                <li style={this.state.user_FM ? {} : { display: "none" }}>
                  <Link to="/fmtermsheet" onClick={this.toggleMenuCall}>
                    {this.props.t("Termsheet Peril Wise")}
                  </Link>
                </li>
                {/* <li style={this.state.user_FM ? {} : { display: "none" }}>
                  <Link to="/tsimplication" onClick={this.toggleMenuCall}>
                    {this.props.t("Termsheet Implications")}
                  </Link>
                </li> */}

                <li style={this.state.user_FM ? { display: "none" } : {}}>
                  <Link to="tssummarized" onClick={this.toggleMenuCall}>
                    {this.props.t("Termsheet Implications")}
                  </Link>
                </li>
                <li style={this.state.user_FM ? {display: "none"} : {  }}>
                  <Link to="tm_pdfviewer" onClick={this.toggleMenuCall}>
                    {this.props.t("Termsheet Reference")}
                  </Link>
                </li>
                <li style={this.state.user_FM ? {} : { display: "none" }}>
                  <Link to="fm_reference" onClick={this.toggleMenuCall}>
                    {this.props.t("Termsheet Reference")}
                  </Link>
                </li>
              </ul>
            </li>
            <li style={this.state.user_FM ? { display: "none" } : {}}>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="ri-arrow-left-right-line"> </i>
                <span className="ml-1">{this.props.t("Smart Contract")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link
                    to="smc-transactions"
                    className=" waves-effect"
                    onClick={this.toggleMenuCall}
                  >
                    <span className="ml-1">{this.props.t("Payout - RUA")}</span>
                  </Link>
                </li>
                <li style={this.state.farmersubsidy ? {} : { display: "none" }}>
                  <Link
                    to="farmer-Transaction-status"
                    className=" waves-effect"
                    onClick={this.toggleMenuCall}
                  >
                    <span className="ml-1">
                      {this.props.t("Govt. Subsidy Status")}
                    </span>
                  </Link>
                </li>
                <li
                  style={
                    this.state.weatherValidation ? {} : { display: "none" }
                  }
                >
                  <Link
                    to="weathervalidation"
                    className=" waves-effect"
                    onClick={this.toggleMenuCall}
                  >
                    <span className="ml-1">
                      {this.props.t("Weather Data Status")}
                    </span>
                  </Link>
                </li>
                <li style={this.state.premiumStatus ? {} : { display: "none" }}>
                  <Link
                    to="premiumpaid"
                    className=" waves-effect"
                    onClick={this.toggleMenuCall}
                  >
                    <span className="ml-1">
                      {this.props.t("Farmer Premium Status")}
                    </span>
                  </Link>
                </li>
                <li style={this.state.payoutFarmer ? {} : { display: "none" }}>
                  <Link
                    to="smc-transactions-farmer"
                    className=" waves-effect"
                    onClick={this.toggleMenuCall}
                  >
                    <span className="ml-1">
                      {this.props.t("Payout - FARMER")}
                    </span>
                  </Link>
                </li>
                {/* <li>
                  <Link
                    to="transactionfarmer"
                    className=" waves-effect"
                    onClick={this.toggleMenuCall}
                  >
                    <span className="ml-1">
                      {this.props.t("Tranactions - FARMER")}
                    </span>
                  </Link>
                </li> */}
              </ul>
            </li>
            <li style={this.state.isfarmerupload ? { display: "none" } : {}}>
              <Link
                to="upload"
                className=" waves-effect"
                onClick={this.toggleMenuCall}
              >
                {/* <i className=" ri-folder-upload-line"></i>
                <span className="ml-1">
                  {this.props.t("Farmer Data Upload")}
                </span> */}
                <div class="row">
                  <div class="col-md-1 col-1">
                    {" "}
                    <i className=" ri-folder-upload-line"></i>
                  </div>
                  <div class="col-md-10 col-10">
                    <span className="ml-1">
                      {this.props.t("Farmer Data Upload")}
                    </span>
                  </div>
                </div>
              </Link>
            </li>
            <li style={this.state.isfarmersearch ? { display: "none" } : {}}>
              <Link
                to="farmer-search"
                className=" waves-effect"
                onClick={this.toggleMenuCall}
              >
                <i className="ri-user-search-line"></i>
                <span className="ml-1">{this.props.t("Farmer Search")}</span>
              </Link>
            </li>

            <li
              style={this.state.user_FM ? { display: "none" } : {}}
              onClick={this.toggleMenuCall}
            >
              <Link to="alert" className=" waves-effect">
                <i className="ri-alert-line "></i>
                <span className="ml-1">{this.props.t("Alerts")}</span>
              </Link>
            </li>
            <li
              style={this.state.user_FM ? {} : { display: "none" }}
              onClick={this.toggleMenuCall}
            >
              <Link to="/fm_alerts" className=" waves-effect">
                <i className="ri-alert-line "></i>
                <span className="ml-1">{this.props.t("Alerts")}</span>
              </Link>
            </li>
            <li
              style={this.state.user_FM ? {} : { display: "none" }}
              onClick={this.toggleMenuCall}
            >
              <Link to="/fmreports" className=" waves-effect">
                {/* <i className="ri-file-line "></i>
                <span className="ml-1">{this.props.t("Weather Report")}</span> */}
                <div class="row">
                  <div class="col-md-1 col-1">
                    {" "}
                    <i className="ri-file-line "></i>
                  </div>
                  <div class="col-md-10 col-10">
                    <span className="ml-1">
                    {this.props.t("Reports")}
                    </span>
                  </div>
                </div>
              </Link>
            </li>
            <li
              className="report-hide"
              style={this.state.user_FM ? { display: "none" } : {}}
              onClick={this.toggleMenuCall}
            >
              <Link to="report" className=" waves-effect">
                <i className=" ri-file-line"></i>
                <span className="ml-1">{this.props.t("Reports")}</span>
              </Link>
            </li>
            <li style={this.state.issettings ? {} : { display: "none" }}>
              <Link to="/#" className="has-arrow waves-effect">
                <i className=" ri-file-settings-line "></i>
                <span className="ml-1">{this.props.t("Settings")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/dataconfig" onClick={this.toggleMenuCall}>
                    {this.props.t("Data Compensation")}
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        )}
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  return { ...state.Layout };
};

export default withRouter(
  connect(mapStatetoProps, {
    changeLayout,
    changeSidebarTheme,
    changeSidebarType,
    changeLayoutWidth,
    changePreloader,
  })(withNamespaces()(SidebarContent))
);
