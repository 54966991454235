import React, { Component } from "react";
import {
  Row,
  Col,
  CardBody,
  Button,
  FormGroup,
  Label,
} from "reactstrap";
import { MDBDataTable } from "mdbreact";
import "./css/farmerclaims.css";
import { toast } from "react-toastify";
import validate_Token from "../../validateToken.js";
toast.configure();
class FarmerClaims extends Component {
  constructor(props) {
  
    super(props);
    this.state = {
      farmerId: props.farmerId,
      farmerCrops:props.farmerCrops,
      tabledata: [],
      showloader: true,
      perilData: [],
      crop: "all",
      peril: "all",
      season: props.farmerCrops[0],
      rua: "Chittur",
      showLoader: false,
      showWaiting: true,
      showtable: false,
      dropdownKey:1,
      seasonData: [],
      data_provider: "all",
      cropData: [
        "Paddy",
        "Veg Group I",
        "Mango",
        "Paddy 2nd Crop",
        "Paddy 3rd Crop",
        "Paddy Winter",
        "Veg Group I - SMR",
      ],
      perilseasonData: [],
      payoutdata: [],
    };
    this.onChange = this.onChange.bind(this);
    this.handleSeasonchange = this.handleSeasonchange.bind(this);
    this.getpayputdata = this.getpayputdata.bind(this);
  }
  onChange(ev) {
    this.setState({
      [ev.target.name]: ev.target.value,
    });
  }
  async fetchseason() {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + localStorage.getItem("token"),
      },
    };
    try {
      var seasonData = await fetch(
        process.env.REACT_APP_APIEND + `smart_crop/term-sheet-season-crop`,
        requestOptions
      ).then((res) => {
        return res.json();
      });
      this.setState({
        perilseasonData: seasonData.result,
      });

      var newseasonData = [];
      {
        seasonData.result.map(function (item, index, data) {
          newseasonData.push(Object.keys(item));
        });
      }
      this.setState({
        seasonData: newseasonData,
        season: newseasonData[0][0],
      });
    } catch (err) {
      toast.error("No Data Found ! ");
    }
  }
  handleSeasonchange(e) {
    // console.log("SELECTED SEASON", e.target.value);
    this.setState({
      season: e.target.value,
    });
  }
  async getpayputdata() {
    let searchdata = {
      farmer_id: this.state.farmerId,
      season: this.state.season,
    };
    searchdata = JSON.stringify(searchdata);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + localStorage.getItem("token"),
      },
      body: searchdata,
    };
    try {
      var payoutData = await fetch(
        process.env.REACT_APP_APIEND + `farmer/smart-contract-id-season`,
        requestOptions
      ).then((res) => {
        return res.json();
      });
      // console.log("PAYOUT DATA", payoutData);
      if(payoutData.hasOwnProperty('data'))
      {
       
        if(payoutData.data.result.metaData != null){
          this.tabledata(payoutData.data.result);
        }
        else {
          toast.info("Payout not available for current season!");
        }
      }else{
        toast.info("Payout not available for current season!");
      }
      
    } catch (err) {
      // console.log("error", err)
      toast.error("Something's not right");
    }
  }
  componentDidMount() {
    validate_Token();
  }
  tabledata(data) {
    let getthis = this;
    let tabledata = [];
    var newdata = {};
    var uniqueArray = [];
    data.metaData.map(function (item, index, data) {
      // console.log("data", item)
      newdata = [{}];
      if (item != null) {    
        newdata = {
          id: item.id,
          season: item.season,
          crop: item.crop,
          peril: item.peril,
          phase: item.phase,
          value: item.payoutFarmer,
        };
      } else {
        toast.error("Data is Empty !");
      }
      tabledata.push(newdata);
      var newtable = tabledata.map(JSON.stringify);
      var uniqueSet = new Set(newtable);
             uniqueArray = Array.from(uniqueSet).map(JSON.parse);
      getthis.setState({
        payoutdata: uniqueArray,
      });
    })
  }
  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setState({
      dropdownKey:this.state.dropdownKey+1,
      season:this.props.farmerCrops[0]
      });
    }
  }
  render() {
    let data = {
      columns: [
        {
          label: "Season",
          field: "season",
          sort: "asc",
        },

        {
          label: "Crop",
          field: "crop",
          sort: "asc",
        },
        {
          label: "Peril",
          field: "peril",
          sort: "asc",
        },
        {
          label: "Phase",
          field: "phase",
          sort: "asc",
        },
        {
          label: "Amount",
          field: "value",
          sort: "asc",
        },
      ],
      rows: this.state.payoutdata,
    };

    return (
      <React.Fragment>
        <CardBody>
          <Col>
            <Row>
              <Col md="2">
                <FormGroup>
                  <Label
                    className="col-md-10 col-form-label"
                    style={{ fontSize: "14px" }}
                  >
                    Season{" "}
                  </Label>
                  <select
                    className="form-select"
                    style={{ fontSize: "11px" }}
                    onChange={this.handleSeasonchange}
                    // value={this.state.season}
                    key={this.state.dropdownKey}
                  >
                    {/* {console.log("INSIDE PROPSSSS", this.props.farmerCrops)} */}
                    {this.props.farmerCrops.map((item) => (
                      <option value={item} key={item}>
                        {item}
                      </option>
                    ))}
                  </select>
                </FormGroup>
              </Col>
              <Col md="1">
                <FormGroup>
                  <Label
                    className="col-md-12 col-form-label"
                    style={{ fontSize: "14px" }}
                  >
                    <span>&nbsp;</span>
                  </Label>
                  <Button
                    color="primary"
                    className="waves-effect waves-light mr-1"
                    style={{ fontSize: "11px", float: "right" }}
                    onClick={this.getpayputdata}
                  >
                    Search
                  </Button>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col></Col>
            </Row>
          </Col>
        </CardBody>
        <div id="farmerclaims" style={{ fontSize: "12px" }}>
          <MDBDataTable
            responsive
            paging={false}
            showPaginationBottom={false}
            bordered
            data={data}
            order={["id", "asc"]}
            className="datadownloadtable"
          />
        </div>
      </React.Fragment>
    );
  }
}
export default FarmerClaims;
