import React, { Component } from "react";
import { Link } from "react-router-dom";
import { CSVLink } from "react-csv";
import { MDBDataTable } from "mdbreact";
import { Spinner, Table } from "reactstrap";
import "../Datatable-css/datatables.scss";
import { DatePicker } from "antd";
import moment from "moment";
import { withNamespaces } from "react-i18next";
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  Label,
  Container,
  CardHeader,
} from "reactstrap";

const { RangePicker } = DatePicker;
const dateFormat = "DD-MM-YYYY";

class daily_Parameter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subws: true,
      subrua: false,
      subgroup: false,
      showbutton: false,
      subsource: "all",
      source: "weather_stations",
      parameter: "Temperature",
      data: [],
      csvdownloadData: [],
      showtable: false,
      showwaitintext: true,
      showloader: false,
      fromDate: "",
      toDate: "",
    };

    this.handleSourcechange = this.handleSourcechange.bind(this);
    this.onChangedate = this.onChangedate.bind(this);
    this.getData = this.getData.bind(this);
    this.handlesubSourcechange = this.handlesubSourcechange.bind(this);
    this.handleParameterchange = this.handleParameterchange.bind(this);
    this.makecsvdata = this.makecsvdata.bind(this);
  }

  onChangedate(dateString) {
    const date1 = new Date(dateString[0]);
    const date2 = new Date(dateString[1]);
    
    var from_dd = String(date1.getDate()).padStart(2, "0");
    var from_mm = String(date1.getMonth() + 1).padStart(2, "0"); //January is 0!
    var from_yyyy = date1.getFullYear();
    var from_date = from_yyyy + "-" + from_mm + "-" + from_dd;

    var to_dd = String(date2.getDate()).padStart(2, "0");
    var to_mm = String(date2.getMonth() + 1).padStart(2, "0"); //January is 0!
    var to_yyyy = date2.getFullYear();
    var to_date = to_yyyy + "-" + to_mm + "-" + to_dd;

    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    // console.log("DIFFERENCE DAYS", diffDays);

    if (diffDays <= 30) {
      this.setState({
        fromDate: from_date,
        toDate: to_date,
        showbutton: true,
      });
    } else {
      this.setState({
        showbutton: false,
      });
    }
    // console.log("dataaa", date1);
  }

  handlesubSourcechange(e) {
    // console.log("SUBSOURCE ", e.target.value);
    this.setState({
      subsource: e.target.value,
    });
  }

  handleParameterchange(e) {
    this.setState({
      parameter: e.target.value,
    });
  }

  handleSourcechange(e) {
    this.setState({
      source: e.target.value,
    });
    if (e.target.value == "weather_stations") {
      this.setState({
        subws: true,
        subrua: false,
        subgroup: false,
      });
    }
    if (e.target.value == "rua") {
      this.setState({
        subrua: true,
        subws: false,
        subgroup: false,
      });
    }
    if (e.target.value == "group") {
      this.setState({
        subgroup: true,
        subws: false,
        subrua: false,
      });
    }
  }

  async getData() {
    this.setState({
      showloader: true,
      showtable: false,
      showwaitintext: false,
    });
    var getthis = this;
    let searchData = {
      weather_parms: this.state.parameter,
      from_date: this.state.fromDate,
      to_date: this.state.toDate,
      rua: localStorage.getItem("krishibhavan"),
    };

    searchData = JSON.stringify(searchData);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token"),
      },
      body: searchData,
    };
    var weatherResponse = await fetch(
      process.env.REACT_APP_APIEND + `weather/daily-report-farmer`,
      requestOptions
    ).then((res) => {
      return res.json();
    });
    getthis.makecsvdata(weatherResponse.result);
    this.generatetabledata(weatherResponse.result);
  }
  checkforunit(peril){
    // console.log("PERIL",peril)
    if(peril=="Temperature"){
      return("Temperature [°C]")
    }
    if(peril=="Rainfall"){
      return("Rainfall [mm]")
    }
    if(peril=="Wind_Speed"){
      return("Wind Speed [m/s]")
    }
    if(peril=="Wind_Direction"){
      return("Wind Direction [°]")
    }
    if(peril=="UV_Index"){
      return("UV")
    }
    if(peril=="Humidity"){
      return("Relative Humidity [%]")
    }
    if(peril=="Pressure"){
      return("Pressure [hPa]")
    }

  }
  generatetabledata(data) {
    let getthis = this;
    let tableData = [];
    // console.log("FORMAT DATA", data);
    data.map(function (item, index, data) {
      var newdata = {
        rua: <p className="alnleft">{item.rua}</p>,
        date:  <p className="alnleft"> {moment(item.date).format("DD-MM-YYYY")}</p>,
        // parameter: <p className="alnleft"> {getthis.checkforunit(item.parameter)}</p>,
        min: item.min,
        max: item.max,
        avg: item.avg,
        agg:getthis.checknull(item.agg),
      };
      tableData.push(newdata);
    });
    this.setState({
      data: tableData,
      showtable: true,
      showloader: false,
    });
  }
  checknull(item) {
    if (item == null) {
      return "N/A";
    } else {
      return item;
    }
  }
  disabledDate(current) {
    // Can not select days before today and today
    return current && current > moment().endOf("day");
  }
  makecsvdata(data) {
    let csvData = [
      [
        "RUA",
        "Date",
        "Parameter",
        "Minimum",
        "Maximum",
        "Average",
        "Aggregate",
      ],
    ];
    data.map((wdata) => {
      csvData.push([
        wdata.rua,
        wdata.date,
        wdata.parameter,
        wdata.min,
        wdata.max,
        wdata.avg,
        wdata.agg,
      ]);
    });
    this.setState({
      csvdownloadData: csvData,
    });
  }

  render() {
    const { t, i18n } = this.props;
    let data = {
      columns: [
        {
          label: "RUA",
          field: "rua",
          sort: "asc",
          width: 150,
        },
        {
          label: "Date",
          field: "date",
          sort: "asc",
          width: 150,
          sort:"disabled"
        },
        {
          label: "Minimum",
          field: "min",
          sort: "asc",
          width: 150,
        },
        {
          label: "Maximum",
          field: "max",
          sort: "asc",
          width: 150,
        },
        {
          label: "Average",
          field: "avg",
          sort: "asc",
          width: 150,
        },
        {
          label: "Aggregate",
          field: "agg",
          sort: "asc",
          width: 150,
        },
      ],
      rows: this.state.data,
    };
    return (
      <React.Fragment>
        <div className="page-content">
          <Card style={{ backgroundColor: "#e5ebee" }}>
            <CardHeader style={{ backgroundColor: "#A4C1D2" }}>
              <Row>
                <Col>
                  <h4
                    className="card-title"
                    style={{ padding: "5px", fontSize: "18px" }}
                  >
                    {" "}
                    {t("Daily report")}
                  </h4>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Container fluid>
                <Row>
                  <Col xs={12}></Col>
                </Row>
                <Row>
                  <Col xl={12}>
                    <Card style={{ backgroundColor: "#f1f6fb" }}>
                      <CardBody>
                        <Col>
                          <Row>
                            <Col md="4">
                              <div className="col-md-12 date-range">
                                <Label
                                  className="col-md-12 col-form-label"
                                  style={{ fontSize: "11px" }}
                                >
                                  {t("Date range")}{" "}
                                </Label>
                                <RangePicker
                                  name="reportrange"
                                  onChange={this.onChangedate}
                                  format="DD-MM-YYYY"
                                  disabledDate={this.disabledDate}
                                />
                              </div>
                              <center
                                style={
                                  this.state.showbutton
                                    ? { display: "none" }
                                    : {}
                                }
                              >
                                <p style={{ color: "red", fontSize: "10px" }}>
                                  {t(
                                    "Please select a date range between 30 days"
                                  )}
                                </p>
                              </center>
                            </Col>
                            <Col md="2">
                              <FormGroup>
                                <Label
                                  className="col-md-12 col-form-label"
                                  style={{ fontSize: "11px" }}
                                >
                                  {t("Parameter")}{" "}
                                </Label>
                                <select
                                  className="form-select"
                                  md="6"
                                  style={{ fontSize: "11px" }}
                                  name="location"
                                  required
                                  onChange={this.handleParameterchange}
                                >
                                  <option value="Temperature">
                                    Temperature [°C]
                                  </option>
                                  <option value="Rainfall">Rainfall [mm]</option>
                                  <option value="Wind_Speed">Wind Speed [m/s]</option>
                                  <option value="Wind_Direction">
                                    Wind Direction [°]
                                  </option>
                                  <option value="UV_Index">UVI</option>
                                  <option value="Humidity">
                                    Relative Humidity [%]
                                  </option>
                                  <option value="Pressure">Pressure [hPa]</option>
                                </select>
                              </FormGroup>
                            </Col>

                            <Col md="2">
                              <FormGroup
                               
                              >
                                <Label
                                  className="col-md-12 col-form-label"
                                  style={{ fontSize: "14px" }}
                                >
                                  <span>&nbsp;</span>
                                </Label>
                                <Button
                                  color="primary"
                                  className="waves-effect waves-light mr-1"
                                  style={{ fontSize: "11px", float: "right" }}
                                  onClick={this.getData}
                                  disabled={this.state.showbutton?false:true
                                  }
                                >
                                  {t("Search")}
                                </Button>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col></Col>
                          </Row>
                        </Col>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col xl={12}>
                    <Card>
                      <CardBody>
                        <Row>
                          <div
                            style={
                              this.state.showwaitintext
                                ? {}
                                : { display: "none" }
                            }
                          >
                            <center>{t("Waiting for search")}</center>
                          </div>
                          <div
                            style={
                              this.state.showloader ? {} : { display: "none" }
                            }
                          >
                            <center>
                              <Spinner color="dark" />
                            </center>
                          </div>
                          <Col xs={12}>
                            <div
                              style={
                                this.state.showtable ? {} : { display: "none" }
                              }
                            >
                              {/* <div class="text-right">
                                <CSVLink
                                  filename="weatherData.csv"
                                  data={this.state.csvdownloadData}
                                >
                                  {t("Download Report")}
                                </CSVLink>
                              </div> */}
                              <div id="dailydatareport" style={{ fontSize: "12px" }}>
                                <MDBDataTable
                                  paging={true}
                                  bordered
                                  data={data}
                                  scrollX
                                  className="datadownloadtable alnright"
                                  style={{ fontSize: "12px" }}
                                  maxHeight="300px"
                                />
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </CardBody>
          </Card>
        </div>
      </React.Fragment>
    );
  }
}
export default withNamespaces()(daily_Parameter);
