import React, { useState, useEffect } from "react";
import FileViewer from "react-file-viewer";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import {
  Table,
  Card,
  CardBody,
  Row,
  Col,
  Container,
  FormGroup,
  Label,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";
// Bootstrap CSS
import { Link } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import { toast } from "react-toastify";
import axios from "axios";
import { withNamespaces } from "react-i18next";
import KH2020PADDY from "../../assets/pdf/KH2020Paddy.pdf";
import KH2021PADDY from "../../assets/pdf/KH2021Paddy.pdf";
import KH2022PADDY from "../../assets/pdf/KH2022Paddy.pdf";
import KH2020VEG from "../../assets/pdf/KH2020Veg.pdf";
import KH2021VEG from "../../assets/pdf/KH2021Veg.pdf";
import KH2022VEG from "../../assets/pdf/KH2022Veg.pdf";
import RB2020PADDY3 from "../../assets/pdf/RB2020PADDY3.pdf";
import RB2021PADDY3 from "../../assets/pdf/RB2021PADDY3.pdf";
import RB2022PADDY3 from "../../assets/pdf/RB2022PADDY3.pdf";
import RB2020MANGO from "../../assets/pdf/RB2020MANGO.pdf";
import RB2021MANGO from "../../assets/pdf/RB2021MANGO.pdf";
import RB2022MANGO from "../../assets/pdf/RB2022MANGO.pdf";
import RB2020PADDY2 from "../../assets/pdf/RB2020PADDY2.pdf";
import RB2021PADDY2 from "../../assets/pdf/RB2021PADDY2.pdf";
import RB2022PADDY2 from "../../assets/pdf/RB2022PADDY2.pdf";
import RB2020VEG from "../../assets/pdf/RB2020Veg.pdf";
import RB2021VEG from "../../assets/pdf/RB2021Veg.pdf";
import RB2022VEG from "../../assets/pdf/RB2022Veg.pdf";
import RB2020VEGSMR from "../../assets/pdf/RB2020VEGSMR.pdf";
import RB2021VEGSMR from "../../assets/pdf/RB2021VEGSMR.pdf";
import RB2022VEGSMR from "../../assets/pdf/RB2022VEGSMR.pdf";
import RB2020PADDYWINTER from "../../assets/pdf/RB2020PADDYWINTER.pdf";
import RB2021PADDYWINTER from "../../assets/pdf/RB2021PADDYWINTER.pdf";
import RB2022PADDYWINTER from "../../assets/pdf/RB2022PADDYWINTER.pdf";
// Import styles
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import {
  Position,
  Tooltip,
  Viewer,
  Worker,
  PrimaryButton,
} from "@react-pdf-viewer/core";
let file = KH2020PADDY;

function Tm_Pdf(props) {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const [season, setSeason] = useState("");
  const [crop, setCrop] = useState("Paddy");
  const [cropsData, setCropsData] = useState([]);
  const [seasonData, setseasonData] = useState([]);
  const [allseasonData, setallseasonData] = useState([]);
  const [showWaiting, setshowWaiting] = useState(true);
  const [key, setKey] = useState(1);

  const onChangeSeason = (e) => {
    setSeason(e.target.value);
   
    var newcrops = [];
    allseasonData.map(function (list) {
      if (e.target.value == Object.keys(list)[0]) {
        list[e.target.value].map(function (crops) {
          newcrops.push(crops.crop);     
        });      
      }
    });
    setCropsData(newcrops);
    setCrop(newcrops[0])
    // console.log("ALL LIST", newcrops);
  };
  const onChangeCrop = (e) => {
    setCrop(e.target.value);
  };
  function searchFile() {
    setshowWaiting(false);
    // console.log("SEASON CROP", season, crop);
    if (crop == "Paddy" && season == "Kharif 2020") {
      file = KH2020PADDY;
    } else if (crop == "Paddy" && season == "Kharif 2021") {
      file = KH2021PADDY;
    } else if (crop == "Paddy" && season == "Kharif 2022") {
      file = KH2022PADDY;
    } else if (crop == "Veg Group I" && season == "Kharif 2020") {
      file = KH2020VEG;
    } else if (crop == "Veg Group I" && season == "Kharif 2021") {
      file = KH2021VEG;
    } else if (crop == "Veg Group I" && season == "Kharif 2022") {
      file = KH2022VEG;
    } else if (crop == "Paddy 3rd Crop" && season == "Rabi 2020-21") {
      file = RB2020PADDY3;
    } else if (crop == "Paddy 3rd Crop" && season == "Rabi 2021-22") {
      file = RB2021PADDY3;
    } else if (crop == "Paddy 3rd Crop" && season == "Rabi 2022-23") {
      file = RB2022PADDY3;
    } else if (crop == "Mango" && season == "Rabi 2020-21") {
      file = RB2020MANGO;
    } else if (crop == "Mango" && season == "Rabi 2021-22") {
      file = RB2021MANGO;
    } else if (crop == "Mango" && season == "Rabi 2022-23") {
      file = RB2022MANGO;
    } else if (crop == "Paddy 2nd Crop" && season == "Rabi 2020-21") {
      file = RB2020PADDY2;
    } else if (crop == "Paddy 2nd Crop" && season == "Rabi 2021-22") {
      file = RB2021PADDY2;
    } else if (crop == "Paddy 2nd Crop" && season == "Rabi 2022-23") {
      file = RB2022PADDY2;
    } else if (crop == "Veg Group I" && season == "Rabi 2020-21") {
      file = RB2020VEG;
    } else if (crop == "Veg Group I" && season == "Rabi 2021-22") {
      file = RB2021VEG;
    } else if (crop == "Veg Group I" && season == "Rabi 2022-23") {
      file = RB2022VEG;
    } else if (crop == "Veg Group I - SMR" && season == "Rabi 2020-21") {
      file = RB2020VEGSMR;
    } else if (crop == "Veg Group I - SMR" && season == "Rabi 2021-22") {
      file = RB2021VEGSMR;
    } else if (crop == "Veg Group I - SMR" && season == "Rabi 2022-23") {
      file = RB2022VEGSMR;
    } else if (crop == "Paddy Winter" && season == "Rabi 2020-21") {
      file = RB2020PADDYWINTER;
    } else if (crop == "Paddy Winter" && season == "Rabi 2021-22") {
      file = RB2021PADDYWINTER;
    } else if (crop == "Paddy Winter" && season == "Rabi 2022-23") {
      file = RB2022PADDYWINTER;
    } else {
      file = [];
    }
    setKey(key + 1);
  }
  async function getfmeasons() {
    let searchdata = {
      farmer_id: localStorage.getItem("farmer_id"),
    };
    searchdata = JSON.stringify(searchdata);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token"),
      },
      body: searchdata,
    };
    var SeasonPeril = await fetch(
      process.env.REACT_APP_APIEND + `farmer/farmer-crops-list`,
      requestOptions
    ).then((res) => {
      return res.json();
    });

    var seasons = [];
    var crops = [];
    var allseason_data = [];
    SeasonPeril.result.map(function (item, index, data) {
      seasons.push(Object.keys(item));
      allseason_data.push(item);
    });
    setallseasonData(allseason_data);
    SeasonPeril.result[0][seasons[0]].map(function (cropslist) {
      crops.push(cropslist.crop);
    });
    // console.log("FETCH SEASON CROP", seasons[0][0]);
    setseasonData(seasons);
    setSeason(seasons[0][0]);
    setCropsData(crops);
    setCrop(crops[0]);
  
  }
  useEffect(() => {
    getfmeasons();
  }, []);
  const  {t,i18n} =props

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xs={12}>
              <h4
                className="card-title"
                style={{ padding: "5px", fontSize: "18px" }}
              >
                {t("Termsheet Reference")}
              </h4>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card style={{ backgroundColor: "#f1f6fb" }}>
                <CardBody>
                  <Col>
                    <Row>
                      <Col md="2">
                        <FormGroup>
                          <Label
                            className="col-md-10 col-form-label"
                            style={{ fontSize: "11px" }}
                          >
                            {t("Season")}{" "}
                          </Label>
                          <select
                            className="form-select"
                            style={{ fontSize: "11px" }}
                            name="season"
                            onChange={(e) => onChangeSeason(e)}
                          >
                            {seasonData.length > 0 &&
                              seasonData.map((item) => (
                                <option value={item} key={item}>
                                  {item}
                                </option>
                              ))}
                            {/* <option value="Kharif 2020">Kharif 2020</option>
                            <option value="Kharif 2021">Kharif 2021</option>
                            <option value="Kharif 2022">Kharif 2022</option>
                            <option value="Rabi 2020-21">Rabi 2020-21</option>
                            <option value="Rabi 2021-22">Rabi 2021-22</option>
                            <option value="Rabi 2022-23">Rabi 2022-23</option> */}
                          </select>
                        </FormGroup>
                      </Col>
                      <Col md="2">
                        <FormGroup>
                          <Label
                            className="col-md-10 col-form-label"
                            style={{ fontSize: "11px" }}
                          >
                            {t("Crop")}{" "}
                          </Label>
                          <select
                            className="form-select"
                            style={{ fontSize: "11px" }}
                            name="crop"
                            value={crop}
                            onChange={onChangeCrop}
                          >
                            {cropsData.length > 0 &&
                              cropsData.map((item) => (
                                <option value={item} key={item}>
                                  {item}
                                </option>
                              ))}
                          
                          </select>
                        </FormGroup>
                      </Col>
                      <Col md="2">
                        <FormGroup>
                          <Label
                            className="col-md-12 col-form-label"
                            style={{ fontSize: "14px" }}
                          >
                            <span>&nbsp;</span>
                          </Label>
                          <Button
                            color="primary"
                            className="waves-effect waves-light mr-1"
                            style={{ fontSize: "11px", float: "right" }}
                            onClick={searchFile}
                          >
                            {t("Search")}
                          </Button>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col></Col>
                    </Row>
                  </Col>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  {/* <FileViewer                    
                      fileType={type}
                      filePath={file}
                      key={this.state.key}
                    />    
                                     */}
                  <center>
                    <p style={showWaiting == true ? {} : { display: "none" }}>
                      {t("Waiting for search")}
                    </p>
                  </center>
                  <div style={showWaiting == true ? { display: "none" } : {}}>
                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.1.266/build/pdf.worker.min.js">
                      <div
                        style={{
                          height: "550px",
                          border: "1px solid #919191",
                        }}
                      >
                        <Viewer
                          initialPage={3}
                          fileUrl={file}
                          plugins={[defaultLayoutPluginInstance]}
                        />
                      </div>
                    </Worker>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}
export default withNamespaces()(Tm_Pdf);
