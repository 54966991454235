import React, { useState, useEffect } from "react";
import { Card, CardBody, Row, Col, Table, FormGroup, Label } from "reactstrap";
import Permission from "../../permission.js";
import './table.css'
import { Component } from 'react'
import { withNamespaces } from 'react-i18next';
import Moment from 'moment';


class SiderHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            kb1: false,
            kb2: false,
            kb3: false,
            kb4: false,
            kb5: false,
            kb6: false,
            kb7: false,

        };

    }

    componentDidMount() {
        const krishibhavan = Permission();
        if (krishibhavan[1] == 'Nallepilly' && krishibhavan[0] == 'FM') {
            this.setState({
                kb1: false,
                kb2: false,
                kb3: false,
                kb4: true,
                kb5: false,
                kb6: false,
                kb7: false,
            })
        } else if (krishibhavan[1] == 'Chittur-Thathamangalam' && krishibhavan[0] == 'FM') {
            this.setState({
                kb1: true,
                kb2: false,
                kb3: false,
                kb4: false,
                kb5: false,
                kb6: false,
                kb7: false,
            })

        } else if (krishibhavan[1] == 'Kozhinjampara' && krishibhavan[0] == 'FM') {

            this.setState({
                kb1: false,
                kb2: false,
                kb3: true,
                kb4: false,
                kb5: false,
                kb6: false,
                kb7: false,
            })


        } else if (krishibhavan[1] == 'Eruthenpathy' && krishibhavan[0] == 'FM') {

            this.setState({
                kb1: false,
                kb2: true,
                kb3: false,
                kb4: false,
                kb5: false,
                kb6: false,
                kb7: false,
            })

        } else if (krishibhavan[1] == 'Pattanchery' && krishibhavan[0] == 'FM') {

            this.setState({
                kb1: false,
                kb2: false,
                kb3: false,
                kb4: false,
                kb5: true,
                kb6: false,
                kb7: false,
            })

        } else if (krishibhavan[1] == 'Perumatty' && krishibhavan[0] == 'FM') {

            this.setState({
                kb1: false,
                kb2: false,
                kb3: false,
                kb4: false,
                kb5: false,
                kb6: true,
                kb7: false,
            })

        } else if (krishibhavan[1] == 'Vadakarapathy' && krishibhavan[0] == 'FM') {

            this.setState({
                kb1: false,
                kb2: false,
                kb3: false,
                kb4: false,
                kb5: false,
                kb6: false,
                kb7: true,
            })


        }

    }

    render() {
        const { t, i18n } = this.props

        return (
            <React.Fragment>
                <FormGroup row>
                    <Label className="col-md-3 col-form-label" style={{ fontSize: '14px' }}>{t("Location")}  </Label>
                    <Col md={9}>
                        <select className="form-control" style={{ fontSize: '11px' }} disabled>
                            <option value="" selected={this.state.kb1 ? true : false}  >Chittur-Thathamangalam
                            </option>
                            <option value="" selected={this.state.kb2 ? true : false}>Eruthenpathy</option>
                            <option value="" selected={this.state.kb3 ? true : false}>Kozhinjampara </option>
                            <option value="" selected={this.state.kb4 ? true : false}>Nallepilly</option>
                            <option value="" selected={this.state.kb5 ? true : false}>Pattanchery</option>
                            <option value="" selected={this.state.kb6 ? true : false}>Perumatty</option>
                            <option value="" selected={this.state.kb7 ? true : false}>Vadakarapathy</option>
                        </select>
                    </Col>

                </FormGroup>
                <Row style={{ fontSize: '12px', fontWeight: 'lighter' }}>
                    {/* <Col lg='6'>
                    <Label >Coordinates [Lat,Lon] </Label>
                </Col> */}
                    <Col lg='12' style={{ textAlign: 'right' }}>
                        <Label>{t("Date & Time")} </Label>
                    </Col>

                </Row>
                <Row style={{ fontSize: '12px', fontWeight: 'lighter' }}>
                    {/* <Col lg='6'>
                    <Label >{this.props.coordtime.coordinates[0]}, {this.props.coordtime.coordinates[1]} </Label>
                </Col> */}
                    <Col lg='12' style={{ textAlign: 'right' }}>
                        <Label>{Moment(Date()).format('DD-MM-YYYY HH:mm')}</Label>
                    </Col>
                </Row>

            </React.Fragment >
        )
    }
}

export default withNamespaces()(SiderHeader);
