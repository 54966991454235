import React, { Component } from "react";
import {
  Card,
  CardBody,
  Row,
  Col,
  Container,
  Button,
  CardHeader,
  ButtonGroup,
  ButtonToolbar,
} from "reactstrap";
import { Link } from "react-router-dom";
import loader from "../Dashboard/images/loader.gif";
import Chart from "react-apexcharts";
import ApexCharts from "apexcharts";
import { withNamespaces } from "react-i18next";
import { DatePicker, Space } from "antd";
import moment from "moment";

const { RangePicker } = DatePicker;
let max_value=0.00
class histDatalayout extends Component {
  constructor(props) {
    // console.log("HISTORY PROPS", props);
    super(props);
    this.state = {
      location: "",
      parameter: props.location.state.key,
      latitude: props.location.state.latitude,
      longitude: props.location.state.longitude,
      peril: props.location.state.parameter,
      graphShow: props.location.state.graph,
      showchart: false,
      rua: localStorage.getItem("krishibhavan"),
      kfdcolor: "#151714",
      kfowcolor: "#151714",
      kfallcolor: "#151714",
      kfsencolor: "#151714",
      kfmetcolor: "#151714",
      kfecmwfcolor: "#151714",
      kfnasacolor: "#151714",
      kfaiccolor: "#151714",
      from_date: "",
      active: true,
      to_date: "",
      options: {
        chart: {
          id: "historicaldata",
          type: "area",
          height: 350,
          zoom: {
            autoScaleYaxis: true,
          },
        },
        xaxis: {
          type: "datetime",
          tickAmount: 6,
          labels: {
            datetimeFormatter: {
              year: 'yyyy',
              month: 'dd MMM',
              day: 'dd MMM',
              hour: 'HH:mm'
            }
          },
        },
        tooltip: {
          x: {
            format: "dd MMM yyyy hh:mm:ss",
          },
        },
      },
      series: [
        {
          name: "Test Data",
          data: [
            { x: 1615362718000, y: 67.95 },
            { x: 1615363619000, y: 20.34 },
            { x: 1615364518000, y: 14.18 },
            { x: 1615365418000, y: 81.05 },
            { x: 1615366318000, y: 31.0 },
            { x: 1615367218000, y: 30.95 },
            { x: 1615369018000, y: 31.24 },
            { x: 1615369918000, y: 21.29 },
            { x: 1615467413000, y: 11.85 },
            { x: 1615467520000, y: 51.86 },
            { x: 1615467681000, y: 82.28 },
            { x: 1615468200000, y: 12.1 },
          ],
        },
        {
          name: "Test Data2",
          data: [
            { x: 1615362718000, y: 77.95 },
            { x: 1615363619000, y: 90.34 },
            { x: 1615364518000, y: 24.18 },
            { x: 1615365418000, y: 21.05 },
            { x: 1615366318000, y: 71.0 },
            { x: 1615367218000, y: 80.95 },
            { x: 1615369018000, y: 81.24 },
            { x: 1615369918000, y: 51.29 },
            { x: 1615467413000, y: 41.85 },
            { x: 1615467520000, y: 21.86 },
            { x: 1615467681000, y: 12.28 },
            { x: 1615468200000, y: 82.1 },
          ],
        },
      ],
    };
    this.clickHandlefilter = this.clickHandlefilter.bind(this);
    this.oneweekFilter = this.oneweekFilter.bind(this);
    this.onemonthFilter = this.onemonthFilter.bind(this);
    this.rabiFilter = this.rabiFilter.bind(this);
    this.kharifFilter = this.kharifFilter.bind(this);
    this.allFilter = this.allFilter.bind(this);
    this.handleKfilter = this.handleKfilter.bind(this);
    this.datechange = this.datechange.bind(this);
    this.onChangedate = this.onChangedate.bind(this);
    this.getHourlyHistorical=this.getHourlyHistorical.bind(this)
    this.genereateDailychartdata=this.genereateDailychartdata.bind(this)
  }
  clickHandlefilter() {
    ApexCharts.exec("historicaldata", "hideSeries", "ECMWF");
  }
  onChangedate(dateString) {
    const date1 = new Date(dateString[0]);
    const date2 = new Date(dateString[1]);

    var from_dd = String(date1.getDate()).padStart(2, "0");
    var from_mm = String(date1.getMonth() + 1).padStart(2, "0"); //January is 0!
    var from_yyyy = date1.getFullYear();
    var from_date = from_yyyy + "-" + from_mm + "-" + from_dd;

    var to_dd = String(date2.getDate()).padStart(2, "0");
    var to_mm = String(date2.getMonth() + 1).padStart(2, "0"); //January is 0!
    var to_yyyy = date2.getFullYear();
    var to_date = to_yyyy + "-" + to_mm + "-" + to_dd;

    this.setState(
      {
        from_date: from_date,
        to_date: to_date,
      },
      () => {
        // this.getHistoricalData();
        this.getHourlyHistorical();
      }
    );
  }
  oneweekFilter() {
    let curDate = new Date(Date.now()).getTime();
    let lastweek = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);
    let one = new Date("20 Jan 2021").getTime();
    let two = new Date().getTime();
    let onemonth = new Date();
    onemonth.setDate(onemonth.getDate() - 7);
    ApexCharts.exec("historicaldata", "zoomX", onemonth.getTime(), two);
  }

  onemonthFilter() {
    let curDate = new Date(Date.now()).getTime();
    let lastweek = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);
    let one = new Date("20 Jan 2021").getTime();
    let two = new Date().getTime();
    let onemonth = new Date();
    onemonth.setDate(onemonth.getDate() - 30);
    ApexCharts.exec("historicaldata", "zoomX", onemonth.getTime(), two);
  }

  kharifFilter() {
    let st = new Date("01 Jun 2020").getTime();
    let end = new Date("15 Oct 2020").getTime();
    ApexCharts.exec("historicaldata", "zoomX", st, end);
  }
  rabiFilter() {
    let st = new Date("15 Oct 2020").getTime();
    let end = new Date("30 Nov 2020").getTime();
    ApexCharts.exec("historicaldata", "zoomX", st, end);
  }
  allFilter() {
    ApexCharts.exec("historicaldata", "zoomX");
  }

  findMax(one, two, three, four, five, six) {
    const largest = Math.max(one, two, three, four, five, six);
    var per = (largest * 10) / 100;
    var retn = largest + per;
    var fvalue = Math.floor(retn);
    var top = Math.ceil((fvalue + 1) / 10) * 10;
    var final = parseInt(fvalue / 10, 10) + 1 * 10;
    return top;
  }

  async datechange(type) {
    if (type == "one") {
      this.setState({
        active: true,
        active_week: false,
        active_month: false,
      });
      var to_date = new Date();

      var to_dd = String(to_date.getDate()).padStart(2, "0");
      var to_mm = String(to_date.getMonth() + 1).padStart(2, "0"); //January is 0!
      var to_yyyy = to_date.getFullYear();

      var to_date1 = to_yyyy + "-" + to_mm + "-" + to_dd;

      this.setState(
        {
          from_date: to_date1,
          to_date: to_date1,
        },
        () => {
          this.getHourlyHistorical();
        }
      );
    }
    if (type == "week") {
      this.setState({
        active: false,
        active_week: true,
        active_month: false,
      });
      var to_date = new Date();
      var to_dd = String(to_date.getDate()).padStart(2, "0");
      var to_mm = String(to_date.getMonth() + 1).padStart(2, "0"); //January is 0!
      var to_yyyy = to_date.getFullYear();
      var to_date1 = to_yyyy + "-" + to_mm + "-" + to_dd;

      var from_date = new Date();
      from_date.setDate(from_date.getDate() - 7);
      var from_dd = String(from_date.getDate()).padStart(2, "0");
      var from_mm = String(from_date.getMonth() + 1).padStart(2, "0"); //January is 0!
      var from_yyyy = from_date.getFullYear();
      var from_date1 = from_yyyy + "-" + from_mm + "-" + from_dd;

      this.setState(
        {
          from_date: from_date1,
          to_date: to_date1,
        },
        () => {
          // this.getHistoricalData();
          this.getHourlyHistorical();
        }
      );
    }
    if (type == "month") {
      this.setState({
        active: false,
        active_week: false,
        active_month: true,
      });
      var to_date = new Date();
      var to_dd = String(to_date.getDate()).padStart(2, "0");
      var to_mm = String(to_date.getMonth() + 1).padStart(2, "0"); //January is 0!
      var to_yyyy = to_date.getFullYear();
      var to_date1 = to_yyyy + "-" + to_mm + "-" + to_dd;

      var from_date = new Date();
      from_date.setDate(from_date.getDate() - 30);
      var from_dd = String(from_date.getDate()).padStart(2, "0");
      var from_mm = String(from_date.getMonth() + 1).padStart(2, "0"); //January is 0!
      var from_yyyy = from_date.getFullYear();
      var from_date1 = from_yyyy + "-" + from_mm + "-" + from_dd;

      this.setState(
        {
          from_date: from_date1,
          to_date: to_date1,
        },
        () => {
          this.getHourlyHistorical();
        }
      );
    }
    if (type == "kharif") {
      this.setState({
        active: false,
      });
      this.setState(
        {
          from_date: "2020-05-01",
          to_date: "2020-10-15",
        },
        () => {
          this.getHistoricalData();
        }
      );
    }
    if (type == "rabi") {
      this.setState({
        active: false,
      });
      this.setState(
        {
          from_date: "2020-10-15",
          to_date: "2020-11-30",
        },
        () => {
          this.getHistoricalData();
        }
      );
    }
  }

  genereatechartdata(data) {
    // console.log("GENERATE CHART ", data);
    var getthis=this;
    var chart_values = [];
    var onlinedata = {
      name: "Online Sensor",
      data: [],
    };
    var value_list = [];
    if (data.result != null) {
      data.result.map(function (item, index, data) {      
          onlinedata.data.push({
            x: item.unixms * 1000,
            y: item.weather_parms,          
          })
          value_list.push(item.weather_parms);  
      });
    }
    if(this.state.peril=="Rainfall")
    {
      max_value=20
    }else{
      max_value = Math.max(...value_list);
     
    }
    if (onlinedata.data != null) {
      chart_values = [onlinedata];
    }

    // console.log("CHART VALUES", chart_values);
    return chart_values;
  }
  genereateDailychartdata(data) {
    // console.log("GENERATE CHART ", data);
    var getthis=this;
    var chart_values = [];
    var onlinedata = {
      name: "Online Sensor",
      data: [],
    };
    var value_list = [];
    if (data.result != null) {
      data.result.map(function (item, index, data) {      
          onlinedata.data.push({
            x: item.unixms * 1000,
            y: item[getthis.state.peril.toLowerCase()],          
          })      
          value_list.push(item[getthis.state.peril.toLowerCase()]);  
      });
    }
    if(this.state.peril=="Rainfall")
    {
      max_value=20
    }else{
      max_value = Math.max(...value_list);
     
    }
 
    // console.log("Valkue list",this.state.peril)
    if (onlinedata.data != null) {
      chart_values = [onlinedata];
    }

    // console.log("CHART VALUES", chart_values);
    return chart_values;
  }
  async getHistoricalData() {
    let getthis = this;
    var chart_values = [];
    let id = this.state.parameter;
    this.setState({
      showchart: false,
    });

    if (this.state.rua == "kdisc_sci_aws_01") {
      this.setState({
        location: "Chittur Thathamangalam Municipality",
      });
    }
    if (this.state.rua == "kdisc_sci_aws_02") {
      this.setState({
        location: "GSM High School",
      });
    }
    if (this.state.rua == "kdisc_sci_aws_03") {
      this.setState({
        location: "Nallepilly Grama Panchayat Office",
      });
    }
    if (this.state.rua == "kdisc_sci_aws_04") {
      this.setState({
        location: "Bhagavathy Govt. High School",
      });
    }
    if (this.state.rua == "kdisc_sci_aws_05") {
      this.setState({
        location: "Moolathara Regulator",
      });
    }
    if (this.state.rua == "kdisc_sci_aws_06") {
      this.setState({
        location: "Pattenchery Cremation Ground",
      });
    }
    if (this.state.rua == "kdisc_sci_aws_07") {
      this.setState({
        location: "Government Technical High School chittur",
      });
    }

  
    var rua = localStorage.getItem("krishibhavan");
    let bodydata = {
      from_date: this.state.from_date,
      to_date: this.state.to_date,
      weather_parms: this.state.peril,
      rua: rua,
      type: "avg",
    };

    bodydata = JSON.stringify(bodydata);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token"),
      },
      body: bodydata,
    };
    // console.log("SEARCH DATA MOUNT", bodydata);
    var responsehistory = await fetch(
      process.env.REACT_APP_APIEND + `weather/daily-history`,
      requestOptions
    ).then((res) => {
      return res.json();
    });
    // console.log("HISTORY DATA", responsehistory);

    var chart_values = getthis.genereatechartdata(responsehistory);

    // console.log("RESPONSE HISTORY", chart_values);
    getthis.setState({ graphShow: true });
    getthis.setState({
      options: {
        colors: [
          "#2E93fA",
          "#66DA26",
          "#FF4500",
          "#ff05e6",
          "#FF9800",
          "#9c59ff",
          "#0b6b75",
        ],
        chart: {
          id: "historicaldata",
          type: "area",
          height: 350,
          zoom: {
            autoScaleYaxis: true,
          },
          toolbar: {
            show: true,
            tools: {
              download: false,
            },
          },
        },
        xaxis: {
          type: "datetime",
          labels: {
            datetimeUTC: false,
            datetimeFormatter: {
              year: 'yyyy',
              month: 'dd MMM',
              day: 'dd MMM',
              hour: 'HH:mm'
            }
          },
        },
        yaxis: {
          show: true,
          min: 0,
          max: max_value,
          title: {
            text: this.checkPerilunit(),
            rotate: -90,
            offsetX: 0,
            offsetY: 0,
            style: {
              color: undefined,
              fontSize: "12px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 600,
              cssClass: "apexcharts-yaxis-title",
            },
          },
          labels: {
            formatter: function (val) {
              return val.toFixed(2);
            },
          },
        },

        stroke: {
          width: 1,
        },
        tooltip: {
          x: {
            format: "dd MMM yyyy",
          },
        },
        legend: {
          show: false,
        },
      },
    });
    getthis.setState({
      series: chart_values,
      showchart: true,
    });
  }
  async getHourlyHistorical(){
    let getthis = this;
    var chart_values = [];
    let id = this.state.parameter;
    this.setState({
      showchart: false,
    });

    if (this.state.rua == "kdisc_sci_aws_01") {
      this.setState({
        location: "Chittur Thathamangalam Municipality",
      });
    }
    if (this.state.rua == "kdisc_sci_aws_02") {
      this.setState({
        location: "GSM High School",
      });
    }
    if (this.state.rua == "kdisc_sci_aws_03") {
      this.setState({
        location: "Nallepilly Grama Panchayat Office",
      });
    }
    if (this.state.rua == "kdisc_sci_aws_04") {
      this.setState({
        location: "Bhagavathy Govt. High School",
      });
    }
    if (this.state.rua == "kdisc_sci_aws_05") {
      this.setState({
        location: "Moolathara Regulator",
      });
    }
    if (this.state.rua == "kdisc_sci_aws_06") {
      this.setState({
        location: "Pattenchery Cremation Ground",
      });
    }
    if (this.state.rua == "kdisc_sci_aws_07") {
      this.setState({
        location: "Government Technical High School chittur",
      });
    }

 
    var rua = localStorage.getItem("krishibhavan");
    let bodydata = {
      from_date: this.state.from_date,
      to_date: this.state.to_date,
      weather_parms: this.state.peril.toLowerCase(),
      rua: rua,
    };

    bodydata = JSON.stringify(bodydata);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token"),
      },
      body: bodydata,
    };
    // console.log("SEARCH DATA MOUNT", bodydata);
    var responsehistory = await fetch(
      process.env.REACT_APP_APIEND + `farmer/rua-sensor-history`,
      requestOptions
    ).then((res) => {
      return res.json();
    });
    // console.log("HISTORY DATA", responsehistory);

    var chart_values = getthis.genereateDailychartdata(responsehistory);

    // console.log("RESPONSE HISTORY", chart_values);
    getthis.setState({ graphShow: true });
    getthis.setState({
      options: {
        colors: [
          "#2E93fA",
          "#66DA26",
          "#FF4500",
          "#ff05e6",
          "#FF9800",
          "#9c59ff",
          "#0b6b75",
        ],
        chart: {
          id: "historicaldata",
          type: "area",
          height: 350,
          zoom: {
            autoScaleYaxis: true,
          },
          toolbar: {
            show: true,
            tools: {
              download: false,
            },
          },
        },
        xaxis: {
          type: "datetime",
          labels: {
            datetimeUTC: false,
            datetimeFormatter: {
              year: 'yyyy',
              month: 'dd MMM',
              day: 'dd MMM',
              hour: 'HH:mm'
            }
          },title: {
            text: "Date/Time",
            rotate: -90,
            offsetX: 0,
            offsetY: 0,
            style: {
              color: undefined,
              fontSize: "12px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 600,
              cssClass: "apexcharts-yaxis-title",
            },
          },
        },
        yaxis: {
          show: true,
          min: 0,
          max: max_value,
          title: {
            text: this.checkPerilunit(),
            rotate: -90,
            offsetX: 0,
            offsetY: 0,
            style: {
              color: undefined,
              fontSize: "12px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 600,
              cssClass: "apexcharts-yaxis-title",
            },
          },
          labels: {
            formatter: function (val) {
              return val.toFixed(2);
            },
          },
        },

        stroke: {
          width: 1,
        },
        tooltip: {
          x: {
            format: "dd MMM yyyy hh:mm:ss",
          },
        },
        legend: {
          show: false,
        },
      },
    });
    getthis.setState({
      series: chart_values,
      showchart: true,
    });
  }
  componentDidMount() {
    var to_date = new Date();
    var to_dd = String(to_date.getDate()).padStart(2, "0");
    var to_mm = String(to_date.getMonth() + 1).padStart(2, "0"); //January is 0!
    var to_yyyy = to_date.getFullYear();
    var to_date1 = to_yyyy + "-" + to_mm + "-" + to_dd;
    this.setState({
      from_date:to_date1,
      to_date:to_date1
    },()=>{
      this.getHourlyHistorical();
    })
   
  }
  checkPerilunit() {
    // console.log("PERIL THIS",this.state.peril)
    if (this.state.peril == "Temperature") {
      return "Temperature (°C)";
    }
    if (this.state.peril == "Rainfall") {
      return "Rainfall (mm)";
    }
    if (this.state.peril == "Pressure") {
      return "Pressure (hPa)";
    }
    if (this.state.peril == "Wind_Speed") {
      return "WindSpeed (m/s)";
    }
    if (this.state.peril == "Wind_Direction") {
      return "WindDirection (°)";
    }
    if (this.state.peril == "UV_Index") {
      return "UVI";
    }
    if (this.state.peril == "Humidity") {
      return "Humidity (%)";
    }
  }
  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  handleKfilter(e) {
    if (e.target.id == "ds") {
      this.setState({
        kfdcolor: "#94de71",
        kfowcolor: "#151714",
        kfallcolor: "#151714",
        kfsencolor: "#151714",
        kfmetcolor: "#151714",
        kfecmwfcolor: "#151714",
        kfnasacolor: "#151714",
        kfaiccolor: "#151714",
      });
      ApexCharts.exec("historicaldata", "hideSeries", "ECMWF");
      ApexCharts.exec("historicaldata", "hideSeries", "OpenWeather");
      ApexCharts.exec("historicaldata", "hideSeries", "AIC");
      ApexCharts.exec("historicaldata", "hideSeries", "Meteo Blue");
      ApexCharts.exec("historicaldata", "hideSeries", "Nasa Power Larc");
      ApexCharts.exec("historicaldata", "hideSeries", "Online Sensor");
      //Show current series even if it is hidden
      ApexCharts.exec("historicaldata", "showSeries", "Dark Sky");
    } else if (e.target.id == "ow") {
      this.setState({
        kfowcolor: "#94de71",
        kfdcolor: "#151714",
        kfallcolor: "#151714",
        kfsencolor: "#151714",
        kfmetcolor: "#151714",
        kfecmwfcolor: "#151714",
        kfnasacolor: "#151714",
        kfaiccolor: "#151714",
      });
      ApexCharts.exec("historicaldata", "hideSeries", "ECMWF");
      ApexCharts.exec("historicaldata", "hideSeries", "Dark Sky");
      ApexCharts.exec("historicaldata", "hideSeries", "AIC");
      ApexCharts.exec("historicaldata", "hideSeries", "Meteo Blue");
      ApexCharts.exec("historicaldata", "hideSeries", "Nasa Power Larc");
      ApexCharts.exec("historicaldata", "hideSeries", "Online Sensor");
      //Show current series even if it is hidden
      ApexCharts.exec("historicaldata", "showSeries", "OpenWeather");
    } else if (e.target.id == "aic") {
      this.setState({
        kfaiccolor: "#94de71",
        kfdcolor: "#151714",
        kfowcolor: "#151714",
        kfallcolor: "#151714",
        kfsencolor: "#151714",
        kfmetcolor: "#151714",
        kfecmwfcolor: "#151714",
        kfnasacolor: "#151714",
      });
      ApexCharts.exec("historicaldata", "hideSeries", "ECMWF");
      ApexCharts.exec("historicaldata", "hideSeries", "Dark Sky");
      ApexCharts.exec("historicaldata", "hideSeries", "OpenWeather");
      ApexCharts.exec("historicaldata", "hideSeries", "Meteo Blue");
      ApexCharts.exec("historicaldata", "hideSeries", "Nasa Power Larc");
      ApexCharts.exec("historicaldata", "hideSeries", "Online Sensor");
      //Show current series even if it is hidden
      ApexCharts.exec("historicaldata", "showSeries", "AIC");
    } else if (e.target.id == "nasa") {
      this.setState({
        kfnasacolor: "#94de71",
        kfdcolor: "#151714",
        kfowcolor: "#151714",
        kfallcolor: "#151714",
        kfsencolor: "#151714",
        kfmetcolor: "#151714",
        kfecmwfcolor: "#151714",
        kfaiccolor: "#151714",
      });
      ApexCharts.exec("historicaldata", "hideSeries", "ECMWF");
      ApexCharts.exec("historicaldata", "hideSeries", "Dark Sky");
      ApexCharts.exec("historicaldata", "hideSeries", "OpenWeather");
      ApexCharts.exec("historicaldata", "hideSeries", "AIC");
      ApexCharts.exec("historicaldata", "hideSeries", "Meteo Blue");
      ApexCharts.exec("historicaldata", "hideSeries", "Online Sensor");
      //Show current series even if it is hidden
      ApexCharts.exec("historicaldata", "showSeries", "Nasa Power Larc");
    } else if (e.target.id == "ecmwf") {
      this.setState({
        kfecmwfcolor: "#94de71",
        kfdcolor: "#151714",
        kfowcolor: "#151714",
        kfallcolor: "#151714",
        kfsencolor: "#151714",
        kfmetcolor: "#151714",
        kfnasacolor: "#151714",
        kfaiccolor: "#151714",
      });

      ApexCharts.exec("historicaldata", "hideSeries", "Dark Sky");
      ApexCharts.exec("historicaldata", "hideSeries", "OpenWeather");
      ApexCharts.exec("historicaldata", "hideSeries", "AIC");
      ApexCharts.exec("historicaldata", "hideSeries", "Meteo Blue");
      ApexCharts.exec("historicaldata", "hideSeries", "Nasa Power Larc");
      ApexCharts.exec("historicaldata", "hideSeries", "Online Sensor");
      //Show current series even if it is hidden
      ApexCharts.exec("historicaldata", "showSeries", "ECMWF");
    } else if (e.target.id == "mb") {
      this.setState({
        kfmetcolor: "#94de71",
        kfdcolor: "#151714",
        kfowcolor: "#151714",
        kfallcolor: "#151714",
        kfsencolor: "#151714",
        kfecmwfcolor: "#151714",
        kfnasacolor: "#151714",
        kfaiccolor: "#151714",
      });
      ApexCharts.exec("historicaldata", "hideSeries", "ECMWF");
      ApexCharts.exec("historicaldata", "hideSeries", "Dark Sky");
      ApexCharts.exec("historicaldata", "hideSeries", "OpenWeather");
      ApexCharts.exec("historicaldata", "hideSeries", "AIC");
      ApexCharts.exec("historicaldata", "hideSeries", "Nasa Power Larc");
      ApexCharts.exec("historicaldata", "hideSeries", "Online Sensor");
      //Show current series even if it is hidden
      ApexCharts.exec("historicaldata", "showSeries", "Meteo Blue");
    } else if (e.target.id == "Online") {
      this.setState({
        kfsencolor: "#94de71",
        kfdcolor: "#151714",
        kfowcolor: "#151714",
        kfallcolor: "#151714",
        kfmetcolor: "#151714",
        kfecmwfcolor: "#151714",
        kfnasacolor: "#151714",
        kfaiccolor: "#151714",
      });
      ApexCharts.exec("historicaldata", "hideSeries", "ECMWF");
      ApexCharts.exec("historicaldata", "hideSeries", "Dark Sky");
      ApexCharts.exec("historicaldata", "hideSeries", "OpenWeather");
      ApexCharts.exec("historicaldata", "hideSeries", "AIC");
      ApexCharts.exec("historicaldata", "hideSeries", "Meteo Blue");
      ApexCharts.exec("historicaldata", "hideSeries", "Nasa Power Larc");
      //Show current series even if it is hidden
      ApexCharts.exec("historicaldata", "showSeries", "Online Sensor");
    } else if (e.target.id == "All") {
      this.setState({
        kfallcolor: "#94de71",
        kfdcolor: "#151714",
        kfowcolor: "#151714",
        kfsencolor: "#151714",
        kfmetcolor: "#151714",
        kfecmwfcolor: "#151714",
        kfnasacolor: "#151714",
        kfaiccolor: "#151714",
      });
      ApexCharts.exec("historicaldata", "showSeries", "ECMWF");
      ApexCharts.exec("historicaldata", "showSeries", "Dark Sky");
      ApexCharts.exec("historicaldata", "showSeries", "OpenWeather");
      ApexCharts.exec("historicaldata", "showSeries", "AIC");
      ApexCharts.exec("historicaldata", "showSeries", "Meteo Blue");
      ApexCharts.exec("historicaldata", "showSeries", "Nasa Power Larc");
      ApexCharts.exec("historicaldata", "showSeries", "Online Sensor");
    }
  }
  disabledDate(current) {
    let customDate = moment().format("2021-04-01");
    return current && current < moment(customDate, "YYYY-MM-DD") || current && current > moment().endOf("day");
  }

  render() {
    // console.log("Perilunit",this.state.peril )

    const { t, i18n } = this.props;
    return (
      <React.Fragment>
        <div className="page-content">
          <Container>
            <Card style={{ backgroundColor: "#e5ebee" }}>
              <CardHeader style={{ backgroundColor: "#A4C1D2" }}>
                <Row>
                  <Col>
                    <h4
                      className="card-title"
                      style={{ padding: "5px", fontSize: "18px" }}
                    >
                      {" "}
                      {t("Historical Data")} -{" "}
                      {this.capitalizeFirstLetter(this.state.peril)} -{" "}
                      {this.state.rua}
                    </h4>
                  </Col>
                  <Col>
                    <Link to="/farmerdashboard">
                      <i
                        className="ri-close-fill"
                        style={{ fontSize: "20px", float: "right" }}
                      ></i>
                    </Link>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col xs={12}>
                    <Card>
                      <CardBody>
                        <center>
                          <img
                            src={loader}
                            height={"200px"}
                            style={
                              this.state.showchart ? { display: "none" } : {}
                            }
                          ></img>
                        </center>
                        <div
                          style={
                            this.state.showchart ? {} : { display: "none" }
                          }
                        >
                          <ButtonToolbar>
                            <ButtonGroup size="sm" className="mt-2">
                              <Button
                                color="light"
                                active={this.state.active}
                                onClick={() => this.datechange("one")}
                              >
                                {t("Today")}
                              </Button>
                              <Button
                                color="light"
                                active={this.state.active_week}
                                onClick={() => this.datechange("week")}
                              >
                                {t("Last 7 days")}
                              </Button>
                              <Button
                                color="light"
                                active={this.state.active_month}
                                onClick={() => this.datechange("month")}
                              >
                                {t("Last 30 days")}
                              </Button>
                              {/* <Button
                                color="light"
                                onClick={() => this.datechange("kharif")}
                              >
                                Kharif 2020
                              </Button>
                              <Button
                                color="light"
                                onClick={() => this.datechange("rabi")}
                              >
                                Rabi 2020-21
                              </Button> */}
                            </ButtonGroup>
                            <RangePicker
                              size={"small"}
                              className="hist-date"
                              onChange={this.onChangedate}
                              format="DD-MM-YYYY"
                              disabledDate={this.disabledDate}
                            />
                          </ButtonToolbar>

                          <Chart
                            series={this.state.series}
                            options={this.state.options}
                            type="line"
                            height="310"
                          />

                          <div style={{ "text-align": "center" }}>                         
                            <span
                              class="dot"
                              style={{ "background-color": "#2E93fA" }}
                            ></span>
                            <p
                              id="ow"
                              onClick={this.handleKfilter}
                              style={{
                                cursor: "pointer",
                                display: "inline-block",
                                "font-size": "12px",
                                "font-family": "Segoe UI",
                                paddingRight: "10px",
                                paddingLeft: "4px",
                                color: this.state.kfowcolor,
                              }}
                            >
                              Online Sensor
                            </p>                            
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default withNamespaces()(histDatalayout);
