import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody, Spinner } from "reactstrap";
import DiagnosticADMMap from "../Adm-components/diagnosticADMmap";
import DiagnosticADMTable from "../Adm-components/diagnosticADMtable";
import $ from "jquery";
import validate_Token from "../../validateToken.js";
import { Link } from "react-router-dom";
import { withNamespaces } from 'react-i18next';
import Moment from 'moment';
class SensorDiagnostic extends Component {
  constructor(props) {
    super(props);
    this.state = {
      series: [42, 26, 42],
      options: {
        labels: ["Product A", "Product B", "Product C"],
        plotOptions: {
          pie: {
            donut: {
              size: "50%",
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        colors: ["#5664d2", "#1cbb8c", "#eeb902"],
      },
      livestatus: [],
      mdbtable: [],
      isdata: false,
    };
    this.tabledatacreate = this.tabledatacreate.bind(this);
  }

  componentDidMount() {
    validate_Token();
    let getthis = this;
    $.ajax({
      //first api call
      url: process.env.REACT_APP_APIEND + `weather/hd-current`,
      method: "POST",
      headers: {
        Authorization: "Token " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      success: function (liveweather) {
        // console.log("ADM SENSOR ", liveweather);
        getthis.tabledatacreate(liveweather.result);

        getthis.setState(
          {
            livestatus: liveweather.result,
            isdata: true,
          },
          () => {
            // console.log("LIVEDATA", getthis.state.livestatus);
          }
        );
      },
    });
  }
  tabledatacreate(data) {
    let tabledata = [];
    let statusbadge;
    let gateway;
    data.map(function (item, index, data) {
      if (item.status == true) {
        statusbadge = (
          <div className="badge badge-soft-success font-size-12">Active</div>
        );
      } else {
        statusbadge = (
          <div className="badge badge-soft-danger font-size-12">Inactive</div>
        );
      }
      if (item.station == "kdisc_sci_aws_01") {
        gateway = "GW 1 - Chittur M.O";
      }
      if (item.station == "kdisc_sci_aws_02") {
        gateway = "GW 1";
      }
      if (item.station == "kdisc_sci_aws_03") {
        gateway = "GW 2";
      }
      if (item.station == "kdisc_sci_aws_04") {
        gateway = "GW 2";
      }
      if (item.station == "kdisc_sci_aws_05") {
        gateway = "GW 2";
      }
      if (item.station == "kdisc_sci_aws_06") {
        gateway = "GW 1";
      }
      if (item.station == "kdisc_sci_aws_07") {
        gateway = "GW 1";
      }
      if (item.station == "kdisc_sci_aws_08") {
        gateway = "GW 2 - Bharathamatha";
      }
      if (item.station == "kdisc_sci_aws_09") {
        gateway = "GW 3 - Ahalia";
      }
      if (item.station == "kdisc_sci_aws_10") {
        gateway = "GW 3";
      }
      if (item.station == "kdisc_sci_aws_11") {
        gateway = "GW 4 - Perumatty";
      }
      if (item.station == "kdisc_sci_aws_12") {
        gateway = "GW 4";
      }
      if (item.station == "kdisc_sci_aws_13") {
        gateway = "GW 3";
      }
      if (item.station == "kdisc_sci_aws_14") {
        gateway = "GW 1";
      }
      var newdata = {
        id: index + 1,
        station: item["station"],
        rua: item["rua"],
        gateway: gateway,
        datetime: Moment(item["datetime"]).format('DD-MM-YYYY HH:mm:ss'),
        location: item["location"],
        Battery: <Link to={{ pathname: '/battery-historical-adm',state: {station:item["station"],location:item["location"]} }}><div
        >{parseFloat(item.battery).toFixed(2)}
        </div></Link>,
        status: statusbadge,
      };
      tabledata.push(newdata);
    });
    this.setState({
      mdbtable: tabledata,
    });
  }

  render() {
    const { t, i18n } = this.props
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Row>
              <Col xs={12}>
                <h4
                  className="card-title"
                  style={{ padding: "5px", fontSize: "18px" }}
                >
                  {" "}
                  {t("Sensor Diagnostics - Compensated")}
                  
                </h4>
              </Col>
              <Col xl={4}></Col>
            </Row>

            <Row>
              <Col lg={12}>
                {this.state.isdata ? (
                  <DiagnosticADMMap livedata={this.state.livestatus} />
                ) : (
                  <center>
                    <Spinner color="dark" />
                  </center>
                )}
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    {this.state.isdata ? (
                      <DiagnosticADMTable livedata={this.state.mdbtable} />
                    ) : (
                      <div></div>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default withNamespaces()(SensorDiagnostic);

