import React, { Component } from "react";
import {
  Card,
  CardBody,
  Row,
  Col,
  CardText,
  FormGroup,
  NavLink,
  NavItem,
  Nav,
  Label,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container,
  Button,
  Spinner,
} from "reactstrap";
// import './table.css'

import "antd/dist/antd.css";


import { Tabs, Input, Radio, Checkbox } from "antd";

//import css
import "../../assets/scss/tab.css";
import { DatePicker, Space } from 'antd';

const { RangePicker } = DatePicker;

const TabPane = Tabs.TabPane;
const { TextArea } = Input;
export default function Tm_condition_template() {

  return (
    <>
      <Row>
        <Col>
          <p style={{ fontWeight: "bolder", fontSize: "18px" }}>Basic</p>
          <Row>
            <Col md="2">
              <FormGroup>
                <Label
                  className="col-md-12 col-form-label nextline"
                  style={{ fontSize: "14px" }}
                >
                  <br />
                  Phase
                </Label>
                <Input
                  placeholder="Phase"
                  style={{ width: 100 }}
                  name="Phase"
                />
              </FormGroup>
            </Col>
            <Col md="3">
              <FormGroup>
                <Label
                  className="col-md-12 col-form-label"
                  style={{ fontSize: "14px" }}
                >
                  <br />
                  <br />
               
                </Label>
                <RangePicker />
              </FormGroup>
            </Col>
            <Col md="2">
              <FormGroup>
                <Label
                  className="col-md-12 col-form-label"
                  style={{ fontSize: "14px" }}
                >
                  <span>Phase Maximum<br/> Payout</span>
                </Label>
                <Input
                  placeholder="Phase maximum Payout"
                  style={{ width: 100 }}
                  name="Phase maximum Payout"
                />
              </FormGroup>
            </Col>
            <Col md="2">
              <FormGroup>
                <Label
                  className="col-md-12 col-form-label"
                  style={{ fontSize: "14px" , paddingRight:"0px"}}
                >
                  <span>Season<br />Maximum Payout</span>
                </Label>
                <Input
                  placeholder="Season maximum Payout"
                  style={{ width: 100 }}
                  name="Season maximum Payout"
                />
              </FormGroup>
            </Col>
            <Col md="2">
              <FormGroup>
                <Label
                  className="col-md-12 col-form-label"
                  style={{ fontSize: "14px" }}
                >
                  <br />
                  Unit Cost
                </Label>
                <Input
                  placeholder="Unit Cost"
                  style={{ width: 100 }}
                  name="Unit Cost"
                />
              </FormGroup>
            </Col>
          </Row>
        </Col>
      </Row>
      {/* ---------------------------- */}
      <Row>
        <Col>
          <p style={{ fontWeight: "bolder", fontSize: "18px" }}>Conditions</p>
          <Row>
            <Col md="12">
              {" "}
              <Table id="tsconditions" bordered style={{ fontSize: "11px" }} responsive>
                <thead style={{ backgroundColor: "#ffffff" }}>
                  <tr>
                    <th style={{ width: "100px" }}>ID</th>
                    <th style={{ width: "130px" }}>Condition</th>
                    <th style={{ width: "130px" }}>Index</th>
                    <th style={{ width: "130px" }}>Trigger</th>
                    <th style={{ width: "100px" }}>Strike</th>
                    <th style={{ width: "130px" }}>Exit</th>
                    <th style={{ width: "130px" }}>Notional</th>
                    <th style={{ width: "130px" }}>Fixed</th>
                    <th style={{ width: "130px" }}></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <Input
                        placeholder="ID"
                        style={{ width: 100 }}
                        name="ID"
                      />
                    </td>
                    <td>
                      <Input
                        placeholder="Condition"
                        style={{ width: 100 }}
                        name="Condition"
                      />
                    </td>
                    <td>
                      <Input
                        placeholder="Index"
                        style={{ width: 100 }}
                        name="Index"
                      />
                    </td>
                    <td>
                      <Input
                        placeholder="Trigger"
                        style={{ width: 100 }}
                        name="Trigger"
                      />
                    </td>
                    <td>
                      <Input
                        placeholder="Strike"
                        style={{ width: 100 }}
                        name="Strike"
                      />
                    </td>
                    <td>
                      <Input
                        placeholder="Exit"
                        style={{ width: 100 }}
                        name="Exit"
                      />
                    </td>
                    <td className="alnrighttd">
                      <Input
                        placeholder="Notional"
                        style={{ width: 100 }}
                        name="Notional"
                      />
                    </td>
                    <td className="alnrighttd">
                      <Input
                        placeholder="Fixed"
                        style={{ width: 100 }}
                        name="Fixed"
                      />
                    </td>
                    <td style={{ width: 100 }}>
                      <i
                        className="ri-add-box-fill "
                        style={{ fontSize: "23px" }}
                      />
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </Col>
      </Row>
      {/* ---------------------------- */}
      <Row>
        <Col>
          <p style={{ fontWeight: "bolder", fontSize: "18px", paddingTop:"20px" }}>Others</p>
          <Row>
            <Col md="12">
              <Checkbox>Is Phase End</Checkbox>
              <Checkbox>Is Day End Alert Applicable?</Checkbox>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <FormGroup>
                <Label
                  className="col-md-12 col-form-label"
                  style={{ fontSize: "14px" }}
                >
                  <br />
                  Payout Rules
                </Label>
                <TextArea rows={4} />
              </FormGroup>
            </Col>
            <Col md="5">
              <FormGroup>
                <Label
                  className="col-md-12 col-form-label"
                  style={{ fontSize: "14px" }}
                >
                  <br />
                  Alert Rules
                </Label>
                <TextArea rows={4} />
              </FormGroup>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <Button
            color="primary"
            style={{ "font-size": "10px", float: "right" }}
          >
            Save and Add next phase
          </Button>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col md="12">
          <Button
            color="success"
            style={{ "font-size": "10px", float: "right" }}
          >
            Save and Close
          </Button>
        </Col>
      </Row>
    </>
  );
}
