const settmCloneCropReducer = (state = " ", action) => {
    switch (action.type) {
      case "SETCLONECROP":
        return (state = action.payload);
      default:
        return state;
    }
  };
  
  export default settmCloneCropReducer;
  