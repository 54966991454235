export default function getnallepillystations(){
    const points ={
      "type": "FeatureCollection",
      "name": "nallepilly_stations",
      "crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:OGC:1.3:CRS84" } },
      "features": [
        {
          type: "Feature",
          properties: {
            RUA: "Nallepilly",
            Type: "KrishiBhavan",
            stationName: "kdisc_sci_aws_03",
            tooltipText:"KrishiBhavan",
            Location: "KrishiBhavan",
            "Google Map URL": "https://goo.gl/maps/DZmGbTgp8QBpJt4JA",
            Lat: 10.72918673,
            Long: 76.78622876,
          },
          geometry: { type: "Point", coordinates: [76.78622876, 10.72918673] },
        },
        {
          type: "Feature",
          properties: {
            RUA: "Nallepilly",
            Type: "Extra",
            stationName: "kdisc_sci_aws_07",
            tooltipText:"Government Technical High School chittur",
            Location: "Government Technical High School chittur",
            "Google Map URL": "https://goo.gl/maps/1sAB5vCMH1UQruXT9",
            Lat: 10.70258377,
            Long: 76.75669052000001,
          },
          geometry: { type: "Point", coordinates: [76.75669052, 10.70258377] },
        }
      ]
      }                               
      return points
}