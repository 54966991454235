import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {Redirect} from "react-router-dom"
import { logoutUser } from '../../store/actions';
import { toast } from 'react-toastify';

class Logout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logoutSuccessful: false,
    };
  }
  async logoutuser() {
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: "Token " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    };
    var logoutRes = await fetch(
      process.env.REACT_APP_APIEND + `department/logout`,
      requestOptions
    ).then((res) => {
      return res.json();
    });
    // console.log("LOGOUT RESPONSE", logoutRes);
    if (logoutRes.code == 200) {
      localStorage.clear();
      localStorage.setItem("logoutMessage", logoutRes.message);
      this.setState({ logoutSuccessful: true });
      window.location.href = "/";
    } else {
      toast.error("Something's not right");
    }
  }
  componentDidMount() {
    let user = localStorage.getItem("user_type");
    if (user == "DR") {
      this.logoutuser();
    }
    if (user == "FM") {
      localStorage.clear();
      window.location.href = "/";
    }
    if (user == "ADM") {
      this.logoutuser();
    }
    if (user == "KB") {
      this.logoutuser();
    }

    // Fire Action for Remove all Item from localstorage and redirect to login page
    // this.props.logoutUser(this.props.history);
    // localStorage.removeItem("token")
    // localStorage.removeItem("username")
    // localStorage.clear();
  }

  render() {
    return (
      //  <Redirect to="/" />
      <div></div>
    );
  }
}

export default withRouter(connect(null,{ logoutUser })(Logout));

