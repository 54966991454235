import React, { Component } from "react";
import {
  Table,
  Card,
  CardBody,
  Row,
  Col,
  Container,
  FormGroup,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";
import { MDBDataTable } from "mdbreact";
import { toast } from "react-toastify";
import { Tabs, Input, Radio, Checkbox } from "antd";
toast.configure();
export default class TransactionFarmer extends Component {
  constructor(props) {
    // let querydata=props.tabledata;
    super(props);
    this.state = {
      showtable: false,
      showWaiting: true,
      season: " ",
      seasonData: [],
      perilseasonData: [],
      farmerID: "",
      transactionData: [],
    };
    this.gettransactionData = this.gettransactionData.bind(this);
    this.onChangeID = this.onChangeID.bind(this);
    this.handleSeasonchange = this.handleSeasonchange.bind(this);
    this.generatetable=this.generatetable.bind(this)
  }
  async fetchseason() {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + localStorage.getItem("token"),
      },
    };
    try {
      var seasonData = await fetch(
        process.env.REACT_APP_APIEND + `smart_crop/term-sheet-season-crop`,
        requestOptions
      ).then((res) => {
        return res.json();
      });
      this.setState({
        perilseasonData: seasonData.result,
      });

      var newseasonData = [];
      {
        seasonData.result.map(function (item, index, data) {
          newseasonData.push(Object.keys(item));
        });
      }   
      this.setState({
        seasonData: newseasonData,
        season:newseasonData[0][0]
      });
    } catch (errseason) {
      toast.error("Something's not right");
    }
  }
  componentDidMount() {
    this.fetchseason();
  }
  async gettransactionData() {
    let searchdata = {
      farmer_id: this.state.farmerID,
      season: this.state.season,
    };
    searchdata = JSON.stringify(searchdata);

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + localStorage.getItem("token"),
      },
      body: searchdata,
    };
    try {
      var transactiondata = await fetch(
        process.env.REACT_APP_APIEND + `farmer/smart-contract-id-season`,
        requestOptions
      ).then((res) => {
        return res.json();
      });
    } catch (err) {
      toast.error("Something's not right");
    }
    if (transactiondata.code == 200) {
      this.generatetable(transactiondata.data.result);
    }
  }
  generatetable(data) {
    let transaction_data = [];
    var getthis = this;
    console.log("ITEMSSS", data);
    if(data.metaData!=null && data.metaData!=undefined){
        data.metaData.map(function (item, index) {
            console.log("item.status.govtSubsidyState",item.status.govtSubsidyState);
            var newdata = {
              ID: parseInt(item.id),
              cropRUA: item.cropRUA,
              cropArea: item.cropArea,
              season: item.season,
              crop: item.crop,
              peril:item.peril,
              phase: item.phase,
              dataProvider: item.dataProvider,
              payoutRUA: item.payoutRUA,
              payoutFarmer: item.payoutFarmer,
              createdDate: item.createdDate,
              updatedDate: item.updatedDate,
              WeatherDataVerified: getthis.checkStatus(item.status.WeatherDataVerified),
              govtSubsidyState:  getthis.checkStatus(item.status.govtSubsidyState),
              govtSubsidyCentral:  getthis.checkStatus(item.status.govtSubsidyCentral),
              premiumPaid:  getthis.checkStatus(item.status.premiumPaid),
              payoutStatus:  getthis.checkStatus(item.status.payoutStatus),
            };
            transaction_data.push(newdata);
        });
      
    }else{
        toast.error("Empty Data !");
    }
    
    this.setState({
      transactionData: transaction_data,
      showLoader: false,
      showtable: true,
    });
  }

  checkStatus(status) {
    if (status == true) {
      return (
        <i
          style={{ transform: "translateX(0) translateY(20%)" }}
          className="ri-check-fill"
        ></i>
      );
    } else {
      return (
        <i
          style={{ transform: "translateX(0) translateY(20%)" }}
          className="ri-close-fill"
        ></i>
      );
    }
  }
  onChangeID(e) {
    this.setState({
      farmerID: e.target.value,
    });
  }
  handleSeasonchange(e) {
    this.setState({
      season: e.target.value,
    });
  }
  render() {
    var data = {
      columns: [
        {
          label: "ID",
          field: "ID",
        },
        {
          label: "RUA",
          field: "cropRUA",
        },
        {
          label: "Area",
          field: "cropArea",
        },
        {
          label: "Season",
          field: "season",
        },
        {
          label: "Crop",
          field: "crop",
        },
        {
          label: "Peril",
          field: "peril",
        },
        {
          label: "Phase",
          field: "phase",
        },
        {
          label: "Data Provider",
          field: "dataProvider",
        },
        {
          label: "Payout - RUA",
          field: "payoutRUA",
        },
        {
          label: "Payout - Farmer",
          field: "payoutFarmer",
        },
        {
          label: "Created Date",
          field: "createdDate",
        },
        {
          label: "Updated Date",
          field: "updatedDate",
        },
        {
          label: "Weather Data Verified",
          field: "WeatherDataVerified",
        },
        {
          label: "Govt Subsidy State",
          field: "govtSubsidyState",
        },
        {
          label: "Govt Subsidy Central",
          field: "govtSubsidyCentral",
        },
        {
          label: "Premium Paid",
          field: "premiumPaid",
        },
        {
          label: "Payout Status",
          field: "payoutStatus",
        },
      ],
      rows: this.state.transactionData,
    };
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Row>
              <Col xs={12}>
                <h4
                  className="card-title"
                  style={{ padding: "5px", fontSize: "18px" }}
                >
                  {" "}
                  Transactions - Farmer
                </h4>
              </Col>
            </Row>
            <Row>
              <Col>
                <Card style={{ backgroundColor: "#f1f6fb" }}>
                  <CardBody>
                    <Col>
                      <Row>
                        <Col md="2">
                          <FormGroup>
                            <Label
                              className="col-md-10 col-form-label"
                              style={{ fontSize: "14px" }}
                            >
                              Farmer ID{" "}
                            </Label>
                            <Input
                              style={{ width: 100 }}
                              name="farmerID"
                              onChange={this.onChangeID}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="2">
                          <FormGroup>
                            <Label
                              className="col-md-10 col-form-label"
                              style={{ fontSize: "14px" }}
                            >
                              Season{" "}
                            </Label>
                            <select
                              className="form-select"
                              style={{ fontSize: "11px" }}
                               defaultValue={this.state.seasonData[0]}
                              onChange={this.handleSeasonchange}
                              name="season"
                            >
                              {/* <option value="all">All</option> */}
                              {this.state.seasonData.length > 0 &&
                                this.state.seasonData.map((item) => (
                                  <option value={item} key={item}>
                                    {item}
                                  </option>
                                ))}
                            </select>
                          </FormGroup>
                        </Col>
                        <Col md="1">
                          <FormGroup>
                            <Label
                              className="col-md-12 col-form-label"
                              style={{ fontSize: "14px" }}
                            >
                              <span>&nbsp;</span>
                            </Label>
                            <Button
                              color="primary"
                              className="waves-effect waves-light mr-1"
                              style={{ fontSize: "11px", float: "right" }}
                              onClick={this.gettransactionData}
                            >
                              Search
                            </Button>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <center>
                      <p
                        style={
                          this.state.showWaiting ? {} : { display: "none" }
                        }
                      >
                        Waiting for search
                      </p>
                      <Spinner
                        style={this.state.showloader ? {} : { display: "none" }}
                      ></Spinner>
                    </center>
                    <div
                      id="payout-transaction"
                      style={this.state.showtable ? {} : { display: "none" }}
                    >
                      <MDBDataTable
                        responsive
                        paging={true}
                        bordered
                        data={data}
                        className="datadownloadtable"
                        style={{ fontSize: "12px" }}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
