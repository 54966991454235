import React, { Component } from "react";
import {
  Card,
  CardBody,
  Row,
  Col,
  CardText,
  FormGroup,
  NavLink,
  NavItem,
  Nav,
  Label,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container,
  Button,
  Spinner,
} from "reactstrap";
import { Link } from "react-router-dom";
import "antd/dist/antd.css";
import validate_Token from "../../validateToken.js";
import { MDBDataTable } from "mdbreact";
import moment from "moment";
//import css
import "../../assets/scss/tab.css";
import { toast } from "react-toastify";
import "./css/tsconditions.css";
import Moment from "moment";
import { withNamespaces } from "react-i18next";
toast.configure();
class FarmerSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal_center: false,
      modal_center1: false,
      modal_delete: false,
      modal_condition: false,
      disabledPeril: true,
      conditions_list: [],
      current_perildelete: "",
      disablegetcondition: true,
      perilData: [
        "Disease Congenial Climate",
        "Rise in Temperature",
        "Excess Rainfall",
      ],
      cropData: ["Paddy"],
      seasonData: [],
      selectedseason: "Kharif 2020-21",
      selectedperil: "all",
      crop: "",
      conditions: [],
      returnperil: "",
      tabledata: "",
      showmessage: true,
      showloader: false,
      phasedata: [],
      rules: [],
    };
    this.handleSeasonchange = this.handleSeasonchange.bind(this);
    this.handleCropchange = this.handleCropchange.bind(this);
    this.getconditions = this.getconditions.bind(this);
    this.handlePerilchange = this.handlePerilchange.bind(this);
    this.seerules = this.seerules.bind(this);
    this.tog_center = this.tog_center.bind(this);
    this.update_rules = this.update_rules.bind(this);
    this.deletecondition = this.deletecondition.bind(this);
    this.seeconditions = this.seeconditions.bind(this);
    this.tog_conditions = this.tog_conditions.bind(this);
  }
  tog_center() {
    this.setState((prevState) => ({
      modal_center: !prevState.modal_center,
    }));
  }
  update_rules() {
    this.setState((prevState) => ({
      modal_center1: !prevState.modal_center1,
    }));
  }
  tog_conditions() {
    this.setState((prevState) => ({
      modal_condition: !prevState.modal_condition,
    }));
  }
  async fetchseason() {
    // document.getElementById("cropselection").selectedIndex = "1";
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + localStorage.getItem("token"),
      },
    };

    var seasonData = await fetch(
      process.env.REACT_APP_APIEND + `smart_crop/term-sheet-season-crop`,
      requestOptions
    ).then((res) => {
      return res.json();
    });
    this.setState({
      perilseasonData: seasonData.result,
    });
    var newseasonData = [];
    var newcropdata = [];
    {
      seasonData.result.map(function (item, index, data) {
        newseasonData.push(Object.keys(item));
      });
    }
    this.setState({
      seasonData: newseasonData,
      cropData: seasonData.result[0][Object.keys(seasonData.result[0])],
      selectedseason: newseasonData[0][0],
      // crop: seasonData.result[0][Object.keys(seasonData.result[0])][0],
    });
  }
  handleSeasonchange(e) {
    this.setState({
      season: e.target.value,
      selectedseason: e.target.value,
    });
    this.setState({
      cropData: [],
    });
    let selected_index = e.target.selectedIndex;
    let season = e.target.value;
    console.log(
      "SEASON DATA & CROP DATA",
      this.state.perilseasonData[selected_index][
        Object.keys(this.state.perilseasonData[selected_index])
      ][0]
    );
    this.setState({
      cropData:
        this.state.perilseasonData[selected_index][
          Object.keys(this.state.perilseasonData[selected_index])
        ],
      // crop: this.state.perilseasonData[selected_index][
      //   Object.keys(this.state.perilseasonData[selected_index])
      // ][0],
    });
  }
  async handleCropchange(e) {
    this.setState({
      crop: e.target.value,
    });
    let searchdata = {
      season: this.state.selectedseason,
      crop: e.target.value,
    };
    searchdata = JSON.stringify(searchdata);

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + localStorage.getItem("token"),
      },
      body: searchdata,
    };
    var perildata = await fetch(
      process.env.REACT_APP_APIEND + `smart_crop/get-perils`,
      requestOptions
    ).then((res) => {
      return res.json();
    });
    var newperils = perildata.result[0].perils;
    console.log("PERIL DATA", newperils);
    this.setState({
      perilData: newperils,
      selectedperil: newperils[0],
      disabledPeril: false,
      disablegetcondition: false,
    });
  }
  handlePerilchange(e) {
    this.setState({
      selectedperil: e.target.value,
    });
    // if (this.state.crop != "") {
    //   this.setState({
    //     disablegetcondition: false,
    //   });
    // } else {

    // }
  }
  async getconditions() {
    if (this.state.crop != "" && this.state.peril != "") {
      this.setState({
        showloader: true,
        showmessage: false,
      });
      let searchdata = {
        crop: this.state.crop,
        peril: this.state.selectedperil,
        season: this.state.selectedseason,
      };
      searchdata = JSON.stringify(searchdata);

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + localStorage.getItem("token"),
        },
        body: searchdata,
      };
      try {
        var conditiondata = await fetch(
          process.env.REACT_APP_APIEND + `smart_crop/term-sheet-condition/term-condition`,
          requestOptions
        ).then((res) => {
          return res.json();
        });
        if (conditiondata.code == 200) {
          this.setState({
            showloader: false,
          });
        }
        var phase = conditiondata.result[0];
        var peril_name = Object.keys(phase);
        var phasedata = phase[peril_name].phase_data;

        this.setState({
          conditions: conditiondata.result,
          phasedata: phasedata,
        });
      } catch (err) {
        toast.error("Something's not right");
      }
    } else {
      toast.error("Invalid crop and peril !");
    }
  }
  checknull(value) {
    if (value != null && value > 0) {
      return parseFloat(value).toFixed(2);
    } else if (value == "true") {
      return "True";
    } else if (value == "false") {
      return "False";
    } else {
      return "0.00";
    }
  }
  spellcheckperil(peril) {
    if (peril == "High Temperature") {
      return "High Temperature";
    }
    if (peril == "Deficit Rainfall") {
      return "Deficit Rainfall";
    }
    if (peril == "Disease Congenial Climate") {
      return "Disease Congenial Climate";
    }
    if (peril == "Dry Spell") {
      return "Dry Spell";
    }
    if (peril == "Excess Rainfall") {
      return "Excess Rainfall";
    }
    if (peril == "Rise in Temperature") {
      return "Rise in Temperature";
    } else {
      return peril;
    }
  }
  seerules(data) {
    // console.log("RULES", rules);
    if (data.is_daily == "N" && data.is_phase == "Y") {
      this.setState({
        rules: data.rule,
      });
    }
    if (data.is_daily == "Y" && data.is_phase == "N") {
      this.setState({
        rules: data.daily_rule,
      });
    }
  }
  checkphase(phase) {
    if (phase == "1") {
      return "Phase 1";
    }
    if (phase == "2") {
      return "Phase 2";
    }
    if (phase == "3") {
      return "Phase 3";
    }
    if (phase == "4") {
      return "Phase 4";
    }
    if (phase == "5") {
      return "Phase 5";
    }
  }

  seeconditions(conditions) {
    this.setState({
      conditions_list: conditions,
    });
  }
  componentDidMount() {
    validate_Token();
    this.fetchseason();
  }
  addconditions() {
    console.log("CLICK ADD CONDITION");
  }
  deletecondition() {
    this.setState({
      modal_delete: true,
      // current_perildelete:this.spellcheckperil(peril)
    });
  }
  checkconditionList(list, params) {
    if (list != null) {
      if (params == "id") {
        return list.id;
      }
      if (params == "condition") {
        return list.condition;
      }
      if (params == "index") {
        return list.index;
      }
      if (params == "trigger_value") {
        return list.trigger_value;
      }
      if (params == "strike") {
        return list.strike;
      }
      if (params == "exit_value") {
        return list.exit_value;
      }
      if (params == "notional_payout") {
        return list.notional_payout;
      }
      if (params == "fixed_payout") {
        return list.fixed_payout;
      }
      if (params == "parameter") {
        return list.parameter;
      }
    }
  }
  tog_delete() {
    this.setState((prevState) => ({
      modal_delete: !prevState.modal_small,
    }));
  }
  render() {
    const { t, i18n } = this.props;
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Row>
              <Col xs={12}>
                <h4
                  className="card-title"
                  style={{ padding: "5px", fontSize: "18px" }}
                >
                  {" "}
                  {t("Termsheet Conditions")}
                </h4>
              </Col>
            </Row>
            <Row>
              <Col>
                <Card style={{ backgroundColor: "#f1f6fb" }}>
                  <CardBody>
                    <Col>
                      <Row>
                        <div>
                          <Link
                            to={{
                              pathname: "/tm_add",
                              state: {
                                seasonData: this.state.seasonData,
                                cropData: this.state.cropData,
                                perilData: this.state.perilData,
                              },
                            }}
                            className="btn btn-success mb-2 btn-adduser"
                            style={{ fontSize: "11px" }}
                          >
                            <i className="mdi mdi-plus mr-2"></i> {t("Add Condition")}
                          </Link>
                        </div>
                        <Col md="3">
                          <FormGroup>
                            <Label className="col-sm col-form-label" style={{ fontSize:"14px"}}>
                              {t("Season")}
                            </Label>
                            <select
                              className="form-select"
                              style={{ fontSize: "11px" }}
                              onChange={this.handleSeasonchange}
                              defaultValue={this.state.selectedseason}
                            >
                              {/* <option value="all">All</option> */}
                              {this.state.seasonData.length > 0 &&
                                this.state.seasonData.map((item) => (
                                  <option value={item} key={item}>
                                    {item}
                                  </option>
                                ))}
                            </select>
                          </FormGroup>
                        </Col>

                        <Col md="3">
                          <FormGroup>
                            <Label className="col-sm col-form-label" style={{ fontSize:"14px"}}>
                              {t("Crop")}
                            </Label>
                            <select
                              className="form-select"
                              style={{ fontSize: "11px" }}
                              onChange={this.handleCropchange}
                              selectedIndex={0}
                              id="cropselection"
                              // defaultValue={this.state.cropData[0]}
                            >
                              <option defaultChecked>Select Crop</option>
                              {this.state.cropData.length > 0 &&
                                this.state.cropData.map((item) => (
                                  <option value={item} key={item}>
                                    {item}
                                  </option>
                                ))}
                            </select>
                          </FormGroup>
                        </Col>

                        <Col md="3">
                          <Label className=" col-sm col-form-label" style={{ fontSize:"14px"}}>
                            {t("Perils")}
                          </Label>
                          <select
                            className="form-select"
                            style={{ fontSize: "11px" }}
                            onChange={this.handlePerilchange}
                            value={this.state.selectedperil}
                            disabled={this.state.disabledPeril}
                          >
                            <option defaultChecked>Select Peril</option>
                            {this.state.perilData.length > 0 &&
                              this.state.perilData.map((item) => (
                                <option value={item} key={item}>
                                  {item}
                                </option>
                              ))}
                            <option value="all">All</option>
                          </select>
                        </Col>

                        <Col md="2">
                          <FormGroup>
                            <Label
                              className="col-md-12 col-form-label"
                              style={{ fontSize: "14px" }}
                            >
                              <span>&nbsp;</span>
                            </Label>
                            <Button
                              color="primary"
                              className="waves-effect waves-light mr-1"
                              style={{
                                fontSize: "11px",
                                float: "right",
                              }}
                              disabled={this.state.disablegetcondition}
                              onClick={this.getconditions}
                            >
                              {t("Get Condition")}
                            </Button>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <center>
                      <p
                        style={
                          this.state.showmessage ? {} : { display: "none" }
                        }
                      >
                        {t("Waiting for search")}
                      </p>
                      <div
                        style={this.state.showloader ? {} : { display: "none" }}
                      >
                        <center>
                          <Spinner color="dark" />
                        </center>
                      </div>
                      {this.state.conditions.length > 0 &&
                        this.state.conditions.map((item, index, data) => (
                          <div class="accordion" id="accordionExample">
                            <div class="accordion-item">
                              <a class="accordion-header" id="headingTwo">
                                <button
                                  class="accordion-button collapsed"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target={
                                    "#collapsecrop" + String(index + 1)
                                  }
                                  aria-expanded="false"
                                  aria-controls="collapsecrop"
                                >
                                  <a
                                    style={{
                                      fontSize: "12px",
                                      paddingRight: "3px",
                                    }}
                                  >
                                    {this.spellcheckperil(Object.keys(item))}
                                  </a>
                                  <div className="tm-conditions-btns">
                                    <Link
                                      to={{
                                        pathname: "/tm_update",
                                        state: {
                                          data: item[Object.keys(item)]
                                            .phase_data,
                                        },
                                      }}
                                    >
                                      <a
                                        className="edit-condition"
                                        style={{
                                          fontSize: "12px",
                                          // position: "absolute",
                                        }}
                                      >
                                        <div className="btn-edit-conditon">
                                          Edit
                                        </div>
                                      </a>
                                    </Link>
                                    <Link
                                      to={{
                                        pathname: "/tm_clone",
                                        state: {
                                          data: item[Object.keys(item)]
                                            .phase_data,
                                        },
                                      }}
                                    >
                                      <a
                                        className="clone-condition"
                                        style={{
                                          fontSize: "15px",
                                          // position: "absolute",
                                        }}
                                      >
                                        <div className="btn-clone-conditon">
                                          Clone
                                        </div>
                                      </a>
                                    </Link>
                                    <Link>
                                      <a
                                        className="delete-condition"
                                        style={{
                                          fontSize: "12px",
                                          // position: "absolute",
                                          right: "15%",
                                        }}
                                        onClick={this.deletecondition}
                                      >
                                        <div className="btn-delete-conditon">
                                          Delete
                                        </div>
                                      </a>
                                    </Link>
                                  </div>
                                </button>
                              </a>
                              <div
                                id={"collapsecrop" + String(index + 1)}
                                class="accordion-collapse collapse"
                                aria-labelledby="headingTwo"
                                data-bs-parent="#accordionExample"
                              >
                                <div class="accordion-body">
                                  <Table
                                    id="tsconditions"
                                    bordered
                                    style={{ fontSize: "11px" }}
                                  >
                                    <thead
                                      style={{ backgroundColor: "#ffffff" }}
                                    >
                                      <tr>
                                        <th style={{ width: "130px" }}>
                                          Phase
                                        </th>
                                        <th style={{ width: "130px" }}>
                                          Peril
                                        </th>
                                        <th style={{ width: "130px" }}>
                                          From Date
                                        </th>
                                        <th style={{ width: "130px" }}>
                                          To Date
                                        </th>
                                        <th style={{ width: "130px" }}>
                                          Unit Cost
                                        </th>
                                        <th style={{ width: "130px" }}>
                                          Phase Maximum Payout
                                        </th>
                                        {/* <th style={{ width: "130px" }}>
                                          Is Daily
                                        </th>
                                        <th style={{ width: "130px" }}>
                                          Is Phase
                                        </th> */}
                                        <th style={{ width: "130px" }}>
                                          Is Peril End
                                        </th>
                                       
                                        <th style={{ width: "130px" }}>
                                          Season Maximum Payout
                                        </th>
                                        <th style={{ width: "130px" }}>
                                          Condition
                                        </th>
                                        {/* <th style={{ width: "130px" }}>
                                          Rules
                                        </th> */}
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {/* {console.log("ITEM LOG", item)} */}
                                      {item[Object.keys(item)].phase_data.map(
                                        (phase) => (
                                          <tr>
                                            <td>
                                              {this.checkphase(
                                                phase[Object.keys(phase)].phase
                                              )}
                                            </td>
                                            <td>
                                              {phase[Object.keys(phase)].peril}
                                            </td>
                                            <td>
                                              {moment(
                                                phase[Object.keys(phase)]
                                                  .startDate
                                              ).format("DD-MM-YYYY")}
                                            </td>
                                            <td>
                                              {moment(
                                                phase[Object.keys(phase)]
                                                  .endDate
                                              ).format("DD-MM-YYYY")}
                                            </td>
                                            <td className="alnrighttd">
                                              {this.checknull(
                                                phase[Object.keys(phase)]
                                                  .unit_cost
                                              )}
                                            </td>
                                            <td className="alnrighttd">
                                              {this.checknull(
                                                phase[Object.keys(phase)]
                                                  .phase_maximum_payout
                                              )}
                                            </td>
                                            {/* <td>
                                              {String(
                                                phase[Object.keys(phase)]
                                                  .is_daily
                                              )}
                                            </td>
                                            <td>
                                              {String(
                                                phase[Object.keys(phase)]
                                                  .is_phase
                                              )}
                                            </td> */}
                                            <td>
                                              {this.checknull(
                                                String(
                                                  phase[Object.keys(phase)]
                                                    .is_peril_phase_end
                                                )
                                              )}
                                            </td>
                                           
                                            <td className="alnrighttd">
                                              {this.checknull(
                                                phase[Object.keys(phase)]
                                                  .max_payout
                                              )}
                                            </td>
                                            <td
                                              onClick={() =>
                                                this.seeconditions(
                                                  phase[Object.keys(phase)]
                                                    .condition
                                                )
                                              }
                                            >
                                              <Link
                                                to="#"
                                                onClick={this.tog_conditions}
                                              >
                                                See conditions
                                              </Link>
                                            </td>
                                            {/* <td
                                              onClick={() =>
                                                this.seerules(
                                                  phase[Object.keys(phase)]
                                                )
                                              }
                                            >
                                              <Link
                                                to="#"
                                                onClick={this.tog_center}
                                              >
                                                See rules
                                              </Link>
                                            </td> */}
                                          </tr>
                                        )
                                      )}
                                    </tbody>
                                  </Table>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                    </center>
                  </CardBody>
                </Card>
                <Modal
                  isOpen={this.state.modal_center1}
                  toggle={this.update_rules}
                  centered={true}
                  backdrop="static"
                >
                  <ModalHeader
                    toggle={() => this.setState({ modal_center1: false })}
                  >
                    Update Rules
                  </ModalHeader>
                  <ModalBody
                    style={{
                      maxHeight: "calc(100vh - 210px)",
                      overflowY: "auto",
                    }}
                  ></ModalBody>
                </Modal>
              </Col>
            </Row>
            <Modal
              isOpen={this.state.modal_center}
              toggle={this.tog_center}
              centered={true}
              backdrop="static"
            >
              <ModalHeader
                toggle={() => this.setState({ modal_center: false })}
              >
                Rules
              </ModalHeader>
              <ModalBody
                style={{
                  maxHeight: "calc(100vh - 210px)",
                  overflowY: "auto",
                }}
              >
                <pre>{JSON.stringify(this.state.rules, null, 2)}</pre>
              </ModalBody>
            </Modal>
            <Modal
              isOpen={this.state.modal_center1}
              toggle={this.update_rules}
              centered={true}
              backdrop="static"
            >
              <ModalHeader
                toggle={() => this.setState({ modal_center1: false })}
              >
                Update Rules
              </ModalHeader>
              <ModalBody
                style={{
                  maxHeight: "calc(100vh - 210px)",
                  overflowY: "auto",
                }}
              ></ModalBody>
            </Modal>
            {/* ==================================DELETE MODAL=========================================== */}
            <Modal
              size="sm"
              isOpen={this.state.modal_delete}
              toggle={this.tog_delete}
              backdrop="static"
            >
              <ModalHeader
                toggle={() => this.setState({ modal_delete: false })}
                style={{ border: "none", backgroundColor: "#A4C1D2" }}
              >
                Delete Condition
              </ModalHeader>
              <ModalBody
                style={{
                  maxHeight: "calc(100vh - 210px)",
                  overflowY: "auto",
                }}
              >
                Are you sure you want to delete?
              </ModalBody>
              <ModalFooter style={{ border: "none" }}>
                <Button
                  type="button"
                  color="light"
                  onClick={() => this.setState({ modal_delete: false })}
                >
                  Cancel
                </Button>
                <Button
                  type=""
                  color="primary"
                  //   onClick={this.confirmUserDelete}
                >
                  Delete
                </Button>
              </ModalFooter>
            </Modal>
            <Modal
              isOpen={this.state.modal_condition}
              toggle={this.tog_conditions}
              centered={true}
              backdrop="static"
              size="lg"
            >
              <ModalHeader
                toggle={() => this.setState({ modal_condition: false })}
              >
                Conditions
              </ModalHeader>

              <ModalBody
                style={{
                  maxHeight: "calc(100vh - 210px)",
                  overflowY: "auto",
                }}
              >
                <Table id="tsconditions" bordered style={{ fontSize: "11px" }}>
                  <thead style={{ backgroundColor: "#ffffff" }}>
                    <tr>
                      <th style={{ width: "130px" }}>ID</th>
                      <th style={{ width: "130px" }}>Conditions</th>
                      <th style={{ width: "130px" }}>Index</th>
                      <th style={{ width: "130px" }}>Trigger</th>
                      <th style={{ width: "130px" }}>Strike</th>
                      <th style={{ width: "130px" }}>Exit Value</th>
                      <th style={{ width: "130px" }}>Notional Payout</th>
                      <th style={{ width: "130px" }}>Fixed Payout</th>
                      <th style={{ width: "130px" }}>Parameter</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.conditions_list.map((item, index) => (
                      <tr>
                        <td>{this.checkconditionList(item, "id")}</td>
                        <td>{this.checkconditionList(item, "condition")}</td>
                        <td>{this.checkconditionList(item, "index")}</td>
                        <td>
                          {this.checkconditionList(item, "trigger_value")}
                        </td>
                        <td>{this.checkconditionList(item, "strike")}</td>
                        <td>{this.checkconditionList(item, "exit_value")}</td>
                        <td>
                          {this.checkconditionList(item, "notional_payout")}
                        </td>
                        <td>{this.checkconditionList(item, "fixed_payout")}</td>
                        <td>{this.checkconditionList(item, "parameter")}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                {/* <pre>{JSON.stringify(this.state.conditions_list, null, 2)}</pre> */}
              </ModalBody>
            </Modal>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default withNamespaces()(FarmerSearch);
