import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";

// Authentication Module
import Account from "./auth/register/reducer";
import Login from "./auth/login/reducer";
import Forget from "./auth/forgetpwd/reducer";
import TmCloneCrop from "./termsheetConditions/termsheetCloneCrop";
import TmCloneSeason from "./termsheetConditions/termsheetCloneSeason";
import TmClonePeril from "./termsheetConditions/termsheetClonePeril";
import TmCloneExec from "./termsheetConditions/termsheetExec";
const rootReducer = combineReducers({
  // public
  Layout,
  TmCloneCrop,
  TmCloneExec,
  TmCloneSeason,
  TmClonePeril,
  // Authentication
  Account,
  Login,
  Forget,
});

export default rootReducer;
