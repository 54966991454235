import React, { Component } from "react";
import {
  Card,
  CardBody,
  Row,
  Col,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container,
  Button,
} from "reactstrap";
// import './table.css'
import { MDBDataTable } from "mdbreact";
import "antd/dist/antd.css";
import { AvForm } from "availity-reactstrap-validation";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import validate_Token from "../../validateToken.js";
import { withNamespaces } from 'react-i18next';
toast.configure();

class Sensorconfig extends Component {
  constructor(props) {
    super(props);
    this.state = {
      config_data: [],
      modal_center: false,
      temperature: false,
      humidity: false,
      pressure: false,
      rainfall: false,
      uvi: false,
      wind_d: false,
      wind_s: false,
      location: "",
      station_name: "",
      allselect: false,
    };
    this.settabledata = this.settabledata.bind(this);
    this.handleupdate = this.handleupdate.bind(this);
    this.handleCheck = this.handleCheck.bind(this);
    this.updatestatus = this.updatestatus.bind(this);
    this.handletempCheck = this.handletempCheck.bind(this);
    this.handlehumCheck = this.handlehumCheck.bind(this);
    this.handlepressureCheck = this.handlepressureCheck.bind(this);
    this.handlerainCheck = this.handlerainCheck.bind(this);
    this.handleuviCheck = this.handleuviCheck.bind(this);
    this.handlewinddCheck = this.handlewinddCheck.bind(this);
    this.handleallCheck = this.handleallCheck.bind(this);
    this.handlewindsCheck = this.handlewindsCheck.bind(this);
  }
  handleallCheck() {
    if (this.state.allselect == false) {
      this.setState({
        allselect: true,
        temperature: true,
        humidity: true,
        pressure: true,
        rainfall: true,
        uvi: true,
        wind_d: true,
        wind_s: true,
      });
    }
    if (this.state.allselect == true) {
      this.setState({
        allselect: false,
        temperature: false,
        humidity: false,
        pressure: false,
        rainfall: false,
        uvi: false,
        wind_d: false,
        wind_s: false,
      });
    }
  }
  handleupdate(data, station) {
    this.tog_center();
    this.setState({
      location: data.location,
      station_name: station,
    });
    // console.log("TEMPERATURE", data.Temperature);
    /////TEMPERATURE
    if (data.Temperature == true) {
      this.setState({
        temperature: true,
      });
    } else {
      this.setState({
        temperature: false,
      });
    }
    ////HUMIDITY CHECK
    if (data.Humidity == true) {
      this.setState({
        humidity: true,
      });
    } else {
      this.setState({
        humidity: false,
      });
    }
    //////PRESSURE CHECK
    if (data.Pressure == true) {
      this.setState({
        pressure: true,
      });
    } else {
      this.setState({
        pressure: false,
      });
    }
    ////RAINFALL
    if (data.Rainfall == true) {
      this.setState({
        rainfall: true,
      });
    } else {
      this.setState({
        rainfall: false,
      });
    }

    ///WINDDIRECTION
    if (data.Wind_Direction == true) {
      this.setState({
        wind_d: true,
      });
    } else {
      this.setState({
        wind_d: false,
      });
    }
    //////WIND SPEED
    if (data.Wind_Speed == true) {
      this.setState({
        wind_s: true,
      });
    } else {
      this.setState({
        wind_s: false,
      });
    }
    /////UVI
    if (data.UV_Index == true) {
      this.setState({
        uvi: true,
      });
    } else {
      this.setState({
        uvi: false,
      });
    }
  }

  tog_center() {
    this.setState((prevState) => ({
      modal_center: !prevState.modal_center,
    }));
  }

  checkstatus(status) {
    if (status == true) {
      return (
        <i
          style={{ transform: "translateX(0) translateY(20%)" }}
          className="ri-check-fill"
        ></i>
      );
    }
    if (status == false) {
      return (
        <i
          style={{ transform: "translateX(0) translateY(20%)" }}
          className="ri-close-fill alnvertical"
        ></i>
      );
    }
  }
  handletempCheck() {
    this.setState({
      temperature: !this.state.temperature,
    });
  }
  handlerainCheck() {
    this.setState({
      rainfall: !this.state.rainfall,
    });
  }
  handlehumCheck() {
    this.setState({
      humidity: !this.state.humidity,
    });
  }
  handlepressureCheck() {
    this.setState({
      pressure: !this.state.pressure,
    });
  }
  handleuviCheck() {
    this.setState({
      uvi: !this.state.uvi,
    });
  }
  handlewinddCheck() {
    this.setState({
      wind_d: !this.state.wind_d,
    });
  }
  handlewindsCheck() {
    this.setState({
      wind_s: !this.state.wind_s,
    });
  }
  handleCheck(ev) {
    var getthis = this;
    getthis.setState({
      [ev.target.value]: [ev.target.checked],
    });
    // console.log([ev.target.value], [ev.target.checked]);
    // console.log("STATUS", this.state.temperature);
    if ([ev.target.value] == "allselect") {
      // console.log("ALL SELECT CLICK");
      getthis.setState(
        {
          temperature: !this.state.temperature,
          humidity: !this.state.humidity,
          pressure: !this.state.pressure,
          rainfall: !this.state.rainfall,
          uvi: !this.state.uvi,
          wind_d: !this.state.wind_d,
          wind_s: !this.state.wind_s,
        },
        () => {
          console.log(
            this.state.temperature,
            this.state.humidity,
            this.state.pressure,
            this.state.rainfall,
            this.state.uvi,
            this.state.wind_d,
            this.state.wind_s
          );
        }
      );
    }
  }
  settabledata(data) {
    let tabledata = [];
    var getthis = this;
    data.map(function (item, index, data) {
      var keyvar = Object.keys(item);
      var key = item[keyvar];
      var newdata = {
        id: Object.keys(item),
        location: <p className="alnleft">{key.location}</p>,
        temperature: getthis.checkstatus(key.Temperature),
        rh: getthis.checkstatus(key.Humidity),
        pressure: getthis.checkstatus(key.Pressure),
        wind_D: getthis.checkstatus(key.Wind_Direction),
        wind_speed: getthis.checkstatus(key.Wind_Speed),
        rain: getthis.checkstatus(key.Rainfall),
        uvi: getthis.checkstatus(key.UV_Index),
        update: (
          <Link onClick={() => getthis.handleupdate(key, Object.keys(item))}>
            <div>
              <i className="ri-pencil-line "></i>
              {/* <UncontrledTooltip placement="top">
            Edit
          </UncontrolledTooltip> */}
            </div>
          </Link>
        ),
      };
      tabledata.push(newdata);
    });

    this.setState({
      config_data: tabledata,
    });
  }

  async updatestatus() {
    // console.log("Current Status", this.state);
    let bodydata = {
      station: this.state.station_name[0],
      humidity: this.state.humidity,
      pressure: this.state.pressure,
      rainfall: this.state.rainfall,
      temperature: this.state.temperature,
      uv_index: this.state.uvi,
      wind_direction: this.state.wind_d,
      wind_speed: this.state.wind_s,
    };

    bodydata = JSON.stringify(bodydata);
    // console.log("SEARCHDATA", bodydata);
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: "Token " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      body: bodydata,
    };

    var compensate_status = await fetch(
      process.env.REACT_APP_APIEND + `weather/compensate-status`,
      requestOptions
    ).then((res) => {
      return res.json();
    });
    // console.log("UPDATE API STATUS CODE", compensate_status.code);
    if (compensate_status.code == 200) {
      toast.success("Updated Successfully!");
    } else {
      toast.error("Error");
    }
    this.getdata();
  }

  async getdata() {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + localStorage.getItem("token"),
      },
    };

    var config_status = await fetch(
      process.env.REACT_APP_APIEND + `weather/get-compensate-status`,
      requestOptions
    ).then((res) => {
      return res.json();
    });
    // console.log("CONFIG STATUS", config_status);

    this.settabledata(config_status.result);
  }
  componentDidMount() {
    validate_Token();
    this.getdata();
  }
  render() {
    const { t, i18n } = this.props
    let data = {
      columns: [
        {
          label: "Station ID",
          field: "id",
          width: 150
        },
        {
          label: "Location",
          field: "location",
          width: 150
        },

        {
          label: "Temperature",
          field: "temperature",
          width: 150
        },
        {
          label: "Relative Humidity",
          field: "rh",
          width: 150
        },
        {
          label: "Pressure",
          field: "pressure",
          width: 150
        },
        {
          label: "Wind Direction",
          field: "wind_D",
          width: 150
        },
        {
          label: "Wind Speed",
          field: "wind_speed",
          width: 150
        },
        {
          label: "Rainfall",
          field: "rain",
          width: 150
        },
        {
          label: "UVI",
          field: "uvi",
          width: 150
        },
        {
          label: "Update",
          field: "update",
          width: 150
        },
      ],
      rows: this.state.config_data,
    };
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Row>
              <Col xs={12}>
                <h4
                  className="card-title"
                  style={{ padding: "5px", fontSize: "18px" }}
                >
                  {" "}
                  {t("Data Compensation Configuration")}
                  
                </h4>
              </Col>
            </Row>
            <Row>
              <Col>
                <Card>
                  <CardBody>
                    <Col>
                      <Row>
                        <Col md="12">
                          <div id="datacompensated" style={{fontSize:"12px"}}>
                            <MDBDataTable
                              paging={false}
                              showPaginationBottom={false}
                              bordered
                              scrollX
                              data={data}
                              order={["id", "asc"]}
                              className="datadownloadtable vertical-center"
                              maxHeight="300px"
                            />
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>

          <Modal
            isOpen={this.state.modal_center}
            toggle={this.tog_center}
            backdrop="static"
            centered
            size="sm"
          >
            <ModalHeader
              toggle={() => this.setState({ modal_center: false })}
              style={{ backgroundColor: "#A4C1D2" }}
            >
              Select to Compensate
            </ModalHeader>
            {/* <ModalBody style={{ marginLeft: "10%" }}> */}
            <ModalBody style={{ marginLeft: "5%" }}>
              <p style={{ fontSize: 17 }}>
                {this.state.station_name} | {this.state.location}
              </p>
              <hr className="mt-1" />
              <AvForm>
                <div class="row">
                  <div className="form-check form-check-right mb-1">
                    <div className="col">
                      {" "}
                      <Input
                        className="form-check-input"
                        type="checkbox"
                        id="defaultCheck1"
                        value="temperature"
                        checked={this.state.temperature}
                        defaultChecked={this.state.temperature}
                        onChange={this.handletempCheck}
                      />
                      <Label
                        className="form-check-label"
                        htmlFor="defaultCheck1"
                      >
                        Temperature
                      </Label>
                    </div>
                  </div>
                  <div className="w-100"></div>
                  <div className="form-check form-check-right mb-1">
                    <div className="col">
                      <Input
                        className="form-check-input"
                        type="checkbox"
                        id="defaultCheck2"
                        value="humidity"
                        defaultChecked={this.state.humidity}
                        onChange={this.handlehumCheck}
                        checked={this.state.humidity}
                      />
                      <Label
                        className="form-check-label"
                        htmlFor="defaultCheck2"
                      >
                        Relative Humidity
                      </Label>
                    </div>
                  </div>
                  <div class="w-100"></div>
                  <div className="form-check form-check-right mb-1">
                    <div class="col">
                      <Input
                        className="form-check-input"
                        type="checkbox"
                        id="defaultCheck3"
                        defaultChecked={this.state.rainfall}
                        onChange={this.handlerainCheck}
                        checked={this.state.rainfall}
                        value="rainfall"
                      />
                      <Label
                        className="form-check-label"
                        htmlFor="defaultCheck3"
                      >
                        Rainfall
                      </Label>
                    </div>
                  </div>
                  <div class="w-100"></div>
                  <div className="form-check form-check-right mb-1">
                    <div class="col">
                      <Input
                        className="form-check-input"
                        type="checkbox"
                        id="defaultCheck4"
                        defaultChecked={this.state.wind_s}
                        onChange={this.handlewindsCheck}
                        value="wind_s"
                        checked={this.state.wind_s}
                      />
                      <Label
                        className="form-check-label"
                        htmlFor="defaultCheck4"
                      >
                        Wind Speed
                      </Label>
                    </div>
                  </div>
                  <div class="w-100"></div>
                  <div className="form-check form-check-right mb-1">
                    <div class="col">
                      {" "}
                      <Input
                        className="form-check-input"
                        type="checkbox"
                        id="defaultCheck5"
                        defaultChecked={this.state.wind_d}
                        onChange={this.handlewinddCheck}
                        checked={this.state.wind_d}
                        value="wind_d"
                      />
                      <Label
                        className="form-check-label"
                        htmlFor="defaultCheck5"
                      >
                        Wind Direction
                      </Label>
                    </div>
                  </div>
                  <div className="form-check form-check-right mb-1">
                    <div class="col">
                      <Input
                        className="form-check-input"
                        type="checkbox"
                        id="defaultCheck6"
                        defaultChecked={this.state.uvi}
                        onChange={this.handleuviCheck}
                        checked={this.state.uvi}
                        value="uvi"
                      />
                      <Label
                        className="form-check-label"
                        htmlFor="defaultCheck6"
                      >
                        UVI
                      </Label>
                    </div>
                  </div>
                  <div className="form-check form-check-right mb-1">
                    <div class="col">
                      <Input
                        className="form-check-input"
                        type="checkbox"
                        id="defaultCheck7"
                        defaultChecked={this.state.pressure}
                        onChange={this.handlepressureCheck}
                        checked={this.state.pressure}
                        value="pressure"
                      />
                      <Label
                        className="form-check-label"
                        htmlFor="defaultCheck7"
                      >
                        Pressure
                      </Label>
                    </div>
                  </div>
                  <div className="form-check form-check-right mb-1">
                    <hr />
                    <div class="col">
                      <Input
                        className="form-check-input"
                        type="checkbox"
                        id="defaultCheck8"
                        onChange={this.handleallCheck}
                        value="allselect"
                      />
                    </div>

                    <Label className="form-check-label" htmlFor="defaultCheck8">
                      Select All
                    </Label>
                  </div>
                </div>
                <ModalFooter style={{ border: "none" }}>
                  <Button
                    type="button"
                    color="light"
                    onClick={() => this.setState({ modal_center: false })}
                  >
                    Cancel
                  </Button>
                  <Button type="" color="primary" onClick={this.updatestatus}>
                    Update
                  </Button>
                </ModalFooter>
              </AvForm>
            </ModalBody>
          </Modal>
        </div>
      </React.Fragment>
    );
  }
}
export default withNamespaces()(Sensorconfig);

