import React, { Component } from 'react'
import { Container, Card, CardBody, Row, Col, UncontrolledTooltip, Nav, Button, NavItem, NavLink, TabPane, TabContent, FormGroup, Label, CardText, Spinner } from "reactstrap";
import classnames from "classnames";
import { DatePicker} from 'antd';
import moment from 'moment';
import { Link } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import { withWidth } from '@material-ui/core';
import '../../pages/daterange.scss'
import validate_Token from "../../validateToken.js";
import { withNamespaces } from 'react-i18next';
import Moment from 'moment';
const { RangePicker } = DatePicker;

const dateFormat = 'YYYY-MM-DD';

class Trend_comparison extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeTab: "1",
      fromDate: '',
      toDate: '',
      showbutton: false,
      parameter: 'Temperature',
      trendData: [],
      showloader: true,
      groupData: [],
      showWaiting: true,
      showtable: false

    }
    this.toggle = this.toggle.bind(this);
    this.getData = this.getData.bind(this);
    this.onChangedate = this.onChangedate.bind(this);
    this.onChangeparams = this.onChangeparams.bind(this);
    this.generatetabledata = this.generatetabledata.bind(this);
    this.checkNAN = this.checkNAN.bind(this);
  }

  onChangedate(dateString) {
    const date1 = new Date(dateString[0]);
    const date2 = new Date(dateString[1]);
    // console.log("DATESTRING", dateString);
    // console.log("DATESTRING DATES", date1, date2);
    var from_dd = String(date1.getDate()).padStart(2, "0");
    var from_mm = String(date1.getMonth() + 1).padStart(2, "0"); //January is 0!
    var from_yyyy = date1.getFullYear();
    var from_date = from_yyyy + "-" + from_mm + "-" + from_dd;

    var to_dd = String(date2.getDate()).padStart(2, "0");
    var to_mm = String(date2.getMonth() + 1).padStart(2, "0"); //January is 0!
    var to_yyyy = date2.getFullYear();
    var to_date = to_yyyy + "-" + to_mm + "-" + to_dd;

    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    // console.log("DIFFERENCE DAYS", diffDays);

    if (diffDays <= 30) {
      this.setState({
        fromDate: from_date,
        toDate: to_date,
        showbutton: true,
      });
    } else {
      this.setState({
        showbutton: false,
      });
    }
  }


  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }
  onChangeparams(e) {
    this.setState({
      parameter: e.target.value
    })
  }

  async getData() {
    this.setState({
      showWaiting: false,
      showloader: false,
      showtable: false,
    })
    let searchdata = {
      "weather_parms": this.state.parameter,
      "from_date": this.state.fromDate,
      "to_date": this.state.toDate
    }
    searchdata = JSON.stringify(searchdata);
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + localStorage.getItem('token')

      },
      body: searchdata
    };
    var trendData = await fetch(process.env.REACT_APP_APIEND + `weather/rua-trend`, requestOptions).then(res => {
      return res.json();
    });
    // console.log("RUA HD TREND",trendData)

    if (trendData.code==200) {
      this.generatetabledata(trendData.result)
      this.setState({        
        showloader: true,
        showtable: true
      });
    }
    else {
      // console.log("Trend Data", trendData);
    }
  }
  checkNAN(checkdata) {
    if (isNaN(checkdata)) {
      return ("Data not available")
    } else {
      return (checkdata)
    }
  }
  generatetabledata(data) {
    let getthis = this;
    let trend_d = []
    let group_trend = []
    // console.log("TYPE OF DATA", typeof (data))

    // console.log("FUNCTION DATa", data[0].ruawise)

    data[0].ruawise.map(function (item, index, data) {
      var newdata = {
        date: moment(item.date).format("DD-MM-YYYY"),
        chittur: getthis.checkNAN(Number(item.chittur)),
        eruthenpathy: getthis.checkNAN(Number(item.eruthenpathy)),
        nallepilly: getthis.checkNAN(Number(item.nallepilly)),
        kozhinjampara: getthis.checkNAN(Number(item.kozhinjampara)),
        perumatty: getthis.checkNAN(Number(item.perumatty)),
        pattanchery: getthis.checkNAN(Number(item.pattanchery)),
        vadakarapathy: getthis.checkNAN(Number(item.vadakarapathy)),
      }
      trend_d.push(newdata);
    });

    data[1].groupwise.map(function (item, index, data) {
      var groupdata = {
        date: moment(item.date).format("DD-MM-YYYY") ,
        group1: parseFloat(item.group1).toFixed(2),
        group2: parseFloat(item.group2).toFixed(2)
      }
      group_trend.push(groupdata);

    });


    this.setState({
      trendData: trend_d,
      groupData: group_trend
    })

  }
  
disabledDate(current) {
  // Can not select days before today and today
  return current && current > moment().endOf('day');
}
  componentDidMount(){
    validate_Token();
  }
  render() {
    const { t, i18n } = this.props
    let data_ruawise = {
      columns: [
        {
          label: "Date",
          field: "date",  
          width: 130 ,
          sort: "disabled"   
        },
        {
          label: "Chittur",
          field: "chittur",
          width: 130     

        },

        {
          label: "Eruthenpathy",
          field: "eruthenpathy",
          width: 130     

        },
        {
          label: "Nallepilly",
          field: "nallepilly",
          width: 130     

        },
        {
          label: "Kozhinjampara",
          field: "kozhinjampara",
          width: 130     

        },
        {
          label: "Perumatty",
          field: "perumatty",
          width: 130     

        },
        {
          label: "Pattanchery",
          field: "pattanchery",
          width: 130     

        },
        {
          label: "Vadakarapathy",
          field: "vadakarapathy",
          width: 130     

        },
      ],
      rows: this.state.trendData
    };

    let data_group = {
      columns: [
        {
          label: "Date",
          field: "date",
          width: 150,
          sort: "disabled"   
        },
        {
          label: <span> Group 1 <Link className="mr-3 text-primary" id={"group1"}><i className="ri-information-line" style={{ display: "inline-block", float: "right" }}></i></Link>
            <UncontrolledTooltip target={"group1"} placement="top">
              Vadakarapathy,{" "}Eruthenpathy,{" "}Kozhinjampara
            </UncontrolledTooltip></span>,
          field: "group1",
          width: 376

        },

        {
          label: <span> Group 2
            <Link className="mr-3 text-primary" id={"group2"}><i className="ri-information-line" style={{ display: "inline-block", float: "right" }}></i></Link>
            <UncontrolledTooltip target={"group2"} placement="top">
              Nallepilly,{" "}Perumatty,{" "}Pattanchery,{" "}Chittur-Thathamangalam
            </UncontrolledTooltip>
          </span>,
          field: "group2",

        }
      ],
      rows: this.state.groupData
    };

    return (
      <React.Fragment>
        <div className="page-content" >
          <Container fluid>
            <Row>
              <Col xs={12}>
                <h4 className="card-title" style={{ padding: '5px', fontSize: '18px' }}>
                {t("Historical Data Comparison")}  </h4>
              </Col>

            </Row>
            <Row>
              <Col>
                <Card style={{ backgroundColor: '#f1f6fb' }}>
                  <CardBody>
                    <Col >
                      <Row>
                        <Col md="4">
                          <FormGroup required  >
                            <Label className="col-md-5 col-form-label" style={{ fontSize: '14px' }}>{t("Parameter")} </Label>
                            <select className="form-select" style={{ fontSize: '12px' }} onChange={this.onChangeparams}  >

                              <option value="Temperature" selected >Temperature [°C]</option>
                              <option value="Rainfall">Rainfall [mm]</option>
                              <option value="Wind_Speed">Wind Speed [m/s]</option>
                              <option value="Wind_Direction">Wind Direction [°]</option>
                              <option value="UV_Index">UVI</option>
                              <option value="Humidity">Humidity [%]</option>
                              <option value="Pressure">Pressure [hPa]</option>
                            </select>
                          </FormGroup>
                        </Col>

                        <Col md="4">
                        <div className="col-md-12" style={{ padding:"0px"}}>

                          <Label className="col-md-12 col-form-label" style={{ fontSize: '14px' }}>{t("Date range")} </Label>
                            <RangePicker name="range"
                              onChange={this.onChangedate}
                              disabledDate={this.disabledDate}
                              format="DD-MM-YYYY"
                              // disabledDate={d => !d || d.isBefore("2021-06-01")}
                            />

                          </div>
                          <center style={this.state.showbutton ? { display: 'none' } : {}}>
                            <p style={{ color: "red", fontSize: '10px' }}>
                              {t("Please select a date range between 30 days")}
                            </p>
                          </center>

                        </Col>

                        <Col md="2">
                          <FormGroup style={this.state.showbutton? {} : {display: 'none'}}>
                          <Label
                              className="col-md-12 col-form-label"
                              style={{ fontSize: "14px" }}
                            >
                             <span>&nbsp;</span>
                            </Label>
                            <Button
                              color="primary"
                              className="waves-effect waves-light mr-1"
                              style={{ fontSize: '11px', float: 'right'}}
                              onClick={this.getData}
                              
                            >
                              {t("Search")}
                            </Button>
                          </FormGroup>
                        </Col>
                      </Row>

                    </Col>
                  </CardBody>
                </Card>
              </Col>
            </Row>


            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>

                    <Nav tabs>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: this.state.activeTab === "1"
                          })}
                          onClick={() => {
                            this.toggle("1");
                          }}
                        >
                          RUA
                        </NavLink>
                      </NavItem>
                      <NavItem  >
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: this.state.activeTab === "2"
                          })}
                          onClick={() => {
                            this.toggle("2");
                          }}
                        >
                          Group
                        </NavLink>
                      </NavItem>
                    </Nav>

                    <TabContent activeTab={this.state.activeTab}>
                      <TabPane tabId="1" className="p-3">
                        <Row>
                          <Col sm="12">
                            <CardText>
                              <center>
                                <p style={this.state.showWaiting ? {} : { display: 'none' }}>{t("Waiting for search")}</p>
                                <Spinner style={this.state.showloader ? { display: 'none' } : {}} ></Spinner>
                              </center>
                              <div className="ruahistorical" id="ruahistorical" style={this.state.showtable ? {} : { display: 'none' }}>
                                <MDBDataTable maxHeight="300px" scrollX  lengthChange={false} paging={false} showPaginationBottom={false} searching={false} bordered data={data_ruawise} className='datadownloadtable alnright' style={{fontSize:"12px"}}/>
                              </div>
                            </CardText>
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId="2" className="p-3">


                        <div id="grouphistorical" style={this.state.showtable ? {} : { display: 'none' }}>
                          <MDBDataTable paging={false} maxHeight="300px" scrollX  showPaginationBottom={false} bordered data={data_group} searching={false} className='datadownloadtable' style={{fontSize:"12px"}}/>
                        </div>

                      </TabPane>
                    </TabContent>

                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}
export default withNamespaces()(Trend_comparison);
