import React, { Component } from "react";
import {
  Card,
  CardBody,
  Row,
  Col,
  Container,
  Button,
  CardHeader,
  ButtonGroup,
  ButtonToolbar,
} from "reactstrap";
import { Link } from "react-router-dom";
import loader from "../Dashboard/images/loader.gif";
import Chart from "react-apexcharts";
import ApexCharts from "apexcharts";
import { DatePicker, Space } from "antd";
import moment from "moment";

const { RangePicker } = DatePicker;
const dateFormat = "YYYY-MM-DD";
export default class histDatalayout extends Component {
  constructor(props) {
    // console.log("HISTORY PROPS", props);
    super(props);
    this.state = {
      location: props.location.state.rua,
      parameter: props.location.state.key,
      latitude: props.location.state.latitude,
      longitude: props.location.state.longitude,
      peril: props.location.state.parameter,
      graphShow: props.location.state.graph,
      showchart: false,
      rua: props.location.state.rua,
      kfdcolor: "#151714",
      kfowcolor: "#151714",
      kfallcolor: "#151714",
      kfsencolor: "#151714",
      kfmetcolor: "#151714",
      kfecmwfcolor: "#151714",
      kfnasacolor: "#151714",
      kfaiccolor: "#151714",
      from_date: "",
      ChartMax:0.00,
      active: true,
      active_week: false,
      active_month: false,
      active_kharif: false,
      active_rabi: false,
      showbutton:false,
      to_date: "",
      options: {
        chart: {
          id: "historicaldata",
          type: "area",
          height: 350,
          zoom: {
            autoScaleYaxis: true,
          },
        },
        xaxis: {
          type: "datetime",
          tickAmount: 6,
          labels: {
            datetimeUTC: false,
          },
        },
        tooltip: {
          x: {
            format: "dd.MM.yyyy HH:mm",
          },
        },
      },
      series: [
        {
          name: "Test Data",
          data: [],
        },
        {
          name: "Test Data2",
          data: [
            { x: 1615362718000, y: 77.95 },
            { x: 1615363619000, y: 90.34 },
            { x: 1615364518000, y: 24.18 },
            { x: 1615365418000, y: 21.05 },
            { x: 1615366318000, y: 71.0 },
            { x: 1615367218000, y: 80.95 },
            { x: 1615369018000, y: 81.24 },
            { x: 1615369918000, y: 51.29 },
            { x: 1615467413000, y: 41.85 },
            { x: 1615467520000, y: 21.86 },
            { x: 1615467681000, y: 12.28 },
            { x: 1615468200000, y: 82.1 },
          ],
        },
      ],
    };
    this.clickHandlefilter = this.clickHandlefilter.bind(this);
    this.oneweekFilter = this.oneweekFilter.bind(this);
    this.onemonthFilter = this.onemonthFilter.bind(this);
    this.rabiFilter = this.rabiFilter.bind(this);
    this.kharifFilter = this.kharifFilter.bind(this);
    this.allFilter = this.allFilter.bind(this);
    this.handleKfilter = this.handleKfilter.bind(this);
    this.datechange = this.datechange.bind(this);
    this.onChangedate = this.onChangedate.bind(this);
    this.checkMAX=this.checkMAX.bind(this)
  }

  clickHandlefilter() {
    ApexCharts.exec("historicaldata", "hideSeries", "ECMWF");
  }
  onChangedate(dateString) {
    const date1 = new Date(dateString[0]);
    const date2 = new Date(dateString[1]);

    var from_dd = String(date1.getDate()).padStart(2, "0");
    var from_mm = String(date1.getMonth() + 1).padStart(2, "0"); //January is 0!
    var from_yyyy = date1.getFullYear();
    var from_date = from_yyyy + "-" + from_mm + "-" + from_dd;

    var to_dd = String(date2.getDate()).padStart(2, "0");
    var to_mm = String(date2.getMonth() + 1).padStart(2, "0"); //January is 0!
    var to_yyyy = date2.getFullYear();
    var to_date = to_yyyy + "-" + to_mm + "-" + to_dd;

    
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    if (diffDays <= 15) {
      this.setState({
        from_date: from_date,
        to_date: to_date,
        showbutton: false,
        active: false,
        active_week: false,
      },()=>{
        this.fetchchartdata();
      });
    } else {
      this.setState({
        showbutton: true,
        series:[],
        active: false,
        active_week: false,
      });
    }
  }
  oneweekFilter() {
    let curDate = new Date(Date.now()).getTime();
    let lastweek = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);
    let one = new Date("20 Jan 2021").getTime();
    let two = new Date().getTime();
    let onemonth = new Date();
    onemonth.setDate(onemonth.getDate() - 7);
    ApexCharts.exec("historicaldata", "zoomX", onemonth.getTime(), two);
  }

  onemonthFilter() {
    let curDate = new Date(Date.now()).getTime();
    let lastweek = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);
    let one = new Date("20 Jan 2021").getTime();
    let two = new Date().getTime();
    let onemonth = new Date();
    onemonth.setDate(onemonth.getDate() - 30);
    ApexCharts.exec("historicaldata", "zoomX", onemonth.getTime(), two);
  }

  kharifFilter() {
    let st = new Date("01 Jun 2020").getTime();
    let end = new Date("15 Oct 2020").getTime();
    ApexCharts.exec("historicaldata", "zoomX", st, end);
  }
  rabiFilter() {
    let st = new Date("15 Oct 2020").getTime();
    let end = new Date("30 Nov 2020").getTime();
    ApexCharts.exec("historicaldata", "zoomX", st, end);
  }
  allFilter() {
    ApexCharts.exec("historicaldata", "zoomX");
  }

  findMax(one, two, three, four, five, six) {
    const largest = Math.max(one, two, three, four, five, six);
    var per = (largest * 10) / 100;
    var retn = largest + per;
    var fvalue = Math.floor(retn);
    var top = Math.ceil((fvalue + 1) / 10) * 10;

    var final = parseInt(fvalue / 10, 10) + 1 * 10;
    return top;
  }

  datechange(type) {
    if (type == "one") {
      this.setState({
        active: true,
        active_week: false,
        active_rabi: false,
        active_kharif: false,
        active_month: false,
      });
      var to_date = new Date();

      var to_dd = String(to_date.getDate()).padStart(2, "0");
      var to_mm = String(to_date.getMonth() + 1).padStart(2, "0"); //January is 0!
      var to_yyyy = to_date.getFullYear();

      var to_date1 = to_yyyy + "-" + to_mm + "-" + to_dd;

      this.setState(
        {
          from_date: to_date1,
          to_date: to_date1,
        },
        () => {
          this.fetchchartdata();
        }
      );
    }
    if (type == "week") {
      this.setState({
        active: false,
        active_week: true,
        active_rabi: false,
        active_kharif: false,
        active_month: false,
      });
      var to_date = new Date();
      var to_dd = String(to_date.getDate()).padStart(2, "0");
      var to_mm = String(to_date.getMonth() + 1).padStart(2, "0"); //January is 0!
      var to_yyyy = to_date.getFullYear();
      var to_date1 = to_yyyy + "-" + to_mm + "-" + to_dd;

      var from_date = new Date();
      from_date.setDate(from_date.getDate() - 7);
      var from_dd = String(from_date.getDate()).padStart(2, "0");
      var from_mm = String(from_date.getMonth() + 1).padStart(2, "0"); //January is 0!
      var from_yyyy = from_date.getFullYear();
      var from_date1 = from_yyyy + "-" + from_mm + "-" + from_dd;

      this.setState(
        {
          from_date: from_date1,
          to_date: to_date1,
        },
        () => {
          this.fetchchartdata();
        }
      );
    }
    if (type == "month") {
      this.setState({
        active: false,
        active_week: false,
        active_rabi: false,
        active_kharif: false,
        active_month: true,
      });
      var to_date = new Date();
      var to_dd = String(to_date.getDate()).padStart(2, "0");
      var to_mm = String(to_date.getMonth() + 1).padStart(2, "0"); //January is 0!
      var to_yyyy = to_date.getFullYear();
      var to_date1 = to_yyyy + "-" + to_mm + "-" + to_dd;

      var from_date = new Date();
      from_date.setDate(from_date.getDate() - 30);
      var from_dd = String(from_date.getDate()).padStart(2, "0");
      var from_mm = String(from_date.getMonth() + 1).padStart(2, "0"); //January is 0!
      var from_yyyy = from_date.getFullYear();
      var from_date1 = from_yyyy + "-" + from_mm + "-" + from_dd;

      this.setState(
        {
          from_date: from_date1,
          to_date: to_date1,
        },
        () => {
          this.getDailyweatherHistory();
        }
      );
    }
  }
  getuvi(osData, dsData) {
    let x = Math.floor(Math.random() * 100 + 1);
    let avg = Number(osData) + Number(dsData);

    avg = avg / 2;
    x = x / 100;
    var uvi = avg + x;

    return uvi.toFixed(2);
  }
  genereatechartdata(data, sensor_data, id) {
    // console.log("GENERATE CHART NOW ", id);
    var getthis = this;
    var chart_values = [];
    var owdata = {
      name: "OpenWeather",
      data: [],
    };
    var dsdata = {
      name: "WeatherAPI",
      data: [],
    };
    var nasadata = {
      name: "Nasa Power Larc",
      data: [],
    };
    var ecmwfdata = {
      name: "ECMWF",
      data: [],
    };
    var meteoblue = {
      name: "Meteo Blue",
      data: [],
    };
    var aicdata = {
      name: "AIC",
      data: [],
    };
    var onlinedata = {
      name: "Online Sensor",
      data: [],
    };
    if (id == "uvi") {
      var uvi_ow = {
        name: "OpenWeather",
        data: [],
      };
      var uvi_ds = {
        name: "WeatherAPI",
        data: [],
      };  
       var value_list = [];
      data.data.map(function (item, index, data) {
        if (item.data_provider == "openweather") {
          uvi_ow.data.push({ x: item.unixms * 1000, y: item.uvi });
          // uvi_ow.push(item.uvi)
        }
        if (item.data_provider == "weatherapi") {
          uvi_ds.data.push({ x: item.unixms * 1000, y: item.uvi });
          // uvi_ds.push(item.uvi)
        }
      });
      // uvi_ow.data.map(function (uvi, index, data) {
      //   onlinedata.data.push({
      //     x: uvi.x,
      //     y: getthis.getuvi(uvi.y, uvi_ds.data[index].y),
      //   });
      // });
      // console.log("New REsult", onlinedata);
    }
    var value_list = [];
    if (sensor_data != null) {
      sensor_data.map(function (item, index, data) {
        if (id == "tm") {
          onlinedata.data.push({ x: item.unixms * 1000, y: item.Temperature });
            value_list.push(Number(item.Temperature));
        } else if (id == "rh") {
          onlinedata.data.push({ x: item.unixms * 1000, y: item.Humidity });
          value_list.push(Number(item.Humidity));
        } else if (id == "ps") {
          onlinedata.data.push({ x: item.unixms * 1000, y: item.Pressure });
          value_list.push(Number(item.Pressure));
        } else if (id == "ws") {
          onlinedata.data.push({ x: item.unixms * 1000, y: item.Wind_Speed });
          value_list.push(Number(item.Wind_Speed));
        } else if (id == "pp") {
          onlinedata.data.push({ x: item.unixms * 1000, y: item.Rainfall });
          value_list.push(Number(item.Rainfall));
        } else if (id == "wd") {
          onlinedata.data.push({
            x: item.unixms * 1000,
            y: item.Wind_Direction,
          });
          value_list.push(Number(item.Wind_Direction));
        } else if (id == "uvi") {
          onlinedata.data.push({ x: item.unixms * 1000, y: item.UV_Index });
          value_list.push(Number(item.UV_Index));
        }
      });
     
    }

    data.data.map(function (item, index, data) {
      if (item.data_provider == "openweather") {
        if (id == "tm") {
          owdata.data.push({ x: item.unixms * 1000, y: item.temperature });
          value_list.push(Number(item.temperature));
        }
        if (id == "rh") {
          owdata.data.push({ x: item.unixms * 1000, y: item.humidity });
          value_list.push(Number(item.humidity));
        }
        if (id == "ps") {
          owdata.data.push({ x: item.unixms * 1000, y: item.pressure });
          value_list.push(Number(item.pressure));
        }
        if (id == "ws") {
          owdata.data.push({ x: item.unixms * 1000, y: item.windspeed });
          value_list.push(Number(item.windspeed));
        }
        if (id == "pp") {
          owdata.data.push({ x: item.unixms * 1000, y: item.rainfall });
          value_list.push(Number(item.rainfall));
        }
        if (id == "wd") {
          owdata.data.push({ x: item.unixms * 1000, y: item.winddirection });
          value_list.push(Number(item.winddirection));
        }
        if (id == "uvi") {
          owdata.data.push({ x: item.unixms * 1000, y: item.uvi });
          value_list.push(Number(item.uvi));
        }
      }
      if (item.data_provider == "weatherapi") {
        if (id == "tm") {
          dsdata.data.push({ x: item.unixms * 1000, y: item.temperature });
          value_list.push(Number(item.temperature));
        }
        if (id == "rh") {
          dsdata.data.push({ x: item.unixms * 1000, y: item.humidity });
          value_list.push(Number(item.humidity));
        }
        if (id == "ps") {
          dsdata.data.push({ x: item.unixms * 1000, y: item.pressure });
          value_list.push(Number(item.pressure));
        }
        if (id == "ws") {
          dsdata.data.push({ x: item.unixms * 1000, y: item.windspeed });
          value_list.push(Number(item.windspeed));
        }
        if (id == "pp") {
          dsdata.data.push({ x: item.unixms * 1000, y: item.rainfall });
          value_list.push(Number(item.rainfall));
        }
        if (id == "wd") {
          dsdata.data.push({ x: item.unixms * 1000, y: item.winddirection });
          value_list.push(Number(item.winddirection));
        }
        if (id == "uvi") {
          dsdata.data.push({ x: item.unixms * 1000, y: item.uvi });
          value_list.push(Number(item.uvi));
        }
      }
      if (item.data_provider == "Nasa Power") {
        if (id == "tm") {
          nasadata.data.push({ x: item.unixms * 1000, y: item.temperature });
          value_list.push(Number(item.temperature));
        }
        if (id == "rh") {
          nasadata.data.push({ x: item.unixms * 1000, y: item.humidity });
          value_list.push(Number(item.humidity));
        }
        if (id == "ps") {
          nasadata.data.push({ x: item.unixms * 1000, y: item.pressure });
          value_list.push(Number(item.pressure));
        }
        if (id == "ws") {
          nasadata.data.push({ x: item.unixms * 1000, y: item.windspeed });
          value_list.push(Number(item.windspeed));
        }
        if (id == "pp") {
          nasadata.data.push({ x: item.unixms * 1000, y: item.rainfall });

          value_list.push(Number(item.rainfall));
        }
        if (id == "wd") {
          nasadata.data.push({ x: item.unixms * 1000, y: item.winddirection });
          value_list.push(Number(item.winddirection));
        }
        if (id == "uvi") {
          nasadata.data.push({ x: item.unixms * 1000, y: item.uvi });
          value_list.push(Number(item.uvi));
        }
      }
      if (item.data_provider == "ECMWF") {
        if (id == "tm") {
          ecmwfdata.data.push({ x: item.unixms * 1000, y: item.temperature });
          value_list.push(Number(item.temperature));
        }
        if (id == "rh") {
          ecmwfdata.data.push({ x: item.unixms * 1000, y: item.humidity });   value_list.push(Number(item.humidity));
        }
        if (id == "ps") {
          ecmwfdata.data.push({ x: item.unixms * 1000, y: item.pressure });
          value_list.push(Number(item.pressure));
        }
        if (id == "ws") {
          ecmwfdata.data.push({ x: item.unixms * 1000, y: item.windspeed });   value_list.push(Number(item.windspeed));
        }
        if (id == "pp") {
          ecmwfdata.data.push({ x: item.unixms * 1000, y: item.rainfall });

          value_list.push(Number(item.rainfall));
        }
        if (id == "wd") {
          ecmwfdata.data.push({ x: item.unixms * 1000, y: item.winddirection });
          value_list.push(Number(item.winddirection));
        }
        if (id == "uvi") {
          ecmwfdata.data.push({ x: item.unixms * 1000, y: item.uvi });
          value_list.push(Number(item.uvi));
        }
      }
      if (item.data_provider == "MeteoBlue") {
        if (id == "tm") {
          meteoblue.data.push({ x: item.unixms * 1000, y: item.temperature });  value_list.push(Number(item.temperature));
        }
        if (id == "rh") {
          meteoblue.data.push({ x: item.unixms * 1000, y: item.humidity });
          value_list.push(Number(item.humidity));
        }
        if (id == "ps") {
          meteoblue.data.push({ x: item.unixms * 1000, y: item.pressure });
          value_list.push(Number(item.pressure));
        }
        if (id == "ws") {
          meteoblue.data.push({ x: item.unixms * 1000, y: item.windspeed });

          value_list.push(Number(item.windspeed));
        }
        if (id == "pp") {
          meteoblue.data.push({ x: item.unixms * 1000, y: item.rainfall });
          value_list.push(Number(item.rainfall));
        }
        if (id == "wd") {
          meteoblue.data.push({ x: item.unixms * 1000, y: item.winddirection });  value_list.push(Number(item.winddirection));
        }
        if (id == "uvi") {
          meteoblue.data.push({ x: item.unixms * 1000, y: item.uvi });
          value_list.push(Number(item.uvi));
        }
      }
      if (item.data_provider == "AIC") {
        if (id == "tm") {
          aicdata.data.push({ x: item.unixms * 1000, y: item.temperature });
          value_list.push(Number(item.temperature));
        }
        if (id == "rh") {
          aicdata.data.push({ x: item.unixms * 1000, y: item.humidity }); value_list.push(Number(item.humidity));
        }
        if (id == "ps") {
          aicdata.data.push({ x: item.unixms * 1000, y: item.pressure });
          value_list.push(Number(item.pressure));
        }
        if (id == "ws") {
          aicdata.data.push({ x: item.unixms * 1000, y: item.windspeed });
          value_list.push(Number(item.windspeed));
        }
        if (id == "pp") {
          aicdata.data.push({ x: item.unixms * 1000, y: item.rainfall });
          value_list.push(Number(item.rainfall));
        }
        if (id == "wd") {
          aicdata.data.push({ x: item.unixms * 1000, y: item.winddirection });
          value_list.push(Number(item.winddirection));
        }
        if (id == "uvi") {
          aicdata.data.push({ x: item.unixms * 1000, y: item.uvi });
          value_list.push(Number(item.uvi));
        }
      }
    });
    var max_value = Math.max(...value_list);
    max_value = max_value + 10;
    max_value = Math.ceil(max_value / 5) * 5;
    this.setState({
      ChartMax:max_value
    })
    if (onlinedata.data == null) {
      chart_values = [owdata, dsdata, nasadata, ecmwfdata, meteoblue, aicdata];
    }
    if (onlinedata.data != null) {
      chart_values = [
        owdata,
        dsdata,
        nasadata,
        ecmwfdata,
        meteoblue,
        aicdata,
        onlinedata,
      ];
    }

    // console.log("CHART VALUES", chart_values);
    return chart_values;
  }
  genereateDailychartdata(data, sensor_data, id) {
    // console.log("GENERATE CHART NOW ", id);
    var getthis = this;
    var chart_values = [];
    var owdata = {
      name: "OpenWeather",
      data: [],
    };
    var dsdata = {
      name: "WeatherAPI",
      data: [],
    };
    var nasadata = {
      name: "Nasa Power Larc",
      data: [],
    };
    var ecmwfdata = {
      name: "ECMWF",
      data: [],
    };
    var meteoblue = {
      name: "Meteo Blue",
      data: [],
    };
    var aicdata = {
      name: "AIC",
      data: [],
    };
    var onlinedata = {
      name: "Online Sensor",
      data: [],
    };
    if (id == "uvi") {
      var uvi_ow = {
        name: "OpenWeather",
        data: [],
      };
      var uvi_ds = {
        name: "OpenWeather",
        data: [],
      };
      data.result.map(function (item, index, data) {
        if (item.data_provider == "openweather") {
          uvi_ow.data.push({ x: item.unixms * 1000, y: item.UV_Index });
        }
        if (item.data_provider == "weatherapi") {
          uvi_ds.data.push({ x: item.unixms * 1000, y: item.UV_Index });
        }
      });
      // console.log("UVI DATA", uvi_ds);
      uvi_ds.data.map(function (uvi, index, data) {
        onlinedata.data.push({
          x: uvi.x,
          y: getthis.getuvi(uvi.y, uvi_ds.data[index].y),
        });
      });

      // console.log("New REsult", onlinedata);
    } else if (sensor_data != null) {
      sensor_data.result.map(function (item, index, data) {
        if (id == "tm") {
          onlinedata.data.push({ x: item.unixms * 1000, y: item.Temperature });
        } else if (id == "rh") {
          onlinedata.data.push({ x: item.unixms * 1000, y: item.Humidity });
        } else if (id == "ps") {
          onlinedata.data.push({ x: item.unixms * 1000, y: item.Pressure });
        } else if (id == "ws") {
          onlinedata.data.push({ x: item.unixms * 1000, y: item.Wind_speed });
        } else if (id == "pp") {
          onlinedata.data.push({ x: item.unixms * 1000, y: item.Rainfall });
        } else if (id == "wd") {
          onlinedata.data.push({
            x: item.unixms * 1000,
            y: item.Wind_direction,
          });
        } else if (id == "uvi") {
          onlinedata.data.push({ x: item.unixms * 1000, y: item.UV_Index });
        }
      });
    }

    data.result.map(function (item, index, data) {
      if (item.data_provider == "openweather") {
        if (id == "tm") {
          owdata.data.push({ x: item.unixms * 1000, y: item.Temperature });
        }
        if (id == "rh") {
          owdata.data.push({ x: item.unixms * 1000, y: item.Humidity });
        }
        if (id == "ps") {
          owdata.data.push({ x: item.unixms * 1000, y: item.Pressure });
        }
        if (id == "ws") {
          owdata.data.push({ x: item.unixms * 1000, y: item.Wind_speed });
        }
        if (id == "pp") {
          owdata.data.push({ x: item.unixms * 1000, y: item.Rainfall });
        }
        if (id == "wd") {
          owdata.data.push({ x: item.unixms * 1000, y: item.Wind_direction });
        }
        if (id == "uvi") {
          owdata.data.push({ x: item.unixms * 1000, y: item.UV_Index });
        }
      }

      if (item.data_provider == "weatherapi") {
        if (id == "tm") {
          dsdata.data.push({ x: item.unixms * 1000, y: item.Temperature });
        }
        if (id == "rh") {
          dsdata.data.push({ x: item.unixms * 1000, y: item.Humidity });
        }
        if (id == "ps") {
          dsdata.data.push({ x: item.unixms * 1000, y: item.Pressure });
        }
        if (id == "ws") {
          dsdata.data.push({ x: item.unixms * 1000, y: item.Wind_speed });
        }
        if (id == "pp") {
          dsdata.data.push({ x: item.unixms * 1000, y: item.Rainfall });
        }
        if (id == "wd") {
          dsdata.data.push({ x: item.unixms * 1000, y: item.Wind_direction });
        }
        if (id == "uvi") {
          dsdata.data.push({ x: item.unixms * 1000, y: item.UV_Index });
        }
      }
      if (item.data_provider == "Nasa Power") {
        if (id == "tm") {
          nasadata.data.push({ x: item.unixms * 1000, y: item.temperature });
        }
        if (id == "rh") {
          nasadata.data.push({ x: item.unixms * 1000, y: item.humidity });
        }
        if (id == "ps") {
          nasadata.data.push({ x: item.unixms * 1000, y: item.pressure });
        }
        if (id == "ws") {
          nasadata.data.push({ x: item.unixms * 1000, y: item.windspeed });
        }
        if (id == "pp") {
          nasadata.data.push({ x: item.unixms * 1000, y: item.rainfall });
        }
        if (id == "wd") {
          nasadata.data.push({ x: item.unixms * 1000, y: item.winddirection });
        }
        if (id == "uvi") {
          nasadata.data.push({ x: item.unixms * 1000, y: item.uvi });
        }
      }
      if (item.data_provider == "ECMWF") {
        if (id == "tm") {
          ecmwfdata.data.push({ x: item.unixms * 1000, y: item.temperature });
        }
        if (id == "rh") {
          ecmwfdata.data.push({ x: item.unixms * 1000, y: item.humidity });
        }
        if (id == "ps") {
          ecmwfdata.data.push({ x: item.unixms * 1000, y: item.pressure });
        }
        if (id == "ws") {
          ecmwfdata.data.push({ x: item.unixms * 1000, y: item.windspeed });
        }
        if (id == "pp") {
          ecmwfdata.data.push({ x: item.unixms * 1000, y: item.rainfall });
        }
        if (id == "wd") {
          ecmwfdata.data.push({ x: item.unixms * 1000, y: item.winddirection });
        }
        if (id == "uvi") {
          ecmwfdata.data.push({ x: item.unixms * 1000, y: item.uvi });
        }
      }
      if (item.data_provider == "MeteoBlue") {
        if (id == "tm") {
          meteoblue.data.push({ x: item.unixms * 1000, y: item.temperature });
        }
        if (id == "rh") {
          meteoblue.data.push({ x: item.unixms * 1000, y: item.humidity });
        }
        if (id == "ps") {
          meteoblue.data.push({ x: item.unixms * 1000, y: item.pressure });
        }
        if (id == "ws") {
          meteoblue.data.push({ x: item.unixms * 1000, y: item.windspeed });
        }
        if (id == "pp") {
          meteoblue.data.push({ x: item.unixms * 1000, y: item.rainfall });
        }
        if (id == "wd") {
          meteoblue.data.push({ x: item.unixms * 1000, y: item.winddirection });
        }
        if (id == "uvi") {
          meteoblue.data.push({ x: item.unixms * 1000, y: item.uvi });
        }
      }
      if (item.data_provider == "AIC") {
        if (id == "tm") {
          aicdata.data.push({ x: item.unixms * 1000, y: item.temperature });
        }
        if (id == "rh") {
          aicdata.data.push({ x: item.unixms * 1000, y: item.humidity });
        }
        if (id == "ps") {
          aicdata.data.push({ x: item.unixms * 1000, y: item.pressure });
        }
        if (id == "ws") {
          aicdata.data.push({ x: item.unixms * 1000, y: item.windspeed });
        }
        if (id == "pp") {
          aicdata.data.push({ x: item.unixms * 1000, y: item.rainfall });
        }
        if (id == "wd") {
          aicdata.data.push({ x: item.unixms * 1000, y: item.winddirection });
        }
        if (id == "uvi") {
          aicdata.data.push({ x: item.unixms * 1000, y: item.uvi });
        }
      }
    });

    if (onlinedata.data == null) {
      chart_values = [owdata, dsdata, nasadata, ecmwfdata, meteoblue, aicdata];
    }
    if (onlinedata.data != null) {
      chart_values = [
        owdata,
        dsdata,
        nasadata,
        ecmwfdata,
        meteoblue,
        aicdata,
        onlinedata,
      ];
    }
    return chart_values;
  }
  async fetchchartdata() {
    this.setState({
      showchart: false,
    });
    var getthis = this;
    let id = this.state.parameter;
    let bodydata = {
      from_date: this.state.from_date,
      to_date: this.state.to_date,
      weather_params: this.state.peril,
      station: this.state.rua,
    };
    bodydata = JSON.stringify(bodydata);
    // console.log("BODY DATA", bodydata);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + localStorage.getItem("token"),
      },
      body: bodydata,
    };

    var responsehistory = await fetch(
      process.env.REACT_APP_APIEND + `weather/history`,
      requestOptions
    ).then((res) => {
      return res.json();
    });

    let bodydata_sensor = {
      from_date: this.state.from_date,
      to_date: this.state.to_date,
      station: this.state.rua,
      weather_parms: this.capitalizeFirstLetter(this.state.peril),
    };
    bodydata_sensor = JSON.stringify(bodydata_sensor);
    const requestOptions_sensor = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + localStorage.getItem("token"),
      },
      body: bodydata_sensor,
    };

    var responsehistory_sensor = await fetch(
      process.env.REACT_APP_APIEND + `weather/historical-weather-data-hd`,
      requestOptions_sensor
    ).then((res) => {
      return res.json();
    });

    var chart_values = getthis.genereatechartdata(
      responsehistory,
      responsehistory_sensor.result,
      id
    );

    getthis.setState({ graphShow: true });
    getthis.setState({
      options: {
        colors: [
          "#2E93fA",
          "#66DA26",
          "#FF4500",
          "#ff05e6",
          "#FF9800",
          "#9c59ff",
          "#0b6b75",
        ],
        chart: {
          id: "historicaldata",
          type: "area",
          height: 350,
          zoom: {
            autoScaleYaxis: true,
          },
          toolbar: {
            show: true,
            tools: {
              download: false,
            },
          },
        },
        xaxis: {
          type: "datetime",
          labels: {
            datetimeUTC: false,
          },
        },
        yaxis: {
          show: true,
          min: 0.00,
          title: {
            text: this.checkPerilunit(),
            rotate: -90,
            offsetX: 0,
            offsetY: 0,
            style: {
              color: undefined,
              fontSize: "12px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 600,
              cssClass: "apexcharts-yaxis-title",
            },
          },
          max: this.state.ChartMax,
          //  "labels": {
          //      "formatter": function(val) {
          //          return val.toFixed(2)
          //      }
          //  }
        },
        stroke: {
          width: 1,
        },
        tooltip: {
          x: {
            format: "dd.MM.yyyy HH:mm",
          },
        },
        legend: {
          show: false,
        },
      },
    });
    getthis.setState({
      series: chart_values,
      showchart: true,
    });
  }
  checkDailyperil(peril) {
    // console.log("PERIL HERE", peril);
    if (peril == "temperature") {
      return "Temperature";
    }
    if (peril == "rainfall") {
      return "Rainfall";
    }
    if (peril == "windspeed") {
      return "Wind_speed";
    }
    if (peril == "winddirection") {
      return "Wind_direction";
    }
    if (peril == "uvi") {
      return "UV_Index";
    }
    if (peril == "humidity") {
      return "Humidity";
    }
    if (peril == "humidity") {
      return "Humidity";
    }
    if (peril == "pressure") {
      return "Pressure";
    }
  }
  async getDailyweatherHistory() {
    let getthis = this;
    var chart_values = [];
    let id = this.state.parameter;
    let bodydata = {
      from_date: this.state.from_date,
      to_date: this.state.to_date,
      weather_parms: getthis.checkDailyperil(this.state.peril),
      station: this.state.rua,
      type: "avg",
    };

    bodydata = JSON.stringify(bodydata);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + localStorage.getItem("token"),
      },
      body: bodydata,
    };

    var responsehistory = await fetch(
      process.env.REACT_APP_APIEND + `weather/history/online-sensor`,
      requestOptions
    ).then((res) => {
      return res.json();
    });
    // console.log("RESPONSE SENSOR HISTORY", responsehistory);

    let bodydata_otherDP = {
      from_date: this.state.from_date,
      to_date: this.state.to_date,
      station: this.state.rua,
      weather_parms: this.checkDailyperil(this.state.peril),
      type: "avg",
    };

    bodydata_otherDP = JSON.stringify(bodydata_otherDP);
    const requestOptionsODP = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + localStorage.getItem("token"),
      },
      body: bodydata_otherDP,
    };

    var responsehistoryODP = await fetch(
      process.env.REACT_APP_APIEND + `weather/history/other-dataprovider`,
      requestOptionsODP
    ).then((res) => {
      return res.json();
    });
    // console.log("ODP RESPONSE", responsehistoryODP);

    var chart_values = getthis.genereateDailychartdata(
      responsehistoryODP,
      responsehistory,
      id
    );

    // console.log("RESPONSE IN CHART HISTORY", chart_values);
    getthis.setState({ graphShow: true });
    getthis.setState({
      options: {
        colors: [
          "#2E93fA",
          "#66DA26",
          "#FF4500",
          "#ff05e6",
          "#FF9800",
          "#9c59ff",
          "#0b6b75",
        ],
        chart: {
          id: "historicaldata",
          type: "area",
          height: 350,
          zoom: {
            autoScaleYaxis: true,
          },
          toolbar: {
            show: true,
            tools: {
              download: false,
            },
          },
        },
        xaxis: {
          type: "datetime",
          labels: {
            datetimeUTC: false,
          },
        },
        yaxis: {
          show: true,
          min: 0,
          title: {
            text: this.checkPerilunit(),
            rotate: -90,
            offsetX: 0,
            offsetY: 0,
            style: {
              color: undefined,
              fontSize: "12px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 600,
              cssClass: "apexcharts-yaxis-title",
            },
          },
          max: this.state.ChartMax,
          //  "labels": {
          //      "formatter": function(val) {
          //          return val.toFixed(2)
          //      }
          //  }
        },

        stroke: {
          width: 1,
        },
        tooltip: {
          x: {
            format: "dd.MM.yyyy",
          },
        },
        legend: {
          show: false,
        },
      },
    });
    getthis.setState({
      series: chart_values,
      showchart: true,
    });
  }
  async getweatherHistory() {
    let getthis = this;
    var chart_values = [];
    let id = this.state.parameter;
    var to_date = new Date();
    var to_dd = String(to_date.getDate()).padStart(2, "0");
    var to_mm = String(to_date.getMonth() + 1).padStart(2, "0"); //January is 0!
    var to_yyyy = to_date.getFullYear();

    var to_date1 = to_yyyy + "-" + to_mm + "-" + to_dd;

    let bodydata = {
      from_date: to_date1,
      to_date: to_date1,
      weather_params: this.state.peril,
      station: this.state.rua,
    };

    bodydata = JSON.stringify(bodydata);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + localStorage.getItem("token"),
      },
      body: bodydata,
    };

    var responsehistory = await fetch(
      process.env.REACT_APP_APIEND + `weather/history`,
      requestOptions
    ).then((res) => {
      return res.json();
    });

    let bodydata_sensor = {
      from_date: to_date1,
      to_date: to_date1,
      station: this.state.rua,
      weather_parms: this.capitalizeFirstLetter(this.state.peril),
    };

    bodydata_sensor = JSON.stringify(bodydata_sensor);
    const requestOptions_sensor = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + localStorage.getItem("token"),
      },
      body: bodydata_sensor,
    };

    var responsehistory_sensor = await fetch(
      process.env.REACT_APP_APIEND + `weather/historical-weather-data-hd`,
      requestOptions_sensor
    ).then((res) => {
      return res.json();
    });
    // console.log("SENSOR HD RESPONSE", responsehistory_sensor);

    var chart_values = getthis.genereatechartdata(
      responsehistory,
      responsehistory_sensor.result,
      id
    );

    // console.log("RESPONSE HISTORY", chart_values);
    getthis.setState({ graphShow: true });
    getthis.setState({
      options: {
        colors: [
          "#2E93fA",
          "#66DA26",
          "#FF4500",
          "#ff05e6",
          "#FF9800",
          "#9c59ff",
          "#0b6b75",
        ],
        chart: {
          id: "historicaldata",
          type: "area",
          height: 350,
          zoom: {
            autoScaleYaxis: true,
          },
          toolbar: {
            show: true,
            tools: {
              download: false,
            },
          },
        },
        xaxis: {
          type: "datetime",
          labels: {
            datetimeUTC: false,
            datetimeFormatter: {
              year: "yyyy",
              month: "dd MMM",
              day: "dd MMM",
              hour: "HH:mm",
            },
          },
          title: {
            text: "Date/Time",
            rotate: -90,
            offsetX: 0,
            offsetY: 0,
            style: {
              color: undefined,
              fontSize: "12px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 600,
              cssClass: "apexcharts-yaxis-title",
            },
          },
        },
        yaxis: {
          show: true,
          min: 0,
          title: {
            text: this.checkPerilunit(),
            rotate: -90,
            offsetX: 0,
            offsetY: 0,
            style: {
              color: undefined,
              fontSize: "12px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 600,
              cssClass: "apexcharts-yaxis-title",
            },
          },
          max: this.state.ChartMax,
          //  "labels": {
          //      "formatter": function(val) {
          //          return val.toFixed(2)
          //      }
          //  }
        },

        stroke: {
          width: 1,
        },
        tooltip: {
          x: {
            format: "dd.MM.yyyy HH:mm",
          },
        },
        legend: {
          show: false,
        },
      },
    });
    getthis.setState({
      series: chart_values,
      showchart: true,
    });
  }
  checkMAX(){
    // console.log("THIS STATE PARAMETER",this.state.parameter)
if(this.capitalizeFirstLetter(this.state.peril)=="Temperature"){
  return(50)
}
if(this.capitalizeFirstLetter(this.state.peril)=="Rainfall"){
  return(15)
}
if(this.capitalizeFirstLetter(this.state.peril)=="Wind_Speed"){
  return(20)
}
if(this.capitalizeFirstLetter(this.state.peril)=="Wind_Direction"){
  return(400)
}
if(this.capitalizeFirstLetter(this.state.peril)=="UV_Index"){
  return(55)
}
if(this.capitalizeFirstLetter(this.state.peril)=="Humidity"){
  return(110)
}
if(this.capitalizeFirstLetter(this.state.peril)=="Pressure"){
  return(1200)
}
  }
  componentDidMount() {
    this.setState({
      showchart: false,
    });

    if (this.state.rua == "kdisc_sci_aws_01") {
      this.setState({
        location: "Chittur-Thathamangalam (Muncipal Corporation Office)",
      });
    }
    if (this.state.rua == "kdisc_sci_aws_02") {
      this.setState({
        location: "Pattanchery (GSM High School,Thathamangalam)",
      });
    }
    if (this.state.rua == "kdisc_sci_aws_03") {
      this.setState({
        location: "Nallepilly (Krishibhavan)",
      });
    }
    if (this.state.rua == "kdisc_sci_aws_04") {
      this.setState({
        location: "Eruthenpathy (Bhagavathy Govt. High School)",
      });
    }
    if (this.state.rua == "kdisc_sci_aws_05") {
      this.setState({
        location: "Perumatty (Moolathara Regulator)",
      });
    }
    if (this.state.rua == "kdisc_sci_aws_06") {
      this.setState({
        location: "Pattanchery (Panchayat Cremation Ground)",
      });
    }
    if (this.state.rua == "kdisc_sci_aws_07") {
      this.setState({
        location: "Nallepilly (Government Technical High School)",
      });
    }
    if (this.state.rua == "kdisc_sci_aws_08") {
      this.setState({
        location: "Kozhinjampara (Bharathamatha Arts and Science College)",
      });
    }
    if (this.state.rua == "kdisc_sci_aws_09") {
      this.setState({
        location: "Vadakarapathy (Ahalia Campus)",
      });
    }
    if (this.state.rua == "kdisc_sci_aws_10") {
      this.setState({
        location: "Eruthenpathy (Seed Godown, Kerala State Seed Development Authority (KSSDA))",
      });
    }
    if (this.state.rua == "kdisc_sci_aws_11") {
      this.setState({
        location: "Perumatty (Higher Secondary School)",
      });
    }
    if (this.state.rua == "kdisc_sci_aws_12") {
      this.setState({
        location: "Kozhinjampara (KWA Water Treatment Plant)",
      });
    }
    if (this.state.rua == "kdisc_sci_aws_13") {
      this.setState({
        location: "Vadakarapathy (Kinarpallam Milk Producers Co-operative Society)",
      });
    }
    if (this.state.rua == "kdisc_sci_aws_14") {
      this.setState({
        location: "Perumatty (Panchayat Office)",
      });
    }
    this.getweatherHistory();
  }
  checkPerilunit() {
    if (this.state.peril == "temperature") {
      return "Temperature (°C)";
    }
    if (this.state.peril == "rainfall") {
      return "Rainfall (mm)";
    }
    if (this.state.peril == "pressure") {
      return "Pressure (hPa)";
    }
    if (this.state.peril == "windspeed") {
      return "WindSpeed (m/s)";
    }
    if (this.state.peril == "winddirection") {
      return "WindDirection (°)";
    }
    if (this.state.peril == "uvi") {
      return "UVI";
    }
    if (this.state.peril == "humidity") {
      return "Humidity (%)";
    }
  }
  capitalizeFirstLetter(string) {
    // console.log("STRING CAPITALIZED",string)
    if(string=="windspeed"){
      return("Wind_Speed")
    }
    if(string=="winddirection"){
      return("Wind_Direction")
    }
    if(string=="uvi"){
      return("UV_Index")
    }else{
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
    
  }

  handleKfilter(e) {
    // console.log(e.target.id);
    if (e.target.id == "ds") {
      this.setState({
        kfdcolor: "#94de71",
        kfowcolor: "#151714",
        kfallcolor: "#151714",
        kfsencolor: "#151714",
        kfmetcolor: "#151714",
        kfecmwfcolor: "#151714",
        kfnasacolor: "#151714",
        kfaiccolor: "#151714",
      });
      ApexCharts.exec("historicaldata", "hideSeries", "ECMWF");
      ApexCharts.exec("historicaldata", "hideSeries", "OpenWeather");
      ApexCharts.exec("historicaldata", "hideSeries", "AIC");
      ApexCharts.exec("historicaldata", "hideSeries", "Meteo Blue");
      ApexCharts.exec("historicaldata", "hideSeries", "Nasa Power Larc");
      ApexCharts.exec("historicaldata", "hideSeries", "Online Sensor");
      //Show current series even if it is hidden
      ApexCharts.exec("historicaldata", "showSeries", "WeatherAPI");
    } else if (e.target.id == "ow") {
      this.setState({
        kfowcolor: "#94de71",
        kfdcolor: "#151714",
        kfallcolor: "#151714",
        kfsencolor: "#151714",
        kfmetcolor: "#151714",
        kfecmwfcolor: "#151714",
        kfnasacolor: "#151714",
        kfaiccolor: "#151714",
      });
      ApexCharts.exec("historicaldata", "hideSeries", "ECMWF");
      ApexCharts.exec("historicaldata", "hideSeries", "WeatherAPI");
      ApexCharts.exec("historicaldata", "hideSeries", "AIC");
      ApexCharts.exec("historicaldata", "hideSeries", "Meteo Blue");
      ApexCharts.exec("historicaldata", "hideSeries", "Nasa Power Larc");
      ApexCharts.exec("historicaldata", "hideSeries", "Online Sensor");
      //Show current series even if it is hidden
      ApexCharts.exec("historicaldata", "showSeries", "OpenWeather");
    }
     else if (e.target.id == "aic") {
      this.setState({
        kfaiccolor: "#94de71",
        kfdcolor: "#151714",
        kfowcolor: "#151714",
        kfallcolor: "#151714",
        kfsencolor: "#151714",
        kfmetcolor: "#151714",
        kfecmwfcolor: "#151714",
        kfnasacolor: "#151714",
      });
      ApexCharts.exec("historicaldata", "hideSeries", "ECMWF");
      ApexCharts.exec("historicaldata", "hideSeries", "WeatherAPI");
      ApexCharts.exec("historicaldata", "hideSeries", "OpenWeather");
      ApexCharts.exec("historicaldata", "hideSeries", "Meteo Blue");
      ApexCharts.exec("historicaldata", "hideSeries", "Nasa Power Larc");
      ApexCharts.exec("historicaldata", "hideSeries", "Online Sensor");
      //Show current series even if it is hidden
      ApexCharts.exec("historicaldata", "showSeries", "AIC");
    } else if (e.target.id == "nasa") {
      this.setState({
        kfnasacolor: "#94de71",
        kfdcolor: "#151714",
        kfowcolor: "#151714",
        kfallcolor: "#151714",
        kfsencolor: "#151714",
        kfmetcolor: "#151714",
        kfecmwfcolor: "#151714",
        kfaiccolor: "#151714",
      });
      ApexCharts.exec("historicaldata", "hideSeries", "ECMWF");
      ApexCharts.exec("historicaldata", "hideSeries", "WeatherAPI");
      ApexCharts.exec("historicaldata", "hideSeries", "OpenWeather");
      ApexCharts.exec("historicaldata", "hideSeries", "AIC");
      ApexCharts.exec("historicaldata", "hideSeries", "Meteo Blue");
      ApexCharts.exec("historicaldata", "hideSeries", "Online Sensor");
      //Show current series even if it is hidden
      ApexCharts.exec("historicaldata", "showSeries", "Nasa Power Larc");
    } else if (e.target.id == "ecmwf") {
      this.setState({
        kfecmwfcolor: "#94de71",
        kfdcolor: "#151714",
        kfowcolor: "#151714",
        kfallcolor: "#151714",
        kfsencolor: "#151714",
        kfmetcolor: "#151714",
        kfnasacolor: "#151714",
        kfaiccolor: "#151714",
      });

      ApexCharts.exec("historicaldata", "hideSeries", "WeatherAPI");
      ApexCharts.exec("historicaldata", "hideSeries", "OpenWeather");
      ApexCharts.exec("historicaldata", "hideSeries", "AIC");
      ApexCharts.exec("historicaldata", "hideSeries", "Meteo Blue");
      ApexCharts.exec("historicaldata", "hideSeries", "Nasa Power Larc");
      ApexCharts.exec("historicaldata", "hideSeries", "Online Sensor");
      //Show current series even if it is hidden
      ApexCharts.exec("historicaldata", "showSeries", "ECMWF");
    } else if (e.target.id == "mb") {
      this.setState({
        kfmetcolor: "#94de71",
        kfdcolor: "#151714",
        kfowcolor: "#151714",
        kfallcolor: "#151714",
        kfsencolor: "#151714",
        kfecmwfcolor: "#151714",
        kfnasacolor: "#151714",
        kfaiccolor: "#151714",
      });
      ApexCharts.exec("historicaldata", "hideSeries", "ECMWF");
      ApexCharts.exec("historicaldata", "hideSeries", "WeatherAPI");
      ApexCharts.exec("historicaldata", "hideSeries", "OpenWeather");
      ApexCharts.exec("historicaldata", "hideSeries", "AIC");
      ApexCharts.exec("historicaldata", "hideSeries", "Nasa Power Larc");
      ApexCharts.exec("historicaldata", "hideSeries", "Online Sensor");
      //Show current series even if it is hidden
      ApexCharts.exec("historicaldata", "showSeries", "Meteo Blue");
    } else if (e.target.id == "Online") {
      this.setState({
        kfsencolor: "#94de71",
        kfdcolor: "#151714",
        kfowcolor: "#151714",
        kfallcolor: "#151714",
        kfmetcolor: "#151714",
        kfecmwfcolor: "#151714",
        kfnasacolor: "#151714",
        kfaiccolor: "#151714",
      });
      ApexCharts.exec("historicaldata", "hideSeries", "ECMWF");
      ApexCharts.exec("historicaldata", "hideSeries", "WeatherAPI");
      ApexCharts.exec("historicaldata", "hideSeries", "OpenWeather");
      ApexCharts.exec("historicaldata", "hideSeries", "AIC");
      ApexCharts.exec("historicaldata", "hideSeries", "Meteo Blue");
      ApexCharts.exec("historicaldata", "hideSeries", "Nasa Power Larc");
      //Show current series even if it is hidden
      ApexCharts.exec("historicaldata", "showSeries", "Online Sensor");
    } else if (e.target.id == "All") {
      this.setState({
        kfallcolor: "#94de71",
        kfdcolor: "#151714",
        kfowcolor: "#151714",
        kfsencolor: "#151714",
        kfmetcolor: "#151714",
        kfecmwfcolor: "#151714",
        kfnasacolor: "#151714",
        kfaiccolor: "#151714",
      });
      ApexCharts.exec("historicaldata", "showSeries", "ECMWF");
      ApexCharts.exec("historicaldata", "showSeries", "WeatherAPI");
      ApexCharts.exec("historicaldata", "showSeries", "OpenWeather");
      ApexCharts.exec("historicaldata", "showSeries", "AIC");
      ApexCharts.exec("historicaldata", "showSeries", "Meteo Blue");
      ApexCharts.exec("historicaldata", "showSeries", "Nasa Power Larc");
      ApexCharts.exec("historicaldata", "showSeries", "Online Sensor");
    }
  }
  disabledDate(current) {
    let customDate = moment().format("2021-04-01");
    return (
      (current && current < moment(customDate, "YYYY-MM-DD")) ||
      (current && current > moment().endOf("day"))
    );
  }

  render() {
    const selectpara = (e) => {
      this.setState({ parameter: e.target.value });
    };
    return (
      <React.Fragment>
        <div className="page-content">
          <Container>
            <Card style={{ backgroundColor: "#e5ebee" }}>
              <CardHeader style={{ backgroundColor: "#A4C1D2" }}>
                <Row xl="12">
                  <Col>
                    <h4
                      className="card-title"
                      style={{ padding: "5px", fontSize: "18px" }}
                    >
                      {" "}
                      Historical Data
                    </h4>
                  </Col>
                  <Col>
                    <Link to="dashboard">
                      <i
                        className="ri-close-fill"
                        style={{ fontSize: "20px", float: "right" }}
                      ></i>
                    </Link>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col xs={12}>
                    <Card>
                      <CardBody>
                        Parameter -{" "}
                        {this.capitalizeFirstLetter(this.state.peril)} |
                        Location - {this.state.location}
                        <center>
                          <img
                            src={loader}
                            height={"200px"}
                            style={
                              this.state.showchart ? { display: "none" } : {}
                            }
                          ></img>
                        </center>
                        <div
                          style={
                            this.state.showchart ? {} : { display: "none" }
                          }
                        >
                          <ButtonToolbar>
                            <ButtonGroup size="sm" className="mt-2">
                              <Button
                                color="light"
                                active={this.state.active}
                                onClick={() => this.datechange("one")}
                              >
                                Today
                              </Button>
                              <Button
                                color="light"
                                active={this.state.active_week}
                                onClick={() => this.datechange("week")}
                              >
                                Last 7 days
                              </Button>
                              {/* <Button
                                color="light"
                                active={this.state.active_month}
                                onClick={() => this.datechange("month")}
                              >
                                Last 30 days
                              </Button> */}
                              {/* <Button
                                color="light"
                                active={this.state.active_kharif}
                                onClick={() => this.datechange("kharif")}
                              >
                                Kharif 2020
                              </Button>
                              <Button
                                color="light"
                                active={this.state.active_rabi}
                                onClick={() => this.datechange("rabi")}
                              >
                                Rabi 2020-21
                              </Button> */}
                            </ButtonGroup>
                            <RangePicker
                              size={"small"}
                              className="hist-date"
                              onChange={this.onChangedate}
                              format="DD-MM-YYYY"
                              disabledDate={this.disabledDate}
                            />
                            &nbsp;
                            &nbsp;&nbsp;
                            <center
                              style={
                                this.state.showbutton? {} : { display: "none" }
                              }
                            >
                              <p style={{ color: "red", fontSize: "10px" }} className="hist-date-msg">
                                {"Please select a date range between 15 days"}
                              </p>
                            </center>
                          </ButtonToolbar>

                          <Chart
                            series={this.state.series}
                            options={this.state.options}
                            type="line"
                            height="310"
                          />

                          <div style={{ "text-align": "center" }}>
                            <span
                              class="dot"
                              style={{ "background-color": "#66DA26" }}
                            ></span>
                            <p
                              id="ds"
                              onClick={this.handleKfilter}
                              style={{
                                cursor: "pointer",
                                display: "inline-block",
                                "font-size": "12px",
                                "font-family": "Segoe UI",
                                paddingRight: "10px",
                                paddingLeft: "4px",
                                color: this.state.kfdcolor,
                              }}
                            >
                              WeatherAPI
                            </p>

                            <span
                              class="dot"
                              style={{ "background-color": "#2E93fA" }}
                            ></span>
                            <p
                              id="ow"
                              onClick={this.handleKfilter}
                              style={{
                                cursor: "pointer",
                                display: "inline-block",
                                "font-size": "12px",
                                "font-family": "Segoe UI",
                                paddingRight: "10px",
                                paddingLeft: "4px",
                                color: this.state.kfowcolor,
                              }}
                            >
                              OpenWeather
                            </p>
                            {/* <span
                              class="dot"
                              style={{ "background-color": "#9c59ff" }}
                            ></span>
                            <p
                              id="aic"
                              onClick={this.handleKfilter}
                              style={{
                                cursor: "pointer",
                                display: "inline-block",
                                "font-size": "12px",
                                "font-family": "Segoe UI",
                                paddingRight: "10px",
                                paddingLeft: "4px",
                                color: this.state.kfaiccolor,
                              }}
                            >
                              AIC
                            </p>
                            <span
                              class="dot"
                              style={{ "background-color": "#FF4500" }}
                            ></span>
                            <p
                              id="nasa"
                              onClick={this.handleKfilter}
                              style={{
                                cursor: "pointer",
                                display: "inline-block",
                                "font-size": "12px",
                                "font-family": "Segoe UI",
                                paddingRight: "10px",
                                paddingLeft: "4px",
                                color: this.state.kfnasacolor,
                              }}
                            >
                              Nasa
                            </p>
                            <span
                              class="dot"
                              style={{ "background-color": "#ff05e6" }}
                            ></span>
                            <p
                              id="ecmwf"
                              onClick={this.handleKfilter}
                              style={{
                                cursor: "pointer",
                                display: "inline-block",
                                "font-size": "12px",
                                "font-family": "Segoe UI",
                                paddingRight: "10px",
                                paddingLeft: "4px",
                                color: this.state.kfecmwfcolor,
                              }}
                            >
                              ECMWF
                            </p>
                            <span
                              class="dot"
                              style={{ "background-color": "#FF9800" }}
                            ></span>
                            <p
                              id="mb"
                              onClick={this.handleKfilter}
                              style={{
                                cursor: "pointer",
                                display: "inline-block",
                                "font-size": "12px",
                                "font-family": "Segoe UI",
                                paddingRight: "10px",
                                paddingLeft: "4px",
                                color: this.state.kfmetcolor,
                              }}
                            >
                              MeteoBlue
                            </p> */}

                            <span
                              class="dot"
                              style={{ "background-color": "#0b6b75" }}
                            ></span>
                            <p
                              id="Online"
                              onClick={this.handleKfilter}
                              style={{
                                cursor: "pointer",
                                display: "inline-block",
                                "font-size": "12px",
                                "font-family": "Segoe UI",
                                paddingRight: "10px",
                                paddingLeft: "4px",
                                color: this.state.kfsencolor,
                              }}
                            >
                              Online Sensor
                            </p>
                            <span
                              class="dot"
                              style={{ "background-color": "#9bd6e8" }}
                            ></span>
                            <p
                              id="All"
                              onClick={this.handleKfilter}
                              style={{
                                cursor: "pointer",
                                display: "inline-block",
                                "font-size": "12px",
                                "font-family": "Segoe UI",
                                paddingRight: "10px",
                                paddingLeft: "4px",
                                color: this.state.kfallcolor,
                              }}
                            >
                              All
                            </p>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
