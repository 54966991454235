import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Spinner,
} from "reactstrap";
import GWDiagnosticMap from "../Adm-components/GWdiagnosticMap";
import GWDiagnosticTable from "../Adm-components/GWdiagnosticTable";
import validate_Token from "../../validateToken.js";
import { withNamespaces } from "react-i18next";
import Moment from "moment";
class GatewayDiagnostic extends Component {
  constructor(props) {
    super(props);
    this.state = {
      series: [42, 26, 42],
      options: {
        labels: ["Product A", "Product B", "Product C"],
        plotOptions: {
          pie: {
            donut: {
              size: "50%",
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        colors: ["#5664d2", "#1cbb8c", "#eeb902"],
      },
      livestatus: [],
      mdbtable: [],
      gatewaydetails: "",
      isdata: false,
    };
  }
  async getgatewayStatus() {
    let getthis = this;
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: "Token " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    };
    var searchresponse = await fetch(
      process.env.REACT_APP_APIEND + `weather/gateway-status`,
      requestOptions
    ).then((res) => {
      return res.json();
    });
    this.setState({
      livestatus: searchresponse.result,
    });
    this.generatetable(searchresponse.result);
    // console.log("CURRENT STATUS", searchresponse.result);
  }
  componentDidMount() {
    validate_Token();
    this.getgatewayStatus();
  }
  getgateway(gateway) {
    if (gateway == "Gateway_1") {
      return "Gateway 1";
    }
    if (gateway == "Gateway_2") {
      return "Gateway 2";
    }
    if (gateway == "Gateway_3") {
      return "Gateway 3";
    }
    if (gateway == "Gateway_4") {
      return "Gateway 4";
    }
  }
  getrua(loc) {
    if (loc == "Gateway_1") {
      return "Chittur";
    }
    if (loc == "Gateway_2") {
      return "Kozhinjampara";
    }
    if (loc == "Gateway_3") {
      return "Vadakarapathy";
    }
    if (loc == "Gateway_4") {
      return "Kozhinjampara";
    }
  }
  generatetable(data) {
    let getthis = this;
    let tabledata = [];
    data.map(function (item, index, data) {
      var newdata = {
        id: index + 1,
        station: getthis.getgateway(item["gateway "]),
        location: getthis.getlocation(item["gateway "]),
        rua: getthis.getrua(item["gateway "]),
        datetime: Moment(item["last_seen "]).format("DD-MM-YYYY HH:mm:ss"),
        status: getthis.checkactive(item["status "]),
      };
      tabledata.push(newdata);
    });
    this.setState({
      mdbtable: tabledata,
      isdata: true,
    });
  }
  getlocation(loc) {
    if (loc == "Gateway_1") {
      return "Chittur Municipality Office";
    }
    if (loc == "Gateway_2") {
      return "Bharathamatha Arts and Science College";
    }
    if (loc == "Gateway_3") {
      return "Ahalia Campus";
    }
    if (loc == "Gateway_4") {
      return "KWA Water Treatment Plant";
    }
  }
  checkactive(status) {
    if (status == true) {
      return "Active";
    } else {
      return "Inactive";
    }
  }
  render() {
    const { t, i18n } = this.props;
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Row>
              <Col xs={12}>
                <h4
                  className="card-title"
                  style={{ padding: "5px", fontSize: "18px" }}
                >
                  {t("Gateway Diagnostics")}{" "}
                </h4>
              </Col>
            </Row>

            <Row>
              <Col lg={12}>
                {this.state.isdata ? (
                  <GWDiagnosticMap livedata={this.state.livestatus} />
                ) : (
                  <center>
                    <Spinner color="dark" />
                  </center>
                )}
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    {this.state.isdata ? (
                      <GWDiagnosticTable livedata={this.state.mdbtable} />
                    ) : (
                      <div></div>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default withNamespaces()(GatewayDiagnostic);
