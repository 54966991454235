import React, { Component } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Row,
  Col,
  FormGroup,
  Label,
  Table,
  Container,
  Button,
} from "reactstrap";
// import './table.css'
import defaultcontent from "./Tm_condition_template";
import "antd/dist/antd.css";
import { Link } from "react-router-dom";
import { Tabs, Input, Radio, Checkbox } from "antd";
import { DatePicker } from "antd";
import validate_Token from "../../validateToken.js";
//import css
import "../../assets/scss/tab.css";
import { toast } from "react-toastify";
const TabPane = Tabs.TabPane;
const { TextArea } = Input;
const { RangePicker } = DatePicker;
toast.configure();
class Termsheet_ADD extends Component {
  constructor(props) {
    super(props);
    this.newTabIndex = 0;

    const panes = [
      {
        title: "Phase 1",
        content: defaultcontent(),
        key: "1",
        closable: false,
      },
    ];
    this.state = {
      activeKey: panes[0].key,
      panes,
      season: "Kharif 2020-21",
      crop: "Paddy",
      peril: "High Temperature",
      phase: "",
      startDate: "",
      endDate: "",
      phase_maximum_payout: 0,
      is_daily: "N",
      is_phase: "Y",
      is_peril_phase_end: "No",
      unit_cost: "",
      unit_cost2: 0,
      max_payout: 0,
      is_day_end_alert: "No",
      condition: "",
      index: "",
      trigger_value: 0.0,
      strike: 0.0,
      exit_value: 0.0,
      notional_payout: 0.0,
      fixed_payout: 0.0,
      rule: [],
      daily_rule: [],
      day_end_alert_rule: [],
      rows: [{}],
    };
    this.onexecutionChange = this.onexecutionChange.bind(this);
    this.onChangefield = this.onChangefield.bind(this);
    this.onChangedate = this.onChangedate.bind(this);
    this.saveclick = this.saveclick.bind(this);
    this.otherselection = this.otherselection.bind(this);
    this.handleAddRow = this.handleAddRow.bind(this);
    this.handleRemoveSpecificRow = this.handleRemoveSpecificRow.bind(this);
    this.onConditionchange = this.onConditionchange.bind(this);
  }

  onChange = (activeKey) => {
    this.setState({ activeKey });
  };
  onEdit = (targetKey, action) => {
    this[action](targetKey);
  };
  handleAddRow = () => {
    const item = {
      condition: "",
      index: "",
      trigger_value: "",
      strike: "",
      exit_value: "",
      notional_payout: "",
      fixed_payout: "",
    };
    this.setState({
      rows: [...this.state.rows, item],
    });
  };
  add = () => {
    const panes = this.state.panes;
    const activeKey = `newTab${this.newTabIndex++}`;
    let phase_no = panes.length;
    phase_no = phase_no + 1;
    panes.push({
      title: "Phase " + phase_no,
      content: defaultcontent(),
      key: activeKey,
    });
    this.setState({ panes, activeKey });
  };
  remove = (targetKey) => {
    let activeKey = this.state.activeKey;
    let lastIndex;
    this.state.panes.forEach((pane, i) => {
      if (pane.key === targetKey) {
        lastIndex = i - 1;
      }
    });
    const panes = this.state.panes.filter((pane) => pane.key !== targetKey);
    if (lastIndex >= 0 && activeKey === targetKey) {
      activeKey = panes[lastIndex].key;
    }
    this.setState({ panes, activeKey });
  };
  componentDidMount() {
    validate_Token();
  }
  onChangefield(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
    // console.log("ONCHANGE", e.target.name);
  }
  onChangedate(date, dateString) {
    const date1 = new Date(dateString[0]);
    const date2 = new Date(dateString[1]);
    var from_dd = String(date1.getDate()).padStart(2, "0");
    var from_mm = String(date1.getMonth() + 1).padStart(2, "0"); //January is 0!
    var from_yyyy = date1.getFullYear();
    var from_date = from_yyyy + "-" + from_mm + "-" + from_dd;

    var to_dd = String(date2.getDate()).padStart(2, "0");
    var to_mm = String(date2.getMonth() + 1).padStart(2, "0"); //January is 0!
    var to_yyyy = date2.getFullYear();
    var to_date = to_yyyy + "-" + to_mm + "-" + to_dd;

    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    this.setState({
      startDate: from_date,
      endDate: to_date,
    });
  }

  onConditionchange = (idx) => (e) => {
    const { name, value } = e.target;
    let rows = [...this.state.rows];

    // rows[idx] = {
    //   [name]: value,
    // };
    rows[idx][name] = value;

    // console.log("ONCHANGEEE00", rows);
  };
  onexecutionChange(e) {
    if (e.target.value == "day") {
      this.setState({
        is_daily: "Y",
        is_phase: "N",
      });
    } else if (e.target.value == "phase") {
      this.setState({
        is_daily: "N",
        is_phase: "Y",
      });
    }
  }
  async saveclick() {
    this.add();
    var new_list = {
      season: this.state.season,
      crop: this.state.crop,
      peril: this.state.peril,
      phase: this.state.phase,
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      phase_maximum_payout: this.state.phase_maximum_payout,
      is_daily: this.state.is_daily,
      is_phase: this.state.is_phase,
      is_peril_phase_end: this.state.is_peril_phase_end,
      unit_cost: this.state.unit_cost,
      unit_cost2: this.state.unit_cost2,
      max_payout: this.state.max_payout,
      is_day_end_alert: this.state.is_day_end_alert,
      condition: this.state.rows,
      rule: this.state.rule,
      daily_rule: this.state.daily_rule,
      day_end_alert_rule: this.state.day_end_alert_rule,
    };
    // console.log("SEARCH DATA", new_list);
    new_list = JSON.stringify(new_list);

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + localStorage.getItem("token"),
      },
      body: new_list,
    };
    try {
      // var adddata = await fetch(
      //   "https://smc.misteo.co/smart_crop/termsheet-condition-add",
      //   requestOptions
      // ).then((res) => {
      //   return res.json();
      // });
    } catch (err) {
      toast.error("Something's not right");
    }
  }
  otherselection(e) {
    if (e.target.value == "isphaseend") {
      this.setState({
        is_peril_phase_end: true,
      });
    } else if (e.target.value == "isdayend") {
      this.setState({
        is_day_end_alert: "Yes",
      });
    }
  }
  handleRemoveSpecificRow = (idx) => () => {
    const rows = [...this.state.rows];
    rows.splice(idx, 1);
    this.setState({ rows });
  };
  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Card style={{ backgroundColor: "#e5ebee" }}>
            <CardHeader style={{ backgroundColor: "#A4C1D2" }}>
              <Row>
                <Col xs={8}>
                  <h4
                    className="card-title"
                    style={{ padding: "5px", fontSize: "18px" }}
                  >
                    {" "}
                    Add Termsheet Conditions
                  </h4>
                </Col>
                <Col>
                  <Link to="/tm_conditions">
                    <i
                      className="ri-close-fill"
                      style={{ fontSize: "20px", float: "right" }}
                    ></i>
                  </Link>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Container fluid>
                <Row>
                  <Col xs={12}></Col>
                </Row>
                <Card>
                  <CardBody>
                    <Row>
                      <Col>
                        <Col style={{ paddingLeft: "0px" }}>
                          <Row>
                            <Col md="4">
                              <FormGroup>
                                <Label
                                  className="col-md-5 col-form-label"
                                  style={{ fontSize: "14px" }}
                                >
                                  Season{" "}
                                </Label>
                                <select
                                  className="form-select"
                                  md="6"
                                  style={{ fontSize: "11px" }}
                                  name="season"
                                  onChange={this.onChangefield}
                                >
                                   <option value="Rabi 2022-23">Rabi 2022-23</option>
                                  <option value="Kharif 2022">Kharif 2022</option>
                                  <option value="Rabi 2021-22">Rabi 2021-22</option>
                                  <option value="Kharif 2021">Kharif 2021</option>
                                  <option value="Rabi 2020-21">Rabi 2020-21</option>
                                  <option value="Kharif 2020">Kharif 2020</option>
                                </select>
                              </FormGroup>
                            </Col>

                            <Col md="2">
                              <FormGroup>
                                <Label
                                  className="col-md-10 col-form-label"
                                  style={{ fontSize: "14px" }}
                                >
                                  Crop{" "}
                                </Label>
                                <select
                                  className="form-select"
                                  style={{ fontSize: "11px" }}
                                  name="crop"
                                  onChange={this.onChangefield}
                                >
                                  <option value="Mango">Mango</option>
                                  <option value="Paddy">Paddy</option>
                                  <option value="Paddy 2nd Crop">
                                    Paddy 2nd Crop
                                  </option>
                                  <option value="Paddy 3rd Crop">
                                    Paddy 3rd Crop
                                  </option>
                                  <option value="Paddy Winter">
                                    Paddy Winter
                                  </option>
                                  <option value="Veg Group I">
                                    Veg Group I
                                  </option>
                                  <option value="Veg Group I - SMR">
                                    Veg Group I - SMR
                                  </option>
                                </select>
                              </FormGroup>
                            </Col>

                            <Col md="2">
                              <FormGroup>
                                <Label
                                  className="col-md-10 col-form-label"
                                  style={{ fontSize: "14px" }}
                                >
                                  Peril{" "}
                                </Label>
                                <select
                                  className="form-select"
                                  style={{ fontSize: "11px" }}
                                  name="peril"
                                  onChange={this.onChangefield}
                                >
                                 <option value="High Temperature">
                                    High Temperature
                                  </option>
                                  <option value="Deficit Rainfall">
                                    Deficit Rainfall
                                  </option>
                                  <option value="Disease Congenial Climate">
                                    Disease Congenial Climate
                                  </option>
                                  <option value="Dry Spell">Dry Spell</option>
                                  <option value="Excess Rainfall">
                                    Excess Rainfall
                                  </option>
                                  <option value="Wind Speed">Wind Speed</option>
                                  <option value="Unseasonal Rainfall">
                                    Unseasonal Rainfall
                                  </option>
                                  <option value="Wind Speed (A)">
                                    Wind Speed (A)
                                  </option>
                                  <option value="Wind Speed (B)">
                                    Wind Speed (B)
                                  </option>
                                  <option value="Rise in Temperature">
                                  Rise in Temperature
                                  </option>
                                </select>
                              </FormGroup>
                            </Col>
                            <Col md="4">
                              <FormGroup>
                                <Label
                                  className="col-md-10 col-form-label"
                                  style={{ fontSize: "14px" }}
                                >
                                  Execution
                                </Label>
                                <Radio.Group
                                  defaultValue="phase"
                                  buttonStyle="solid"
                                  onChange={this.onexecutionChange}
                                >
                                  <Radio.Button value="day">
                                    Day End
                                  </Radio.Button>
                                  <Radio.Button value="phase">
                                    Phase End
                                  </Radio.Button>
                                </Radio.Group>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col></Col>
                          </Row>
                        </Col>
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={12}>
                        <center></center>
                        <Tabs
                          onChange={this.onChange}
                          activeKey={this.state.activeKey}
                          type="editable-card"
                          onEdit={this.onEdit}
                        >
                          {this.state.panes.map((pane) => (
                            <TabPane
                              tab={pane.title}
                              key={pane.key}
                              closable={pane.closable}
                            >
                              <Row>
                                <Col>
                                  <p
                                    style={{
                                      fontWeight: "bolder",
                                      fontSize: "18px",
                                    }}
                                  >
                                    Basic
                                  </p>
                                  <Row>
                                    <Col md="2">
                                      <FormGroup>
                                        <Label
                                          className="col-md-12 col-form-label nextline"
                                          style={{ fontSize: "14px" }}
                                        >
                                          <br />
                                          Phase
                                        </Label>
                                        <Input
                                          placeholder="Phase"
                                          style={{ width: 100 }}
                                          name="phase"
                                          onChange={this.onChangefield}
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col md="4">
                                      <FormGroup>
                                        <Label
                                          className="col-md-12 col-form-label"
                                          style={{ fontSize: "14px" }}
                                        >
                                          <br />
                                          From Date - To Date
                                        </Label>
                                        <RangePicker
                                          onChange={this.onChangedate}
                                          format="DD-MM-YYYY"
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col md="2">
                                      <FormGroup>
                                        <Label
                                          className="col-md-12 col-form-label"
                                          style={{ fontSize: "14px" }}
                                        >
                                          <span>
                                            Phase Maximum
                                            <br /> Payout
                                          </span>
                                        </Label>
                                        <Input
                                          placeholder="Phase maximum Payout"
                                          style={{ width: 100 }}
                                          name="phase_maximum_payout"
                                          onChange={this.onChangefield}
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col md="2">
                                      <FormGroup>
                                        <Label
                                          className="col-md-12 col-form-label"
                                          style={{
                                            fontSize: "14px",
                                            paddingRight: "0px",
                                          }}
                                        >
                                          <span>
                                            Season <br />
                                            Maximum Payout
                                          </span>
                                        </Label>
                                        <Input
                                          placeholder="Season maximum Payout"
                                          style={{ width: 100 }}
                                          name="max_payout"
                                          onChange={this.onChangefield}
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col md="2">
                                      <FormGroup>
                                        <Label
                                          className="col-md-12 col-form-label"
                                          style={{ fontSize: "14px" }}
                                        >
                                          <br />
                                          Unit Cost
                                        </Label>
                                        <Input
                                          placeholder="Unit Cost"
                                          style={{ width: 100 }}
                                          name="unit_cost"
                                          onChange={this.onChangefield}
                                        />
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                              {/* ---------------------------- */}
                              <Row>
                                <Col>
                                  <p
                                    style={{
                                      fontWeight: "bolder",
                                      fontSize: "18px",
                                    }}
                                  >
                                    Conditions
                                  </p>
                                  <Row style={{ paddingTop: "20px" }}>
                                    <Col md="12">
                                      {" "}
                                      <Table
                                        id="tsconditions"
                                        bordered
                                        style={{ fontSize: "11px" }}
                                        responsive
                                      >
                                        <thead
                                          style={{ backgroundColor: "#ffffff" }}
                                        >
                                          <tr>
                                            {/* <th style={{ width: "130px" }}>
                                              Parameter
                                            </th>
                                            <th style={{ width: "130px" }}>
                                              Operator
                                            </th> */}
                                            <th style={{ width: "130px" }}>
                                              Condition
                                            </th>
                                            <th style={{ width: "130px" }}>
                                              Index
                                            </th>
                                            <th style={{ width: "60px" }}>
                                              Trigger
                                            </th>
                                            <th style={{ width: "60px" }}>
                                              Strike
                                            </th>
                                            <th style={{ width: "60px" }}>
                                              Exit
                                            </th>
                                            <th style={{ width: "60px" }}>
                                              Notional
                                            </th>
                                            <th style={{ width: "60px" }}>
                                              Fixed
                                            </th>
                                            <th style={{ width: "60px" }}>
                                              <button
                                                className="btn-add-row"
                                                onClick={this.handleAddRow}
                                              >
                                                Add&nbsp;Row
                                                {/* <i
                                              className="ri-add-box-fill "
                                              style={{
                                                fontSize: "18px",
                                                cursor: "pointer",
                                              }} />   */}
                                              </button>
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {this.state.rows.map((item, idx) => (
                                            <tr>
                                              <td>
                                                <Input
                                                  placeholder="Condition"
                                                  style={{ width: 100 }}
                                                  name="condition"
                                                  onChange={this.onConditionchange(
                                                    idx
                                                  )}
                                                />
                                              </td>
                                              <td>
                                                <Input
                                                  placeholder="Index"
                                                  style={{ width: 100 }}
                                                  name="index"
                                                  onChange={this.onConditionchange(
                                                    idx
                                                  )}
                                                />
                                              </td>
                                              <td>
                                                <Input
                                                  placeholder="Trigger"
                                                  style={{ width: 60 }}
                                                  name="trigger_value"
                                                  onChange={this.onConditionchange(
                                                    idx
                                                  )}
                                                />
                                              </td>
                                              <td>
                                                <Input
                                                  placeholder="Strike"
                                                  style={{ width: 60 }}
                                                  name="strike"
                                                  onChange={this.onConditionchange(
                                                    idx
                                                  )}
                                                />
                                              </td>
                                              <td>
                                                <Input
                                                  placeholder="Exit"
                                                  style={{ width: 60 }}
                                                  name="exit_value"
                                                  onChange={this.onConditionchange(
                                                    idx
                                                  )}
                                                />
                                              </td>
                                              <td className="alnrighttd">
                                                <Input
                                                  placeholder="Notional"
                                                  style={{ width: 60 }}
                                                  name="notional_payout"
                                                  onChange={this.onConditionchange(
                                                    idx
                                                  )}
                                                />
                                              </td>
                                              <td className="alnrighttd">
                                                <Input
                                                  placeholder="Fixed"
                                                  style={{ width: 60 }}
                                                  name="fixed_payout"
                                                  onChange={this.onConditionchange(
                                                    idx
                                                  )}
                                                />
                                              </td>
                                              <td style={{ width: 60 }}>
                                                <i
                                                  className="ri-delete-bin-fill"
                                                  style={{
                                                    fontSize: "18px",
                                                    cursor: "pointer",
                                                    color: "red",
                                                  }}
                                                  onClick={this.handleRemoveSpecificRow(
                                                    idx
                                                  )}
                                                />
                                              </td>
                                            </tr>
                                          ))}
                                        </tbody>
                                      </Table>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                              {/* ---------------------------- */}
                              <Row>
                                <Col>
                                  <p
                                    style={{
                                      fontWeight: "bolder",
                                      fontSize: "18px",
                                      paddingTop: "20px",
                                    }}
                                  >
                                    Others
                                  </p>
                                  <Row style={{ paddingTop: "20px" }}>
                                    <Col md="12">
                                      <Checkbox
                                        onChange={this.otherselection}
                                        value="isphaseend"
                                      >
                                        Is Phase End
                                      </Checkbox>
                                      <Checkbox value="isdayend">
                                        Is Day End Alert Applicable?
                                      </Checkbox>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col md="6">
                                      <FormGroup>
                                        <Label
                                          className="col-md-12 col-form-label"
                                          style={{ fontSize: "14px" }}
                                        >
                                          <br />
                                          Payout Rules
                                        </Label>
                                        <TextArea
                                          rows={4}
                                          name="rule"
                                          onChange={this.onChangefield}
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col md="5">
                                      <FormGroup>
                                        <Label
                                          className="col-md-12 col-form-label"
                                          style={{ fontSize: "14px" }}
                                        >
                                          <br />
                                          Alert Rules
                                        </Label>
                                        <TextArea
                                          rows={4}
                                          name="daily_rule"
                                          onChange={this.onChangefield}
                                        />
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                              <Row>
                                <Col md="12">
                                  <Button
                                    color="primary"
                                    style={{
                                      "font-size": "10px",
                                      float: "right",
                                    }}
                                    onClick={this.saveclick}
                                  >
                                    Save and Add next phase
                                  </Button>
                                </Col>
                              </Row>
                              <hr />
                              <Row>
                                <Col md="12">
                                  <Link to="/termsheetconditions">
                                    <Button
                                      color="success"
                                      style={{
                                        "font-size": "10px",
                                        float: "right",
                                      }}
                                      onClick={this.saveclick}
                                    >
                                      Save and Close
                                    </Button>
                                  </Link>
                                </Col>
                              </Row>
                            </TabPane>
                          ))}
                        </Tabs>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Container>
            </CardBody>
          </Card>
        </div>
      </React.Fragment>
    );
  }
}
export default Termsheet_ADD;
