import React from "react";
import { Table, Container, Row, Col, FormGroup, Label } from "reactstrap";
import "../Dashboard/table.css";

const Policy = (props) => {
  // console.log("POLICY PROPS", props);
  return (
    <React.Fragment>
      {props.policydata.map((item, index) => (
        <div class="accordion" id="accordionExample1">
        <div class="accordion-item">
          <a class="accordion-header" id="headingOne">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={"#collapsepolicy"+ String(index + 1)}
              aria-expanded="false"
              aria-controls="collapsepolicy"
            >
              <span className="short-text">
                <div class="container">
                  <div class="row">
                    <div class="col-sm-4">
                      <a
                        style={{
                          fontSize: "12px",
                          color: "#a3a3a3",
                          paddingRight: "3px",
                        }}
                      >
                          Application Id:
                        </a>
                      </div>
                      <div class="col-sm-8">
                        <a style={{ fontSize: "12px" }}>
                          {item.application_id}
                        </a>
                      </div>
                    </div>
                  </div>
                </span>

                <span className="full-text">
                  <a
                    style={{
                      fontSize: "12px",
                      color: "#a3a3a3",
                      paddingRight: "3px",
                    }}
                  >
                    Application Id:
                  </a>
                  <a style={{ fontSize: "12px" }}>
                    {item.application_id}
                  </a>
                </span>
              </button>
            </a>
            <div
              id={"collapsepolicy"+String(index + 1)}
              class="accordion-collapse collapse"
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample1"
            >
              <div class="accordion-body">
                <Row xs={12}>
                  <Col sm={3}>
                    <FormGroup>
                      <Label
                        className="col-md-8"
                        style={{ fontSize: "12px", color: "#a3a3a3" }}
                      >
                        Scheme Name{" "}
                      </Label>
                      <Col>{item.scheme}</Col>
                    </FormGroup>
                  </Col>
                  <Col sm={3}>
                    <FormGroup>
                      <Label
                        className="col-md-8"
                        style={{ fontSize: "12px", color: "#a3a3a3" }}
                      >
                        Application Source
                      </Label>
                      <Col>
                        {/* {props.policydata.application_source} */}
                        NIL
                      </Col>
                    </FormGroup>
                  </Col>
                  <Col sm={3}>
                    <FormGroup>
                      <Label
                        className="col-md-8"
                        style={{ fontSize: "12px", color: "#a3a3a3" }}
                      >
                        Insurance company Code & Name
                      </Label>
                      <Col>
                        {item.insurance_company}
                        
                      </Col>
                    </FormGroup>
                  </Col>
                  <Col sm={3}>
                    <FormGroup>
                      <Label
                        className="col-md-8"
                        style={{ fontSize: "12px", color: "#a3a3a3" }}
                      >
                        Farmer Type
                      </Label>
                      <Col>NIL</Col>
                    </FormGroup>
                  </Col>
                </Row>

                <Row xs={12}>
                  <Col sm={3}>
                    <FormGroup>
                      <Label
                        className="col-md-8"
                        style={{ fontSize: "12px", color: "#a3a3a3" }}
                      >
                        Farmer Category
                      </Label>
                      <Col>
                        {/* {props.policydata.farmer_category} */}
                        NIL
                      </Col>
                    </FormGroup>
                  </Col>
                  <Col sm={3}>
                    <FormGroup>
                      <Label
                        className="col-md-8"
                        style={{ fontSize: "12px", color: "#a3a3a3" }}
                      >
                        Nature of Farmer
                      </Label>
                      <Col>
                        {/* {props.policydata.nature_of_farmer} */}
                        NIL
                      </Col>
                    </FormGroup>
                  </Col>
                  <Col sm={3}>
                    <FormGroup>
                      <Label
                        className="col-md-8"
                        style={{ fontSize: "12px", color: "#a3a3a3" }}
                      >
                        Bank Account Number
                      </Label>
                      <Col>
                        {/* {props.policydata.account_number} */}
                        NIL
                      </Col>
                    </FormGroup>
                  </Col>
                  <Col sm={3}>
                    <FormGroup>
                      <Label
                        className="col-md-8"
                        style={{ fontSize: "12px", color: "#a3a3a3" }}
                      >
                        Bank Account Type
                      </Label>
                      <Col>
                        {/* {props.policydata.farmer_account_type} */}
                        NIL
                      </Col>
                    </FormGroup>
                  </Col>
                </Row>

                <Row xs={12}>
                  <Col sm={3}>
                    <FormGroup>
                      <Label
                        className="col-md-8"
                        style={{ fontSize: "12px", color: "#a3a3a3" }}
                      >
                        Bank Branch
                      </Label>
                      <Col>
                        {/* {props.policydata.branch_name} */}
                        NIL
                      </Col>
                    </FormGroup>
                  </Col>
                  <Col sm={3}>
                    <FormGroup>
                      <Label
                        className="col-md-6"
                        style={{ fontSize: "12px", color: "#a3a3a3" }}
                      >
                        IFSC
                      </Label>
                      <Col>
                        {/* {props.policydata.ifsc} */}
                        NIL
                      </Col>
                    </FormGroup>
                  </Col>
                  <Col sm={3}>
                    <FormGroup>
                      <Label
                        className="col-md-6"
                        style={{ fontSize: "12px", color: "#a3a3a3" }}
                      >
                        Gross Premium
                      </Label>
                      <Col>
                        {/* {parseFloat(props.policydata.gross_premium).toFixed(2)} */}
                        NIL
                      </Col>
                    </FormGroup>
                  </Col>
                  <Col sm={3}>
                    <FormGroup>
                      <Label
                        className="col-md-6"
                        style={{ fontSize: "12px", color: "#a3a3a3" }}
                      >
                        Sum Insured
                      </Label>
                      <Col>
                        {/* {parseFloat(props.policydata.sum_insured).toFixed(2)} */}
                        NIL
                      </Col>
                    </FormGroup>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
      ))}
    </React.Fragment>
  );
};

export default Policy;
