import React, { Component } from "react";
import {
  Card,
  CardBody,
  Row,
  Col,
  CardText,
  FormGroup,
  NavLink,
  NavItem,
  Nav,
  Label,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container,
  Button,
  Spinner,
} from "reactstrap";
import { Link } from "react-router-dom";
import "antd/dist/antd.css";
import validate_Token from "../../validateToken.js";
import { MDBDataTable } from "mdbreact";
//import css
import "../../assets/scss/tab.css";
import { toast } from "react-toastify";
import "./css/tsconditions.css";
import Moment from "moment";
toast.configure();
class FarmerSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal_center: false,
      modal_center1: false,
      modal_delete: false,
      modal_condition: false,
      conditions_list: [],
      current_perildelete: "",
      perilData: [
        "High Temperature",
        "Deficit Rainfall",
        "Disease Congenial Climate",
        "Dry Spell",
        "Excess Rainfall",
      ],
      cropData: [
        "Paddy",
        "Veg Group I",
        "Mango",
        "Paddy 2nd Crop",
        "Paddy 3rd Crop",
        "Paddy Winter",
        "Veg Group I - SMR",
      ],
      seasonData: [],
      crop: "Paddy",
      season: "Kharif 2020-21",
      peril: "all",
      perilseasonData: [],
      conditions: [],
      returnperil: "",
      tabledata: "",
      showmessage: true,
      showloader: false,
      phasedata: [],
      rules: [],
    };
    this.handleSeasonchange = this.handleSeasonchange.bind(this);
    this.handleCropchange = this.handleCropchange.bind(this);
    this.getconditions = this.getconditions.bind(this);
    this.handlePerilchange = this.handlePerilchange.bind(this);
    this.seerules = this.seerules.bind(this);
    this.tog_center = this.tog_center.bind(this);
    this.update_rules = this.update_rules.bind(this);
    this.deletecondition = this.deletecondition.bind(this);
    this.seeconditions = this.seeconditions.bind(this);
    this.tog_conditions = this.tog_conditions.bind(this);
  }
  tog_center() {
    this.setState((prevState) => ({
      modal_center: !prevState.modal_center,
    }));
  }
  update_rules() {
    this.setState((prevState) => ({
      modal_center1: !prevState.modal_center1,
    }));
  }
  tog_conditions() {
    this.setState((prevState) => ({
      modal_condition: !prevState.modal_condition,
    }));
  }
  async fetchseason() {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + localStorage.getItem("token"),
      },
    };
    try {
      var seasonData = await fetch(
        process.env.REACT_APP_APIEND + `smart_crop/term-sheet-season-crop`,
        requestOptions
      ).then((res) => {
        return res.json();
      });
      this.setState({
        perilseasonData: seasonData.result,
      });

      var newseasonData = [];
      {
        seasonData.result.map(function (item, index, data) {
          newseasonData.push(Object.keys(item));
        });
      }
      console.log("NEW DATA", newseasonData);
      this.setState({
        seasonData: newseasonData,
      });
    } catch (err) {
      toast.error("Something's not right");
    }
  }
  handleSeasonchange(e) {
    this.setState({
      season: e.target.value,
    });
    this.setState({
      cropData: [],
    });
    let selected_index = e.target.selectedIndex;
    let season = e.target.value;
    let allcropdata = this.state.perilseasonData;
    var seasoncrop = allcropdata[selected_index - 0];
    console.log("Season crop", seasoncrop);
    var croplist = seasoncrop[Object.keys(seasoncrop)[0]];
    var list_array = [];
    croplist.map((item, index, data) => {
      list_array.push(item);
    });
    this.setState({
      cropData: list_array,
    });
  }

  async handleCropchange(e) {
    this.setState({
      crop: e.target.value,
    });
    let searchdata = {
      season: this.state.season,
      crop: e.target.value,
    };
    searchdata = JSON.stringify(searchdata);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + localStorage.getItem("token"),
      },
      body: searchdata,
    };
    try {
      var perildata = await fetch(
        process.env.REACT_APP_APIEND + `smart_crop/get-perils`,
        requestOptions
      ).then((res) => {
        return res.json();
      });
      var newperils = perildata.result[0].perils;
      console.log("PERIL DATA", newperils);
      this.setState({
        perilData: newperils,
      });
    } catch (err) {
      toast.error("Check Season");
    }
  }
  handlePerilchange(e) {
    this.setState({
      peril: e.target.value,
    });
  }
  async getconditions() {
    this.setState({
      showloader: true,
      showmessage: false,
    });
    let searchdata = {
      crop: this.state.crop,
      peril: this.state.peril,
      season: this.state.season,
    };
    searchdata = JSON.stringify(searchdata);

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + localStorage.getItem("token"),
      },
      body: searchdata,
    };
    try {
      var conditiondata = await fetch(
        process.env.REACT_APP_APIEND + `smart_crop/term-sheet-condition/term-condition`,
        requestOptions
      ).then((res) => {
        return res.json();
      });
      if (conditiondata.code == 200) {
        this.setState({
          showloader: false,
        });
      }
      var phase = conditiondata.result[0];
      var peril_name = Object.keys(phase);
      var phasedata = phase[peril_name].phase_data;

      this.setState({
        conditions: conditiondata.result,
        phasedata: phasedata,
      });
    } catch (err) {
      toast.error("Something's not right");
    }
  }
  checknull(value) {
    if (value != null && value > 0) {
      return parseFloat(value).toFixed(2);
    } else if (value == "true") {
      return "True";
    } else if (value == "false") {
      return "False";
    } else {
      return "0.00";
    }
  }
  spellcheckperil(peril) {
    if (peril == "High Temperature") {
      return "High Temperature";
    }
    if (peril == "Deficit Rainfall") {
      return "Deficit Rainfall";
    }
    if (peril == "Disease Congenial Climate") {
      return "Disease Congenial Climate";
    }
    if (peril == "Dry Spell") {
      return "Dry Spell";
    }
    if (peril == "Excess Rainfall") {
      return "Excess Rainfall";
    }
    if (peril == "Rise in Temperature") {
      return "Rise in Temperature";
    } else {
      return peril;
    }
  }
  seerules(rules) {
    console.log("RULES", rules);
    this.setState({
      rules: rules,
    });
  }
  checkphase(phase) {
    if (phase == "1") {
      return "Phase 1";
    }
    if (phase == "2") {
      return "Phase 2";
    }
    if (phase == "3") {
      return "Phase 3";
    }
    if (phase == "4") {
      return "Phase 4";
    }
  }

  seeconditions(conditions) {
    this.setState({
      conditions_list: conditions,
    });
    console.log("lists: ", conditions[0].index);
  }
  componentDidMount() {
    validate_Token();
    this.fetchseason();
  }
  addconditions() {
    console.log("CLICK ADD CONDITION");
  }
  deletecondition() {
    this.setState({
      modal_delete: true,
      // current_perildelete:this.spellcheckperil(peril)
    });
  }
  tog_delete() {
    this.setState((prevState) => ({
      modal_delete: !prevState.modal_small,
    }));
  }
  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Row>
              <Col xs={12}>
                <h4
                  className="card-title"
                  style={{ padding: "5px", fontSize: "18px" }}
                >
                  {" "}
                  Termsheet Conditions
                </h4>
              </Col>
            </Row>
            <Row>
              <Col>
                <Card style={{ backgroundColor: "#f1f6fb" }}>
                  <CardBody>
                    <Col>
                      <Row>
                        <div>
                          <Link
                            to={{
                              pathname: "/add-conditions",
                              state: {
                                seasonData: this.state.seasonData,
                                cropData: this.state.cropData,
                                perilData: this.state.perilData,
                              },
                            }}
                            className="btn btn-success mb-2 btn-adduser"
                            style={{ fontSize: "11px" }}
                          >
                            <i className="mdi mdi-plus mr-2"></i> Add Condition
                          </Link>
                        </div>
                        <Col md="3">
                          <FormGroup>
                            <Label className="col-sm col-form-label">
                              Season
                            </Label>
                            <select
                              className="form-select"
                              style={{ fontSize: "11px" }}
                              onChange={this.handleSeasonchange}
                            >
                              {this.state.seasonData.length > 0 &&
                                this.state.seasonData.map((item) => (
                                  <option value={item} key={item}>
                                    {item}
                                  </option>
                                ))}
                            </select>
                          </FormGroup>
                        </Col>

                        <Col md="3">
                          <FormGroup>
                            <Label className="col-sm col-form-label">
                              Crop
                            </Label>
                            <select
                              className="form-select"
                              style={{ fontSize: "11px" }}
                              onChange={this.handleCropchange}
                            >
                              {this.state.cropData.length > 0 &&
                                this.state.cropData.map((item) => (
                                  <option value={item} key={item}>
                                    {item}
                                  </option>
                                ))}
                            </select>
                          </FormGroup>
                        </Col>

                        <Col md="3">
                          <Label className=" col-sm col-form-label">
                            Perils
                          </Label>
                          <select
                            className="form-select"
                            style={{ fontSize: "11px" }}
                            onChange={this.handlePerilchange}
                            value={this.state.peril}
                          >
                            <option value="all">All</option>
                            {this.state.perilData.length > 0 &&
                              this.state.perilData.map((item) => (
                                <option value={item} key={item}>
                                  {item}
                                </option>
                              ))}
                          </select>
                        </Col>

                        <Col md="2">
                          <FormGroup>
                            <Label
                              className="col-md-12 col-form-label"
                              style={{ fontSize: "14px" }}
                            >
                              <span>&nbsp;</span>
                            </Label>
                            <Button
                              color="primary"
                              className="waves-effect waves-light mr-1"
                              style={{
                                fontSize: "11px",
                                float: "right",
                              }}
                              onClick={this.getconditions}
                            >
                              Get Condition
                            </Button>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <center>
                      <p
                        style={
                          this.state.showmessage ? {} : { display: "none" }
                        }
                      >
                        Waiting for search
                      </p>
                      <div
                        style={this.state.showloader ? {} : { display: "none" }}
                      >
                        <center>
                          <Spinner color="dark" />
                        </center>
                      </div>
                      {this.state.conditions.length > 0 &&
                        this.state.conditions.map((item, index, data) => (
                          <div class="accordion" id="accordionExample">
                            <div class="accordion-item">
                              <a class="accordion-header" id="headingTwo">
                                <button
                                  class="accordion-button collapsed"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target={
                                    "#collapsecrop" + String(index + 1)
                                  }
                                  aria-expanded="false"
                                  aria-controls="collapsecrop"
                                >
                                  <a
                                    style={{
                                      fontSize: "12px",
                                      paddingRight: "3px",
                                    }}
                                    className="crop-name"
                                  >
                                    {this.spellcheckperil(Object.keys(item))}
                                  </a>
                                  <Link
                                    to={{
                                      pathname: "/edit-conditions",
                                      state: {
                                        data: item[Object.keys(item)]
                                          .phase_data,
                                      },
                                    }}
                                  >
                                    <a
                                      className="edit-condition"
                                      style={{
                                        fontSize: "12px",
                                        position: "absolute",
                                      }}
                                    >
                                      <div className="btn-edit-conditon">
                                        Edit Condition
                                      </div>
                                    </a>
                                  </Link>
                                  {/* <Link>
                                    <a
                                      style={{
                                        fontSize: "12px",
                                        position: "absolute",
                                        right: "15%",
                                      }}
                                    >
                                      <div className="badge badge-soft-warning font-size-12">
                                        Clone
                                      </div>
                                    </a>
                                  </Link> */}
                                  {/* <Link>
                                    <a
                                      style={{
                                        fontSize: "12px",
                                        position: "absolute",
                                        right: "15%",
                                      }}
                                      onClick={this.deletecondition}
                                    >
                                      <div className="badge badge-soft-danger font-size-12">
                                        Delete
                                      </div>
                                    </a>
                                  </Link> */}
                                </button>
                              </a>
                              <div
                                id={"collapsecrop" + String(index + 1)}
                                class="accordion-collapse collapse"
                                aria-labelledby="headingTwo"
                                data-bs-parent="#accordionExample"
                              >
                                <div class="accordion-body">
                                  <Table
                                    id="tsconditions"
                                    bordered
                                    style={{ fontSize: "11px" }}
                                  >
                                    <thead
                                      style={{ backgroundColor: "#ffffff" }}
                                    >
                                      <tr>
                                        <th style={{ width: "130px" }}>
                                          Phase
                                        </th>
                                        <th style={{ width: "160px" }}>
                                          From Date
                                        </th>
                                        <th style={{ width: "160px" }}>
                                          To Date
                                        </th>
                                        <th style={{ width: "130px" }}>
                                          Phase Maximum Payout
                                        </th>
                                        <th style={{ width: "130px" }}>
                                          Is Daily
                                        </th>
                                        <th style={{ width: "130px" }}>
                                          Is Phase
                                        </th>
                                        <th style={{ width: "130px" }}>
                                          Is Peril End
                                        </th>
                                        <th style={{ width: "130px" }}>
                                          Unit Cost
                                        </th>
                                        <th style={{ width: "130px" }}>
                                          Maximum Payout
                                        </th>
                                        <th style={{ width: "130px" }}>
                                          Condition
                                        </th>
                                        <th style={{ width: "130px" }}>
                                          Rules
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {/* {console.log("ITEM LOG", item)} */}
                                      {item[Object.keys(item)].phase_data.map(
                                        (phase) => (
                                          <tr>
                                            <td>
                                              {this.checkphase(
                                                phase[Object.keys(phase)].phase
                                              )}
                                            </td>
                                            <td>
                                              {Moment(
                                                phase[Object.keys(phase)]
                                                  .startDate
                                              ).format("DD-MM-YYYY")}
                                            </td>
                                            <td>
                                              {Moment(
                                                phase[Object.keys(phase)]
                                                  .endDate
                                              ).format("DD-MM-YYYY")}
                                            </td>
                                            <td>
                                              {this.checknull(
                                                phase[Object.keys(phase)]
                                                  .phase_maximum_payout
                                              )}
                                            </td>
                                            <td className="alnrighttd">
                                              {String(
                                                phase[Object.keys(phase)]
                                                  .is_daily
                                              )}
                                            </td>
                                            <td className="alnrighttd">
                                              {String(
                                                phase[Object.keys(phase)]
                                                  .is_phase
                                              )}
                                            </td>
                                            <td className="alnrighttd">
                                              {this.checknull(
                                                String(
                                                  phase[Object.keys(phase)]
                                                    .is_peril_phase_end
                                                )
                                              )}
                                            </td>
                                            <td className="alnrighttd">
                                              {this.checknull(
                                                phase[Object.keys(phase)]
                                                  .unit_cost
                                              )}
                                            </td>
                                            <td className="alnrighttd">
                                              {this.checknull(
                                                phase[Object.keys(phase)]
                                                  .max_payout
                                              )}
                                            </td>
                                            <td
                                              onClick={() =>
                                                this.seeconditions(
                                                  phase[Object.keys(phase)]
                                                    .condition
                                                )
                                              }
                                            >
                                              <Link
                                                to="#"
                                                onClick={this.tog_conditions}
                                              >
                                                See conditions
                                              </Link>
                                            </td>
                                            <td
                                              onClick={() =>
                                                this.seerules(
                                                  phase[Object.keys(phase)].rule
                                                )
                                              }
                                            >
                                              <Link
                                                to="#"
                                                onClick={this.tog_center}
                                              >
                                                See rules
                                              </Link>
                                            </td>
                                          </tr>
                                        )
                                      )}
                                    </tbody>
                                  </Table>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                    </center>
                  </CardBody>
                </Card>
                <Modal
                  isOpen={this.state.modal_center1}
                  toggle={this.update_rules}
                  centered={true}
                  backdrop="static"
                >
                  <ModalHeader
                    toggle={() => this.setState({ modal_center1: false })}
                  >
                    Update Rules
                  </ModalHeader>
                  <ModalBody
                    style={{
                      maxHeight: "calc(100vh - 210px)",
                      overflowY: "auto",
                    }}
                  ></ModalBody>
                </Modal>
              </Col>
            </Row>
            <Modal
              isOpen={this.state.modal_center}
              toggle={this.tog_center}
              centered={true}
              backdrop="static"
            >
              <ModalHeader
                toggle={() => this.setState({ modal_center: false })}
              >
                Rules
              </ModalHeader>
              <ModalBody
                style={{
                  maxHeight: "calc(100vh - 210px)",
                  overflowY: "auto",
                }}
              >
                <pre>{JSON.stringify(this.state.rules, null, 2)}</pre>
              </ModalBody>
            </Modal>
            <Modal
              isOpen={this.state.modal_center1}
              toggle={this.update_rules}
              centered={true}
              backdrop="static"
            >
              <ModalHeader
                toggle={() => this.setState({ modal_center1: false })}
              >
                Update Rules
              </ModalHeader>
              <ModalBody
                style={{
                  maxHeight: "calc(100vh - 210px)",
                  overflowY: "auto",
                }}
              ></ModalBody>
            </Modal>
            {/* ==================================DELETE MODAL=========================================== */}
            <Modal
              size="sm"
              isOpen={this.state.modal_delete}
              toggle={this.tog_delete}
              backdrop="static"
            >
              <ModalHeader
                toggle={() => this.setState({ modal_delete: false })}
                style={{ border: "none", backgroundColor: "#A4C1D2" }}
              >
                Delete Condition
              </ModalHeader>
              <ModalBody
                style={{
                  maxHeight: "calc(100vh - 210px)",
                  overflowY: "auto",
                }}
              >
                Are you sure you want to delete?
              </ModalBody>
              <ModalFooter style={{ border: "none" }}>
                <Button
                  type="button"
                  color="light"
                  onClick={() => this.setState({ modal_delete: false })}
                >
                  Cancel
                </Button>
                <Button
                  type=""
                  color="primary"
                  //   onClick={this.confirmUserDelete}
                >
                  Delete
                </Button>
              </ModalFooter>
            </Modal>
            <Modal
              isOpen={this.state.modal_condition}
              toggle={this.tog_conditions}
              centered={true}
              backdrop="static"
            >
              <ModalHeader
                toggle={() => this.setState({ modal_condition: false })}
              >
                Conditions
              </ModalHeader>

              <ModalBody
                style={{
                  maxHeight: "calc(100vh - 210px)",
                  overflowY: "auto",
                }}
              >
                <pre>{JSON.stringify(this.state.conditions_list, null, 2)}</pre>
              </ModalBody>
            </Modal>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default FarmerSearch;
