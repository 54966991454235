import React from "react";

import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  Input,
  Container,
} from "reactstrap";

function OnlineSensorData() {
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xs={12}>
              <h4
                className="card-title"
                style={{ padding: "5px", fontSize: "18px" }}
              >
                {" "}
                Online sensor data Vs other open data sources
              </h4>
            </Col>
          </Row>
          <Row>
            <Col xl={12}>
              <Card>
                <CardBody>
                  <Col>
                    <Row>
                      <Col md="2">
                        <FormGroup>
                          <Input
                            className="form-control"
                            type="date"
                            id="example-date-input"
                            style={{ fontSize: "11px" }}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="2">
                        <FormGroup>
                          <Input
                            className="form-control"
                            type="date"
                            id="example-date-input"
                            style={{ fontSize: "11px" }}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup>
                          <select
                            className="form-control"
                            md="6"
                            style={{ fontSize: "11px" }}
                          >
                            <option>Select</option>
                            <option value="10.69300691,76.72595507">
                              Chittur-Thathamangalam (Muncipal Corporation
                              Office)
                            </option>
                            <option value="10.74866619,76.85879364">
                              Eruthenpathy (Seed Godown)
                            </option>
                            <option value="10.70246231,76.85248817">
                              Eruthenpathy (Bhagavathy Govt. High School)
                            </option>
                            <option value="10.73595019,76.82999565">
                              Kozhinjampara (Bharathamatha Arts and
                              ScienceCollege)
                            </option>
                            <option value="10.66709167,76.82955577">
                              Kozhinjampara (KWA Water treatment Plant)
                            </option>
                            <option value="10.6657739,76.87386074">
                              Kozhinjampara (Moolathara Regulator)
                            </option>
                            <option value="10.72918673,76.78622876">
                              Nallepilly (Krishi Bhavan)
                            </option>
                            <option value="10.70258377,76.75669052">
                              Nallepilly (Government Technical High School)
                            </option>
                            <option value="10.64777998,76.73270522">
                              Pattanchery (Panchayat Cremation Ground)
                            </option>
                            <option value="10.67481694,76.70654271">
                              Pattanchery (GSM High School,Thattamangalam)
                            </option>
                            <option value="10.64975298,76.76319652">
                              Perumatty (Panchayat Office)
                            </option>
                            <option value="10.65674374,76.80928768">
                              Perumatty (Higher Secondary School)
                            </option>
                            <option value="10.77941127,76.85986374">
                              Vadakarapathy (Kinarpallam Milk Society)
                            </option>
                            <option value="10.7951,76.8363">
                              Vadakarapathy (Ahalia campus)
                            </option>
                          </select>
                        </FormGroup>
                      </Col>

                      <Col md="4">
                        <FormGroup>
                          <Button
                            color="primary"
                            className="waves-effect waves-light mr-1"
                            style={{ fontSize: "11px", float: "right" }}
                          >
                            Search
                          </Button>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col></Col>
                    </Row>
                  </Col>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xl={12}>
              <Card>
                <CardBody></CardBody>
              </Card>
              <div class="text-right">
                <Button type="button" color="primary" disabled>
                  Download
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default OnlineSensorData;
