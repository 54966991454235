import React, { Component } from "react";
import { Switch, BrowserRouter as Router } from "react-router-dom";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import IdleTimer from "react-idle-timer";
import "react-toastify/dist/ReactToastify.css";
import 'react-dropzone-uploader/dist/styles.css'
// Import Routes
import { authProtectedRoutes, publicRoutes } from "./routes/";
import AppRoute from "./routes/route";
//i18n
import { withNamespaces } from "react-i18next";
// layouts
import VerticalLayout from "./components/VerticalLayout/";
// import HorizontalLayout from "./components/HorizontalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";
import "./pages/Adm-components/css/vertical-center.css";
import { withRouter } from "react-router-dom";
toast.configure();
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.getLayout = this.getLayout.bind(this);

    this.idleTimer = null;
    this.handleOnAction = this.handleOnAction.bind(this);
    this.handleOnActive = this.handleOnActive.bind(this);
    this.handleOnIdle = this.handleOnIdle.bind(this);
  }
  handleOnAction(event) {
    console.log("user did something", event);
  }

  handleOnActive(event) {
    console.log("user is active", event);
    console.log("time remaining", this.idleTimer.getRemainingTime());
  }

  handleOnIdle(event) {
    console.log("user is idle");
    console.log("last active", this.idleTimer.getLastActiveTime());
    localStorage.clear();
    window.location.href = "/";
  }

  /**
   * Returns the layout
   */
  getLayout = () => {
    let layoutCls = VerticalLayout;

    switch (this.props.layout.layoutType) {
      // case "horizontal":
      // 	layoutCls = HorizontalLayout;
      // 	break;
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  };

  render() {
    const Layout = this.getLayout();

    return (
      <React.Fragment>
        {/* <IdleTimer
          ref={(ref) => {
            this.idleTimer = ref;
          }}
          timeout={1000 * 60 * 20}
          onIdle={this.handleOnIdle}
          debounce={250}
        /> */}

        <Router>
          <Switch>
            {publicRoutes.map((route, idx) => (
              <AppRoute
                path={route.path}
                layout={NonAuthLayout}
                component={route.component}
                key={idx}
                isAuthProtected={false}
              />
            ))}

            {authProtectedRoutes.map((route, idx) => (
              <AppRoute
                path={route.path}
                layout={Layout}
                component={route.component}
                key={idx}
                isAuthProtected={true}
              />
            ))}
          </Switch>
        </Router>
      </React.Fragment>     
    );
  }
}

const mapStateToProps = (state) => {
  return {
    layout: state.Layout,
  };
};

export default withRouter(connect (mapStateToProps, null)(withNamespaces()(App)));
