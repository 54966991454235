import React, { Component } from "react";
import {
  Card,
  CardBody,
  Row,
  Col,
  CardText,
  FormGroup,
  NavLink,
  NavItem,
  Nav,
  Label,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container,
  Button,
  Spinner,
} from "reactstrap";
// import './table.css'

import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import "antd/dist/antd.css";
import { Tabs, Input, Radio, Checkbox } from "antd";
import { toast } from "react-toastify";
import "../../assets/scss/tab.css";
import { DatePicker, Space } from "antd";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
const { RangePicker } = DatePicker;

const TabPane = Tabs.TabPane;
toast.configure();
const { TextArea } = Input;
let items;
const mapStateToProps = (ReduxProps) => {
  return {
    TmCloneSeason: ReduxProps.TmCloneSeason,
    TmCloneExec: ReduxProps.TmCloneExec,
    TmCloneCrop: ReduxProps.TmCloneCrop,
    TmClonePeril: ReduxProps.TmClonePeril,
  };
};

class Tm_clonetemplate extends Component {
  constructor(props) {
    super(props);
    // console.log("CLASS PROPS NEW", props);
    items = { ...props.props };
    this.state = {
      isDaily: props.props.is_daily == "N" ? false : true,
    };
    this.saveclick = this.saveclick.bind(this);
  }
  componentDidUpdate(prevProps) {
    // console.log("REDUX UPDATE", this.props.TmCloneExec);
    if (prevProps !== this.props) {
    if (this.props.TmCloneExec == "Phase End") {
      this.setState({
        isDaily: false,
      });
    } else {
      this.setState({
        isDaily: true,
      });
    }
  }
  }
  async saveclick() {
    // console.log("+++++++++++++++++++++++++++++++++++++++++++++++++++");
    // console.log("SEARCH DATA", items);
    delete items["id"];
    delete items["previous_phases"];

    // console.log("PROPS DATA", this.props);

    items["season"] = this.props.TmCloneSeason;
    items["crop"] = this.props.TmCloneCrop;
    items["peril"] = this.props.TmClonePeril;
    items["max_payout"] = Number(items["max_payout"]);
    if (this.props.TmCloneExec == "Day End") {
      items["is_daily"] = "Y";
      items["is_phase"] = "N";
    } else if (this.props.TmCloneExec == "Phase End") {
      items["is_daily"] = "N";
      items["is_phase"] = "Y";
    }

    var new_list = JSON.stringify(items);
    // console.log("SEARCH DATA", new_list);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + localStorage.getItem("token"),
      },
      body: new_list,
    };
    try {
      var updateData = await fetch(
        process.env.REACT_APP_APIEND + `smart_crop/termsheet-condition-add`,
        requestOptions
      ).then((res) => {
        return res.json();
      });
      // console.log("Updated Data", updateData);
      toast.success(updateData.msg);
    } catch (err) {
      toast.error("Something's not right");
    }
  }
  onChangeField(e) {
    items[e.target.name] = [e.target.value][0];
    // console.log("NEW ITEMS", items);
  }
  getdaterange(date, dateString) {
    var getthis = this;
    const date1 = new Date(dateString[0]);
    const date2 = new Date(dateString[1]);
    var from_dd = String(date1.getDate()).padStart(2, "0");
    var from_mm = String(date1.getMonth() + 1).padStart(2, "0"); //January is 0!
    var from_yyyy = date1.getFullYear();
    var from_date = from_yyyy + "-" + from_mm + "-" + from_dd;

    var to_dd = String(date2.getDate()).padStart(2, "0");
    var to_mm = String(date2.getMonth() + 1).padStart(2, "0"); //January is 0!
    var to_yyyy = date2.getFullYear();
    var to_date = to_yyyy + "-" + to_mm + "-" + to_dd;
    items["startDate"] = from_date;
    items["endDate"] = to_date;
  }
  onChangeCondition(e, index) {
    items["condition"][index][e.target.name] = [e.target.value][0];
    // console.log("Condition ITEMS", items);
  }
  onChangePhase(e) {
    // items[e.target.name] = [e.target.value][0]
    if (e.target.name == "is_day_end_alert") {
      if (e.target.checked == true) {
        items["is_day_end_alert"] = "Yes";
      } else {
        items["is_day_end_alert"] = "No";
      }
    } else {
      items[e.target.name] = [e.target.checked][0];
    }
    // console.log("Phase ITEMS", items);
  }
  onchangeRules(e) {
    if (e.target.name == "day_end_alert_rule") {
      items["day_end_alert_rule"] = JSON.parse(e.target.value);
    }
    if (e.target.name == "rule") {
      items["rule"] = JSON.parse(e.target.value);
      items["daily_rule"] = JSON.parse([]);
    }
    // console.log("Phase ITEMS", items);
  }
  // function handleAddRow() {
  //   var table = document.getElementById("tsconditions");
  //   var rowCount = table.rows.length;
  //   var row = table.insertRow(rowCount);
  //   var cell0 = row.insertCell(0);
  // 		var element0 = document.createElement("input");
  // 		element0.type = "text";
  //     element0.addEventListener("onChange", onChangeCondition(e,rowCount));
  //     // element0.onChange = function (e) { onChangeCondition(e,rowCount); }

  // 		cell0.appendChild(element0);

  //     var cell1 = row.insertCell(1);
  // 		var element1 = document.createElement("input");
  // 		element1.type = "text";
  // 		cell1.appendChild(element1);
  //     var cell2 = row.insertCell(2);
  // 		var element2 = document.createElement("input");
  // 		element2.type = "text";
  // 		cell2.appendChild(element2);
  //     var cell3 = row.insertCell(3);
  // 		var element3 = document.createElement("input");
  // 		element3.type = "text";
  // 		cell3.appendChild(element3);
  //     var cell4 = row.insertCell(4);
  // 		var element4 = document.createElement("input");
  // 		element4.type = "text";
  // 		cell4.appendChild(element4);
  //     var cell5 = row.insertCell(5);
  // 		var element5 = document.createElement("input");
  // 		element5.type = "text";
  // 		cell5.appendChild(element5);
  //     var cell6 = row.insertCell(6);
  // 		var element6 = document.createElement("input");
  // 		element6.type = "text";
  // 		cell6.appendChild(element6);
  //     var cell7 = row.insertCell(7);
  // 		var element7 = document.createElement("input");
  // 		element7.type = "text";
  // 		cell7.appendChild(element7);
  //   props["condition"].push({
  //     id: "",
  //     condition: "",
  //     index: "",
  //     trigger_value: "",
  //     strike: "",
  //     exit_value: "",
  //     notional_payout: "",
  //     fixed_payout: "",
  //     parameter: "",
  //   })
  //   console.log("NEW ROW ITEMS", props);
  //   // props=[...items]
  // }
  checkNaN(cost) {
    if (cost == null) {
      return "0.00";
    } else {
      return parseFloat(cost).toFixed(2);
    }
  }
  onchangeDailyRules(e) {
    items["daily_rule"] = JSON.parse(e.target.value);
    items["rule"]=[]
  }
  render() {
    return (
      <>
        <Row>
          <Col>
            <h2 style={{ fontWeight: "bolder", fontSize: "14px" }}>Basic</h2>
            <Row>
              <Col md="2">
                <FormGroup>
                  <Label
                    className="col-md-12 col-form-label nextline"
                    style={{ fontSize: "12px" }}
                  >
                    <br />
                    Phase
                  </Label>
                  <Input
                    placeholder="Phase"
                    style={{ width: 50, fontSize: "11px" }}
                    name="phase"
                    defaultValue={this.props.props.phase}
                    onChange={this.onChangeField}
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <Label
                    className="col-md-12 col-form-label"
                    style={{ fontSize: "12px" }}
                  >
                    <br />
                    Start Date - End Date
                    <br />
                  </Label>
                  <RangePicker
                    defaultValue={[
                      moment(this.props.props.startDate, "YYYY/MM/DD"),
                      moment(this.props.props.endDate, "YYYY/MM/DD"),
                    ]}
                    onChange={this.getdaterange}
                    format="DD-MM-YYYY"
                  />
                </FormGroup>
              </Col>
              <Col md="2">
                <FormGroup>
                  <Label
                    className="col-md-12 col-form-label"
                    style={{ fontSize: "12px" }}
                  >
                    <span>
                      Phase Max
                      <br /> Payout (Rs.)
                    </span>
                  </Label>
                  <Input
                    placeholder="Phase maximum Payout"
                    style={{ width: 100, fontSize: "11px" }}
                    name="phase_maximum_payout"
                    defaultValue={this.props.props.phase_maximum_payout}
                    onChange={this.onChangeField}
                  />
                </FormGroup>
              </Col>
              <Col md="2">
                <FormGroup>
                  <Label
                    className="col-md-12 col-form-label"
                    style={{ fontSize: "12px", paddingRight: "0px" }}
                  >
                    <span>
                      Season
                      <br />
                      Max Payout (Rs.)
                    </span>
                  </Label>
                  <Input
                    placeholder="Season maximum Payout"
                    style={{ width: 100, fontSize: "11px" }}
                    name="max_payout"
                    defaultValue={this.props.props.max_payout}
                    onChange={this.onChangeField}
                  />
                </FormGroup>
              </Col>
              <Col md="2">
                <FormGroup>
                  <Label
                    className="col-md-12 col-form-label"
                    style={{ fontSize: "12px" }}
                  >
                    <br />
                    Unit Cost (Rs.)
                  </Label>
                  <Input
                    placeholder="Unit Cost"
                    style={{ width: 100, fontSize: "11px" }}
                    name="unit_cost"
                    defaultValue={this.props.props.unit_cost}
                    onChange={this.onChangeField}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Col>
        </Row>
        {/* ---------------------------- */}
        <Row>
          <Col>
            <h2 style={{ fontWeight: "bolder", fontSize: "14px" }}>
              Conditions
            </h2>
            <Row>
              <Col md="12">
                {" "}
                <Table
                  id="tsconditions"
                  bordered
                  style={{ fontSize: "12px" }}
                  responsive
                  className="ts-conditions"
                >
                  <thead style={{ backgroundColor: "#ffffff" }}>
                    <tr>
                      <th style={{ width: "130px" }}>Condition</th>
                      <th style={{ width: "130px" }}>Index</th>

                      <th style={{ width: "100px" }}>Trigger</th>
                      <th style={{ width: "100px" }}>Strike</th>
                      <th style={{ width: "100px" }}>Exit</th>
                      <th style={{ width: "100px" }}>Notional (Rs.)</th>
                      <th style={{ width: "100px" }}>Fixed (Rs.)</th>
                      {/* <th style={{ width: "130px" }}>
                    <button className="btn-add-row" onClick={handleAddRow}>
                      Add Row
                    </button>
                  </th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.props.condition.map((item, index) => (
                      <tr>
                        <td>
                          <TextArea
                            rows={1}
                            defaultValue={JSON.stringify(
                              item.condition,
                              undefined,
                              2
                            )}
                            name="condition"
                            style={{ fontSize: "11px" }}
                            onChange={(e) => this.onChangeCondition(e, index)}
                          />
                        </td>
                        <td>
                          <TextArea
                            rows={1}
                            defaultValue={item.index}
                            name="index"
                            style={{ fontSize: "11px", width: 130 }}
                            onChange={(e) => this.onChangeCondition(e, index)}
                          />
                        </td>
                        <td>
                          <Input
                            placeholder="Trigger"
                            style={{ width: 50, fontSize: "11px" }}
                            name="trigger_value"
                            defaultValue={item.trigger_value}
                            onChange={(e) => this.onChangeCondition(e, index)}
                          />
                        </td>
                        <td>
                          <Input
                            placeholder="Strike"
                            style={{ width: 50, fontSize: "11px" }}
                            name="strike"
                            defaultValue={item.strike}
                            onChange={(e) => this.onChangeCondition(e, index)}
                          />
                        </td>
                        <td>
                          <Input
                            placeholder="Exit"
                            style={{ width: 50, fontSize: "11px" }}
                            name="exit_value"
                            defaultValue={item.exit_value}
                            onChange={(e) => this.onChangeCondition(e, index)}
                          />
                        </td>
                        <td className="alnrighttd">
                          <Input
                            placeholder="Notional"
                            style={{ width: 75, fontSize: "11px" }}
                            name="notional_payout"
                            defaultValue={this.checkNaN(item.notional_payout)}
                            onChange={(e) => this.onChangeCondition(e, index)}
                          />
                        </td>
                        <td className="alnrighttd">
                          <Input
                            placeholder="Fixed"
                            style={{ width: 75, fontSize: "11px" }}
                            name="fixed_payout"
                            defaultValue={this.checkNaN(item.fixed_payout)}
                            onChange={(e) => this.onChangeCondition(e, index)}
                          />
                        </td>
                        {/* <td style={{ width: 100 }}>
                    <i
                      className="ri-add-box-fill "
                      style={{ fontSize: "23px" }}
                    />
                  </td> */}
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Col>
        </Row>
        {/* ---------------------------- */}
        <Row>
          <Col>
            <p
              style={{
                fontWeight: "bolder",
                fontSize: "18px",
                paddingTop: "20px",
              }}
            >
              <h2 style={{ fontWeight: "bolder", fontSize: "14px" }}>Others</h2>
            </p>
            <Row>
              <Col md="12">
                <Checkbox
                  defaultChecked={this.props.props.is_peril_phase_end}
                  name="is_peril_phase_end"
                  onChange={this.onChangePhase}
                  style={{ fontSize: "12px" }}
                >
                  Is Phase End
                </Checkbox>
                <Checkbox
                  defaultChecked={
                    this.props.props.is_day_end_alert == "No" ? false : true
                  }
                  name="is_day_end_alert"
                  onChange={this.onChangePhase}
                  style={{ fontSize: "12px" }}
                >
                  Is Day End Alert Applicable?
                </Checkbox>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <FormGroup
                  style={this.state.isDaily ? { display: "none" } : {}}
                >
                  <Label
                    className="col-md-12 col-form-label"
                    style={{ fontSize: "14px" }}
                  >
                    <br />
                    Payout Rules
                  </Label>

                  <div
                    style={{
                      maxHeight: "calc(100vh - 390px)",
                      overflowY: "auto",
                      height: "500px",
                      backgroundColor: "#e6ebef",
                    }}
                  >
                    <TextArea
                      defaultValue={
                        this.props.props.rule.length < 1
                          ? " "
                          : JSON.stringify(this.props.props.rule, undefined, 2)
                      }
                      name="rule"
                      rows={4}
                      style={{
                        maxHeight: "calc(100vh - 390px)",
                        overflowY: "auto",
                        height: "500px",
                        backgroundColor: "#e6ebef",
                      }}
                      onChange={this.onchangeRules}
                    />
                  </div>
                </FormGroup>

                <FormGroup
                  style={this.state.isDaily ? {} : { display: "none" }}
                >
                  <Label
                    className="col-md-12 col-form-label"
                    style={{ fontSize: "14px" }}
                  >
                    <br />
                    Daily Rules
                  </Label>

                  <div
                    style={{
                      maxHeight: "calc(100vh - 390px)",
                      overflowY: "auto",
                      height: "500px",
                      backgroundColor: "#e6ebef",
                    }}
                  >
                    <TextArea
                      defaultValue={
                        this.props.props.daily_rule.length < 1
                          ? " "
                          : JSON.stringify(
                              this.props.props.daily_rule,
                              undefined,
                              2
                            )
                      }
                      name="rule"
                      rows={4}
                      style={{
                        maxHeight: "calc(100vh - 390px)",
                        overflowY: "auto",
                        height: "500px",
                        backgroundColor: "#e6ebef",
                      }}
                      onChange={this.onchangeDailyRules}
                    />
                  </div>
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label
                    className="col-md-12 col-form-label"
                    style={{ fontSize: "14px" }}
                  >
                    <br />
                    Alert Rules
                  </Label>
                  <div
                    style={{
                      maxHeight: "calc(100vh - 390px)",
                      overflowY: "auto",
                      backgroundColor: "#e6ebef",
                      height: "500px",
                    }}
                  >
                    <TextArea
                      defaultValue={
                        this.props.props.day_end_alert_rule == []
                          ? {}
                          : JSON.stringify(
                              this.props.props.day_end_alert_rule,
                              undefined,
                              2
                            )
                      }
                      name="day_end_alert_rule"
                      rows={4}
                      style={{
                        maxHeight: "calc(100vh - 390px)",
                        overflowY: "auto",
                        height: "500px",
                        backgroundColor: "#e6ebef",
                      }}
                      onChange={this.onchangeRules}
                    />
                  </div>
                </FormGroup>
              </Col>
            </Row>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md="12">
            <Button
              color="primary"
              style={{ "font-size": "10px", float: "right" }}
              onClick={this.saveclick}
            >
              Save
            </Button>
            <Link to="/tm_conditions">
              <Button
                color="danger"
                style={{
                  "font-size": "10px",
                  float: "right",
                  marginRight: "15px",
                }}
              >
                Close
              </Button>
            </Link>
          </Col>
        </Row>
      </>
    );
  }
}
export default connect(mapStateToProps, null)(Tm_clonetemplate);
