import React, { Component } from 'react';
import { Container, Card, CardBody, Row, Col} from "reactstrap";
import validate_Token from '../../validateToken';
//Import Breadcrumb
import ReportList from './reportList'
import Permission from "../../permission.js";
//Import Images
import { withNamespaces } from "react-i18next";


class ReportLayout extends Component {
    constructor(props) {
        super(props);
        this.state={
            userKB:true
        }
        this.toggleTab = this.toggleTab.bind(this);
    }

    toggleTab(tab) {
        if (this.state.activeTab !== tab) {
            if(tab >= 1 && tab <=3 ){
                this.setState({
                    activeTab: tab
                });
            }
        }
    }
componentDidMount(){
    validate_Token();
    const user_role = Permission();    
    if(user_role[0]=="KB"){
        this.setState({
            userKB:false
        })    
    }
  
}
    render() {
        const { t, i18n } = this.props;
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>

                    <Row>
                        <Col >
                            <h4 className="card-title" style={{ padding: '5px', fontSize: '18px' }}> {t("Report")}</h4>
                        </Col>
                    </Row>
                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody >                                     
                                               < ReportList userrole={this.state.userKB} />                                       
                                    </CardBody>
                                </Card>
                            </Col>                                                          
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default withNamespaces()(ReportLayout);