import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  Form,
  CardBody,
  CardTitle,
  CardSubtitle,
  Container,
  Button,
} from "reactstrap";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";
import { withNamespaces } from 'react-i18next';
import validate_Token from "../../validateToken";

toast.configure();
class FormUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Forms", link: "#" },
        { title: "From Upload", link: "#" },
      ],
      selectedFiles: [],
    };
  }

  getUploadParams = ({ meta }) => {
    return { url: "https://httpbin.org/post" };
  };

  // called every time a file's `status` changes
  handleChangeStatus = ({ meta, file }, status) => {
    console.log(status, meta, file);
  };

  // receives array of files that are done uploading when submit button is clicked
  handleSubmit = async (files, allFiles) => {
    var file = files.map((f) => f.meta);
    var allfiles = allFiles[0].file;
    const formData = new FormData();
    formData.append("file", allfiles);

    allFiles.forEach((f) => f.remove());
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: "Token " + localStorage.getItem("token"),
      },
      body: formData,
    };
    try {
      var data = await fetch(
        process.env.REACT_APP_APIEND + `farmer/farmer-data-upload`,
        requestOptions
      ).then((res) => {
        return res.json();
      });
      if (data.code == 200) {
        if (data.errors.length > 0) {
          data.errors.map((error) => {
            toast.error(error);
          });
        } else {
          toast.success("File uploaded successfully");
        }
      } else {
        toast.error("Error Occured!");
      }
    } catch (err) {
      toast.error("Something's not right");
    }
  };
  componentDidMount() {
    validate_Token();
  }
  capitalizeFirstLetter (string) {
    return string.charAt(1).toUpperCase() + string.slice(2);
  };
  render() {
    const  {t,i18n} =this.props
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Row>
              <Col xs={12}>
                <h4
                  className="card-title"
                  style={{ padding: "5px", fontSize: "18px" }}
                >
                  {" "}
                  {t("Farmer Data Upload")}      
                  
                </h4>
              </Col>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <Form>
                      <Dropzone                      
                        getUploadParams={this.getUploadParams}
                        onChangeStatus={this.handleChangeStatus}
                        onSubmit={this.handleSubmit}
                        accept=".xls, .xlsx"
                        maxFiles={1} 
                        styles={{
                          dropzone: {  overflow: "hidden" },                         
                        }}                       
                        inputContent={<div style={{fontSize:"16px",color:"#8f8e8d"}}>
                          {t("Upload Data")}
                        </div>}                       
                      />
                    </Form>

                    <div className="text-center mt-4">
                      <div style={{ float: "right" }}>
                        <Link
                          to="/farmer_data_upload_template.xlsx"
                          target="_blank"
                          download
                        >
                          {t("Download Template")}
                        </Link>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default withNamespaces()(FormUpload);

