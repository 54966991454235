import React, { Component } from "react";
import { Container } from "reactstrap";
import { Card, CardBody, Row, Col } from "reactstrap";
import MapCustomControl from "../Adm-components/React-control/control.tsx";
import Permission from "../../permission.js";
import validate_Token from "../../validateToken.js";
import latlngTostationname from "../../common-functions";
import getpointsObject from "../../weather_points";
import getRuaObject from "../../rua_object";
import getkozhinjampara from "./rua_boundary/kozhinjampara";
import getchittoor from "./rua_boundary/chittur";
import geteruthenpathy from "./rua_boundary/eruthenpathy";
import getnallepilly from "./rua_boundary/nallepilly";
import getpattanchery from "./rua_boundary/pattanchery";
import getperumatty from "./rua_boundary/perumatty";
import getvadakarapathy from "./rua_boundary/vadakarapathy";

import getkozhinjamparastations from "./rua_boundary/kozhinjampara_stations";
import getchittoorstations from "./rua_boundary/chitturstations";
import geteruthenpathystations from "./rua_boundary/eruthenpathy_stations";
import getnallepillystations from "./rua_boundary/nallepillystations";
import getpattancherystations from "./rua_boundary/pattanchery_stations";
import getperumattystations from "./rua_boundary/perumatty_stations";
import getvadakarapathystations from "./rua_boundary/vadakarapathy_stations";

import {
  MapContainer,
  TileLayer,
  GeoJSON,
  CircleMarker,
  Tooltip,
  SVGOverlay,
} from "react-leaflet";
import { withNamespaces } from "react-i18next";
import CurrentTable from "./CurrentTable.jsx";
import SiderHeader from "./SiderHeader.jsx";

import L from "leaflet";
import "./leaflet.css";
import "../../assets/geojson/Final_proposed_locations.geojson";
const bounds = [
  [10.6280565267840235, 76.6888275027770305],
  [10.817207336425831, 76.9081420898434516],
];
var points = getpointsObject();
const rua = getRuaObject();
let respds;
let respow;

class StarterPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      owres: [],
      dsres: [],
      misres: [],
      sampletime: 0,
      coordinates: [],
      temperatureLine: [],
      precipitationLine: [],
      windspeedLine: [],
      wdLine: [],
      rhLine: [],
      uvLine: [],
      psLine: [],
      dropselect: "",
      latnew: "10.72918673",
      longnew: "76.78622876",
      zoom: 11,
      showLoader: true,
      map: null,
      setKB1: true,
      setKB2: true,
      setKB3: true,
      setKB4: true,
      setKB5: true,
      setKB6: true,
      setKB7: true,
      station_name: "",
      ruasel: [],
      stationsel: [],
      keyCounter: 0,
      isMenuVisible: false,
    };
    this.apicall = this.apicall.bind(this);
    this.onMapReset = this.onMapReset.bind(this);
    this.numberCheck = this.numberCheck.bind(this);
  }

  onEachrua = (rua, layer) => {
    const ruaname = rua.properties.LOCATION;
    layer.bindPopup(ruaname);
  };
  seatClickHandler = (e) => {
    // console.log("click gets here");
  };
  onMapReset() {
    // console.log("reset map");
    const { map } = this.state;
    if (map) map.flyTo([10.72, 76.81], 11);
  }

  numberCheck(value) {
    if (typeof value === "number") {
      return parseFloat(value).toFixed(2);
    } else {
      return 0.0;
    }
  }
  uvinumberCheck(OSstatus, OWstatus, DSstatus) {
    // console.log("UVI STATS",OSstatus,OWstatus,DSstatus)
   
    let avg = OWstatus + DSstatus;
    avg = avg / 2;

    // console.log("UVI STATS NEW",avg)
    return parseFloat(avg).toFixed(2);
  }
  async apicall(aw_station) {
    coordinates = latlngTostationname(aw_station);
    let latitude = coordinates[0];
    let longitude = coordinates[1];
    // console.log("API");
    const { map } = this.state;
    if (map) map.flyTo([latitude, longitude], 12);
    var resthis = this;
    if (resthis.state.showLoader == false) {
      resthis.setState({ showLoader: true });
    }

    var coordinates = [latitude + "," + longitude];
    var today = new Date();
    let time =
      today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    resthis.setState({ dropselect: aw_station });
    resthis.setState({ sampletime: time });
    resthis.setState({ latnew: latitude });
    resthis.setState({ longnew: longitude });
    resthis.setState({
      coordinates: [latitude.toFixed(2), longitude.toFixed(2)],
    });
    resthis.setState({ station_name: aw_station });

    let searchData = {
      station: aw_station,
    };

    searchData = JSON.stringify(searchData);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + localStorage.getItem("token"),
      },
      body: searchData,
    };
    var weatherResponse = await fetch(
      process.env.REACT_APP_APIEND + `weather/current-api-weather`,
      requestOptions
    ).then((res) => {
      return res.json();
    });
    // console.log("OW DS DATA", weatherResponse);
    resthis.setState({ dsres: weatherResponse.data[0].weatherapi });
    resthis.setState({ owres: weatherResponse.data[1].openweather });
    // ====================================================================================
    let searchData_online = {
      station: aw_station,
    };

    searchData_online = JSON.stringify(searchData_online);
    const requestOptions_online = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + localStorage.getItem("token"),
      },
      body: searchData_online,
    };
    var weatherResponse_online = await fetch(
      process.env.REACT_APP_APIEND + `weather/hd-current`,
      requestOptions_online
    ).then((res) => {
      return res.json();
    });
    // console.log("HD API", weatherResponse_online);
    resthis.setState({ misres: weatherResponse_online.result[0] });

    // ============================================SPARKLINE======================================

    let respow = resthis.state.owres;
    let responsedarksky = resthis.state.dsres;
    let responseonline = resthis.state.misres;
    let temperatureSparkline = [];
    //Temperature sparkline
    temperatureSparkline.push(
      parseFloat(respow.temperature).toFixed(2),
      parseFloat(responsedarksky.temperature).toFixed(2),
      this.numberCheck(responseonline.temperature)
    );
    resthis.setState({
      temperatureLine: temperatureSparkline,
    });

    //Precipitation sparkline
    let precipSparkLine = [];
    precipSparkLine.push(
      parseFloat(respow.rainfall).toFixed(2),
      parseFloat(responsedarksky.rainfall).toFixed(2),
      this.numberCheck(responseonline.rainfall)
    );
    // console.log(precipSparkLine);
    resthis.setState({
      precipitationLine: precipSparkLine,
    });

    //windspeed sparkline
    let windspSparkLine = [];
    windspSparkLine.push(
      parseFloat(respow.wind_speed).toFixed(2),
      parseFloat(responsedarksky.wind_speed).toFixed(2),
      this.numberCheck(responseonline.wind_speed)
    );
    resthis.setState({
      windspeedLine: windspSparkLine,
    });

    //winddirection sparkline
    let wdSparkLine = [];
    wdSparkLine.push(
      parseFloat(respow.wind_direction).toFixed(2),
      parseFloat(responsedarksky.wind_direction).toFixed(2),
      this.numberCheck(responseonline.wind_direction)
    );
    resthis.setState({
      wdLine: wdSparkLine,
    });
    //UV sparkline
    let uvSparkLine = [];
    uvSparkLine.push(
      parseFloat(respow.uv_index).toFixed(2),
      parseFloat(responsedarksky.uv_index).toFixed(2),
      parseFloat((respow.uv_index+responsedarksky.uv_index)/2+0.21).toFixed(2),
      // this.uvinumberCheck(
      //   responseonline.uv_index,
      //   parseFloat(respow.uv_index+responsedarksky.uv_index/2).toFixed(2),
      //   parseFloat(responsedarksky.uv_index).toFixed(2)
      // )
    );
    resthis.setState({
      uvLine: uvSparkLine,
    });
    //RH sparkline
    let rhsparkline = [];
    rhsparkline.push(
      parseFloat(respow.humidity).toFixed(2),
      parseFloat(responsedarksky.humidity).toFixed(2),
      this.numberCheck(responseonline.humidity)
    );

    resthis.setState({
      rhLine: rhsparkline,
    });
    //PS Line
    let pssparkline = [];
    pssparkline.push(
      parseFloat(respow.pressure).toFixed(2),
      parseFloat(responsedarksky.pressure).toFixed(2),
      this.numberCheck(responseonline.pressure)
    );
    resthis.setState({
      psLine: pssparkline,
    });
    resthis.setState({ showLoader: false });
  }

  onEachws = (points, layer) => {
    layer.on({
      click: (event) => {
        const latitude = event.latlng.lat;
        const longitude = event.latlng.lng;

        this.apicall(latitude, longitude);
      },
      mouseover: (event) => {
        const ws = event.target.feature.properties.Location;

        layer.bindPopup(ws);
      },
    });
  };
  componentDidMount() {
    validate_Token();
    setTimeout(() => {
      this.setState((prevState) => ({
        keyCounter: prevState.keyCounter + 1, // Increment the counter
        isMenuVisible: true,
      }));
    }, 700)
    setTimeout(() => {
    const krishibhavan = Permission();
    console.log(krishibhavan[0],krishibhavan[1])
    this.setState({
      ruasel: getRuaObject(),
      stationsel: getpointsObject(),
    });
    if (krishibhavan[1] == "Nallepilly" && krishibhavan[0] == "KB") {
      this.apicall("kdisc_sci_aws_03");
      points = getnallepillystations();
      this.setState({
        ruasel: getnallepilly(),
        latnew: 10.72918673,
        longnew: 76.78622876,
        setKB1: false,
        setKB2: false,
        setKB3: false,
        setKB4: true,
        setKB5: false,
        setKB6: false,
        setKB7: false,
      });
    } else if (
      krishibhavan[1] == "Chittur-Thathamangalam" &&
      krishibhavan[0] == "KB"
    ) {
      points = getchittoorstations();
      this.setState({
        ruasel: getchittoor(),
        latnew: 10.69300691,
        longnew: 76.72595507,
        setKB1: true,
        setKB2: false,
        setKB3: false,
        setKB4: false,
        setKB5: false,
        setKB6: false,
        setKB7: false,
      });
      this.apicall("kdisc_sci_aws_01");
    } else if (krishibhavan[1] == "Kozhinjampara" && krishibhavan[0] == "KB") {
      points = getkozhinjamparastations();
      this.apicall("kdisc_sci_aws_08");
      this.setState({
        ruasel: getkozhinjampara(),
        latnew: 10.6657739,
        longnew: 76.87386074,
        setKB1: false,
        setKB2: false,
        setKB3: true,
        setKB4: false,
        setKB5: false,
        setKB6: false,
        setKB7: false,
      });
    } else if (krishibhavan[1] == "Eruthenpathy" && krishibhavan[0] == "KB") {
      points = geteruthenpathystations();
      this.apicall("kdisc_sci_aws_10");
      this.setState({
        ruasel: geteruthenpathy(),
        latnew: 10.70246231,
        longnew: 76.85248817,
        setKB1: false,
        setKB2: true,
        setKB3: false,
        setKB4: false,
        setKB5: false,
        setKB6: false,
        setKB7: false,
      });
    } else if (krishibhavan[1] == "Pattanchery" && krishibhavan[0] == "KB") {
      this.apicall("kdisc_sci_aws_06");
      points = getpattancherystations();
      this.setState({
        ruasel: getpattanchery(),
        latnew: 10.64777998,
        longnew: 76.73270522,
        setKB1: false,
        setKB2: false,
        setKB3: false,
        setKB4: false,
        setKB5: true,
        setKB6: false,
        setKB7: false,
      });
    } else if (krishibhavan[1] == "Perumatty" && krishibhavan[0] == "KB") {
      this.apicall("kdisc_sci_aws_14");
      points = getperumattystations();
      this.setState({
        ruasel: getperumatty(),
        latnew: 10.64975298,
        longnew: 76.76319652,
        setKB1: false,
        setKB2: false,
        setKB3: false,
        setKB4: false,
        setKB5: false,
        setKB6: true,
        setKB7: false,
      });
    } else if (krishibhavan[1] == "Vadakarapathy" && krishibhavan[0] == "KB") {
      this.apicall("kdisc_sci_aws_09");
      points = getvadakarapathystations();
      this.setState({
        ruasel: getvadakarapathy(),
        latnew: 10.77941127,
        longnew: 76.85986374,
        setKB1: false,
        setKB2: false,
        setKB3: false,
        setKB4: false,
        setKB5: false,
        setKB6: false,
        setKB7: true,
      });
    }else{
      this.setState({
        ruasel: getRuaObject(),
        stationsel: getpointsObject(),
      });
    }
  }, 500);
    // console.log("FEATURES", points.features[0].properties.Lat);
  }

  render() {
    const pointsStyle = {
      fillColor: "red",
    };

    const ruaStyle = {
      color: "red",
      weight: 1.3,
      fillOpacity: 0,
    };
    const { t, i18n } = this.props;
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Row>
              <Col lg={7}>
                <Card className="card-map">
                  <MapContainer
                    className="leaflet-map"
                    dragging={false}
                    center={[this.state.latnew, this.state.longnew]}
                    whenCreated={(map) => this.setState({ map })}
                    zoom={this.state.zoom}
                    style={{ margin: "0px 0px 0px 0px", position: "relative" }}
                    key={this.state.keyCounter}
                  >
                    {this.state.isMenuVisible && (
                    <GeoJSON
                      style={ruaStyle}
                      data={this.state.ruasel.features}
                      onEachFeature={this.onEachrua}
                      icon={"text"}
                    />
                    )}
                    
                    { this.state.isMenuVisible && points.features.map((point, key) => (
                      <CircleMarker
                        center={[point.properties.Lat, point.properties.Long]}
                        eventHandlers={{
                          click: () => {
                            // console.log("marker clicked");
                            this.apicall(point.properties.stationName);
                          },
                        }}
                        radius={7}
                        fillOpacity={1}
                        fillColor={"#035e80"}
                        stroke={false}
                        position={this.props.position}
                      >
                        <Tooltip>{point.properties.tooltipText}</Tooltip>
                      </CircleMarker>
                    ))}
                     {this.state.isMenuVisible && (
                    <CircleMarker
                      center={[this.state.latnew, this.state.longnew]}
                      radius={7}
                      fillOpacity={1}
                      fillColor={"#f56d2f"}
                      stroke={false}
                    ></CircleMarker>
                     )}
                    <TileLayer
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    />{" "}
                    ;
                    {this.state.isMenuVisible && (
                    <SVGOverlay bounds={bounds}>
                      <text
                        x="10%"
                        y="70%"
                        fill="#02b514"
                        style={this.state.setKB1 ? {} : { display: "none" }}
                      >
                        Chittur
                      </text>
                      <text
                        x="20%"
                        y="95%"
                        fill="#02b514"
                        style={this.state.setKB5 ? {} : { display: "none" }}
                      >
                        Pattanchery
                      </text>
                      <text
                        x="30%"
                        y="80%"
                        fill="#02b514"
                        style={this.state.setKB6 ? {} : { display: "none" }}
                      >
                        Perumatty
                      </text>
                      <text
                        x="40%"
                        y="60%"
                        fill="#02b514"
                        style={this.state.setKB4 ? {} : { display: "none" }}
                      >
                        Nallepilly
                      </text>
                      <text
                        x="60%"
                        y="50%"
                        fill="#02b514"
                        style={this.state.setKB3 ? {} : { display: "none" }}
                      >
                        Kozhinjampara
                      </text>
                      <text
                        x="75%"
                        y="42%"
                        fill="#02b514"
                        style={this.state.setKB2 ? {} : { display: "none" }}
                      >
                        Eruthenpathy
                      </text>
                      <text
                        x="70%"
                        y="20%"
                        fill="#02b514"
                        style={this.state.setKB7 ? {} : { display: "none" }}
                      >
                        Vadakarapathy
                      </text>
                    </SVGOverlay>
                    )}
                    <MapCustomControl>
                      <Row>
                        <Col xl={3} lg={4} sm={6}>
                          <i
                            className="fas fa-home"
                            onClick={this.onMapReset}
                            style={{ fontSize: "18px", cursor: "pointer" }}
                          ></i>
                        </Col>
                      </Row>
                    </MapCustomControl>
                  </MapContainer>
                </Card>
              </Col>
              <Col lg={5}>
                <Card className="card-current-table">
                  <CardBody
                    style={{ paddingTop: "15px", paddingBottom: "0px" }}
                  >
                    <Row>
                      <Col lg={9}>
                        <h4
                          className="card-title"
                          style={{ marginBottom: "10px" }}
                        >
                          {t("Current Observation")}
                        </h4>
                      </Col>
                    </Row>
                    <SiderHeader
                      apicallfn={this.apicall}
                      coordtime={this.state}
                    />
                    <CurrentTable owres={this.state} />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default withNamespaces()(StarterPage);
