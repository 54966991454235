import React, { Component } from "react";
import {
  Card,
  CardBody,
  Row,
  Col,
  Container,
  Button,
  CardHeader,
  ButtonGroup,
  ButtonToolbar,
} from "reactstrap";
import { Link } from "react-router-dom";
import loader from "../Dashboard/images/loader.gif";
import Chart from "react-apexcharts";
import ApexCharts from "apexcharts";
import { DatePicker, Space } from "antd";
import moment from "moment";
import { Spinner } from "reactstrap";
const { RangePicker } = DatePicker;
const dateFormat = "YYYY-MM-DD";
export default class histDatalayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      from_date: "",
      to_date: "",
      active: true,
      active_week:false,
      active_month:false,
      station: props.location.state.station,
      location: props.location.state.location,
      showloader: true,
      options: {
        chart: {
          id: "historicaldata",
          type: "area",
          height: 350,
          zoom: {
            autoScaleYaxis: true,
          },
        },
        xaxis: {
          type: "datetime",
          tickAmount: 6,
          labels: {
            datetimeUTC: false,
            datetimeFormatter: {
              year: 'yyyy',
              month: 'dd MMM',
              day: 'dd MMM',
              hour: 'HH:mm'
            }
          },
        },
        tooltip: {
          x: {
            format: "dd.MM.yyyy HH:mm",
          },
        },
      },
      series: [
        {
          name: "Test Data",
          data: [],
        },
        {
          name: "Test Data2",
          data: [
            { x: 1615362718000, y: 77.95 },
            { x: 1615363619000, y: 90.34 },
            { x: 1615364518000, y: 24.18 },
            { x: 1615365418000, y: 21.05 },
            { x: 1615366318000, y: 71.0 },
            { x: 1615367218000, y: 80.95 },
            { x: 1615369018000, y: 81.24 },
            { x: 1615369918000, y: 51.29 },
            { x: 1615467413000, y: 41.85 },
            { x: 1615467520000, y: 21.86 },
            { x: 1615467681000, y: 12.28 },
            { x: 1615468200000, y: 82.1 },
          ],
        },
      ],
    };
    this.generatechart = this.generatechart.bind(this);
    this.onChangedate = this.onChangedate.bind(this);
    this.datechange = this.datechange.bind(this);
  }
  onChangedate(dateString) {
    const date1 = new Date(dateString[0]);
    const date2 = new Date(dateString[1]);

    var from_dd = String(date1.getDate()).padStart(2, "0");
    var from_mm = String(date1.getMonth() + 1).padStart(2, "0"); //January is 0!
    var from_yyyy = date1.getFullYear();
    var from_date = from_yyyy + "-" + from_mm + "-" + from_dd;

    var to_dd = String(date2.getDate()).padStart(2, "0");
    var to_mm = String(date2.getMonth() + 1).padStart(2, "0"); //January is 0!
    var to_yyyy = date2.getFullYear();
    var to_date = to_yyyy + "-" + to_mm + "-" + to_dd;

    this.setState(
      {
        from_date: from_date,
        to_date: to_date,
        active: false,
      },
      () => {
        this.fetchbatterydata();
      }
    );
  }
  async fetchbatterydata() {
    var getthis = this;
    getthis.setState({  showloader: true})
    let bodydata = {
      station_name: this.state.station,
      from_date: this.state.from_date,
      to_date: this.state.to_date,
    };
    bodydata = JSON.stringify(bodydata);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + localStorage.getItem("token"),
      },
      body: bodydata,
    };

    var batteryhistory = await fetch(
      process.env.REACT_APP_APIEND + `weather/historical-battery-data`,
      requestOptions
    ).then((res) => {
      return res.json();
    });
    var battery_values = this.generatechart(batteryhistory);

    getthis.setState({
      options: {
        colors: ["#0b6b75"],
        chart: {
          id: "historicaldata",
          type: "area",
          height: 350,
          zoom: {
            autoScaleYaxis: true,
          },
          toolbar: {
            show: true,
            tools: {
              download: false,
            },
          },
        },
        xaxis: {
          type: "datetime",
          labels: {
            datetimeUTC: false,
            datetimeFormatter: {
              year: 'yyyy',
              month: 'dd MMM',
              day: 'dd MMM',
              hour: 'HH:mm'
            }
          },
          title: {
            text: "Date/Time",
            rotate: -90,
            offsetX: 0,
            offsetY: 0,
            style: {
              color: undefined,
              fontSize: "12px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 600,
              cssClass: "apexcharts-yaxis-title",
            },
          },
        },
        yaxis: {
          show: true,
        //   min: 0,
        //   max: 10,
        title: {
          text: "Battery (V)",
          rotate: -90,
          offsetX: 0,
          offsetY: 0,
          style: {
            color: undefined,
            fontSize: "12px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: 600,
            cssClass: "apexcharts-yaxis-title",
          },
        },
        },

        stroke: {
          width: 1,
        },
        tooltip: {
          x: {
            format: "dd.MM.yyyy HH:mm",
          },
        },
      },
    });
    getthis.setState({
      series: battery_values,
      showloader: false,
    });
  }

  generatechart(data) {
    let chart_values = [];
    var batterydata = {
      name: "Battery",
      data: [],
    };

    if (data != null) {
      data.data.map(function (item, index, data) {
        batterydata.data.push({
          x: item.unixms * 1000,
          y: Number(item.battery_value) / 100,
        });
      });
    }
    if (batterydata.data == null) {
      chart_values = [batterydata];
    }
    return [batterydata];
  }
  disabledDate(current) {
    // Can not select days before today and today
    return current && current > moment().endOf("day");
  }
  datechange(day) {
    var getthis = this;
    if (day == "one") {
      this.setState({
        active: true,
        active_week:false,
        active_month:false
      });
      var to_date = new Date();
      var to_dd = String(to_date.getDate()).padStart(2, "0");
      var to_mm = String(to_date.getMonth() + 1).padStart(2, "0"); //January is 0!
      var to_yyyy = to_date.getFullYear();
      var to_date1 = to_yyyy + "-" + to_mm + "-" + to_dd;
      this.setState(
        {
          from_date: to_date1,
          to_date: to_date1,
        },
        () => {
          getthis.fetchbatterydata();
        }
      );
    }
    if (day == "week") {
      this.setState({
        active: false,
        active_week:true,
        active_month:false
      });
      var to_date = new Date();
      var to_dd = String(to_date.getDate()).padStart(2, "0");
      var to_mm = String(to_date.getMonth() + 1).padStart(2, "0"); //January is 0!
      var to_yyyy = to_date.getFullYear();
      var to_date1 = to_yyyy + "-" + to_mm + "-" + to_dd;

      var from_date = new Date();
      from_date.setDate(from_date.getDate() - 7);
      var from_dd = String(from_date.getDate()).padStart(2, "0");
      var from_mm = String(from_date.getMonth() + 1).padStart(2, "0"); //January is 0!
      var from_yyyy = from_date.getFullYear();
      var from_date1 = from_yyyy + "-" + from_mm + "-" + from_dd;

      this.setState(
        {
          from_date: from_date1,
          to_date: to_date1,
        },
        () => {
          this.fetchbatterydata();
        }
      );
    }
    if (day == "month") {
      this.setState({
          active: false,
          active_week: false,            
          active_month: true,
        });
      var to_date = new Date();
      var to_dd = String(to_date.getDate()).padStart(2, "0");
      var to_mm = String(to_date.getMonth() + 1).padStart(2, "0"); //January is 0!
      var to_yyyy = to_date.getFullYear();
      var to_date1 = to_yyyy + "-" + to_mm + "-" + to_dd;

      var from_date = new Date();
      from_date.setDate(from_date.getDate() - 30);
      var from_dd = String(from_date.getDate()).padStart(2, "0");
      var from_mm = String(from_date.getMonth() + 1).padStart(2, "0"); //January is 0!
      var from_yyyy = from_date.getFullYear();
      var from_date1 = from_yyyy + "-" + from_mm + "-" + from_dd;

      this.setState(
        {
          from_date: from_date1,
          to_date: to_date1,
        },
        () => {
          this.fetchbatterydata();
        }
      );
    }
  }
  componentDidMount() {
    this.datechange("one");
  }
  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container>
            <Card style={{ backgroundColor: "#e5ebee" }}>
              <CardHeader style={{ backgroundColor: "#A4C1D2" }}>
                <Row xl="12">
                  <Col>
                    <h4
                      className="card-title"
                      style={{ padding: "5px", fontSize: "18px" }}
                    >
                      {" "}
                      Battery Historical Data
                    </h4>
                  </Col>
                  <Col>
                    <Link to="/admdiagnostic">
                      <i
                        className="ri-close-fill"
                        style={{ fontSize: "20px", float: "right" }}
                      ></i>
                    </Link>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col xs={12}>
                    <Card>
                      <CardBody>
                        Location - {this.state.location}
                        <div>
                          <ButtonToolbar>
                            <ButtonGroup size="sm" className="mt-2">
                              <Button
                                color="light"
                                active={this.state.active}
                                onClick={() => this.datechange("one")}
                              >
                                Today
                              </Button>
                              <Button
                                color="light"
                                active={this.state.active_week}
                                onClick={() => this.datechange("week")}
                              >
                                Last 7 days
                              </Button>
                              <Button
                                color="light"
                                active={this.state.active_month}
                                onClick={() => this.datechange("month")}
                              >
                                Last 30 days
                              </Button>
                            </ButtonGroup>
                            <RangePicker
                              size={"small"}
                              className="hist-date"
                              onChange={this.onChangedate}
                              format="YYYY-MM-DD"
                              disabledDate={this.disabledDate}
                            />
                          </ButtonToolbar>
                          <div
                            style={
                              this.state.showloader ? {} : { display: "none" }
                            }
                          >
                            <center>
                              <Spinner color="dark" />
                            </center>
                          </div>
                          <div
                            style={
                              this.state.showloader ? { display: "none" } : {}
                            }
                          >
                            <Chart
                              series={this.state.series}
                              options={this.state.options}
                              type="line"
                              height="310"
                            />
                            <div style={{ "text-align": "center" }}>
                              <span
                                class="dot"
                                style={{ "background-color": "#0b6b75" }}
                              ></span>
                              <p
                                id="battery"
                                style={{
                                  cursor: "pointer",
                                  display: "inline-block",
                                  "font-size": "12px",
                                  "font-family": "Segoe UI",
                                  paddingRight: "10px",
                                  paddingLeft: "4px",
                                }}
                              >
                                Battery
                              </p>
                            </div>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
