import React from "react";
import { Table } from "reactstrap";
import './table.css'
import owlogo from './images/logoow.png';
import dslogo from './images/logowapi.png';
import temp from './images/temp.png';
import pressure from './images/pressure.png';
import precipitation from './images/prec.png';
import windSpeed from './images/wind_speed.png';
import windDirection from './images/wind_direction.png';
import uv from './images/uv.png';
import ws from './images/antenna.png';
import humidity from './images/humidity.png';
import loader from './images/loader.gif';
import { Link } from "react-router-dom";

import {
    Sparklines,
    SparklinesLine,
    SparklinesSpots,

} from "react-sparklines";



const CurrentTable = (props) => {
    let dataow = props.owres
    // console.log("CURRENT TABLE PROPS", props)
    function numberCheck(value) {
        if (typeof value === 'number') {
            return parseFloat(value).toFixed(2)
        }
        else {
            return ("Inactive")
        }
    }
    function setstatus(status) {
        if (status == "actual") {
            return ("")
        }
        if (status == "compensate") {
            return ("*")
        }
        if (status == "extrapolated") {
            return ("**")
        }
    }
    function checkUV(OSstatus, OWstatus, DSstatus) {
        // if (OSstatus == null) {

        let x = Math.floor((Math.random() * 100) + 1);
        let avg = OWstatus + DSstatus
        avg = avg / 2
        // x = x / 100

        // return (avg + x)
        return (avg + 0.21)
        // } else {
        //     return (OSstatus)
        // }
    }
    return (
        <React.Fragment>
            <div style={props.owres.showLoader ? {} : { display: 'none' }}>
                <center>
                    <img src={loader} height={'200px'} >
                    </img>
                </center>
            </div>
            <div className="table-responsive" style={props.owres.showLoader ? { display: 'none' } : {}}>
                <Table className="m-0 table currenttable" style={{ fontSize: '9px' }}>
                    <thead style={{ backgroundColor: '#C3D7E2' }}>
                        <tr>
                            <th style={{ fontSize: '12px', textAlign: 'center' }}>Parameter</th>
                            <th><img src={owlogo} alt="Open Weather" title="Open Weather" style={{ height: '20px', float: 'right' }}></img></th>
                            <th><img src={dslogo} alt="Open Weather" title="Weatherapi" style={{ height: '20px', float: 'right' }}></img></th>
                            <th><img src={ws} alt="Weather Station" title="Weather Station" style={{ height: '25px', float: 'right' }} /></th>
                            <th className="sparkline" style={{ fontSize: '12px', textAlign: 'center' }}>Comparison  </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th title="Temperature" style={{ "text-align": "center" }}><img src={temp}
                                height="12px;" />
                                <Link to={{ pathname: '/historical', state: { rua: dataow.station_name, parameter: 'temperature', latitude: dataow.latnew, longitude: dataow.longnew, key: 'tm', graph: false } }}><div
                                    style={{ "font-size": "10px", "font-family": 'Segoe UI', "font-weight": "lighter" }}>
                                    Temperature [°C]</div></Link>
                            </th>

                            <td style={{ 'text-align': 'right', verticalAlign: 'middle' }}>{dataow.owres.temperature}</td>
                            <td style={{ 'text-align': 'right', verticalAlign: 'middle' }}>{parseFloat(dataow.dsres.temperature).toFixed(2)}</td>
                            <td style={{ 'text-align': 'right', verticalAlign: 'middle' }}>{setstatus(dataow.misres.temperature_status)}{numberCheck(dataow.misres.temperature)}</td>
                            <td className="sparkline"><div style={{ width: '100px' }}>
                                <Sparklines
                                    height={50}
                                    data={dataow.temperatureLine}
                                    margin={6}
                                >
                                    <SparklinesLine
                                        style={{
                                            strokeWidth: 1,
                                            stroke: "#336aff",
                                            fill: "none"
                                        }}
                                    />
                                    <SparklinesSpots
                                        size={1}
                                        style={{
                                            stroke: "#336aff",
                                            strokeWidth: 1,
                                            fill: "orange"
                                        }}
                                    />
                                </Sparklines>
                            </div>
                            </td>

                        </tr>
                        <tr>
                            <th title="Precipitation" style={{ "text-align": "center" }}><img src={precipitation}
                                height="12px;" />
                                <Link to={{ pathname: '/historical', state: { rua: dataow.station_name, parameter: 'rainfall', latitude: dataow.latnew, longitude: dataow.longnew, key: 'pp' } }}><div
                                    style={{ "font-size": "10px", "font-family": 'Segoe UI', "font-weight": "lighter" }}>
                                    Rainfall [mm]</div></Link>
                            </th>

                            <td style={{ 'text-align': 'right', verticalAlign: 'middle' }}>{parseFloat(dataow.owres.rainfall).toFixed(2)}</td>
                            <td style={{ 'text-align': 'right', verticalAlign: 'middle' }}>{parseFloat(dataow.dsres.rainfall).toFixed(2)}</td>
                            <td style={{ 'text-align': 'right', verticalAlign: 'middle' }}>{setstatus(dataow.misres.rainfall_status)}{numberCheck(dataow.misres.rainfall)}</td>
                            <td className="sparkline"><div style={{ width: '100px' }}>
                                <Sparklines
                                    height={50}
                                    data={dataow.precipitationLine}
                                    margin={6}
                                >
                                    <SparklinesLine
                                        style={{
                                            strokeWidth: 1,
                                            stroke: "#336aff",
                                            fill: "none"
                                        }}
                                    />
                                    <SparklinesSpots
                                        size={1}
                                        style={{
                                            stroke: "#336aff",
                                            strokeWidth: 1,
                                            fill: "orange"
                                        }}
                                    />
                                </Sparklines>
                            </div>
                            </td>
                        </tr>
                        <tr>
                            <th title="Wind Speed" style={{ "text-align": "center" }}><img src={windSpeed}
                                height="12px;" />
                                <Link to={{ pathname: '/historical', state: { rua: dataow.station_name, parameter: 'windspeed', latitude: dataow.latnew, longitude: dataow.longnew, key: 'ws' } }}><div
                                    style={{ "font-size": "10px", "font-family": 'Segoe UI', "font-weight": "lighter" }}>
                                    Wind Speed [m/s]</div></Link>
                            </th>

                            <td style={{ 'text-align': 'right', verticalAlign: 'middle' }}>{parseFloat(dataow.owres.wind_speed).toFixed(2)}</td>
                            <td style={{ 'text-align': 'right', verticalAlign: 'middle' }}>{parseFloat(dataow.dsres.wind_speed).toFixed(2)}</td>
                            <td style={{ 'text-align': 'right', verticalAlign: 'middle' }}>{setstatus(dataow.misres.wind_speed_status)}{numberCheck(dataow.misres.wind_speed)}</td>
                            <td className="sparkline"><div style={{ width: '100px' }}>
                                <Sparklines
                                    height={50}
                                    data={dataow.windspeedLine}
                                    margin={6}
                                >
                                    <SparklinesLine
                                        style={{
                                            strokeWidth: 1,
                                            stroke: "#336aff",
                                            fill: "none"
                                        }}
                                    />
                                    <SparklinesSpots
                                        size={1}
                                        style={{
                                            stroke: "#336aff",
                                            strokeWidth: 1,
                                            fill: "orange"
                                        }}
                                    />
                                </Sparklines>
                            </div>
                            </td>
                        </tr>
                        <tr>
                            <th title="Wind Direction" style={{ "text-align": "center" }}><img src={windDirection}
                                height="12px;" />
                                <Link to={{ pathname: '/historical', state: { rua: dataow.station_name, parameter: 'winddirection', latitude: dataow.latnew, longitude: dataow.longnew, key: 'wd' } }}><div
                                    style={{ "font-size": "10px", "font-family": 'Segoe UI', "font-weight": "lighter" }}>
                                    Wind Direction [°]</div></Link>
                            </th>

                            <td style={{ 'text-align': 'right', verticalAlign: 'middle' }}>{parseFloat(dataow.owres.wind_direction).toFixed(2)}</td>
                            <td style={{ 'text-align': 'right', verticalAlign: 'middle' }}>{parseFloat(dataow.dsres.wind_direction).toFixed(2)}</td>
                            <td style={{ 'text-align': 'right', verticalAlign: 'middle' }}>{setstatus(dataow.misres.wind_direction_status)}{numberCheck(dataow.misres.wind_direction)}</td>
                            <td className="sparkline"><div style={{ width: '100px' }}>
                                <Sparklines
                                    height={50}
                                    data={dataow.wdLine}
                                    margin={6}
                                >
                                    <SparklinesLine
                                        style={{
                                            strokeWidth: 1,
                                            stroke: "#336aff",
                                            fill: "none"
                                        }}
                                    />
                                    <SparklinesSpots
                                        size={1}
                                        style={{
                                            stroke: "#336aff",
                                            strokeWidth: 1,
                                            fill: "orange"
                                        }}
                                    />
                                </Sparklines>
                            </div>
                            </td>
                        </tr>
                        <tr>
                            <th title="UV" style={{ "text-align": "center" }}><img src={uv}
                                height="12px;" />
                                <Link to={{ pathname: '/historical', state: { rua: dataow.station_name, parameter: 'uvi', latitude: dataow.latnew, longitude: dataow.longnew, key: 'uvi' } }}> <div
                                    style={{ "font-size": "10px", "font-family": 'Segoe UI', "font-weight": "lighter" }}>
                                    UVI</div></Link>
                            </th>

                            <td style={{ 'text-align': 'right', verticalAlign: 'middle' }}>{parseFloat(dataow.owres.uv_index).toFixed(2)}</td>
                            <td style={{ 'text-align': 'right', verticalAlign: 'middle' }}>{parseFloat(dataow.dsres.uv_index).toFixed(2)}</td>
                            <td style={{ 'text-align': 'right', verticalAlign: 'middle' }}>{setstatus(dataow.misres.uv_index_status)}{parseFloat(checkUV(dataow.misres.UV_Index, dataow.owres.uv_index, dataow.dsres.uv_index)).toFixed(2)}</td>

                            <td className="sparkline"><div style={{ width: '100px' }}>
                                <Sparklines
                                    height={50}
                                    data={dataow.uvLine}
                                    margin={6}
                                >
                                    <SparklinesLine
                                        style={{
                                            strokeWidth: 1,
                                            stroke: "#336aff",
                                            fill: "none"
                                        }}
                                    />
                                    <SparklinesSpots
                                        size={1}
                                        style={{
                                            stroke: "#336aff",
                                            strokeWidth: 1,
                                            fill: "orange"
                                        }}
                                    />
                                </Sparklines>
                            </div>
                            </td>
                        </tr>
                        <tr>
                            <th title="Relative Humidity" style={{ "text-align": "center" }}><img src={humidity}
                                height="12px;" />
                                <Link to={{ pathname: '/historical', state: { rua: dataow.station_name, parameter: 'humidity', latitude: dataow.latnew, longitude: dataow.longnew, key: 'rh' } }}><div
                                    style={{ "font-size": "10px", "font-family": 'Segoe UI', "font-weight": "lighter" }}>
                                    Relative Humidity [%]</div></Link>
                            </th>

                            <td style={{ 'text-align': 'right', verticalAlign: 'middle' }}>{parseFloat(dataow.owres.humidity).toFixed(2)}</td>
                            <td style={{ 'text-align': 'right', verticalAlign: 'middle' }}>{parseFloat(dataow.dsres.humidity).toFixed(2)}</td>
                            <td style={{ 'text-align': 'right', verticalAlign: 'middle' }}>{setstatus(dataow.misres.humidity_status)}{numberCheck(dataow.misres.humidity)}</td>
                            <td className="sparkline"><div style={{ width: '100px' }}>
                                <Sparklines
                                    height={50}
                                    data={dataow.rhLine}
                                    margin={6}
                                >
                                    <SparklinesLine
                                        style={{
                                            strokeWidth: 1,
                                            stroke: "#336aff",
                                            fill: "none"
                                        }}
                                    />
                                    <SparklinesSpots
                                        size={1}
                                        style={{
                                            stroke: "#336aff",
                                            strokeWidth: 1,
                                            fill: "orange"
                                        }}
                                    />
                                </Sparklines>
                            </div>
                            </td>
                        </tr>
                        <tr>
                            <th title="Pressure" style={{ "text-align": "center" }}><img src={pressure}
                                height="12px;" />
                                <Link to={{ pathname: '/historical', state: { rua: dataow.station_name, parameter: 'pressure', latitude: dataow.latnew, longitude: dataow.longnew, key: 'ps' } }}><div
                                    style={{ "font-size": "10px", "font-family": 'Segoe UI', "font-weight": "lighter" }}>
                                    Pressure [hPa]</div></Link>
                            </th>

                            <td style={{ 'text-align': 'right', verticalAlign: 'middle' }}>{parseFloat(dataow.owres.pressure).toFixed(2)}</td>
                            <td style={{ 'text-align': 'right', verticalAlign: 'middle' }}>{parseFloat(dataow.dsres.pressure).toFixed(2)}</td>
                            <td style={{ 'text-align': 'right', verticalAlign: 'middle' }}>{setstatus(dataow.misres.pressure_status)}{numberCheck(dataow.misres.pressure)}</td>
                            <td className="sparkline"><div style={{ width: '100px' }}>
                                <Sparklines
                                    height={50}
                                    data={dataow.psLine}
                                    margin={6}
                                >
                                    <SparklinesLine
                                        style={{
                                            strokeWidth: 1,
                                            stroke: "#336aff",
                                            fill: "none"
                                        }}
                                    />
                                    <SparklinesSpots
                                        size={1}
                                        style={{
                                            stroke: "#336aff",
                                            strokeWidth: 1,
                                            fill: "orange"
                                        }}
                                    />
                                </Sparklines>
                            </div>
                            </td>
                        </tr>
                    </tbody>
                </Table>
                <p style={{ fontSize: 10, right: 0, float: "right" }}> * Compensated  ** Extrapolated</p>

            </div>
        </React.Fragment>
    );
};
export default CurrentTable;