import React, { Component } from "react";
import { DatePicker } from "antd";
import {
  Card,
  CardBody,
  Row,
  Col,
  CardText,
  FormGroup,
  NavLink,
  NavItem,
  Nav,
  Label,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container,
  Button,
  Spinner,
  CardHeader,
} from "reactstrap";
import { Link } from "react-router-dom";
import { Input, Space } from "antd";
import { toast } from "react-toastify";
const { TextArea } = Input;

toast.configure();
export default class add_tmconditions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      seasonData: props.location.state.seasonData,
      cropData: props.location.state.cropData,
      perilData: props.location.state.perilData,
      modal_small: false,
      modal_rule: false,
      season: "Kharif 2020-21",
      crop: "Paddy",
      peril: "High Temperature",
      phase: "",
      startDate: "",
      endDate: "",
      phase_maximum_payout: 0.0,
      is_daily: "Y",
      is_phase: "Y",
      is_peril_phase_end: "true",
      previous_phases: [],
      unit_cost: 0.0,
      unit_cost2: 0.0,
      max_payout: 0.0,
      is_day_end_alert: "Yes",
      condition: [],
      rule: [],
      daily_rule: [],
      day_end_alert_rule: [],
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.fromonChange=this.fromonChange.bind(this);
    this.ToonChange=this.ToonChange.bind(this);
  }
  tog_addconditions() {
    this.setState((prevState) => ({
      modal_small: !prevState.modal_small,
    }));
  }
  tog_addrule() {
    this.setState((prevState) => ({
      modal_rule: !prevState.modal_rule,
    }));
  }

  handleAddFields = () => {
    const values = this.state.condition_data;
    values.push({
      phase: "",
      from_date: "",
      to_date: "",
      trigger: "",
      strike: "",
      exit: "",
      variable_payout: "",
      fixed_payout: "",
      maximum_payout: "",
    });
    this.setState({
      condition_data: values,
    });
  };
  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }
  // handleChange(index, event) {
  //   const values = this.state.condition_data;
  //   console.log("EVENT", event.target.name, event.target.value);
  //   if (event.target.name === "phase") {
  //     values[index].phase = event.target.value;
  //   }
  //   if (event.target.name === "from_date") {
  //     values[index].from_date = event.target.value;
  //   } else if (event.target.name === "to_date") {
  //     values[index].to_date = event.target.value;
  //   } else if (event.target.name === "trigger") {
  //     values[index].trigger = event.target.value;
  //   } else if (event.target.name === "strike") {
  //     values[index].strike = event.target.value;
  //   } else if (event.target.name === "exit") {
  //     values[index].exit = event.target.value;
  //   } else if (event.target.name === "variable_payout") {
  //     values[index].variable_payout = event.target.value;
  //   } else if (event.target.name === "fixed_payout") {
  //     values[index].fixed_payout = event.target.value;
  //   } else if (event.target.name === "maximum_payout") {
  //     values[index].maximum_payout = event.target.value;
  //   }
  //   this.setState({
  //     condition_data: values,
  //   });
  // }
  // handleRemoveFields = () => {
  //   const values = this.state.condition_data;
  //   if (values.length > 1) values.pop();
  //   this.setState({
  //     condition_data: values,
  //   });
  // };
  async handleSubmit(e) {
    e.preventDefault();
    var newccondition = {
      season: this.state.season,
      crop: this.state.crop,
      peril: this.state.peril,
      phase: this.state.phase,
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      phase_maximum_payout: this.state.phase_maximum_payout,
      is_daily: this.state.is_daily,
      is_phase: this.state.is_phase,
      is_peril_phase_end: this.state.is_peril_phase_end,
      previous_phases: this.state.previous_phases,
      unit_cost: this.state.unit_cost,
      unit_cost2:this.state.unit_cost2,
      max_payout: this.state.max_payout,
      is_day_end_alert: this.state.is_day_end_alert,
      condition:this.state.condition,
      rule:this.state.rule,
      daily_rule:this.state.daily_rule,
      day_end_alert_rule:this.state.day_end_alert_rule
    };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + localStorage.getItem("token"),
      },
      body: JSON.stringify(newccondition),
    };
    try {
      var condition_add = await fetch(
        process.env.REACT_APP_APIEND + `smart_crop/termsheet-condition-add`,
        requestOptions
      ).then((res) => {
        return res.json();
      });
      toast.success("Succesfully added");
    } catch (err) {
      toast.error("Something's not right");
    }
  }
  fromonChange(date, dateString) {
    var date = new Date(dateString);
    var from_dd = String(date.getDate()).padStart(2, "0");
    var from_mm = String(date.getMonth() + 1).padStart(2, "0"); //January is 0!
    var from_yyyy = date.getFullYear();
    var from_date = from_yyyy + "-" + from_mm + "-" + from_dd;

    this.setState({
      startDate: from_date,
    });
  }
  ToonChange(date, dateString) {
    var date = new Date(dateString);
    var to_dd = String(date.getDate()).padStart(2, "0");
    var to_mm = String(date.getMonth() + 1).padStart(2, "0"); //January is 0!
    var to_yyyy = date.getFullYear();
    var to_date = to_yyyy + "-" + to_mm + "-" + to_dd;
    this.setState({
      endDate: to_date,
    });
  }
  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Card style={{ backgroundColor: "#e5ebee" }}>
              <CardHeader style={{ backgroundColor: "#A4C1D2" }}>
                <Row xl="12">
                  <Col>
                    <h4
                      className="card-title"
                      style={{ padding: "5px", fontSize: "18px" }}
                    >
                      {" "}
                      Add Termsheet Conditions
                    </h4>
                  </Col>
                  <Col>
                    <Link to="termsheetconditions">
                      <i
                        className="ri-close-fill"
                        style={{ fontSize: "20px", float: "right" }}
                      ></i>
                    </Link>
                  </Col>
                </Row>
              </CardHeader>
              <Row>
                <Col xs={12}>
                  <Card>
                    <CardBody>
                      <Col>
                        <Row>
                          <Col md="3">
                            <FormGroup>
                              <Label className="col-sm col-form-label">
                                Season
                              </Label>
                              <select
                                className="form-select"
                                style={{ fontSize: "11px" }}
                                name="season"
                                onChange={this.handleChange}
                              >
                                {this.state.seasonData.length > 0 &&
                                  this.state.seasonData.map((item) => (
                                    <option value={item} key={item}>
                                      {item}
                                    </option>
                                  ))}
                              </select>
                            </FormGroup>
                          </Col>

                          <Col md="3">
                            <FormGroup>
                              <Label className="col-sm col-form-label">
                                Crop
                              </Label>
                              <select
                                className="form-select"
                                style={{ fontSize: "11px" }}
                                name="crop"
                                onChange={this.handleChange}
                              >
                                {this.state.cropData.length > 0 &&
                                  this.state.cropData.map((item) => (
                                    <option value={item} key={item}>
                                      {item}
                                    </option>
                                  ))}
                              </select>
                            </FormGroup>
                          </Col>

                          <Col md="3">
                            <Label className=" col-sm col-form-label">
                              Perils
                            </Label>
                            <select
                              className="form-select"
                              style={{ fontSize: "11px" }}
                              name="peril"
                              onChange={this.handleChange}
                            >
                              {this.state.perilData.length > 0 &&
                                this.state.perilData.map((item) => (
                                  <option value={item} key={item}>
                                    {item}
                                  </option>
                                ))}
                            </select>
                          </Col>
                          {/* <Col md="3">
                            <div style={{ float: "right" }}>
                              <Row>
                                <Button
                                  type="button"
                                  color="primary"
                                  style={{ float: "right" }}
                                  className="btn-sm"
                                  onClick={this.handleAddFields}
                                >
                                  Add Phase
                                </Button>
                              </Row>
                              <Row>
                                <Button
                                  type="button"
                                  color="danger"
                                  style={{ float: "right" }}
                                  className="btn-sm"
                                  onClick={this.handleRemoveFields}
                                >
                                  Delete Phase
                                </Button>
                              </Row>
                            </div>
                          </Col> */}
                        </Row>
                        <Row style={{ "overflow-x": "scroll" }} className="ts-add-mob">
                          <Table
                            id="add-conditions"
                            bordered
                            style={{ fontSize: "11px" }}
                          >
                            <thead style={{ backgroundColor: "#ffffff" }}>
                              <tr>
                                <th style={{ width: "130px" }}>Phase</th>
                                <th style={{ width: "130px" }}>From Date</th>
                                <th style={{ width: "130px" }}>To Date</th>
                                <th style={{ width: "130px" }}>
                                  Phase maximum payout
                                </th>
                                <th style={{ width: "130px" }}>Is Daily</th>
                                <th style={{ width: "130px" }}>Is Phase</th>
                                <th style={{ width: "130px" }}>
                                  Is Peril Phase End
                                </th>
                                <th style={{ width: "130px" }}>Unit cost</th>
                                <th style={{ width: "130px" }}>Unit Cost 2</th>
                                <th style={{ width: "130px" }}>Max Payout</th>
                                <th style={{ width: "130px" }}>
                                  Is day end alert
                                </th>
                                <th style={{ width: "130px" }}>Condition</th>
                                <th style={{ width: "130px" }}>Rule</th>
                                <th style={{ width: "130px" }}>Daily rule</th>
                                <th style={{ width: "130px" }}>
                                  Day end alert rule
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <Input
                                    placeholder="Phase"
                                    style={{ width: 100 }}
                                    // value={data.phase}
                                    name="phase"
                                    // onChange={(event) =>
                                    //   this.handleChange(i, event)
                                    // }
                                    onChange={this.handleChange}
                                  />
                                </td>
                                <td>
                                  <DatePicker
                                    format="YYYY-MM-DD"
                                    onChange={this.fromonChange}
                                    style={{ width: 100 }}
                                  />
                                </td>
                                <td>
                                  <DatePicker
                                    format="YYYY-MM-DD"
                                    onChange={this.ToonChange}
                                    style={{ width: 100 }}
                                  />
                                </td>
                                <td>
                                  <Input
                                    placeholder="Phase maximum payout"
                                    style={{ width: 100 }}
                                    name="phase_maximum_payout"
                                    onChange={this.handleChange}
                                  />
                                </td>
                                <td>
                                  <select
                                    className="form-select"
                                    style={{ fontSize: "11px" }}
                                    name="is_daily"
                                    onChange={this.handleChange}
                                    style={{ width: 100, float:"right"}}
                                  >
                                    <option value={"Y"}>Yes</option>
                                    <option value={"N"}>No</option>
                                  </select>
                                </td>
                                <td>
                                  <select
                                    className="form-select"
                                    style={{ fontSize: "11px" }}
                                    name="is_phase"
                                    onChange={this.handleChange}
                                    style={{ width: 100, float:"right" }}
                                  >
                                    <option value={"Y"}>Yes</option>
                                    <option value={"N"}>No</option>
                                  </select>
                                </td>
                                <td className="alnrighttd">
                                  <select
                                    className="form-select"
                                    style={{ fontSize: "11px" }}
                                    name="is_peril_phase_end"
                                    onChange={this.handleChange}
                                    style={{ width: 100, float:"right"}}
                                  >
                                    <option value={"true"}>True</option>
                                    <option value={"false"}>False</option>
                                  </select>
                                </td>
                                <td className="alnrighttd">
                                  <Input
                                    placeholder="Unit cost"
                                    style={{ width: 100 }}
                                    name="unit_cost"
                                    onChange={this.handleChange}
                                  />
                                </td>
                                <td className="alnrighttd">
                                  <Input
                                    placeholder="Unit Cost 2"
                                    style={{ width: 100 }}
                                    name="unit_cost2"
                                    onChange={this.handleChange}
                                  />
                                </td>
                                <td className="alnrighttd">
                                  <Input
                                    placeholder="Max Payout"
                                    style={{ width: 100 }}
                                    name="max_payout"
                                    onChange={this.handleChange}
                                  />
                                </td>
                                <td className="alnrighttd">
                                  <select
                                    className="form-select"
                                    style={{ fontSize: "11px" }}
                                    name="is_day_end_alert"
                                    onChange={this.handleChange}
                                    style={{ width: 100, float:"right" }}
                                  >
                                    <option value={"Yes"}>True</option>
                                    <option value={"No"}>False</option>
                                  </select>
                                </td>
                                <td className="alnrighttd">
                                  <Link
                                    to="#"
                                    onClick={(e) =>
                                      this.setState({
                                        modal_small: true,
                                      })
                                    }
                                  >
                                    Add Conditions
                                  </Link>
                                </td>
                                <td className="alnrighttd">
                                  <Link
                                    to="#"
                                    onClick={(e) =>
                                      this.setState({
                                        modal_rule: true,
                                      })
                                    }
                                  >
                                    Add Rule
                                  </Link>
                                  {/* <Input
                                    placeholder="Rule"
                                    style={{ width: 100 }}
                                    name="rule"
                                    onChange={this.handleChange}
                                  /> */}
                                </td>
                                <td className="alnrighttd">
                                  <Input
                                    placeholder="Daily rule"
                                    style={{ width: 100 }}
                                    name="daily_rule"
                                    onChange={this.handleChange}
                                  />
                                </td>
                                <td className="alnrighttd">
                                  <Input
                                    placeholder="Day end alert rule"
                                    style={{ width: 100 }}
                                    name="day_end_alert_rule"
                                    onChange={this.handleChange}
                                  />
                                </td>

                                {/* <td>
                                  <Link
                                    to="#"
                                    onClick={() => this.openupdaterulesbox()}
                                  >
                                    Update rules
                                  </Link>
                                </td> */}
                              </tr>
                            </tbody>
                          </Table>
                        </Row>
                        <Row>
                          <Col md="12" style={{paddingTop:"5px"}}>
                            <Button
                              type="button"
                              color="success"
                              style={{ float: "right"}}
                              onClick={this.handleSubmit}
                            >
                              Add Rule
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Card>
            {/* ==========================================Add condition================================================ */}
            <Modal
              size="sm"
              isOpen={this.state.modal_small}
              toggle={this.tog_addconditions}
              backdrop="static"
            >
              <ModalHeader
                toggle={() => this.setState({ modal_small: false })}
                style={{ border: "none", backgroundColor: "#A4C1D2" }}
              >
                Conditions
              </ModalHeader>
              <ModalBody
                style={{
                  maxHeight: "calc(100vh - 210px)",
                  overflowY: "auto",
                }}
              >
                {/* <pre>{JSON.stringify(this.state.current_rules, null, 2)}</pre> */}
                <TextArea
                  rows={4}
                  name="condition"
                  onChange={this.handleChange}
                />
              </ModalBody>
              {/* <ModalFooter style={{ border: "none" }}>
                <Button
                  type="button"
                  color="light"
                  onClick={() => this.setState({ modal_small: false })}
                >
                  Cancel
                </Button>
                <Button
                  type=""
                  color="primary"
                  //   onClick={this.confirmUserDelete}
                >
                  Update
                </Button>
              </ModalFooter> */}
            </Modal>
            {/* ==========================================Add Rules================================================ */}
            <Modal
              size="sm"
              isOpen={this.state.modal_rule}
              toggle={this.tog_addrule}
              backdrop="static"
            >
              <ModalHeader
                toggle={() => this.setState({ modal_rule: false })}
                style={{ border: "none", backgroundColor: "#A4C1D2" }}
              >
                Rules
              </ModalHeader>
              <ModalBody
                style={{
                  maxHeight: "calc(100vh - 210px)",
                  overflowY: "auto",
                }}
              >
                {/* <pre>{JSON.stringify(this.state.current_rules, null, 2)}</pre> */}
                <TextArea rows={4} name="rule" onChange={this.handleChange} />
              </ModalBody>
              {/* <ModalFooter style={{ border: "none" }}>
                <Button
                  type="button"
                  color="light"
                  onClick={() => this.setState({ modal_rule: false })}
                >
                  Cancel
                </Button>
                <Button
                  type=""
                  color="primary"
                  //   onClick={this.confirmUserDelete}
                >
                  Update
                </Button>
              </ModalFooter> */}
            </Modal>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
