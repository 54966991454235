import React, { Component } from "react";
import Permission from "../../permission.js";
import {
  Row,
  Col,
  Button,
  Alert,
  Container,
  Label,
  FormGroup,
} from "reactstrap";
import Captcha from 'demos-react-captcha';
// Redux
import { Link } from "react-router-dom";
import validator from "validator";
import ReCAPTCHA from 'react-google-recaptcha';
// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

// import images
import logodark from "../../assets/images/misteo-logo.png";
import { Redirect, Route } from "react-router-dom";
import { toast } from "react-toastify";

export default class Login extends React.Component {
  constructor() {
    super();
    let loggedIn = false;
    this.recaptchaRef = React.createRef();
    const token = localStorage.getItem("token");
    if (token) loggedIn = true;

    this.state = {
      email: "",
      password: "",
      loggedIn,
      error: "",
      isEmail: false,
      isNumber: false,
      shownext: false,
      farmernext: false,
      showbutton: true,
      showfield1: false,
      phno: "",
      otp: "",
      username: "",
      invaliduser: false,
      minutes: 0,
      seconds: 30,
      logoutMessage: '',
      captchaResponse:'',
      captchaVerified: false,
      captchaKey: 1,
    };
    this.onChange = this.onChange.bind(this);
    this.formSubmit = this.formSubmit.bind(this);
    this.checknext = this.checknext.bind(this);
    this.emailinput = React.createRef();
    this.passwordinput = React.createRef();
    this.resendOTP=this.resendOTP.bind(this);
    this.handleReload = this.handleReload.bind(this);
  }

  onChange(ev) {
    this.setState({
      [ev.target.name]: ev.target.value,
    });
  }
  async resendOTP() {
    let resendotp = {
      farmer_mobile: this.state.phno,
    };
    var data = JSON.stringify(resendotp);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: data,
    };
    try {
      var resendOTP = await fetch(
        process.env.REACT_APP_APIEND + `farmer/resend-otp`,
        requestOptions
      ).then((res) => {
        return res.json();
      });
      {
        toast.success("OTP Sent to", this.state.phno);
      }
    } catch (err) {
      toast.error("Something's not right");
    }
  }
  async formSubmit(ev) {
    ev.preventDefault();
    // console.log(this.state.farmernext);
    if (this.state.farmernext == true) {
      let logindata = {
        farmer_mobile: this.state.phno,
        otp: this.state.otp,
      };
      var credentials = JSON.stringify(logindata);
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: credentials,
      };
      try {
        var darmerresp = await fetch(
          process.env.REACT_APP_APIEND + `farmer/login`,
          requestOptions
        ).then((res) => {
          return res.json();
        });
        if (darmerresp.result.token && darmerresp.code == 200) {
          localStorage.setItem("token", String(darmerresp.result.token));
          localStorage.setItem("email", "farmer@misteo.co");
          localStorage.setItem("username", darmerresp.result.farmer_name);
          localStorage.setItem("uid", darmerresp.result.farmer_id);
          localStorage.setItem("krishibhavan", darmerresp.result.rua);
          localStorage.setItem("user_type", "FM");
          localStorage.setItem("farmer_id", darmerresp.result.farmer_id);
          localStorage.setItem("LN", "മലയാളം");
          this.setState({
            loggedIn: true,
          });
        } else {
          toast.error("Invalid OTP");
        }
      } catch (err) {
        toast.error("Something's not right");
        this.handleReload();
        this.setState({captchaVerified: false})
      }
    } else {
      let credentialdata = {
        email: this.emailinput.current.value,
        password: this.passwordinput.current.value,
        // recaptcha: this.state.captchaResponse
      };
      credentialdata = JSON.stringify(credentialdata);

      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: credentialdata,
      };
      try {
        var loginapiResponse = await fetch(
          process.env.REACT_APP_APIEND + `department/login`,
          requestOptions
        ).then((res) => {
          return res.json();
        });
        if (loginapiResponse.token && loginapiResponse.code == 200) {
          localStorage.setItem("token", loginapiResponse.token);
          localStorage.setItem("email", loginapiResponse.email);
          localStorage.setItem("username", loginapiResponse.name);
          localStorage.setItem("uid", loginapiResponse.id);
          localStorage.setItem("LN", "English");
          localStorage.setItem(
            "krishibhavan",
            loginapiResponse["krishibhavan"]
          );
          localStorage.setItem("authUser", JSON.stringify(loginapiResponse));
          localStorage.setItem("user_type", loginapiResponse["user type"]);
          this.setState({
            loggedIn: true,
          });
        } else if (loginapiResponse.code == 501) {
          toast.error("Invalid User Credentials");
          if (this.recaptchaRef.current) {
            this.recaptchaRef.current.reset();
          }
        }else if (loginapiResponse.code == 625) {
          toast.error(loginapiResponse.message);
          if (this.recaptchaRef.current) {
            this.recaptchaRef.current.reset();
          }
        }if (loginapiResponse.code == 626) {
          toast.error(loginapiResponse.message);
          if (this.recaptchaRef.current) {
            this.recaptchaRef.current.reset();
          }
        }
        if (loginapiResponse.code == 623) {
          toast.error(loginapiResponse.message);
          if (this.recaptchaRef.current) {
            this.recaptchaRef.current.reset();
          }
        }if (loginapiResponse.code == 400) {
          toast.error(loginapiResponse.message);
          if (this.recaptchaRef.current) {
            this.recaptchaRef.current.reset();
          }
        }
      } catch (err) {
        toast.error("Something's not right");
      }
    }
  }

  async checknext() {
    var phoneno = /^[0-9]{10}$/;

    if (validator.isEmail(this.emailinput.current.value)) {
      this.setState({
        shownext: true,
        showbutton: false,
        showfield1: true,
      });
    } else if (this.emailinput.current.value.match(phoneno)) {
      let user_phno = {
        farmer_mobile: String(this.emailinput.current.value),
      };
      user_phno = JSON.stringify(user_phno);
      // console.log("FARMER PHONE NUMBER ", user_phno);
      this.setState({
        phno: this.emailinput.current.value,
      });

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: user_phno,
      };
      var phnovalidate = await fetch(
        process.env.REACT_APP_APIEND + `farmer/mob-check`,
        requestOptions
      ).then((res) => {
        return res.json();
      });
      // console.log("API STATUS", phnovalidate[0].status);
      if (phnovalidate[0].status == "True") {
        this.setState({
          // phno: this.state.username,
          farmernext: true,
          showbutton: false,
          showfield1: true,
        });
      } else {
        this.setState({
          invaliduser: true,
        });
      }
    }
  }
  handleCaptchaSuccess = (response) => {
    this.setState({ captchaResponse: response });
  };
  handleCaptchaVerification = (verified) => {
    this.setState({ captchaVerified: verified });
  };
  handleReload() {
    this.setState(prevState => ({
      captchaKey: prevState.captchaKey + 1, 
    }));
  }
  componentDidMount() {
    const logoutMessage = localStorage.getItem('logoutMessage'); 
    if (logoutMessage) {
      this.setState({ logoutMessage });
      localStorage.removeItem('logoutMessage');
      toast.success(logoutMessage);
    }
    this.myInterval = setInterval(() => {
      const { seconds, minutes } = this.state;

      if (seconds > 0) {
        this.setState(({ seconds }) => ({
          seconds: seconds - 1,
        }));
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(this.myInterval);
        } else {
          this.setState(({ minutes }) => ({
            minutes: minutes - 1,
            seconds: 59,
          }));
        }
      }
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.myInterval);
  }

  render() {
    const siteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
    const { captchaVerified } = this.state;
    const { captchaKey } = this.state;
    const user_role = Permission();
    if (this.state.loggedIn === true && user_role[0] == "FM") {
      return <Redirect to="/farmerdashboard" />;
    } else if (
      (this.state.loggedIn === true && user_role[0] == "DR") ||
      user_role[0] == "ADM" ||
      user_role[0] == "KB"
    ) {
      return <Redirect to="/dashboard" />;
    }

    return (
      <React.Fragment>
        <div>
          <Container fluid className="p-0">
            <Row className="no-gutters">
              <Col lg={4}>
                <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                  <div className="w-100">
                    <Row className="justify-content-center">
                      <Col lg={9}>
                        <div>
                          <div className="text-center">
                            <div>
                              <Link to="/" className="logo">
                                <img src={logodark} height="50" alt="logo" />
                              </Link>
                            </div>

                            <h4 className="font-size-20 font-weight-bold">
                              SMART CROP INSURANCE
                            </h4>
                            <h4 className="font-size-14">PALAKKAD</h4>
                          </div>

                          {this.props.loginError && this.props.loginError ? (
                            <Alert color="danger">
                              {this.props.loginError}
                            </Alert>
                          ) : null}

                          <div className="p-2 mt-5">
                            <AvForm
                              className="form-horizontal"
                              onSubmit={this.formSubmit}
                            >
                              <FormGroup className="auth-form-group-custom mb-4">
                                <i className="ri-user-2-line auti-custom-input-icon"></i>
                                <Label htmlFor="username">Username</Label>
                                <input
                                  autoComplete="off"
                                  type="email"
                                  ref={this.emailinput}
                                  id="email"
                                  name="email"
                                  onChange={this.onChange}
                                  className="form-control"
                                  placeholder="Enter username"
                                  onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                />
                              </FormGroup>
                              <center
                                style={
                                  this.state.invaliduser
                                    ? {}
                                    : { display: "none" }
                                }
                              >
                                <p style={{ color: "red" }}>Invalid User !</p>
                              </center>

                              <center
                                style={
                                  this.state.showbutton
                                    ? {}
                                    : { display: "none" }
                                }
                              >
                                <Button
                                  color="primary"
                                  type="button"
                                  className="waves-effect waves-light mr-1"

                                  onClick={this.checknext}
                                >
                                  <i className="ri-arrow-right-line align-middle"></i>
                                </Button>                           
                              </center>
                              <div
                                style={
                                  this.state.shownext ? {} : { display: "none" }
                                }
                              >
                                <FormGroup
                                  className="auth-form-group-custom mb-4"
                                  style={{ marginTop: "5%" }}
                                >
                                  <i className="ri-lock-2-line auti-custom-input-icon"></i>
                                  <Label htmlFor="userpassword">Password</Label>
                                  <input
                                    placeholder="Enter password"
                                    type="password"
                                    ref={this.passwordinput}
                                    id="userpassword"
                                    className="form-control"
                                    name="password"
                                    onChange={this.onChange}
                                  />
                                </FormGroup>
                                <div className="mt-4 text-center">
                                  <p>{this.state.error}</p>
                                </div>
                                <div style={{display:"flex", justifyContent:"center", alignItems: "center"}}>
                                {/* <ReCAPTCHA ref={this.recaptchaRef} sitekey={siteKey} onChange={this.handleCaptchaSuccess} /> */}
                                </div>
                                <div className="mt-4 text-center">
                                  <Button
                                    type="button"
                                    color="primary"
                                    className="w-md waves-effect waves-light"
                                    type="submit"
                                  >
                                    Log In
                                  </Button>
                                </div>

                                <div className="mt-4 text-center">
                                  <Link
                                    to="/forgot-password"
                                    className="text-muted"
                                  >
                                    <i className="mdi mdi-lock mr-1"></i> Forgot
                                    your password?
                                  </Link>
                                  <br/>
                                  <Link
                                    to="/"
                                    className="text-muted"
                                  >
                                    <i className="mdi"></i> Try again with another email id?

                                  </Link>
                                </div>
                              </div>
                              <div
                                style={
                                  this.state.farmernext
                                    ? {}
                                    : { display: "none" }
                                }
                              >
                                <center>
                                  <div
                                    className="alert alert-success"
                                    role="alert"
                                    style={{
                                      fontSize: "13px",
                                      fontWeight: "lighter",
                                    }}
                                  >
                                    OTP sent succesfully to your primary mobile
                                    number {this.state.phno}
                                  </div>
                                </center>
                                <FormGroup
                                  className="auth-form-group-custom mb-4"
                                  style={{ marginTop: "5%" }}
                                >
                                  <i className="ri-lock-2-line auti-custom-input-icon"></i>
                                  <Label htmlFor="farmerotp">OTP</Label>
                                  <AvField
                                    autoComplete="off"
                                    name="otp"
                                    className="form-control"
                                    placeholder="Enter OTP"
                                    onChange={this.onChange}
                                  />
                                </FormGroup>
                                <div className="mt-4 text-center">
                                  <p>{this.state.error}</p>
                                </div>
                                <div className="mt-2 text-center">
                                <div>
                                    {this.state.seconds === 0 ? (
                                      <Link
                                        to="#"
                                        className="text-muted"
                                        onClick={this.resendOTP}
                                      >
                                        <i className="mdi mdi-restore mr-1"></i>
                                        Resend OTP
                                      </Link>
                                    ) : (
                                      <h5>
                                        Resend OTP in :
                                        {this.state.seconds < 10
                                          ? `0${this.state.seconds}`
                                          : this.state.seconds}
                                      </h5>
                                    )}
                                  </div>
                                  {/* <Link
                                    to="#"
                                    className="text-muted"
                                    onClick={this.resendOTP}
                                  >
                                    <i className="mdi mdi-restore mr-1"></i>
                                    Resend OTP
                                  </Link> */}
                                </div>
                                {/* <div style={{display:"flex", justifyContent:"center", alignItems: "center"}}>
                                <Captcha key={captchaKey} onChange={this.handleCaptchaVerification}
                                    placeholder="Enter captcha" 
                                    length={6} />
                                </div> */}
                                <div className="mt-4 text-center">
                                  <Button
                                    color="primary"
                                    className="w-md waves-effect waves-light"
                                    type="submit"
                                    // disabled={!captchaVerified}
                                  >
                                    Log In
                                  </Button>
                                </div>

                                <div className="mt-4 text-center">
                                  <Link
                                    to="/"
                                    className="text-muted"
                                  >
                                    <i className="mdi"></i> Try again with another number?

                                  </Link>
                                </div>
                              </div>
                            </AvForm>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
              <Col lg={8}>
                <div className="authentication-bg"></div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
