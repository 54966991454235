
import React, { Component } from "react";
import { Row, Col, Alert, Button, Container, FormGroup, Label } from "reactstrap";

// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

// action
import { forgetUser } from '../../store/actions';

// import images
import logodark from "../../assets/images/misteo-logo.png";
import { toast } from 'react-toastify';
class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      confirmpassword: "",
      showSuccess: false,
      successMessage: "New password  are sended to your mailbox",
      uid: "",
    };

    // handleValidSubmit
    this.onChange = this.onChange.bind(this);
    this.passwordinput = React.createRef();
    this.confirmpasswordinput = React.createRef();
  }
  handlePasswordChange = (event) => {
    this.setState({ password: event.target.value });
  };

  handleConfirmPasswordChange = (event) => {
    this.setState({ confirmPassword: event.target.value });
  };
  handleSubmit = async (event) => {
    event.preventDefault();
    let credentialdata = {
      password: this.passwordinput.current.value,
      confirm_password: this.confirmpasswordinput.current.value,
    };
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/;

    if (!passwordRegex.test(this.state.password)) {
      toast.error(
        "Password must be at least 6 characters long and contain at least one uppercase letter, one lowercase letter, one number, and one special character."
      );
      return;
    }
    if (this.state.password === this.state.confirmpassword) {
      try {
        const requestOptions = {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(credentialdata),
        };

        const response = await fetch(
          process.env.REACT_APP_APIEND + `department/reset-password?uid=` + this.state.uid,
          requestOptions
        );
        const resetResponse = await response.json();
        if (resetResponse.hasOwnProperty("code")) {
          if (resetResponse.code === 200) {
            toast.success(resetResponse.message);
            setTimeout(() => {
                this.props.history.push('/login');
              }, 2000);
          } else if (resetResponse.code === 204) {
            toast.error(resetResponse.message);
          }
        } else if (resetResponse.hasOwnProperty("non_field_errors")) {
          toast.error(resetResponse.non_field_errors[0]);
        }
      } catch (error) {
        toast.error("An error occurred during password reset.");
      }
    } else {
      toast.error("Passwords do not match!");
    }
  };
  onChange(ev) {
    this.setState({
      [ev.target.name]: ev.target.value,
    });
  }
  componentDidMount() {
    const url = new URL(window.location.href);
    const searchParams = new URLSearchParams(url.search);
    const id = searchParams.get("uid");
    this.setState({
      uid: id,
    });
  }

  render() {
    const { password, confirmPassword } = this.state;
    return (
      <React.Fragment>
        <div className="home-btn d-none d-sm-block">
          <Link to="/">
            <i className="mdi mdi-home-variant h2 text-white"></i>
          </Link>
        </div>
        <div>
          <Container fluid className="p-0">
            <Row className="no-gutters">
              <Col lg={4}>
                <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                  <div className="w-100">
                    <Row className="justify-content-center">
                      <Col lg={9}>
                        <div>
                          <div className="text-center">
                            <div>
                              <Link to="/" className="logo">
                                <img src={logodark} height="50" alt="logo" />
                              </Link>
                            </div>

                            <h4 className="font-size-18 mt-4">
                              Change Password
                            </h4>
                          </div>

                          <div className="p-2 mt-5">
                            <AvForm
                              className="form-horizontal"
                              onValidSubmit={this.handleSubmit}
                            >
                              <FormGroup
                                className="auth-form-group-custom mb-4"
                                style={{ marginTop: "5%" }}
                              >
                                <i className="ri-lock-2-line auti-custom-input-icon"></i>
                                <Label htmlFor="userpassword">Password</Label>
                                <input
                                  placeholder="Enter password"
                                  type="password"
                                  ref={this.passwordinput}
                                  id="userpassword"
                                  className="form-control"
                                  name="password"
                                  onChange={this.onChange}
                                />
                              </FormGroup>
                              <FormGroup
                                className="auth-form-group-custom mb-4"
                                style={{ marginTop: "5%" }}
                              >
                                <i className="ri-lock-2-line auti-custom-input-icon"></i>
                                <Label htmlFor="confirmpassword">
                                  Confirm Password
                                </Label>
                                <input
                                  placeholder="Enter confirm password"
                                  type="password"
                                  ref={this.confirmpasswordinput}
                                  id="confirmpassword"
                                  className="form-control"
                                  name="confirmpassword"
                                  onChange={this.onChange}
                                />
                              </FormGroup>

                              <div className="mt-4 text-center">
                                <Button
                                  color="primary"
                                  className="w-md waves-effect waves-light"
                                  type="submit"
                                >
                                  Change Password
                                </Button>
                              </div>
                              <div className="mt-4 text-center">
                                <Link to="/login" className="text-muted">
                                  <i className="mdi mdi-login mr-1"></i> Return
                                  to Login{" "}
                                </Link>
                              </div>
                            </AvForm>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
              <Col lg={8}>
                <div className="authentication-bg"></div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = state => {
    const { message, forgetError, loading } = state.Forget;
    return { message, forgetError, loading };
}

export default withRouter(
    connect(mapStatetoProps, { forgetUser })(ResetPassword)
);
