import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
  Col,
  Media,
  Button,
} from "reactstrap";
import SimpleBar from "simplebar-react";

//i18b
import { withNamespaces } from "react-i18next";

class NotificationDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: false,
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState((prevState) => ({
      menu: !prevState.menu,
    }));
  }
 
  render() {
    return (
      <React.Fragment>
        <Dropdown
          isOpen={this.state.menu}
          toggle={this.toggle}
          className="d-inline-block user-dropdown"
        >
          <DropdownToggle
            tag="button"
            className="btn header-item waves-effect"
            id="page-header-user-dropdown"
          >
            <i
              className="ri-notification-3-line"
              style={{ fontSize: "22px" }}
            ></i>
          </DropdownToggle>
          <DropdownMenu right>
            <DropdownItem>
              {/* 2021-03-01 05:29:00 Your eligible per hectare payout for Rabi
              2020-21 Mango Disease Congenial Climate phase-1 is 320.00 and the
              final eligible payout is 320.0 */}
            </DropdownItem>
            <DropdownItem></DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </React.Fragment>
    );
  }
}
export default withNamespaces()(NotificationDropdown);
