import React from "react";
import {
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
  ListGroupItemText,
} from "reactstrap";
import { withNamespaces } from "react-i18next";
import { Link } from "react-router-dom";
const ReportList = (props) => {
  const { t, i18n } = props;
  return (
    <ListGroup style={{ fontSize: "11px" }}>
      <ListGroupItem className="block-example border border-0 border-dark">
        <Link to="/weatherdata">
          <ListGroupItemHeading>
            <h6>{t("Weather Data")}</h6>
          </ListGroupItemHeading>
        </Link>
        <ListGroupItemText>
          {t("Download location wise weather data")}
        </ListGroupItemText>
      </ListGroupItem>
      <div style={props.userrole ? {} : { display: "none" }}>
        <ListGroupItem className="block-example border border-0 border-dark">
          <Link to="/hourlyparamsreport">
            <ListGroupItemHeading>
              <h6>{t("Hourly report parameter wise")}</h6>
            </ListGroupItemHeading>
          </Link>
          <ListGroupItemText>
            {t("Download hourly weather parameters - Group, RUA and Weather Station wise report")}
          </ListGroupItemText>
        </ListGroupItem>
        <ListGroupItem className="block-example border border-0 border-dark">
          <Link to="/dailyparamsreport">
            <ListGroupItemHeading>
              <h6>{t("Daily report parameter wise")}</h6>
            </ListGroupItemHeading>
          </Link>
          <ListGroupItemText>
            {t("Download daily weather parameters - Group, RUA and Weather Station wise report")}
          </ListGroupItemText>
        </ListGroupItem>
        <ListGroupItem className="block-example border border-0 border-dark">
          <Link to="/hourlyallparamsreport">
            <ListGroupItemHeading>
              <h6>{t("Hourly report - all parameters")}</h6>
            </ListGroupItemHeading>
          </Link>
          <ListGroupItemText>
            {t("Download hourly weather parameters (Minimum, Maximum & Average) - Group, RUA and Weather Station wise report")}
          </ListGroupItemText>
        </ListGroupItem>
        <ListGroupItem className="block-example border border-0 border-dark">
          <Link to="/dailyallparamsreport">
            <ListGroupItemHeading>
              <h6>{t("Daily report - all parameters")}</h6>
            </ListGroupItemHeading>
          </Link>
          <ListGroupItemText>
            {t("Download daily weather parameters (Minimum, Maximum & Average) - Group, RUA and Weather Station wise report")}
          </ListGroupItemText>
        </ListGroupItem>
      </div>
    </ListGroup>
  );
};

export default withNamespaces()(ReportList);
