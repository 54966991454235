const settmClonePerilReducer = (state = " ", action) => {
    switch (action.type) {
      case "SETCLONEPERIL":
        return (state = action.payload);
      default:
        return state;
    }
  };
  
  export default settmClonePerilReducer;
  