import React, { Component } from "react";
import { MDBDataTable } from "mdbreact";
import { Row, Col, Card, CardBody, Container } from "reactstrap";

//Import Breadcrumb

import "../Datatable-css/datatables.scss"



class DatatableTables extends Component {
  constructor(props) {
    super(props);
    this.state = {
      
    };
  }
  

  render() {

    const data = {
      columns: [
        {
          label: "Station",
          field: "station",
          sort: "asc",
          width: 150
        },
        {
          label: "Dataprovider",
          field: "data_provider",
          sort: "asc",
          width: 270
        },
        {
          label: "RUA",
          field: "rua",
          sort: "asc",
          width: 200
        },
        {
          label: "Location",
          field: "location",
          sort: "asc",
          width: 100
        },
        {
          label: "Temperature",
          field: "temperature",
          sort: "asc",
          width: 150
        },
        {
          label: "Humidity",
          field: "humidity",
          sort: "asc",
          width: 100
        }
      ],
      rows: this.props.tabledata
    };


const selectRow = {
  mode: 'checkbox',
  clickToSelect: true
};

  const CustomToggleList = ({
      columns,
      onColumnToggle,
      toggles
    }) => (
      <div className="btn-group btn-group-toggle" data-toggle="buttons">
        {
          columns
            .map(column => ({
              ...column,
              toggle: toggles[column.dataField]
            }))
            .map(column => (
              <button
                type="button"
                key={ column.dataField }
                className={ `btn btn-secondary ${column.toggle ? 'active' : ''}` }
                data-toggle="button"
                aria-pressed={ column.toggle ? 'true' : 'false' }
                onClick={ () => onColumnToggle(column.dataField) }
              >
                { column.text }
              </button>
            ))
        }
      </div>
    );
    return (
      <React.Fragment>


            <Row>
              <Col xs={12}>
                

                    <MDBDataTable responsive scrollY maxHeight='350px' paging={false} bordered data={data} style={{fontSize:"12px"}}/>

                  
              </Col>
            </Row>

          
      </React.Fragment>
    );
  }
}

export default DatatableTables;
