import React, { Component } from "react";
// Table from react-bootstrap
import {
  Card,
  CardBody,
  Row,
  Col,
  Container,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";
// Bootstrap CSS
import Permission from "../../permission.js";
import { Link } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import "../Adm-components/css/tsperilwise.css";
import { toast } from "react-toastify";
import { CSVLink } from "react-csv";
import Dropzone from "react-dropzone-uploader";
import { AvForm } from "availity-reactstrap-validation";
import { withNamespaces } from "react-i18next";
import validate_Token from "../../validateToken.js";

toast.configure();
class FarmerPremium extends Component {
  constructor(props) {
    // let querydata=props.tabledata;
    super(props);
    this.state = {
      showtable: false,
      showWaiting: true,
      season: "all",
      phase: "1",
      seasonData: [],
      userRole: "",
      dataToUpdate: [],
      statusData: [],
      modalpremiumPaid: false,
      defaultPremiumChecked: false,
      showbulkupdate: false,
      currentUID: 0,
      rua: "all",
      modal_bulkupdate: false,
      csvmapdata: [],
    };
    this.fetchseason = this.fetchseason.bind(this);
    this.handleSeasonchange = this.handleSeasonchange.bind(this);
    this.getStatus = this.getStatus.bind(this);
    this.handlepremiumCheck = this.handlepremiumCheck.bind(this);
    this.saveStatus = this.saveStatus.bind(this);
    this.handleRUAChange = this.handleRUAChange.bind(this);
    this.tog_bulkupdate = this.tog_bulkupdate.bind(this);
  }
  tog_bulkupdate() {
    this.setState((prevState) => ({
      modal_bulkupdate: !prevState.modal_bulkupdate,
    }));
    this.makecsvdata();
  }
  makecsvdata() {
    var getthis = this;
    let csvData = [
      [
        "RUA",
        "user_id",
        "farmer_id",
        "farmer_name",
        "mobile",
        "crop",
        "season",
        "premium_paid_status",
      ],
    ];
    this.state.csvmapdata.map((wdata) => {
      csvData.push([
        wdata.rua,
        localStorage.getItem('uid'),
        wdata.farmerID,
        wdata.farmerName,
        wdata.mobile,
        wdata.crop,
        wdata.season,
        wdata.premiumPaid,
      ]);
    });
    this.setState({
      csvdownloadData: csvData,
    });
  }
  getUploadParams = ({ meta }) => {
    return { url: "https://httpbin.org/post" };
  };
  handleChangeStatus = ({ meta, file }, status) => {
    // console.log(status, meta, file);
  };
  handleSubmit = async (files, allFiles) => {
    var file = files.map((f) => f.meta);
    var allfiles = allFiles[0].file;
    const formData = new FormData();
    formData.append("file", allfiles);

    allFiles.forEach((f) => f.remove());
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: "Token " + localStorage.getItem("token"),
      },
      body: formData,
    };
    try {
      var data = await fetch(
        process.env.REACT_APP_APIEND + `farmer/farmer-insuranceStatus-upload`,
        requestOptions
      ).then((res) => {
        return res.json();
      });
      if (data.code == 200) {
        toast.success("Data Uploaded Successfully!");
        // this.onsearch();
        this.getStatus();
      } else {
        toast.error("Error Occured!");
      }
    } catch (err) {
      toast.error("Something's not right");
    }
  
  };
  async getStatus() {
    this.setState({
      showloader: true,
      showtable: false,
      showWaiting: false,
    });
    let searchData = {
      season: this.state.season,
      rua: this.state.rua === "Chittur-Thathamangalam" ? "Chittur" : this.state.rua,
    };
    searchData = JSON.stringify(searchData);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + localStorage.getItem("token"),
      },
      body: searchData,
    };
    try {
      var premiumData = await fetch(
        process.env.REACT_APP_APIEND + `farmer/farmer-insurance-premium-status`,
        requestOptions
      ).then((res) => {
        return res.json();
      });
      this.setState({
        csvmapdata: premiumData.data,
      });
      this.generatetable(premiumData.data);
    } catch (err) {
      toast.error("Something's not right");
    }
  }
  updatePremiumStatus(data) {
    // console.log("PREMUM STATUS", data);
    if (data.premiumPaid == "No") {
      this.setState({
        defaultPremiumChecked: false,
      });
    } else if (data.premiumPaid == "Yes") {
      this.setState({
        defaultPremiumChecked: true,
      });
    }
    this.setState({
      currentUID: data.uid,
    });
    this.tog_premiumPaid();
  }
  tog_premiumPaid() {
    this.setState((prevState) => ({
      modalpremiumPaid: !prevState.modalpremiumPaid,
    }));
  }
  generatetable(data) {
    let getthis = this;
    let tabledata = [];
    data.map(function (item, index, data) {
      var newdata = {
        rua: item.rua,
        uid: item.uid,
        farmerID: item.farmerID,
        farmerName: item.farmerName,
        mobile: item.mobile,
        crop: item.crop,
        season: item.season,
        perils: item.perils,
        premiumPaid: getthis.checkStatus(item.premiumPaid),
        updateStatus: (
          <p onClick={() => getthis.updatePremiumStatus(item)}>
            <Link>Change Status</Link>
          </p>
        ),
      };
      tabledata.push(newdata);
    });
    // console.log("ITEMS", tabledata);
    this.setState({
      statusData: tabledata,
      showtable: true,
      showWaiting: false,
      showloader: false,
      showbulkupdate: true,
    });
  }
  checkStatus(status) {
    if (status == "Yes") {
      return (
        <i
          style={{
            transform: "translateX(0) translateY(20%)",
            textAlign: "center",
            display: "grid",
          }}
          className="ri-check-fill"
        ></i>
      );
    } else {
      return (
        <i
          style={{
            transform: "translateX(0) translateY(20%)",
            textAlign: "center",
            display: "grid",
          }}
          className="ri-close-fill"
        ></i>
      );
    }
  }
  async fetchseason() {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + localStorage.getItem("token"),
      },
    };
    try {
      var seasonData = await fetch(
        process.env.REACT_APP_APIEND + `smart_crop/term-sheet-season-crop`,
        requestOptions
      ).then((res) => {
        return res.json();
      });
      this.setState({
        perilseasonData: seasonData.result,
      });

      var newseasonData = [];
      {
        seasonData.result.map(function (item, index, data) {
          newseasonData.push(Object.keys(item));
        });
      }
      // console.log("NEW DATA", seasonData.result);
      this.setState({
        seasonData: newseasonData,
      });
    } catch (errseason) {
      toast.error("Something's not right");
    }
  }
  handleSeasonchange(e) {
    this.setState({
      season: e.target.value,
    });
  }
  componentDidMount() {
    validate_Token();
    this.fetchseason();
    
    const krishibhavan = Permission();
    if (krishibhavan[0] == "KB") {
      this.setState({
        userRole: "KB",
        rua: krishibhavan[1],
      });
    }
  }
  handlepremiumCheck(e) {
    // console.log("HANDLE CHECK", e.target.checked);
    this.setState({
      defaultPremiumChecked: e.target.checked,
    });
  }
  async saveStatus() {
    // console.log("CURRENT STATUs", this.state.defaultPremiumChecked);
    var status;
    if (this.state.defaultPremiumChecked == true) {
      status = "Yes";
    } else {
      status = "No";
    }
    let searchData = {
      uid: this.state.currentUID,
      user_id: localStorage.getItem("uid"),
      premium_paid: status,
    };
    searchData = JSON.stringify(searchData);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + localStorage.getItem("token"),
      },
      body: searchData,
    };
    try {
      var premiumData = await fetch(
        process.env.REACT_APP_APIEND + `farmer/farmer-insurance-status-premium-update`,
        requestOptions
      ).then((res) => {
        return res.json();
      });
      if(premiumData.code === 200 ){
        toast.success("Data Updated Successfully!");
        this.getStatus();
      }else {
        toast.error("Error Occured!");
      }
    } catch (err) {
      toast.error("Something's not right");
    }
  }
  handleRUAChange(e) {
    this.setState({
      rua: e.target.value,
    });
  }
  render() {
    const { t, i18n } = this.props;
    var data = {
      columns: [
        {
          label: "UID",
          field: "uid",
          width: 100,
        },
        {
          label: "RUA",
          field: "rua",
          width: 150,
        },
        {
          label: "Farmer ID",
          field: "farmerID",
          width: 150,
        },
        {
          label: "Farmer Name",
          field: "farmerName",
          width: 150,
        },
        {
          label: "Crop",
          field: "crop",
          width: 150,
        },
        {
          label: "Mobile",
          field: "mobile",
          width: 150,
        },
        {
          label: "Season",
          field: "season",
          width: 150,
        },
        {
          label: "Premium Paid",
          field: "premiumPaid",
          width: 150,
        },
        {
          label: "Update Status",
          field: "updateStatus",
          width: 150,
        },
      ],

      rows: this.state.statusData,
    };
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Row>
              <Col xs={12}>
                <h4
                  className="card-title"
                  style={{ padding: "5px", fontSize: "18px" }}
                >
                  {t("Farmer Premium Status")}
                </h4>
              </Col>
            </Row>
            <Row>
              <Col>
                <Card style={{ backgroundColor: "#f1f6fb" }}>
                  <CardBody>
                    <Col>
                      <Row>
                        <Col md="2">
                          <FormGroup>
                            <Label
                              className="col-md-10 col-form-label"
                              style={{ fontSize: "14px" }}
                            >
                              {t("RUA")}{" "}
                            </Label>
                            <select
                              className="form-select"
                              style={{ fontSize: "11px" }}
                              value={this.state.rua}
                              onChange={this.handleRUAChange}
                              name="rua"
                            >
                              <option
                                style={
                                  this.state.userRole == "KB"
                                    ? { display: "none" }
                                    : {}
                                }
                                value="all"
                              >
                                All
                              </option>
                              <option
                                value="Chittur-Thathamangalam"
                                style={
                                  this.state.userRole == "KB"
                                    ? this.state.rua == "Chittur-Thathamangalam"
                                      ? {}
                                      : { display: "none" }
                                    : {}
                                }
                              >
                                Chittur-Thathamangalam
                              </option>
                              <option
                                value="Eruthenpathy"
                                style={
                                  this.state.userRole == "KB"
                                    ? this.state.rua == "Eruthenpathy"
                                      ? {}
                                      : { display: "none" }
                                    : {}
                                }
                              >
                                Eruthenpathy{" "}
                              </option>
                              <option
                                value="Kozhinjampara"
                                style={
                                  this.state.userRole == "KB"
                                    ? this.state.rua == "Kozhinjampara"
                                      ? {}
                                      : { display: "none" }
                                    : {}
                                }
                              >
                                Kozhinjampara
                              </option>
                              <option
                                value="Nallepilly"
                                style={
                                  this.state.userRole == "KB"
                                    ? this.state.rua == "Nallepilly"
                                      ? {}
                                      : { display: "none" }
                                    : {}
                                }
                              >
                                Nallepilly
                              </option>
                              <option
                                value="Pattanchery"
                                style={
                                  this.state.userRole == "KB"
                                    ? this.state.rua == "Pattanchery"
                                      ? {}
                                      : { display: "none" }
                                    : {}
                                }
                              >
                                Pattanchery
                              </option>
                              <option
                                value="Perumatty"
                                style={
                                  this.state.userRole == "KB"
                                    ? this.state.rua == "Perumatty"
                                      ? {}
                                      : { display: "none" }
                                    : {}
                                }
                              >
                                Perumatty
                              </option>
                              <option
                                value="Vadakarapathy"
                                style={
                                  this.state.userRole == "KB"
                                    ? this.state.rua == "Vadakarapathy"
                                      ? {}
                                      : { display: "none" }
                                    : {}
                                }
                              >
                                Vadakarapathy
                              </option>
                            </select>
                          </FormGroup>
                        </Col>
                        <Col md="2">
                          <FormGroup>
                            <Label
                              className="col-md-10 col-form-label"
                              style={{ fontSize: "14px" }}
                            >
                              {t("Season")}{" "}
                            </Label>
                            <select
                              className="form-select"
                              style={{ fontSize: "11px" }}
                              value={this.state.season}
                              onChange={this.handleSeasonchange}
                              name="season"
                            >
                              <option value="all">All</option>
                              {this.state.seasonData.length > 0 &&
                                this.state.seasonData.map((item) => (
                                  <option value={item} key={item}>
                                    {item}
                                  </option>
                                ))}
                            </select>
                          </FormGroup>
                        </Col>
                        <Col md="2">
                          <FormGroup>
                            <Label
                              className="col-md-12 col-form-label"
                              style={{ fontSize: "14px" }}
                            >
                              <span>&nbsp;</span>
                            </Label>
                            <Button
                              color="primary"
                              className="waves-effect waves-light mr-1"
                              style={{ fontSize: "11px", float: "right" }}
                              onClick={this.getStatus}
                            >
                              {t("Search")}
                            </Button>
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup
                            style={
                              this.state.showbulkupdate == true
                                ? {}
                                : { display: "none" }
                            }
                          >
                            <Button
                              color="success"
                              className="waves-effect waves-light mr-1 btn-bulkupdate"
                              style={{
                                fontSize: "11px",
                                float: "right",
                                // marginBottom: "20px",
                              }}
                              onClick={this.tog_bulkupdate}
                            >
                              Bulk Update
                            </Button>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col></Col>
                      </Row>
                    </Col>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <center>
                      <p
                        style={
                          this.state.showWaiting ? {} : { display: "none" }
                        }
                      >
                        {t("Waiting for search")}
                      </p>
                      <Spinner
                        style={this.state.showloader ? {} : { display: "none" }}
                      ></Spinner>
                    </center>
                    <div
                      style={this.state.showtable ? {} : { display: "none" }}
                    >
                      <MDBDataTable
                        paging={true}
                        bordered
                        data={data}
                        order={["trnID", "asc"]}
                        className="datadownloadtable"
                        style={{ fontSize: "12px" }}
                        scrollX
                        maxHeight="300px"
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Modal
              isOpen={this.state.modalpremiumPaid}
              toggle={this.tog_premiumPaid}
              centered={true}
              backdrop="static"
            >
              <ModalHeader
                toggle={() => this.setState({ modalpremiumPaid: false })}
              >
                Update Status
              </ModalHeader>

              <ModalBody
                style={{
                  maxHeight: "calc(100vh - 210px)",
                  overflowY: "auto",
                }}
              >
                {" "}
                <AvForm>
                  <div class="row">
                    <div className="form-check form-check-right mb-1">
                      <div class="col" style={{ float: "left" }}>
                        <Input
                          className="form-check-input"
                          type="checkbox"
                          id="premiumPaid"
                          onChange={this.handlepremiumCheck}
                          checked={this.state.defaultPremiumChecked}
                        />
                        <Label
                          className="form-check-label"
                          htmlFor="weathercheck"
                          style={{ fontWeight: "lighter" }}
                        >
                          Premium Paid ?
                        </Label>
                      </div>
                    </div>
                  </div>
                  <ModalFooter style={{ border: "none" }}>
                    <Button
                      type="button"
                      color="light"
                      onClick={() => this.setState({ modalpremiumPaid: false })}
                    >
                      Cancel
                    </Button>
                    <Button type="" color="primary" onClick={this.saveStatus}>
                      Update
                    </Button>
                  </ModalFooter>
                </AvForm>
              </ModalBody>
            </Modal>
            {/* ===============================================Bulk Update========================================================= */}
            <Modal
              isOpen={this.state.modal_bulkupdate}
              toggle={this.tog_bulkupdate}
              backdrop="static"
              centered
              size="md"
            >
              <ModalHeader
                toggle={() => this.setState({ modal_bulkupdate: false })}
                style={{ backgroundColor: "#A4C1D2" }}
              >
                Bulk Update
              </ModalHeader>
              <ModalBody>
                <div style={{ float: "right", padding: "2px" }}>
                  <CSVLink
                    filename="TransactionData.csv"
                    data={this.state.csvdownloadData}
                  >
                    Download Data
                  </CSVLink>
                </div>
                <Form>
                  <Dropzone
                    getUploadParams={this.getUploadParams}
                    onChangeStatus={this.handleChangeStatus}
                    onSubmit={this.handleSubmit}
                    maxFiles={1}
                    accept=".csv"
                    styles={{
                      dropzone: { overflow: "hidden" },
                    }}
                    inputContent={
                      <div style={{ fontSize: "16px", color: "#8f8e8d" }}>
                        Upload Data
                      </div>
                    }
                  />
                </Form>
              </ModalBody>
            </Modal>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default withNamespaces()(FarmerPremium);
