import React, { Component } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Row,
  Col,
  CardText,
  FormGroup,
  NavLink,
  NavItem,
  Nav,
  Label,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container,
  Button,
  Spinner,
} from "reactstrap";
// import './table.css'

import "antd/dist/antd.css";

import defaultcontent from "./termsheet_default";
import Tmtemplate from "./Tm_condition_template";
import { Link } from "react-router-dom";

import { Tabs, Input, Radio, Checkbox } from "antd";
import validate_Token from "../../validateToken.js";

//import css
import "../../assets/scss/tab.css";
const TabPane = Tabs.TabPane;
const { TextArea } = Input;

class Termsheet_update extends Component {
  constructor(props) {
    // console.log("PROPS IN UPDATE", props);
    super(props);
    let Setpanes = [];
    props.location.state.data.map((phases, index) => {
      // console.log("PHASES",phases,index)
      Setpanes.push({
        title: "Phase" + (index + 1),
        content: defaultcontent(phases[index + 1]),
        key: index + 1,
        closable: false,
      });
      // console.log("PANES",phases[index+1])
    });
    const panes = Setpanes;
    this.state = {
      props_data: props.location.state.data,
      activeKey: panes.key,
      panes,
    };
    this.newTabIndex = 0;
    // console.log("PROPS STATE", this.state.props_data);
  }
  onChange = (activeKey) => {
    this.setState({ activeKey });
  };
  onEdit = (targetKey, action) => {
    this[action](targetKey);
  };
  add = () => {
    const panes = this.state.panes;
    const activeKey = `newTab${this.newTabIndex++}`;
    let phase_no = panes.length;
    phase_no = phase_no + 1;
    panes.push({
      title: "Phase " + phase_no,
      content: Tmtemplate(),
      key: activeKey,
    });
    this.setState({ panes, activeKey });
  };
  remove = (targetKey) => {
    let activeKey = this.state.activeKey;
    let lastIndex;
    this.state.panes.forEach((pane, i) => {
      if (pane.key === targetKey) {
        lastIndex = i - 1;
      }
    });
    const panes = this.state.panes.filter((pane) => pane.key !== targetKey);
    if (lastIndex >= 0 && activeKey === targetKey) {
      activeKey = panes[lastIndex].key;
    }
    this.setState({ panes, activeKey });
  };
  componentDidMount() {
    validate_Token();
  }
  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Card style={{ backgroundColor: "#e5ebee" }}>
            <CardHeader style={{ backgroundColor: "#A4C1D2" }}>
              <Row>
                <Col xs={8}>
                  <h4
                    className="card-title"
                    style={{ padding: "5px", fontSize: "18px" }}
                  >
                    {" "}
                    Update Termsheet Conditions
                  </h4>
                </Col>
                <Col>
                  <Link to="/tm_conditions">
                    <i
                      className="ri-close-fill"
                      style={{ fontSize: "20px", float: "right" }}
                    ></i>
                  </Link>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Container fluid>
                <Row>
                  <Col xs={12}></Col>
                </Row>
                <Card>
                  <CardBody>
                    <Row>
                      <Col>
                        <Col>
                          <Row>
                            <Col md="4">
                              <FormGroup>
                                <Label
                                  className="col-md-5 col-form-label"
                                  style={{ fontSize: "14px" }}
                                >
                                  Season{" "}
                                </Label>
                                <select
                                  className="form-select"
                                  md="6"
                                  style={{ fontSize: "11px" }}
                                  name="season"
                                  value={this.state.props_data[0][1].season}
                                  disabled
                                >
                                  <option value={this.state.props_data[0][1].season}>
                                  {this.state.props_data[0][1].season}
                                  </option>                                 
                                </select>
                              </FormGroup>
                            </Col>

                            <Col md="2">
                              <FormGroup>
                                <Label
                                  className="col-md-10 col-form-label"
                                  style={{ fontSize: "14px" }}
                                >
                                  Crop{" "}
                                </Label>
                                <select
                                  className="form-select"
                                  style={{ fontSize: "11px" }}
                                  name="crop"
                                  onChange={this.onChange}
                                  value={this.state.props_data[0][1].crop}
                                  disabled
                                >
                                  <option value={this.state.props_data[0][1].crop} selected>
                                  {this.state.props_data[0][1].crop}
                                  </option>
                                 
                                </select>
                              </FormGroup>
                            </Col>

                            <Col md="2">
                              <FormGroup>
                                <Label
                                  className="col-md-10 col-form-label"
                                  style={{ fontSize: "14px" }}
                                >
                                  Peril{" "}
                                </Label>
                                <select
                                  className="form-select"
                                  style={{ fontSize: "11px" }}
                                  name="season"
                                  onChange={this.onChange}
                                  value={this.state.props_data[0][1].peril}
                                  disabled
                                >
                                  <option value={this.state.props_data[0][1].peril}>
                                  {this.state.props_data[0][1].peril}
                                  </option>
                            
                                </select>
                              </FormGroup>
                            </Col>
                            <Col md="4">
                              <FormGroup>
                                <Label
                                  className="col-md-10 col-form-label"
                                  style={{ fontSize: "14px" }}
                                >
                                  Execution
                                </Label>
                                <Radio.Group
                                  buttonStyle="solid"                                 
                                  defaultValue={
                                    this.state.props_data[0][1].is_phase == "Y"
                                      ? "Phase End"
                                      : "Day End"
                                  }
                                >
                                  <Radio.Button value="Day End"  style={{ fontSize: "12px" }}>
                                    Day End
                                  </Radio.Button>
                                  <Radio.Button value="Phase End"  style={{ fontSize: "12px" }}>
                                    Phase End
                                  </Radio.Button>
                                </Radio.Group>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col></Col>
                          </Row>
                        </Col>
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={12}>
                        <center></center>
                        <Tabs
                          onChange={this.onChange}
                          activeKey={this.state.activeKey}
                          type="editable-card"
                          onEdit={this.onEdit}
                          centered
                        >
                          {this.state.panes.map((pane) => (
                            <TabPane
                              tab={pane.title}
                              key={pane.key}
                              closable={pane.closable}
                            >
                              {pane.content}
                            </TabPane>
                          ))}
                        </Tabs>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Container>
            </CardBody>
          </Card>
        </div>
      </React.Fragment>
    );
  }
}
export default Termsheet_update;
