import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { CSVLink } from 'react-csv';
import { MDBDataTable } from "mdbreact";
import { Spinner, Table } from "reactstrap";
import "../Datatable-css/datatables.scss"
import { DatePicker } from 'antd';
import moment from 'moment';
import validate_Token from "../../validateToken.js";
import { withNamespaces } from "react-i18next";
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  Label,
  Container,
  CardHeader,
} from "reactstrap";

const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD';

class daily_Parameter extends Component {
  constructor(props) {
    super(props)
    this.state = {
      subws: true,
      subrua: false,
      subgroup: false,
      showbutton: false,
      subsource: 'all',
      source: 'weather_stations',
      parameter: 'temperature',
      data: [],
      csvdownloadData: [],
      showtable: false,
      showwaitintext: true,
      showloader: false,
    }
    this.handleSourcechange = this.handleSourcechange.bind(this);
    this.onChangedate = this.onChangedate.bind(this);
    this.getData = this.getData.bind(this);
    this.handlesubSourcechange = this.handlesubSourcechange.bind(this);
    this.handleParameterchange = this.handleParameterchange.bind(this);
    this.makecsvdata = this.makecsvdata.bind(this);
  }


  onChangedate(dateString) {
    const date1 = new Date(dateString[0]);
    const date2 = new Date(dateString[1]);
    var from_dd = String(date1.getDate()).padStart(2, "0");
    var from_mm = String(date1.getMonth() + 1).padStart(2, "0"); //January is 0!
    var from_yyyy = date1.getFullYear();
    var from_date = from_yyyy + "-" + from_mm + "-" + from_dd;

    var to_dd = String(date2.getDate()).padStart(2, "0");
    var to_mm = String(date2.getMonth() + 1).padStart(2, "0"); //January is 0!
    var to_yyyy = date2.getFullYear();
    var to_date = to_yyyy + "-" + to_mm + "-" + to_dd;

    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    if (diffDays <= 30) {
      this.setState({
        fromDate: from_date,
        toDate: to_date,
        showbutton: true,
      });
    } else {
      this.setState({
        showbutton: false,
      });
    }
  }



  handlesubSourcechange(e) {
    this.setState({
      subsource: e.target.value
    })
  }

  handleParameterchange(e) {
    this.setState({
      parameter: e.target.value
    })
  }

  handleSourcechange(e) {
    this.setState({
      source: e.target.value
    })
    if (e.target.value == "weather_stations") {
      this.setState({
        subws: true,
        subrua: false,
        subgroup: false,
      })
    }
    if (e.target.value == "rua") {
      this.setState({
        subrua: true,
        subws: false,
        subgroup: false,
      })

    }
    if (e.target.value == "group") {
      this.setState({
        subgroup: true,
        subws: false,
        subrua: false,

      })
    }
  }


  async getData() {
    this.setState({
      showloader: true,
      showtable: false,
      showwaitintext: false
    });
    var getthis = this;
    let searchData = {
      "weather_parms": this.state.parameter,
      "from_date": this.state.fromDate,
      "to_date": this.state.toDate,
      "source": this.state.source,
      "sub_source": this.state.subsource
    }

    // console.log("weather_parms", this.state.parameter,
    //   "from_date", this.state.fromDate,
    //   "to_date", this.state.toDate,
    //   "source", this.state.source,
    //   "sub_source", this.state.subsource)
    searchData = JSON.stringify(searchData);
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + localStorage.getItem('token')
      },
      body: searchData

    };
    var weatherResponse = await fetch(process.env.REACT_APP_APIEND + `weather/daily-parameter-report`, requestOptions).then(res => {
      return res.json();
    });
    getthis.makecsvdata(weatherResponse.result);
    this.generatetabledata(weatherResponse.result);
    // this.setState({
    //   data: weatherResponse.result,
    //   showtable: true,
    //   showloader: false,
    // });

  }
  generatetabledata(data) {
    let getthis = this;
    let tableData = [];
    data.map(function (item, index, data) {
      var newdata = {
        source: item.source,
        date: moment(item.date).format("DD-MM-YYYY"),
        location: item.location,
        min: parseFloat(item.min),
        max: parseFloat(item.max),
        avg: parseFloat(item.avg),
        agg: getthis.checkagg(parseFloat(item.agg))
      };
      tableData.push(newdata);
    });

    this.setState({
      data: tableData,
      showtable: true,
      showloader: false,
    });
  }
  checkagg(agg) {
    if (this.state.parameter == "rainfall") {
      return (agg)
    } else {

      return ("N/A")

    }

  }

  disabledDate(current) {
    // Can not select days before today and today
    return current && current > moment().endOf('day');
  }
  makecsvdata(data) {
    let csvData = [['Source', 'Date', 'Parameter', 'Minimum', 'Maximum', 'Average', 'Aggregate']]
    data.map(wdata => {
      csvData.push([wdata.source, wdata.date, wdata.parameter, wdata.min, wdata.max, wdata.avg, wdata.agg]);
    });
    this.setState({
      csvdownloadData: csvData
    })
  }
  componentDidMount() {
    validate_Token();
  }
  render() {
    const { t, i18n } = this.props;
    let data = {
      columns: [
        {
          label: "Source",
          field: "source",
          sort: "asc",
          width: 150
        },
        {
          label: "Date",
          field: "date",
          sort: "asc",
          width: 150,
          sort: "disabled"
        },
        // {
        //   label: "Parameter",
        //   field: "parameter",
        //   sort: "asc",
        //   width: 150
        // },
        {
          label: "Minimum",
          field: "min",
          sort: "asc",
          width: 150
        },
        {
          label: "Maximum",
          field: "max",
          sort: "asc",
          width: 150
        },
        {
          label: "Average",
          field: "avg",
          sort: "asc",
          width: 150
        },
        {
          label: "Aggregate",
          field: "agg",
          sort: "asc",
        }
      ],
      rows: this.state.data
    };
    return (
      <React.Fragment>
        <div className="page-content">
          <Card style={{ backgroundColor: "#e5ebee" }}>
            <CardHeader style={{ backgroundColor: "#A4C1D2" }}>
              <Row>
                <Col>
                  <h4
                    className="card-title"
                    style={{ padding: "5px", fontSize: "18px" }}
                  >
                    {" "}
                    {t("Daily report parameter wise")}
                  </h4>
                </Col>
                <Col>
                  <Link to="report">
                    <i
                      className="ri-close-fill"
                      style={{ fontSize: "20px", float: "right" }}
                    ></i>
                  </Link>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Container fluid>
                <Row>
                  <Col xs={12}></Col>
                </Row>
                <Row>
                  <Col xl={12}>
                    <Card style={{ backgroundColor: '#f1f6fb' }}>
                      <CardBody>
                        <Col>
                          <Row>
                            <Col md="4">
                              <div className="col-md-12 ">
                                <Label className="col-md-12 col-form-label" style={{ fontSize: '14px' }}>{t("Date range")} </Label>
                                <RangePicker name="range"
                                  onChange={this.onChangedate}
                                  // defaultValue={[moment('2021-05-26', dateFormat)]}
                                  disabledDate={this.disabledDate}
                                  format="DD-MM-YYYY"
                                />
                              </div>
                              <center style={this.state.showbutton ? { display: 'none' } : {}} >
                                <p style={{ color: "red", fontSize: '10px' }}>
                                  {t("Please select a date range between 30 days")}
                                </p>
                              </center>

                            </Col>
                            <Col md="2">
                              <FormGroup>
                                <Label className="col-md-12 col-form-label" style={{ fontSize: '14px' }}>{t("Parameter")} </Label>
                                <select
                                  className="form-select"
                                  md="6"
                                  style={{ fontSize: "11px" }}
                                  name="location"
                                  required
                                  onChange={this.handleParameterchange}
                                >

                                  <option value="temperature">Temperature [°C]</option>
                                  <option value="rainfall">Rainfall [mm]</option>
                                  <option value="wind_speed">Wind Speed [m/s]</option>
                                  <option value="wind_direction">Wind Direction [°]</option>
                                  <option value="uv_index">UVI</option>
                                  <option value="humidity">Relative Humidity [%]</option>
                                  <option value="pressure">Pressure [hPa]</option>
                                </select>
                              </FormGroup>
                            </Col>

                            <Col md="2">
                              <FormGroup>
                                <Label className="col-md-12 col-form-label" style={{ fontSize: '14px' }}>{t("Source")} </Label>
                                <select
                                  className="form-select"
                                  md="6"
                                  style={{ fontSize: "11px" }}
                                  name="source"
                                  required
                                  onChange={this.handleSourcechange}
                                >

                                  <option value="weather_stations">Weather Station</option>
                                  <option value="rua">RUA</option>
                                  <option value="group">Group</option>
                                </select>
                              </FormGroup>
                            </Col>


                            <Col md="2">
                              <div style={this.state.subws ? {} : { display: 'none' }}>
                                <FormGroup>
                                  <Label className="col-md-12 col-form-label" style={{ fontSize: '14px' }}>{t("Station")} </Label>
                                  <select
                                    className="form-select"
                                    md="6"
                                    style={{ fontSize: "11px" }}
                                    name="stationdrop"
                                    required
                                    onChange={this.handlesubSourcechange}
                                  >

                                    <option value="all" selected>All </option>
                                    <option value="kdisc_sci_aws_01">Chittur-Thathamangalam (Muncipal Corporation Office)
                                    </option>
                                    <option value="kdisc_sci_aws_10">Eruthenpathy (Seed Godown)</option>
                                    <option value="kdisc_sci_aws_04" >Eruthenpathy (Bhagavathy Govt. High School)</option>
                                    <option value="kdisc_sci_aws_08" >Kozhinjampara (Bharathamatha Arts and Science
                                      College)</option>
                                    <option value="kdisc_sci_aws_12" >Kozhinjampara (KWA Water treatment Plant)</option>
                                    <option value="kdisc_sci_aws_03">Nallepilly (Krishi Bhavan)</option>

                                    <option value="kdisc_sci_aws_07">Nallepilly (Government Technical High School)
                                    </option>
                                    <option value="kdisc_sci_aws_06">Pattanchery (Panchayat Cremation Ground)</option>
                                    <option value="kdisc_sci_aws_02">Pattanchery (GSM High School,Thattamangalam)
                                    </option>
                                    <option value="kdisc_sci_aws_05" >Perumatty (Moolathara Regulator)</option>
                                    <option value="kdisc_sci_aws_14">Perumatty (Panchayat Office)</option>
                                    <option value="kdisc_sci_aws_11">Perumatty (Higher Secondary School)</option>
                                    <option value="kdisc_sci_aws_13">Vadakarapathy (Kinarpallam Milk Society)</option>
                                    <option value="kdisc_sci_aws_09">Vadakarapathy (Ahalia campus)</option>
                                  </select>

                                </FormGroup>
                              </div>




                              <div style={this.state.subgroup ? {} : { display: 'none' }}>
                                <FormGroup>
                                  <Label className="col-md-12 col-form-label" style={{ fontSize: '14px' }}>Group </Label>
                                  <select
                                    className="form-select"
                                    md="6"
                                    style={{ fontSize: "11px" }}
                                    name="groupdrop"
                                    required
                                    onChange={this.handlesubSourcechange}
                                  >
                                    <option value="all" selected>All </option>
                                    <option value="Group1" >Group 1</option>
                                    <option value="Group2" >Group 2 </option>
                                  </select>
                                </FormGroup>
                              </div>



                              <div style={this.state.subrua ? {} : { display: 'none' }}>
                                <FormGroup>
                                  <Label className="col-md-12 col-form-label" style={{ fontSize: '14px' }}>{t("RUA")} </Label>
                                  <select
                                    className="form-select"
                                    md="6"
                                    style={{ fontSize: "11px" }}
                                    name="ruadrop"
                                    required
                                    onChange={this.handlesubSourcechange}
                                  >
                                    <option value="all" selected>All </option>
                                    <option value="Chittur" >Chittur-Thathamangalam</option>
                                    <option value="Eruthenpathy" >Eruthenpathy </option>
                                    <option value="Kozhinjampara">Kozhinjampara</option>
                                    <option value="Nallepilly">Nallepilly</option>
                                    <option value="Pattanchery">Pattanchery</option>
                                    <option value="Perumatty">Perumatty</option>
                                    <option value="Vadakarapathy">Vadakarapathy</option>
                                  </select>
                                </FormGroup>
                              </div>
                            </Col>

                            <Col md="2">
                              <FormGroup style={this.state.showbutton ? {} : { display: 'none' }}>
                                <Label
                                  className="col-md-12 col-form-label"
                                  style={{ fontSize: "14px" }}
                                >
                                  <span>&nbsp;</span>
                                </Label>
                                <Button
                                  color="primary"
                                  className="waves-effect waves-light mr-1"
                                  style={{ fontSize: "11px", float: "right" }}
                                  onClick={this.getData}
                                >
                                  {t("Search")}
                                </Button>
                              </FormGroup>
                            </Col>

                          </Row>
                          <Row>
                            <Col></Col>
                          </Row>
                        </Col>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col xl={12}>
                    <Card>
                      <CardBody>
                        <Row>
                          <div style={this.state.showwaitintext ? {} : { display: 'none' }}><center>{t("Waiting for search")}</center></div>
                          <div style={this.state.showloader ? {} : { display: 'none' }}>
                            <center>
                              <Spinner color="dark" />
                            </center>
                          </div>
                          <Col xs={12}>
                            <div style={this.state.showtable ? {} : { display: 'none' }}>
                              <div class="text-right">
                                <CSVLink filename="weather_datadaily_onlinesensor.csv" data={this.state.csvdownloadData} >Download Report</CSVLink>

                              </div>
                              <div id="dailydata" style={{ fontSize: "12px" }}>
                                <MDBDataTable scrollX maxHeight="300px" paging={true} bordered data={data} className='datadownloadtable alnright' />
                              </div>
                            </div>
                          </Col>
                        </Row>

                      </CardBody>
                    </Card>

                  </Col>
                </Row>
              </Container>
            </CardBody>
          </Card>
        </div>
      </React.Fragment>
    )
  }
}
export default withNamespaces()(daily_Parameter);
