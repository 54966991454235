import React, { Component } from "react";

import { connect } from "react-redux";
import {
  Row,
  Col,
  Form,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  Input,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  Modal,
  ButtonGroup,
  ButtonToolbar,
  ModalHeader,
  ModalBody,
  Table,
} from "reactstrap";

import { Link } from "react-router-dom";
import Permission from "../../permission.js";

// Import menuDropdown
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown";
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";
import alert from "../../assets/images/alert.png";
//Import i18n
import { withNamespaces } from "react-i18next";

//Import Megamenu
import MegaMenu from "./MegaMenu";

// Redux Store
// import { toggleRightSidebar } from "../../store/actions";
import { message, Switch } from "antd";
//Import logo Images
// import logosmdark from "../../assets/images/logo-sm-dark.png";
// import logodark from "../../assets/images/logo-dark.png";
import logosmlight from "../../assets/images/misteo.png";
import logolight from "../../assets/images/misteo-lg-logo.png";
import Moment from "moment";
import Malayalammessages from "./alertMessages/Mmessages";
import Englishmessages from "./alertMessages/Emessages";
let user_role = Permission();
class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      isSearch: false,
      isSocialPf: false,
      modal_large: false,
      alert: [],
      showtable: false,
      showmessage: true,
      userFM: false,
      isML: false,
      active_week: false,
      active_month: false,
      active: true,
      fromDate: "",
      toDate: "",
      show: false,
    };
    this.toggleMenu = this.toggleMenu.bind(this);
    // this.toggleRightbar = this.toggleRightbar.bind(this);
    this.toggleFullscreen = this.toggleFullscreen.bind(this);
    this.tog_large = this.tog_large.bind(this);
    this.ChangeLN = this.ChangeLN.bind(this);
  }
  /**
   * Toggle sidebar
   */
  toggleMenu() {
    // console.log("Cliking!");
    this.props.toggleMenuCallback();
  }
  /**
   * Toggle modal
   */
  tog_large() {
    this.setState((prevState) => ({
      modal_large: !prevState.modal_large,
    }));
  }
  show() {
    this.setState({ visible: true });
  }
  hide() {
    this.setState({ visible: false });
  }
  /**
   * Toggles the sidebar
   */
  // toggleRightbar() {
  //   this.props.toggleRightSidebar();
  // }
  toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }
  async getalert(user) {
    let bodydata = {};
    this.setState({ show: false });
    if (localStorage.getItem("user_type") == "KB") {
      bodydata = {
        crop: "all",
        rua: localStorage.getItem("krishibhavan") === "Chittur-Thathamangalam" ? "Chittur" : localStorage.getItem("krishibhavan"),
        season: "all",
        from_date: this.state.fromDate,
        to_date: this.state.toDate,
        alert_type: "all",
        peril: "all",
      };
      bodydata = JSON.stringify(bodydata);
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + localStorage.getItem("token"),
        },
        body: bodydata,
      };
      try {
        var alertresponse = await fetch(
          process.env.REACT_APP_APIEND + `smart_crop/alert-notification`,
          requestOptions
        ).then((res) => {
          return res.json();
        });
        setTimeout(() => {
          this.setState({ show: true });
        }, 5000);
        if (alertresponse.data.length > 0) {
          // console.log("TRUE ALERT");
          this.setState({
            alert: alertresponse.data,
            showmessage: false,
            showtable: true,
          });
        } else {
          // console.log("NO DATA ALERT");
          this.setState({
            noalert: true,
            showmessage: true,
            showtable: false,
          });
        }
      } catch (err) {
        // console.log("SOMETHING HAPPENED");
      }
    } else if (localStorage.getItem("user_type") == "FM") {
      bodydata = {
        farmer_id: localStorage.getItem("farmer_id"),
        season: "all",
        from_date: this.state.fromDate,
        to_date: this.state.toDate,
        crop: "all",
        rua: localStorage.getItem("krishibhavan") === "Chittur-Thathamangalam" ? "Chittur" : localStorage.getItem("krishibhavan"),
        alert_type: "all",
        peril: "all",
      };
      bodydata = JSON.stringify(bodydata);
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
        body: bodydata,
      };
      try {
        var alertresponse = await fetch(
          process.env.REACT_APP_APIEND + `farmer/alert-notification-farmer`,
          requestOptions
        ).then((res) => {
          return res.json();
        });
        setTimeout(() => {
          this.setState({ show: true });
        }, 5000);
        if (alertresponse.data.length > 0) {
          // console.log("TRUE ALERT");
          this.setState({
            alert: alertresponse.data,
            showmessage: false,
            showtable: true,
          });
        } else {
          // console.log("NO DATA ALERT");
          this.setState({
            noalert: true,
            showmessage: true,
            showtable: false,
          });
        }
      } catch (err) {
        // console.log("SOMETHING HAPPENED");
      }
    } else if (user == "ALL") {
      bodydata = {
        crop: "all",
        rua: "all",
        season: "all",
        from_date: this.state.fromDate,
        to_date: this.state.toDate,
        peril: "all",
        alert_type: "all",
      };
      bodydata = JSON.stringify(bodydata);
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + localStorage.getItem("token"),
        },
        body: bodydata,
      };
      try {
        var alertresponse = await fetch(
          process.env.REACT_APP_APIEND + `smart_crop/alert-notification`,
          requestOptions
        ).then((res) => {
          return res.json();
        });
        setTimeout(() => {
          this.setState({ show: true });
        }, 5000);
        if (alertresponse.data.length > 0) {
          // console.log("TRUE ALERT");
          this.setState({
            alert: alertresponse.data,
            showmessage: false,
            showtable: true,
          });
        } else {
          // console.log("NO DATA ALERT");
          this.setState({
            noalert: true,
            showmessage: true,
            showtable: false,
          });
        }
      } catch (err) {
        // console.log("SOMETHING HAPPENED");
      }
    }
  }
  componentDidMount() {
    var to_date = new Date();
    var to_dd = String(to_date.getDate()).padStart(2, "0");
    var to_mm = String(to_date.getMonth() + 1).padStart(2, "0"); //January is 0!
    var to_yyyy = to_date.getFullYear();
    var to_date1 = to_yyyy + "-" + to_mm + "-" + to_dd;

    var from_date = new Date();
    from_date.setDate(from_date.getDate() - 1);
    var from_dd = String(from_date.getDate()).padStart(2, "0");
    var from_mm = String(from_date.getMonth() + 1).padStart(2, "0"); //January is 0!
    var from_yyyy = from_date.getFullYear();
    var from_date1 = from_yyyy + "-" + from_mm + "-" + from_dd;
    setTimeout(() => {
    if (user_role[0] == "KB") {
      this.setState(
        {
          fromDate: to_date1,
          toDate: to_date1,
        },
        () => {
          this.getalert("KB");
        }
      );
    } else if (user_role[0] == "FM") {
      this.setState(
        {
          fromDate: to_date1,
          toDate: to_date1,
        },
        () => {
          this.getalert("FM");
          this.setState({
            userFM: true,
          });
        }
      );
    } else {
      this.setState(
        {
          fromDate: to_date1,
          toDate: to_date1,
        },
        () => {
          this.getalert("ALL");
        }
      );
    }
  }, 500);
  }
  getmessage(alert) {
    if (this.state.userFM == true) {
      if (alert.alert_type == "Payout Alert") {
        if (this.state.isML == true) {
          return "ഒരു ഹെക്ടറിന് നിങ്ങളുടെ പേഔട്ട് " + alert.payout + " ആണ്.";
        } else {
          return "Your eligible per hectare payout is " + alert.payout;
        }
      } else if (alert.alert_type == "Day End") {
        return Englishmessages(alert);
      }
    } else {
      if (alert.alert_type == "Payout Alert") {
        if (this.state.isML == true) {
          return "ഒരു ഹെക്ടറിന് നിങ്ങളുടെ പേഔട്ട് " + alert.payout + " ആണ്.";
        } else {
          return "Your eligible per hectare payout is " + alert.payout;
        }
      } else if (alert.alert_type == "Day End") {
        return Englishmessages(alert);
      }
    }
  }
  getmalayalamrua(rua) {
    if (rua == "Nallepilly") {
      return "നല്ലേപ്പിള്ളി";
    }
    if (rua == "Chittur") {
      return "ചിറ്റൂർ";
    }
    if (rua == "Pattanchery") {
      return "പട്ടഞ്ചേരി";
    }
    if (rua == "Eruthenpathy") {
      return "എരുത്തേൻപതി";
    }
    if (rua == "Perumatty") {
      return "പെരുമാട്ടി";
    }
    if (rua == "Kozhinjampara") {
      return "കൊഴിഞ്ഞാമ്പാറ";
    }
    if (rua == "Vadakarapathy") {
      return "വടകരപതി";
    }
  }
  getmalayalamseason(season) {
    if (season == "Kharif 2020") {
      return "ഖാരിഫ് 2020";
    }
    if (season == "Kharif 2021") {
      return "ഖാരിഫ് 2021";
    }
    if (season == "Kharif 2022") {
      return "ഖാരിഫ് 2022";
    }
    if (season == "Kharif 2023") {
      return "ഖാരിഫ് 2023";
    }
    if (season == "Rabi 2020-21") {
      return "റാബി 2020-21";
    }
    if (season == "Rabi 2021-22") {
      return "റാബി 2021-22";
    }
    if (season == "Rabi 2022-23") {
      return "റാബി 2022-23";
    }
    if (season == "Rabi 2023-24") {
      return "റാബി 2023-24";
    }
  }
  getmalayalamcrop(crop) {
    if (crop == "Veg Group I") {
      return "വെജ് ഗ്രൂപ്പ് ഐ";
    }
    if (crop == "Paddy") {
      return "നെല്ല്";
    }
    if (crop == "Paddy 3rd Crop") {
      return "നെല്ല് മൂന്നാം വിള";
    }
    if (crop == "Paddy Winter") {
      return "നെല്ല് ശീതകാലം";
    }
    if (crop == "Veg Group I - SMR") {
      return "വെജ് ഗ്രൂപ്പ് I - എസ്.എം.ആർ";
    }
    if (crop == "Mango") {
      return "മാമ്പഴം";
    }
    if (crop == "Paddy 2nd Crop") {
      return "നെല്ല് രണ്ടാം വിള";
    }
  }
  getmalayalamtype(type) {
    if (type == "Day End") {
      return "ദിവസം അവസാനം";
    }
    if (type == "Payout Alert") {
      return "പേഔട്ട് അലേർട്ട്";
    }
  }
  getmalayalamperil(peril) {
    if (peril == "Disease Congenial Climate") {
      return "രോഗം അനുകൂലമായ കാലാവസ്ഥ";
    }
    if (peril == "Dry Spell") {
      return "ഡ്രൈ സ്പെൽ";
    }
    if (peril == "Deficit Rainfall") {
      return "കമ്മി മഴ";
    }
    if (peril == "High Temperature") {
      return "ഉയർന്ന താപനില";
    }
    if (peril == "Unseasonal Rainfall") {
      return "കാലാനുസൃതമല്ലാത്ത മഴ";
    }
    if (peril == "Excess Rainfall") {
      return "അധിക മഴ";
    }
  }
  ChangeLN(e) {
    if (e == true) {
      this.setState({
        isML: true,
      });
    }
    if (e == false) {
      this.setState({
        isML: false,
      });
    }
  }
  getmalayalammessage(alert) {
    if (alert.alert_type == "Payout Alert") {
      if (this.state.isML == true) {
        return "ഒരു ഹെക്ടറിന് നിങ്ങളുടെ പേഔട്ട് " + alert.payout + " ആണ്.";
      } else {
        return "Your eligible per hectare payout is " + alert.payout;
      }
    } else if (alert.alert_type == "Day End") {
      return Malayalammessages(alert);
    }
  }
  datechange(daterange) {
    if (daterange == "one") {
      this.setState({
        active: true,
        active_week: false,
        active_month: false,
      });
      var to_date = new Date();

      var to_dd = String(to_date.getDate()).padStart(2, "0");
      var to_mm = String(to_date.getMonth() + 1).padStart(2, "0"); //January is 0!
      var to_yyyy = to_date.getFullYear();

      var to_date1 = to_yyyy + "-" + to_mm + "-" + to_dd;

      this.setState(
        {
          fromDate: to_date1,
          toDate: to_date1,
        },
        () => {
          if (user_role[0] == "KB") {
            this.getalert("KB");
          } else if (user_role[0] == "FM") {
            this.getalert("FM");
          } else {
            this.getalert("ALL");
          }
        }
      );
    }
    if (daterange == "week") {
      this.setState({
        active_week: true,
        active: false,
        active_month: false,
      });
      var to_date = new Date();
      var to_dd = String(to_date.getDate()).padStart(2, "0");
      var to_mm = String(to_date.getMonth() + 1).padStart(2, "0"); //January is 0!
      var to_yyyy = to_date.getFullYear();
      var to_date1 = to_yyyy + "-" + to_mm + "-" + to_dd;

      var from_date = new Date();
      from_date.setDate(from_date.getDate() - 7);
      var from_dd = String(from_date.getDate()).padStart(2, "0");
      var from_mm = String(from_date.getMonth() + 1).padStart(2, "0"); //January is 0!
      var from_yyyy = from_date.getFullYear();
      var from_date1 = from_yyyy + "-" + from_mm + "-" + from_dd;

      this.setState(
        {
          fromDate: from_date1,
          toDate: to_date1,
        },
        () => {
          if (user_role[0] == "KB") {
            this.getalert("KB");
          } else if (user_role[0] == "FM") {
            this.getalert("FM");
          } else {
            this.getalert("ALL");
          }
        }
      );
    }
    if (daterange == "month") {
      this.setState({
        active_month: true,
        active_week: false,
        active: false,
      });
      var to_date = new Date();
      var to_dd = String(to_date.getDate()).padStart(2, "0");
      var to_mm = String(to_date.getMonth() + 1).padStart(2, "0"); //January is 0!
      var to_yyyy = to_date.getFullYear();
      var to_date1 = to_yyyy + "-" + to_mm + "-" + to_dd;

      var from_date = new Date();
      from_date.setDate(from_date.getDate() - 30);
      var from_dd = String(from_date.getDate()).padStart(2, "0");
      var from_mm = String(from_date.getMonth() + 1).padStart(2, "0"); //January is 0!
      var from_yyyy = from_date.getFullYear();
      var from_date1 = from_yyyy + "-" + from_mm + "-" + from_dd;

      this.setState(
        {
          // fromDate: "2021-02-01",
          fromDate: from_date1,
          toDate: to_date1,
        },
        () => {
          if (user_role[0] == "KB") {
            this.getalert("KB");
          } else if (user_role[0] == "FM") {
            this.getalert("FM");
          } else {
            this.getalert("ALL");
          }
        }
      );
    }
  }
  checkType(type) {
    // console.log("ALERT TYPE IN FN", type);
    if (type == "A2") {
      return "Day End";
    }
    if (type == "A3") {
      return "Payout Alert";
    }
  }
  render() {
    const { t, i18n } = this.props;
    return (
      <React.Fragment>
        <header id="page-topbar">
          <div className="navbar-header">
            <div className="d-flex">
              <div className="navbar-brand-box">
                <Link to="#" className="logo logo-light">
                  <span className="logo-sm">
                    <img src={logosmlight} alt="" height="26" />
                  </span>
                  <span className="logo-lg">
                    <img src={logolight} alt="" height="20" />
                  </span>
                </Link>
              </div>

              <Button
                size="sm"
                color="none"
                type="button"
                onClick={this.toggleMenu}
                className="px-3 font-size-24 header-item waves-effect"
                id="vertical-menu-btn"
              >
                <i className="ri-menu-2-line align-middle"></i>
              </Button>
            </div>

            <div className="d-flex">
              <div className="dropdown d-lg-inline-block ml-1">
                <Button
                  color="none"
                  type="button"
                  className="header-item noti-icon waves-effect"
                  onClick={this.tog_large}
                >
                  <i className="ri-notification-3-line"></i>
                </Button>
              </div>
              <LanguageDropdown />
              <div className="dropdown d-none d-lg-inline-block ml-1">
                <Button
                  color="none"
                  type="button"
                  className="header-item noti-icon waves-effect"
                  onClick={this.toggleFullscreen}
                >
                  <i className="ri-fullscreen-line"></i>
                </Button>
              </div>
              <ProfileMenu />
            </div>
          </div>
        </header>
        <Modal
          size="xl"
          isOpen={this.state.modal_large}
          toggle={this.tog_large}
          backdrop="static"
        >
          <ModalHeader
            toggle={() => this.setState({ modal_large: false })}
            style={{ backgroundColor: "rgb(164, 193, 210)" }}
          >
            {t("Recent Alerts")}
          </ModalHeader>
          <div>
            <div style={{ marginLeft: "10px" }}>
              <ButtonToolbar>
                <ButtonGroup size="sm" className="mt-2">
                  <Button
                    color="light"
                    active={this.state.active}
                    onClick={() => this.datechange("one")}
                  >
                    Today
                  </Button>
                  <Button
                    color="light"
                    active={this.state.active_week}
                    onClick={() => this.datechange("week")}
                  >
                    Last 7 days
                  </Button>
                  <Button
                    color="light"
                    active={this.state.active_month}
                    onClick={() => this.datechange("month")}
                  >
                    Last 30 days
                  </Button>
                </ButtonGroup>
              </ButtonToolbar>
            </div>
            <Switch
              checkedChildren="ML"
              unCheckedChildren="EN"
              className="language-toggle"
              onChange={this.ChangeLN}
            />
          </div>
          <ModalBody>
            <div
              style={this.state.showtable ? {} : { display: "none" }}
              className="alert-table-section"
            >
              <Table
                id="alerts"
                bordered
                style={{ fontSize: "11px", height: "300px" }}
                className="alert-table"
              >
                <thead style={{ backgroundColor: "#ffffff" }}>
                  <tr>
                    <th style={{ width: "60px" }}>Date</th>
                    <th style={{ width: "60px" }}>Rua</th>
                    <th style={{ width: "60px" }}>Season</th>
                    <th style={{ width: "60px" }}>Crop</th>
                    <th style={{ width: "60px" }}>Phase</th>
                    <th style={{ width: "60px" }}>Alert Type</th>
                    <th style={{ width: "130px" }}>Peril</th>
                    <th style={{ width: "130px" }}>Message</th>
                  </tr>
                </thead>
                <tbody style={this.state.isML ? {} : { display: "none" }}>
                  {this.state.alert
                    .slice()
                    .reverse()
                    .map((alerts) => {
                      return (
                        <tr style={{ overflowX: "auto" }}>
                          <td>
                            {Moment(alerts.dateTime).format("DD-MM-YYYY")}
                          </td>
                          <td>{this.getmalayalamrua(alerts.rua)}</td>
                          <td>{this.getmalayalamseason(alerts.season)}</td>
                          <td>{this.getmalayalamcrop(alerts.crop)}</td>
                          <td>{alerts.phase}</td>
                          <td>{this.getmalayalamtype(alerts.alert_type)}</td>
                          <td>{this.getmalayalamperil(alerts.peril)}</td>
                          <td>{this.getmalayalammessage(alerts)}</td>
                        </tr>
                      );
                    })}
                </tbody>
                <tbody style={this.state.isML ? { display: "none" } : {}}>
                  {this.state.alert
                    .slice()
                    .reverse()
                    .map((alerts) => {
                      return (
                        <tr style={{ overflowX: "auto" }}>
                          <td>
                            {Moment(alerts.dateTime).format("DD-MM-YYYY")}
                          </td>
                          <td>{alerts.rua}</td>
                          <td>{alerts.season}</td>
                          <td>{alerts.crop}</td>
                          <td>{alerts.phase}</td>
                          <td>{alerts.alert_type}</td>
                          <td>{alerts.peril}</td>
                          <td>{this.getmessage(alerts)}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </div>
            <div style={this.state.showmessage ? {} : { display: "none" }}>
              <center>
                <img src={alert} height="150" alt="alert" />
                <p>{t("No Recent Alerts")}</p>
              </center>
            </div>
            <div
              style={
                localStorage.getItem("user_type") == "FM"
                  ? { display: "none" }
                  : this.state.show
                  ? {}
                  : { display: "none" }
              }
            >
              <Link to="/alert" style={{ float: "right", fontSize: "13px" }}>
                Historical alerts
              </Link>
            </div>

            <div
              style={
                localStorage.getItem("user_type") == "FM"
                  ? this.state.show
                    ? {}
                    : { display: "none" }
                  : { display: "none" }
              }
            >
              <Link
                to="/fm_alerts"
                style={{ float: "right", fontSize: "13px" }}
              >
                Historical alerts
              </Link>
            </div>
          </ModalBody>
        </Modal>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { layoutType } = state.Layout;
  return { layoutType };
};

export default connect(mapStatetoProps, {  })(
  withNamespaces()(Header)
);
