import React, { useState, useEffect } from "react";
import KH2020PADDY from "../../assets/pdf/KH2020Paddy.pdf";
import KH2021PADDY from "../../assets/pdf/KH2021Paddy.pdf";
import KH2022PADDY from "../../assets/pdf/KH2022Paddy.pdf";
import KH2020VEG from "../../assets/pdf/KH2020Veg.pdf";
import KH2021VEG from "../../assets/pdf/KH2021Veg.pdf";
import KH2022VEG from "../../assets/pdf/KH2022Veg.pdf";
import RB2020PADDY3 from "../../assets/pdf/RB2020PADDY3.pdf";
import RB2021PADDY3 from "../../assets/pdf/RB2021PADDY3.pdf";
import RB2022PADDY3 from "../../assets/pdf/RB2022PADDY3.pdf";
import RB2020MANGO from "../../assets/pdf/RB2020MANGO.pdf";
import RB2021MANGO from "../../assets/pdf/RB2021MANGO.pdf";
import RB2022MANGO from "../../assets/pdf/RB2022MANGO.pdf";
import RB2020PADDY2 from "../../assets/pdf/RB2020PADDY2.pdf";
import RB2021PADDY2 from "../../assets/pdf/RB2021PADDY2.pdf";
import RB2022PADDY2 from "../../assets/pdf/RB2022PADDY2.pdf";
import RB2020VEG from "../../assets/pdf/RB2020Veg.pdf";
import RB2021VEG from "../../assets/pdf/RB2021Veg.pdf";
import RB2022VEG from "../../assets/pdf/RB2022Veg.pdf";
import RB2020VEGSMR from "../../assets/pdf/RB2020VEGSMR.pdf";
import RB2021VEGSMR from "../../assets/pdf/RB2021VEGSMR.pdf";
import RB2022VEGSMR from "../../assets/pdf/RB2022VEGSMR.pdf";
import RB2020PADDYWINTER from "../../assets/pdf/RB2020PADDYWINTER.pdf";
import RB2021PADDYWINTER from "../../assets/pdf/RB2021PADDYWINTER.pdf";
import RB2022PADDYWINTER from "../../assets/pdf/RB2022PADDYWINTER.pdf";
// Import styles
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import {
  Position,
  Tooltip,
  Viewer,
  Worker,
  PrimaryButton,
} from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import {
  Table,
  Card,
  CardBody,
  Row,
  Col,
  Container,
  FormGroup,
  Label,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";
let file = KH2020PADDY;
export default function TermsheetPDF(props) {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  // console.log("PDF PROPS", props);
  function searchFile() {
    var crop = props.data.crop;
    var season = props.data.season;
    // console.log("SEASON ", season);
    if (crop == "Paddy" && season == "Kharif 2020") {
      file = KH2020PADDY;
    } else if (crop == "Paddy" && season == "Kharif 2021") {
      file = KH2021PADDY;
    } else if (crop == "Paddy" && season == "Kharif 2022") {
      file = KH2022PADDY;
    } else if (crop == "Veg Group I" && season == "Kharif 2020") {
      file = KH2020VEG;
    } else if (crop == "Veg Group I" && season == "Kharif 2021") {
      file = KH2021VEG;
    } else if (crop == "Veg Group I" && season == "Kharif 2022") {
      file = KH2022VEG;
    } else if (crop == "Paddy 3rd Crop" && season == "Rabi 2020-21") {
      file = RB2020PADDY3;
    } else if (crop == "Paddy 3rd Crop" && season == "Rabi 2021-22") {
      file = RB2021PADDY3;
    } else if (crop == "Paddy 3rd Crop" && season == "Rabi 2022-23") {
      file = RB2022PADDY3;
    } else if (crop == "Mango" && season == "Rabi 2020-21") {
      file = RB2020MANGO;
    } else if (crop == "Mango" && season == "Rabi 2021-22") {
      file = RB2021MANGO;
    } else if (crop == "Mango" && season == "Rabi 2022-23") {
      file = RB2022MANGO;
    } else if (crop == "Paddy 2nd Crop" && season == "Rabi 2020-21") {
      file = RB2020PADDY2;
    } else if (crop == "Paddy 2nd Crop" && season == "Rabi 2021-22") {
      file = RB2021PADDY2;
    } else if (crop =="Paddy 2nd Crop" && season == "Rabi 2022-23") {
      file = RB2022PADDY2;
    } else if (crop == "Veg Group I" && season == "Rabi 2020-21") {
      file = RB2020VEG;
    } else if (crop == "Veg Group I" && season == "Rabi 2021-22") {
      file = RB2021VEG;
    } else if (crop == "Veg Group I" && season == "Rabi 2022-23") {
      file = RB2022VEG;
    } else if (crop == "Veg Group I - SMR" && season == "Rabi 2020-21") {
      file = RB2020VEGSMR;
    } else if (crop == "Veg Group I - SMR" && season == "Rabi 2021-22") {
      file = RB2021VEGSMR;
    } else if (crop == "Veg Group I - SMR" && season == "Rabi 2022-23") {
      file = RB2022VEGSMR;
    } else if (crop == "Paddy Winter" && season == "Rabi 2020-21") {
      file = RB2020PADDYWINTER;
    } else if (crop == "Paddy Winter" && season == "Rabi 2021-22") {
      file = RB2021PADDYWINTER;
    } else if (crop == "Paddy Winter" && season == "Rabi 2022-23") {
      file = RB2022PADDYWINTER;
    } else {
      file = [];
    }
  }
  searchFile(() => {
    searchFile();
    props.onRef(undefined);
  }, [props.data.tablecrop, props.data.tableseason]);
  return (
    <div>
      {" "}
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.1.266/build/pdf.worker.min.js">
        <div
          style={{
            height: "550px",
            border: "1px solid #919191",
          }}
        >
          <Viewer
            initialPage={3}
            fileUrl={file}
            plugins={[defaultLayoutPluginInstance]}
          />
        </div>
      </Worker>
    </div>
  );
}
