import React, { Component } from "react";
// Table from react-bootstrap
import {
  Table,
  Card,
  CardBody,
  Row,
  Col,
  Container,
  FormGroup,
  Label,
  Input,
  Button,
  CardText,
  Spinner,
} from "reactstrap";
import { MDBDataTable } from "mdbreact";
import "../Adm-components/css/tsimplications.css";
import Permission from "../../permission.js";
import { withNamespaces } from 'react-i18next';
import validate_Token from "../../validateToken";
class TermsheetTable extends Component {
  constructor(props) {
    // let querydata=props.tabledata;
    super(props);
    this.state = {
      rowtabledata: [],
      rua: "Nallepilly",
      season: "all",
      dp: "all",
      crop: "all",
      showWaiting: true,
      showloader: false,
      seasonData: [],
      acre: 1,
      setKB1: true,
      setKB2: true,
      setKB3: true,
      setKB4: true,
      setKB5: true,
      setKB6: true,
      setKB7: true,
      allrua: true,
      selrua: "Nallepilly",
      cropData: [],
      cropDPkey:1,
      dpData: ["AIC", "Meteoblue", "NASA PowerLarc", "ECMWF"],
      perilseasonData:[],
    };
    this.fetchdata = this.fetchdata.bind(this);
    this.handleSeasonchange = this.handleSeasonchange.bind(this);
    this.handleLocationchange = this.handleLocationchange.bind(this);
    this.handledpchange = this.handledpchange.bind(this);
    this.handleAcreChange = this.handleAcreChange.bind(this);
    this.handlecropchange = this.handlecropchange.bind(this);
  }
  async fetchdata() {
    this.setState({
      showWaiting: false,
      showloader: true,
      showtable: false,
    });
    let searchdata = {
      rua: this.state.rua,
      season: this.state.season,
      // acreage: parseInt(this.state.acre),
      data_provider: this.state.dp,
      crop: this.state.crop,
    };
    searchdata = JSON.stringify(searchdata);

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + localStorage.getItem("token"),
      },
      body: searchdata,
    };
    var Summarydata = await fetch(
      process.env.REACT_APP_APIEND + `smart_crop/crop-season-payout`,
      requestOptions
    ).then((res) => {
      return res.json();
    });
    this.generatedata(Summarydata);
    this.setState({
      showloader: false,
      showtable: true,
    });
  }
  async fetchseason() {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + localStorage.getItem("token"),
      },
    };

    var seasonData = await fetch(
      process.env.REACT_APP_APIEND + `smart_crop/term-sheet-season-crop`,
      requestOptions
    ).then((res) => {
      return res.json();
    });
    this.setState({
      perilseasonData:seasonData.result
    })
    
    var newseasonData = [];
    var newcropdata = [];
    {
      seasonData.result.map(function (item, index, data) {
        newseasonData.push(Object.keys(item));
        var cropdata = item[Object.keys(item)];
        cropdata.map(function (crops, index, dt) {
          newcropdata.push(crops);
        });
        // cropdata.push(Object.keys(item)[item])
      });
    }
    this.setState({
      seasonData: newseasonData,
      CropData: newcropdata,
    });
  }

  checkVAL(val) {
    var acreage=this.state.acre
    if (isNaN(parseInt(val))) {
      return "N/A";
    }
    if (typeof val !== "undefined") {
      return parseFloat( val*acreage).toFixed(2);
    }
  }

  generatedata(data) {
    let getthis = this;
    let summarydata = [];

    data.result.map(function (item, index, data) {
      var response = Object.keys(item);
      var perils = item[response[1]];
      // console.log("PERILS", perils["Deficit Rainfall"]);
      var newdata = {
        season: item.season,
        crop: response[1],
        rua: item.rua,
        dp: item.data_provider,
        deficit_rainfall: getthis.checkVAL(
          parseFloat(perils["Deficit Rainfall"]).toFixed(2)
        ),
        "Excess Rainfall": getthis.checkVAL(
          parseFloat(perils["Excess Rainfall"]).toFixed(2)
        ),
        "Rise in Temperature": getthis.checkVAL(
          parseFloat(perils["Rise in Temperature"]).toFixed(2)
        ),
        "Disease Congenial Climate": getthis.checkVAL(
          parseFloat(perils["Disease Congenial Climate"]).toFixed(2)
        ),
        "High Temperature": getthis.checkVAL(
          parseFloat(perils["High Temperature"]).toFixed(2)
        ),
        "Dry Spell": getthis.checkVAL(
          parseFloat(perils["Dry Spell"]).toFixed(2)
        ),
        "Wind Speed": getthis.checkVAL(
          parseFloat(perils["Wind Speed"]).toFixed(2)
        ),
        "Wind Speed (A)": getthis.checkVAL(
          parseFloat(perils["Wind Speed (A)"]).toFixed(2)
        ),
        "Wind Speed (B)": getthis.checkVAL(
          parseFloat(perils["Wind Speed (B)"]).toFixed(2)
        ),
        "Rise in Night Temperature": getthis.checkVAL(
          parseFloat(perils["Rise in Night Temperature"]).toFixed(2)
        ),
        total: getthis.checkVAL(parseFloat(item.total).toFixed(2)),
      };
      // var newdata = {
      //   crop: <p className="alnleft">{Object.keys(item)}</p>,
      //   "Deficit Rainfall": getthis.checkVAL(
      //     parseFloat(item[crop]["Deficit Rainfall"]).toFixed(2)
      //   ),
      //   "Excess Rainfall": getthis.checkVAL(
      //     parseFloat(item[crop]["Excess Rainfall"]).toFixed(2)
      //   ),
      //   "Rise in Temperature": getthis.checkVAL(
      //     parseFloat(item[crop]["Rise in Temperature"]).toFixed(2)
      //   ),
      //   "Disease Congenial Climate": getthis.checkVAL(
      //     parseFloat(item[crop]["Disease Congenial Climate"]).toFixed(2)
      //   ),
      //   "High Temperature": getthis.checkVAL(
      //     parseFloat(item[crop]["High Temperature"]).toFixed(2)
      //   ),
      //   "Dry Spell": getthis.checkVAL(
      //     parseFloat(item[crop]["Dry Spell"]).toFixed(2)
      //   ),
      //   "Rise in Night Temperature": getthis.checkVAL(
      //     parseFloat(item[crop]["Rise in Night Temperature"]).toFixed(2)
      //   ),
      //   total: getthis.checkVAL(parseFloat(item[crop].total).toFixed(2)),
      // };
      summarydata.push(newdata);
    });
    this.setState({
      rowtabledata: summarydata,
    });
  }

  handleAcreChange(e) {
    this.setState({
      acre: e.target.value,
    });
  }

  handleSeasonchange(e) {
    this.setState({
      season: e.target.value,
    });
    this.setState({
      cropData:[]
    })
    if (e.target.value == "Kharif 2020") {
      this.setState({
        dpData: ["AIC", "Meteoblue", "NASA PowerLarc", "ECMWF"],
        // dp: "AIC",
      });
    } else if (e.target.value == "Rabi 2020-21") {
      this.setState({
        dpData: ["darksky"],
        // dp: "darksky",
      });
    } else {
      this.setState({
        dpData: ["Online Sensors"],
        // dp: "Online Sensors",
      })
    }
    let selected_index = e.target.selectedIndex;
    let season = e.target.value;
    let allcropdata = this.state.perilseasonData;
    if (selected_index == "0") {
      this.setState({
        cropData: [],
      });
      var list_array = [];
      allcropdata.map((index) => {
        list_array.push("---------" + Object.keys(index)[0] + "--------");
        // console.log("INDEX ", index[Object.keys(index)[0]]);
        var seasoncrop = index[Object.keys(index)[0]];
        seasoncrop.map((crop) => {
          list_array.push(crop);
        });
      });
      this.setState({
        cropData: list_array,
        cropDPkey:this.state.cropDPkey+1
      });
    } else {
      this.setState({
        cropData: [],
      });
      var seasoncrop = allcropdata[selected_index - 1];
      // console.log("Season crop", seasoncrop[Object.keys(seasoncrop)[0]]);
      var croplist = seasoncrop[Object.keys(seasoncrop)[0]];
      var list_array = [];
      croplist.map((item, index, data) => {
        list_array.push(item);
      });
      this.setState({
        cropData: list_array,
        cropDPkey:this.state.cropDPkey+1
      },()=>{
        // console.log("CROPMLIST NEW",list_array)
      });
     
    }
  }

  handleLocationchange(e) {
    this.setState({
      rua: e.target.value,
      selrua: e.target.value,
    });
  }

  handledpchange(e) {
    var getthis = this;
    getthis.setState({
      dp: e.target.value,
    });
  }
  handlecropchange(e) {
    var getthis = this;
    getthis.setState({
      crop: e.target.value,
    });
  }

  componentDidMount() {
    validate_Token();
    this.fetchseason();
    const user_role = Permission();
    if (user_role[0] == "KB") {
      if (user_role[1] == "Pattanchery") {
        this.setState({
          selrua: "Pattanchery",
          //Chittur-Thathamangalam Muncipality</option>
          setKB1: false,
          //Eruthenpathy
          setKB2: false,
          //Kozhinjampara
          setKB3: false,
          //Nallepilly
          setKB4: false,
          //Pattanchery
          setKB5: true,
          //Perumatty
          setKB6: false,
          //Vadakarapathy
          setKB7: false,
          //ALL
          allrua: false,
        });
      }
      if (user_role[1] == "Nallepilly") {
        this.setState({
          selrua: "Nallepilly",
          rua:"Nallepilly",
          //Chittur-Thathamangalam Muncipality</option>
          setKB1: false,
          //Eruthenpathy
          setKB2: false,
          //Kozhinjampara
          setKB3: false,
          //Nallepilly
          setKB4: true,
          //Pattanchery
          setKB5: false,
          //Perumatty
          setKB6: false,
          //Vadakarapathy
          setKB7: false,
          //ALL
          allrua: false,
        });
      }
      if (user_role[1] == "Kozhinjampara") {
        this.setState({
          selrua: "Kozhinjampara",  
           rua: "Kozhinjampara",  
          //Chittur-Thathamangalam Muncipality</option>
          setKB1: false,
          //Eruthenpathy
          setKB2: false,
          //Kozhinjampara
          setKB3: true,
          //Nallepilly
          setKB4: false,
          //Pattanchery
          setKB5: false,
          //Perumatty
          setKB6: false,
          //Vadakarapathy
          setKB7: false,
          //ALL
          allrua: false,
        });
      }
      if (user_role[1] == "Vadakarapathy") {
        this.setState({
          selrua: "Vadakarapathy",
          rua: "Vadakarapathy",
          //Chittur-Thathamangalam Muncipality</option>
          setKB1: false,
          //Eruthenpathy
          setKB2: false,
          //Kozhinjampara
          setKB3: false,
          //Nallepilly
          setKB4: false,
          //Pattanchery
          setKB5: false,
          //Perumatty
          setKB6: false,
          //Vadakarapathy
          setKB7: true,
          //ALL
          allrua: false,
        });
      }
      if (user_role[1] == "Perumatty") {
        this.setState({
          selrua: "Perumatty",
          rua:"Perumatty",
          //Chittur-Thathamangalam Muncipality</option>
          setKB1: false,
          //Eruthenpathy
          setKB2: false,
          //Kozhinjampara
          setKB3: false,
          //Nallepilly
          setKB4: false,
          //Pattanchery
          setKB5: false,
          //Perumatty
          setKB6: true,
          //Vadakarapathy
          setKB7: false,
          //ALL
          allrua: false,
        });
      }
      if (user_role[1] == "Eruthenpathy") {
        this.setState({
          selrua: "Eruthenpathy",
          rua:"Eruthenpathy",
          //Chittur-Thathamangalam Muncipality</option>
          setKB1: false,
          //Eruthenpathy
          setKB2: true,
          //Kozhinjampara
          setKB3: false,
          //Nallepilly
          setKB4: false,
          //Pattanchery
          setKB5: false,
          //Perumatty
          setKB6: false,
          //Vadakarapathy
          setKB7: false,
          //ALL
          allrua: false,
        });
      }
      if (user_role[1] == "Chittur" || user_role[1] == "Chittur-Thathamangalam") {
        this.setState({
          selrua: "Chittur",
          rua:"Chittur",
          //Chittur-Thathamangalam Muncipality</option>
          setKB1: true,
          //Eruthenpathy
          setKB2: false,
          //Kozhinjampara
          setKB3: false,
          //Nallepilly
          setKB4: false,
          //Pattanchery
          setKB5: false,
          //Perumatty
          setKB6: false,
          //Vadakarapathy
          setKB7: false,
          //ALL
          allrua: false,
        });
      }
    }
  }

  render() {
    const { t, i18n } = this.props
    let data_table = {
      columns: [
        {
          label: "Season",
          field: "season",
          sort: "asc",
          width: 150
        },
        {
          label: "Crop",
          field: "crop",
          sort: "asc",
          width: 150
        },
        {
          label: "Data Provider",
          field: "dp",
          sort: "asc",
          width: 150
        },
        {
          label: "RUA",
          field: "rua",
          sort: "asc",
          width: 150
        },
        {
          label: "Deficit Rainfall",
          field: "deficit_rainfall",
          sort: "asc",
          width: 150
        },
        {
          label: "Disease Congenial Climate",
          field: "Disease Congenial Climate",
          sort: "asc",
          width: 150
        },

        {
          label: "Excess Rainfall",
          field: "Excess Rainfall",
          sort: "asc",
          width: 150
        },
        {
          label: "High Temperature",
          field: "High Temperature",
          sort: "asc",
          width: 150
        },
        {
          label: "Dry Spell",
          field: "Dry Spell",
          sort: "asc",
          width: 150
        },
        {
          label: "Wind Speed",
          field: "Wind Speed",
          sort: "asc",
          width: 150
        },
        {
          label:"Wind Speed (A)",
          field: "Wind Speed (A)",
          sort: "asc",
          width: 150
        },
        {
          label: "Wind Speed (B)",
          field: "Wind Speed (B)",
          sort: "asc",
          width: 150
        },
        {
          label: "Rise in Night Temperature",
          field: "Rise in Night Temperature",
          sort: "asc",
          width: 150
        },
        {
          label: "Rise in Temperature",
          field: "Rise in Temperature",
          sort: "asc",
          width: 150
        },
        {
          label: "Total",
          field: "total",
          sort: "asc",
          width: 150
        },
      ],
      rows: this.state.rowtabledata,
    };
    return (
      <>
        <React.Fragment>
          <div className="page-content">
            <Container fluid>
              <Row>
                <Col xs={12}>
                  <h4
                    className="card-title"
                    style={{ padding: "5px", fontSize: "18px" }}
                  >
                    {" "}
                    {t("Termsheet Implications")}    
                    
                  </h4>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Card style={{ backgroundColor: "#F1F6FB" }}>
                    <CardBody>
                      <Row>
                        <Col md="3">
                          <FormGroup>
                            <Label
                              className="col-md-12 col-form-label"
                              style={{ fontSize: "14px" }}
                            >
                              {t("Location")}{" "}
                            </Label>
                            <select
                              className="form-select"
                              style={{ fontSize: "11px" }}
                              onChange={this.handleLocationchange}
                              value={this.state.selrua}
                            >
                              <option
                                value="Chittur"
                                style={
                                  this.state.setKB1 ? {} : { display: "none" }
                                }
                              >
                                Chittur-Thathamangalam
                              </option>
                              <option
                                value="Eruthenpathy"
                                style={
                                  this.state.setKB2 ? {} : { display: "none" }
                                }
                              >
                                Eruthenpathy
                              </option>
                              <option
                                value="Kozhinjampara"
                                style={
                                  this.state.setKB3 ? {} : { display: "none" }
                                }
                              >
                                Kozhinjampara
                              </option>
                              <option
                                value="Nallepilly"
                                style={
                                  this.state.setKB4 ? {} : { display: "none" }
                                }
                              >
                                Nallepilly
                              </option>
                              <option
                                value="Pattanchery"
                                style={
                                  this.state.setKB5 ? {} : { display: "none" }
                                }
                              >
                                Pattanchery
                              </option>
                              <option
                                value="Perumatty"
                                style={
                                  this.state.setKB6 ? {} : { display: "none" }
                                }
                              >
                                Perumatty
                              </option>
                              <option
                                value="Vadakarapathy"
                                style={
                                  this.state.setKB7 ? {} : { display: "none" }
                                }
                              >
                                Vadakarapathy
                              </option>
                              <option
                                value="all"
                                style={
                                  this.state.allrua ? {} : { display: "none" }
                                }
                              >
                                All
                              </option>
                            </select>
                          </FormGroup>
                        </Col>
                        <Col md="2">
                          <FormGroup>
                            <Label
                              className="col-md-12 col-form-label"
                              style={{ fontSize: "14px" }}
                            >
                              {t("Season")}
                            </Label>
                            <select
                              className="form-select"
                              style={{ fontSize: "11px" }}
                              onChange={this.handleSeasonchange}
                            >
                              <option value="all">All</option>
                              {this.state.seasonData.length > 0 &&
                                this.state.seasonData.map((item) => (
                                  <option value={item} key={item}>
                                    {item}
                                  </option>
                                ))}                              
                            </select>
                          </FormGroup>
                        </Col>
                        <Col md="2">
                          <FormGroup>
                            <Label
                              className="col-md-12 col-form-label"
                              style={{ fontSize: "14px" }}
                            >
                              {t("Crop")}
                            </Label>
                            <select
                              className="form-select"
                              style={{ fontSize: "11px" }}
                              key={this.state.cropDPkey}
                              onChange={this.handlecropchange}
                            >
                               <option value="all">All</option>
                              {this.state.cropData.length > 0 &&
                                this.state.cropData.map((item) => (
                                  <option value={item} key={item}>
                                    {item}
                                  </option>
                                ))}                             
                            </select>
                          </FormGroup>
                        </Col>
                        <Col md="2">
                          <FormGroup>
                            <Label
                              htmlFor="example-number-input"
                              className="col-form-label"
                            >
                              {" "}
                              {t("Acreage (ha)")}
                            </Label>
                            <Input
                              className="form-control acreage"
                              type="number"
                              defaultValue="1"
                              min="0"
                              id="example-number-input"
                              style={{ fontSize: "8px" }}
                              onChange={this.handleAcreChange}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="2">
                          <FormGroup>
                            <Label
                              className="col-md-12 col-form-label"
                              style={{ fontSize: "14px" }}
                            >
                              {t("Data Provider")}
                            </Label>
                            <select
                              className="form-select"
                              style={{ fontSize: "11px" }}
                              onChange={this.handledpchange}
                            >
                              {/* <option value="AIC" selected>
                                AIC
                              </option>
                              <option value="Meteoblue">Meteoblue</option>
                              <option value="darksky">Dark Sky</option> */}
                              <option value="all">All</option>
                              {this.state.dpData.length > 0 &&
                                this.state.dpData.map((item) => (
                                  <option value={item} key={item}>
                                    {item}
                                  </option>
                                ))}
                            </select>
                          </FormGroup>
                        </Col>
                       
                        <Col md="1">
                          <FormGroup>
                            <Label
                              className="col-md-12 col-form-label"
                              style={{ fontSize: "14px" }}
                            >
                              <span>&nbsp;</span>
                            </Label>
                            <Button
                              color="primary"
                              className="waves-effect waves-light mr-1"
                              style={{
                                fontSize: "11px",
                                float: "right",
                              }}
                              onClick={this.fetchdata}
                            >
                              {t("Get")}&nbsp;{t("Data")}
                            </Button>
                          </FormGroup>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Col md={12}>
                <Row>
                  <Card>
                    <CardBody>
                      <Row>
                        {/* <Col style={{ paddingTop: "10px" }}> */}
                        <Col sm="12">
                          <CardText>
                            <center>
                              <p
                                style={
                                  this.state.showWaiting
                                    ? {}
                                    : { display: "none" }
                                }
                              >
                                {t("Waiting for search")}
                              </p>
                              <Spinner
                                style={
                                  this.state.showloader
                                    ? {}
                                    : { display: "none" }
                                }
                              ></Spinner>
                            </center>
                            <div
                              id="tsimplications"
                              style={
                                this.state.showtable ? {} : { display: "none" }
                              }
                            >
                              <MDBDataTable
                                maxHeight="300px"
                                scrollX
                                searching={false}
                                lengthChange={false}
                                paging={false}
                                sorting={true}
                                showPaginationBottom={false}
                                bordered
                                data={data_table}
                                className="datadownloadtable"
                                style={{fontSize:"12px"}}
                              />
                            </div>
                          </CardText>
                        </Col>
                        {/* </Col> */}
                      </Row>
                    </CardBody>
                  </Card>
                </Row>
              </Col>
            </Container>
          </div>
        </React.Fragment>
      </>
    );
  }
}
export default withNamespaces()(TermsheetTable);

