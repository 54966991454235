export default function getRuaObject(){
    const rua = {
        type: "FeatureCollection",
        name: "chittur_rua",
        crs: { type: "name", properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" } },
        features: [
          {
            type: "Feature",
            properties: {
              AREA: 44918713.699500002,
              PERIMETER: 29244.1466072,
              LOCATION: "Vadakarapathy",
              PANCHAYAT: "Vadakarapathy",
              BLOCK: "Chittur",
              MUNICIPALI: null,
              CORPORATIO: null,
              DISTRICT: "Palakkad",
              AEU_NEW: "Agro-ecological unit 23: Palakkad Eastern Plains",
              AEU_FINAL: 23,
            },
            geometry: {
              type: "MultiPolygon",
              coordinates: [
                [
                  [
                    [76.862998962402116, 10.817207336425831],
                    [76.863769531249773, 10.816986083984421],
                    [76.86454773059846, 10.816687586482393],
                    [76.864852903478464, 10.816537850946823],
                    [76.865249633788835, 10.816082000732472],
                    [76.865409845448184, 10.815700531759534],
                    [76.865432751767159, 10.814322482025078],
                    [76.865745547326057, 10.813713066299467],
                    [76.865982055663835, 10.813254356384325],
                    [76.867080688476335, 10.81204032897954],
                    [76.867553710937273, 10.811355590820359],
                    [76.868179321288835, 10.810520172119187],
                    [76.868499755859148, 10.809909820556683],
                    [76.86912536621071, 10.809303283691454],
                    [76.869354248046648, 10.809228897094773],
                    [76.871154785156008, 10.807715415954634],
                    [76.872077928498001, 10.807496076947908],
                    [76.872627247788827, 10.807426461735082],
                    [76.87539673930246, 10.80799295175755],
                    [76.876464843749758, 10.808235168457076],
                    [76.877159118652102, 10.808320045471238],
                    [76.878784169980278, 10.808567030680424],
                    [76.879936207848417, 10.808657660616879],
                    [76.880477899956546, 10.808588013877459],
                    [76.881637573241932, 10.808445930481003],
                    [76.882331843555505, 10.808455451325425],
                    [76.88495637355993, 10.808484090979846],
                    [76.88610841142804, 10.808496489937012],
                    [76.888259902976202, 10.809209830812135],
                    [76.889411940844326, 10.809605599850096],
                    [76.890724190083247, 10.809544571129038],
                    [76.892112731933324, 10.809559822082566],
                    [76.89257812499973, 10.809487342834517],
                    [76.89373779422921, 10.809347167390538],
                    [76.895050043468132, 10.809132592920408],
                    [76.895515441894261, 10.809137344360396],
                    [76.896987915038778, 10.808923721313521],
                    [76.897453308105199, 10.808852195739789],
                    [76.898918151855199, 10.808408737182662],
                    [76.899383553310429, 10.808260930017944],
                    [76.900627151149365, 10.807662949436631],
                    [76.901565551757528, 10.807212829589888],
                    [76.901954650878608, 10.80652713775639],
                    [76.901885986327841, 10.805835723876998],
                    [76.901824934098471, 10.804762847767593],
                    [76.901611321189307, 10.803380954041154],
                    [76.901466369628608, 10.80253601074223],
                    [76.901489257812202, 10.801385879516644],
                    [76.901580823718021, 10.800161359664907],
                    [76.902130126952827, 10.799477577209512],
                    [76.902748109195841, 10.799024569406475],
                    [76.903076171874702, 10.797878265380898],
                    [76.903472900390341, 10.796886444091838],
                    [76.903564453124716, 10.796427726745643],
                    [76.903808593749702, 10.795204162597695],
                    [76.903739937945076, 10.794513708988347],
                    [76.903907771994781, 10.793825163775653],
                    [76.905021655934235, 10.791232126581971],
                    [76.905975328502905, 10.789174084348108],
                    [76.906303407701856, 10.788027779815417],
                    [76.906158446192677, 10.787336352171714],
                    [76.906013484683456, 10.786338821613631],
                    [76.906021107362349, 10.785419458981655],
                    [76.906578063964545, 10.784734725952182],
                    [76.906974779931019, 10.783820137628753],
                    [76.907440185546577, 10.783135414123569],
                    [76.907775878905952, 10.781683921813999],
                    [76.907928452499675, 10.781454071610471],
                    [76.907943725585625, 10.780765533447298],
                    [76.908111572265312, 10.779463768005401],
                    [76.908119201659858, 10.779233932495153],
                    [76.908142089843452, 10.777471542358432],
                    [76.908065791329975, 10.777009973248841],
                    [76.907341015310564, 10.774243364166221],
                    [76.907043465109467, 10.773320189068849],
                    [76.90605926513642, 10.771392822265652],
                    [76.905990609891063, 10.771085726293524],
                    [76.905685424804375, 10.770929336547882],
                    [76.905067434793708, 10.771075250458301],
                    [76.905052178240922, 10.771078853097588],
                    [76.904449466354819, 10.771221175780374],
                    [76.90429687991481, 10.771142976327676],
                    [76.903541564941122, 10.7706108093262],
                    [76.903541564941122, 10.7706029159219],
                    [76.90353394771482, 10.770597469502242],
                    [76.9026870827267, 10.770359027152161],
                    [76.902305599737446, 10.769664748604029],
                    [76.901702878908253, 10.768663405580545],
                    [76.901481641068287, 10.768045437141653],
                    [76.900642398759075, 10.767346384567153],
                    [76.900032052999066, 10.767033586756362],
                    [76.898727428690961, 10.766328798594515],
                    [76.897506705644389, 10.765702243663858],
                    [76.896583564325496, 10.7651538881859],
                    [76.894744870588141, 10.764289850214228],
                    [76.893219006188161, 10.763124454079868],
                    [76.891685485330797, 10.762340538682839],
                    [76.89038848595348, 10.761481274737543],
                    [76.889152513045303, 10.76123809213958],
                    [76.886924747418306, 10.760983472145419],
                    [76.886001572320922, 10.760972034749226],
                    [76.885383601630153, 10.760812753412816],
                    [76.885070806071255, 10.76088525105666],
                    [76.883300797512348, 10.760635371310384],
                    [76.879753123937121, 10.760518087894635],
                    [76.878829948839751, 10.76050854209379],
                    [76.877899182590085, 10.760957714922018],
                    [76.877662661110037, 10.761414512681039],
                    [76.877349865551153, 10.761487971885854],
                    [76.876579274641884, 10.761555661724843],
                    [76.875961306202996, 10.761471760463293],
                    [76.87435913394566, 10.760687813539676],
                    [76.874046336134867, 10.760453215181585],
                    [76.872207642397512, 10.759742725210888],
                    [76.871368402340181, 10.758890158413902],
                    [76.871063229460177, 10.758733759508505],
                    [76.870613095070979, 10.758114829508646],
                    [76.869766227830951, 10.757721911375111],
                    [76.869056701673145, 10.758788124859741],
                    [76.868515007313107, 10.759087562152398],
                    [76.867118825286042, 10.75937844898367],
                    [76.86619568171524, 10.759674073810926],
                    [76.864952086128199, 10.759737953436415],
                    [76.86395263470007, 10.759419453816767],
                    [76.863502500310872, 10.759030348148636],
                    [76.862907402160573, 10.757490163921444],
                    [76.862754815720578, 10.757259378028742],
                    [76.862449642840573, 10.757102979123347],
                    [76.860816939333489, 10.757927899353474],
                    [76.860191345963813, 10.758226408863646],
                    [76.85926820464492, 10.758293139393558],
                    [76.858108508067502, 10.758740422878329],
                    [76.857719433925965, 10.758812920522177],
                    [76.85708618409889, 10.759648316587523],
                    [76.855934146230766, 10.759864814179597],
                    [76.85377502975183, 10.759380338327125],
                    [76.852851854654446, 10.759295475504604],
                    [76.851615915524775, 10.759355542664693],
                    [76.8513030861874, 10.759583477652962],
                    [76.850532529056593, 10.759881025602162],
                    [76.850219733497696, 10.760030761137735],
                    [76.849296558400326, 10.760019289963052],
                    [76.848983760589533, 10.759863852618629],
                    [76.848457349869577, 10.759167684727041],
                    [76.847923280440355, 10.758624069496962],
                    [76.847465521120355, 10.758619295470588],
                    [76.847221376961414, 10.758769992567133],
                    [76.846672059922497, 10.759607311754422],
                    [76.846054089231714, 10.759906782825567],
                    [76.845741259894325, 10.759902970360168],
                    [76.844451916974393, 10.75881480986563],
                    [76.843208321387323, 10.758877761708634],
                    [76.842987049768894, 10.758798600694966],
                    [76.842224117568904, 10.758101468990503],
                    [76.840690596711525, 10.757545457055162],
                    [76.840003974620785, 10.756464955269905],
                    [76.838928213083406, 10.756452556312743],
                    [76.838378896044489, 10.756983741059065],
                    [76.836433394726583, 10.758494389375938],
                    [76.835739149956936, 10.758640278667627],
                    [76.83512114548769, 10.758556377406075],
                    [76.834732039819542, 10.758704192071599],
                    [76.834335342999097, 10.759237297687967],
                    [76.834327684289818, 10.760004042353358],
                    [76.833854677360137, 10.76053522709968],
                    [76.833549504480118, 10.760608686304497],
                    [76.832084668801215, 10.760131833130922],
                    [76.831703185811961, 10.760280607105525],
                    [76.831466666583822, 10.760508542093792],
                    [76.831130996232545, 10.76234435114824],
                    [76.830902099683314, 10.762571324575539],
                    [76.830512994015166, 10.762568473671113],
                    [76.829284677564402, 10.76193999561851],
                    [76.828979506936307, 10.761936183153113],
                    [76.828666675347009, 10.762085918688685],
                    [76.82803345704653, 10.762845999984251],
                    [76.826484688579484, 10.763594643883616],
                    [76.826248167099436, 10.76389885520264],
                    [76.82562257372976, 10.764428150605507],
                    [76.825233468061612, 10.765113842661876],
                    [76.824920670250819, 10.765187270340103],
                    [76.824234014381588, 10.764489177074671],
                    [76.823928841501598, 10.764333739730246],
                    [76.823226938022685, 10.764709489384042],
                    [76.822830207423735, 10.76562404421116],
                    [76.822601310874489, 10.765774741307704],
                    [76.82175444588637, 10.765459061065897],
                    [76.820983891007472, 10.765372275121431],
                    [76.820289610207439, 10.765671714665988],
                    [76.818504320260345, 10.765881548888235],
                    [76.81726838113066, 10.766251560702791],
                    [76.816558854972854, 10.766933442545655],
                    [76.815399158395422, 10.76843835527519],
                    [76.815681458734943, 10.769606604565885],
                    [76.815818795313334, 10.770451546432072],
                    [76.815963756822555, 10.77152634640847],
                    [76.816177371983599, 10.772141466194835],
                    [76.818214431020422, 10.776075363007172],
                    [76.818359358751138, 10.776689518980666],
                    [76.818580630369581, 10.776922227995303],
                    [76.818580630369581, 10.777229323997242],
                    [76.818946831970663, 10.7781534268771],
                    [76.819091793479856, 10.779228226853499],
                    [76.819244379919837, 10.779538139981378],
                    [76.819702139239837, 10.779849048448716],
                    [76.821777352205359, 10.779795644658453],
                    [76.823623670873516, 10.780277238079908],
                    [76.824310290712347, 10.780820853309985],
                    [76.825233468061612, 10.781290047774275],
                    [76.826606743769716, 10.782226549611297],
                    [76.827369675969692, 10.783078188625803],
                    [76.82850646622812, 10.784317012438393],
                    [76.829421982616196, 10.784939754903652],
                    [76.830184948594692, 10.785409910928914],
                    [76.830871570685431, 10.786183352742619],
                    [76.830940255863865, 10.786952018278049],
                    [76.831085217373072, 10.787260042062471],
                    [76.831703185811961, 10.787879005840821],
                    [76.832145697522265, 10.788804070281648],
                    [76.832527146733028, 10.789422040972438],
                    [76.833213800350364, 10.789966583985002],
                    [76.834358215539581, 10.790209766582961],
                    [76.835052494087719, 10.79067803551667],
                    [76.835578904807662, 10.791526789848257],
                    [76.835807801356907, 10.792142868943687],
                    [76.836853031644509, 10.794224747530915],
                    [76.836853031644509, 10.794530879719986],
                    [76.837074271736341, 10.794839865065377],
                    [76.837295543354799, 10.795763008636166],
                    [76.837280261966626, 10.796987544148136],
                    [76.837265012105021, 10.797985072454326],
                    [76.837402350935321, 10.79951955487266],
                    [76.837692273953721, 10.800443657752515],
                    [76.837989821902937, 10.801059739099854],
                    [76.838371271113687, 10.801830293978737],
                    [76.839889510582879, 10.803380954041158],
                    [76.840797438070538, 10.804310792508353],
                    [76.841720579389431, 10.80485914798631],
                    [76.843399061755989, 10.806179053682618],
                    [76.843551648195998, 10.806412690479743],
                    [76.845153820453319, 10.807502740317736],
                    [76.8462219255333, 10.808203682235694],
                    [76.849433894978731, 10.810004219792486],
                    [76.852439876445828, 10.810727106468454],
                    [76.853515637983193, 10.811352733616625],
                    [76.854660017142024, 10.811749466467459],
                    [76.857421885976748, 10.813082698903411],
                    [76.858573923844887, 10.813324919940403],
                    [76.860649105283798, 10.814115530233844],
                    [76.861335761153029, 10.814581876045608],
                    [76.861793518221134, 10.815047258044499],
                    [76.862251277541105, 10.815512642295291],
                    [76.862998962402116, 10.817207336425831],
                  ],
                ],
              ],
            },
          },
          {
            type: "Feature",
            properties: {
              AREA: 38687817.057300001,
              PERIMETER: 37075.822932499999,
              LOCATION: "Eruthenpathy",
              PANCHAYAT: "Eruthenpathy",
              BLOCK: "Chittur",
              MUNICIPALI: null,
              CORPORATIO: null,
              DISTRICT: "Palakkad",
              AEU_NEW: "Agro-ecological unit 23: Palakkad Eastern Plains",
              AEU_FINAL: 23,
            },
            geometry: {
              type: "MultiPolygon",
              coordinates: [
                [
                  [
                    [76.840003974620785, 10.756464955269905],
                    [76.840690596711525, 10.757545457055162],
                    [76.842224117568904, 10.758101468990503],
                    [76.842987049768894, 10.758798600694966],
                    [76.843208321387323, 10.758877761708634],
                    [76.844451916974393, 10.75881480986563],
                    [76.845741259894325, 10.759902970360168],
                    [76.846054089231714, 10.759906782825567],
                    [76.846672059922497, 10.759607311754422],
                    [76.847221376961414, 10.758769992567133],
                    [76.847465521120355, 10.758619295470588],
                    [76.847923280440355, 10.758624069496962],
                    [76.848457349869577, 10.759167684727041],
                    [76.848983760589533, 10.759863852618629],
                    [76.849296558400326, 10.760019289963052],
                    [76.850219733497696, 10.760030761137735],
                    [76.850532529056593, 10.759881025602162],
                    [76.8513030861874, 10.759583477652962],
                    [76.851615915524775, 10.759355542664693],
                    [76.852851854654446, 10.759295475504604],
                    [76.85377502975183, 10.759380338327125],
                    [76.855934146230766, 10.759864814179597],
                    [76.85708618409889, 10.759648316587523],
                    [76.857719433925965, 10.758812920522177],
                    [76.858108508067502, 10.758740422878329],
                    [76.85926820464492, 10.758293139393558],
                    [76.860191345963813, 10.758226408863646],
                    [76.860816939333489, 10.757927899353474],
                    [76.862449642840573, 10.757102979123347],
                    [76.862754815720578, 10.757259378028742],
                    [76.862907402160573, 10.757490163921444],
                    [76.863502500310872, 10.759030348148636],
                    [76.86395263470007, 10.759419453816767],
                    [76.864952086128199, 10.759737953436415],
                    [76.86619568171524, 10.759674073810926],
                    [76.867118825286042, 10.75937844898367],
                    [76.868515007313107, 10.759087562152398],
                    [76.869056701673145, 10.758788124859741],
                    [76.869766227830951, 10.757721911375111],
                    [76.870613095070979, 10.758114829508646],
                    [76.871063229460177, 10.758733759508505],
                    [76.871368402340181, 10.758890158413902],
                    [76.872207642397512, 10.759742725210888],
                    [76.874046336134867, 10.760453215181585],
                    [76.87435913394566, 10.760687813539676],
                    [76.875961306202996, 10.761471760463293],
                    [76.876579274641884, 10.761555661724843],
                    [76.877349865551153, 10.761487971885854],
                    [76.877662661110037, 10.761414512681039],
                    [76.877899182590085, 10.760957714922018],
                    [76.878829948839751, 10.76050854209379],
                    [76.879753123937121, 10.760518087894635],
                    [76.883300797512348, 10.760635371310384],
                    [76.885070806071255, 10.76088525105666],
                    [76.885383601630153, 10.760812753412816],
                    [76.886001572320922, 10.760972034749226],
                    [76.886924747418306, 10.760983472145419],
                    [76.889152513045303, 10.76123809213958],
                    [76.89038848595348, 10.761481274737543],
                    [76.891685485330797, 10.762340538682839],
                    [76.893219006188161, 10.763124454079868],
                    [76.894744870588141, 10.764289850214228],
                    [76.896583564325496, 10.7651538881859],
                    [76.897506705644389, 10.765702243663858],
                    [76.898727428690961, 10.766328798594515],
                    [76.900032052999066, 10.767033586756362],
                    [76.900642398759075, 10.767346384567153],
                    [76.901481641068287, 10.768045437141653],
                    [76.901702878908253, 10.768663405580545],
                    [76.902305599737446, 10.769664748604029],
                    [76.9026870827267, 10.770359027152161],
                    [76.90353394771482, 10.770597469502242],
                    [76.903541564941122, 10.769449443717759],
                    [76.903541564941122, 10.769448280334501],
                    [76.904190072334274, 10.767156603507553],
                    [76.904678329125559, 10.765321722235589],
                    [76.904930114745795, 10.764176368713406],
                    [76.905799869522397, 10.762577057910976],
                    [76.905502321573181, 10.761652955031119],
                    [76.904983533532146, 10.760114662399284],
                    [76.90438079833956, 10.759187698364281],
                    [76.904304504845612, 10.758878721017705],
                    [76.903999331965622, 10.758723252146691],
                    [76.902793884277045, 10.756794929504419],
                    [76.902198791503608, 10.755253791809107],
                    [76.900367736816108, 10.753853797912619],
                    [76.899757385253622, 10.753540039062523],
                    [76.898452758788778, 10.752529144287131],
                    [76.897918701171591, 10.752140045166037],
                    [76.897315979003622, 10.751290321350117],
                    [76.897163391112997, 10.751058578491232],
                    [76.897071838378622, 10.749964714050314],
                    [76.896911621093466, 10.750163078308127],
                    [76.896675109862997, 10.74955177307131],
                    [76.896659851073949, 10.748016357421895],
                    [76.896492004394247, 10.746176719665545],
                    [76.896568314463906, 10.74517820888196],
                    [76.89648437499973, 10.744487762451188],
                    [76.896324170304965, 10.744258877776574],
                    [76.89516448974581, 10.74334716796877],
                    [76.894378668987073, 10.742203720225623],
                    [76.893684390438949, 10.740674011833654],
                    [76.893363952636449, 10.73960208892824],
                    [76.89266203269186, 10.738917361540977],
                    [76.891815199230294, 10.738310830498282],
                    [76.890953052854002, 10.737396239640777],
                    [76.89056396484348, 10.736784934997569],
                    [76.88946533203098, 10.734108924865742],
                    [76.888992309570042, 10.732577323913587],
                    [76.888984680175511, 10.731578826904308],
                    [76.889129640504819, 10.730658541378117],
                    [76.889198291904776, 10.729352955509023],
                    [76.889114392895124, 10.72812556909262],
                    [76.889793395995838, 10.726660728454599],
                    [76.889945976495071, 10.72635363741176],
                    [76.890556335448949, 10.725733757019054],
                    [76.890937805175511, 10.724887847900403],
                    [76.891006469726292, 10.723964691162118],
                    [76.891616833930826, 10.72280884851946],
                    [76.891998283141575, 10.722191807863053],
                    [76.893142700195028, 10.721184730529792],
                    [76.893447868958901, 10.720568650156793],
                    [76.893371582030966, 10.720262527465827],
                    [76.892745971679403, 10.719115257263192],
                    [76.892349243163778, 10.718196868896493],
                    [76.891960129212904, 10.717357642272319],
                    [76.89188385288216, 10.717052469392325],
                    [76.891334533591333, 10.716518402214986],
                    [76.890640255043223, 10.71606253223845],
                    [76.889717102050511, 10.71637725830079],
                    [76.888412489416197, 10.717309940290908],
                    [76.887878417968494, 10.717697143554693],
                    [76.886955261230213, 10.718012809753427],
                    [76.886032104491917, 10.717866897583015],
                    [76.884712219238011, 10.717494964599615],
                    [76.883476256492827, 10.71704484446153],
                    [76.882705701613943, 10.716512666627644],
                    [76.882675170898182, 10.716276168823249],
                    [76.882621766573905, 10.715899469963222],
                    [76.882156382323117, 10.715366362094962],
                    [76.881607055663807, 10.714142799377447],
                    [76.881134033202869, 10.713533401489261],
                    [76.88059233246787, 10.712693202326632],
                    [76.87888336181615, 10.710635185241703],
                    [76.878334035591081, 10.709412579229326],
                    [76.878021237780288, 10.708802233469335],
                    [76.877311709370574, 10.707271563516398],
                    [76.876380920409915, 10.706051826477056],
                    [76.875915527343523, 10.705364218379074],
                    [76.875442504882571, 10.704446792602544],
                    [76.874893188476321, 10.703070640563963],
                    [76.874496470524065, 10.7021532104946],
                    [76.873870843375897, 10.701006874435327],
                    [76.873252868652102, 10.700091361999515],
                    [76.872779846191165, 10.698945045471191],
                    [76.872474661348832, 10.698793426383624],
                    [76.871772757869905, 10.698108662109743],
                    [76.871231079101321, 10.697498321533203],
                    [76.870689392089602, 10.696581840515137],
                    [76.870376586913821, 10.696353912353517],
                    [76.869827270507585, 10.695670127868651],
                    [76.869133009530472, 10.694982514795891],
                    [76.86912536621071, 10.694140434265137],
                    [76.869735717773196, 10.693596839904785],
                    [76.87034607611966, 10.693284972354229],
                    [76.870887756347415, 10.692667007446287],
                    [76.870727539062258, 10.691439628601071],
                    [76.870948791503665, 10.690670967102049],
                    [76.870483414949959, 10.689447407287199],
                    [76.86923219218022, 10.687769886481606],
                    [76.868690490722429, 10.687390327453608],
                    [76.867675781249773, 10.685788154602047],
                    [76.867446899413835, 10.685635566711424],
                    [76.867202758074185, 10.684715277502526],
                    [76.867279068183421, 10.684409143061558],
                    [76.867431654623431, 10.683869371823469],
                    [76.867881789012614, 10.682024014716278],
                    [76.867797856224485, 10.681717882527213],
                    [76.868247990613682, 10.680102349751744],
                    [76.868476853384436, 10.678872112430923],
                    [76.869239817110994, 10.677562714096426],
                    [76.86972807390228, 10.67715932012767],
                    [76.865997316415786, 10.677664747650587],
                    [76.864150999999538, 10.678065290714917],
                    [76.862533577880612, 10.67861460775385],
                    [76.859535221344345, 10.679024698870924],
                    [76.857452383448035, 10.679501518266006],
                    [76.853530885592875, 10.679688448421178],
                    [76.849746692789523, 10.679490080869812],
                    [76.849288933469538, 10.679494854896182],
                    [76.845352185752773, 10.679142941595746],
                    [76.838195812133193, 10.679739960616089],
                    [76.836570733556883, 10.679906835356707],
                    [76.835113522808768, 10.680071818501975],
                    [76.83397673255034, 10.682460790839237],
                    [76.833984389007725, 10.683074949064626],
                    [76.833839427498518, 10.684688558718147],
                    [76.834236158097454, 10.686526290894538],
                    [76.834869376397933, 10.688747393151726],
                    [76.83518217420874, 10.689971928663697],
                    [76.835746772635844, 10.693420419589184],
                    [76.836074820308212, 10.69579696144269],
                    [76.836387649645616, 10.697022424737151],
                    [76.836944591615335, 10.698783880582786],
                    [76.837486249944973, 10.700005531411842],
                    [76.838958744333183, 10.701374996906427],
                    [76.840972897051032, 10.702816960044855],
                    [76.84120941627917, 10.703581813114889],
                    [76.841835009648847, 10.704420093863154],
                    [76.842071531128894, 10.705034249836645],
                    [76.841842634579663, 10.705648408062036],
                    [76.841308598928904, 10.706420886062865],
                    [76.841308598928904, 10.706727982064809],
                    [76.843017578766748, 10.708247183094965],
                    [76.844108590165703, 10.709620492581532],
                    [76.845756541282512, 10.713827141680641],
                    [76.846069339093305, 10.714439408310676],
                    [76.846534721092198, 10.714971554617971],
                    [76.847541797451115, 10.715270991910625],
                    [76.848464974800393, 10.715339645562484],
                    [76.850326541078246, 10.716091140366279],
                    [76.851249682397125, 10.716696743626489],
                    [76.851867684614504, 10.716846479162063],
                    [76.853095998813373, 10.716528875798309],
                    [76.853637693173411, 10.716139803908661],
                    [76.855415358189703, 10.716049205498795],
                    [76.856025703949697, 10.716197017912425],
                    [76.856651297319388, 10.717344284006082],
                    [76.856658922250176, 10.717958439979576],
                    [76.856285097970229, 10.719802869304282],
                    [76.85683441500916, 10.72194670214315],
                    [76.857002249058851, 10.723096850667819],
                    [76.856170665458905, 10.724945056427533],
                    [76.855323800470771, 10.72587393558566],
                    [76.855171214030776, 10.726488125337642],
                    [76.855102528852328, 10.727410305095558],
                    [76.855422983120491, 10.728942864391948],
                    [76.856437682158329, 10.729931842236761],
                    [76.857521068626497, 10.731227880053114],
                    [76.857833866437289, 10.731531129811167],
                    [76.858070385665428, 10.732144360254072],
                    [76.857231145608083, 10.733378410040297],
                    [76.857002249058851, 10.734224313467459],
                    [76.857635501137807, 10.736751552417511],
                    [76.857566847485955, 10.737366669951975],
                    [76.857109056639374, 10.737907402751034],
                    [76.855339048080467, 10.737921722578248],
                    [76.854644769532314, 10.738619813591781],
                    [76.853256210184185, 10.738400465095275],
                    [76.852790828185277, 10.73848055389143],
                    [76.851493826556066, 10.739871964144022],
                    [76.850570685237173, 10.740494740387772],
                    [76.849960339477178, 10.741036398717412],
                    [76.850036615807937, 10.741419804828597],
                    [76.850502000058725, 10.741876602587618],
                    [76.850975040766912, 10.742869390645923],
                    [76.850677492817709, 10.744407651751171],
                    [76.850685115496603, 10.745635038167572],
                    [76.850997913307395, 10.746553407711987],
                    [76.851699816786308, 10.747238174237767],
                    [76.851852403226331, 10.747544274900248],
                    [76.852012646123711, 10.74761962344852],
                    [76.852333068865278, 10.748691572520492],
                    [76.85234069379608, 10.749612824495923],
                    [76.852172857494494, 10.750230795186711],
                    [76.851196278606849, 10.750842100255776],
                    [76.849807753037183, 10.750826852646082],
                    [76.848960888049049, 10.750510210843307],
                    [76.848808301609054, 10.750202187058882],
                    [76.847740165002477, 10.749959929991501],
                    [76.847274780751675, 10.749954230434547],
                    [76.846565252341961, 10.750636110025516],
                    [76.846099870343068, 10.751014708331837],
                    [76.845870973793822, 10.751012787461798],
                    [76.845642077244591, 10.750932698665645],
                    [76.845184317924605, 10.75046731441485],
                    [76.844726558604592, 10.750155446638439],
                    [76.844261176605727, 10.750150672612072],
                    [76.844032280056481, 10.750301369708614],
                    [76.84348296301755, 10.750908864564179],
                    [76.843475338086748, 10.751137727334934],
                    [76.842765809677033, 10.752279289367836],
                    [76.841354377788363, 10.75410366327799],
                    [76.840248118779726, 10.755702023069915],
                    [76.840003974620785, 10.756464955269905],
                  ],
                ],
              ],
            },
          },
          {
            type: "Feature",
            properties: {
              AREA: 39915786.072499998,
              PERIMETER: 49578.9149235,
              LOCATION: "Kozhinjampara",
              PANCHAYAT: "Kozhinjampara",
              BLOCK: "Chittur",
              MUNICIPALI: null,
              CORPORATIO: null,
              DISTRICT: "Palakkad",
              AEU_NEW: "Agro-ecological unit 23: Palakkad Eastern Plains",
              AEU_FINAL: 23,
            },
            geometry: {
              type: "MultiPolygon",
              coordinates: [
                [
                  [
                    [76.815399158395422, 10.76843835527519],
                    [76.816558854972854, 10.766933442545655],
                    [76.81726838113066, 10.766251560702791],
                    [76.818504320260345, 10.765881548888235],
                    [76.820289610207439, 10.765671714665988],
                    [76.820983891007472, 10.765372275121431],
                    [76.82175444588637, 10.765459061065897],
                    [76.822601310874489, 10.765774741307704],
                    [76.822830207423735, 10.76562404421116],
                    [76.823226938022685, 10.764709489384042],
                    [76.823928841501598, 10.764333739730246],
                    [76.824234014381588, 10.764489177074671],
                    [76.824920670250819, 10.765187270340103],
                    [76.825233468061612, 10.765113842661876],
                    [76.82562257372976, 10.764428150605507],
                    [76.826248167099436, 10.76389885520264],
                    [76.826484688579484, 10.763594643883616],
                    [76.82803345704653, 10.762845999984251],
                    [76.828666675347009, 10.762085918688685],
                    [76.828979506936307, 10.761936183153113],
                    [76.829284677564402, 10.76193999561851],
                    [76.830512994015166, 10.762568473671113],
                    [76.830902099683314, 10.762571324575539],
                    [76.831130996232545, 10.76234435114824],
                    [76.831466666583822, 10.760508542093792],
                    [76.831703185811961, 10.760280607105525],
                    [76.832084668801215, 10.760131833130922],
                    [76.833549504480118, 10.760608686304497],
                    [76.833854677360137, 10.76053522709968],
                    [76.834327684289818, 10.760004042353358],
                    [76.834335342999097, 10.759237297687967],
                    [76.834732039819542, 10.758704192071599],
                    [76.83512114548769, 10.758556377406075],
                    [76.835739149956936, 10.758640278667627],
                    [76.836433394726583, 10.758494389375938],
                    [76.838378896044489, 10.756983741059065],
                    [76.838928213083406, 10.756452556312743],
                    [76.840003974620785, 10.756464955269905],
                    [76.840248118779726, 10.755702023069915],
                    [76.841354377788363, 10.75410366327799],
                    [76.842765809677033, 10.752279289367836],
                    [76.843475338086748, 10.751137727334934],
                    [76.84348296301755, 10.750908864564179],
                    [76.844032280056481, 10.750301369708614],
                    [76.844261176605727, 10.750150672612072],
                    [76.844726558604592, 10.750155446638439],
                    [76.845184317924605, 10.75046731441485],
                    [76.845642077244591, 10.750932698665645],
                    [76.845870973793822, 10.751012787461798],
                    [76.846099870343068, 10.751014708331837],
                    [76.846565252341961, 10.750636110025516],
                    [76.847274780751675, 10.749954230434547],
                    [76.847740165002477, 10.749959929991501],
                    [76.848808301609054, 10.750202187058882],
                    [76.848960888049049, 10.750510210843307],
                    [76.849807753037183, 10.750826852646082],
                    [76.851196278606849, 10.750842100255776],
                    [76.852172857494494, 10.750230795186711],
                    [76.85234069379608, 10.749612824495923],
                    [76.852333068865278, 10.748691572520492],
                    [76.852012646123711, 10.74761962344852],
                    [76.851852403226331, 10.747544274900248],
                    [76.851699816786308, 10.747238174237767],
                    [76.850997913307395, 10.746553407711987],
                    [76.850685115496603, 10.745635038167572],
                    [76.850677492817709, 10.744407651751171],
                    [76.850975040766912, 10.742869390645923],
                    [76.850502000058725, 10.741876602587618],
                    [76.850036615807937, 10.741419804828597],
                    [76.849960339477178, 10.741036398717412],
                    [76.850570685237173, 10.740494740387772],
                    [76.851493826556066, 10.739871964144022],
                    [76.852790828185277, 10.73848055389143],
                    [76.853256210184185, 10.738400465095275],
                    [76.854644769532314, 10.738619813591781],
                    [76.855339048080467, 10.737921722578248],
                    [76.857109056639374, 10.737907402751034],
                    [76.857566847485955, 10.737366669951975],
                    [76.857635501137807, 10.736751552417511],
                    [76.857002249058851, 10.734224313467459],
                    [76.857231145608083, 10.733378410040297],
                    [76.858070385665428, 10.732144360254072],
                    [76.857833866437289, 10.731531129811167],
                    [76.857521068626497, 10.731227880053114],
                    [76.856437682158329, 10.729931842236761],
                    [76.855422983120491, 10.728942864391948],
                    [76.855102528852328, 10.727410305095558],
                    [76.855171214030776, 10.726488125337642],
                    [76.855323800470771, 10.72587393558566],
                    [76.856170665458905, 10.724945056427533],
                    [76.857002249058851, 10.723096850667819],
                    [76.85683441500916, 10.72194670214315],
                    [76.856285097970229, 10.719802869304282],
                    [76.856658922250176, 10.717958439979576],
                    [76.856651297319388, 10.717344284006082],
                    [76.856025703949697, 10.716197017912425],
                    [76.855415358189703, 10.716049205498795],
                    [76.853637693173411, 10.716139803908661],
                    [76.853095998813373, 10.716528875798309],
                    [76.851867684614504, 10.716846479162063],
                    [76.851249682397125, 10.716696743626489],
                    [76.850326541078246, 10.716091140366279],
                    [76.848464974800393, 10.715339645562484],
                    [76.847541797451115, 10.715270991910625],
                    [76.846534721092198, 10.714971554617971],
                    [76.846069339093305, 10.714439408310676],
                    [76.845756541282512, 10.713827141680641],
                    [76.844108590165703, 10.709620492581532],
                    [76.843017578766748, 10.708247183094965],
                    [76.841308598928904, 10.706727982064809],
                    [76.841308598928904, 10.706420886062865],
                    [76.841842634579663, 10.705648408062036],
                    [76.842071531128894, 10.705034249836645],
                    [76.841835009648847, 10.704420093863154],
                    [76.84120941627917, 10.703581813114889],
                    [76.840972897051032, 10.702816960044855],
                    [76.838958744333183, 10.701374996906427],
                    [76.837486249944973, 10.700005531411842],
                    [76.836944591615335, 10.698783880582786],
                    [76.836387649645616, 10.697022424737151],
                    [76.836074820308212, 10.69579696144269],
                    [76.835746772635844, 10.693420419589184],
                    [76.83518217420874, 10.689971928663697],
                    [76.834869376397933, 10.688747393151726],
                    [76.834236158097454, 10.686526290894538],
                    [76.833839427498518, 10.684688558718147],
                    [76.833984389007725, 10.683074949064626],
                    [76.83397673255034, 10.682460790839237],
                    [76.835113522808768, 10.680071818501975],
                    [76.836570733556883, 10.679906835356707],
                    [76.838195812133193, 10.679739960616089],
                    [76.845352185752773, 10.679142941595746],
                    [76.849288933469538, 10.679494854896182],
                    [76.849746692789523, 10.679490080869812],
                    [76.853530885592875, 10.679688448421178],
                    [76.857452383448035, 10.679501518266006],
                    [76.859535221344345, 10.679024698870924],
                    [76.862533577880612, 10.67861460775385],
                    [76.864150999999538, 10.678065290714917],
                    [76.865997316415786, 10.677664747650587],
                    [76.86972807390228, 10.67715932012767],
                    [76.870460508630984, 10.676553714615558],
                    [76.870689392089602, 10.676321029663075],
                    [76.871383666991946, 10.676239013671864],
                    [76.874008182206197, 10.676677692927726],
                    [76.874465939274302, 10.676750190571571],
                    [76.875160220074321, 10.676668180905372],
                    [76.87539673930246, 10.676512709782459],
                    [76.876548767089588, 10.676579475402823],
                    [76.877014160156008, 10.676422119140616],
                    [76.877471923827869, 10.676418304443349],
                    [76.877708435058338, 10.676569938659659],
                    [76.878013610839588, 10.676489830017083],
                    [76.878547682278736, 10.676026342334634],
                    [76.8787002563474, 10.675563812255847],
                    [76.879158020019275, 10.674639701843251],
                    [76.879226679438688, 10.674024588573946],
                    [76.879211431828992, 10.671798710038493],
                    [76.879051220458194, 10.671186443408455],
                    [76.87900543212865, 10.670797348022454],
                    [76.877815247550032, 10.670966133350982],
                    [76.877197279111158, 10.670818318685455],
                    [76.876655584751106, 10.670515068927402],
                    [76.875953681272193, 10.669754028322766],
                    [76.875488265494809, 10.668837548121807],
                    [76.874862672125133, 10.667078015398113],
                    [76.874313352834292, 10.665854441447117],
                    [76.873527516567222, 10.663788808060938],
                    [76.872741711826748, 10.662260061229945],
                    [76.872116084678566, 10.660730352837975],
                    [76.871650700427779, 10.659813872637015],
                    [76.870719900399607, 10.659283649451664],
                    [76.869560239852589, 10.659063339394192],
                    [76.86886595905257, 10.65929985862233],
                    [76.868255613292575, 10.65991782931312],
                    [76.867340096904485, 10.661384588113984],
                    [76.866958613915244, 10.662077905101141],
                    [76.86682127733684, 10.663000118637546],
                    [76.866989145165022, 10.665301311942784],
                    [76.866767873546578, 10.667298325455583],
                    [76.867095952745558, 10.670519842953773],
                    [76.867034924024495, 10.671901705153623],
                    [76.866653441035254, 10.67267133450193],
                    [76.866127030315297, 10.673366540832545],
                    [76.865585338207168, 10.673830995048952],
                    [76.86466216085789, 10.674375540313413],
                    [76.863433846659007, 10.674617761350405],
                    [76.862510671561637, 10.674625386281202],
                    [76.861579905311956, 10.674172400987572],
                    [76.860115035854548, 10.673109997716447],
                    [76.859481817554084, 10.671887385326412],
                    [76.859169021995186, 10.670892705672758],
                    [76.858856190405902, 10.670127852602722],
                    [76.858619704956254, 10.669514655938302],
                    [76.858230597036211, 10.668981548070036],
                    [76.857452383448035, 10.668374055466368],
                    [76.856369030758373, 10.667614935731779],
                    [76.855751028540993, 10.667314536878152],
                    [76.854827884970206, 10.66709137591625],
                    [76.853897087193928, 10.667098998595147],
                    [76.852508561624276, 10.666728025219619],
                    [76.851585386526907, 10.666658412258693],
                    [76.849739067858735, 10.66675185932108],
                    [76.849433894978731, 10.666753782443028],
                    [76.845428462083532, 10.66740132057072],
                    [76.842964174976501, 10.667574890207751],
                    [76.841270445000234, 10.667664527056646],
                    [76.840339644972062, 10.667750349188237],
                    [76.837722771425021, 10.667849531837975],
                    [76.834945686507183, 10.667949676048677],
                    [76.83232878143356, 10.667280193162979],
                    [76.831626877954619, 10.666441912414719],
                    [76.830924972223784, 10.665219300024686],
                    [76.829528823975224, 10.663927072421831],
                    [76.829376237535229, 10.663620940232761],
                    [76.828758235317849, 10.663166993378164],
                    [76.828140264627066, 10.663247113700907],
                    [76.827293399638933, 10.663715348856128],
                    [76.826309195820485, 10.665105829074333],
                    [76.825393677180514, 10.667031274977674],
                    [76.825012196443168, 10.668032649527747],
                    [76.824554437123183, 10.668573382326811],
                    [76.823020950044295, 10.669352523697464],
                    [76.822090150016137, 10.669131283605608],
                    [76.82147217932534, 10.668828961630041],
                    [76.819305408640915, 10.667081827863512],
                    [76.818992610830122, 10.666931164545458],
                    [76.818069469511229, 10.666939748785326],
                    [76.816833496603081, 10.667027462512277],
                    [76.815917977963068, 10.667263983992314],
                    [76.813758863736055, 10.668049820259387],
                    [76.813453657077545, 10.668205257603809],
                    [76.812080381369469, 10.669598590978346],
                    [76.811775208489465, 10.670063011416262],
                    [76.811477660540277, 10.672136303511721],
                    [76.810951249820334, 10.673752797848154],
                    [76.80934907531109, 10.676220895168699],
                    [76.809280390132642, 10.676230443221444],
                    [76.810050978790045, 10.677136413808695],
                    [76.810668949480828, 10.677515045893502],
                    [76.81213378515973, 10.677962295599785],
                    [76.813064585187902, 10.678185456561685],
                    [76.813606277296032, 10.678565016428982],
                    [76.814071661546848, 10.67909716273628],
                    [76.814308180774972, 10.680017486929223],
                    [76.815078737905736, 10.680853844555546],
                    [76.816627506372797, 10.681455603823769],
                    [76.817558272622477, 10.681831351225666],
                    [76.818481447719847, 10.681977274295841],
                    [76.81925200485064, 10.681817065176942],
                    [76.819938658467976, 10.681120863506866],
                    [76.820556629158759, 10.681038851588776],
                    [76.821479804256143, 10.681414598990669],
                    [76.821952811185824, 10.682253839048006],
                    [76.822639467055069, 10.682402615274505],
                    [76.822952262613953, 10.682630548010875],
                    [76.823509204583686, 10.683776852543559],
                    [76.823509204583686, 10.685004238959959],
                    [76.823669449732975, 10.685617435624376],
                    [76.823440553183744, 10.686616887052505],
                    [76.823158252844237, 10.689075472350703],
                    [76.822776803633488, 10.690229399562282],
                    [76.822547909336123, 10.691151615350583],
                    [76.821945188506945, 10.692385665136808],
                    [76.821327217816147, 10.693157181576668],
                    [76.820487975506921, 10.693778034698473],
                    [76.819801321889585, 10.694089904726781],
                    [76.819641110518774, 10.694321618401958],
                    [76.818870555639904, 10.6946363370828],
                    [76.81726838113066, 10.695878043326408],
                    [76.816192619593309, 10.697037706125331],
                    [76.8154296896452, 10.698271755911557],
                    [76.814132688015988, 10.69935703172318],
                    [76.813903791466743, 10.70027924525958],
                    [76.814376834426824, 10.701503749244964],
                    [76.815002427796514, 10.702342027741325],
                    [76.814765940094958, 10.702959070649632],
                    [76.814933774144649, 10.70357130575308],
                    [76.816329958423609, 10.70524693721522],
                    [76.816871650531738, 10.706316963165252],
                    [76.81710813823328, 10.706622136045251],
                    [76.817420967570683, 10.707541467150634],
                    [76.817199695952212, 10.708463680687037],
                    [76.816970833181486, 10.708771704471456],
                    [76.816741936632226, 10.709848393791315],
                    [76.81697845811226, 10.710460662673254],
                    [76.81745146504197, 10.710994729850592],
                    [76.818222055951225, 10.712291731479819],
                    [76.818382265070113, 10.712596902107915],
                    [76.818305954960891, 10.712828649561583],
                    [76.817840572961984, 10.712985048466978],
                    [76.817695611452791, 10.71367740614507],
                    [76.818473822789059, 10.714821819082399],
                    [76.818244928491708, 10.715131765988765],
                    [76.816711441412835, 10.716065414669462],
                    [76.815551744835446, 10.716534611385656],
                    [76.815322882064677, 10.716766358839322],
                    [76.814796437566244, 10.71769238484112],
                    [76.813873296247365, 10.718544951638103],
                    [76.813415536927366, 10.718778622213714],
                    [76.810806286059218, 10.720411291942327],
                    [76.810806286059218, 10.721025481694312],
                    [76.810577391761896, 10.721639639919697],
                    [76.810752884520852, 10.72378824678494],
                    [76.81060792075975, 10.72501755407138],
                    [76.810447677862371, 10.725326539416779],
                    [76.806289658527163, 10.726434721547379],
                    [76.805137620659053, 10.726366997929901],
                    [76.804443375889392, 10.726527207048798],
                    [76.802520747111998, 10.726465219018666],
                    [76.801589980862317, 10.726550113367777],
                    [76.80144501935311, 10.726704589151229],
                    [76.800994884963913, 10.728627217928629],
                    [76.800834639814653, 10.728858931603813],
                    [76.799911498495746, 10.728866588061198],
                    [76.799446114244958, 10.728716852525624],
                    [76.799057008576824, 10.727799413015592],
                    [76.798751835696834, 10.72757055024484],
                    [76.797752384268705, 10.728116982600858],
                    [76.796600346400567, 10.729046821068055],
                    [76.795753479160538, 10.729822149973311],
                    [76.798233016129188, 10.731105791084403],
                    [76.80008699125473, 10.731934557558416],
                    [76.80039216413472, 10.731932634436472],
                    [76.802406316852569, 10.732605932039467],
                    [76.803329458171476, 10.732904441549637],
                    [76.804870603959628, 10.733276376486137],
                    [76.806426997357462, 10.733723626192422],
                    [76.812675477267859, 10.735283865834166],
                    [76.813293447958657, 10.73535440657758],
                    [76.815528872294934, 10.735643404065399],
                    [76.816764842951201, 10.735939990453625],
                    [76.818161027230161, 10.736849773506274],
                    [76.818397514931732, 10.737461080827238],
                    [76.818176274839857, 10.738384255924609],
                    [76.818252551170616, 10.73869039036558],
                    [76.818099966982516, 10.738998411898102],
                    [76.817733765381476, 10.740920079114535],
                    [76.817733765381476, 10.74268531364708],
                    [76.818519603900427, 10.743906036693652],
                    [76.81867981301933, 10.744520194919046],
                    [76.818603505161974, 10.745594994895445],
                    [76.818847649320915, 10.747126592630867],
                    [76.818702687811708, 10.747435577976264],
                    [76.818702687811708, 10.747971534497056],
                    [76.818550101371713, 10.748587615844391],
                    [76.818634034159871, 10.749506944697877],
                    [76.818542476440925, 10.750021920273532],
                    [76.818183899770673, 10.750927924639271],
                    [76.817855820571694, 10.752073299137576],
                    [76.81745146504197, 10.753295913779501],
                    [76.817214979592293, 10.753982533618352],
                    [76.816505417404088, 10.754972473024127],
                    [76.816101061874363, 10.756117813743943],
                    [76.815933227824672, 10.757035255505876],
                    [76.815696706344639, 10.757569324935112],
                    [76.816001879224643, 10.757649413731263],
                    [76.816230775773874, 10.757882120494006],
                    [76.81621552591227, 10.758801451599394],
                    [76.816123968193338, 10.759874328453849],
                    [76.816184996914416, 10.760718342537555],
                    [76.815948475434368, 10.761405959967759],
                    [76.815246571955427, 10.76208780803214],
                    [76.812530515758667, 10.76297664166624],
                    [76.811912545067869, 10.763124454079868],
                    [76.8109893699705, 10.763113016683668],
                    [76.810440052931568, 10.763489725646544],
                    [76.809654248191094, 10.764709489384044],
                    [76.809089649763976, 10.76654052441212],
                    [76.808998123571627, 10.767307266825611],
                    [76.809150710011622, 10.767615324388522],
                    [76.8095321592224, 10.767849922746617],
                    [76.810691822021298, 10.767633422902641],
                    [76.811851484820224, 10.767646783420782],
                    [76.815315257133875, 10.76807023280409],
                    [76.815399158395422, 10.76843835527519],
                  ],
                ],
              ],
            },
          },
          {
            type: "Feature",
            properties: {
              AREA: 43257364.467900001,
              PERIMETER: 44332.079425199998,
              LOCATION: "Nallepilly",
              PANCHAYAT: "Nallepilly",
              BLOCK: "Chittur",
              MUNICIPALI: null,
              CORPORATIO: null,
              DISTRICT: "Palakkad",
              AEU_NEW: "Agro-ecological unit 23: Palakkad Eastern Plains",
              AEU_FINAL: 23,
            },
            geometry: {
              type: "MultiPolygon",
              coordinates: [
                [
                  [
                    [76.815696706344639, 10.757569324935112],
                    [76.815933227824672, 10.757035255505876],
                    [76.816101061874363, 10.756117813743943],
                    [76.816505417404088, 10.754972473024127],
                    [76.817214979592293, 10.753982533618352],
                    [76.81745146504197, 10.753295913779501],
                    [76.817855820571694, 10.752073299137576],
                    [76.818183899770673, 10.750927924639271],
                    [76.818542476440925, 10.750021920273532],
                    [76.818634034159871, 10.749506944697877],
                    [76.818550101371713, 10.748587615844391],
                    [76.818702687811708, 10.747971534497056],
                    [76.818702687811708, 10.747435577976264],
                    [76.818847649320915, 10.747126592630867],
                    [76.818603505161974, 10.745594994895445],
                    [76.81867981301933, 10.744520194919046],
                    [76.818519603900427, 10.743906036693652],
                    [76.817733765381476, 10.74268531364708],
                    [76.817733765381476, 10.740920079114535],
                    [76.818099966982516, 10.738998411898102],
                    [76.818252551170616, 10.73869039036558],
                    [76.818176274839857, 10.738384255924609],
                    [76.818397514931732, 10.737461080827238],
                    [76.818161027230161, 10.736849773506274],
                    [76.816764842951201, 10.735939990453625],
                    [76.815528872294934, 10.735643404065399],
                    [76.813293447958657, 10.73535440657758],
                    [76.812675477267859, 10.735283865834166],
                    [76.806426997357462, 10.733723626192422],
                    [76.804870603959628, 10.733276376486137],
                    [76.803329458171476, 10.732904441549637],
                    [76.802406316852569, 10.732605932039467],
                    [76.80039216413472, 10.731932634436472],
                    [76.80008699125473, 10.731934557558416],
                    [76.798233016129188, 10.731105791084403],
                    [76.795753479160538, 10.729822149973311],
                    [76.796600346400567, 10.729046821068055],
                    [76.797752384268705, 10.728116982600858],
                    [76.798751835696834, 10.72757055024484],
                    [76.799057008576824, 10.727799413015592],
                    [76.799446114244958, 10.728716852525624],
                    [76.799911498495746, 10.728866588061198],
                    [76.800834639814653, 10.728858931603813],
                    [76.800994884963913, 10.728627217928629],
                    [76.80144501935311, 10.726704589151229],
                    [76.801589980862317, 10.726550113367777],
                    [76.802520747111998, 10.726465219018666],
                    [76.804443375889392, 10.726527207048798],
                    [76.805137620659053, 10.726366997929901],
                    [76.806289658527163, 10.726434721547379],
                    [76.810447677862371, 10.725326539416779],
                    [76.81060792075975, 10.72501755407138],
                    [76.810752884520852, 10.72378824678494],
                    [76.810577391761896, 10.721639639919697],
                    [76.810806286059218, 10.721025481694312],
                    [76.810806286059218, 10.720411291942327],
                    [76.813415536927366, 10.718778622213714],
                    [76.813873296247365, 10.718544951638103],
                    [76.814796437566244, 10.71769238484112],
                    [76.815322882064677, 10.716766358839322],
                    [76.815551744835446, 10.716534611385656],
                    [76.816711441412835, 10.716065414669462],
                    [76.818244928491708, 10.715131765988765],
                    [76.818473822789059, 10.714821819082399],
                    [76.817695611452791, 10.71367740614507],
                    [76.817840572961984, 10.712985048466978],
                    [76.818305954960891, 10.712828649561583],
                    [76.818382265070113, 10.712596902107915],
                    [76.818222055951225, 10.712291731479819],
                    [76.81745146504197, 10.710994729850592],
                    [76.81697845811226, 10.710460662673254],
                    [76.816741936632226, 10.709848393791315],
                    [76.816970833181486, 10.708771704471456],
                    [76.817199695952212, 10.708463680687037],
                    [76.817420967570683, 10.707541467150634],
                    [76.81710813823328, 10.706622136045251],
                    [76.816871650531738, 10.706316963165252],
                    [76.816329958423609, 10.70524693721522],
                    [76.814933774144649, 10.70357130575308],
                    [76.814765940094958, 10.702959070649632],
                    [76.815002427796514, 10.702342027741325],
                    [76.814376834426824, 10.701503749244964],
                    [76.813903791466743, 10.70027924525958],
                    [76.814132688015988, 10.69935703172318],
                    [76.8154296896452, 10.698271755911557],
                    [76.816192619593309, 10.697037706125331],
                    [76.81726838113066, 10.695878043326408],
                    [76.818870555639904, 10.6946363370828],
                    [76.819641110518774, 10.694321618401958],
                    [76.819801321889585, 10.694089904726781],
                    [76.820487975506921, 10.693778034698473],
                    [76.821327217816147, 10.693157181576668],
                    [76.821945188506945, 10.692385665136808],
                    [76.822547909336123, 10.691151615350583],
                    [76.822776803633488, 10.690229399562282],
                    [76.823158252844237, 10.689075472350703],
                    [76.823440553183744, 10.686616887052505],
                    [76.823669449732975, 10.685617435624376],
                    [76.823509204583686, 10.685004238959959],
                    [76.823509204583686, 10.683776852543559],
                    [76.822952262613953, 10.682630548010875],
                    [76.822639467055069, 10.682402615274505],
                    [76.821952811185824, 10.682253839048006],
                    [76.821479804256143, 10.681414598990669],
                    [76.820556629158759, 10.681038851588776],
                    [76.819938658467976, 10.681120863506866],
                    [76.81925200485064, 10.681817065176942],
                    [76.818481447719847, 10.681977274295841],
                    [76.817558272622477, 10.681831351225666],
                    [76.816627506372797, 10.681455603823769],
                    [76.815078737905736, 10.680853844555546],
                    [76.814308180774972, 10.680017486929223],
                    [76.814071661546848, 10.67909716273628],
                    [76.813606277296032, 10.678565016428982],
                    [76.813064585187902, 10.678185456561685],
                    [76.81213378515973, 10.677962295599785],
                    [76.810668949480828, 10.677515045893502],
                    [76.810050978790045, 10.677136413808695],
                    [76.809280390132642, 10.676230443221444],
                    [76.808731073093711, 10.676302907086797],
                    [76.807350172454832, 10.676545130375688],
                    [76.807037343117472, 10.676547051245734],
                    [76.803955085319615, 10.676573738503521],
                    [76.802421564462264, 10.676586135208792],
                    [76.80064393322445, 10.676601416596972],
                    [76.800033553685978, 10.67675970484582],
                    [76.798187268796312, 10.67777347835306],
                    [76.797882064389725, 10.678158771555802],
                    [76.797576891509721, 10.679312701019283],
                    [76.797126757120523, 10.68131256543651],
                    [76.796829209171321, 10.682464603304638],
                    [76.796829209171321, 10.682927136651005],
                    [76.796150209759475, 10.684619905066292],
                    [76.796073901902133, 10.684927928850714],
                    [76.795616142582134, 10.685544971759022],
                    [76.793464651033986, 10.686407084356846],
                    [76.791618334617723, 10.686576843780379],
                    [76.790153498938821, 10.686895374926612],
                    [76.788314805201452, 10.687524780761697],
                    [76.786239626014449, 10.688693061578979],
                    [76.785308825986277, 10.689161296734198],
                    [76.783164993147409, 10.690484053334929],
                    [76.782936096598164, 10.690714839227628],
                    [76.781402575740799, 10.691495903720229],
                    [76.780395499381868, 10.691887860292786],
                    [76.778862012303009, 10.69213104514265],
                    [76.778244010085629, 10.692060468368853],
                    [76.772674558861766, 10.68918990486203],
                    [76.77151489606284, 10.68858622247186],
                    [76.769737231046548, 10.687295917990946],
                    [76.768806464796867, 10.686920170589044],
                    [76.767181386220557, 10.686090476332552],
                    [76.76602172342163, 10.685332284380442],
                    [76.763771051475658, 10.683662420032233],
                    [76.761764521436703, 10.682374968707645],
                    [76.760215752969643, 10.681620589220936],
                    [76.758056638742588, 10.681026452631606],
                    [76.756820665834411, 10.681342135125314],
                    [76.75598145730369, 10.681885716576906],
                    [76.755294803686354, 10.682659156138708],
                    [76.75360869638898, 10.684745772721914],
                    [76.75292207429824, 10.685749038867343],
                    [76.750701897571631, 10.687762261550811],
                    [76.749931342692761, 10.68792151136063],
                    [76.749626169812771, 10.687924396043549],
                    [76.748695369784599, 10.688393590507838],
                    [76.748008716167263, 10.68924237861791],
                    [76.747474680516504, 10.689631452759457],
                    [76.746864334756523, 10.689865123335069],
                    [76.745239256180199, 10.690219887539932],
                    [76.745330813899159, 10.69126034380116],
                    [76.745796195898052, 10.69294452797658],
                    [76.745727544498081, 10.693175313869276],
                    [76.745887755868878, 10.69432546239395],
                    [76.746276861537012, 10.695933336460126],
                    [76.746292111398617, 10.698003777651159],
                    [76.746070871306742, 10.699157704862742],
                    [76.745689390569396, 10.69985198566277],
                    [76.744079593381301, 10.701706888570804],
                    [76.743164074741301, 10.703095445667069],
                    [76.74156190248398, 10.70533372090779],
                    [76.741104109385503, 10.706258787600516],
                    [76.740264900854754, 10.708797461694866],
                    [76.740043629236311, 10.709720603013748],
                    [76.73987579518662, 10.711727130800808],
                    [76.740905777864526, 10.71163176061647],
                    [76.742134092063395, 10.711696633329524],
                    [76.743682860530456, 10.712070459861373],
                    [76.745376592758618, 10.712514892441719],
                    [76.746696466928341, 10.713501944912682],
                    [76.74693298615648, 10.7133455144807],
                    [76.746925361225664, 10.712348945483582],
                    [76.747451774197515, 10.710733414960021],
                    [76.748214737924116, 10.710419655588256],
                    [76.749145504173796, 10.710488309240112],
                    [76.75029754204192, 10.710556032857587],
                    [76.751152031960828, 10.710010526032157],
                    [76.752998350628999, 10.709996239983434],
                    [76.754226698606359, 10.709525122397203],
                    [76.756690985713405, 10.708889947196287],
                    [76.759147647889662, 10.707642570670412],
                    [76.759765618580445, 10.707790385335942],
                    [76.760696418608617, 10.708703980853985],
                    [76.761245735647549, 10.709545144033262],
                    [76.761863704086437, 10.709845542886889],
                    [76.762168876966442, 10.709843619764944],
                    [76.763092052063811, 10.709297187408927],
                    [76.764633164073487, 10.708746908809029],
                    [76.764778125582694, 10.708823218918271],
                    [76.764785750513468, 10.708976733140751],
                    [76.764099128422743, 10.709903720703528],
                    [76.762794504114609, 10.711065304372495],
                    [76.762489331234619, 10.711528765501342],
                    [76.762413021125369, 10.711835861503278],
                    [76.763122549535083, 10.714284898748739],
                    [76.763359071015131, 10.714513761519489],
                    [76.764045724632453, 10.714584338293291],
                    [76.76466369532325, 10.714349739935196],
                    [76.765739456860615, 10.713496211577242],
                    [76.766052254671408, 10.713494288455298],
                    [76.766273492511374, 10.713262541001628],
                    [76.767204292539532, 10.713484774181044],
                    [76.76782226097842, 10.713862410926399],
                    [76.768371578017351, 10.714702614796604],
                    [76.768371578017351, 10.715621943650088],
                    [76.768989548708149, 10.716463075302778],
                    [76.770233144295204, 10.717064868349491],
                    [76.770545975884488, 10.71737004122949],
                    [76.772010811563405, 10.717970838936742],
                    [76.772171020682308, 10.71965883557756],
                    [76.772056590422878, 10.720188130980432],
                    [76.771499648453158, 10.722732538410218],
                    [76.771423338343908, 10.72303963441216],
                    [76.771659859823941, 10.723499283075608],
                    [76.772506724812075, 10.723415381814057],
                    [76.772895798953627, 10.723795903242328],
                    [76.773132318181766, 10.724638924238464],
                    [76.773368839661799, 10.72486685922674],
                    [76.773681635220697, 10.726015084629465],
                    [76.773689260151485, 10.727242471045862],
                    [76.77353667371149, 10.727858550141303],
                    [76.773315435871538, 10.728166573925725],
                    [76.772079462963362, 10.72917461184562],
                    [76.771614078712574, 10.729409210203713],
                    [76.771171569254179, 10.731254565059002],
                    [76.771247879363415, 10.731867795501913],
                    [76.770645124755731, 10.732792859942743],
                    [76.770721434864981, 10.733098994383708],
                    [76.771026607744972, 10.733326895593487],
                    [76.771568299853115, 10.733245845236368],
                    [76.772186270543898, 10.733470929320204],
                    [76.774040211890949, 10.733455647932026],
                    [76.774269108440194, 10.733607306589541],
                    [76.774345384770953, 10.73406695525299],
                    [76.775039663319077, 10.734137529774891],
                    [76.776206984827297, 10.734664902055815],
                    [76.777359022695421, 10.734886173674262],
                    [76.778289788945102, 10.734953863513246],
                    [76.779518136922462, 10.73440743115723],
                    [76.779823309802453, 10.734481818144534],
                    [76.780059829030591, 10.734863267355282],
                    [76.780914318949513, 10.735700618069165],
                    [76.780914318949513, 10.736161228293582],
                    [76.780685424652177, 10.736776348079944],
                    [76.780151355222941, 10.737087254295382],
                    [76.779685970972153, 10.737014756651538],
                    [76.779373175413269, 10.736556999583444],
                    [76.778762829653274, 10.736639009249643],
                    [76.77829741387589, 10.736412997383315],
                    [76.777481077885639, 10.736051569808627],
                    [76.777603133075857, 10.737186436945116],
                    [76.777999865926702, 10.737796782705111],
                    [76.778617868144067, 10.738405239121647],
                    [76.778533933104029, 10.737331400706216],
                    [76.779388423022951, 10.73786258545254],
                    [76.779693595902955, 10.737859734548113],
                    [76.779930117382975, 10.738547315947935],
                    [76.778770454584063, 10.739018435786067],
                    [76.778465281704072, 10.73932741887956],
                    [76.778160108824082, 10.739944461787866],
                    [76.778015147314875, 10.74055861776136],
                    [76.777946462136427, 10.741787895773109],
                    [76.778022772245677, 10.742094989523151],
                    [76.778251635016431, 10.74232196295045],
                    [76.778640740684565, 10.742395388376778],
                    [76.781105027791597, 10.74183844640705],
                    [76.783264175797143, 10.741819386331956],
                    [76.784194942046824, 10.741965309402129],
                    [76.784500114926814, 10.741579054638422],
                    [76.784492489996026, 10.740427976079369],
                    [76.784713730087873, 10.739274976650268],
                    [76.784629828826326, 10.738585472128504],
                    [76.78440093227708, 10.738280299248512],
                    [76.783630375146302, 10.738210686287582],
                    [76.783241269478154, 10.737906441190065],
                    [76.783164993147409, 10.737524030418342],
                    [76.783309920878111, 10.73706245863295],
                    [76.783927925347399, 10.736749663074059],
                    [76.785545345214416, 10.73642920880588],
                    [76.78608703957444, 10.736808768673177],
                    [76.786170972362584, 10.737268448863214],
                    [76.786178597293386, 10.739339817836731],
                    [76.786338808664183, 10.739798538717693],
                    [76.786796567984169, 10.739719377704024],
                    [76.787178048721515, 10.739253993453232],
                    [76.787796019412298, 10.739173904657083],
                    [76.788421646560479, 10.739321717070705],
                    [76.788574233000475, 10.739473341949735],
                    [76.788421646560479, 10.740013113187826],
                    [76.788192750011234, 10.740321170750741],
                    [76.786811815593865, 10.742020602535847],
                    [76.784965530704198, 10.742571842696725],
                    [76.784820569194991, 10.743188851826542],
                    [76.784828194125794, 10.745029434907359],
                    [76.784988403244682, 10.745642663098367],
                    [76.78545378749547, 10.746099460857394],
                    [76.78715514240254, 10.746776568673891],
                    [76.787857045881466, 10.747920983863118],
                    [76.787704461693366, 10.748843197399522],
                    [76.787940980921505, 10.749071132387794],
                    [76.788322463910745, 10.74914455781412],
                    [76.788482673029634, 10.749373420584874],
                    [76.788330086589639, 10.749835023896853],
                    [76.785560595076007, 10.749883653660746],
                    [76.785713181516002, 10.750481602715469],
                    [76.786239626014449, 10.750946984714364],
                    [76.786705008013342, 10.751184467755374],
                    [76.788002009642568, 10.751811022686031],
                    [76.788307182522558, 10.752428063342439],
                    [76.78858948061017, 10.753812776446717],
                    [76.788749691980954, 10.754043596117899],
                    [76.789207451300953, 10.754278194475997],
                    [76.79036711409988, 10.753985418301262],
                    [76.791526808425374, 10.753922468710162],
                    [76.792449951996161, 10.754009252402726],
                    [76.794525131183178, 10.754492768946127],
                    [76.795677169051302, 10.754582405795016],
                    [76.797386182667637, 10.754141817206662],
                    [76.797843941987637, 10.754376415564758],
                    [76.799400335385499, 10.753474223664417],
                    [76.800399786813628, 10.753562930478928],
                    [76.800559998184411, 10.753334033929686],
                    [76.801948557532583, 10.753043183128803],
                    [76.802795422520717, 10.753436101262334],
                    [76.803871150279591, 10.753678322299326],
                    [76.805252084696946, 10.754001629723833],
                    [76.806404124816979, 10.754473675092546],
                    [76.806945816925108, 10.754709235011616],
                    [76.807464602714262, 10.75555991246514],
                    [76.807762152915359, 10.756789222003485],
                    [76.80812072733373, 10.758788124859745],
                    [76.808418277534827, 10.759480482537834],
                    [76.808876034602918, 10.759792316535755],
                    [76.809104931152163, 10.759795201218669],
                    [76.810729978201877, 10.759276381651022],
                    [76.812126160228956, 10.759138115038235],
                    [76.8130569602571, 10.758842456432488],
                    [76.814453110757583, 10.758014685297937],
                    [76.815696706344639, 10.757569324935112],
                  ],
                ],
              ],
            },
          },
          {
            type: "Feature",
            properties: {
              AREA: 11390769.569599999,
              PERIMETER: 20410.7488954,
              LOCATION: "Chittur-Thathamangalam MC",
              PANCHAYAT: null,
              BLOCK: null,
              MUNICIPALI: "Chittur-Thathamangalam",
              CORPORATIO: null,
              DISTRICT: "Palakkad",
              AEU_NEW: "Agro-ecological unit 23: Palakkad Eastern Plains",
              AEU_FINAL: 23,
            },
            geometry: {
              type: "MultiPolygon",
              coordinates: [
                [
                  [
                    [76.724945077256649, 10.700643523738693],
                    [76.725036634975595, 10.700790408369842],
                    [76.725120536237142, 10.701174742263508],
                    [76.725196846346364, 10.701787938927929],
                    [76.725433365574503, 10.702014912355221],
                    [76.725662228345271, 10.702013020759869],
                    [76.726196297774493, 10.701624876652705],
                    [76.726585403442655, 10.701698302079034],
                    [76.726982102514981, 10.702923765373487],
                    [76.727127064024188, 10.703228938253485],
                    [76.727439893361577, 10.703456873241755],
                    [76.728134140383119, 10.703759195217323],
                    [76.72968290885018, 10.704435341472848],
                    [76.730300913319454, 10.704584115447449],
                    [76.732772823105407, 10.705560694335084],
                    [76.733077995985383, 10.705634119761415],
                    [76.734237658784309, 10.705625535521545],
                    [76.734931939584342, 10.705005643960716],
                    [76.735626218132467, 10.705153456374338],
                    [76.735702528241703, 10.705535898672649],
                    [76.734710699492481, 10.707002623695027],
                    [76.734954845903317, 10.709762569407824],
                    [76.735046369843758, 10.710759172183426],
                    [76.735351576502254, 10.711984635477883],
                    [76.735588064203782, 10.71221257046615],
                    [76.736900344969314, 10.71204854662996],
                    [76.73905180273897, 10.711800590005621],
                    [76.73987579518662, 10.711727130800808],
                    [76.740043629236311, 10.709720603013748],
                    [76.740264900854754, 10.708797461694866],
                    [76.741104109385503, 10.706258787600516],
                    [76.74156190248398, 10.70533372090779],
                    [76.743164074741301, 10.703095445667069],
                    [76.744079593381301, 10.701706888570804],
                    [76.745689390569396, 10.69985198566277],
                    [76.746070871306742, 10.699157704862742],
                    [76.746292111398617, 10.698003777651159],
                    [76.746276861537012, 10.695933336460126],
                    [76.745887755868878, 10.69432546239395],
                    [76.745727544498081, 10.693175313869276],
                    [76.745796195898052, 10.69294452797658],
                    [76.745330813899159, 10.69126034380116],
                    [76.745239256180199, 10.690219887539932],
                    [76.745239256180199, 10.690187466946698],
                    [76.743858321762843, 10.689814570449233],
                    [76.743392937512056, 10.68966483491366],
                    [76.741531371234188, 10.687914847990809],
                    [76.740837092686078, 10.687538139027936],
                    [76.73905180273897, 10.686094288797952],
                    [76.736968998621165, 10.685037621114157],
                    [76.735572814342206, 10.684589376068418],
                    [76.734420776474067, 10.684370025320018],
                    [76.731567381446993, 10.683932289887984],
                    [76.730873102898883, 10.683860785331699],
                    [76.729484543550697, 10.683795912618653],
                    [76.729019161551818, 10.683569900752321],
                    [76.728706363741026, 10.682881357791537],
                    [76.728385909472834, 10.680965426162444],
                    [76.727920527473955, 10.680049907522456],
                    [76.726524343194995, 10.678756720610524],
                    [76.726287855493439, 10.677607531394923],
                    [76.724884048535571, 10.675546638256634],
                    [76.724037183547466, 10.67494010496204],
                    [76.722724900530054, 10.67395401405205],
                    [76.721801759211161, 10.674269660515369],
                    [76.719024674293323, 10.674676900728015],
                    [76.717102047767824, 10.674769386229432],
                    [76.715484625648912, 10.674859056856812],
                    [76.713478097861852, 10.675335876251902],
                    [76.712333682672622, 10.6761131282791],
                    [76.710716262805605, 10.67720221430422],
                    [76.709335328388235, 10.678209290663151],
                    [76.707649221090875, 10.679375650610385],
                    [76.706497183222766, 10.680381765408338],
                    [76.706268320451997, 10.680460926422009],
                    [76.70520018159354, 10.681544312890175],
                    [76.703346240246461, 10.682478891605257],
                    [76.701889029498361, 10.68310640809689],
                    [76.699584953762098, 10.68389320592493],
                    [76.698432913642066, 10.684133539870365],
                    [76.697273253095048, 10.684449186333687],
                    [76.696289049276601, 10.684458734386428],
                    [76.696357734455049, 10.685837745681852],
                    [76.696594253683188, 10.686450012311886],
                    [76.696594253683188, 10.686756146752856],
                    [76.69644163571661, 10.686912545658252],
                    [76.696212772945842, 10.686989783549976],
                    [76.694976800037679, 10.686616887052507],
                    [76.694282521489569, 10.686928754828921],
                    [76.692665099370657, 10.687173829022235],
                    [76.691970818570624, 10.687485696798646],
                    [76.691360475062524, 10.688181866942129],
                    [76.691284164953288, 10.688719746584871],
                    [76.691833481992219, 10.689175616561407],
                    [76.692451484209585, 10.689247118865792],
                    [76.693069454900353, 10.689624789389635],
                    [76.693611147008525, 10.691308973565055],
                    [76.694465636927447, 10.691838268967922],
                    [76.695541366938215, 10.691753374618813],
                    [76.696159369155581, 10.692438138892694],
                    [76.696548474823715, 10.692435256461678],
                    [76.697395339811848, 10.69181443486646],
                    [76.698013310502645, 10.691578874947394],
                    [76.698158272011838, 10.691808699279116],
                    [76.698402416170779, 10.69295692693375],
                    [76.698257454661572, 10.694185241132633],
                    [76.698410041101582, 10.694414137681871],
                    [76.698715213981572, 10.694564834778415],
                    [76.700111398260532, 10.694860459605671],
                    [76.701026914648637, 10.694853796235847],
                    [76.702270512487573, 10.695072185423275],
                    [76.702499375258327, 10.694764127860365],
                    [76.703346240246461, 10.694295892705149],
                    [76.703811656023859, 10.695596706799774],
                    [76.703514108074643, 10.69652080967963],
                    [76.702896105857263, 10.697752936343912],
                    [76.701599106479946, 10.699221618266719],
                    [76.701293933599956, 10.699838625144636],
                    [76.701370241457283, 10.700144759585605],
                    [76.701606762937317, 10.700371733012901],
                    [76.702301009958873, 10.700674054988472],
                    [76.704147326375136, 10.700888629458602],
                    [76.704460157964405, 10.701039326555144],
                    [76.705001816294072, 10.70080376888798],
                    [76.704917915032496, 10.700267776336798],
                    [76.705520635861717, 10.697960849696116],
                    [76.705444328004347, 10.697502128815152],
                    [76.705825808741693, 10.696578025935297],
                    [76.706207291730948, 10.696496975578169],
                    [76.707283019489836, 10.696795451309852],
                    [76.708213819517979, 10.696787826379055],
                    [76.708671578837979, 10.697013840497281],
                    [76.708831790208791, 10.697166458463872],
                    [76.70883941288767, 10.69778061668926],
                    [76.708686828699584, 10.698395734223723],
                    [76.709152210698463, 10.698775294091018],
                    [76.709915176676958, 10.698615084972122],
                    [76.710220347305039, 10.698306099626731],
                    [76.711296077315836, 10.696532247075824],
                    [76.712059007263917, 10.696448312035786],
                    [76.712989807292104, 10.697285662749666],
                    [76.713920607320247, 10.697737688734216],
                    [76.714538578011044, 10.697655676816121],
                    [76.714767440781785, 10.697500239471697],
                    [76.715682990948352, 10.6961126416845],
                    [76.716911305147249, 10.695027365872877],
                    [76.717903133896471, 10.694098455188165],
                    [76.718749998884618, 10.69270992961849],
                    [76.719200133273816, 10.691325184987619],
                    [76.719886789143047, 10.69024468320236],
                    [76.720352171141926, 10.690087322735996],
                    [76.721817006820856, 10.691072454336918],
                    [76.722518910299783, 10.691374776312488],
                    [76.723365777539811, 10.691980348046107],
                    [76.723983779757177, 10.69243621577075],
                    [76.724288952637181, 10.692510605009948],
                    [76.725296028996112, 10.693115246709187],
                    [76.726158143845836, 10.693952565896476],
                    [76.726158143845836, 10.694490447791111],
                    [76.725929247296605, 10.695105565325576],
                    [76.725471487976606, 10.695721612894422],
                    [76.724624622988472, 10.696267117467954],
                    [76.724243173777708, 10.696884158124362],
                    [76.724327072787375, 10.69780445079072],
                    [76.724876392078201, 10.698720928739782],
                    [76.724639904376659, 10.699643144528086],
                    [76.724647529307447, 10.700180992644233],
                    [76.724945077256649, 10.700643523738693],
                  ],
                ],
              ],
            },
          },
          {
            type: "Feature",
            properties: {
              AREA: 60302013.945900001,
              PERIMETER: 46956.7734136,
              LOCATION: "Perumatty",
              PANCHAYAT: "Perumatty",
              BLOCK: "Chittur",
              MUNICIPALI: null,
              CORPORATIO: null,
              DISTRICT: "Palakkad",
              AEU_NEW: "Agro-ecological unit 23: Palakkad Eastern Plains",
              AEU_FINAL: 23,
            },
            geometry: {
              type: "MultiPolygon",
              coordinates: [
                [
                  [
                    [76.745239256180199, 10.690219887539932],
                    [76.746864334756523, 10.689865123335069],
                    [76.747474680516504, 10.689631452759457],
                    [76.748008716167263, 10.68924237861791],
                    [76.748695369784599, 10.688393590507838],
                    [76.749626169812771, 10.687924396043549],
                    [76.749931342692761, 10.68792151136063],
                    [76.750701897571631, 10.687762261550811],
                    [76.75292207429824, 10.685749038867343],
                    [76.75360869638898, 10.684745772721914],
                    [76.755294803686354, 10.682659156138708],
                    [76.75598145730369, 10.681885716576906],
                    [76.756820665834411, 10.681342135125314],
                    [76.758056638742588, 10.681026452631606],
                    [76.760215752969643, 10.681620589220936],
                    [76.761764521436703, 10.682374968707645],
                    [76.763771051475658, 10.683662420032233],
                    [76.76602172342163, 10.685332284380442],
                    [76.767181386220557, 10.686090476332552],
                    [76.768806464796867, 10.686920170589044],
                    [76.769737231046548, 10.687295917990946],
                    [76.77151489606284, 10.68858622247186],
                    [76.772674558861766, 10.68918990486203],
                    [76.778244010085629, 10.692060468368853],
                    [76.778862012303009, 10.69213104514265],
                    [76.780395499381868, 10.691887860292786],
                    [76.781402575740799, 10.691495903720229],
                    [76.782936096598164, 10.690714839227628],
                    [76.783164993147409, 10.690484053334929],
                    [76.785308825986277, 10.689161296734198],
                    [76.786239626014449, 10.688693061578979],
                    [76.788314805201452, 10.687524780761697],
                    [76.790153498938821, 10.686895374926612],
                    [76.791618334617723, 10.686576843780379],
                    [76.793464651033986, 10.686407084356846],
                    [76.795616142582134, 10.685544971759022],
                    [76.796073901902133, 10.684927928850714],
                    [76.796150209759475, 10.684619905066292],
                    [76.796829209171321, 10.682927136651005],
                    [76.796829209171321, 10.682464603304638],
                    [76.797126757120523, 10.68131256543651],
                    [76.797576891509721, 10.679312701019283],
                    [76.797882064389725, 10.678158771555802],
                    [76.798187268796312, 10.67777347835306],
                    [76.800033553685978, 10.67675970484582],
                    [76.80064393322445, 10.676601416596972],
                    [76.802421564462264, 10.676586135208792],
                    [76.803955085319615, 10.676573738503521],
                    [76.807037343117472, 10.676547051245734],
                    [76.807350172454832, 10.676545130375688],
                    [76.808731073093711, 10.676302907086797],
                    [76.809280390132642, 10.676230443221444],
                    [76.80934907531109, 10.676220895168699],
                    [76.810951249820334, 10.673752797848154],
                    [76.811477660540277, 10.672136303511721],
                    [76.811775208489465, 10.670063011416262],
                    [76.812080381369469, 10.669598590978346],
                    [76.813453657077545, 10.668205257603809],
                    [76.813758863736055, 10.668049820259387],
                    [76.815917977963068, 10.667263983992314],
                    [76.816833496603081, 10.667027462512277],
                    [76.818069469511229, 10.666939748785326],
                    [76.818992610830122, 10.666931164545458],
                    [76.819305408640915, 10.667081827863512],
                    [76.82147217932534, 10.668828961630041],
                    [76.822090150016137, 10.669131283605608],
                    [76.823020950044295, 10.669352523697464],
                    [76.824554437123183, 10.668573382326811],
                    [76.825012196443168, 10.668032649527747],
                    [76.825393677180514, 10.667031274977674],
                    [76.826309195820485, 10.665105829074333],
                    [76.827293399638933, 10.663715348856128],
                    [76.828140264627066, 10.663247113700907],
                    [76.828758235317849, 10.663166993378164],
                    [76.829376237535229, 10.663620940232761],
                    [76.829528823975224, 10.663927072421831],
                    [76.830924972223784, 10.665219300024686],
                    [76.831626877954619, 10.666441912414719],
                    [76.83232878143356, 10.667280193162979],
                    [76.834945686507183, 10.667949676048677],
                    [76.837722771425021, 10.667849531837975],
                    [76.840339644972062, 10.667750349188237],
                    [76.841270445000234, 10.667664527056646],
                    [76.842964174976501, 10.667574890207751],
                    [76.845428462083532, 10.66740132057072],
                    [76.849433894978731, 10.666753782443028],
                    [76.849739067858735, 10.66675185932108],
                    [76.851585386526907, 10.666658412258693],
                    [76.852508561624276, 10.666728025219619],
                    [76.853897087193928, 10.667098998595147],
                    [76.854827884970206, 10.66709137591625],
                    [76.855751028540993, 10.667314536878152],
                    [76.856369030758373, 10.667614935731779],
                    [76.857452383448035, 10.668374055466368],
                    [76.858230597036211, 10.668981548070036],
                    [76.858619704956254, 10.669514655938302],
                    [76.858856190405902, 10.670127852602722],
                    [76.859169021995186, 10.670892705672758],
                    [76.859481817554084, 10.671887385326412],
                    [76.860115035854548, 10.673109997716447],
                    [76.861579905311956, 10.674172400987572],
                    [76.862510671561637, 10.674625386281202],
                    [76.863433846659007, 10.674617761350405],
                    [76.86466216085789, 10.674375540313413],
                    [76.865585338207168, 10.673830995048952],
                    [76.866127030315297, 10.673366540832545],
                    [76.866653441035254, 10.67267133450193],
                    [76.867034924024495, 10.671901705153623],
                    [76.867095952745558, 10.670519842953773],
                    [76.866767873546578, 10.667298325455583],
                    [76.866989145165022, 10.665301311942784],
                    [76.86682127733684, 10.663000118637546],
                    [76.866958613915244, 10.662077905101141],
                    [76.867340096904485, 10.661384588113984],
                    [76.868255613292575, 10.65991782931312],
                    [76.86886595905257, 10.65929985862233],
                    [76.869560239852589, 10.659063339394192],
                    [76.870719900399607, 10.659283649451664],
                    [76.871650700427779, 10.659813872637015],
                    [76.872116084678566, 10.660730352837975],
                    [76.872741711826748, 10.662260061229945],
                    [76.873527516567222, 10.663788808060938],
                    [76.874313352834292, 10.665854441447117],
                    [76.874862672125133, 10.667078015398113],
                    [76.875488265494809, 10.668837548121807],
                    [76.875953681272193, 10.669754028322766],
                    [76.876655584751106, 10.670515068927402],
                    [76.877197279111158, 10.670818318685455],
                    [76.877815247550032, 10.670966133350982],
                    [76.87900543212865, 10.670797348022454],
                    [76.878936767577869, 10.670177459716788],
                    [76.87888336181615, 10.669729232788074],
                    [76.878723144530994, 10.668503761291491],
                    [76.878791792659186, 10.667734140017574],
                    [76.878868103027088, 10.667120933532699],
                    [76.87939454726687, 10.665197389045169],
                    [76.879852306586855, 10.66427232460434],
                    [76.880378717306812, 10.663806906575056],
                    [76.880920410155994, 10.663265228271472],
                    [76.880996704101307, 10.662959098815906],
                    [76.881828305376033, 10.661645903004553],
                    [76.882057199673383, 10.660723689468151],
                    [76.882347091165187, 10.65910911825366],
                    [76.882347091165187, 10.658802983812693],
                    [76.882034301757557, 10.658189773559554],
                    [76.882034293354394, 10.65711687651533],
                    [76.881950378417713, 10.656503677368148],
                    [76.882164007253905, 10.654737486009287],
                    [76.882240283584665, 10.654122366222927],
                    [76.88300324956316, 10.653194417099183],
                    [76.8840713500974, 10.652341842651349],
                    [76.884216316152333, 10.651419636765796],
                    [76.884811401366917, 10.647960662841776],
                    [76.88488006345068, 10.647269242361386],
                    [76.884727478027074, 10.646963119506816],
                    [76.883796691894275, 10.646433830261211],
                    [76.88340760509287, 10.645899776866797],
                    [76.883628842932822, 10.6447467774377],
                    [76.883552551269275, 10.643825531005836],
                    [76.883132934570057, 10.640069961547828],
                    [76.883132929684166, 10.63930226833887],
                    [76.882896408204118, 10.639151571242326],
                    [76.882583618163807, 10.639228820800758],
                    [76.881355285644275, 10.639010429382303],
                    [76.878265380859119, 10.638422012329078],
                    [76.877960209059225, 10.638346672648268],
                    [76.876647962072212, 10.638129245021808],
                    [76.874565121924022, 10.637378680252398],
                    [76.873321533202883, 10.636775016784645],
                    [76.872940077126216, 10.636548985995899],
                    [76.872863769531008, 10.63624191284177],
                    [76.871002200739113, 10.634570136302294],
                    [76.870452883700182, 10.634189614874032],
                    [76.86929322090127, 10.633278838733821],
                    [76.867980957031023, 10.632984161376928],
                    [76.867057796564978, 10.63283728858449],
                    [76.865669250488054, 10.632695198059055],
                    [76.864746095897928, 10.632626526579756],
                    [76.863662719726335, 10.632328987121557],
                    [76.862434387206804, 10.632108688354469],
                    [76.861579905311956, 10.632116325030468],
                    [76.8606567302146, 10.631970401960295],
                    [76.859657278786472, 10.632132534201135],
                    [76.859046936034943, 10.632061004638651],
                    [76.8578109601183, 10.632301329811801],
                    [76.857658373678305, 10.632534038826437],
                    [76.857040405239403, 10.632768637184535],
                    [76.856124886599432, 10.633544927650762],
                    [76.855209334180955, 10.634012232771598],
                    [76.854049673633924, 10.634482391048756],
                    [76.853439331054474, 10.634564399719212],
                    [76.852203354965781, 10.634881010991146],
                    [76.85189818208579, 10.634883895674061],
                    [76.850280759966878, 10.635204316163751],
                    [76.846511848551685, 10.635390284757952],
                    [76.845588673454316, 10.635474217546092],
                    [76.844505320764654, 10.635099397926679],
                    [76.843887329101364, 10.635027885436989],
                    [76.843582145667284, 10.634799960634023],
                    [76.84241485793757, 10.634349826244824],
                    [76.8414916828402, 10.633896840951202],
                    [76.84087371826152, 10.634056091308571],
                    [76.840263368641303, 10.633907316786424],
                    [76.839027404784972, 10.634378433227516],
                    [76.838722229003707, 10.634534835815407],
                    [76.837028503417784, 10.635162353515602],
                    [76.835342417106119, 10.635714517713039],
                    [76.834724414888726, 10.635796561157724],
                    [76.833267204140611, 10.63626957033931],
                    [76.832962031260621, 10.636424077649355],
                    [76.831581130621743, 10.637050632580012],
                    [76.831268301284354, 10.636977207153684],
                    [76.829879775714701, 10.636527072764485],
                    [76.829833984374815, 10.636501312255835],
                    [76.829832716964432, 10.63650061744336],
                    [76.82575225385078, 10.640398979294792],
                    [76.824905388862632, 10.641557680532742],
                    [76.82299804372532, 10.643722530347137],
                    [76.822227486594556, 10.644495969908943],
                    [76.821617140834547, 10.644885077828977],
                    [76.82115934998798, 10.645195952517824],
                    [76.820930487217211, 10.645428659280567],
                    [76.820007312119827, 10.645895966653303],
                    [76.819389341429044, 10.646054252650254],
                    [76.81846620011018, 10.64590932491954],
                    [76.818153368520882, 10.64598845215472],
                    [76.817237852132806, 10.646533956728252],
                    [76.816314708562004, 10.646771439769262],
                    [76.815383908533832, 10.646471968698117],
                    [76.814231870665722, 10.646558752390684],
                    [76.813621527157636, 10.646639836526301],
                    [76.810852067170586, 10.647124280852182],
                    [76.810234062701312, 10.647283562188594],
                    [76.80831146995429, 10.647684105252925],
                    [76.806770324166138, 10.647313131877395],
                    [76.804840072709837, 10.647635475489031],
                    [76.803306585630949, 10.648107523109646],
                    [76.800529466934634, 10.648363104664776],
                    [76.79937742906651, 10.648448960574859],
                    [76.798759460627636, 10.648377424491985],
                    [76.798141489936853, 10.648535712740834],
                    [76.796920766890267, 10.649084101997284],
                    [76.79668427918871, 10.649314887889984],
                    [76.795768729022129, 10.649784082354271],
                    [76.795150758331346, 10.649865166489882],
                    [76.793220506875045, 10.64988137566055],
                    [76.791992192676162, 10.649814613604047],
                    [76.791374221985379, 10.649973863413868],
                    [76.789833078449135, 10.6503705940128],
                    [76.788909903351751, 10.650531764692666],
                    [76.787139894792844, 10.650316228661564],
                    [76.786827096982051, 10.650242803235237],
                    [76.784133913325761, 10.64957427965861],
                    [76.781662003539807, 10.648596772988489],
                    [76.779808059940862, 10.647385595742753],
                    [76.778488154244556, 10.646629329164487],
                    [76.776634212897505, 10.645954142218036],
                    [76.775321963658584, 10.645735755282507],
                    [76.774398788561214, 10.645665180760608],
                    [76.771629328574164, 10.64591980075476],
                    [76.767929068558956, 10.645719512333352],
                    [76.767311100120068, 10.645420075040697],
                    [76.767005927240078, 10.645421034349768],
                    [76.76600647356004, 10.645659445173262],
                    [76.764770502903772, 10.646130562759497],
                    [76.763473501274561, 10.647369386572084],
                    [76.762245187075678, 10.648146638599291],
                    [76.761322011978308, 10.648538595171853],
                    [76.760398870659415, 10.648699768103617],
                    [76.759170522682055, 10.648633967608088],
                    [76.757621751963086, 10.648798950753353],
                    [76.756088264884212, 10.649196609134767],
                    [76.754158013427897, 10.649519916559274],
                    [76.753852840547921, 10.649599075321044],
                    [76.751701351251668, 10.65000154150732],
                    [76.74993896762355, 10.650859843891643],
                    [76.749855032583511, 10.650860771674129],
                    [76.748626718384642, 10.65186788181154],
                    [76.746490476698071, 10.654725087052125],
                    [76.745727544498081, 10.655881867420034],
                    [76.744735718000754, 10.657119761198244],
                    [76.743736264320731, 10.65827846243619],
                    [76.74282074793264, 10.659129139889725],
                    [76.742286678503405, 10.659594524140513],
                    [76.741973880692612, 10.659674646715159],
                    [76.740516669944483, 10.660376550194083],
                    [76.739288321967109, 10.660846706219347],
                    [76.736976621300059, 10.661480919859292],
                    [76.73605347998118, 10.66179560476164],
                    [76.733978267015672, 10.662887577721573],
                    [76.733139024706446, 10.663508430843377],
                    [76.731376641078342, 10.664980891453096],
                    [76.730453499759449, 10.665909768359324],
                    [76.729080188020973, 10.667455685921956],
                    [76.728469844512887, 10.668151853813546],
                    [76.726860047324763, 10.6700839968652],
                    [76.726249701564782, 10.67054845108161],
                    [76.724250796456616, 10.672100070453094],
                    [76.722724900530054, 10.67395401405205],
                    [76.724037183547466, 10.67494010496204],
                    [76.724884048535571, 10.675546638256634],
                    [76.726287855493439, 10.677607531394923],
                    [76.726524343194995, 10.678756720610524],
                    [76.727920527473955, 10.680049907522456],
                    [76.728385909472834, 10.680965426162444],
                    [76.728706363741026, 10.682881357791537],
                    [76.729019161551818, 10.683569900752321],
                    [76.729484543550697, 10.683795912618653],
                    [76.730873102898883, 10.683860785331699],
                    [76.731567381446993, 10.683932289887984],
                    [76.734420776474067, 10.684370025320018],
                    [76.735572814342206, 10.684589376068418],
                    [76.736968998621165, 10.685037621114157],
                    [76.73905180273897, 10.686094288797952],
                    [76.740837092686078, 10.687538139027936],
                    [76.741531371234188, 10.687914847990809],
                    [76.743392937512056, 10.68966483491366],
                    [76.743858321762843, 10.689814570449233],
                    [76.745239256180199, 10.690187466946698],
                    [76.745239256180199, 10.690219887539932],
                  ],
                ],
              ],
            },
          },
          {
            type: "Feature",
            properties: {
              AREA: 31592316.929900002,
              PERIMETER: 41747.869149400001,
              LOCATION: "Pattanchery",
              PANCHAYAT: "Pattencherry",
              BLOCK: "Chittur",
              MUNICIPALI: null,
              CORPORATIO: null,
              DISTRICT: "Palakkad",
              AEU_NEW: "Agro-ecological unit 23: Palakkad Eastern Plains",
              AEU_FINAL: 23,
            },
            geometry: {
              type: "MultiPolygon",
              coordinates: [
                [
                  [
                    [76.695945753994522, 10.681817992959427],
                    [76.696266176736117, 10.68300056207733],
                    [76.696273799415025, 10.684227948493728],
                    [76.696289049276601, 10.684458734386428],
                    [76.697273253095048, 10.684449186333687],
                    [76.698432913642066, 10.684133539870365],
                    [76.699584953762098, 10.68389320592493],
                    [76.701889029498361, 10.68310640809689],
                    [76.703346240246461, 10.682478891605257],
                    [76.70520018159354, 10.681544312890175],
                    [76.706268320451997, 10.680460926422009],
                    [76.706497183222766, 10.680381765408338],
                    [76.707649221090875, 10.679375650610385],
                    [76.709335328388235, 10.678209290663151],
                    [76.710716262805605, 10.67720221430422],
                    [76.712333682672622, 10.6761131282791],
                    [76.713478097861852, 10.675335876251902],
                    [76.715484625648912, 10.674859056856812],
                    [76.717102047767824, 10.674769386229432],
                    [76.719024674293323, 10.674676900728015],
                    [76.721801759211161, 10.674269660515369],
                    [76.722724900530054, 10.67395401405205],
                    [76.724250796456616, 10.672100070453094],
                    [76.726249701564782, 10.67054845108161],
                    [76.726860047324763, 10.6700839968652],
                    [76.728469844512887, 10.668151853813546],
                    [76.729080188020973, 10.667455685921956],
                    [76.730453499759449, 10.665909768359324],
                    [76.731376641078342, 10.664980891453096],
                    [76.733139024706446, 10.663508430843377],
                    [76.733978267015672, 10.662887577721573],
                    [76.73605347998118, 10.66179560476164],
                    [76.736976621300059, 10.661480919859292],
                    [76.739288321967109, 10.660846706219347],
                    [76.740516669944483, 10.660376550194083],
                    [76.741973880692612, 10.659674646715159],
                    [76.742286678503405, 10.659594524140513],
                    [76.74282074793264, 10.659129139889725],
                    [76.743736264320731, 10.65827846243619],
                    [76.744735718000754, 10.657119761198244],
                    [76.745727544498081, 10.655881867420034],
                    [76.746490476698071, 10.654725087052125],
                    [76.748626718384642, 10.65186788181154],
                    [76.749855032583511, 10.650860771674129],
                    [76.74993896762355, 10.650859843891643],
                    [76.751701351251668, 10.65000154150732],
                    [76.753852840547921, 10.649599075321044],
                    [76.754158013427897, 10.649519916559274],
                    [76.756088264884212, 10.649196609134767],
                    [76.757621751963086, 10.648798950753353],
                    [76.759170522682055, 10.648633967608088],
                    [76.760398870659415, 10.648699768103617],
                    [76.761322011978308, 10.648538595171853],
                    [76.762245187075678, 10.648146638599291],
                    [76.763473501274561, 10.647369386572084],
                    [76.764770502903772, 10.646130562759497],
                    [76.76600647356004, 10.645659445173262],
                    [76.767005927240078, 10.645421034349768],
                    [76.767311100120068, 10.645420075040697],
                    [76.767929068558956, 10.645719512333352],
                    [76.771629328574164, 10.64591980075476],
                    [76.774398788561214, 10.645665180760608],
                    [76.775321963658584, 10.645735755282507],
                    [76.776634212897505, 10.645954142218036],
                    [76.778488154244556, 10.646629329164487],
                    [76.779808059940862, 10.647385595742753],
                    [76.781662003539807, 10.648596772988489],
                    [76.784133913325761, 10.64957427965861],
                    [76.786827096982051, 10.650242803235237],
                    [76.787139894792844, 10.650316228661564],
                    [76.788909903351751, 10.650531764692666],
                    [76.789833078449135, 10.6503705940128],
                    [76.791374221985379, 10.649973863413868],
                    [76.791992192676162, 10.649814613604047],
                    [76.793220506875045, 10.64988137566055],
                    [76.795150758331346, 10.649865166489882],
                    [76.795768729022129, 10.649784082354271],
                    [76.79668427918871, 10.649314887889984],
                    [76.796920766890267, 10.649084101997284],
                    [76.798141489936853, 10.648535712740834],
                    [76.798759460627636, 10.648377424491985],
                    [76.79937742906651, 10.648448960574859],
                    [76.800529466934634, 10.648363104664776],
                    [76.803306585630949, 10.648107523109646],
                    [76.804840072709837, 10.647635475489031],
                    [76.806770324166138, 10.647313131877395],
                    [76.80831146995429, 10.647684105252925],
                    [76.810234062701312, 10.647283562188594],
                    [76.810852067170586, 10.647124280852182],
                    [76.813621527157636, 10.646639836526301],
                    [76.814231870665722, 10.646558752390684],
                    [76.815383908533832, 10.646471968698117],
                    [76.816314708562004, 10.646771439769262],
                    [76.817237852132806, 10.646533956728252],
                    [76.818153368520882, 10.64598845215472],
                    [76.81846620011018, 10.64590932491954],
                    [76.819389341429044, 10.646054252650254],
                    [76.820007312119827, 10.645895966653303],
                    [76.820930487217211, 10.645428659280567],
                    [76.82115934998798, 10.645195952517824],
                    [76.821617140834547, 10.644885077828977],
                    [76.822227486594556, 10.644495969908943],
                    [76.82299804372532, 10.643722530347137],
                    [76.824905388862632, 10.641557680532742],
                    [76.82575225385078, 10.640398979294792],
                    [76.829832716964432, 10.63650061744336],
                    [76.829299926757628, 10.636208534240701],
                    [76.827949523925611, 10.635469436645485],
                    [76.825630198660534, 10.634951585513049],
                    [76.825325012206861, 10.634877204894996],
                    [76.823623657226392, 10.634124755859352],
                    [76.823310852050611, 10.63405036926267],
                    [76.82169342041, 10.63383388519285],
                    [76.820770275846428, 10.633765203929746],
                    [76.820152273629034, 10.63399983606633],
                    [76.819229125976392, 10.633931159973121],
                    [76.817848205566236, 10.634326934814428],
                    [76.816314697265469, 10.634877204894996],
                    [76.816001879224643, 10.634956359539416],
                    [76.815139764374905, 10.635036482114058],
                    [76.815155014236495, 10.635116568658315],
                    [76.81362152715765, 10.636203767591882],
                    [76.810707103409484, 10.637608536110712],
                    [76.810401932781389, 10.637612348576109],
                    [76.808708200553227, 10.638239865067737],
                    [76.807777400525055, 10.638094903558535],
                    [76.8059387067877, 10.638110151168231],
                    [76.803627006120649, 10.638360030914505],
                    [76.796997076999503, 10.63856983361017],
                    [76.795616142582134, 10.638581271006357],
                    [76.794990549212457, 10.637895576698098],
                    [76.794685376332453, 10.637821189710797],
                    [76.791969286357187, 10.63446806896965],
                    [76.79126738287826, 10.633321766688864],
                    [76.79126738287826, 10.633091942357137],
                    [76.790489203068574, 10.631948457202295],
                    [76.789245605229624, 10.63034724425404],
                    [76.7885513266815, 10.629815097946747],
                    [76.787239077442578, 10.629443163010245],
                    [76.784927376775528, 10.629156122422856],
                    [76.783538817427356, 10.629167559819052],
                    [76.776985164636969, 10.62891482916835],
                    [76.776527405316983, 10.629072189634716],
                    [76.775146470899614, 10.629009237791715],
                    [76.772827145301761, 10.628644927786006],
                    [76.772590626073622, 10.628492341346009],
                    [76.772132866753637, 10.628572463920651],
                    [76.771667482502849, 10.628423689946054],
                    [76.770515444634711, 10.628355964076681],
                    [76.769584644606539, 10.628056526784023],
                    [76.769126885286553, 10.628213887250393],
                    [76.768898022515799, 10.628447524047512],
                    [76.766822809550291, 10.629538533194584],
                    [76.765670771682167, 10.629854215688287],
                    [76.76359559024327, 10.630947114178813],
                    [76.762451177305948, 10.631340034564237],
                    [76.760604858637791, 10.632583630151307],
                    [76.758758542221528, 10.633597369880057],
                    [76.75806426367339, 10.633679381798157],
                    [76.757141120102602, 10.633378982944532],
                    [76.751823440220363, 10.632197375387596],
                    [76.750671400100344, 10.632128721735739],
                    [76.750129707992215, 10.632288933106533],
                    [76.748054495026736, 10.633226396504526],
                    [76.747360214226703, 10.63369274006439],
                    [76.743446341302317, 10.635874760610426],
                    [76.743217478531562, 10.635952960063122],
                    [76.739524843447157, 10.636137967096349],
                    [76.738601699876355, 10.636452685777186],
                    [76.73844145697899, 10.636069313444493],
                    [76.737670902100092, 10.635539058732553],
                    [76.736282342751934, 10.63524435943588],
                    [76.732505806405982, 10.634738931912961],
                    [76.732460027546495, 10.634761806705352],
                    [76.731124871988598, 10.635439876082819],
                    [76.730659487737825, 10.635751710080738],
                    [76.729736312640441, 10.635759368790024],
                    [76.728813171321548, 10.63545989771888],
                    [76.727661133453438, 10.635931015305115],
                    [76.727355960573419, 10.636470786543207],
                    [76.727973929012322, 10.637692437372261],
                    [76.727592479801572, 10.638156891588672],
                    [76.726364131824198, 10.638013853201413],
                    [76.726364131824198, 10.638780597866804],
                    [76.726295480424241, 10.639088621651224],
                    [76.726058958944193, 10.639320366852994],
                    [76.725677478206862, 10.639476765758388],
                    [76.725059507516065, 10.639481539784764],
                    [76.724754334636089, 10.639790525130154],
                    [76.724678026778733, 10.640636428557315],
                    [76.725074757377669, 10.641860002508315],
                    [76.724998481046924, 10.642475122294673],
                    [76.724777209428467, 10.642706869748341],
                    [76.724311825177679, 10.642710679961839],
                    [76.723388683858786, 10.643026362455549],
                    [76.723152162378739, 10.64295197546825],
                    [76.722839364567974, 10.642569530918037],
                    [76.722839364567974, 10.642033572145344],
                    [76.722526535230585, 10.641115204852831],
                    [76.721603393911707, 10.641045558113413],
                    [76.720985423220895, 10.641434663781549],
                    [76.720916738042447, 10.642127021459638],
                    [76.719612113734328, 10.642751687046839],
                    [76.71993256800252, 10.644666657114959],
                    [76.719474777155924, 10.645589832212334],
                    [76.718864431395943, 10.6459035893322],
                    [76.713859547072587, 10.647556316727258],
                    [76.713165266272568, 10.647870073847127],
                    [76.712944028432631, 10.64825440774079],
                    [76.712867752101872, 10.649099349606979],
                    [76.711952201935304, 10.650027264952236],
                    [76.711418166284545, 10.650953293205935],
                    [76.710662825236867, 10.653261181407588],
                    [76.710823068134246, 10.654794702264955],
                    [76.710601796515789, 10.655946740133079],
                    [76.710754382955798, 10.6565599367975],
                    [76.710601796515789, 10.657482150333903],
                    [76.710151662126592, 10.658021919320095],
                    [76.709838866567694, 10.658179279786461],
                    [76.708457932150353, 10.658344265183629],
                    [76.707763685128782, 10.658196450518099],
                    [76.70706178164987, 10.657665265771774],
                    [76.70690153875249, 10.657359133582709],
                    [76.706436154501688, 10.655829425190742],
                    [76.705970770250914, 10.655142769321504],
                    [76.705894462393545, 10.654684082219024],
                    [76.705497729542728, 10.65345858514608],
                    [76.704414376853038, 10.651856412888757],
                    [76.703414925424909, 10.651864035567653],
                    [76.702255262625982, 10.652487741845784],
                    [76.70172119544867, 10.653259292064133],
                    [76.701728818127549, 10.654563916372254],
                    [76.702583308046471, 10.656091701642284],
                    [76.702514656646514, 10.656705859867673],
                    [76.702133173657273, 10.657321938963111],
                    [76.702056897326514, 10.657630924308503],
                    [76.701362618778404, 10.657942760558329],
                    [76.701133722229144, 10.658175467321064],
                    [76.700752273018395, 10.659176841871137],
                    [76.700523376469164, 10.659331317654591],
                    [76.698516848682104, 10.658581745972736],
                    [76.697128289333946, 10.658514983916236],
                    [76.695671078585818, 10.658603656952252],
                    [76.69451141578692, 10.65884495245866],
                    [76.694206242906915, 10.65915393555216],
                    [76.69397734860955, 10.659923564900462],
                    [76.69344331295882, 10.660312636790113],
                    [76.692672724301431, 10.660470958817447],
                    [76.69143678517176, 10.660099023880949],
                    [76.691383347603022, 10.660278329105326],
                    [76.691207888622543, 10.660867689416381],
                    [76.691284164953288, 10.661173823857352],
                    [76.691520684181427, 10.661401758845622],
                    [76.691520684181427, 10.661708821069075],
                    [76.692527760540329, 10.662851344662947],
                    [76.692832933420348, 10.663002969541973],
                    [76.693840043557756, 10.662994385302104],
                    [76.695381155567404, 10.663210882894182],
                    [76.695693984904807, 10.663363469334183],
                    [76.695770261235552, 10.663822187963246],
                    [76.69432067541824, 10.665291797668534],
                    [76.693008426179304, 10.665609367253799],
                    [76.69208525108192, 10.665695191637292],
                    [76.69093321321381, 10.666088109770826],
                    [76.690780626773815, 10.666395171994282],
                    [76.690467828963023, 10.666475294568921],
                    [76.690406802493854, 10.668087942661465],
                    [76.690803533092804, 10.670845999030805],
                    [76.692123405010605, 10.672446250418087],
                    [76.692123405010605, 10.672752382607156],
                    [76.69204712867986, 10.673060406391578],
                    [76.691818234382509, 10.673292153845244],
                    [76.690742504371727, 10.673608795648025],
                    [76.690666194262505, 10.67376230987051],
                    [76.690589917931746, 10.674069405872446],
                    [76.690826405633288, 10.675449378728846],
                    [76.690597542862534, 10.675603854512298],
                    [76.689598091434405, 10.675612441004066],
                    [76.688827502777031, 10.676003469794148],
                    [76.68898774567441, 10.67630864267414],
                    [76.689216608445179, 10.676460267553168],
                    [76.690216059873308, 10.676835053394095],
                    [76.690299994913332, 10.677064911504306],
                    [76.690147408473337, 10.677910814931469],
                    [76.690376271244091, 10.678445812143186],
                    [76.691772453271142, 10.678664199078716],
                    [76.692382799031151, 10.678503989959822],
                    [76.692848214808521, 10.67804145661346],
                    [76.692916868460372, 10.677350028969752],
                    [76.692687971911155, 10.676660522196091],
                    [76.693069454900368, 10.675967205208929],
                    [76.694290144168448, 10.67534446049177],
                    [76.694602975757746, 10.675495157588314],
                    [76.695228569127423, 10.676793087000027],
                    [76.695228569127423, 10.677100180750067],
                    [76.695617674795542, 10.677480702178332],
                    [76.695930472606335, 10.677708637166603],
                    [76.696929924034464, 10.677853598675805],
                    [76.697547926251843, 10.678153997529433],
                    [76.697708137622641, 10.678383821861154],
                    [76.697708137622641, 10.679534900420208],
                    [76.69755555118266, 10.679842955731221],
                    [76.696563724685305, 10.680771832637449],
                    [76.695945753994522, 10.681817992959427],
                  ],
                ],
              ],
            },
          },
        ],
      };

      return rua
}