import React, { Component } from "react";
// Table from react-bootstrap
import {
  Table,
  Card,
  CardBody,
  Row,
  Col,
  Container,
  FormGroup,
  Label,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";
// Bootstrap CSS
import { Link } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import "../Adm-components/css/tsperilwise.css";
import { toast } from "react-toastify";
import axios from "axios";
import { withNamespaces } from "react-i18next";
import { isConstructorDeclaration } from "typescript";
import { AvForm, AvField } from "availity-reactstrap-validation";
import validate_Token from "../../validateToken";

toast.configure();
class WeatherValidation extends Component {
  constructor(props) {
    // let querydata=props.tabledata;
    super(props);
    this.state = {
      showtable: false,
      showWaiting: true,
      crop: "all",
      peril: "all",
      season: "all",
      weatherData: "",
      phase: "all",
      seasonData: [],
      perilseasonData: [],
      dataToUpdate: [],
      validationData: [],
      modalupdateValidation: false,
      defaultWeatherChecked: false,
      weatherCheck: "No",
      modal_static: false,
      cropData: [
        "Paddy",
        "Veg Group I",
        "Mango",
        "Paddy 2nd Crop",
        "Paddy 3rd Crop",
        "Paddy Winter",
        "Veg Group I - SMR",
      ],
      perilData: [
        "Deficit Rainfall",
        "Disease Congenial Climate",
        "Dry Spell",
        "Excess Rainfall",
        "High Temperature",
        "Low Temperature",
        "Rise in Night Temperature",
        "Rise in Temperature",
        "Unseasonal Rainfall",
        "Wet Spell",
        "Wind Speed",
        "Wind Speed (A)",
        "Wind Speed (B)",
      ],
    };
    this.handleSeasonchange = this.handleSeasonchange.bind(this);
    this.handleCropchange = this.handleCropchange.bind(this);
    this.fetchseason = this.fetchseason.bind(this);
    this.onPerilChange = this.onPerilChange.bind(this);
    this.getValidationData = this.getValidationData.bind(this);
    this.handlePhasechange = this.handlePhasechange.bind(this);
    this.tog_conditions = this.tog_conditions.bind(this);
    this.updateweatherData = this.updateweatherData.bind(this);
    this.saveWeatherData = this.saveWeatherData.bind(this);
    this.changeSeason=this.changeSeason.bind(this)
    this.handleweatherCheck = this.handleweatherCheck.bind(this);
  }
  async getValidationData() {
    let searchdata = {
      season: this.state.season,
      crop: this.state.crop,
      phase: this.state.phase,
    };
    searchdata = JSON.stringify(searchdata);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + localStorage.getItem("token"),
      },
      body: searchdata,
    };
    try {
      var validationData = await fetch(
        process.env.REACT_APP_APIEND + `cultivation/validate-weather-status/get-data`,
        requestOptions
      ).then((res) => {
        return res.json();
      });
      if (validationData.result.length > 0) {
        this.generatetabledata(validationData.result);
      } else {
        toast.info("Data yet to be added!");
      }
    } catch (err) {
      toast.error("Something's not right");
    }
  }
  generatetabledata(data) {
    let tabledata = [];
    var getthis = this;
    data.map(function (item, index, data) {
      var newdata = {
        season: item.season,
        crop: item.crop,
        peril: item.peril,
        phase: item.phase,
        weather_data: item.weather_data,
        // action: <Link  to="#" onClick={this.tog_conditions}>Update</Link>,
        action: (
          <p onClick={() => getthis.updateweatherData(item)}>
            <Link>Change Status</Link>
          </p>
        ),
      };
      tabledata.push(newdata);
    });
    this.setState({
      validationData: tabledata,
      showtable: true,
      showWaiting: false,
    });
  }
  async saveWeatherData() {
    let updateData = {
      user_id: localStorage.getItem("uid"),
      season: this.state.dataToUpdate["season"],
      crop: this.state.dataToUpdate["crop"],
      peril: this.state.dataToUpdate["peril"],
      phase: this.state.dataToUpdate["phase"],
      weather_data: this.state.dataToUpdate["weather_data"],
    };
    updateData = JSON.stringify(updateData);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + localStorage.getItem("token"),
      },
      body: updateData,
    };
    try {
      var updateweatherStatus = await fetch(
        process.env.REACT_APP_APIEND + `cultivation/validate-weather-status`,
        requestOptions
      ).then((res) => {
        return res.json();
      });
      if(updateweatherStatus.code === 200) {
        toast.success("Status Updated");
        this.getValidationData()
      }
     else{
      toast.error(updateweatherStatus.message);
     }
    } catch (err) {
      toast.error("Something's not right");
    }
  }
  addweatherData(e) {
    if (e.target.checked == true) {
      this.state.dataToUpdate["weather_data"] = "Yes";
    } else {
      this.state.dataToUpdate["weather_data"] = "No";
    }
  }
  updateweatherData(data) {
    // console.log("CURRENT DATA", data);
    if (data.weather_data == "No") {
      this.setState({
        defaultWeatherChecked: false,
      });
    } else {
      this.setState({
        defaultWeatherChecked: true,
      });
    }
    this.setState(
      {
        dataToUpdate: data,
      },
      () => {
        this.tog_conditions();
      }
    );
  }
  async handleCropchange(e) {
    this.setState({
      crop: e.target.value,
    });
    let searchdata = {
      season: this.state.season,
      crop: e.target.value,
    };
    let selected_index = e.target.selectedIndex;
    if (selected_index == "0") {
      this.setState({
        perilData: [
          "Deficit Rainfall",
          "Disease Congenial Climate",
          "Dry Spell",
          "Excess Rainfall",
          "High Temperature",
          "Low Temperature",
          "Rise in Night Temperature",
          "Rise in Temperature",
          "Unseasonal Rainfall",
          "Wet Spell",
          "Wind Speed",
          "Wind Speed (A)",
          "Wind Speed (B)",
        ],
      });
    } else {
      searchdata = JSON.stringify(searchdata);
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + localStorage.getItem("token"),
        },
        body: searchdata,
      };
      try {
        var perildata = await fetch(
          process.env.REACT_APP_APIEND + `smart_crop/get-perils`,
          requestOptions
        ).then((res) => {
          return res.json();
        });
        var newperils = perildata.result[0].perils;
        // console.log("PERIL DATA", newperils);
        this.setState({
          perilData: newperils,
        });
      } catch (err) {
        toast.error("Check Season");
      }
    }
  }
  async fetchseason() {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + localStorage.getItem("token"),
      },
    };
    try {
      var seasonData = await fetch(
        process.env.REACT_APP_APIEND + `smart_crop/term-sheet-season-crop`,
        requestOptions
      ).then((res) => {
        return res.json();
      });
      this.setState({
        perilseasonData: seasonData.result,
      });

      var newseasonData = [];
      {
        seasonData.result.map(function (item, index, data) {
          newseasonData.push(Object.keys(item));
        });
      }
      // console.log("NEW DATA", seasonData.result);
      this.setState({
        seasonData: newseasonData,
        season: newseasonData[0][0],
        cropData: seasonData.result[0][Object.keys(seasonData.result[0])],
        crop: seasonData.result[0][Object.keys(seasonData.result[0])][0],
      });
    } catch (errseason) {
      toast.error("Something's not right");
    }
  }
  handleSeasonchange(e) {
    this.setState({
      season: e.target.value,
    });
    this.setState({
      cropData: [],
    });
    let selected_index = e.target.selectedIndex;
    let season = e.target.value;
    let allcropdata = this.state.perilseasonData;
    if (selected_index == "0") {
      var list_array = [];
      allcropdata.map((index) => {
        list_array.push("---------" + Object.keys(index)[0] + "--------");
        // console.log("INDEX ", index[Object.keys(index)[0]]);
        var seasoncrop = index[Object.keys(index)[0]];
        seasoncrop.map((crop) => {
          list_array.push(crop);
        });
      });
      this.setState({
        cropData: list_array,
      });
    } else {
      var seasoncrop = allcropdata[selected_index - 1];
      // console.log("Season crop", seasoncrop);
      var croplist = seasoncrop[Object.keys(seasoncrop)[0]];
      var list_array = [];
      croplist.map((item, index, data) => {
        list_array.push(item);
      });
      this.setState({
        cropData:
          this.state.perilseasonData[selected_index][
            Object.keys(this.state.perilseasonData[selected_index])
          ],
        crop: this.state.perilseasonData[selected_index][
          Object.keys(this.state.perilseasonData[selected_index])
        ][0],
      });
    }
  }
  handlePhasechange(e) {
    this.setState(
      {
        phase: e.target.value,
      },
      () => {
        // console.log("PHASE CHANGED", this.state.phase);
      }
    );
  }
  openAddStatusbox() {
    this.setState({ modal_static: true, isAlertOpen: false });
  }
  onPerilChange(e) {
    this.setState({
      peril: e.target.value,
    });
  }
  componentDidMount() {
    this.fetchseason();
    validate_Token();
  }
  tog_conditions() {
    this.setState((prevState) => ({
      modalupdateValidation: !prevState.modalupdateValidation,
    }));
  }
  handleweatherCheck(e) {
    // console.log("DATA TO UPDATE", this.state.dataToUpdate);
    if (e.target.checked == true) {
      this.setState({
        defaultWeatherChecked: true,
      });
      this.state.dataToUpdate["weather_data"] = "Yes";
    } else {
      this.setState({
        defaultWeatherChecked: false,
      });
      this.state.dataToUpdate["weather_data"] = "No";
    }
    // console.log("DATA AFTER UPDATE", this.state.dataToUpdate);
  }
  changeSeason(e){
    // console.log("SEASON CHANGE",e.target.value)
    if(e.target.value=="Kharif 2020"){
      this.setState({
        cropData:["Paddy","Veg Group I"]
    })}
    if(e.target.value=="Kharif 2021"){
      this.setState({
        cropData:["Paddy","Veg Group I"]
    })}
    if(e.target.value=="Kharif 2022"){
      this.setState({
        cropData:["Paddy","Veg Group I"]
    })
  }
    if(e.target.value=="Rabi 2020-21"){
      this.setState({
        cropData: [
          "Veg Group I",
          "Mango",
          "Paddy 2nd Crop",
          "Paddy 3rd Crop",
          "Paddy Winter",
          "Veg Group I - SMR",
        ]
    })
    }

    if(e.target.value=="Rabi 2021-22"){
      this.setState({
        cropData: [
          "Veg Group I",
          "Mango",
          "Paddy 2nd Crop",
          "Paddy 3rd Crop",
          "Paddy Winter",
          "Veg Group I - SMR",
        ]
    })
    }
    if(e.target.value=="Rabi 2022-23"){
      this.setState({
        cropData: [
          "Veg Group I",
          "Mango",
          "Paddy 2nd Crop",
          "Paddy 3rd Crop",
          "Paddy Winter",
          "Veg Group I - SMR",
        ]
    })
    }
    this.state.dataToUpdate["season"] = e.target.value
  
}
  
  render() {
    const { t, i18n } = this.props;
    var data = {
      columns: [
        {
          label: "Season",
          field: "season",
          width: 150,
        },
        {
          label: "Crop",
          field: "crop",
          width: 150,
        },
        {
          label: "Peril",
          field: "peril",
          width: 150,
        },
        {
          label: "Phase",
          field: "phase",
          width: 200,
        },
        {
          label: "Status",
          field: "weather_data",
          width: 150,
        },
        {
          label: "Action",
          field: "action",
          width: 150,
        },
      ],
      rows: this.state.validationData,
    };
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Row>
              <Col xs={12}>
                <h4
                  className="card-title"
                  style={{ padding: "5px", fontSize: "18px" }}
                >
                  {" "}
                  {t("Weather Data Status")}
                </h4>
              </Col>
            </Row>
            <Row>
              <Col>
                <Card style={{ backgroundColor: "#f1f6fb" }}>
                  <CardBody>
                    <Col>
                      <Row>
                        <Col md="2">
                          <FormGroup>
                            <Label
                              className="col-md-10 col-form-label"
                              style={{ fontSize: "14px" }}
                            >
                              {t("Season")}{" "}
                            </Label>
                            <select
                              className="form-select"
                              style={{ fontSize: "11px" }}
                              value={this.state.season}
                              onChange={this.handleSeasonchange}
                              name="season"
                            >
                              {/* <option value="all">All</option> */}
                              {this.state.seasonData.length > 0 &&
                                this.state.seasonData.map((item) => (
                                  <option value={item} key={item}>
                                    {item}
                                  </option>
                                ))}
                            </select>
                          </FormGroup>
                        </Col>
                        <Col md="2">
                          <FormGroup>
                            <Label
                              className="col-md-10 col-form-label"
                              style={{ fontSize: "14px" }}
                            >
                              {t("Crop")}{" "}
                            </Label>
                            <select
                              className="form-select"
                              style={{ fontSize: "11px" }}
                              value={this.state.crop}
                              onChange={this.handleCropchange}
                              name="crop"
                            >
                              {/* <option value="all">All</option> */}
                              {this.state.cropData.length > 0 &&
                                this.state.cropData.map((item) => (
                                  <option value={item} key={item}>
                                    {item}
                                  </option>
                                ))}
                            </select>
                          </FormGroup>
                        </Col>
                        <Col md="2">
                          <FormGroup>
                            <Label
                              className="col-md-12 col-form-label"
                              style={{ fontSize: "14px" }}
                            >
                              {t("Phase")}
                            </Label>
                            <select
                              className="form-select"
                              style={{ fontSize: "11px" }}
                              onChange={this.handlePhasechange}
                            >
                              <option value="all">All</option>
                              <option value="1">Phase 1</option>
                              <option value="2">Phase 2</option>
                              <option value="3">Phase 3</option>
                              <option value="4">Phase 4</option>
                            </select>
                          </FormGroup>
                        </Col>
                        <Col md="2">
                          <FormGroup>
                            <Label
                              className="col-md-12 col-form-label"
                              style={{ fontSize: "14px" }}
                            >
                              <span>&nbsp;</span>
                            </Label>
                            <Button
                              color="primary"
                              className="waves-effect waves-light mr-1"
                              style={{ fontSize: "11px", float: "right" }}
                              onClick={this.getValidationData}
                            >
                              {t("Search")}
                            </Button>
                          </FormGroup>
                        </Col>
                        <Col md="4">
                          <FormGroup style={{ float: "right" }}>
                            <Link
                              to="#"
                              onClick={() => this.openAddStatusbox()}
                              className="btn btn-success mb-2 btn-adduser"
                              // style={{ fontSize: "11px" }}
                              style={this.state.showtable === true ? {fontSize: "11px"} : {display:'none'}}
                            >
                              <i className="mdi mdi-plus mr-2"></i>{" "}
                              {t("Add Status")}
                            </Link>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col></Col>
                      </Row>
                    </Col>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <center>
                      <p
                        style={
                          this.state.showWaiting ? {} : { display: "none" }
                        }
                      >
                        {t("Waiting for search")}
                      </p>
                      <Spinner
                        style={this.state.showloader ? {} : { display: "none" }}
                      ></Spinner>
                    </center>
                    <div
                      id="weather-data-status"
                      style={this.state.showtable ? {} : { display: "none" }}
                    >
                      <MDBDataTable
                        maxHeight="300px"
                        scrollX
                        paging={true}
                        bordered
                        data={data}
                        order={["trnID", "asc"]}
                        className="datadownloadtable"
                        style={{ fontSize: "12px" }}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Modal
              isOpen={this.state.modalupdateValidation}
              toggle={this.tog_conditions}
              centered={true}
              backdrop="static"
            >
              <ModalHeader
                toggle={() => this.setState({ modalupdateValidation: false })}
              >
                Update Status
              </ModalHeader>
              <ModalBody
                style={{
                  maxHeight: "calc(100vh - 210px)",
                  overflowY: "auto",
                }}
              >
                {" "}
                <AvForm>
                  <div class="row">
                    <div className="form-check form-check-right mb-1">
                      <div class="col" style={{ float: "left" }}>
                        <Input
                          className="form-check-input"
                          type="checkbox"
                          id="weathercheck"
                          onChange={this.handleweatherCheck}
                          checked={this.state.defaultWeatherChecked}
                        />
                        <Label
                          className="form-check-label"
                          htmlFor="weathercheck"
                          style={{ fontWeight: "lighter" }}
                        >
                          Weather Data valid ?
                        </Label>
                      </div>
                    </div>
                  </div>
                  <ModalFooter style={{ border: "none" }}>
                    <Button
                      type="button"
                      color="light"
                      onClick={() =>
                        this.setState({ modalupdateValidation: false })
                      }
                    >
                      Cancel
                    </Button>
                    <Button
                      type=""
                      color="primary"
                      onClick={this.saveWeatherData}
                    >
                      Update
                    </Button>
                  </ModalFooter>
                </AvForm>
              </ModalBody>
            </Modal>
            <Modal
              isOpen={this.state.modal_static}
              toggle={this.tog_static}
              backdrop="static"
              centered
              size="lg"
            >
              <ModalHeader
                toggle={() => this.setState({ modal_static: false })}
                style={{ backgroundColor: "#A4C1D2" }}
              >
                Add Status Details
              </ModalHeader>
              <ModalBody style={{ marginLeft: "10%" }}>
                <Row>
                  <Col md="2">
                    <FormGroup>
                      <Label
                        className="col-md-10 col-form-label"
                        style={{ fontSize: "14px" }}
                      >
                        Season{" "}
                      </Label>
                      <select
                        className="form-select"
                        style={{ fontSize: "11px" }}
                        name="season"
                        // onChange={(e) =>
                        //   (this.state.dataToUpdate["season"] = e.target.value)
                        // }
                        onChange={(e) =>this.changeSeason(e)
                        }
                      // defaultValue={this.state.seasonData[0]}
                      >
                        <option  disabled selected >
                         Select Season
                        </option>
                        {this.state.seasonData.length > 0 &&
                          this.state.seasonData.map((item) => (
                            <option value={item} key={item}>
                              {item}
                            </option>
                          ))}
                      </select>
                    </FormGroup>
                  </Col>
                  <Col md="2">
                    <FormGroup>
                      <Label
                        className="col-md-10 col-form-label"
                        style={{ fontSize: "14px" }}
                      >
                        Crop{" "}
                      </Label>
                      <select
                        className="form-select"
                        style={{ fontSize: "11px" }}
                        onChange={(e) =>
                          (this.state.dataToUpdate["crop"] = e.target.value)
                        }
                        // defaultValue={this.state.cropData[0]}
                        name="crop"
                      >
                        <option  disabled selected  >
                         Select Crop
                        </option>
                        {this.state.cropData.length > 0 &&
                          this.state.cropData.map((item) => (
                            <option value={item} key={item}>
                              {item}
                            </option>
                          ))}
                      </select>
                    </FormGroup>
                  </Col>
                  <Col md="2">
                    <FormGroup>
                      <Label
                        className="col-md-12 col-form-label"
                        style={{ fontSize: "14px" }}
                      >
                        Peril
                      </Label>
                      <select
                        className="form-select"
                        style={{ fontSize: "11px" }}
                        onChange={(e) =>
                          (this.state.dataToUpdate["peril"] = e.target.value)
                        } 
                      >
                          <option  disabled selected >
                         Select Peril
                        </option>
                        <option value="High Temperature">
                          High Temperature
                        </option>
                        <option value="Deficit Rainfall">
                          Deficit Rainfall
                        </option>
                        <option value="Excess Rainfall">Excess Rainfall</option>
                        <option value="Disease Congenial Climate">
                          Disease Congenial Climate
                        </option>
                        <option value="Dry Spell">Dry Spell</option>
                      </select>
                    </FormGroup>
                  </Col>
                  <Col md="2">
                    <FormGroup>
                      <Label
                        className="col-md-12 col-form-label"
                        style={{ fontSize: "14px" }}
                      >
                        Phase
                      </Label>
                      <select
                        className="form-select"
                        style={{ fontSize: "11px" }}
                        onChange={(e) =>
                          (this.state.dataToUpdate["phase"] = e.target.value)
                        }
                      >
                          <option disabled selected>Select Phase</option>
                        <option value="1">Phase 1</option>
                        <option value="2">Phase 2</option>
                        <option value="3">Phase 3</option>
                        <option value="4">Phase 4</option>
                      </select>
                    </FormGroup>
                  </Col>

                  <Col md="3">
                    <FormGroup>
                      <Label
                        className="col-md-12 col-form-label"
                        style={{ fontSize: "14px" }}
                      >
                        Weather Data Valid?
                      </Label>
                      <Input
                        className="form-check-input"
                        type="checkbox"
                        id="weathercheck"
                        onChange={(e) => this.addweatherData(e)}
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <ModalFooter style={{ border: "none" }}>
                  <Button
                    type="button"
                    color="light"
                    onClick={() => this.setState({ modal_static: false })}
                  >
                    Cancel
                  </Button>
                  <Button color="primary" onClick={this.saveWeatherData}>
                    Add
                  </Button>
                </ModalFooter>
              </ModalBody>
            </Modal>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default withNamespaces()(WeatherValidation);
