import React, { Component } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Row,
  Col,
  FormGroup,
  Label,
  Container,
} from "reactstrap";
// import './table.css'

import "antd/dist/antd.css";

import Defaultcontent from "./tm_clonetemplate";
import Tmtemplate from "./Tm_condition_template";
import { Link } from "react-router-dom";

import { Tabs, Input, Radio, Checkbox } from "antd";
import validate_Token from "../../validateToken.js";
import { connect } from "react-redux";
//import css
import "../../assets/scss/tab.css";
const TabPane = Tabs.TabPane;
const { TextArea } = Input;

const mapDispatchToProps = (dispatch) => {
  return {
    setseason: (inc) => dispatch({ type: "SETCLONESEASON", payload: inc }),
    setcrop: (inc) => dispatch({ type: "SETCLONECROP", payload: inc }),
    setperil: (inc) => dispatch({ type: "SETCLONEPERIL", payload: inc }),
    setexec: (inc) => dispatch({ type: "SETCLONEEXEC", payload: inc }),
  };
};
class Termsheet_clone extends Component {
  constructor(props) {
    console.log("PROPS ", props);
    super(props);
    let Setpanes = [];
    props.location.state.data.map((phases, index) => {
      Setpanes.push({
        title: "Phase" + (index + 1),
        content:<Defaultcontent props={phases[index + 1]}/>,         
        key: index + 1,
        closable: false,
      });
      // console.log("PANES",phases[index+1])
    });
    const panes = Setpanes;
    this.state = {
      props_data: props.location.state.data,
      activeKey: panes.key,
      perilData: [],
      cropData: ["Paddy", "Veg Group I"],
      seasonData: [],
      selectedperil: " ",
      crop: props.location.state.data[0][1].crop,
      peril: props.location.state.data[0][1].peril,
      phasedata: [],
      season: props.location.state.data[0][1].season,
      panes,
    };
    this.newTabIndex = 0;
    this.handleSeasonchange = this.handleSeasonchange.bind(this);
    this.handleCropchange = this.handleCropchange.bind(this);
    console.log("PROPS STATE", this.state.props_data);
    this.handlePerilchange = this.handlePerilchange.bind(this);
    this.changeExec=this.changeExec.bind(this)
  }

  onChange = (activeKey) => {
    this.setState({ activeKey });
  };
  onEdit = (targetKey, action) => {
    this[action](targetKey);
  };
  add = () => {
    const panes = this.state.panes;
    const activeKey = `newTab${this.newTabIndex++}`;
    let phase_no = panes.length;
    phase_no = phase_no + 1;
    panes.push({
      title: "Phase " + phase_no,
      content: Tmtemplate(),
      key: activeKey,
    });
    this.setState({ panes, activeKey });
  };
  remove = (targetKey) => {
    let activeKey = this.state.activeKey;
    let lastIndex;
    this.state.panes.forEach((pane, i) => {
      if (pane.key === targetKey) {
        lastIndex = i - 1;
      }
    });
    const panes = this.state.panes.filter((pane) => pane.key !== targetKey);
    if (lastIndex >= 0 && activeKey === targetKey) {
      activeKey = panes[lastIndex].key;
    }
    this.setState({ panes, activeKey });
  };
  async fetchseason() {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + localStorage.getItem("token"),
      },
    };

    var seasonData = await fetch(
      process.env.REACT_APP_APIEND + `smart_crop/term-sheet-season-crop`,
      requestOptions
    ).then((res) => {
      return res.json();
    });
    this.setState({
      perilseasonData: seasonData.result,
    });

    var newseasonData = [];
    var newcropdata = [];
    {
      seasonData.result.map(function (item, index, data) {
        newseasonData.push(Object.keys(item));
        var cropdata = item[Object.keys(item)];
        cropdata.map(function (crops, index, dt) {
          newcropdata.push(crops);
        });
        // cropdata.push(Object.keys(item)[item])
      });
    }
    this.setState({
      seasonData: newseasonData,
      CropData: newcropdata,      
    });
  }
  componentDidMount() {
    validate_Token();
    this.fetchseason();
  }
  handleSeasonchange(e) {
    this.setState({
      season: e.target.value,
    });
    this.props.setseason(e.target.value);
    // this.setState({
    //   cropData: [],
    // });
    // let selected_index = e.target.selectedIndex;
    // let season = e.target.value;
    // let allcropdata = this.state.perilseasonData;

    //   var seasoncrop = allcropdata[selected_index];
    //   // console.log("Season crop", seasoncrop[Object.keys(seasoncrop)[0]]);
    //   var croplist = seasoncrop[Object.keys(seasoncrop)[0]];
    //   var list_array = [];
    //   croplist.map((item, index, data) => {
    //     list_array.push(item);
    //   });
    //   this.setState({
    //     cropData: list_array,
    //   });
  }
  async handleCropchange(e) {
    this.setState({
      crop: e.target.value,
    });
    this.props.setcrop(e.target.value);
    let searchdata = {
      season: this.state.selectedseason,
      crop: e.target.value,
    };
    searchdata = JSON.stringify(searchdata);

    // const requestOptions = {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: "Token " + localStorage.getItem("token"),
    //   },
    //   body: searchdata,
    // };
    // var perildata = await fetch(
    //   process.env.REACT_APP_APIEND + `smart_crop/get-perils`,
    //   requestOptions
    // ).then((res) => {
    //   return res.json();
    // });
    // var newperils = perildata.result[0].perils;
    // console.log("PERIL DATA", newperils);
    // this.setState({
    //   perilData: newperils,
    // });
  }
  changeExec(e) {
    this.props.setexec(e.target.value);
  }
  handlePerilchange(e) {
    this.setState({
      selectedperil: e.target.value,
    });
    this.props.setperil(e.target.value);
  }
  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Card style={{ backgroundColor: "#e5ebee" }}>
            <CardHeader style={{ backgroundColor: "#A4C1D2" }}>
              <Row>
                <Col xs={8}>
                  <h4
                    className="card-title"
                    style={{ padding: "5px", fontSize: "18px" }}
                  >
                    {" "}
                    Clone Termsheet Conditions
                  </h4>
                </Col>
                <Col>
                  <Link to="/tm_conditions">
                    <i
                      className="ri-close-fill"
                      style={{ fontSize: "20px", float: "right" }}
                    ></i>
                  </Link>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Container fluid>
                <Row>
                  <Col xs={12}></Col>
                </Row>
                <Card>
                  <CardBody>
                    <Row>
                      <Col>
                        <Col>
                          <Row>
                            <Col md="4">
                              <FormGroup>
                                <Label
                                  className="col-md-5 col-form-label"
                                  style={{ fontSize: "14px" }}
                                >
                                  Season{" "}
                                </Label>
                                <select
                                  className="form-select"
                                  md="6"
                                  style={{ fontSize: "11px" }}
                                  name="season"
                                  onChange={this.handleSeasonchange}
                                  // defaultValue={
                                  //   this.state.props_data[0][1].season
                                  // }
                                >
                                  {/* {this.state.seasonData.length > 0 &&
                                    this.state.seasonData.map((item) => (
                                      <option value={item} key={item}>
                                        {item}
                                      </option>
                                    ))} */}
                                    <option defaultChecked >
                                    Select season
                                  </option>
                                
                                  <option value="Rabi 2022-23">Rabi 2022-23</option>
                                  <option value="Kharif 2022">Kharif 2022</option>
                                  <option value="Rabi 2021-22">Rabi 2021-22</option>
                                  <option value="Kharif 2021">Kharif 2021</option>
                                  <option value="Rabi 2020-21">Rabi 2020-21</option>
                                  <option value="Kharif 2020">Kharif 2020</option>
                                </select>
                              </FormGroup>
                            </Col>

                            <Col md="2">
                              <FormGroup>
                                <Label
                                  className="col-md-10 col-form-label"
                                  style={{ fontSize: "14px" }}
                                >
                                  Crop{" "}
                                </Label>
                                <select
                                  className="form-select"
                                  style={{ fontSize: "11px" }}
                                  name="crop"
                                  onChange={this.handleCropchange}
                                  // defaultValue={
                                  //   this.state.props_data[0][1].crop
                                  // }
                                >
                                  {/* {this.state.cropData.length > 0 &&
                                    this.state.cropData.map((item) => (
                                      <option value={item} key={item}>
                                        {item}
                                      </option>
                                    ))} */}
                                    <option defaultChecked >
                                    Select crop
                                  </option>
                                  <option value="Mango">Mango</option>
                                  <option value="Paddy">Paddy</option>
                                  <option value="Paddy 2nd Crop">
                                    Paddy 2nd Crop
                                  </option>
                                  <option value="Paddy 3rd Crop">
                                    Paddy 3rd Crop
                                  </option>
                                  <option value="Paddy Winter">
                                    Paddy Winter
                                  </option>
                                  <option value="Veg Group I">
                                    Veg Group I
                                  </option>
                                  <option value="Veg Group I - SMR">
                                    Veg Group I - SMR
                                  </option>
                                </select>
                              </FormGroup>
                            </Col>

                            <Col md="2">
                              <FormGroup>
                                <Label
                                  className="col-md-10 col-form-label"
                                  style={{ fontSize: "14px" }}
                                >
                                  Peril{" "}
                                </Label>
                                <select
                                  className="form-select"
                                  style={{ fontSize: "11px" }}
                                  name="peril"
                                  onChange={this.handlePerilchange}
                                  defaultValue={
                                    this.state.props_data[0][1].peril
                                  }
                                >
                                  {/* {this.state.perilData.length > 0 &&
                                    this.state.perilData.map((item) => (
                                      <option value={item} key={item}>
                                        {item}
                                      </option>
                                    ))} */}
                                  <option value="High Temperature">
                                    High Temperature
                                  </option>
                                  <option value="Deficit Rainfall">
                                    Deficit Rainfall
                                  </option>
                                  <option value="Disease Congenial Climate">
                                    Disease Congenial Climate
                                  </option>
                                  <option value="Dry Spell">Dry Spell</option>
                                  <option value="Excess Rainfall">
                                    Excess Rainfall
                                  </option>
                                  <option value="Wind Speed">Wind Speed</option>
                                  <option value="Unseasonal Rainfall">
                                    Unseasonal Rainfall
                                  </option>
                                  <option value="Wind Speed (A)">
                                    Wind Speed (A)
                                  </option>
                                  <option value="Wind Speed (B)">
                                    Wind Speed (B)
                                  </option>
                                  <option value="Rise in Temperature">
                                  Rise in Temperature
                                  </option>
                                </select>
                              </FormGroup>
                            </Col>
                            <Col md="4">
                              <FormGroup>
                                <Label
                                  className="col-md-10 col-form-label"
                                  style={{ fontSize: "14px" }}
                                >
                                  Execution
                                </Label>
                                <Radio.Group
                                  buttonStyle="solid"
                                  defaultValue={
                                    this.state.props_data[0][1].is_phase == "Y"
                                      ? "Phase End"
                                      : "Day End"
                                  }
                                  onChange={this.changeExec}
                                >
                                  <Radio.Button value="Day End">
                                    Day End
                                  </Radio.Button>
                                  <Radio.Button value="Phase End">
                                    Phase End
                                  </Radio.Button>
                                </Radio.Group>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col></Col>
                          </Row>
                        </Col>
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={12}>
                        <center></center>
                        <Tabs
                          onChange={this.onChange}
                          activeKey={this.state.activeKey}
                          type="editable-card"
                          onEdit={this.onEdit}
                          centered
                        >
                          {this.state.panes.map((pane) => (
                            <TabPane
                              tab={pane.title}
                              key={pane.key}
                              closable={pane.closable}
                            >
                              {pane.content}
                            </TabPane>
                          ))}
                        </Tabs>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Container>
            </CardBody>
          </Card>
        </div>
      </React.Fragment>
    );
  }
}
export default connect(null, mapDispatchToProps)(Termsheet_clone);
// export default Termsheet_clone;
