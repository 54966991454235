import React, { Component } from "react";
import {
  Card,
  CardBody,
  Row,
  Col,
  UncontrolledTooltip,
  Alert,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormGroup,
  Container,
  Spinner,
} from "reactstrap";
import { Link } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Permission from "../../permission.js";
import { toast } from "react-toastify";
import validate_Token from "../../validateToken.js";
import { withNamespaces } from "react-i18next";
import "react-toastify/dist/ReactToastify.css";

toast.configure();

class UserManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropshow: false,
      editdropshow: false,
      isAlertOpen: false,
      modal_static: false,
      userlist: [],
      currentUser: "",
      userData: [
        {
          first_name: "Test",
          last_name: "User",
          designation: "Developer",
          mob_no: "8452125536",
          user_type: "KB",
          krishibhavan: "TVM",
          custom_user: {
            id: 43,
            email: "test@test.com",
          },
        },
      ],
      userdeleteMsg: "",
      currentName: "",
      currentDesignation: "",
      currentEmailId: "",
      currentPhone: "",
      currentUtype: "",
      currentKb: "",
      currentUserId: null,
      deleteuserId: null,
      name: "",
      designation: "",
      email: "",
      phoneNo: null,
      userType: "",
      krishibhavan: "",
      showloader: true,
      isDR: false,
      req: false
    };
    this.addUser = this.addUser.bind(this);
    this.editUserdata = this.editUserdata.bind(this);
    this.tog_center = this.tog_center.bind(this);
    this.getUserdata = this.getUserdata.bind(this);
    this.deleteUserData = this.deleteUserData.bind(this);
    this.confirmUserDelete = this.confirmUserDelete.bind(this);
    this.onChange = this.onChange.bind(this);
    this.handleChangeedit = this.handleChangeedit.bind(this);
  }
  componentDidMount() {
    validate_Token();
    const user_role = Permission();
    // alert(user_role[1])
    this.setState({
      currentUser: user_role[0],
    });
    if (user_role[0] == "DR") {
      this.setState({
        isDR: true,
      });
    }
    this.getUserdata();
  }
  checkkrishibhavan(krishibhavan) {
    if (krishibhavan == null || krishibhavan == "") {
      return "N/A";
    } else {
      return krishibhavan;
    }
  }
  generatetabledata(data) {
    let getthis = this;
    let tabledata = [];
    data.map(function (item, index, data) {
      var newdata = {
        id: index + 1,
        name: item.name,
        designation: item.designation,
        email: item.custom_user.email,
        phno: item.mob_no,
        usertype: item.user_type,
        krishibhavan: getthis.checkkrishibhavan(item.krishibhavan),
        action: (
          <div>
            <Link
              onClick={() => getthis.editUser(index)}
              className="mr-3 text-primary"
              id={"edit" + index}
            >
              <i className="mdi mdi-pencil font-size-18"></i>
            </Link>
            <UncontrolledTooltip target={"edit" + index} placement="left">
              Edit
            </UncontrolledTooltip>
            <Link
              className="text-danger"
              id={"delete" + index}
              onClick={() => getthis.deleteUserData(index)}
            >
              <i className="mdi mdi-trash-can font-size-18"></i>
            </Link>
            <UncontrolledTooltip target={"delete" + index} placement="left">
              Delete
            </UncontrolledTooltip>
          </div>
        ),
      };
      tabledata.push(newdata);
    });
    this.setState({
      userlist: tabledata,
    });
    // console.log("USER DETAILS", tabledata);
  }

  async getUserdata() {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + localStorage.getItem("token"),
      },
    };
    var userDataResponse = await fetch(
      process.env.REACT_APP_APIEND + `department/user`,
      requestOptions
    ).then((res) => {
      return res.json();
    });
    if (userDataResponse.hasOwnProperty("detail")) {
      // console.log("USER DATA", userDataResponse);
    } else {
      // console.log("USER DATA ELSE", userDataResponse);
      this.generatetabledata(userDataResponse);
      this.setState({
        userData: userDataResponse,
        showloader: false,
      });
    }
  }

  editUser(key) {
    /////////////////////////////////////////////////////////////////////////////
    if (this.state.currentUser != "ADM") {
      if (this.state.currentUser == this.state.userData[key].user_type) {
        toast.info("You are not authorized !");
      } else if (
        this.state.currentUser == "DR" &&
        this.state.userData[key].user_type == "ADM"
      ) {
        toast.info("You are not authorized !");
      } else {
        this.setState(
          {
            currentUtype: this.state.userData[key].user_type,
            currentName: this.state.userData[key].name,
            currentPhone: this.state.userData[key].mob_no,
            currentDesignation: this.state.userData[key].designation,
            currentEmailId: this.state.userData[key].custom_user.email,
            currentKb: this.state.userData[key].krishibhavan,
            currentUserId: this.state.userData[key].custom_user.id,
          },
          () => {
            this.tog_center();
          }
        );
      }
    } else if (this.state.currentUser == "ADM") {
      this.setState(
        {
          currentUtype: this.state.userData[key].user_type,
          currentName: this.state.userData[key].name,
          currentPhone: this.state.userData[key].mob_no,
          currentDesignation: this.state.userData[key].designation,
          currentEmailId: this.state.userData[key].custom_user.email,
          currentKb: this.state.userData[key].krishibhavan,
          currentUserId: this.state.userData[key].custom_user.id,
        },
        () => {
          this.tog_center();
        }
      );
    }

    // ==============================================================================
    if (this.state.userData[key].user_type == "KB") {
      this.setState({
        editdropshow: true,
      });
    } else {
      this.setState({
        editdropshow: false,
      });
    }
  
  }

  openAdduserbox() {
    this.setState({
      currentName: "",
    });
    this.setState({ modal_static: true, isAlertOpen: false });
  }
  tog_center() {
    this.setState((prevState) => ({
      modal_center: !prevState.modal_center,
    }));
  }
  tog_small() {
    this.setState((prevState) => ({
      modal_small: !prevState.modal_small,
    }));
  }

  onChange(ev) {
    let showKblist;
    if (this.state.userType == "DR") {
      showKblist = false;
    } else if (this.state.userType == "KB") {
      showKblist = true;
    } else if (this.state.userType == "Admin") {
      showKblist = false;
    }
    this.setState({
      [ev.target.name]: ev.target.value,
      dropshow: showKblist,
    });

    if (ev.target.value == "DR") {
      this.setState({
        dropshow: false,
        krishibhavan: "",
      });
    } else if (ev.target.value == "KB") {
      this.setState({
        dropshow: true,
        krishibhavan: "Chittur-Thathamangalam",
      });
    } else if (ev.target.value == "ADM") {
      this.setState({
        dropshow: false,
        krishibhavan: "",
      });
    }
  }

  handleChangeedit(ev) {
    if (ev.target.value == "DR") {
      this.setState({
        editdropshow: false,
        req : false
      });
    } else if (ev.target.value == "KB") {
      this.setState({
        editdropshow: true,
        req : true
      });
    } else if (ev.target.value == "ADM") {
      this.setState({
        editdropshow: false,
        req : false
      });
    }
    this.setState({
      [ev.target.name]: ev.target.value,
    });
  }

  editUserdata = () => {
    var kb = null;
    if (this.state.currentUtype == "ADM") {
      kb = "";
    }else if (this.state.currentUtype == "DR") {
      kb = "";
    } else {
      kb = this.state.currentKb;
    }
    let editUserdata = {
      name: this.state.currentName,
      designation: this.state.currentDesignation,
      mob_no: this.state.currentPhone,
      user_type: this.state.currentUtype,
      krishibhavan: kb,
    };
    this.posteditData(editUserdata);
    setTimeout(() => {
      this.setState({ modal_center: false });
    }, 2000);
  };

  addUser = () => {
    //show alert for success message
    let userData = {
      name: this.state.name,
      designation: this.state.designation,
      mob_no: this.state.phoneNo,
      email: this.state.email,
      user_type: this.state.userType,
      krishibhavan: this.state.krishibhavan,
    };
    this.postUserData(userData);
    this.setState({ isAlertOpen: true });

    //update state for closing
    setTimeout(() => {
      this.setState({ modal_static: false });
    }, 2000);
    setTimeout(() => {
      this.setState({ modal_center: false });
    }, 2000);
    setTimeout(() => {
      this.setState({ modal_small: false });
    }, 2000);
  };
  async deleteUserData(key) {
    if (this.state.currentUser != "ADM") {
      if (this.state.currentUser == this.state.userData[key].user_type) {
        toast.info("You are not authorized !");
      } else if (
        this.state.currentUser == "DR" &&
        this.state.userData[key].user_type == "ADM"
      ) {
        toast.info("You are not authorized !");
      } else {
        this.setState({
          userdeleteMsg:
            "Do you want to delete user: " + this.state.userData[key].name,
          deleteuserId: this.state.userData[key].custom_user.id,
        });

        this.tog_small();
      }
    } else if (this.state.currentUser == "ADM") {
      this.setState({
        userdeleteMsg:
          "Do you want to delete user: " + this.state.userData[key].name,
        deleteuserId: this.state.userData[key].custom_user.id,
      });

      this.tog_small();
    }
  }

  async confirmUserDelete() {
    const requestOptions = {
      method: "DELETE",
      headers: {
        Authorization: "Token " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    };
    var userDeleteResponse = await fetch(
      process.env.REACT_APP_APIEND + `department/user/` + this.state.deleteuserId,
      requestOptions
    ).then((res) => {
      return res.json();
    });
    if (userDeleteResponse.code == 200 && userDeleteResponse.message == "Ok") {
      toast.success("User Deleted Successfully!");

      this.setState({
        userDeleteResponse: "User Deleted Successfully!",
      });
    } else if(userDeleteResponse.code == 403){
      toast.error(userDeleteResponse.message);
    }
    this.setState({ modal_small: false });
    //call function to get new records
    this.getUserdata();
  }

  async posteditData(editData) {
    var data = JSON.stringify(editData);
    const requestOptions = {
      method: "PATCH",
      headers: {
        Authorization: "Token " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      body: data,
    };
    var userEditresponse = await fetch(
      process.env.REACT_APP_APIEND + `department/user/` + this.state.currentUserId,
      requestOptions
    ).then((res) => {
      return res.json();
    });
    if (userEditresponse.hasOwnProperty("name")) {
      toast.success("User Data edited Successfully");
    } else {
      toast.error("Error");
    }
    this.getUserdata();
  }

  async postUserData(userData) {
    var data = JSON.stringify(userData);


    const requestOptions = {
      method: "post",
      headers: {
        Authorization: "Token " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      body: data,
    };
    var userCreateResponse = await fetch(
      process.env.REACT_APP_APIEND + `department/user`,
      requestOptions
    ).then((res) => {
      return res.json();
    });
    if (userCreateResponse.hasOwnProperty("code")) {
      if (userCreateResponse.code == 400) {
        toast.error("User Exist with the given mail Id or Mobile Number ");
      }
    } else if (userCreateResponse.hasOwnProperty("name")) {
      toast.success("User Added Successfully!");
    } else if (userCreateResponse.hasOwnProperty("mob_no")) {
      toast.error("User Exist with the given mail Id or Mobile Number ");
    } else {
      toast.error("Something's not right");
    }
    //call function to get newly inserted records
    this.getUserdata();
  }

  render() {
    const { t, i18n } = this.props;
    let data = {
      columns: [
        {
          label: "Sl. No.",
          field: "id",
          width: 100,
        },
        {
          label: "Name",
          field: "name",
          width: 250,
        },

        {
          label: "Designation",
          field: "designation",
          width: 150,
        },
        {
          label: "Email",
          field: "email",
          width: 250,
        },
        {
          label: "Phone Number",
          field: "phno",
          width: 150,
        },
        {
          label: "User Type",
          field: "usertype",
          width: 150,
        },
        {
          label: "Krishibhavan",
          field: "krishibhavan",
          width: 150,
        },
        {
          label: "Action",
          field: "action",
          width: 150,
        },
      ],
      rows: this.state.userlist,
    };
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Row>
              <Col xs={12}>
                <h4
                  className="card-title"
                  style={{ padding: "5px", fontSize: "18px" }}
                >
                  {" "}
                  {t("User Management")}
                </h4>
              </Col>

              <Col lg={12}>
                <Card>
                  <CardBody>
                    <div>
                      <Link
                        to="#"
                        onClick={() => this.openAdduserbox()}
                        className="btn btn-success mb-2 btn-adduser"
                        style={{ fontSize: "11px" }}
                      >
                        <i className="mdi mdi-plus mr-2"></i> Add User
                      </Link>
                    </div>
                    <div
                      style={this.state.showloader ? {} : { display: "none" }}
                    >
                      <center>
                        <Spinner color="dark" />
                      </center>
                    </div>
                    <div
                      className="table-responsive mt-3"
                      id="usermanagement"
                      style={this.state.showloader ? { display: "none" } : {}}
                    >
                      <MDBDataTable
                        paging={true}
                        entries={20}
                        showPaginationBottom={false}
                        bordered
                        scrollX
                        data={data}
                        order={["id", "asc"]}
                        className="datadownloadtable"
                        style={{ fontSize: "12px" }}
                        disableRetreatAfterSorting={true}
                        maxHeight="300px"
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Modal
              isOpen={this.state.modal_static}
              toggle={this.tog_static}
              backdrop="static"
              centered
              size="lg"
            >
              <ModalHeader
                toggle={() => this.setState({ modal_static: false })}
                style={{ backgroundColor: "#A4C1D2" }}
              >
                Add User Details
              </ModalHeader>
              <ModalBody style={{ marginLeft: "10%" }}>
                <AvForm onValidSubmit={this.addUser}>
                  <Row>
                    <Col lg={5}>
                      <FormGroup>
                        <Label htmlFor="name">Name</Label>
                        <AvField
                          name="name"
                          type="text"
                          className="form-control"
                          id="custname"
                          placeholder="Enter Name"
                          required
                          onChange={this.onChange}
                          value={this.state.name}
                        />
                      </FormGroup>
                    </Col>

                    <Col lg={5}>
                      <FormGroup>
                        <Label htmlFor="desig">Designation</Label>
                        <AvField
                          name="designation"
                          type="text"
                          className="form-control"
                          id="desig"
                          placeholder="Enter designation"
                          required
                          onChange={this.onChange}
                          autocomplete="off"
                          value={this.state.designation}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={5}>
                      <FormGroup>
                        <Label htmlFor="email">Email</Label>
                        <AvField
                          name="email"
                          type="email"
                          className="form-control"
                          id="custemail"
                          required
                          placeholder="Enter Email"
                          onChange={this.onChange}
                          value={this.state.email}
                        />
                      </FormGroup>
                    </Col>

                    <Col lg={5}>
                      <FormGroup>
                        <Label htmlFor="phno">Phone Number</Label>
                        <AvField
                          name="phoneNo"
                          type="tel"
                          className="form-control"
                          id="phonenumber"
                          placeholder="Enter Phone Number"
                          required
                          onChange={this.onChange}
                          value={this.state.phoneNo}
                          validate={{ number: true }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={5}>
                      <FormGroup>
                        <Label
                          className="col-form-label"
                          style={{ fontSize: "14px" }}
                        >
                          User Type{" "}
                        </Label>

                        <AvField
                        type="select"
                          value={this.state.userType}
                          name="userType"
                          className="form-select"
                          onChange={this.onChange}
                          style={{ fontSize: "14px", color: "#505d69" }}
                          required
                        >
                          <option value="" disabled selected>
                            Select User type
                          </option>
                          <option value="DR" style={this.state.isDR ? { display: "none" } : {}}>Directorate</option>
                          <option value="KB">Krishibhavan</option>
                          <option
                            style={this.state.isDR ? { display: "none" } : {}}
                            value="ADM"
                          >
                            Admin
                          </option>
                        </AvField>
                      </FormGroup>
                    </Col>

                    <Col lg={5}>
                      <FormGroup
                        style={this.state.dropshow ? {} : { display: "none" }}
                      >
                        <Label
                          className="col-md-5 col-form-label"
                          style={{ fontSize: "14px" }}
                        >
                          Krishibhavan{" "}
                        </Label>
                        <AvField
                        type="select"
                        className="form-control"         
                        required={localStorage.getItem('user_type')=== 'DR' ? true : false} 
                          value={this.state.krishibhavan}
                          name="krishibhavan"
                          onChange={this.onChange}
                        >
                          <option value="" disabled selected>
                            Select Krishibhavan
                          </option>
                          <option value="Chittur-Thathamangalam">
                            Chittur-Thathamangalam
                          </option>
                          <option value="Eruthenpathy">Eruthenpathy</option>
                          <option value="Kozhinjampara">Kozhinjampara</option>
                          <option value="Nallepilly">Nallepilly</option>
                          <option value="Pattanchery">Pattanchery</option>
                          <option value="Perumatty">Perumatty</option>
                          <option value="Vadakarapathy">Vadakarapathy</option>
                        </AvField>
                        
                      </FormGroup>
                    </Col>
                  </Row>
                  <ModalFooter style={{ border: "none" }}>
                    <Button
                      type="button"
                      color="light"
                      onClick={() => this.setState({ modal_static: false })}
                    >
                      Cancel
                    </Button>
                    <Button type="submit" color="primary">
                      Add
                    </Button>
                  </ModalFooter>
                </AvForm>
              </ModalBody>
            </Modal>

            {/* Edit user */}

            <Modal
              isOpen={this.state.modal_center}
              toggle={this.tog_center}
              backdrop="static"
              centered
              size="lg"
            >
              <ModalHeader
                toggle={() => this.setState({ modal_center: false })}
                style={{ backgroundColor: "#A4C1D2" }}
              >
                Edit User Details
              </ModalHeader>
              <ModalBody style={{ marginLeft: "10%" }}>
                <AvForm onValidSubmit={this.editUserdata}>
                  <Row>
                    <Col lg={5}>
                      <Alert
                        color="success"
                        isOpen={this.state.isAlertOpen}
                        toggle={() => this.setState({ isAlertOpen: false })}
                      >
                        Data Updated Successfully...!
                      </Alert>
                      <FormGroup>
                        <Label htmlFor="name">Name</Label>
                        <AvField
                          type="text"
                          className="form-control"
                          id="custname"
                          placeholder="Enter Name"
                          required
                          value={this.state.currentName}
                          name="currentName"
                          onChange={this.handleChangeedit}
                        />
                      </FormGroup>
                    </Col>

                    <Col lg={5}>
                      <FormGroup>
                        <Label htmlFor="desig">Designation</Label>
                        <AvField
                          type="text"
                          className="form-control"
                          id="desig"
                          placeholder="Enter designation"
                          required
                          value={this.state.currentDesignation}
                          name="currentDesignation"
                          onChange={this.handleChangeedit}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={5}>
                      <FormGroup>
                        <Label htmlFor="email">Email</Label>
                        <AvField
                          type="email"
                          className="form-control"
                          id="custemail"
                          placeholder="Enter Email"
                          value={this.state.currentEmailId}
                          disabled={true}
                          name="currentEmailId"
                          onChange={this.handleChangeedit}
                        />
                      </FormGroup>
                    </Col>

                    <Col lg={5}>
                      <FormGroup>
                        <Label htmlFor="phno">Phone Number</Label>
                        <AvField
                          type="tel"
                          className="form-control"
                          id="phonenumber"
                          placeholder="Enter Phone Number"
                          required
                          value={this.state.currentPhone}
                          name="currentPhone"
                          onChange={this.handleChangeedit}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={5}>
                      <FormGroup>
                        <Label
                          className="col-form-label"
                          style={{ fontSize: "14px" }}
                        >
                          User Type{" "}
                        </Label>

                        <select
                          className="form-select"
                          name="currentUtype"
                          value={this.state.currentUtype}
                          onChange={this.handleChangeedit}
                        >
                          <option value="DR" style={this.state.isDR ? { display: "none" } : {}}>Directorate</option>
                          <option value="KB">Krishibhavan</option>
                          <option
                            style={this.state.isDR ? { display: "none" } : {}}
                            value="ADM"
                          >
                            Admin
                          </option>
                        </select>
                      </FormGroup>
                    </Col>

                    <Col lg={5}>
                      <FormGroup
                        style={
                          this.state.editdropshow ? {} : { display: "none" }
                        }
                      >
                        <Label
                          className="col-md-5 col-form-label"
                          style={{ fontSize: "14px" }}
                        >
                          Krishibhavan{" "}
                        </Label>
                        <AvField
                        type="select"
                          className="form-control"
                          selected={this.state.currentKb}
                          onChange={this.handleChangeedit}
                          name="currentKb"
                          required={this.state.req}
                        >
                          <option value="" disabled selected>
                            Select Krishibhavan
                          </option>
                          <option
                            name="currentKb"
                            value="Chittur-Thathamangalam"
                          >
                            Chittur-Thathamangalam
                          </option>
                          <option name="currentKb" value="Eruthenpathy">
                            Eruthenpathy
                          </option>
                          <option name="currentKb" value="Kozhinjampara">
                            Kozhinjampara
                          </option>
                          <option name="currentKb" value="Nallepilly">
                            Nallepilly
                          </option>
                          <option name="currentKb" value="Pattanchery">
                            Pattanchery
                          </option>
                          <option name="currentKb" value="Perumatty">
                            Perumatty
                          </option>
                          <option name="currentKb" value="Vadakarapathy">
                            Vadakarapathy
                          </option>
                        </AvField>
                      </FormGroup>
                    </Col>
                  </Row>
                  <ModalFooter style={{ border: "none" }}>
                    <Button
                      type="button"
                      color="light"
                      onClick={() => this.setState({ modal_center: false })}
                    >
                      Cancel
                    </Button>
                    <Button type="" color="primary">
                      Update
                    </Button>
                  </ModalFooter>
                </AvForm>
              </ModalBody>
            </Modal>

            {/* Delete user */}

            <Modal
              size="sm"
              isOpen={this.state.modal_small}
              toggle={this.tog_small}
              backdrop="static"
            >
              <ModalHeader
                toggle={() => this.setState({ modal_small: false })}
                style={{ border: "none", backgroundColor: "#A4C1D2" }}
              >
                Delete User
              </ModalHeader>
              <ModalBody>{this.state.userdeleteMsg}</ModalBody>
              <ModalFooter style={{ border: "none" }}>
                <Button
                  type="button"
                  color="light"
                  onClick={() => this.setState({ modal_small: false })}
                >
                  Cancel
                </Button>
                <Button
                  type=""
                  color="primary"
                  onClick={this.confirmUserDelete}
                >
                  Delete
                </Button>
              </ModalFooter>
            </Modal>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default withNamespaces()(UserManagement);
