import React from "react";
import { Table, Container, Row, Col, FormGroup, Label } from "reactstrap";
function checkvalue(value) {
  if (value == null) {
    return "NIL";
  } else {
    return value;
  }
}
const FarmerPolicy = (props) => {
  return (
    <React.Fragment>
      <div class="accordion" id="accordionExample">
        <div class="accordion-item">
          <a class="accordion-header" id="headingTwo">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
            >
              <a
                style={{
                  fontSize: "12px",
                  color: "#a3a3a3",
                  paddingRight: "3px",
                }}
              >
                Application Id:
              </a>
              <a style={{ fontSize: "12px" }}>
                {" "}
                {props.data.application_id} ({props.data.year})
              </a>
            </button>
          </a>
          <div
            id="collapseTwo"
            class="accordion-collapse collapse"
            aria-labelledby="headingTwo"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              <Row xs={12}>
                <Col sm={3}>
                  <FormGroup>
                    <Label
                      className="col-md-8"
                      style={{ fontSize: "12px", color: "#a3a3a3" }}
                    >
                      Scheme Name{" "}
                    </Label>
                    <Col>{checkvalue(props.data.scheme)}</Col>
                  </FormGroup>
                </Col>
                <Col sm={3}>
                  <FormGroup>
                    <Label
                      className="col-md-8"
                      style={{ fontSize: "12px", color: "#a3a3a3" }}
                    >
                      Application Source
                    </Label>
                    {/* <Col>{props.data.application_source}</Col> */}
                    <Col>NIL</Col>
                  </FormGroup>
                </Col>
                <Col sm={3}>
                  <FormGroup>
                    <Label
                      className="col-md-8"
                      style={{ fontSize: "12px", color: "#a3a3a3" }}
                    >
                      Insurance company Code & Name
                    </Label>
                    <Col>{checkvalue(props.data.scheme)}</Col>
                  </FormGroup>
                </Col>
                <Col sm={3}>
                  <FormGroup>
                    <Label
                      className="col-md-8"
                      style={{ fontSize: "12px", color: "#a3a3a3" }}
                    >
                      Farmer Type
                    </Label>
                    {/* <Col>{props.data.farmer_type}</Col> */}
                    <Col>NIL</Col>
                  </FormGroup>
                </Col>
              </Row>

              <Row xs={12}>
                <Col sm={3}>
                  <FormGroup>
                    <Label
                      className="col-md-8"
                      style={{ fontSize: "12px", color: "#a3a3a3" }}
                    >
                      Farmer Category
                    </Label>
                    {/* <Col>{props.data.farmer_category}</Col> */}
                    <Col>NIL</Col>
                  </FormGroup>
                </Col>
                <Col sm={3}>
                  <FormGroup>
                    <Label
                      className="col-md-8"
                      style={{ fontSize: "12px", color: "#a3a3a3" }}
                    >
                      Nature of Farmer
                    </Label>
                    {/* <Col>{props.data.nature_of_farmer}</Col> */}
                    <Col>NIL</Col>
                  </FormGroup>
                </Col>
                <Col sm={3}>
                  <FormGroup>
                    <Label
                      className="col-md-8"
                      style={{ fontSize: "12px", color: "#a3a3a3" }}
                    >
                      Bank Account Number
                    </Label>
                    {/* <Col>{props.data.account_number}</Col> */}
                    <Col>NIL</Col>
                  </FormGroup>
                </Col>
                <Col sm={3}>
                  <FormGroup>
                    <Label
                      className="col-md-8"
                      style={{ fontSize: "12px", color: "#a3a3a3" }}
                    >
                      Bank Account Type
                    </Label>
                    {/* <Col>{props.data.farmer_account_type}</Col> */}
                    <Col>NIL</Col>
                  </FormGroup>
                </Col>
              </Row>

              <Row xs={12}>
                <Col sm={3}>
                  <FormGroup>
                    <Label
                      className="col-md-8"
                      style={{ fontSize: "12px", color: "#a3a3a3" }}
                    >
                      Bank Branch
                    </Label>
                    {/* <Col>{props.data.branch_name}</Col> */}
                    <Col>NIL</Col>
                  </FormGroup>
                </Col>
                <Col sm={3}>
                  <FormGroup>
                    <Label
                      className="col-md-6"
                      style={{ fontSize: "12px", color: "#a3a3a3" }}
                    >
                      IFSC
                    </Label>
                    {/* <Col>{props.data.ifsc}</Col> */}
                    <Col>NIL</Col>
                  </FormGroup>
                </Col>
                <Col sm={3}>
                  <FormGroup>
                    <Label
                      className="col-md-6"
                      style={{ fontSize: "12px", color: "#a3a3a3" }}
                    >
                      Gross Premium
                    </Label>
                    {/* <Col>{parseFloat(props.data.gross_premium).toFixed(2)}</Col> */}
                    <Col>NIL</Col>
                  </FormGroup>
                </Col>
                <Col sm={3}>
                  <FormGroup>
                    <Label
                      className="col-md-6"
                      style={{ fontSize: "12px", color: "#a3a3a3" }}
                    >
                      Sum Insured
                    </Label>
                    {/* <Col>{parseFloat(props.data.sum_insured).toFixed(2)}</Col> */}
                    <Col>NIL</Col>
                  </FormGroup>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default FarmerPolicy;
