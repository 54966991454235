import React, { Component } from "react";
import {
  Container,
  Card,
  CardBody,
  Row,
  Col,
  Nav,
  Button,
  NavItem,
  NavLink,
  TabPane,
  TabContent,
  Form,
  Input,
  Table,
  FormGroup,
  Label,
  CardText,
} from "reactstrap";
import { Link } from "react-router-dom";
import $ from "jquery";

import Chart from "react-apexcharts";
import ApexCharts from "apexcharts";
import classnames from "classnames";
import Hightemptable from "./hightemtable.jsx";
import Deficittable from "./deficitrain.jsx";
import Excessraintable from "./excessraintable.jsx";
import Dryspelltable from "./dryspelltable.jsx";
import Congenialtable from "./congenialtable.jsx";
import loader from "../Dashboard/images/loader.gif";
import Termsheettable from "./fm_termsheet_table";
import Permission from "../../permission.js";
import { toast } from "react-toastify";
import { withNamespaces } from "react-i18next";
import "react-toastify/dist/ReactToastify.css";
import termsheet_json from "../Adm-components/Termsheet.json";

class termSheet extends Component {
  constructor(props) {
    super(props);

    this.state = {
      location: "10.72918673,76.78622876",
      season: "",
      crop: "",
      peril: "",
      dprovider: "",
      activeTab: "1",
      activeTab1: "5",
      activeTab2: "9",
      activeTab3: "13",
      customActiveTab: "1",
      activeTabJustify: "5",
      isDiseaseCong: false,
      isExcessrain: false,
      phaseselection: [],
      allseasonData: [],
      seasonData: [],
      col1: true,
      col2: false,
      col3: false,
      tmtable: false,
      col5: true,
      showchart: false,
      hightemp: false,
      deficitrain: false,
      excessrain: false,
      dryspell: false,
      diseasecongenial: false,
      showWaiting: true,
      showPhaseselector: false,
      showLoader: false,
      rua: "",
      showCongenial: false,
      isGetdatadisabled: false,
      selectedValue: "",
      dloc: "",
      dperil: "High Temperature",
      kfdcolor: "#151714",
      kfowcolor: "#151714",
      kfallcolor: "#151714",
      kfsencolor: "#151714",
      kfmetcolor: "#151714",
      kfecmwfcolor: "#151714",
      cropData: [],
      allstDate: "",
      allendDate: "",
      kfnasacolor: "#151714",
      kfaiccolor: "#151714",
      showdeficit: true,
      showfilter: true,
      ddloc: "",
      ddperil: "High Temperature",
      ddseason: "kharif",
      user_DR: false,
      user_KB: false,
      latnew: 10.72918673,
      longnew: 76.78622876,
      perilData: [],
      dpData: [],
      tableseason: "Kharif 2020",
      tablecrop: "Paddy",
      tableperil: "High Temperature",
      tabledp: "AIC",
      farmer_id: "",

      //CHITTUR
      kb1: false,

      //ERUTHENPATHY
      kb2: false,

      //KOZJINJAMPARA
      kb3: false,

      //NALLEPILLY
      kb4: false,

      //PATTANCHERY
      kb5: false,

      //PERUMATTY
      kb6: false,

      //VADAKARAPATHY
      kb7: false,
      options: {
        chart: {
          id: "area-datetime",
          type: "area",
          height: 350,
          zoom: {
            autoScaleYaxis: true,
          },
        },
        xaxis: {
          type: "datetime",
          tickAmount: 6,
          labels: {
            datetimeUTC: false,
            datetimeFormatter: {
              year: "yyyy",
              month: "dd MMM",
              day: "dd MMM",
              hour: "HH:mm",
            },
          },
          title: {
            text: "Date/Time",
            rotate: -90,
            offsetX: 0,
            offsetY: 0,
            style: {
              color: undefined,
              fontSize: "12px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 600,
              cssClass: "apexcharts-yaxis-title",
            },
          },
        },
        tooltip: {
          x: {
            format: "dd.MM.yyyy",
          },
        },
        legend: {
          show: false,
        },
      },
      series: [
        {
          name: "Test Data",
          data: [],
        },
        {
          name: "Test Data2",
          data: [
            { x: 1615362718000, y: 77.95 },
            { x: 1615363619000, y: 90.34 },
            { x: 1615364518000, y: 24.18 },
            { x: 1615365418000, y: 21.05 },
            { x: 1615366318000, y: 71.0 },
            { x: 1615367218000, y: 80.95 },
            { x: 1615369018000, y: 81.24 },
            { x: 1615369918000, y: 51.29 },
            { x: 1615467413000, y: 41.85 },
            { x: 1615467520000, y: 21.86 },
            { x: 1615467681000, y: 12.28 },
            { x: 1615468200000, y: 82.1 },
          ],
        },
      ],
      optionscongenial1: {
        chart: {
          id: "tmaxcongenial",
          group: "congenial",
          type: "area",
          height: 350,
          stacked: false,
          zoom: {
            autoScaleYaxis: true,
          },
        },
        xaxis: {
          type: "datetime",
          tickAmount: 6,
          labels: {
            datetimeFormatter: {
              year: "yyyy",
              month: "dd MMM",
              day: "dd MMM",
              hour: "HH:mm",
            },
          },
          title: {
            text: "Date/Time",
            rotate: -90,
            offsetX: 0,
            offsetY: 0,
            style: {
              color: undefined,
              fontSize: "12px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 600,
              cssClass: "apexcharts-yaxis-title",
            },
          },
        },
        yaxis: {
          min: 0,
          max: 50,
          title: {
            text: "Temperature (°C)",
            rotate: -90,
            offsetX: 0,
            offsetY: 0,
            style: {
              color: undefined,
              fontSize: "12px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 600,
              cssClass: "apexcharts-yaxis-title",
            },
          },
        },
        tooltip: {
          x: {
            format: "dd MMM yyyy",
          },
        },
        title: {
          text: "TEMPERATURE",
          align: "left",
        },
      },
      seriescongenial1: [
        {
          name: "temp test",
          data: [],
        },
      ],
      optionscongenial2: {
        chart: {
          id: "humiditycongenial",
          group: "congenial",
          height: 350,
          stacked: false,
          zoom: {
            autoScaleYaxis: true,
          },
        },
        xaxis: {
          type: "datetime",
          tickAmount: 6,
          labels: {
            datetimeFormatter: {
              year: "yyyy",
              month: "dd MMM",
              day: "dd MMM",
              hour: "HH:mm",
            },
          },
          title: {
            text: "Date/Time",
            rotate: -90,
            offsetX: 0,
            offsetY: 0,
            style: {
              color: undefined,
              fontSize: "12px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 600,
              cssClass: "apexcharts-yaxis-title",
            },
          },
        },
        yaxis: {
          show: true,
          min: 0,
          title: {
            text: "Humidity (%)",
            rotate: -90,
            offsetX: 0,
            offsetY: 0,
            style: {
              color: undefined,
              fontSize: "12px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 600,
              cssClass: "apexcharts-yaxis-title",
            },
          },
        },
        tooltip: {
          x: {
            format: "dd MMM yyyy",
          },
        },
        title: {
          text: "HUMIDITY",
          align: "left",
        },
      },
      seriescongenial2: [
        {
          name: "Data humidity",
          data: [],
        },
      ],
    };
    this.handleLocationchange = this.handleLocationchange.bind(this);
    this.handleCropchange = this.handleCropchange.bind(this);
    this.handleSeasonchange = this.handleSeasonchange.bind(this);
    this.handlePerilchange = this.handlePerilchange.bind(this);
    this.getdata = this.getdata.bind(this);
    this.toggle = this.toggle.bind(this);
    this.phaseSelector = this.phaseSelector.bind(this);
    this.handleKfilter = this.handleKfilter.bind(this);
    this.handlecongenialKfilter = this.handlecongenialKfilter.bind(this);
    this.handledpchange = this.handledpchange.bind(this);
    this.getPerilSeason = this.getPerilSeason.bind(this);
  }
  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }
  handleLocationchange(e) {
    this.setState({ location: e.target.value });
    if (e.target.value === "10.69300691,76.72595507") {
      this.setState({ rua: "chittur" });
      this.setState({ dloc: "Chittur Thathamangalam" });
    }
    if (
      e.target.value === "10.74866619,76.85879364" ||
      e.target.value === "10.736,76.862"
    ) {
      this.setState({ rua: "eruthenpathy" });
      this.setState({ dloc: "Eruthenpathy" });
    }
    if (
      e.target.value === "10.738,76.834" ||
      e.target.value === "10.73595019,76.82999565"
    ) {
      this.setState({ rua: "kozhinjampara" });
      this.setState({ dloc: "Kozhinjampara" });
    }
    if (
      e.target.value === "10.72918673,76.78622876" ||
      e.target.value === "10.73,76.786"
    ) {
      this.setState({ rua: "nallepilly" });
      this.setState({ dloc: "Nallepilly" });
    }
    if (e.target.value === "10.64777998,76.73270522") {
      this.setState({ rua: "pattanchery" });
      this.setState({ dloc: "Pattanchery" });
    }
    if (
      e.target.value === "10.662,76.787" ||
      e.target.value === "10.64975298,76.76319652"
    ) {
      this.setState({ rua: "perumatty" });
      this.setState({ dloc: "Perumatty" });
    }
    if (
      e.target.value === "10.807,76.846" ||
      e.target.value === "10.81,76.846"
    ) {
      this.setState({ rua: "vadakarapathy" });
      this.setState({ dloc: "Vadakarapathy" });
    }
  }
  handleCropchange(e) {
    this.setState({ crop: e.target.value, tablecrop: e.target.value });
    if (this.state.season.slice(0, 3) == "Kha" && e.target.value == "Paddy") {
      this.setState({
        perilData: [
          "High Temperature",
          "Deficit Rainfall",
          "Excess Rainfall",
          "Dry Spell",
          "Disease Congenial Climate",
        ],
        peril: "High Temperature",
        tableperil: "High Temperature",
      });
    }
    ////////////////////////////////
    if (this.state.season == "Kharif 2020" && e.target.value == "Paddy") {
      this.setState({
        perilData: [
          "High Temperature",
          "Deficit Rainfall",
          "Excess Rainfall",
          "Dry Spell",
          "Disease Congenial Climate",
        ],
        peril: "High Temperature",
        tableperil: "High Temperature",
      });
    }
    if (this.state.season == "Kharif 2020" && e.target.value == "Veg Group I") {
      this.setState({
        perilData: [
          "Rise in Temperature",
          "Excess Rainfall",
          "Disease Congenial Climate",
        ],
        peril: "Rise in Temperature",
        tableperil: "Rise in Temperature",
      });
    }
    if (this.state.season == "Kharif 2021" && e.target.value == "Paddy") {
      this.setState({
        perilData: [
          "High Temperature",
          "Deficit Rainfall",
          "Excess Rainfall",
          "Dry Spell",
          "Disease Congenial Climate",
        ],
        peril: "High Temperature",
        tableperil: "High Temperature",
      });
    }
    if (this.state.season == "Kharif 2021" && e.target.value == "Veg Group I") {
      this.setState({
        perilData: [
          "Rise in Temperature",
          "Excess Rainfall",
          "Disease Congenial Climate",
        ],
        peril: "Rise in Temperature",
        tableperil: "Rise in Temperature",
      });
    }
    if (this.state.season == "Kharif 2022" && e.target.value == "Paddy") {
      this.setState({
        perilData: [
          "High Temperature",
          "Deficit Rainfall",
          "Excess Rainfall",
          "Dry Spell",
          "Disease Congenial Climate",
        ],
        peril: "High Temperature",
        tableperil: "High Temperature",
      });
    }
    if (this.state.season == "Kharif 2022" && e.target.value == "Veg Group I") {
      this.setState({
        perilData: [
          "Rise in Temperature",
          "Excess Rainfall",
          "Disease Congenial Climate",
        ],
        peril: "Rise in Temperature",
        tableperil: "Rise in Temperature",
      });
    }
    if (this.state.season.slice(0, 3) == "Rab" && e.target.value == "Mango") {
      this.setState({
        perilData: [
          "Excess Rainfall",
          "Wind Speed",
          "Disease Congenial Climate",
        ],
        peril: "Excess Rainfall",
      });
    }
    if (
      this.state.season.slice(0, 3) == "Rab" &&
      e.target.value == "Paddy 2nd Crop"
    ) {
      this.setState({
        perilData: [
          "Excess Rainfall",
          "Wind Speed (A)",
          "Wind Speed (B)",
          "Disease Congenial Climate",
          "Deficit Rainfall",
          "Unseasonal Rainfall",
        ],
        peril: "Excess Rainfall",
        tableperil: "Excess Rainfall",
      });
    }
    //  ===============================
    if (this.state.season.slice(0, 3) == "Rab" && e.target.value == "Mango") {
      this.setState({
        perilData: [
          "Excess Rainfall",
          "Wind Speed",
          "Disease Congenial Climate",
        ],
        peril: "Excess Rainfall",
        tableperil: "Excess Rainfall",
      });
    }
    if (
      this.state.season.slice(0, 3) == "Rab" &&
      e.target.value == "Paddy 2nd Crop"
    ) {
      this.setState({
        perilData: [
          "Excess Rainfall",
          "Wind Speed (A)",
          "Wind Speed (B)",
          "Disease Congenial Climate",
          "Deficit Rainfall",
          "Unseasonal Rainfall",
        ],
        peril: "Excess Rainfall",
        tableperil: "Excess Rainfall",
      });
    }
    if (
      this.state.season.slice(0, 3) == "Rab" &&
      e.target.value == "Paddy 3rd Crop"
    ) {
      this.setState({
        perilData: [
          "Excess Rainfall",
          "High Temperature",
          "Disease Congenial Climate",
          "Deficit Rainfall",
        ],
        peril: "Excess Rainfall",
        tableperil: "Excess Rainfall",
      });
    }
    if (
      this.state.season.slice(0, 3) == "Rab" &&
      e.target.value == "Paddy Winter"
    ) {
      this.setState({
        perilData: [
          "Excess Rainfall",
          "High Temperature",
          "Disease Congenial Climate",
          "Deficit Rainfall",
        ],
        peril: "Excess Rainfall",
        tableperil: "Excess Rainfall",
      });
    }
    if (
      this.state.season.slice(0, 3) == "Rab" &&
      e.target.value == "Veg Group I"
    ) {
      this.setState({
        perilData: [
          "Excess Rainfall",
          "Rise in Temperature",
          "Disease Congenial Climate",
        ],
        peril: "Excess Rainfall",
        tableperil: "Excess Rainfall",
      });
    }
    if (
      this.state.season.slice(0, 3) == "Rab" &&
      e.target.value == "Veg Group I - SMR"
    ) {
      this.setState({
        perilData: [
          "Excess Rainfall",
          "Deficit Rainfall",
          "Rise in Temperature",
          "Disease Congenial Climate",
        ],
        peril: "Excess Rainfall",
        tableperil: "Excess Rainfall",
      });
    }
    if (this.state.season.slice(0, 3) == "Rab" && e.target.value == "Mango") {
      this.setState({
        perilData: [
          "Excess Rainfall",
          "Wind Speed",
          "Disease Congenial Climate",
        ],
        peril: "Excess Rainfall",
        tableperil: "Excess Rainfall",
      });
    }
    if (
      this.state.season.slice(0, 3) == "Rab" &&
      e.target.value == "Paddy 2nd Crop"
    ) {
      this.setState({
        perilData: [
          "Excess Rainfall",
          "Wind Speed (A)",
          "Wind Speed (B)",
          "Disease Congenial Climate",
          "Deficit Rainfall",
          "Unseasonal Rainfall",
        ],
        peril: "Excess Rainfall",
        tableperil: "Excess Rainfall",
      });
    }
  }
  handleSeasonchange(e) {
    this.setState({ season: e.target.value, tableseason: e.target.value });
    // console.log("CROP DATA", this.state.allseasonData);
    let newcropData = [];
    this.state.allseasonData.map(function (seasons, index, data) {
      var currentSeason = Object.keys(seasons);
      if (currentSeason[0] == e.target.value) {
        seasons[currentSeason[0]].map(function (crops) {
          newcropData.push(crops.crop);
        });
      }
    });
    this.setState(
      {
        cropData: newcropData,
        crop: newcropData[0],
      },
      () => {
        // console.log("CROPS IN NEW", this.state.crop);
        // console.log("SEASON IN NEW", e.target.value);
        if (e.target.value == "Kharif 2020" && this.state.crop == "Paddy") {
          this.setState({
            perilData: [
              "High Temperature",
              "Deficit Rainfall",
              "Excess Rainfall",
              "Dry Spell",
              "Disease Congenial Climate",
            ],
            peril: "High Temperature",
            tableperil: "High Temperature",
            dpData: ["AIC", "Nasa Power", "MeteoBlue", "ECMWF"],
            tabledp: "AIC",
            tablecrop: "Paddy",
          });
        }
        if (
          e.target.value == "Kharif 2020" &&
          this.state.crop == "Veg Group I"
        ) {
          this.setState({
            perilData: [
              "Rise in Temperature",
              "Excess Rainfall",
              "Disease Congenial Climate",
            ],
            peril: "Rise in Temperature",
            tableperil: "Rise in Temperature",
            dpData: ["AIC", "Nasa Power", "MeteoBlue", "ECMWF"],
            tabledp: "AIC",
            tablecrop: "Veg Group I",
          });
        }
        if (e.target.value == "Kharif 2021" && this.state.crop == "Paddy") {
          this.setState({
            perilData: [
              "High Temperature",
              "Deficit Rainfall",
              "Excess Rainfall",
              "Dry Spell",
              "Disease Congenial Climate",
            ],
            peril: "High Temperature",
            tableperil: "High Temperature",
            dpData: ["Online Sensors"],
            tabledp: "Online Sensors",
            tablecrop: "Paddy",
          });
        }
        if (
          e.target.value == "Kharif 2021" &&
          this.state.crop == "Veg Group I"
        ) {
          this.setState({
            perilData: [
              "Rise in Temperature",
              "Excess Rainfall",
              "Disease Congenial Climate",
            ],
            peril: "Rise in Temperature",
            tableperil: "Rise in Temperature",
            dpData: ["Online Sensors"],
            tabledp: "Online Sensors",
            tablecrop: "Veg Group I",
          });
        } else if (e.target.value == "Rabi 2020-21") {
          this.setState({
            dpData: ["DarkSky"],
            tabledp: "DarkSky",
          });
        }
        if (e.target.value == "Kharif 2022" && this.state.crop == "Paddy") {
          this.setState({
            perilData: [
              "High Temperature",
              "Deficit Rainfall",
              "Excess Rainfall",
              "Dry Spell",
              "Disease Congenial Climate",
            ],
            peril: "High Temperature",
            tableperil: "High Temperature",
            dpData: ["Online Sensors"],
            tabledp: "Online Sensors",
            tablecrop: "Paddy",
          });
        }
        if (
          e.target.value == "Kharif 2022" &&
          this.state.crop == "Veg Group I"
        ) {
          this.setState({
            perilData: [
              "Rise in Temperature",
              "Excess Rainfall",
              "Disease Congenial Climate",
            ],
            peril: "Rise in Temperature",
            tableperil: "Rise in Temperature",
            dpData: ["Online Sensors"],
            tabledp: "Online Sensors",
            tablecrop: "Veg Group I",
          });
        }

        if (e.target.value.slice(0, 3) == "Rab" && this.state.crop == "Mango") {
          this.setState({
            perilData: [
              "Excess Rainfall",
              "Wind Speed",
              "Disease Congenial Climate",
            ],
            peril: "Excess Rainfall",
            tableperil: "Excess Rainfall",
            dpData: ["Online Sensors"],
            tabledp: "Online Sensors",
            tablecrop: "Mango",
          });
        }
        if (
          e.target.value.slice(0, 3) == "Rab" &&
          this.state.crop == "Paddy 2nd Crop"
        ) {
          this.setState({
            perilData: [
              "Excess Rainfall",
              "Wind Speed (A)",
              "Wind Speed (B)",
              "Disease Congenial Climate",
              "Deficit Rainfall",
              "Unseasonal Rainfall",
            ],
            peril: "Excess Rainfall",
            tableperil: "Excess Rainfall",
            dpData: ["Online Sensors"],
            tabledp: "Online Sensors",
            tablecrop: "Paddy 2nd Crop",
          });
        }
        if (
          e.target.value.slice(0, 3) == "Rab" &&
          this.state.crop == "Paddy 3rd Crop"
        ) {
          this.setState({
            perilData: [
              "Excess Rainfall",
              "High Temperature",
              "Disease Congenial Climate",
              "Deficit Rainfall",
            ],
            peril: "Excess Rainfall",
            tableperil: "Excess Rainfall",
            dpData: ["Online Sensors"],
            tabledp: "Online Sensors",
            tablecrop: "Paddy 3rd Crop",
          });
        }
        if (
          e.target.value.slice(0, 3) == "Rab" &&
          this.state.crop == "Paddy Winter"
        ) {
          this.setState({
            perilData: [
              "Excess Rainfall",
              "High Temperature",
              "Disease Congenial Climate",
              "Deficit Rainfall",
            ],
            peril: "Excess Rainfall",
            tableperil: "Excess Rainfall",
            dpData: ["Online Sensors"],
            tabledp: "Online Sensors",
            tablecrop: "Paddy Winter",
          });
        }
        if (
          e.target.value.slice(0, 3) == "Rab" &&
          this.state.crop == "Veg Group I"
        ) {
          this.setState({
            perilData: [
              "Excess Rainfall",
              "Rise in Temperature",
              "Disease Congenial Climate",
            ],
            peril: "Excess Rainfall",
            tableperil: "Excess Rainfall",
            dpData: ["Online Sensors"],
            tabledp: "Online Sensors",
            tablecrop: "Veg Group I",
          });
        }
        if (
          e.target.value.slice(0, 3) == "Rab" &&
          this.state.crop == "Veg Group I - SMR"
        ) {
          this.setState({
            perilData: [
              "Excess Rainfall",
              "Deficit Rainfall",
              "Rise in Temperature",
              "Disease Congenial Climate",
            ],
            peril: "Excess Rainfall",
            tableperil: "Excess Rainfall",
            dpData: ["Online Sensors"],
            tabledp: "Online Sensors",
            tablecrop: "Veg Group I - SMR",
          });
        }
        if (e.target.value.slice(0, 3) == "Rab" && this.state.crop == "Mango") {
          this.setState({
            perilData: [
              "Excess Rainfall",
              "Wind Speed",
              "Disease Congenial Climate",
            ],
            peril: "Excess Rainfall",
            tableperil: "Excess Rainfall",
            dpData: ["Online Sensors"],
            tabledp: "Online Sensors",
            tablecrop: "Mango",
          });
        }
        if (
          e.target.value.slice(0, 3) == "Rab" &&
          this.state.crop == "Paddy 2nd Crop"
        ) {
          this.setState({
            perilData: [
              "Excess Rainfall",
              "Wind Speed (A)",
              "Wind Speed (B)",
              "Disease Congenial Climate",
              "Deficit Rainfall",
              "Unseasonal Rainfall",
            ],
            peril: "Excess Rainfall",
            tableperil: "Excess Rainfall",
            dpData: ["Online Sensors"],
            tabledp: "Online Sensors",
            tablecrop: "Paddy 2nd Crop",
          });
        }
        if (e.target.value == "Rabi 2020-21") {
          this.setState({
            dpData: ["DarkSky"],
            tabledp: "darksky",
          });
        }
      }
    );
  }

  handleDataProvider(e) {
    // console.log(e.target.value);
    this.setState({ dprovider: e.target.value });
  }

  handlePerilchange(e) {
    this.setState({ peril: e.target.value, tableperil: e.target.value }, () => {
      // console.log("SELECTED PERIL ", this.state.peril);
    });
    // if (e.target.value === "hightemp") {
    //   this.setState({
    //     dperil: "High Temperature",
    //     tableperil: "High Temperature",
    //   });
    // } else if (e.target.value === "deficitrain") {
    //   this.setState({ dperil: "Deficit Rain", tableperil: "Deficit Rainfall" });
    // } else if (e.target.value === "excessrain") {
    //   this.setState({ dperil: "Excess Rain", tableperil: "Excess Rainfall" });
    // } else if (e.target.value === "dryspell") {
    //   this.setState({ dperil: "Dry Spell", tableperil: "Dry Spell" });
    // } else if (e.target.value === "diseasecongenial") {
    //   this.setState({
    //     dperil: "Disease Congenial",
    //     tableperil: "Disease Congenial Climate",
    //   });
    // }
  }

  phaseSelector(e) {
    this.setState({
      selectedValue: e.target.value,
    });

    if (e.target.id == "p1") {
      // console.log("this state", this.state);
      var season = this.state.season;
      var crop = this.state.crop;
      var peril = this.state.tableperil;
      // console.log("SEASON", season, "CROP", crop, "Peril", peril);
      var strike =
        termsheet_json[season].crop[crop].peril[peril].Phase_1.strike;
      ApexCharts.exec("area-datetime", "removeAnnotation", "phase4");
      ApexCharts.exec("area-datetime", "removeAnnotation", "phase2");
      ApexCharts.exec("area-datetime", "removeAnnotation", "phase3");
      ApexCharts.exec("area-datetime", "removeAnnotation", "phase5");
      ApexCharts.exec("area-datetime", "addYaxisAnnotation", {
        id: "phase1",
        y: strike,
        borderColor: "#74007E",
        label: {
          borderColor: "#74007E",
          style: {
            color: "#fff",
            background: "#74007E",
          },
          text: "Strike-Phase 1",
        },
      });
      let st = this.state.phaseselection[0].Phase_1;
      st = st[0].from_date;
      st = new Date(st).getTime();
      let end = this.state.phaseselection[0].Phase_1;
      end = end[0].end_date;
      end = new Date(end).getTime();
      // console.log("FM_DATE", st);
      // console.log("TO_DATE", end);
      ApexCharts.exec("area-datetime", "zoomX", st, end);
    } else if (e.target.id == "p2") {
      var season = this.state.season;
      var crop = this.state.crop;
      var peril = this.state.tableperil;
      // console.log("SEASON", season, "CROP", crop, "Peril", peril);
      var strike =
        termsheet_json[season].crop[crop].peril[peril].Phase_2.strike;
      ApexCharts.exec("area-datetime", "removeAnnotation", "phase1");
      ApexCharts.exec("area-datetime", "removeAnnotation", "phase3");
      ApexCharts.exec("area-datetime", "removeAnnotation", "phase4");
      ApexCharts.exec("area-datetime", "removeAnnotation", "phase5");
      ApexCharts.exec("area-datetime", "addYaxisAnnotation", {
        id: "phase2",
        y: strike,
        borderColor: "#FF0000",
        label: {
          borderColor: "#FF0000",
          offsetX: -430,

          style: {
            color: "#fff",
            background: "#FF0000",
          },
          text: "Strike-Phase 2",
        },
      });
      let st = this.state.phaseselection[1].Phase_2;
      st = st[0].from_date;
      st = new Date(st).getTime();

      let end = this.state.phaseselection[1].Phase_2;
      end = end[0].end_date;
      end = new Date(end).getTime();
      ApexCharts.exec("area-datetime", "zoomX", st, end);
    } else if (e.target.id == "p3") {
      var season = this.state.season;
      var crop = this.state.crop;
      var peril = this.state.tableperil;
      // console.log("SEASON", season, "CROP", crop, "Peril", peril);
      var strike =
        termsheet_json[season].crop[crop].peril[peril].Phase_3.strike;
      ApexCharts.exec("area-datetime", "removeAnnotation", "phase1");
      ApexCharts.exec("area-datetime", "removeAnnotation", "phase2");
      ApexCharts.exec("area-datetime", "removeAnnotation", "phase4");
      ApexCharts.exec("area-datetime", "removeAnnotation", "phase5");
      ApexCharts.exec("area-datetime", "addYaxisAnnotation", {
        id: "phase3",
        y: strike,
        borderColor: "#FE670A",
        label: {
          borderColor: "#FE670A",
          offsetX: -200,
          style: {
            color: "#fff",
            background: "#FE670A",
          },
          text: "Strike-Phase 3",
        },
      });
      let st = this.state.phaseselection[2].Phase_3;
      st = st[0].from_date;
      st = new Date(st).getTime();

      let end = this.state.phaseselection[2].Phase_3;
      end = end[0].end_date;
      end = new Date(end).getTime();
      ApexCharts.exec("area-datetime", "zoomX", st, end);
    } else if (e.target.id == "p4") {
      var season = this.state.season;
      var crop = this.state.crop;
      var peril = this.state.tableperil;
      // console.log("SEASON", season, "CROP", crop, "Peril", peril);
      var strike =
        termsheet_json[season].crop[crop].peril[peril].Phase_4.strike;
      ApexCharts.exec("area-datetime", "removeAnnotation", "phase1");
      ApexCharts.exec("area-datetime", "removeAnnotation", "phase2");
      ApexCharts.exec("area-datetime", "removeAnnotation", "phase3");
      ApexCharts.exec("area-datetime", "removeAnnotation", "phase5");
      ApexCharts.exec("area-datetime", "addYaxisAnnotation", {
        id: "phase4",
        y: strike,
        borderColor: "#FFAA0D",
        label: {
          borderColor: "#FFAA0D",
          offsetX: -100,
          style: {
            color: "#fff",
            background: "#FFAA0D",
          },
          text: "Strike-Phase 4",
        },
      });
      let st = this.state.phaseselection[3].Phase_4;
      st = st[0].from_date;
      st = new Date(st).getTime();

      let end = this.state.phaseselection[3].Phase_4;
      end = end[0].end_date;
      end = new Date(end).getTime();
      ApexCharts.exec("area-datetime", "zoomX", st, end);
    }else if (e.target.id == "p5") {
      var season = this.state.season;
      var crop = this.state.crop;
      var peril = this.state.tableperil;
      // console.log("SEASON", season, "CROP", crop, "Peril", peril);
      var strike =
        termsheet_json[season].crop[crop].peril[peril].Phase_5.strike;
      ApexCharts.exec("area-datetime", "removeAnnotation", "phase1");
      ApexCharts.exec("area-datetime", "removeAnnotation", "phase2");
      ApexCharts.exec("area-datetime", "removeAnnotation", "phase3");
      ApexCharts.exec("area-datetime", "removeAnnotation", "phase4");
      ApexCharts.exec("area-datetime", "addYaxisAnnotation", {
        id: "phase5",
        y: strike,
        borderColor: "#ffd505",
        label: {
          borderColor: "#ffd505",
          offsetX: -100,
          style: {
            color: "#fff",
            background: "#ffd505",
          },
          text: "Strike-Phase 5",
        },
      });
      let st = this.state.phaseselection[4].Phase_5;
      st = st[0].from_date;
      st = new Date(st).getTime();

      let end = this.state.phaseselection[4].Phase_5;
      end = end[0].end_date;
      end = new Date(end).getTime();
      ApexCharts.exec("area-datetime", "zoomX", st, end);
    } else if (e.target.id == "all") {
      // console.log("ALL ST END", this.state.allstDate, this.state.allendDate);
      // console.log("ALL PHASEs", this.state.phaseselection.length);
      ApexCharts.exec("area-datetime", "removeAnnotation", "phase1");
      ApexCharts.exec("area-datetime", "removeAnnotation", "phase2");
      ApexCharts.exec("area-datetime", "removeAnnotation", "phase3");
      ApexCharts.exec("area-datetime", "removeAnnotation", "phase4");
      ApexCharts.exec("area-datetime", "removeAnnotation", "phase5");
      var season = this.state.season;
      var crop = this.state.crop;
      var peril = this.state.tableperil;
      var phases = this.state.phaseselection.length;
      // {
      //   this.state.phaseselection.map((item, index, data) => {
      //     var phase = [Object.keys(item)[0]];
      //     var strike =
      //       termsheet_json[season].crop[crop].peril[peril][phase].strike;
      //     ApexCharts.exec("area-datetime", "addYaxisAnnotation", {
      //       id: String( Object.keys(item)[0]),
      //       y: strike,
      //       borderColor: "#FFAA0D",
      //       label: {
      //         borderColor: "#FFAA0D",
      //         offsetX: -50,
      //         offsetX: -50,
      //         style: {
      //           color: "#fff",
      //           background: "#FFAA0D",
      //         },
      //         text: "Strike- "+ Object.keys(item)[0],
      //       },
      //     });
      //     console.log("ITEMSS", Object.keys(item)[0]);
      //   });
      // }
      // var strike1 =
      // termsheet_json[season].crop[crop].peril[peril].Phase_1.strike;
      // var strike2 =
      // termsheet_json[season].crop[crop].peril[peril].Phase_2.strike;
      // var strike3 =
      // termsheet_json[season].crop[crop].peril[peril].Phase_3.strike;

      ApexCharts.exec("area-datetime", "resetSeries");
    }
  }
  handlecongenialKfilter(e) {
    if (e.target.id == "aic") {
      this.setState({
        kfaiccolor: "#94de71",
        kfdcolor: "#151714",
        kfowcolor: "#151714",
        kfallcolor: "#151714",
        kfsencolor: "#151714",
        kfmetcolor: "#151714",
        kfecmwfcolor: "#151714",
        kfnasacolor: "#151714",
      });
      ApexCharts.exec("tmaxcongenial", "hideSeries", "ECMWF");
      ApexCharts.exec("tmaxcongenial", "hideSeries", "Meteo Blue");
      ApexCharts.exec("tmaxcongenial", "hideSeries", "Nasa Power Larc");
      ApexCharts.exec("tmaxcongenial", "hideSeries", "Online Sensor");
      //Show current series even if it is hidden
      ApexCharts.exec("tmaxcongenial", "showSeries", "AIC");

      ApexCharts.exec("humiditycongenial", "hideSeries", "ECMWF");
      ApexCharts.exec("humiditycongenial", "hideSeries", "Meteo Blue");
      ApexCharts.exec("humiditycongenial", "hideSeries", "Nasa Power Larc");
      ApexCharts.exec("humiditycongenial", "hideSeries", "Online Sensor");
      //Show current series even if it is hidden
      ApexCharts.exec("humiditycongenial", "showSeries", "AIC");
    } else if (e.target.id == "nasa") {
      this.setState({
        kfnasacolor: "#94de71",
        kfdcolor: "#151714",
        kfowcolor: "#151714",
        kfallcolor: "#151714",
        kfsencolor: "#151714",
        kfmetcolor: "#151714",
        kfecmwfcolor: "#151714",
        kfaiccolor: "#151714",
      });
      ApexCharts.exec("tmaxcongenial", "hideSeries", "ECMWF");
      ApexCharts.exec("tmaxcongenial", "hideSeries", "AIC");
      ApexCharts.exec("tmaxcongenial", "hideSeries", "Meteo Blue");
      ApexCharts.exec("tmaxcongenial", "hideSeries", "Online Sensor");
      //Show current series even if it is hidden
      ApexCharts.exec("tmaxcongenial", "showSeries", "Nasa Power Larc");

      ApexCharts.exec("humiditycongenial", "hideSeries", "ECMWF");
      ApexCharts.exec("humiditycongenial", "hideSeries", "AIC");
      ApexCharts.exec("humiditycongenial", "hideSeries", "Meteo Blue");
      ApexCharts.exec("humiditycongenial", "hideSeries", "Online Sensor");
      //Show current series even if it is hidden
      ApexCharts.exec("humiditycongenial", "showSeries", "Nasa Power Larc");
    } else if (e.target.id == "ecmwf") {
      this.setState({
        kfecmwfcolor: "#94de71",
        kfdcolor: "#151714",
        kfowcolor: "#151714",
        kfallcolor: "#151714",
        kfsencolor: "#151714",
        kfmetcolor: "#151714",
        kfnasacolor: "#151714",
        kfaiccolor: "#151714",
      });

      ApexCharts.exec("tmaxcongenial", "hideSeries", "AIC");
      ApexCharts.exec("tmaxcongenial", "hideSeries", "Meteo Blue");
      ApexCharts.exec("tmaxcongenial", "hideSeries", "Nasa Power Larc");
      ApexCharts.exec("tmaxcongenial", "hideSeries", "Online Sensor");
      //Show current series even if it is hidden
      ApexCharts.exec("tmaxcongenial", "showSeries", "ECMWF");

      ApexCharts.exec("humiditycongenial", "hideSeries", "AIC");
      ApexCharts.exec("humiditycongenial", "hideSeries", "Meteo Blue");
      ApexCharts.exec("humiditycongenial", "hideSeries", "Nasa Power Larc");
      ApexCharts.exec("humiditycongenial", "hideSeries", "Online Sensor");
      //Show current series even if it is hidden
      ApexCharts.exec("humiditycongenial", "showSeries", "ECMWF");
    } else if (e.target.id == "mb") {
      this.setState({
        kfmetcolor: "#94de71",
        kfdcolor: "#151714",
        kfowcolor: "#151714",
        kfallcolor: "#151714",
        kfsencolor: "#151714",
        kfecmwfcolor: "#151714",
        kfnasacolor: "#151714",
        kfaiccolor: "#151714",
      });
      ApexCharts.exec("tmaxcongenial", "hideSeries", "ECMWF");
      ApexCharts.exec("tmaxcongenial", "hideSeries", "AIC");
      ApexCharts.exec("tmaxcongenial", "hideSeries", "Nasa Power Larc");
      ApexCharts.exec("tmaxcongenial", "hideSeries", "Online Sensor");
      //Show current series even if it is hidden
      ApexCharts.exec("tmaxcongenial", "showSeries", "Meteo Blue");

      ApexCharts.exec("humiditycongenial", "hideSeries", "ECMWF");
      ApexCharts.exec("humiditycongenial", "hideSeries", "AIC");
      ApexCharts.exec("humiditycongenial", "hideSeries", "Nasa Power Larc");
      ApexCharts.exec("humiditycongenial", "hideSeries", "Online Sensor");
      //Show current series even if it is hidden
      ApexCharts.exec("humiditycongenial", "showSeries", "Meteo Blue");
    } else if (e.target.id == "Online") {
      this.setState({
        kfsencolor: "#94de71",
        kfdcolor: "#151714",
        kfowcolor: "#151714",
        kfallcolor: "#151714",
        kfmetcolor: "#151714",
        kfecmwfcolor: "#151714",
        kfnasacolor: "#151714",
        kfaiccolor: "#151714",
      });
      ApexCharts.exec("tmaxcongenial", "hideSeries", "ECMWF");
      ApexCharts.exec("tmaxcongenial", "hideSeries", "Dark Sky");
      ApexCharts.exec("tmaxcongenial", "hideSeries", "OpenWeather");
      ApexCharts.exec("tmaxcongenial", "hideSeries", "AIC");
      ApexCharts.exec("tmaxcongenial", "hideSeries", "Meteo Blue");
      ApexCharts.exec("tmaxcongenial", "hideSeries", "Nasa Power Larc");
      //Show current series even if it is hidden
      ApexCharts.exec("tmaxcongenial", "showSeries", "Online Sensor");

      ApexCharts.exec("humiditycongenial", "hideSeries", "ECMWF");
      ApexCharts.exec("humiditycongenial", "hideSeries", "Dark Sky");
      ApexCharts.exec("humiditycongenial", "hideSeries", "OpenWeather");
      ApexCharts.exec("humiditycongenial", "hideSeries", "AIC");
      ApexCharts.exec("humiditycongenial", "hideSeries", "Meteo Blue");
      ApexCharts.exec("humiditycongenial", "hideSeries", "Nasa Power Larc");
      //Show current series even if it is hidden
      ApexCharts.exec("humiditycongenial", "showSeries", "Online Sensor");
    } else if (e.target.id == "All") {
      this.setState({
        kfallcolor: "#94de71",
        kfdcolor: "#151714",
        kfowcolor: "#151714",
        kfsencolor: "#151714",
        kfmetcolor: "#151714",
        kfecmwfcolor: "#151714",
        kfnasacolor: "#151714",
        kfaiccolor: "#151714",
      });
      ApexCharts.exec("tmaxcongenial", "showSeries", "ECMWF");
      ApexCharts.exec("tmaxcongenial", "showSeries", "AIC");
      ApexCharts.exec("tmaxcongenial", "showSeries", "Meteo Blue");
      ApexCharts.exec("tmaxcongenial", "showSeries", "Nasa Power Larc");
      ApexCharts.exec("tmaxcongenial", "showSeries", "Online Sensor");

      ApexCharts.exec("humiditycongenial", "showSeries", "ECMWF");
      ApexCharts.exec("humiditycongenial", "showSeries", "AIC");
      ApexCharts.exec("humiditycongenial", "showSeries", "Meteo Blue");
      ApexCharts.exec("humiditycongenial", "showSeries", "Nasa Power Larc");
      ApexCharts.exec("humiditycongenial", "showSeries", "Online Sensor");
    }
  }
  handleKfilter(e) {
    // console.log(e.target.id);
    if (this.state.peril == "deficitrain") {
      if (e.target.id == "aic") {
        this.setState({
          kfaiccolor: "#94de71",
          kfdcolor: "#151714",
          kfowcolor: "#151714",
          kfallcolor: "#151714",
          kfsencolor: "#151714",
          kfmetcolor: "#151714",
          kfecmwfcolor: "#151714",
          kfnasacolor: "#151714",
        });
        ApexCharts.exec("area-datetime", "hideSeries", "ECMWF");

        ApexCharts.exec("area-datetime", "hideSeries", "Meteo Blue");
        ApexCharts.exec("area-datetime", "hideSeries", "Nasa Power Larc");
        // ApexCharts.exec('area-datetime', 'hideSeries', 'Online Sensor');
        //Show current series even if it is hidden
        ApexCharts.exec("area-datetime", "showSeries", "AIC");
      } else if (e.target.id == "nasa") {
        this.setState({
          kfnasacolor: "#94de71",
          kfdcolor: "#151714",
          kfowcolor: "#151714",
          kfallcolor: "#151714",
          kfsencolor: "#151714",
          kfmetcolor: "#151714",
          kfecmwfcolor: "#151714",
          kfaiccolor: "#151714",
        });
        ApexCharts.exec("area-datetime", "hideSeries", "ECMWF");

        ApexCharts.exec("area-datetime", "hideSeries", "AIC");
        ApexCharts.exec("area-datetime", "hideSeries", "Meteo Blue");
        // ApexCharts.exec('area-datetime', 'hideSeries', 'Online Sensor');
        //Show current series even if it is hidden
        ApexCharts.exec("area-datetime", "showSeries", "Nasa Power Larc");
      } else if (e.target.id == "ecmwf") {
        this.setState({
          kfecmwfcolor: "#94de71",
          kfdcolor: "#151714",
          kfowcolor: "#151714",
          kfallcolor: "#151714",
          kfsencolor: "#151714",
          kfmetcolor: "#151714",
          kfnasacolor: "#151714",
          kfaiccolor: "#151714",
        });

        ApexCharts.exec("area-datetime", "hideSeries", "AIC");
        ApexCharts.exec("area-datetime", "hideSeries", "Meteo Blue");
        ApexCharts.exec("area-datetime", "hideSeries", "Nasa Power Larc");
        // ApexCharts.exec('area-datetime', 'hideSeries', 'Online Sensor');
        //Show current series even if it is hidden
        ApexCharts.exec("area-datetime", "showSeries", "ECMWF");
      } else if (e.target.id == "mb") {
        this.setState({
          kfmetcolor: "#94de71",
          kfdcolor: "#151714",
          kfowcolor: "#151714",
          kfallcolor: "#151714",
          kfsencolor: "#151714",
          kfecmwfcolor: "#151714",
          kfnasacolor: "#151714",
          kfaiccolor: "#151714",
        });
        ApexCharts.exec("area-datetime", "hideSeries", "ECMWF");
        ApexCharts.exec("area-datetime", "hideSeries", "AIC");
        ApexCharts.exec("area-datetime", "hideSeries", "Nasa Power Larc");
        // ApexCharts.exec('area-datetime', 'hideSeries', 'Online Sensor');
        //Show current series even if it is hidden
        ApexCharts.exec("area-datetime", "showSeries", "Meteo Blue");
      } else if (e.target.id == "Online") {
        this.setState({
          kfsencolor: "#94de71",
          kfdcolor: "#151714",
          kfowcolor: "#151714",
          kfallcolor: "#151714",
          kfmetcolor: "#151714",
          kfecmwfcolor: "#151714",
          kfnasacolor: "#151714",
          kfaiccolor: "#151714",
        });
        ApexCharts.exec("area-datetime", "hideSeries", "ECMWF");
        ApexCharts.exec("area-datetime", "hideSeries", "AIC");
        ApexCharts.exec("area-datetime", "hideSeries", "Meteo Blue");
        ApexCharts.exec("area-datetime", "hideSeries", "Nasa Power Larc");
        //Show current series even if it is hidden
        ApexCharts.exec("area-datetime", "showSeries", "Online Sensor");
      } else if (e.target.id == "All") {
        this.setState({
          kfallcolor: "#94de71",
          kfdcolor: "#151714",
          kfowcolor: "#151714",
          kfsencolor: "#151714",
          kfmetcolor: "#151714",
          kfecmwfcolor: "#151714",
          kfnasacolor: "#151714",
          kfaiccolor: "#151714",
        });
        ApexCharts.exec("area-datetime", "showSeries", "ECMWF");
        ApexCharts.exec("area-datetime", "showSeries", "AIC");
        ApexCharts.exec("area-datetime", "showSeries", "Meteo Blue");
        ApexCharts.exec("area-datetime", "showSeries", "Nasa Power Larc");
        // ApexCharts.exec('area-datetime', 'showSeries', 'Online Sensor');
      }
    } else {
      if (e.target.id == "ds") {
        this.setState({
          kfdcolor: "#94de71",
          kfowcolor: "#151714",
          kfallcolor: "#151714",
          kfsencolor: "#151714",
          kfmetcolor: "#151714",
          kfecmwfcolor: "#151714",
          kfnasacolor: "#151714",
          kfaiccolor: "#151714",
        });
        ApexCharts.exec("area-datetime", "hideSeries", "ECMWF");
        ApexCharts.exec("area-datetime", "hideSeries", "OpenWeather");
        ApexCharts.exec("area-datetime", "hideSeries", "AIC");
        ApexCharts.exec("area-datetime", "hideSeries", "Meteo Blue");
        ApexCharts.exec("area-datetime", "hideSeries", "Nasa Power Larc");
        ApexCharts.exec("area-datetime", "hideSeries", "Online Sensor");
        //Show current series even if it is hidden
        ApexCharts.exec("area-datetime", "showSeries", "Dark Sky");
      } else if (e.target.id == "ow") {
        this.setState({
          kfowcolor: "#94de71",
          kfdcolor: "#151714",
          kfallcolor: "#151714",
          kfsencolor: "#151714",
          kfmetcolor: "#151714",
          kfecmwfcolor: "#151714",
          kfnasacolor: "#151714",
          kfaiccolor: "#151714",
        });
        ApexCharts.exec("area-datetime", "hideSeries", "ECMWF");
        ApexCharts.exec("area-datetime", "hideSeries", "Dark Sky");
        ApexCharts.exec("area-datetime", "hideSeries", "AIC");
        ApexCharts.exec("area-datetime", "hideSeries", "Meteo Blue");
        ApexCharts.exec("area-datetime", "hideSeries", "Nasa Power Larc");
        ApexCharts.exec("area-datetime", "hideSeries", "Online Sensor");
        //Show current series even if it is hidden
        ApexCharts.exec("area-datetime", "showSeries", "OpenWeather");
      } else if (e.target.id == "aic") {
        this.setState({
          kfaiccolor: "#94de71",
          kfdcolor: "#151714",
          kfowcolor: "#151714",
          kfallcolor: "#151714",
          kfsencolor: "#151714",
          kfmetcolor: "#151714",
          kfecmwfcolor: "#151714",
          kfnasacolor: "#151714",
        });
        ApexCharts.exec("area-datetime", "hideSeries", "ECMWF");
        ApexCharts.exec("area-datetime", "hideSeries", "Dark Sky");
        ApexCharts.exec("area-datetime", "hideSeries", "OpenWeather");
        ApexCharts.exec("area-datetime", "hideSeries", "Meteo Blue");
        ApexCharts.exec("area-datetime", "hideSeries", "Nasa Power Larc");
        ApexCharts.exec("area-datetime", "hideSeries", "Online Sensor");
        //Show current series even if it is hidden
        ApexCharts.exec("area-datetime", "showSeries", "AIC");
      } else if (e.target.id == "nasa") {
        this.setState({
          kfnasacolor: "#94de71",
          kfdcolor: "#151714",
          kfowcolor: "#151714",
          kfallcolor: "#151714",
          kfsencolor: "#151714",
          kfmetcolor: "#151714",
          kfecmwfcolor: "#151714",
          kfaiccolor: "#151714",
        });
        ApexCharts.exec("area-datetime", "hideSeries", "ECMWF");
        ApexCharts.exec("area-datetime", "hideSeries", "Dark Sky");
        ApexCharts.exec("area-datetime", "hideSeries", "OpenWeather");
        ApexCharts.exec("area-datetime", "hideSeries", "AIC");
        ApexCharts.exec("area-datetime", "hideSeries", "Meteo Blue");
        ApexCharts.exec("area-datetime", "hideSeries", "Online Sensor");
        //Show current series even if it is hidden
        ApexCharts.exec("area-datetime", "showSeries", "Nasa Power Larc");
      } else if (e.target.id == "ecmwf") {
        this.setState({
          kfecmwfcolor: "#94de71",
          kfdcolor: "#151714",
          kfowcolor: "#151714",
          kfallcolor: "#151714",
          kfsencolor: "#151714",
          kfmetcolor: "#151714",
          kfnasacolor: "#151714",
          kfaiccolor: "#151714",
        });

        ApexCharts.exec("area-datetime", "hideSeries", "Dark Sky");
        ApexCharts.exec("area-datetime", "hideSeries", "OpenWeather");
        ApexCharts.exec("area-datetime", "hideSeries", "AIC");
        ApexCharts.exec("area-datetime", "hideSeries", "Meteo Blue");
        ApexCharts.exec("area-datetime", "hideSeries", "Nasa Power Larc");
        ApexCharts.exec("area-datetime", "hideSeries", "Online Sensor");
        //Show current series even if it is hidden
        ApexCharts.exec("area-datetime", "showSeries", "ECMWF");
      } else if (e.target.id == "mb") {
        this.setState({
          kfmetcolor: "#94de71",
          kfdcolor: "#151714",
          kfowcolor: "#151714",
          kfallcolor: "#151714",
          kfsencolor: "#151714",
          kfecmwfcolor: "#151714",
          kfnasacolor: "#151714",
          kfaiccolor: "#151714",
        });
        ApexCharts.exec("area-datetime", "hideSeries", "ECMWF");
        ApexCharts.exec("area-datetime", "hideSeries", "Dark Sky");
        ApexCharts.exec("area-datetime", "hideSeries", "OpenWeather");
        ApexCharts.exec("area-datetime", "hideSeries", "AIC");
        ApexCharts.exec("area-datetime", "hideSeries", "Nasa Power Larc");
        ApexCharts.exec("area-datetime", "hideSeries", "Online Sensor");
        //Show current series even if it is hidden
        ApexCharts.exec("area-datetime", "showSeries", "Meteo Blue");
      } else if (e.target.id == "Online") {
        this.setState({
          kfsencolor: "#94de71",
          kfdcolor: "#151714",
          kfowcolor: "#151714",
          kfallcolor: "#151714",
          kfmetcolor: "#151714",
          kfecmwfcolor: "#151714",
          kfnasacolor: "#151714",
          kfaiccolor: "#151714",
        });
        ApexCharts.exec("area-datetime", "hideSeries", "ECMWF");
        ApexCharts.exec("area-datetime", "hideSeries", "Dark Sky");
        ApexCharts.exec("area-datetime", "hideSeries", "OpenWeather");
        ApexCharts.exec("area-datetime", "hideSeries", "AIC");
        ApexCharts.exec("area-datetime", "hideSeries", "Meteo Blue");
        ApexCharts.exec("area-datetime", "hideSeries", "Nasa Power Larc");
        //Show current series even if it is hidden
        ApexCharts.exec("area-datetime", "showSeries", "Online Sensor");
      } else if (e.target.id == "All") {
        this.setState({
          kfallcolor: "#94de71",
          kfdcolor: "#151714",
          kfowcolor: "#151714",
          kfsencolor: "#151714",
          kfmetcolor: "#151714",
          kfecmwfcolor: "#151714",
          kfnasacolor: "#151714",
          kfaiccolor: "#151714",
        });
        ApexCharts.exec("area-datetime", "showSeries", "ECMWF");
        ApexCharts.exec("area-datetime", "showSeries", "Dark Sky");
        ApexCharts.exec("area-datetime", "showSeries", "OpenWeather");
        ApexCharts.exec("area-datetime", "showSeries", "AIC");
        ApexCharts.exec("area-datetime", "showSeries", "Meteo Blue");
        ApexCharts.exec("area-datetime", "showSeries", "Nasa Power Larc");
        ApexCharts.exec("area-datetime", "showSeries", "Online Sensor");
      }
    }
  }
  checkPerilunit() {
    // console.log("PERILS", this.state.tableperil);
    if (this.state.tableperil == "High Temperature") {
      return "Temperature (°C)";
    }
    if (this.state.tableperil == "Deficit Rainfall") {
      return "Rainfall (mm)";
    }
    if (this.state.tableperil == "Dry Spell") {
      return "Rainfall (mm)";
    }
    if (this.state.tableperil == "Excess Rainfall") {
      return "Rainfall (mm)";
    }
    if (this.state.tableperil == "Wind Speed") {
      return "Wind Speed (m/s)";
    }
    if (this.state.tableperil == "Wind Speed") {
      return "Wind Speed (m/s)";
    }
    if (this.state.tableperil == "Wind Speed (A)") {
      return "Wind Speed (m/s)";
    }
    if (this.state.tableperil == "Wind Speed (B)") {
      return "Wind Speed (m/s)";
    }
    if (this.state.tableperil == "Unseasonal Rainfall") {
      return "Rainfall (mm)";
    }
    if (this.state.tableperil == "Rise in Temperature") {
      return "Temperature (°C)";
    }
  }

  findMax(one, two, three, four, five, six) {
    const largest = Math.max(one, two, three, four, five, six);
    let per = (largest * 10) / 100;
    let retn = largest + per;
    let fvalue = Math.floor(retn);
    let top = Math.ceil((fvalue + 1) / 10) * 10;
    let final = parseInt(fvalue / 10, 10) + 1 * 10;
    // console.log("top y axis chart value =", top);
    return top;
  }
  findpercentage(val) {
    let per = (val * 10) / 100;
    return val + per;
  }
  async getPerilSeason() {
    var getthis = this
    let searchdata = {
      farmer_id: localStorage.getItem("farmer_id"),
    };
    searchdata = JSON.stringify(searchdata);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token"),
      },
      body: searchdata,
    };
    var SeasonPeril = await fetch(
      process.env.REACT_APP_APIEND + `farmer/farmer-crops-list`,
      requestOptions
    ).then((res) => {
      return res.json();
    });
    // console.log("kharif", SeasonPeril);
    var seasons = [];
    var crops = [];

    SeasonPeril.result.map(function (item, index, data) {
      seasons.push(Object.keys(item));
      SeasonPeril.result[index][Object.keys(item)].map(function (cropslist) {
        crops.push(cropslist.crop);
      });
    });
   
    // console.log("FETCH SEASON CROP", seasons[0][0]);
    seasons.map(function (seasonitem, seasonindex, seasondata) {
      crops.map(function(cropsitem,cropsindex){
        if (seasonitem == "Kharif 2020" && cropsitem == "Paddy") {
          var cropsslist=[]
          SeasonPeril.result[seasonindex][seasonitem].map(function(cropss){
            cropsslist.push(cropss.crop)
          })
          getthis.setState(
            {
              perilData: [
                "High Temperature",
                "Deficit Rainfall",
                "Excess Rainfall",
                "Dry Spell",
                "Disease Congenial Climate",
              ],
              peril: "High Temperature",
              tableperil: "High Temperature",
              dpData: ["AIC", "Nasa Power", "MeteoBlue", "ECMWF"],
              tabledp: "AIC",
            },
            () => {
              getthis.setState({
                seasonData: seasons,
                cropData: cropsslist,
                season: seasonitem,
                tableseason: seasonitem[0],
                crop: cropsitem,
                tablecrop: cropsitem,
                allseasonData: SeasonPeril.result,
              });
            }
          );
        }
        if (seasonitem == "Kharif 2020" && cropsitem == "Veg Group I") {
          var cropsslist=[]
          SeasonPeril.result[seasonindex][seasonitem].map(function(cropss){
            cropsslist.push(cropss.crop)
          })
          getthis.setState(
            {
              perilData: [
                "Rise in Temperature",
                "Excess Rainfall",
                "Disease Congenial Climate",
              ],
              peril: "Rise in Temperature",
              tableperil: "Rise in Temperature",
              dpData: ["Online Sensors"],
              tabledp: "Online Sensors",
            },
            () => {
              getthis.setState({
                seasonData: seasons,
                cropData: cropsslist,
                season: seasonitem,
                tableseason: seasonitem[0],
                crop: cropsitem,
                tablecrop: cropsitem,
                allseasonData: SeasonPeril.result,
              });
            }
          );
        }
        if (seasonitem == "Kharif 2021" && cropsitem == "Paddy") {
          var cropsslist=[]
          SeasonPeril.result[seasonindex][seasonitem].map(function(cropss){
            cropsslist.push(cropss.crop)
          })
          getthis.setState(
            {
              perilData: [
                "High Temperature",
                "Deficit Rainfall",
                "Excess Rainfall",
                "Dry Spell",
                "Disease Congenial Climate",
              ],
              peril: "High Temperature",
              tableperil: "High Temperature",
              dpData: ["Online Sensors"],
              tabledp: "Online Sensors",
            },
            () => {
              getthis.setState({
                seasonData: seasons,
                cropData: cropsslist,
                season: seasonitem,
                tableseason: seasonitem[0],
                crop: cropsitem,
                tablecrop: cropsitem,
                allseasonData: SeasonPeril.result,
              });
            }
          );
        }
        if (seasonitem == "Kharif 2021" && cropsitem == "Veg Group I") {
          var cropsslist=[]
          SeasonPeril.result[seasonindex][seasonitem].map(function(cropss){
            cropsslist.push(cropss.crop)
          })
          getthis.setState(
            {
              perilData: [
                "Rise in Temperature",
                "Excess Rainfall",
                "Disease Congenial Climate",
              ],
              peril: "Rise in Temperature",
              tableperil: "Rise in Temperature",
              dpData: ["Online Sensors"],
              tabledp: "Online Sensors",
            },
            () => {
              getthis.setState({
                seasonData: seasons,
                cropData: cropsslist,
                season: seasonitem,
                tableseason: seasonitem[0],
                crop: cropsitem,
                tablecrop: cropsitem,
                allseasonData: SeasonPeril.result,
              });
            }
          );
        }
        if (seasonitem == "Kharif 2022" && cropsitem == "Paddy") {
          var cropsslist=[]
          SeasonPeril.result[seasonindex][seasonitem].map(function(cropss){
            cropsslist.push(cropss.crop)
          })
          getthis.setState(
            {
              perilData: [
                "High Temperature",
                "Deficit Rainfall",
                "Excess Rainfall",
                "Dry Spell",
                "Disease Congenial Climate",
              ],
              peril: "High Temperature",
              tableperil: "High Temperature",
              dpData: ["Online Sensors"],
              tabledp: "Online Sensors",
            },
            () => {
              getthis.setState({
                seasonData: seasons,
                cropData:  cropsslist,
                season: seasonitem,
                tableseason: seasonitem[0],
                crop: cropsitem,
                tablecrop: cropsitem,
                allseasonData: SeasonPeril.result,
              });
            }
          );
        }
        if (seasonitem == "Kharif 2022" && cropsitem == "Veg Group I") {
          var cropsslist=[]
          SeasonPeril.result[seasonindex][seasonitem].map(function(cropss){
            cropsslist.push(cropss.crop)
          })
          getthis.setState(
            {
              perilData: [
                "Rise in Temperature",
                "Excess Rainfall",
                "Disease Congenial Climate",
              ],
              peril: "Rise in Temperature",
              tableperil: "Rise in Temperature",
              dpData: ["Online Sensors"],
              tabledp: "Online Sensors",
            },
            () => {
              getthis.setState({
                seasonData: seasons,
                cropData: cropsslist,
                season: seasonitem,
                tableseason: seasonitem[0],
                crop: cropsitem,
                tablecrop: cropsitem,
                allseasonData: SeasonPeril.result,
              });
            }
          );
        }
        if (seasonitem == "Kharif 2023" && cropsitem == "Paddy") {
          var cropsslist=[]
          SeasonPeril.result[seasonindex][seasonitem].map(function(cropss){
            cropsslist.push(cropss.crop)
          })
          getthis.setState(
            {
              perilData: [
                "High Temperature",
                "Deficit Rainfall",
                "Excess Rainfall",
                "Dry Spell",
                "Disease Congenial Climate",
              ],
              peril: "High Temperature",
              tableperil: "High Temperature",
              dpData: ["Online Sensors"],
              tabledp: "Online Sensors",
            },
            () => {
              getthis.setState({
                seasonData: seasons,
                cropData:  cropsslist,
                season: seasonitem,
                tableseason: seasonitem[0],
                crop: cropsitem,
                tablecrop: cropsitem,
                allseasonData: SeasonPeril.result,
              });
            }
          );
        }
        if (seasonitem == "Kharif 2023" && cropsitem == "Veg Group I") {
          var cropsslist=[]
          SeasonPeril.result[seasonindex][seasonitem].map(function(cropss){
            cropsslist.push(cropss.crop)
          })
          getthis.setState(
            {
              perilData: [
                "Rise in Temperature",
                "Excess Rainfall",
                "Disease Congenial Climate",
              ],
              peril: "Rise in Temperature",
              tableperil: "Rise in Temperature",
              dpData: ["Online Sensors"],
              tabledp: "Online Sensors",
            },
            () => {
              getthis.setState({
                seasonData: seasons,
                cropData: cropsslist,
                season: seasonitem,
                tableseason: seasonitem[0],
                crop: cropsitem,
                tablecrop: cropsitem,
                allseasonData: SeasonPeril.result,
              });
            }
          );
        }
        if (seasonitem == "Kharif 2024" && cropsitem == "Paddy") {
          var cropsslist=[]
          SeasonPeril.result[seasonindex][seasonitem].map(function(cropss){
            cropsslist.push(cropss.crop)
          })
          getthis.setState(
            {
              perilData: [
                "High Temperature",
                "Deficit Rainfall",
                "Excess Rainfall",
                "Dry Spell",
                "Disease Congenial Climate",
              ],
              peril: "High Temperature",
              tableperil: "High Temperature",
              dpData: ["Online Sensors"],
              tabledp: "Online Sensors",
            },
            () => {
              getthis.setState({
                seasonData: seasons,
                cropData:  cropsslist,
                season: seasonitem,
                tableseason: seasonitem[0],
                crop: cropsitem,
                tablecrop: cropsitem,
                allseasonData: SeasonPeril.result,
              });
            }
          );
        }
        if (seasonitem == "Kharif 2024" && cropsitem == "Veg Group I") {
          var cropsslist=[]
          SeasonPeril.result[seasonindex][seasonitem].map(function(cropss){
            cropsslist.push(cropss.crop)
          })
          getthis.setState(
            {
              perilData: [
                "Rise in Temperature",
                "Excess Rainfall",
                "Disease Congenial Climate",
              ],
              peril: "Rise in Temperature",
              tableperil: "Rise in Temperature",
              dpData: ["Online Sensors"],
              tabledp: "Online Sensors",
            },
            () => {
              getthis.setState({
                seasonData: seasons,
                cropData: cropsslist,
                season: seasonitem,
                tableseason: seasonitem[0],
                crop: cropsitem,
                tablecrop: cropsitem,
                allseasonData: SeasonPeril.result,
              });
            }
          );
        }
        if (seasonitem == "Rabi 2020-21" && cropsitem == "Mango") {
          var cropsslist=[]
          SeasonPeril.result[seasonindex][seasonitem].map(function(cropss){
            cropsslist.push(cropss.crop)
          })
          getthis.setState(
            {
              perilData: [
                "Excess Rainfall",
                "Wind Speed",
                "Disease Congenial Climate",
              ],
              peril: "Excess Rainfall",
              tableperil: "Excess Rainfall",
              dpData: ["Online Sensors"],
              tabledp: "Online Sensors",
            },
            () => {
              getthis.setState({
                seasonData: seasons,
                cropData: cropsslist,
                season: seasonitem,
                tableseason: seasonitem[0],
                crop: cropsitem,
                tablecrop: cropsitem,
                allseasonData: SeasonPeril.result,
              });
            }
          );
        }
        if (seasonitem == "Rabi 2020-21" && cropsitem == "Paddy 2nd Crop") {
          var cropsslist=[]
          SeasonPeril.result[seasonindex][seasonitem].map(function(cropss){
            cropsslist.push(cropss.crop)
          })
          getthis.setState(
            {
              perilData: [
                "Excess Rainfall",
                "Wind Speed (A)",
                "Wind Speed (B)",
                "Disease Congenial Climate",
                "Deficit Rainfall",
                "Unseasonal Rainfall",
              ],
              peril: "Excess Rainfall",
              tableperil: "Excess Rainfall",
              dpData: ["Online Sensors"],
              tabledp: "Online Sensors",
            },
            () => {
              getthis.setState({
                seasonData: seasons,
                cropData: cropsslist,
                season: seasonitem,
                tableseason: seasonitem[0],
                crop: cropsitem,
                tablecrop: cropsitem,
                allseasonData: SeasonPeril.result,
              });
            }
          );
        }
        if (seasonitem == "Rabi 2020-21" && cropsitem == "Paddy 3rd Crop") {
          var cropsslist=[]
          SeasonPeril.result[seasonindex][seasonitem].map(function(cropss){
            cropsslist.push(cropss.crop)
          })
          getthis.setState(
            {
              perilData: [
                "Excess Rainfall",
                "High Temperature",
                "Disease Congenial Climate",
                "Deficit Rainfall",
              ],
              peril: "Excess Rainfall",
              tableperil: "Excess Rainfall",
              dpData: ["Online Sensors"],
              tabledp: "Online Sensors",
            },
            () => {
              getthis.setState({
                seasonData: seasons,
                cropData: cropsslist,
                season: seasonitem,
                tableseason: seasonitem[0],
                crop: cropsitem,
                tablecrop: cropsitem,
                allseasonData: SeasonPeril.result,
              });
            }
          );
        }
        if (seasonitem == "Rabi 2020-21" && cropsitem == "Paddy Winter") {
          var cropsslist=[]
          SeasonPeril.result[seasonindex][seasonitem].map(function(cropss){
            cropsslist.push(cropss.crop)
          })
          getthis.setState(
            {
              perilData: [
                "Excess Rainfall",
                "High Temperature",
                "Disease Congenial Climate",
                "Deficit Rainfall",
              ],
              peril: "Excess Rainfall",
              tableperil: "Excess Rainfall",
              dpData: ["Online Sensors"],
              tabledp: "Online Sensors",
            },
            () => {
              getthis.setState({
                seasonData: seasons,
                cropData: cropsslist,
                season: seasonitem,
                tableseason: seasonitem[0],
                crop: cropsitem,
                tablecrop: cropsitem,
                allseasonData: SeasonPeril.result,
              });
            }
          );
        }
        if (seasonitem == "Rabi 2020-21" && cropsitem == "Veg Group I") {
          var cropsslist=[]
          SeasonPeril.result[seasonindex][seasonitem].map(function(cropss){
            cropsslist.push(cropss.crop)
          })
          getthis.setState(
            {
              perilData: [
                "Excess Rainfall",
                "Rise in Temperature",
                "Disease Congenial Climate",
              ],
              peril: "Excess Rainfall",
              tableperil: "Excess Rainfall",
              dpData: ["Online Sensors"],
              tabledp: "Online Sensors",
            },
            () => {
              getthis.setState({
                seasonData: seasons,
                cropData: cropsslist,
                season: seasonitem,
                tableseason: seasonitem[0],
                crop: cropsitem,
                tablecrop: cropsitem,
                allseasonData: SeasonPeril.result,
              });
            }
          );
        }
        if (seasonitem == "Rabi 2020-21" && cropsitem == "Veg Group I - SMR") {
          var cropsslist=[]
          SeasonPeril.result[seasonindex][seasonitem].map(function(cropss){
            cropsslist.push(cropss.crop)
          })
          getthis.setState(
            {
              perilData: [
                "Excess Rainfall",
                "Deficit Rainfall",
                "Rise in Temperature",
                "Disease Congenial Climate",
              ],
              peril: "Excess Rainfall",
              tableperil: "Excess Rainfall",
              dpData: ["Online Sensors"],
              tabledp: "Online Sensors",
            },
            () => {
              getthis.setState({
                seasonData: seasons,
                cropData: cropsslist,
                season: seasonitem,
                tableseason: seasonitem[0],
                crop: cropsitem,
                tablecrop: cropsitem,
                allseasonData: SeasonPeril.result,
              });
            }
          );
        }
        if (seasonitem == "Rabi 2021-22" && cropsitem == "Mango") {
          var cropsslist=[]
          SeasonPeril.result[seasonindex][seasonitem].map(function(cropss){
            cropsslist.push(cropss.crop)
          })
          getthis.setState(
            {
              perilData: [
                "Excess Rainfall",
                "Wind Speed",
                "Disease Congenial Climate",
              ],
              peril: "Excess Rainfall",
              tableperil: "Excess Rainfall",
              dpData: ["Online Sensors"],
              tabledp: "Online Sensors",
            },
            () => {
              getthis.setState({
                seasonData: seasons,
                cropData: cropsslist,
                season: seasonitem,
                tableseason: seasonitem[0],
                crop: cropsitem,
                tablecrop: cropsitem,
                allseasonData: SeasonPeril.result,
              });
            }
          );
        }
        if (seasonitem == "Rabi 2021-22" && cropsitem == "Paddy 2nd Crop") {
          var cropsslist=[]
          SeasonPeril.result[seasonindex][seasonitem].map(function(cropss){
            cropsslist.push(cropss.crop)
          })
          getthis.setState(
            {
              perilData: [
                "Excess Rainfall",
                "Wind Speed (A)",
                "Wind Speed (B)",
                "Disease Congenial Climate",
                "Deficit Rainfall",
                "Unseasonal Rainfall",
              ],
              peril: "Excess Rainfall",
              tableperil: "Excess Rainfall",
              dpData: ["Online Sensors"],
              tabledp: "Online Sensors",
            },
            () => {
              getthis.setState({
                seasonData: seasons,
                cropData: cropsslist,
                season: seasonitem,
                tableseason: seasonitem[0],
                crop: cropsitem,
                tablecrop: cropsitem,
                allseasonData: SeasonPeril.result,
              });
            }
          );
        }
        if (seasonitem == "Rabi 2021-22" && cropsitem == "Paddy 3rd Crop") {
          var cropsslist=[]
          SeasonPeril.result[seasonindex][seasonitem].map(function(cropss){
            cropsslist.push(cropss.crop)
          })
          getthis.setState(
            {
              perilData: [
                "Excess Rainfall",
                "High Temperature",
                "Disease Congenial Climate",
                "Deficit Rainfall",
              ],
              peril: "Excess Rainfall",
              tableperil: "Excess Rainfall",
              dpData: ["Online Sensors"],
              tabledp: "Online Sensors",
            },
            () => {
              getthis.setState({
                seasonData: seasons,
                cropData: cropsslist,
                season: seasonitem,
                tableseason: seasonitem[0],
                crop: cropsitem,
                tablecrop: cropsitem,
                allseasonData: SeasonPeril.result,
              });
            }
          );
        }
        if (seasonitem == "Rabi 2021-22" && cropsitem == "Paddy Winter") {
          var cropsslist=[]
          SeasonPeril.result[seasonindex][seasonitem].map(function(cropss){
            cropsslist.push(cropss.crop)
          })
          getthis.setState(
            {
              perilData: [
                "Excess Rainfall",
                "High Temperature",
                "Disease Congenial Climate",
                "Deficit Rainfall",
              ],
              peril: "Excess Rainfall",
              tableperil: "Excess Rainfall",
              dpData: ["Online Sensors"],
              tabledp: "Online Sensors",
            },
            () => {
              getthis.setState({
                seasonData: seasons,
                cropData: cropsslist,
                season: seasonitem,
                tableseason: seasonitem[0],
                crop: cropsitem,
                tablecrop: cropsitem,
                allseasonData: SeasonPeril.result,
              });
            }
          );
        }
        if (seasonitem == "Rabi 2021-22" && cropsitem == "Veg Group I") {
          var cropsslist=[]
          SeasonPeril.result[seasonindex][seasonitem].map(function(cropss){
            cropsslist.push(cropss.crop)
          })
          getthis.setState(
            {
              perilData: [
                "Excess Rainfall",
                "Rise in Temperature",
                "Disease Congenial Climate",
              ],
              peril: "Excess Rainfall",
              tableperil: "Excess Rainfall",
              dpData: ["Online Sensors"],
              tabledp: "Online Sensors",
            },
            () => {
              getthis.setState({
                seasonData: seasons,
                cropData: cropsslist,
                season: seasonitem,
                tableseason: seasonitem[0],
                crop: cropsitem,
                tablecrop: cropsitem,
                allseasonData: SeasonPeril.result,
              });
            }
          );
        }
        if (seasonitem == "Rabi 2021-22" && cropsitem == "Veg Group I - SMR") {
          var cropsslist=[]
          SeasonPeril.result[seasonindex][seasonitem].map(function(cropss){
            cropsslist.push(cropss.crop)
          })
          getthis.setState(
            {
              perilData: [
                "Excess Rainfall",
                "Deficit Rainfall",
                "Rise in Temperature",
                "Disease Congenial Climate",
              ],
              peril: "Excess Rainfall",
              tableperil: "Excess Rainfall",
              dpData: ["Online Sensors"],
              tabledp: "Online Sensors",
            },
            () => {
              getthis.setState({
                seasonData: seasons,
                cropData: cropsslist,
                season: seasonitem,
                tableseason: seasonitem[0],
                crop: cropsitem,
                tablecrop: cropsitem,
                allseasonData: SeasonPeril.result,
              });
            }
          );
        }


        if (seasonitem == "Rabi 2022-23" && cropsitem == "Mango") {
          var cropsslist=[]
          SeasonPeril.result[seasonindex][seasonitem].map(function(cropss){
            cropsslist.push(cropss.crop)
          })
          getthis.setState(
            {
              perilData: [
                "Excess Rainfall",
                "Wind Speed",
                "Disease Congenial Climate",
              ],
              peril: "Excess Rainfall",
              tableperil: "Excess Rainfall",
              dpData: ["Online Sensors"],
              tabledp: "Online Sensors",
            },
            () => {
              getthis.setState({
                seasonData: seasons,
                cropData: cropsslist,
                season: seasonitem,
                tableseason: seasonitem[0],
                crop: cropsitem,
                tablecrop: cropsitem,
                allseasonData: SeasonPeril.result,
              });
            }
          );
        }
        if (seasonitem == "Rabi 2022-23" && cropsitem == "Paddy 2nd Crop") {
          var cropsslist=[]
          SeasonPeril.result[seasonindex][seasonitem].map(function(cropss){
            cropsslist.push(cropss.crop)
          })
          getthis.setState(
            {
              perilData: [
                "Excess Rainfall",
                "Wind Speed (A)",
                "Wind Speed (B)",
                "Disease Congenial Climate",
                "Deficit Rainfall",
                "Unseasonal Rainfall",
              ],
              peril: "Excess Rainfall",
              tableperil: "Excess Rainfall",
              dpData: ["Online Sensors"],
              tabledp: "Online Sensors",
            },
            () => {
              getthis.setState({
                seasonData: seasons,
                cropData: cropsslist,
                season: seasonitem,
                tableseason: seasonitem[0],
                crop: cropsitem,
                tablecrop: cropsitem,
                allseasonData: SeasonPeril.result,
              });
            }
          );
        }
        if (seasonitem == "Rabi 2022-23" && cropsitem == "Paddy 3rd Crop") {
          var cropsslist=[]
          SeasonPeril.result[seasonindex][seasonitem].map(function(cropss){
            cropsslist.push(cropss.crop)
          })
          getthis.setState(
            {
              perilData: [
                "Excess Rainfall",
                "High Temperature",
                "Disease Congenial Climate",
                "Deficit Rainfall",
              ],
              peril: "Excess Rainfall",
              tableperil: "Excess Rainfall",
              dpData: ["Online Sensors"],
              tabledp: "Online Sensors",
            },
            () => {
              getthis.setState({
                seasonData: seasons,
                cropData: cropsslist,
                season: seasonitem,
                tableseason: seasonitem[0],
                crop: cropsitem,
                tablecrop: cropsitem,
                allseasonData: SeasonPeril.result,
              });
            }
          );
        }
        if (seasonitem == "Rabi 2022-23" && cropsitem == "Paddy Winter") {
          var cropsslist=[]
          SeasonPeril.result[seasonindex][seasonitem].map(function(cropss){
            cropsslist.push(cropss.crop)
          })
          getthis.setState(
            {
              perilData: [
                "Excess Rainfall",
                "High Temperature",
                "Disease Congenial Climate",
                "Deficit Rainfall",
              ],
              peril: "Excess Rainfall",
              tableperil: "Excess Rainfall",
              dpData: ["Online Sensors"],
              tabledp: "Online Sensors",
            },
            () => {
              getthis.setState({
                seasonData: seasons,
                cropData: cropsslist,
                season: seasonitem,
                tableseason: seasonitem[0],
                crop: cropsitem,
                tablecrop: cropsitem,
                allseasonData: SeasonPeril.result,
              });
            }
          );
        }
        if (seasonitem == "Rabi 2022-23" && cropsitem == "Veg Group I") {
          var cropsslist=[]
          SeasonPeril.result[seasonindex][seasonitem].map(function(cropss){
            cropsslist.push(cropss.crop)
          })
          getthis.setState(
            {
              perilData: [
                "Excess Rainfall",
                "Rise in Temperature",
                "Disease Congenial Climate",
              ],
              peril: "Excess Rainfall",
              tableperil: "Excess Rainfall",
              dpData: ["Online Sensors"],
              tabledp: "Online Sensors",
            },
            () => {
              getthis.setState({
                seasonData: seasons,
                cropData: cropsslist,
                season: seasonitem,
                tableseason: seasonitem[0],
                crop: cropsitem,
                tablecrop: cropsitem,
                allseasonData: SeasonPeril.result,
              });
            }
          );
        }
        if (seasonitem == "Rabi 2022-23" && cropsitem == "Veg Group I - SMR") {
          var cropsslist=[]
          SeasonPeril.result[seasonindex][seasonitem].map(function(cropss){
            cropsslist.push(cropss.crop)
          })
          getthis.setState(
            {
              perilData: [
                "Excess Rainfall",
                "Deficit Rainfall",
                "Rise in Temperature",
                "Disease Congenial Climate",
              ],
              peril: "Excess Rainfall",
              tableperil: "Excess Rainfall",
              dpData: ["Online Sensors"],
              tabledp: "Online Sensors",
            },
            () => {
              getthis.setState({
                seasonData: seasons,
                cropData: cropsslist,
                season: seasonitem,
                tableseason: seasonitem[0],
                crop: cropsitem,
                tablecrop: cropsitem,
                allseasonData: SeasonPeril.result,
              });
            }
          );
        }
        if (seasonitem == "Rabi 2023-24" && cropsitem == "Mango") {
          var cropsslist=[]
          SeasonPeril.result[seasonindex][seasonitem].map(function(cropss){
            cropsslist.push(cropss.crop)
          })
          getthis.setState(
            {
              perilData: [
                "Excess Rainfall",
                "Wind Speed",
                "Disease Congenial Climate",
              ],
              peril: "Excess Rainfall",
              tableperil: "Excess Rainfall",
              dpData: ["Online Sensors"],
              tabledp: "Online Sensors",
            },
            () => {
              getthis.setState({
                seasonData: seasons,
                cropData: cropsslist,
                season: seasonitem,
                tableseason: seasonitem[0],
                crop: cropsitem,
                tablecrop: cropsitem,
                allseasonData: SeasonPeril.result,
              });
            }
          );
        }
        if (seasonitem == "Rabi 2023-24" && cropsitem == "Paddy 2nd Crop") {
          var cropsslist=[]
          SeasonPeril.result[seasonindex][seasonitem].map(function(cropss){
            cropsslist.push(cropss.crop)
          })
          getthis.setState(
            {
              perilData: [
                "Excess Rainfall",
                "Wind Speed (A)",
                "Wind Speed (B)",
                "Disease Congenial Climate",
                "Deficit Rainfall",
                "Unseasonal Rainfall",
              ],
              peril: "Excess Rainfall",
              tableperil: "Excess Rainfall",
              dpData: ["Online Sensors"],
              tabledp: "Online Sensors",
            },
            () => {
              getthis.setState({
                seasonData: seasons,
                cropData: cropsslist,
                season: seasonitem,
                tableseason: seasonitem[0],
                crop: cropsitem,
                tablecrop: cropsitem,
                allseasonData: SeasonPeril.result,
              });
            }
          );
        }
        if (seasonitem == "Rabi 2023-24" && cropsitem == "Paddy 3rd Crop") {
          var cropsslist=[]
          SeasonPeril.result[seasonindex][seasonitem].map(function(cropss){
            cropsslist.push(cropss.crop)
          })
          getthis.setState(
            {
              perilData: [
                "Excess Rainfall",
                "High Temperature",
                "Disease Congenial Climate",
                "Deficit Rainfall",
              ],
              peril: "Excess Rainfall",
              tableperil: "Excess Rainfall",
              dpData: ["Online Sensors"],
              tabledp: "Online Sensors",
            },
            () => {
              getthis.setState({
                seasonData: seasons,
                cropData: cropsslist,
                season: seasonitem,
                tableseason: seasonitem[0],
                crop: cropsitem,
                tablecrop: cropsitem,
                allseasonData: SeasonPeril.result,
              });
            }
          );
        }
        if (seasonitem == "Rabi 2023-24" && cropsitem == "Paddy Winter") {
          var cropsslist=[]
          SeasonPeril.result[seasonindex][seasonitem].map(function(cropss){
            cropsslist.push(cropss.crop)
          })
          getthis.setState(
            {
              perilData: [
                "Excess Rainfall",
                "High Temperature",
                "Disease Congenial Climate",
                "Deficit Rainfall",
              ],
              peril: "Excess Rainfall",
              tableperil: "Excess Rainfall",
              dpData: ["Online Sensors"],
              tabledp: "Online Sensors",
            },
            () => {
              getthis.setState({
                seasonData: seasons,
                cropData: cropsslist,
                season: seasonitem,
                tableseason: seasonitem[0],
                crop: cropsitem,
                tablecrop: cropsitem,
                allseasonData: SeasonPeril.result,
              });
            }
          );
        }
        if (seasonitem == "Rabi 2023-24" && cropsitem == "Veg Group I") {
          var cropsslist=[]
          SeasonPeril.result[seasonindex][seasonitem].map(function(cropss){
            cropsslist.push(cropss.crop)
          })
          getthis.setState(
            {
              perilData: [
                "Excess Rainfall",
                "Rise in Temperature",
                "Disease Congenial Climate",
              ],
              peril: "Excess Rainfall",
              tableperil: "Excess Rainfall",
              dpData: ["Online Sensors"],
              tabledp: "Online Sensors",
            },
            () => {
              getthis.setState({
                seasonData: seasons,
                cropData: cropsslist,
                season: seasonitem,
                tableseason: seasonitem[0],
                crop: cropsitem,
                tablecrop: cropsitem,
                allseasonData: SeasonPeril.result,
              });
            }
          );
        }
        if (seasonitem == "Rabi 2023-24" && cropsitem == "Veg Group I - SMR") {
          var cropsslist=[]
          SeasonPeril.result[seasonindex][seasonitem].map(function(cropss){
            cropsslist.push(cropss.crop)
          })
          getthis.setState(
            {
              perilData: [
                "Excess Rainfall",
                "Deficit Rainfall",
                "Rise in Temperature",
                "Disease Congenial Climate",
              ],
              peril: "Excess Rainfall",
              tableperil: "Excess Rainfall",
              dpData: ["Online Sensors"],
              tabledp: "Online Sensors",
            },
            () => {
              getthis.setState({
                seasonData: seasons,
                cropData: cropsslist,
                season: seasonitem,
                tableseason: seasonitem[0],
                crop: cropsitem,
                tablecrop: cropsitem,
                allseasonData: SeasonPeril.result,
              });
            }
          );
        }
      })
    });
  }
  componentDidMount() {
    const user_role = Permission();
    this.getPerilSeason();
    if (user_role[0] == "DR") {
      this.setState({
        kb4: true,
      });
    }

    if (user_role[0] == "ADM") {
      this.setState({
        kb4: true,
      });
    }
    this.setState({
      dloc: user_role[1],
      ddloc: user_role[1],
    });

    if (user_role[0] === "KB" || user_role[0] === "FM") {
      this.setState({
        farmer_id: localStorage.getItem("farmer_id"),
      });
      if (user_role[1] == "Pattanchery") {
        this.setState({
          kb1: false,
          kb2: false,
          kb3: false,
          kb4: false,
          kb5: true,
          kb6: false,
          kb7: false,
          latnew: 10.72918673,
          longnew: 76.78622876,
          ddloc: "Pattanchery",
        });
      } else if (user_role[1] == "Chittur-Thathamangalam") {
        this.setState({
          kb1: true,
          kb2: false,
          kb3: false,
          kb4: false,
          kb5: false,
          kb6: false,
          kb7: false,
          latnew: 10.69300691,
          longnew: 76.72595507,
          ddloc: "Chittur",
        });
      } else if (user_role[1] == "Nallepilly") {
        this.setState({
          kb1: false,
          kb2: false,
          kb3: false,
          kb4: true,
          kb5: false,
          kb6: false,
          kb7: false,
          latnew: 10.72918673,
          longnew: 76.78622876,
          ddloc: "Nallepilly",
        });
      } else if (user_role[1] == "Kozhinjampara") {
        this.setState({
          kb1: false,
          kb2: false,
          kb3: true,
          kb4: false,
          kb5: false,
          kb6: false,
          kb7: false,
          latnew: 10.73595019,
          longnew: 76.82999565,
          ddloc: "Kozhinjampara",
        });
      } else if (user_role[1] == "Eruthenpathy") {
        this.setState({
          kb1: false,
          kb2: true,
          kb3: false,
          kb4: false,
          kb5: false,
          kb6: false,
          kb7: false,
          latnew: 10.74866619,
          longnew: 76.85879364,
          ddloc: "Eruthenpathy",
        });
      } else if (user_role[1] == "Perumatty") {
        this.setState({
          kb1: false,
          kb2: false,
          kb3: false,
          kb4: false,
          kb5: false,
          kb6: true,
          kb7: false,
          latnew: 10.662,
          longnew: 76.787,
          ddloc: "Perumatty",
        });
      } else if (user_role[1] == "Vadakarapathy") {
        this.setState({
          kb1: false,
          kb2: false,
          kb3: false,
          kb4: false,
          kb5: false,
          kb6: false,
          kb7: true,
          latnew: 10.81,
          longnew: 76.846,
          ddloc: "Vadakarapathy",
        });
      }
    }
  }

  async getdata() {
    this.child.fetchtermsheetdata();
    this.child.fetchconditiondata();
    if (this.state.tableperil == "all") {
      this.setState({
        showchart: false,
        tmtable: true,
        showCongenial: false,
        showWaiting: false,
      });
    } else {
      var i = 0;
      var ele = document.getElementsByName("radio1");
      ele[i].checked = true;
      this.setState({
        showLoader: true,
        showchart: false,
        showWaiting: false,
      });
      if (this.state.tableperil == "Disease Congenial Climate") {
        this.setState({
          isotherperil: true,
        });
      }
      let searchdata = {
        rua: this.state.ddloc,
        data_provider: this.state.tabledp,
        crop: this.state.crop,
        peril: this.state.tableperil,
        season: this.state.tableseason,
      };
      searchdata = JSON.stringify(searchdata);

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
        body: searchdata,
      };
      try {
        var chartdata = await fetch(
          process.env.REACT_APP_APIEND + `farmer/rua-termchart-provider`,
          requestOptions
        ).then((res) => {
          return res.json();
        });
        if (this.state.tableperil == "Disease Congenial Climate") {
          if (chartdata.data.length > 0) {
            this.setDiseasecon(chartdata.data, this.state.tabledp);
          } else {
            toast.error("Data is Empty !");
            this.setState({
              showLoader: false,
              showchart: false,
              showWaiting: true,
              tmtable: false,
            });
          }
        } else if (chartdata.data.length > 0) {
          this.getValues(chartdata.data, this.state.tabledp, chartdata.Phases);
        } else {
          toast.error("Data is Empty !");
          this.setState({
            showLoader: false,
            showchart: false,
            showWaiting: true,
          });
        }

        if (chartdata.Phases.length > 0) {
          this.setState({
            phaseselection: chartdata.Phases,
          });
        } else {
          toast.error("Data is Empty !");
          this.setState({
            showLoader: false,
            showchart: false,
            showWaiting: true,
          });
        }
      } catch (err) {
        this.setState({
          showLoader: false,
          showchart: false,
          showWaiting: true,
        });
      }
    }
  }

  handledpchange(e) {
    this.setState({ tabledp: e.target.value }, () => {
      // console.log("DATA_PROVIDER", this.state.tabledp);
    });
  }
  getDCCRH(type) {
    if (type == "min") {
      if (this.state.crop == "Mango") {
        return 80;
      }
      if (this.state.crop == "Paddy") {
        return 80;
      }
      if (this.state.crop == "Veg Group I") {
        return 80;
      }
      if (this.state.crop == "Paddy 2nd Crop") {
        return 70;
      }
      if (this.state.crop == "Paddy 3rd Crop") {
        return 80;
      }
      if (this.state.crop == "Paddy Winter") {
        return 80;
      }
      if (this.state.crop == "Veg Group I - SMR") {
        return 80;
      }
    }

    if (type == "max") {
      if (this.state.crop == "Mango") {
        return 100;
      }
      if (this.state.crop == "Paddy") {
        return 90;
      }
      if (this.state.crop == "Veg Group I") {
        return 100;
      }
      if (this.state.crop == "Paddy 2nd Crop") {
        return 80;
      }
      if (this.state.crop == "Paddy 3rd Crop") {
        return 90;
      }
      if (this.state.crop == "Paddy Winter") {
        return 90;
      }
      if (this.state.crop == "Veg Group I - SMR") {
        return 100;
      }
    }
  }
  getDCCTM(type) {
    if (type == "min") {
      if (this.state.crop == "Mango") {
        return 0;
      }
      if (this.state.crop == "Paddy") {
        return 32;
      }
      if (this.state.crop == "Veg Group I") {
        return 28;
      }
      if (this.state.crop == "Paddy 2nd Crop") {
        return 31;
      }
      if (this.state.crop == "Paddy 3rd Crop") {
        return 32;
      }
      if (this.state.crop == "Paddy Winter") {
        return 28;
      }
      if (this.state.crop == "Veg Group I - SMR") {
        return 28;
      }
    }

    if (type == "max") {
      if (this.state.crop == "Mango") {
        return 24;
      }
      if (this.state.crop == "Paddy") {
        return 50;
      }
      if (this.state.crop == "Veg Group I") {
        return 32;
      }
      if (this.state.crop == "Paddy 2nd Crop") {
        return 50;
      }
      if (this.state.crop == "Paddy 3rd Crop") {
        return 50;
      }
      if (this.state.crop == "Paddy Winter") {
        return 32;
      }
      if (this.state.crop == "Veg Group I - SMR") {
        return 32;
      }
    }
  } 
  setDiseasecon(data, dp) {
    var chartHMvalues = [];
    var chartTMvalues = [];
    var dataHM = {
      name: dp,
      data: [],
    };
    var dataTM = {
      name: dp,
      data: [],
    };
    for (var key in data) {
      if (
        data[key].parameter == "Humidity_avg" ||
        data[key].parameter == "Humidity"
      ) {
        dataHM.data.push({ x: data[key].unixms * 1000, y: data[key].value });
      }
      if (
        data[key].parameter == "Temperature_avg" ||
        data[key].parameter == "Temperature"
      ) {
        dataTM.data.push({ x: data[key].unixms * 1000, y: data[key].value });
      }
    }
    chartHMvalues = [dataHM];
    chartTMvalues = [dataTM];

    this.setState({
      optionscongenial1: {
        colors: ["#ab2200"],
        chart: {
          id: "Temperature",
          group: "Disease_CONG",
          animations: {
            enabled: false,
          },
          stacked: false,
          zoom: {
            type: "x",
            enabled: true,
          },
          toolbar: {
            autoSelected: "zoom",
            show: true,
          },
          background: "#fff",
        },
        annotations: {
          yaxis: [
            {
              y: this.getDCCTM("min"),
              y2: this.getDCCTM("max"),
              borderColor: "#000",
              fillColor: "#e8b990",
              label: {
                text: "Temperature range",
              },
            },
          ],
        },
        dataLabels: {
          enabled: false,
        },
        markers: {
          size: 0,
        },
        title: {
          text: "Temperature",
          align: "left",
        },
        xaxis: {
          type: "datetime",
          labels: {
            datetimeFormatter: {
              year: "yyyy",
              month: "dd MMM",
              day: "dd MMM",
              hour: "HH:mm",
            },
          },
          title: {
            text: "Date/Time",
            rotate: -90,
            offsetX: 0,
            offsetY: 0,
            style: {
              color: undefined,
              fontSize: "12px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 600,
              cssClass: "apexcharts-yaxis-title",
            },
          },
        },
        yaxis: {
          min: 0,
          max: 50,
          title: {
            text: "Temperature (°C)",
            rotate: -90,
            offsetX: 0,
            offsetY: 0,
            style: {
              color: undefined,
              fontSize: "12px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 600,
              cssClass: "apexcharts-yaxis-title",
            },
          },
        },
        stroke: {
          width: 1,
        },

        tooltip: {
          shared: false,
          x: {
            format: "dd MMM - HH : mm ",
          },
        },
      },
      seriescongenial1: chartTMvalues,
      optionscongenial2: {
        colors: ["#0b6b75"],
        chart: {
          id: `Humidity`,
          group: "Disease_CONG",
          animations: {
            enabled: false,
          },
          stacked: false,
          zoom: {
            type: "x",
            enabled: true,
          },
          background: "#fff",
          toolbar: {
            show: true,
          },
        },

        annotations: {
          yaxis: [
            {
              y: this.getDCCRH("min"),
              y2: this.getDCCRH("max"),
              borderColor: "#000",
              fillColor: "#a2e5eb",
              label: {
                text: "Humidity range",
              },
            },
          ],
        },
        dataLabels: {
          enabled: false,
        },
        markers: {
          size: 0,
        },
        title: {
          text: "Humidity",
          align: "left",
        },
        xaxis: {
          type: "datetime",
          labels: {
            datetimeFormatter: {
              year: "yyyy",
              month: "dd MMM",
              day: "dd MMM",
              hour: "HH:mm",
            },
          },
          title: {
            text: "Date/Time",
            rotate: -90,
            offsetX: 0,
            offsetY: 0,
            style: {
              color: undefined,
              fontSize: "12px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 600,
              cssClass: "apexcharts-yaxis-title",
            },
          },
        },
        yaxis: {
          show: true,
          min: 0,
          max: 100,
          title: {
            text: "Humidity (%)",
            rotate: -90,
            offsetX: 0,
            offsetY: 0,
            style: {
              color: undefined,
              fontSize: "12px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 600,
              cssClass: "apexcharts-yaxis-title",
            },
          },
        },
        stroke: {
          width: 1,
        },

        tooltip: {
          shared: false,
          x: {
            format: "dd MMM - HH : mm ",
          },
        },
      },
      seriescongenial2: chartHMvalues,
      isDiseaseCong: true,
      isExcessrain: false,
      showchart: true,
      showtable: true,
      showLoader: false,
      isGetdatadisabled: false,
      showPhaseselector: false,
    });
  }
  getValues(values, dp, phases) {
    var chartvalues = [];
    if (this.state.peril == "Excess Rainfall" && this.state.crop == "Paddy") {
      var data = {
        name: "Daliy Rainfall",
        data: [],
      };
      var data1 = {
        name: "Last 3-day cumulative",
        data: [],
      };
    } else if (
      this.state.peril == "Excess Rainfall" &&
      this.state.crop == "Paddy 2nd Crop"
    ) {
      var data = {
        name: "Daliy Rainfall",
        data: [],
      };
      var data1 = {
        name: "Last 3-day cumulative",
        data: [],
      };
    } else if (
      this.state.peril == "Excess Rainfall" &&
      this.state.crop == "Paddy 3rd Crop"
    ) {
      var data = {
        name: "Daliy Rainfall",
        data: [],
      };
      var data1 = {
        name: "Last 3-day cumulative",
        data: [],
      };
    } else if (
      this.state.peril == "Excess Rainfall" &&
      this.state.crop == "Paddy Winter"
    ) {
      var data = {
        name: "Daliy Rainfall",
        data: [],
      };
      var data1 = {
        name: "Last 3-day cumulative",
        data: [],
      };
    } else {
      var data = {
        name: dp,
        data: [],
      };
      var data1 = {
        name: "Last 3-day cumulative",
        data: [],
      };
    }
    
    var value_list = [];
    var value_list1 = [];
    for (var key in values) {
      // console.log(object[key]);
      //   if (values.length>0) continue;
      data.data.push({ x: values[key].unixms * 1000, y: values[key].value });
      data1.data.push({ x: values[key].unixms * 1000, y: values[key].cumulative });
      if (values[key].value != null) {
        value_list.push(Number(values[key].value));
      }
      if (values[key].value != null) {
        value_list1.push(Number(values[key].value));
      }
    }
    if (
      this.state.season.slice(0, 3) == "Rab" &&
      this.state.tableperil == "Deficit Rainfall" &&
      this.state.crop == "Paddy 2nd Crop"
    ) {
      var max_value = 210;
    } else if (
      this.state.season == "Rabi 2020-21" &&
      this.state.tableperil == "Deficit Rainfall" &&
      this.state.crop == "Paddy Winter"
    ) {
      var max_value = 310;
    } else {
      var max_value1 = Math.max(...value_list);
      var max_value2 = Math.max(...value_list1);
      var max_value = Math.max(...value_list);
      max_value = max_value + 10;
      max_value = Math.ceil(max_value / 5) * 15;
      // if (max_value1 > max_value2) {
      //   var max_value = Math.max(...value_list);
      //   max_value = max_value + 10;
      //   max_value = Math.ceil(max_value / 5) * 5;
      // } else if (max_value1 < max_value2) {
      //   var max_value = Math.max(...value_list1);
      //   max_value = max_value + 10;
      //   max_value = Math.ceil(max_value / 5) * 5;
      // }
    }
    // console.log(
    //   "SORTED QUERY",
    //   this.state.season,
    //   this.state.tableperil,
    //   this.state.crop
    // );
    chartvalues = [data, data1];

    var lastdate = values.length;
    lastdate = lastdate - 1;
    lastdate = values[lastdate].datetime;
    this.setState({
      allstDate: values[0].datetime,
      allendDate: lastdate,
    });
    if(this.state.crop == "Paddy" && this.state.peril == "Excess Rainfall"){
      this.setState({
        isDiseaseCong: false,
        isExcessrain: true,
        options: {
          colors: ["#0b6b75", "#ff6a00"],
          chart: {
            id: "area-datetime",
            type: "area",
            height: 350,
            zoom: {
              autoScaleYaxis: true,
            },
            toolbar: {
              show: true,
              tools: {
                download: false,
              },
            },
          },
          xaxis: {
            type: "datetime",
            labels: {
              datetimeUTC: false,
              datetimeFormatter: {
                year: "yyyy",
                month: "dd MMM",
                day: "dd MMM",
                hour: "HH:mm",
              },
            },
            title: {
              text: "Date/Time",
              rotate: -90,
              offsetX: 0,
              offsetY: 0,
              style: {
                color: undefined,
                fontSize: "12px",
                fontFamily: "Helvetica, Arial, sans-serif",
                fontWeight: 600,
                cssClass: "apexcharts-yaxis-title",
              },
            },
          },
          yaxis: {
            show: true,
            // min: 0,
            max: max_value,
            title: {
              text: this.checkPerilunit(),
              rotate: -90,
              offsetX: 0,
              offsetY: 0,
              style: {
                color: undefined,
                fontSize: "12px",
                fontFamily: "Helvetica, Arial, sans-serif",
                fontWeight: 600,
                cssClass: "apexcharts-yaxis-title",
              },
            },
          },
  
          stroke: {
            width: 1,
          },
          tooltip: {
            x: {
              format: "dd.MM.yyyy",
            },
          },
        },        
      });
    } else if(this.state.crop == "Paddy 2nd Crop" && this.state.peril == "Excess Rainfall"){
      this.setState({
        isDiseaseCong: false,
        isExcessrain: true,
        options: {
          colors: ["#0b6b75", "#ff6a00"],
          chart: {
            id: "area-datetime",
            type: "area",
            height: 350,
            zoom: {
              autoScaleYaxis: true,
            },
            toolbar: {
              show: true,
              tools: {
                download: false,
              },
            },
          },
          xaxis: {
            type: "datetime",
            labels: {
              datetimeUTC: false,
              datetimeFormatter: {
                year: "yyyy",
                month: "dd MMM",
                day: "dd MMM",
                hour: "HH:mm",
              },
            },
            title: {
              text: "Date/Time",
              rotate: -90,
              offsetX: 0,
              offsetY: 0,
              style: {
                color: undefined,
                fontSize: "12px",
                fontFamily: "Helvetica, Arial, sans-serif",
                fontWeight: 600,
                cssClass: "apexcharts-yaxis-title",
              },
            },
          },
          yaxis: {
            show: true,
            min: 0,
            max: max_value,
            title: {
              text: this.checkPerilunit(),
              rotate: -90,
              offsetX: 0,
              offsetY: 0,
              style: {
                color: undefined,
                fontSize: "12px",
                fontFamily: "Helvetica, Arial, sans-serif",
                fontWeight: 600,
                cssClass: "apexcharts-yaxis-title",
              },
            },
          },
  
          stroke: {
            width: 1,
          },
          tooltip: {
            x: {
              format: "dd.MM.yyyy",
            },
          },
        },
      });
    }else if(this.state.crop == "Paddy 3rd Crop" && this.state.peril == "Excess Rainfall"){
      this.setState({
        isDiseaseCong: false,
        isExcessrain: true,
        options: {
          colors: ["#0b6b75", "#ff6a00"],
          chart: {
            id: "area-datetime",
            type: "area",
            height: 350,
            zoom: {
              autoScaleYaxis: true,
            },
            toolbar: {
              show: true,
              tools: {
                download: false,
              },
            },
          },
          xaxis: {
            type: "datetime",
            labels: {
              datetimeUTC: false,
              datetimeFormatter: {
                year: "yyyy",
                month: "dd MMM",
                day: "dd MMM",
                hour: "HH:mm",
              },
            },
            title: {
              text: "Date/Time",
              rotate: -90,
              offsetX: 0,
              offsetY: 0,
              style: {
                color: undefined,
                fontSize: "12px",
                fontFamily: "Helvetica, Arial, sans-serif",
                fontWeight: 600,
                cssClass: "apexcharts-yaxis-title",
              },
            },
          },
          yaxis: {
            show: true,
            min: 0,
            max: max_value,
            title: {
              text: this.checkPerilunit(),
              rotate: -90,
              offsetX: 0,
              offsetY: 0,
              style: {
                color: undefined,
                fontSize: "12px",
                fontFamily: "Helvetica, Arial, sans-serif",
                fontWeight: 600,
                cssClass: "apexcharts-yaxis-title",
              },
            },
          },
  
          stroke: {
            width: 1,
          },
          tooltip: {
            x: {
              format: "dd.MM.yyyy",
            },
          },
        },
      });
    } else if(this.state.crop == "Paddy Winter" && this.state.peril == "Excess Rainfall"){
      this.setState({
        isDiseaseCong: false,
      isExcessrain: true,
      options: {
        colors: ["#0b6b75", "#ff6a00"],
        chart: {
          id: "area-datetime",
          type: "area",
          height: 350,
          zoom: {
            autoScaleYaxis: true,
          },
          toolbar: {
            show: true,
            tools: {
              download: false,
            },
          },
        },
        xaxis: {
          type: "datetime",
          labels: {
            datetimeUTC: false,
            datetimeFormatter: {
              year: "yyyy",
              month: "dd MMM",
              day: "dd MMM",
              hour: "HH:mm",
            },
          },
          title: {
            text: "Date/Time",
            rotate: -90,
            offsetX: 0,
            offsetY: 0,
            style: {
              color: undefined,
              fontSize: "12px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 600,
              cssClass: "apexcharts-yaxis-title",
            },
          },
        },
        yaxis: {
          show: true,
          min: 0,
          max: max_value,
          title: {
            text: this.checkPerilunit(),
            rotate: -90,
            offsetX: 0,
            offsetY: 0,
            style: {
              color: undefined,
              fontSize: "12px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 600,
              cssClass: "apexcharts-yaxis-title",
            },
          },
        },

        stroke: {
          width: 1,
        },
        tooltip: {
          x: {
            format: "dd.MM.yyyy",
          },
        },
      },
      });
    } else {
     
    this.setState({
      isDiseaseCong: false,
      isExcessrain: false,
      options: {
        colors: ["#0b6b75", "#ff6a00"],
        chart: {
          id: "area-datetime",
          type: "area",
          height: 350,
          zoom: {
            autoScaleYaxis: true,
          },
          toolbar: {
            show: true,
            tools: {
              download: false,
            },
          },
        },
        xaxis: {
          type: "datetime",
          labels: {
            datetimeUTC: false,
            datetimeFormatter: {
              year: "yyyy",
              month: "dd MMM",
              day: "dd MMM",
              hour: "HH:mm",
            },
          },
          title: {
            text: "Date/Time",
            rotate: -90,
            offsetX: 0,
            offsetY: 0,
            style: {
              color: undefined,
              fontSize: "12px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 600,
              cssClass: "apexcharts-yaxis-title",
            },
          },
        },
        yaxis: {
          show: true,
          min: 0,
          max: max_value,
          title: {
            text: this.checkPerilunit(),
            rotate: -90,
            offsetX: 0,
            offsetY: 0,
            style: {
              color: undefined,
              fontSize: "12px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 600,
              cssClass: "apexcharts-yaxis-title",
            },
          },
        },

        stroke: {
          width: 1,
        },
        tooltip: {
          x: {
            format: "dd.MM.yyyy",
          },
        },
      },
    });
  }
    this.setState({
      series: chartvalues,
      showchart: true,
      showtable: true,
      showLoader: false,
      isGetdatadisabled: false,
      showPhaseselector: true,
      tmtable: true,
    });
  
    ApexCharts.exec("area-datetime", "removeAnnotation", "phase1");
    ApexCharts.exec("area-datetime", "removeAnnotation", "phase2");
    ApexCharts.exec("area-datetime", "removeAnnotation", "phase3");
    ApexCharts.exec("area-datetime", "removeAnnotation", "phase4");
    ApexCharts.exec("area-datetime", "removeAnnotation", "phase5");
  }
  findMax(owmax, dsmax) {
    const largest = Math.max(owmax, dsmax);
    var per = (largest * 10) / 100;
    return largest + per;
  }

  findpercentage(val) {
    var per = (val * 10) / 100;
    return val + per;
  }

  render() {
    const { t, i18n } = this.props;
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Row>
              <Col xs={12}>
                <h4
                  className="card-title"
                  style={{ padding: "5px", fontSize: "18px" }}
                >
                  {" "}
                  {t("Termsheet Peril Wise")}
                </h4>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Card style={{ backgroundColor: "#f1f6fb" }}>
                  <CardBody>
                    <div>
                      <Row style={{ fontSize: "11px", padding: "0px" }}>
                        <Col md={2}>
                          <FormGroup>
                            <Label className="col-sm col-form-label">
                              {t("Location")}
                            </Label>
                            <select
                              className="form-select"
                              style={{ fontSize: "11px" }}
                              onChange={this.handleLocationchange}
                            >
                              <option
                                value="10.69300691,76.72595507"
                                style={
                                  this.state.kb1 ? {} : { display: "none" }
                                }
                                // disabled={this.state.kb1 ? false : true}
                                selected={this.state.kb1 ? true : false}
                              >
                                Chittur-Thathamangalam
                              </option>
                              <option
                                // disabled={this.state.kb2 ? false : true}
                                value="10.74866619,76.85879364"
                                style={
                                  this.state.kb2 ? {} : { display: "none" }
                                }
                                selected={this.state.kb2 ? true : false}
                              >
                                Eruthenpathy{" "}
                              </option>
                              <option
                                // disabled={this.state.kb3 ? false : true}
                                value="10.738,76.834"
                                style={
                                  this.state.kb3 ? {} : { display: "none" }
                                }
                                selected={this.state.kb3 ? true : false}
                              >
                                Kozhinjampara
                              </option>
                              <option
                                // disabled={this.state.kb4 ? false : true}
                                value="10.72918673,76.78622876"
                                style={
                                  this.state.kb4 ? {} : { display: "none" }
                                }
                                selected={this.state.kb4 ? true : false}
                              >
                                Nallepilly
                              </option>
                              <option
                                // disabled={this.state.kb5 ? false : true}
                                value="10.64777998,76.73270522"
                                style={
                                  this.state.kb5 ? {} : { display: "none" }
                                }
                                selected={this.state.kb5 ? true : false}
                              >
                                Pattanchery
                              </option>
                              <option
                                // disabled={this.state.kb6 ? false : true}
                                value="10.662,76.787"
                                style={
                                  this.state.kb6 ? {} : { display: "none" }
                                }
                                selected={this.state.kb6 ? true : false}
                              >
                                Perumatty
                              </option>
                              <option
                                // disabled={this.state.kb7 ? false : true}
                                value="10.807,76.846"
                                style={
                                  this.state.kb7 ? {} : { display: "none" }
                                }
                                selected={this.state.kb7 ? true : false}
                              >
                                Vadakarapathy
                              </option>
                              {/* <option value="10.74866619,76.85879364">Eruthenpathy - Krishi Bhavan</option>
                                                                <option value="10.736,76.862">Eruthenpathy - Local Body</option> */}
                              {/* <option value="10.738,76.834">Kozhinjampara - Local Body</option>
                                                                <option value="10.73595019,76.82999565">Kozhinjampara - Krishi Bhavan</option> */}
                              {/* <option value="10.72918673,76.78622876" selected>Nallepilly - Krishi Bhavan</option>
                                                                <option value="10.73,76.786">Nallepilly - Local Body</option> */}
                              {/* <option value="10.64777998,76.73270522">Pattanchery - Krishi Bhavan</option> */}
                              {/* <option value="10.662,76.787">Perumatty - Krishi Bhavan</option>
                                                                <option value="10.64975298,76.76319652">Perumatty - Local Body</option> */}
                              {/* <option value="10.807,76.846">Vadakarapathy - Krishi Bhavan</option>
                                                                <option value="10.81,76.846">Vadakarapathy - Local Body</option> */}
                            </select>
                          </FormGroup>
                        </Col>
                        <Col md={2}>
                          <FormGroup>
                            <Label className="col-sm col-form-label">
                              {t("Season")}
                            </Label>
                            <select
                              className="form-select"
                              style={{ fontSize: "11px" }}
                              value={this.state.season}
                              onChange={this.handleSeasonchange}
                            >
                              {this.state.seasonData.length > 0 &&
                                this.state.seasonData.map((item) => (
                                  <option value={item} key={item}>
                                    {item}
                                  </option>
                                ))}
                            </select>
                          </FormGroup>
                        </Col>
                        <Col md={2}>
                          <FormGroup>
                            <Label className="col-sm col-form-label">
                              {t("Crop")}
                            </Label>
                            <select
                              className="form-select"
                              style={{ fontSize: "11px" }}
                              value={this.state.crop}
                              onChange={this.handleCropchange}
                            >
                              {this.state.cropData.length > 0 &&
                                this.state.cropData.map((item) => (
                                  <option value={item} key={item}>
                                    {item}
                                  </option>
                                ))}
                            </select>
                          </FormGroup>
                        </Col>
                        <Col md={2}>
                          <FormGroup>
                            <Label className=" col-sm col-form-label">
                              {t("Perils")}
                            </Label>
                            <select
                              className="form-select"
                              style={{ fontSize: "11px" }}
                              onChange={this.handlePerilchange}
                              value={this.state.peril}
                            >
                              {this.state.perilData.length > 0 &&
                                this.state.perilData.map((item) => (
                                  <option value={item} key={item}>
                                    {item}
                                  </option>
                                ))}
                              <option value={"all"} key={"item"}>
                                All
                              </option>
                            </select>
                          </FormGroup>
                        </Col>
                        <Col md={2}>
                          <FormGroup>
                            <Label className=" col-sm col-form-label">
                              {t("Data Provider")}
                            </Label>
                            <select
                              className="form-select"
                              style={{ fontSize: "11px" }}
                              onChange={this.handledpchange}
                              value={this.state.tabledp}
                            >
                              {this.state.dpData.length > 0 &&
                                this.state.dpData.map((item) => (
                                  <option value={item} key={item}>
                                    {item}
                                  </option>
                                ))}
                              {/* <option value="AIC">AIC</option>
                              <option value="MeteoBlue">MeteoBlue</option>
                              <option value="Nasa Power">Nasa Power</option>
                              <option value="ECMWF">ECMWF</option>
                              <option value="Online Sensors">
                                Online Sensor
                              </option> */}
                            </select>
                          </FormGroup>
                        </Col>
                        <Col md={2}>
                          <FormGroup
                            className="mb-0"
                            style={{ paddingLeft: "0px" }}
                          >
                            <Label className=" col-sm col-form-label">
                              &nbsp;
                            </Label>
                            <Button
                              type="submit"
                              color="primary"
                              className="mr-1"
                              style={{ fontSize: "11px" }}
                              disabled={this.state.isGetdatadisabled}
                              onClick={this.getdata}
                            >
                              {t("Get Data")}
                            </Button>
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col md={12}>
                <Card>
                  <CardBody>
                    <h6
                      style={this.state.showLoader ? {} : { display: "none" }}
                    >
                      {this.state.ddloc} | {this.state.ddseason} |{" "}
                      {this.state.tableperil}
                    </h6>
                    <Row>
                      <Col sm="12">
                        <CardText>
                          <center>
                            <div
                              style={
                                this.state.showWaiting
                                  ? {}
                                  : { display: "none" }
                              }
                            >
                              {t("Waiting for search")}
                            </div>
                            <img
                              src={loader}
                              height={"200px"}
                              style={
                                this.state.showLoader ? {} : { display: "none" }
                              }
                            ></img>
                          </center>
                          <div
                            style={
                              this.state.showchart ? {} : { display: "none" }
                            }
                          >
                            <div class="row" style={{ marginLeft: "10px" }}>
                              <Row
                                style={
                                  this.state.showPhaseselector
                                    ? {}
                                    : { display: "none" }
                                }
                              >
                                <Col>
                                  <Label check>
                                    <Input
                                      type="radio"
                                      name="radio1"
                                      id={"all"}
                                      // defaultValue={this.state.selectedValue==="" ? {} : "p" + (index + 1)}
                                      value={"all"}
                                      onClick={this.phaseSelector}
                                      defaultChecked
                                    />
                                    <p
                                      style={{
                                        display: "inline-block",
                                        fontSize: "13px",
                                      }}
                                    >
                                      All
                                    </p>
                                  </Label>
                                </Col>
                                {this.state.phaseselection.map(
                                  (item, index, data) => {
                                    return (
                                      <Col>
                                        <Label check>
                                          <Input
                                            type="radio"
                                            name="radio1"
                                            id={"p" + (index + 1)}
                                            value={"p" + (index + 1)}
                                            onClick={this.phaseSelector}
                                            // checked={
                                            //   this.state.selectedValue === "p1"
                                            // }
                                          />{" "}
                                          <p
                                            style={{
                                              display: "inline-block",
                                              fontSize: "13px",
                                            }}
                                          >
                                            Phase {index + 1}
                                          </p>
                                        </Label>
                                      </Col>
                                    );
                                  }
                                )}
                              </Row>
                              <div
                                style={
                                  this.state.isDiseaseCong
                                    ? { display: "none" }
                                    : {}
                                }
                              >
                                <Chart
                                  options={this.state.options}
                                  series={this.state.series}
                                  type="line"
                                  height="300"
                                  width="100%"
                                />
                              </div>
                              <div
                                style={
                                  this.state.isDiseaseCong
                                    ? {}
                                    : { display: "none" }
                                }
                              >
                               
                                  <Chart
                                    options={this.state.optionscongenial1}
                                    series={this.state.seriescongenial1}
                                    type="line"
                                    height="300"
                                    width="100%"
                                  />
                                  <div style={{ "text-align": "center" }}>
                                  <span
                                    class="dot"
                                    style={{ "background-color": "#AB2200" }}
                                  ></span>
                                  <p
                                    id="Data_Provider"
                                    style={{
                                      cursor: "pointer",
                                      display: "inline-block",
                                      "font-size": "12px",
                                      "font-family": "Segoe UI",
                                      paddingRight: "10px",
                                      paddingLeft: "4px",
                                    }}
                                  >
                                    {this.state.tabledp}
                                  </p>
                              
                                  <Chart
                                    options={this.state.optionscongenial2}
                                    series={this.state.seriescongenial2}
                                    type="line"
                                    height="300"
                                    width="100%"
                                  />
                                </div>
                                
                              </div>
                              <div style={{ "text-align": "center" }}>
                                <span
                                style={
                                  this.state.isExcessrain
                                    ? { display: "none" }
                                    : {}
                                }
                                >
                                  <span
                                    class="dot"
                                    style={{ "background-color": "#0b6b75" }}
                                  ></span>
                                  <p
                                    id="Data_Provider"
                                    style={{
                                      cursor: "pointer",
                                      display: "inline-block",
                                      "font-size": "12px",
                                      "font-family": "Segoe UI",
                                      paddingRight: "10px",
                                      paddingLeft: "4px",
                                    }}
                                  >
                                    {this.state.tabledp}
                                  </p>
                                  </span>
                                  <span 
                                   style={
                                    this.state.isExcessrain
                                      ? { }
                                      : {display: "none"}
                                  }
                                  >
                                    <span
                                    class="dot"
                                    style={{ "background-color": "#0b6b75" }}
                                  ></span>
                                  <p
                                    id="Data_Provider"
                                    style={{
                                      cursor: "pointer",
                                      display: "inline-block",
                                      "font-size": "12px",
                                      "font-family": "Segoe UI",
                                      paddingRight: "10px",
                                      paddingLeft: "4px",
                                    }}
                                  >
                                    Daily Rainfall
                                  </p>
                                  <span
                                    class="dot"
                                    style={{ "background-color": "#ff6a00" }}
                                  ></span>
                                  <p
                                    id="Data_Provider"
                                    style={{
                                      cursor: "pointer",
                                      display: "inline-block",
                                      "font-size": "12px",
                                      "font-family": "Segoe UI",
                                      paddingRight: "10px",
                                      paddingLeft: "4px",
                                    }}
                                  >
                                    Last 3-day cumulative
                                  </p>
                                  </span>
                                </div>
                            </div>
                          </div>
                        </CardText>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <Card
                //  style={this.state.tmtable ? {} : { display: "none" }}
                style={{ display: "none" }}
                >
                  <CardBody>
                    <Termsheettable
                      onRef={(ref) => (this.child = ref)}
                      tableprops={this.state}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default withNamespaces()(termSheet);
